import { axiosInstance } from '../../axios-instance';
import {
  IGetConversationHistoryRequest,
  IGetConversationHistoryResponse,
  IGetTranscribeTicketResponse,
  ITranscribeAudioMessageRequest,
  ITranscribeAudioMessageResponse,
} from './types';
import { R } from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

interface IConversationService {
  generateTranscribeTicket: () => R<IGetTranscribeTicketResponse>;
  transcribeAudioMessage: (
    p: ITranscribeAudioMessageRequest
  ) => R<ITranscribeAudioMessageResponse>;
  getHistory: (
    p: IGetConversationHistoryRequest,
    c?: AxiosRequestConfig
  ) => R<IGetConversationHistoryResponse>;
}

export class ConversationService implements IConversationService {
  async generateTranscribeTicket(): R<IGetTranscribeTicketResponse> {
    try {
      return axiosInstance.post(
        `/v1/ai/v1/ana_voice/generate_transcribe_ticket`,
        {}
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getHistory(
    p: IGetConversationHistoryRequest,
    c?: AxiosRequestConfig
  ): R<IGetConversationHistoryResponse> {
    try {
      const params = qs.stringify(p);

      return axiosInstance.get(
        `/v1/ai/v2/ana/conversation_thread/active?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async transcribeAudioMessage(
    p: ITranscribeAudioMessageRequest
  ): R<ITranscribeAudioMessageResponse> {
    try {
      const url = window.origin.includes('localhost')
        ? 'https://sports-dev.analog.io/'
        : window.origin;

      return axiosInstance.post(
        `${url}/vm-transcribe/v1/ana_voice/transcribe_audio_message`,
        { audio_data: p.audio_data },
        { headers: { 'api-key': p.ticket } }
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
