import { useEffect, useMemo, useRef } from 'react';
import { useUsersStore } from '../../../../store/users/slice';
import { useBoolean } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../types/athletes';
import { AthletesRepository } from '../../../../api/athletes/repository';
import { AthletesService } from '../../../../api/athletes/service';
import { useTranslation } from 'react-i18next';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { CoachRepository } from '../../../../api/coach/repository';
import { CoachService } from '../../../../api/coach/service';
import { IStaff } from '../../../../types/coach';
import { useStaffStore } from '../../../../store/staff/slice';
import { EMPTY } from '../../../../constants';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const useViewModel = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);
  const athlete = useAthleteStore((s) => s.athlete);
  const staff = useStaffStore((s) => s.staff);

  const phone = useMemo(() => {
    const phone = athlete?.phone || staff?.phone;
    if (phone) {
      const parsedPhone = parsePhoneNumberFromString(phone);
      if (parsedPhone) {
        return parsedPhone.formatInternational();
      } else {
        return phone;
      }
    }
    return EMPTY;
  }, [athlete?.phone, staff?.phone]);

  const { isAthlete } = useUserRole();
  const {
    value: showProfileDetailsModal,
    setTrue: openProfileDetailsModal,
    setFalse: dismissProfileDetailsModal,
  } = useBoolean(false);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const coachRepository = useMemo(() => {
    return new CoachRepository(new CoachService());
  }, []);

  useEffect(() => {
    if (!isAthlete || fetching.current || !userProfile?.athleteId) {
      return;
    }

    fetching.current = true;
    athletesRepository
      .getAthleteById({
        id: userProfile.athleteId,
      })
      .then((athlete: IAthlete) => {
        useAthleteStore.getState().setAthlete(athlete);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [isAthlete, athletesRepository, t, userProfile?.athleteId]);

  useEffect(() => {
    if (isAthlete || fetching.current) {
      return;
    }

    fetching.current = true;
    coachRepository
      .getProfile()
      .then((staff: IStaff) => {
        useStaffStore.getState().setStaff(staff);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [coachRepository, isAthlete]);

  useEffect(() => {
    return () => {
      useAthleteStore.getState().setAthlete(null);
      useStaffStore.getState().setStaff(null);
    };
  }, []);

  return {
    userProfile,
    showProfileDetailsModal,
    openProfileDetailsModal,
    dismissProfileDetailsModal,
    phone,
  };
};
