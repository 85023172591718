import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';

type IProps = {
  text: string | undefined;
  author: string;
};

export const Comment = ({ text, author }: IProps) => {
  const { t } = useTranslation();

  if (!text) {
    return null;
  }

  return (
    <div className="workout-card__comment">
      <pre>{text}</pre>
      <footer className="workout-card__comment-footer">
        <Typography
          color="text-placeholder"
          variant="subtitle"
          text={t('prepositions.by')}
        />
        <Typography color="text-primary" variant="subtitle" text={author} />
      </footer>
    </div>
  );
};
