import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { ApiErrorCode, LS } from '../constants';
import { parseTeamName } from '../utils/teamName';
import { trackError } from '../ms/log-insights';
import {
  Environment,
  getApiUrl,
  getEnvironment,
} from '../utils/getEnvironment';
import { useAppStore } from '../store/app/slice';
import { onRefreshToken } from './onRefreshToken';
import { ROUTES } from '../router/routes';

const axiosInstance = axios.create({
  baseURL: getApiUrl(),
});

const interceptBlackList: string[] = [
  '/v1/preauth/checkRegistration',
  '/v1/mywoosh/consent',
];
const refreshBlackList: string[] = ['/v1/mywoosh/consent'];

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const isBlacklisted = interceptBlackList.some(
    (path) => config.url && config.url.includes(path)
  );

  if (isBlacklisted) {
    return config;
  }

  config.headers.Authorization = `Bearer ${localStorage.getItem(
    LS.AccessToken
  )}`;

  const teamName = localStorage.getItem(LS.Team);
  if (teamName) {
    config.headers['X-team-name'] = parseTeamName(teamName);
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError) => {
  trackError(error);

  if (error?.response?.status === ApiErrorCode.Unauthorized) {
    const refreshToken = localStorage.getItem(LS.RefreshToken);
    const isBlackListed = refreshBlackList.some(
      (path) => error.config?.url && error.config.url.includes(path)
    );
    const shouldRefresh = !!refreshToken && !isBlackListed;

    if (!shouldRefresh) {
      if (!isBlackListed) {
        window.location.href = `/${ROUTES.SIGN_OUT}`;
      }
      return Promise.reject(error);
    }

    return onRefreshToken(error)
      .then((config) => {
        return axiosInstance.request(config);
      })
      .catch((e: Error | AxiosError) => {
        if (
          e instanceof AxiosError &&
          e.response?.status === ApiErrorCode.Unauthorized
        ) {
          window.location.href = `/${ROUTES.SIGN_OUT}`;
        }
      });
  }

  if (
    (getEnvironment() === Environment.Local ||
      getEnvironment() === Environment.Development) &&
    error?.response?.status &&
    Number(error.response.status) >= 500
  ) {
    useAppStore.getState().setError(error);
  }

  return Promise.reject(error);
};

const intercept = (): void => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
};

export { axiosInstance, intercept };
