import './index.css';
import { useViewModel } from './useViewModel';
import { TrainingLoadFeedback } from './components/TrainingLoadFeedback';
import { TrainingZones } from './components/TrainingZones';
import { HrvVsRhr } from './components/HrvVsRhr';
import { ThisWeek } from './components/ThisWeek';
import { TimeInZones } from './components/TimeInZones';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const AthleteProfileView = () => {
  const { filters, zonePerformanceLoading, zonePerformanceSource } =
    useViewModel();

  return (
    <div className="athlete-profile-page">
      <div className="athlete-profile__card">
        <div className="athlete-profile__card-row">
          <TrainingZones />
          <div className="athlete-profile__card-fixedPart">
            <HrvVsRhr />
            <TrainingLoadFeedback />
          </div>
        </div>
        <div className="athlete-profile__card-row">
          <ThisWeek />
          <TimeInZones
            composeKey={filters.p1}
            source={zonePerformanceSource[filters.p1]}
            loading={zonePerformanceLoading}
          />
          <TimeInZones
            composeKey={filters.p2}
            source={zonePerformanceSource[filters.p2]}
            color="Purple"
            loading={zonePerformanceLoading}
          />
        </div>
      </div>
    </div>
  );
};

export const AthleteProfile = () => {
  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().athleteDetails}
    >
      <AthleteProfileView />
    </PageFiltersContextProvider>
  );
};
