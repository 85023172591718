import { FeatureFlagsService } from './service';
import { IFeatureFlags } from '../../types/feature-flags';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';

export interface IFeatureFlagsRepository {
  getFeatureFlags: (c?: AxiosRequestConfig) => Promise<IFeatureFlags>;
}

export class FeatureFlagsRepository
  extends BaseRepository
  implements IFeatureFlagsRepository
{
  constructor(
    private readonly service: FeatureFlagsService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getFeatureFlags(c?: AxiosRequestConfig): Promise<IFeatureFlags> {
    const response = await this.handleRequest(this.service.getFeatureFlags, c);
    return response.data;
  }
}
