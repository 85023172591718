import { appInsights } from './app-insights';
import { getEnvironment } from '../utils/getEnvironment';
import { useUsersStore } from '../store/users/slice';
import { AnyValue } from '../types/common';
import { TrackingEvent } from './tracking-entities';
import { parseTeamName } from '../utils/teamName';

function generateTelemetryProperties(additionalProperties?: {
  [key: string]: AnyValue;
}) {
  const environment = getEnvironment();
  const user = useUsersStore.getState().userProfile;
  const userId = user?.id ?? 'anonymous';
  const userType = user?.athleteId ? 'athlete' : 'staff';
  const userRoles = user?.roles ?? [];
  const team = parseTeamName(window.location.pathname.split('/')[1] || '');

  return {
    environment,
    userId,
    userType,
    userRoles,
    team,
    url: window.location.href,
    ...additionalProperties,
  };
}

function trackUserInteractionEvent(
  eventName: string,
  additionalProperties?: { [key: string]: string }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties(additionalProperties);
    appInsights.trackEvent({ name: eventName, properties });
  }
}

function trackChartInteraction(
  interactionType: string,
  additionalProperties?: { [key: string]: string | undefined }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties({
      interactionType,
      ...additionalProperties,
    });
    appInsights.trackEvent({
      name: TrackingEvent.CHART_INTERACTION,
      properties,
    });
  }
}

function trackError(
  error: Error,
  additionalProperties?: { [key: string]: AnyValue }
) {
  if (appInsights) {
    const properties = generateTelemetryProperties(additionalProperties);
    appInsights.trackException({ exception: error, properties });
  }
}

function trackUserLogin() {
  if (appInsights) {
    const properties = generateTelemetryProperties();
    appInsights.trackEvent({ name: TrackingEvent.USER_LOGIN, properties });
  }
}

export {
  trackChartInteraction,
  trackError,
  trackUserInteractionEvent,
  trackUserLogin,
};
