import './slider.css';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { ISegmentButton, Segment, Typography } from '@cycling-web/analog-ui';
import { ChartTrackingNames } from '../../../../constants/charts';

type IProps = {
  hasData: boolean;
  loading: boolean;
  buttons: ISegmentButton[];
  activeButton: number;
  setActiveButton: Dispatch<SetStateAction<number>>;
  sampling: number;
  setSampling: Dispatch<SetStateAction<number>>;
  samplingOptions: number[];
};

export const ChannelsChartHeader = ({
  buttons,
  activeButton,
  setActiveButton,
  sampling,
  setSampling,
  samplingOptions,
  loading,
  hasData,
}: IProps) => {
  const currentSamplingIndex = samplingOptions.findIndex((n) => n === sampling);
  const getSamplingLabel = (value: number) => {
    if (activeButton === 0) {
      if (value < 60000) {
        return `${value / 1000}s`;
      }
      return `${value / 60000}m`;
    }

    if (value < 1000) {
      return `${value}m`;
    }
    return `${value / 1000}km`;
  };

  const handleSamplingChange = (e: ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.value);
    setSampling(samplingOptions[index]);
  };

  return (
    <div className="workout-channels-chart__header">
      <Typography
        className="workout-channels-chart__header-title"
        variant="headline"
        weight="bold"
        text={ChartTrackingNames.WorkoutGraph}
      />

      {hasData && (
        <>
          <Segment
            size="s"
            buttons={buttons}
            activeButton={activeButton}
            onChange={setActiveButton}
          />

          <div className="workout-channels-chart__smooth">
            <input
              className="workout-channels-chart__smooth-slider"
              type="range"
              min={0}
              max={samplingOptions.length - 1}
              value={currentSamplingIndex}
              onChange={handleSamplingChange}
              style={{
                '--val':
                  (currentSamplingIndex / (samplingOptions.length - 1)) * 100,
              }}
            />
            <Typography
              color="text-secondary"
              text={getSamplingLabel(sampling, activeButton)}
            />
          </div>
        </>
      )}
    </div>
  );
};
