import { ISelectOption, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { IDayOffForm } from './types';
import { useCallback } from 'react';
import { ICalendarRuleWeek } from '../../../../../../types/workouts';
import clsx from 'clsx';
import { useDayOfWeek } from './useDayOfWeek';

export const DayOfWeekControl = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<IDayOffForm>();
  const [dayOfWeek] = useWatch<IDayOffForm>({
    name: ['dayOfWeek'],
  });

  const options = useDayOfWeek();

  const value = options.find((o) => o.id === dayOfWeek);

  const onClick = useCallback(
    (o: ISelectOption) => {
      return () => {
        setValue('dayOfWeek', o.id as ICalendarRuleWeek);
      };
    },
    [setValue]
  );

  const daysJSX = options.map((o) => {
    const rootClass = clsx(
      'day-of-week-control__button',
      dayOfWeek === o.id && 'day-of-week-control__button--active',
      'analog-typography--button-m'
    );

    return (
      <button key={o.id} className={rootClass} onClick={onClick(o)}>
        {o.text[0]}
      </button>
    );
  });

  return (
    <div className="day-of-week-control">
      <div className="day-of-week-control__inner">{daysJSX}</div>
      <Typography
        color="text-primary"
        text={t('label.occurs_every_what', { what: value?.text })}
      />
    </div>
  );
};
