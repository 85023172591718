export enum FallbackValue {
  Unknown = 'unknown',
}

export enum TrackingForm {
  ANTHROPOMETRY = 'Anthropometry',
  HEIGHT_AND_WEIGHT = 'HeightAndWeight',
  REPORT_ILLNESS = 'ReportIllness',
  REPORT_INJURY = 'ReportInjury',
  REPORT_REHABILITATION = 'ReportRehabilitation',
  TRAINING_ZONES = 'TrainingZones',
  GROUP = 'Group',
  WORKOUT_EVENT = 'WorkoutEvent',
  WORKOUT_DAY_OFF = 'WorkoutDayOff',
  WORKOUT_PRESCRIPTION = 'WorkoutPrescription',
  CUSTOM_PERIOD = 'CustomPeriod',
  MARK_RECOVERED = 'MarkRecovered',
}

export enum TrackingAction {
  EXPORT_PERFORMANCE_REPORT = 'ExportPerformanceReport',
  RESET_THRESHOLD = 'ResetThreshold',
  ADD_TRAINING_ZONE = 'AddTrainingZone',
  DELETE_TRAINING_ZONE = 'DeleteTrainingZone',
  START_RECORDING = 'StartRecording',
  STOP_RECORDING = 'StopRecording',
  APPLY_SUGGESTION = 'ApplySuggestion',
  APPLY_MENTION = 'ApplyMention',
  READ_MESSAGE_ALOUD = 'ReadMessageAloud',
  APPLY_ATHLETES = 'ApplyAthletes',
  BACK_BUTTON = 'BackButton',
  SEND_ANA_TEXT_MESSAGE = 'SendAnaTextMessage',
  SEND_SC_TEXT_MESSAGE = 'SendSmartChatTextMessage',
}

export enum TrackingModal {
  TRAINING_ZONES = 'TrainingZonesModal',
  GROUP = 'Group',
  ANA = 'Ana',
  ANA_TOOL = 'AnaTool',
  CALENDAR_ADD_DATA = 'CalendarAddData',
  TRAINING_PLAN = 'TrainingPlan',
  SIDE_NAVIGATION = 'SideNavigation',
  CUSTOM_PERIOD = 'CustomPeriod',
  ATHLETES = 'Athletes',
  EXPAND_CHART = 'ExpandChart',
}

export enum TrackingNavigationSource {
  AVATAR = 'Avatar',
  PAGE_TITLE_AVATAR = 'PageTitleAvatar',
  PAGE_TITLE_DROPDOWN = 'PageTitleDropdown',
}

export enum TrackingEvent {
  SUBMIT_FORM = 'SubmitForm',
  CLICK = 'Click',
  MODAL_OPENED = 'ModalOpened',
  MODAL_CLOSED = 'ModalClosed',
  FILTER = 'Filter',
  NAVIGATE = 'Navigate',
  CHART_INTERACTION = 'ChartInteraction',
  USER_LOGIN = 'UserLogin',
}
