import { useEffect, useState } from 'react';
import { useAthleteStore } from '../../../store/athlete/slice';
import { getProfileParameter } from '../utils/getDefaults';
import { EMPTY } from '../../../constants';
import { IAthlete } from '../../../types/athletes';

type IProps = {
  composeKey: string | undefined;
  athlete?: IAthlete;
};

export const useThreshold = ({ composeKey, athlete: athlete1 }: IProps) => {
  const athlete2 = useAthleteStore((s) => s.athlete);
  const athlete = athlete1 || athlete2;
  const [threshold, setThreshold] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!athlete || !composeKey) {
      return undefined;
    }

    const profileParameter = getProfileParameter(composeKey);
    let value = athlete[profileParameter];
    if (profileParameter === 'ftp') {
      value = !value || isNaN(Number(value)) ? '' : value.toString();
    }
    value = value?.toString() ?? EMPTY;

    setThreshold(value);
  }, [athlete, composeKey]);

  return { threshold, setThreshold };
};
