import './index.css';
import { IWizardStep, TrainingPlanWizardStep } from '../../types';
import { useTranslation } from 'react-i18next';
import { Step } from './Step';

export const TrainingPlanAside = () => {
  const { t } = useTranslation();

  const steps: IWizardStep[] = [
    {
      id: TrainingPlanWizardStep.Step1,
      text: `${t('label.events')}/${t('label.goals')}`,
    },
    {
      id: TrainingPlanWizardStep.Step2,
      text: t('label.availability'),
    },
    {
      id: TrainingPlanWizardStep.Step3,
      text: t('label.overview'),
    },
  ];

  const stepsJSX = steps.map((item) => {
    return <Step key={item.id} step={item} />;
  });

  return <aside className="training-plan__aside">{stepsJSX}</aside>;
};
