import React, { useCallback } from 'react';
import { PageFilters } from '@cycling-web/common';
import { Button, IconButton, useBoolean } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InviteUserFormModal } from '../InviteUserFormModal';
import { IAdminTeamMemberFilters } from '../../../AdminPanel/types';
import { usePageFilters } from '../../../../context/PageFilters';
import { useMediaMatch } from 'rooks';
import { PlusIcon } from 'lucide-react';

export const TeamMembersFilters = () => {
  const { t } = useTranslation();
  const isMobile = useMediaMatch('(max-width: 480px)');

  const { filters, handleChangeFilter, removeFilter } =
    usePageFilters<IAdminTeamMemberFilters>();

  const handleSearchClear = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  const {
    value: showInviteUserModal,
    setTrue: openInviteUserModal,
    setFalse: dismissInviteUserModal,
  } = useBoolean(false);

  return (
    <>
      <PageFilters
        searchInputProps={{
          value: filters?.search || '',
          onChange: handleChangeFilter('search'),
          onClear: handleSearchClear,
        }}
        actionElements={
          isMobile ? (
            <IconButton content={<PlusIcon />} />
          ) : (
            <Button
              content={t('action.invite')}
              onClick={openInviteUserModal}
            />
          )
        }
      />
      {showInviteUserModal && (
        <InviteUserFormModal onDismiss={dismissInviteUserModal} />
      )}
    </>
  );
};
