import './index.css';
import * as echarts from 'echarts';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { getEventName } from '../../utils/getEventName';
import { prepareChart } from '../../utils/prepareChart';
import { changeAfterRender } from '../../utils/changeAfterRender';
import { useChartContext } from '../../context';
import { ChartFixedCursor } from '../ChartFixedCursor';
import { useFixedCursor } from './useFixedCursor';
import debounce from 'lodash/debounce';

export const ChartCore = () => {
  const {
    option,
    events,
    chartRef: outerChartRef,
    setReady,
    group,
    fixedCursors,
    forceReinit,
  } = useChartContext();

  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const localChartRef = useRef<echarts.EChartsType | null>(null);
  const chartRef =
    (outerChartRef as RefObject<echarts.EChartsType | null>) || localChartRef;

  useEffect(() => {
    if (!chartWrapperRef.current) {
      return;
    }

    if (forceReinit && chartRef.current) {
      echarts.dispose(chartRef.current);
      chartRef.current = null;
    }

    if (!chartRef.current) {
      chartRef.current = echarts.init(chartWrapperRef.current);
    }

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (chartRef.current) {
          chartRef.current.resize();
        }
      }, 100)
    );

    resizeObserver.observe(chartWrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [chartRef, forceReinit]);

  useEffect(() => {
    if (!group || !chartRef.current) {
      return;
    }

    chartRef.current.group = group;
    echarts.connect(group);

    return () => {
      if (group) {
        echarts.disconnect(group);
      }
    };
  }, [chartRef, group]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    if (forceReinit) {
      chartRef.current.clear();
    }

    try {
      chartRef.current.setOption(prepareChart(option), {
        lazyUpdate: !forceReinit,
      });
    } catch (e) {
      console.log(e);
    }
    changeAfterRender(chartRef.current);
    setReady(true);
  }, [chartRef, option, setReady, forceReinit]);

  useEffect(() => {
    if (!events || !chartRef.current) {
      return;
    }
    const chart = chartRef.current;

    function globalMouseMove(event) {
      const pixelPoint = [event.offsetX, event.offsetY];
      const option = chart.getOption();

      const seriesList: any[] = option.series ?? [];
      const dataset: any[][] =
        option?.dataset?.[0]?.source ?? option?.dataset?.source ?? [];

      if (!dataset || dataset.length < 2) return;

      const header = dataset[0];
      const dataRows = dataset.slice(1);

      // Convert pixel to X axis value (assumes shared x-axis)
      const xFromPixel = chart.convertFromPixel(
        { seriesIndex: 0 },
        pixelPoint
      )?.[0];
      if (xFromPixel == null) return;

      // Find the closest X value in data
      let closestRow = dataRows[0];
      let minDiff = Math.abs(dataRows[0][0] - xFromPixel);

      for (let i = 1; i < dataRows.length; i++) {
        const diff = Math.abs(dataRows[i][0] - xFromPixel);
        if (diff < minDiff) {
          closestRow = dataRows[i];
          minDiff = diff;
        }
      }

      // Build result for all known series
      const resultSeries = seriesList
        .map((s) => {
          const seriesName = s.name;
          const yIndex = header.indexOf(seriesName);
          if (yIndex === -1) return null;

          return {
            seriesName,
            value: closestRow[yIndex],
            x: closestRow[0],
          };
        })
        .filter(Boolean); // remove nulls

      // Emit only structured values
      events?.onGlobalMove?.({
        x: closestRow[0],
        series: resultSeries,
      });
    }

    Object.keys(events).forEach((eventName) => {
      if (eventName === 'onGlobalMove') {
        chart.getZr().on(getEventName(eventName), globalMouseMove);
      } else {
        chart.on(getEventName(eventName), events[eventName]);
      }
    });

    return () => {
      Object.keys(events).forEach((eventName) => {
        if (eventName === 'onGlobalMove') {
          chart.getZr().off(getEventName(eventName), globalMouseMove);
        } else {
          chart.off(getEventName(eventName), events[eventName]);
        }
      });
    };
  }, [chartRef, events]);

  useFixedCursor();

  const fixedCursorTooltipJSX = useMemo(
    () =>
      fixedCursors.map((cursor) => (
        <ChartFixedCursor key={cursor.id} cursor={cursor} />
      )),
    [fixedCursors]
  );

  return (
    <>
      <div className="chart-core" ref={chartWrapperRef} />
      {fixedCursorTooltipJSX}
    </>
  );
};
