import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import React, { useCallback, useMemo } from 'react';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { useBreakpoints } from '@cycling-web/common';

type IProps = {
  source: IChartSource;
  loading?: boolean;
};

export const TeamCriticalPowerChart = ({ source, loading }: IProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const onRenderTooltipValue = useCallback((value: number, _: string) => {
    return `${value}`;
  }, []);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: isMobile
        ? {
            left: 40,
            right: 0,
            bottom: 110,
          }
        : { bottom: 110 },
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 45,
          interval: 0,
          fontSize: 10,
        },
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
          position: 'left',
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
          axisLabel: isMobile ? { fontSize: 10 } : undefined,
        },
      ],
      series: [
        {
          name: ChartAxisName.mCP_WKg,
          type: 'bar',
          yAxisIndex: 0,
          z: 2,
          itemStyle: {
            color: (params) => {
              return params.dataIndex % 2 === 0
                ? ChartColor.LightPurple
                : ChartColor.LightRose;
            },
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [isMobile, onRenderTooltipValue, source]);

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: ChartAxisName.mCP_WKg,
          tooltipProps: {
            content: t('label.last_n_days', { d: 90 }),
            placement: 'bottom-end',
          },
        }}
        legendProps={{
          show: false,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.CriticalPower,
          }),
        }}
      />
    </ChartWrapper>
  );
};
