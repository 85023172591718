import {
  createRef,
  MouseEvent,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './index.css';
import { ISegmentButton, ISegmentProps } from './types';
import { clsx } from 'clsx';

export const Segment = ({
  activeButton: controlledActiveButton,
  onChange: controlledOnChange,
  buttons,
  className,
  variant = 'dark',
  fullWidth = false,
  size = 'm',
}: ISegmentProps) => {
  const underlineRef = useRef<HTMLDivElement>(null);
  const buttonsRefs = useRef<RefObject<HTMLButtonElement | null>[]>([]);

  const rootClass: string = clsx(
    'analog-segment',
    `analog-segment--${variant}`,
    `analog-segment--${size}`,
    fullWidth && `analog-segment--fullWidth`,
    className
  );

  const [uncontrolledActiveTab, setUncontrolledActiveTab] = useState<number>(0);

  const uncontrolledOnChange = useCallback((index: number) => {
    setUncontrolledActiveTab(index);
  }, []);

  const [activeButton, onChange] = useMemo(() => {
    return [
      controlledActiveButton || uncontrolledActiveTab,
      controlledOnChange || uncontrolledOnChange,
    ];
  }, [
    controlledActiveButton,
    controlledOnChange,
    uncontrolledActiveTab,
    uncontrolledOnChange,
  ]);

  const setFramePosition = useCallback((element: HTMLButtonElement) => {
    if (underlineRef.current) {
      const width: number = element.offsetWidth;
      const x: number = element.offsetLeft;

      underlineRef.current.style.left = `${x}px`;
      underlineRef.current.style.width = `${width}px`;
    }
  }, []);

  useLayoutEffect(() => {
    if (buttons.length > 0 && buttonsRefs.current[activeButton].current) {
      const element = buttonsRefs.current[activeButton].current;
      if (setFramePosition) {
        setFramePosition(element);
      }
    }
  }, [activeButton, buttons, setFramePosition]);

  const handleChange = useCallback(
    (index: number) => (e: MouseEvent<HTMLButtonElement>) => {
      onChange(index);
      e.currentTarget.blur();
    },
    [onChange]
  );

  const buttonStyles = fullWidth
    ? {
        width: `calc(${100 / buttons.length}% - ${
          (4 * (buttons.length - 1)) / buttons.length
        }px)`,
      }
    : undefined;

  const buttonsJSX = buttons.map((b: ISegmentButton, i: number) => {
    if (!buttonsRefs.current[i]) {
      buttonsRefs.current[i] = createRef();
    }

    const buttonClass: string = clsx(
      'analog-segment__button',
      activeButton === i && 'analog-segment__button--active',
      'regular',
      'analog-typography--body'
    );

    return (
      <button
        key={i}
        className={buttonClass}
        onClick={handleChange(i)}
        ref={buttonsRefs.current[i]}
        tabIndex={0}
        style={buttonStyles}
      >
        {b.onRenderButton ? b.onRenderButton() : b.text}
      </button>
    );
  });

  return (
    <div className={rootClass}>
      {buttonsJSX}
      <div className="analog-segment__slider" ref={underlineRef} />
    </div>
  );
};
