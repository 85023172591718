import './index.css';
import { Skeleton, Tooltip, Typography } from '@cycling-web/analog-ui';
import { ArrowDown, ArrowRight, ArrowUp, Info } from 'lucide-react';
import { EMPTY } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

type IProps = {
  value: number | undefined;
  delta: number | undefined;
  text: string | undefined;
  name: string;
  loading?: boolean;
};

export const TrainingLoadCard = ({
  name,
  value,
  delta,
  text,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const icon = useMemo(() => {
    if (delta === undefined) {
      return null;
    }

    if (delta > 0) {
      return (
        <ArrowUp
          className="athlete-profile__training-load-delta-up"
          data-testid="arrow-up-icon"
          size={32}
        />
      );
    }

    if (delta < 0) {
      return (
        <ArrowDown
          className="athlete-profile__training-load-delta-down"
          data-testid="arrow-down-icon"
          size={32}
        />
      );
    }

    return (
      <ArrowRight
        className="athlete-profile__training-load-delta-right"
        data-testid="arrow-right-icon"
        size={32}
      />
    );
  }, [delta]);

  const deltaValue = useMemo(() => {
    if (delta === undefined) {
      return EMPTY;
    }

    if (delta > 0) {
      return `+${delta}%`;
    }

    if (delta < 0) {
      return `${delta}%`;
    }

    return t('label.plateau');
  }, [delta, t]);

  if (loading) {
    return (
      <div className="athlete-profile__training-load">
        <div className="athlete-profile__training-load-header">
          <Skeleton width="80px" height="26px" />
        </div>

        <div className="athlete-profile__training-load-feedback">
          <Skeleton width="32px" height="26px" />
        </div>
      </div>
    );
  }

  return (
    <div className="athlete-profile__training-load">
      <div className="athlete-profile__training-load-header">
        <Typography
          className="athlete-profile__training-load-name"
          weight="bold"
          variant="headline"
          text={name || EMPTY}
        />
        {text && (
          <Tooltip
            anchor={
              <Info className="athlete-profile__training-load-info" size={16} />
            }
            content={text}
            maxWidth="220px"
          />
        )}
        {value !== undefined && (
          <Typography
            className="athlete-profile__training-load-value"
            weight="regular"
            variant="h4"
            text={value.toFixed(0)}
          />
        )}
      </div>

      <div className="athlete-profile__training-load-feedback">
        <Typography
          className="athlete-profile__training-load-feedback-value"
          weight="regular"
          variant="h4"
          text={deltaValue}
        />
        {icon}
      </div>
    </div>
  );
};
