import './index.css';
import { IButtonVariant, IconButton } from '@cycling-web/analog-ui';
import { Send } from 'lucide-react';
import { use, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConversationContext } from '../../context/ConversationContext';
import {
  ConversationState,
  IConversationInputProps,
  IConversationInputVariant,
} from '../../types';
import { RecordingButton } from '../RecordingButton';
import { VoiceRecordingContextProvider } from '../../context/VoiceRecordingContext';
import { clsx } from 'clsx';
import { RecordingTimer } from '../RecordingTimer';
import { StopRecordingButton } from '../StopRecordingButton';
import { ConversationTextEditor } from '../ConversationTextEditor';
import { $getRoot, CLEAR_EDITOR_COMMAND, LexicalEditor } from 'lexical';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IMentions } from '../ConversationTextEditor/types';
import { MentionOption } from '../MentionOption';
import { useGroupsStore } from '../../../../store/groups/slice';
import { useTranslation } from 'react-i18next';
import { RecordingAnimation } from '../RecordingAnimation';
import { IMentionOption } from '../ConversationTextEditor/mention/types';
import { IAutocompleteProps } from '../ConversationTextEditor/autocomplete/types';
import uniqBy from 'lodash/uniqBy';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';

const reverseName = (name: string): string => {
  return name.split(' ').reverse().join(' ');
};

const getUniqueMentions = (mentions: IMentionOption[]) => {
  return uniqBy(
    mentions.map((mention) => {
      const id = Number(mention.id);

      if (id < 0) {
        return {
          ...mention,
          id: Math.abs(id).toString(),
          text: reverseName(mention.text),
        };
      }

      return mention;
    }),
    'id'
  );
};

export const ConversationInput = ({
  variant = 'layer2',
  placeholder,
  editorRef: outsideEditorRef,
  onChange,
  onSendText,
  onSendAudio,
  autofocus,
}: IConversationInputProps) => {
  const { t } = useTranslation();
  const { conversationState } = use(ConversationContext);
  const localEditorRef = useRef<LexicalEditor | null>(null);
  const editorRef = outsideEditorRef || localEditorRef;

  const conversationStateRef = useRef<ConversationState | null>(null);
  const [showRecordButton, setShowRecordButton] = useState<boolean>(true);
  const athletes = useAthletesStore((s) => s.athletes);
  const groups = useGroupsStore((s) => s.groups);

  const mentionsRef = useRef<IMentionOption[]>([]);

  const onMentionAdd = useCallback((option: IMentionOption) => {
    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.APPLY_MENTION,
    });
    mentionsRef.current.push(option);
  }, []);

  const onMentionRemove = useCallback((option: IMentionOption) => {
    mentionsRef.current = mentionsRef.current.filter((o) => {
      return !(o.id === option.id && o.text === option.text);
    });
  }, []);

  const mentions = useMemo((): IMentions => {
    return {
      '@': {
        options: [
          {
            id: 'athletes-heading',
            text: t('label.athletes'),
            type: 'heading',
          },
          ...athletes.map((a) => {
            return {
              id: a.id,
              text: a.fullName,
              entity: 'athlete',
            };
          }),
          {
            id: 'groups-heading',
            text: t('label.groups'),
            type: 'heading',
          },
          ...groups.map((g) => {
            return {
              id: g.id,
              text: g.name,
              entity: 'group',
              description: g.description,
              useDefaultRenderer: true,
            };
          }),
        ],
        onRenderOption: (option, selected) => {
          return <MentionOption option={option} selected={selected} />;
        },
      },
    };
  }, [athletes, groups, t]);

  const autocompleteProps: IAutocompleteProps = useMemo(() => {
    const athleteOptions = athletes.map((a) => {
      return {
        id: a.id,
        text: a.fullName,
        entity: 'athlete',
      };
    });
    const reverseAthleteOptions = athletes.map((a) => {
      return {
        /** Reversed athlete names have negative id (since id is a number) */
        id: -a.id,
        text: reverseName(a.fullName),
        entity: 'athlete',
      };
    });
    const groupOptions = groups.map((a) => {
      return {
        id: a.id,
        text: a.name,
        entity: 'group',
      };
    });
    return {
      options: [...athleteOptions, ...reverseAthleteOptions, ...groupOptions],
    };
  }, [athletes, groups]);

  useEffect(() => {
    conversationStateRef.current = conversationState;
  }, [conversationState]);

  const handleChange = useCallback(
    (text: string) => {
      if (onChange) {
        onChange(text);
      }
      setShowRecordButton(text.length === 0);
    },
    [onChange]
  );

  const handleSendMessage = useCallback(() => {
    const state = editorRef.current?.getEditorState();
    if (
      !state ||
      conversationStateRef.current === ConversationState.PROCESSING
    ) {
      return;
    }

    state.read(() => {
      const text = $getRoot()
        .getTextContent()
        // TODO: Super lame, but works for now
        .replace(t('placeholder.mention_hint'), '')
        .trim();

      if (text.length === 0) {
        return;
      }

      onSendText(text, {
        mentions: getUniqueMentions(mentionsRef.current),
      }).then(() => {
        mentionsRef.current = [];
      });
      if (editorRef.current) {
        editorRef.current.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      }
    });
  }, [editorRef, onSendText, t]);

  const rootClass = clsx(
    'conversation__footer',
    `conversation__footer--${variant}`
  );

  const textareaWrapperClass = clsx(
    'conversation__footer-textarea-wrapper',
    conversationState === ConversationState.LISTENING &&
      'conversation__footer-textarea-wrapper--listening'
  );

  const buttonVariant: Record<IConversationInputVariant, IButtonVariant> = {
    layer1: 'quietLayer3',
    layer2: 'quietLayer2',
    layer3: 'quietLayer2',
  };

  return (
    <VoiceRecordingContextProvider onSendAudio={onSendAudio}>
      <div className={rootClass}>
        <div className={textareaWrapperClass}>
          <div className="conversation__footer-textarea-container">
            <ConversationTextEditor
              editorRef={editorRef}
              onChange={handleChange}
              onSubmit={handleSendMessage}
              mentions={{
                mentions,
                onMentionAdd,
                onMentionRemove,
              }}
              autocompleteProps={autocompleteProps}
              autofocus={autofocus}
            />
          </div>
          {conversationState === ConversationState.LISTENING && (
            <div className="conversation__voice-recording-timer">
              <StopRecordingButton variant={variant} />
              <RecordingTimer />
              <RecordingAnimation />
            </div>
          )}
          <div className="conversation__footer-actions">
            {!showRecordButton ? (
              <IconButton
                variant={buttonVariant[variant] || 'quietLayer2'}
                content={<Send />}
                onClick={handleSendMessage}
              />
            ) : (
              <RecordingButton variant={variant} />
            )}
          </div>
        </div>
      </div>
    </VoiceRecordingContextProvider>
  );
};
