import type { ForwardedRef, MouseEvent, RefObject } from 'react';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import './index.css';
import {
  IButtonBaseProps,
  IButtonNativeProps,
  IButtonSize,
  IButtonSpinnerPosition,
  IButtonVariant,
} from './types';
import type { ISpinnerProps, ISpinnerSize } from '../Spinner/types';
import { clsx } from 'clsx';
import { Spinner } from '../Spinner';
import { ButtonAdornmentIcon } from './ButtonAdornmentIcon';
import { Typography } from '../Typography';
import { ITypographyVariant } from '../Typography/types';

export const BaseButton = forwardRef(
  (props: IButtonBaseProps, buttonRef: ForwardedRef<HTMLButtonElement>) => {
    const localButtonRef = useRef<HTMLButtonElement>(null);
    const ref = (buttonRef || localButtonRef) as RefObject<HTMLButtonElement>;

    const type = props.type || 'button';
    const buttonVariant: IButtonVariant = props.variant || 'primary';
    const isIcon = !!props.isIcon;
    const size: IButtonSize = props.size || 'm';
    const spinnerDefaultSize: ISpinnerSize =
      size === 'xs' || size === 's' ? 'xs' : 's';
    const spinnerPosition: IButtonSpinnerPosition = isIcon
      ? 'center'
      : props.spinnerPosition || 'center';
    const { onClick, allowClickOnLoading, loading, disabled } = props;

    const rootClass: string = clsx(
      'analog-button',
      `analog-button--${buttonVariant}`,
      `analog-button--${size}`,
      isIcon && 'analog-button--icon',
      loading && spinnerPosition === 'center' && 'analog-button--loading',
      disabled && 'analog-button--disabled',
      props.fullWidth && 'analog-button--fullWidth',
      props.className
    );

    const textClass: string = isIcon
      ? 'analog-button__icon'
      : 'analog-button__text';

    const textVariant: Record<IButtonSize, ITypographyVariant> = {
      xs: 'button-s',
      s: 'button-s',
      m: 'button-m',
      l: 'button-m',
    };

    const nativeProps: IButtonNativeProps = useMemo(() => {
      const nativeProps: IButtonBaseProps = { ...props };
      delete nativeProps.loading;
      delete nativeProps.spinnerPosition;
      delete nativeProps.variant;
      delete nativeProps.content;
      delete nativeProps.size;
      delete nativeProps.startIcon;
      delete nativeProps.endIcon;
      delete nativeProps.fullWidth;
      delete nativeProps.allowClickOnLoading;
      delete nativeProps.isIcon;
      return nativeProps;
    }, [props]);

    const spinnerProps: Record<IButtonVariant, ISpinnerProps> = {
      primary: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'dark',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      secondary: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'primary',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      layer1: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      layer2: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      layer3: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      layer4: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      accent: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'primary',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      quiet: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'primary',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      quietLayer2: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      quietLayer3: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      quietLayer4: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'white',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
      link: {
        variant: props.disabled
          ? 'neutral'
          : props.spinnerProps?.variant || 'primary',
        size: props.spinnerProps?.size || spinnerDefaultSize,
      },
    };

    const handleClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (!allowClickOnLoading && loading) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }

        if (onClick) {
          onClick(e);
        }
      },
      [onClick, allowClickOnLoading, loading]
    );

    const showStartIcon: boolean =
      !isIcon &&
      props.startIcon !== undefined &&
      !(spinnerPosition === 'start' && loading === true);
    const showEndIcon: boolean =
      !isIcon &&
      props.endIcon !== undefined &&
      !(spinnerPosition === 'end' && loading === true);
    const showStartSpinner: boolean =
      spinnerPosition === 'start' && loading === true;
    const showCenterSpinner: boolean =
      spinnerPosition === 'center' && loading === true;
    const showEndSpinner: boolean =
      spinnerPosition === 'end' && loading === true;

    return (
      <button
        {...nativeProps}
        type={type}
        className={rootClass}
        ref={ref}
        onClick={handleClick}
        aria-busy={loading}
        aria-live="polite"
        aria-disabled={props.disabled}
      >
        {showStartSpinner && (
          <div className="analog-button__spinner">
            <Spinner {...spinnerProps[buttonVariant]} />
          </div>
        )}
        {showStartIcon && (
          <span className="analog-button__icon analog-button__startIcon">
            <ButtonAdornmentIcon icon={props.startIcon} size={size} />
          </span>
        )}
        <Typography
          as="span"
          variant={textVariant[size]}
          className={textClass}
          text={props.content}
        />
        {showEndIcon && (
          <span className="analog-button__icon analog-button__endIcon">
            <ButtonAdornmentIcon icon={props.endIcon} size={size} />
          </span>
        )}
        {showCenterSpinner && <Spinner {...spinnerProps[buttonVariant]} />}
        {showEndSpinner && (
          <div className="analog-button__spinner">
            <Spinner {...spinnerProps[buttonVariant]} />
          </div>
        )}
      </button>
    );
  }
);

BaseButton.displayName = 'BaseButton';
