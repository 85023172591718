import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ITrainingLoadFeedback } from '../../../types/performance';
import { useParams } from 'react-router';
import { useAthleteProfileStore } from '../store/slice';

export const useFetchTrainingLoadFeedback = () => {
  const { athleteId } = useParams();
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const setTrainingLoadFeedback = useAthleteProfileStore(
    (s) => s.setTrainingLoadFeedback
  );
  const setTrainingLoadFeedbackLoaded = useAthleteProfileStore(
    (s) => s.setTrainingLoadFeedbackLoaded
  );

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  useEffect(() => {
    if (!athleteId || fetching.current) {
      return;
    }

    fetching.current = true;

    performanceRepository
      .getTrainingLoadFeedback({
        athleteId: +athleteId,
      })
      .then((data: ITrainingLoadFeedback) => {
        setTrainingLoadFeedback(data);
      })
      .catch(() => {
        setTrainingLoadFeedbackLoaded(true);
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [
    athleteId,
    performanceRepository,
    setTrainingLoadFeedback,
    setTrainingLoadFeedbackLoaded,
    t,
  ]);
};
