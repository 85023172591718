import './index.css';
import { CalendarHeader } from '../CalendarHeader';
import { WeekHeader } from '../WeekHeader';
import { CalendarDays } from '../CalendarDays';
import { use, useLayoutEffect, useRef } from 'react';
import { WorkoutsCalendarContext } from '../../context';
import { isSameMonth } from 'date-fns';
import { TrainingZonesModal } from '../../../../components/TrainingPlanModal';
import { useParams } from 'react-router';

export const Calendar = () => {
  const { athleteId } = useParams();
  const { date, showTrainingPlanModal, dismissTrainingPlanModal } = use(
    WorkoutsCalendarContext
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isSameMonth(new Date(), date)) {
      scrollContainerRef.current?.scrollTo(0, 0);
    }
  }, [date]);

  return (
    <div className="workouts-calendar">
      <CalendarHeader />
      <div className="workouts-calendar__calendar" ref={scrollContainerRef}>
        <WeekHeader />
        <CalendarDays />
      </div>

      {showTrainingPlanModal && (
        <TrainingZonesModal
          onDismiss={dismissTrainingPlanModal}
          athleteId={athleteId}
        />
      )}
    </div>
  );
};
