import { IAthleteWellnessSlice, IAthleteWellnessState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  IBodyComposition,
  IHeartMeasurement,
  ISelfReportedWellness,
  IWellness,
} from '../../../types/wellness';
import merge from 'lodash/merge';
import { DeepPartial } from '@cycling-web/common';

export const initialState: IAthleteWellnessState = {
  wellness: null,
  wellnessLoaded: false,
  selfReportedWellness: null,
  selfReportedWellnessLoaded: false,
  bodyComposition: null,
  bodyCompositionLoaded: false,
  heartMeasurements: null,
  heartMeasurementsLoaded: false,
};

export const useAthleteWellnessStore = create<IAthleteWellnessSlice>()(
  immer((set) => ({
    ...initialState,
    setWellness: (wellness: IWellness | null) => {
      set((state) => {
        state.wellness = wellness;
        state.wellnessLoaded = true;
      });
    },
    updateWellness: (wellness: DeepPartial<IWellness>) => {
      set((state) => {
        if (state.wellness) {
          state.wellness = merge(state.wellness, wellness);
        }
      });
    },
    setWellnessLoaded: (wellnessLoaded: boolean) => {
      set((state) => {
        state.wellnessLoaded = wellnessLoaded;
      });
    },
    setSelfReportedWellness: (
      selfReportedWellness: ISelfReportedWellness | null
    ) => {
      set((state) => {
        state.selfReportedWellness = selfReportedWellness;
        state.selfReportedWellnessLoaded = true;
      });
    },
    setSelfReportedWellnessLoaded: (loaded: boolean) => {
      set((state) => {
        state.selfReportedWellnessLoaded = loaded;
      });
    },
    setBodyComposition: (bodyComposition: IBodyComposition | null) => {
      set((state) => {
        state.bodyComposition = bodyComposition;
        state.bodyCompositionLoaded = true;
      });
    },
    setBodyCompositionLoaded: (loaded: boolean) => {
      set((state) => {
        state.bodyCompositionLoaded = loaded;
      });
    },
    setHeartMeasurements: (heartMeasurements: IHeartMeasurement | null) => {
      set((state) => {
        state.heartMeasurements = heartMeasurements;
        state.heartMeasurementsLoaded = true;
      });
    },
    setHeartMeasurementsLoaded: (loaded: boolean) => {
      set((state) => {
        state.heartMeasurementsLoaded = loaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
