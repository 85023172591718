import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, FormElement, Toast } from '@cycling-web/analog-ui';
import {
  SegmentControl,
  TextareaControl,
  DatepickerControl,
  SelectSingleControl,
} from '@cycling-web/common';
import { IAthlete } from '../../types/athletes';
import {
  IMedicalReport,
  IReportRehabilitationFormData,
} from '../../types/medical';
import { MedicalRepository } from '../../api/medical/repository';
import { MedicalService } from '../../api/medical/service';
import { useUsersStore } from '../../store/users/slice';
import { addWeeks } from 'date-fns';
import { BodyMapView } from './BodyMapView';
import { useDictionaryOptions } from '../../hooks/useDictionaryOptions';
import { SelectMultiControl } from '@cycling-web/common';
import { trackUserInteractionEvent } from '../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../ms/tracking-entities';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
  onSubmitSuccess?: (report: IMedicalReport) => void;
};

export const ReportRehabilitationFormModal = ({
  athlete,
  onDismiss,
  onSubmitSuccess,
}: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);

  const bodyLocationOptions = useDictionaryOptions('bodyLocation');
  const sideOptions = useDictionaryOptions('side');
  const rehabilitationStageOptions = useDictionaryOptions(
    'rehabilitationStage'
  );
  const typeOptions = useDictionaryOptions('type');
  const modalityUsedOptions = useDictionaryOptions('modalityUsed');
  const assessmentToolsOptions = useDictionaryOptions('assessmentTools');

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        onsetDate: z.date(),
        estimatedRecovery: z.date(),
        side: z.string().min(1, { message: t('validation.required') }),
        bodyLocation: z.string().min(1, { message: t('validation.required') }),
        rehabilitationStage: z
          .string()
          .min(1, { message: t('validation.required') }),
        type: z.string().min(1, { message: t('validation.required') }),
        assessmentTools: z
          .array(z.string())
          .nonempty({ message: t('validation.required') }),
        modalityUsed: z
          .array(z.string())
          .nonempty({ message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IReportRehabilitationFormData>({
    defaultValues: {
      id: userProfile?.coachId || -1,
      athleteId: athlete.id,
      recordType: 'PHYSIO',
      onsetDate: new Date(),
      estimatedRecovery: addWeeks(new Date(), 1),
      side: 'LEFT',
      bodyLocation: '',
      rehabilitationStage: '',
      type: '',
      assessmentTools: [],
      modalityUsed: [],
      note: '',
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IReportRehabilitationFormData) => {
      setLoading(true);
      medicalRepository
        .reportRehabilitation(formData)
        .then((report: IMedicalReport) => {
          if (onSubmitSuccess) {
            onSubmitSuccess(report);
          }
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.create_rehabilitation_report_title'),
              message: t('error.create_rehabilitation_report_message'),
            },
            { toastId: 'create_rehabilitation_report' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [medicalRepository, onDismiss, onSubmitSuccess, t]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.REPORT_REHABILITATION,
    });

    handleSubmit(
      (formData: IReportRehabilitationFormData) => {
        handleSave(formData);
      },
      (errors) => console.log(errors)
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.report_rehabilitation')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="report-rehabilitation-form-modal">
          <div className="report-rehabilitation-form-modal__row">
            <div className="report-rehabilitation-form-modal__form">
              <FormElement
                label={t('label.body_location')}
                message={errors.bodyLocation?.message}
              >
                <SelectSingleControl
                  name="bodyLocation"
                  options={bodyLocationOptions}
                  placeholder={t('placeholder.body_location')}
                  invalid={!!errors.bodyLocation}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.side')}
                message={errors.side?.message}
              >
                <SegmentControl name="side" buttons={sideOptions} fullWidth />
              </FormElement>

              <FormElement
                label={t('label.rehabilitation_stage')}
                message={errors.rehabilitationStage?.message}
              >
                <SelectSingleControl
                  name="rehabilitationStage"
                  options={rehabilitationStageOptions}
                  placeholder={t('placeholder.rehabilitation_stage')}
                  invalid={!!errors.rehabilitationStage}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.type')}
                message={errors.type?.message}
              >
                <SelectSingleControl
                  name="type"
                  options={typeOptions}
                  placeholder={t('placeholder.type')}
                  invalid={!!errors.type}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.modality_used')}
                message={errors.modalityUsed?.message}
              >
                <SelectMultiControl
                  name="modalityUsed"
                  options={modalityUsedOptions}
                  placeholder={t('placeholder.modality_used')}
                  invalid={!!errors.modalityUsed}
                  tags
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.assessment_tools')}
                message={errors.assessmentTools?.message}
              >
                <SelectMultiControl
                  name="assessmentTools"
                  options={assessmentToolsOptions}
                  placeholder={t('placeholder.assessment_tools')}
                  invalid={!!errors.assessmentTools}
                  tags
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.onset_date')}
                message={errors.onsetDate?.message}
              >
                <DatepickerControl
                  name="onsetDate"
                  placeholder={t('placeholder.onset_date')}
                  invalid={!!errors.onsetDate}
                />
              </FormElement>

              <FormElement
                label={t('label.estimate_recovery_date')}
                message={errors.estimatedRecovery?.message}
              >
                <DatepickerControl
                  name="estimatedRecovery"
                  placeholder={t('placeholder.estimate_recovery_date')}
                  invalid={!!errors.estimatedRecovery}
                />
              </FormElement>

              <FormElement
                label={t('label.note')}
                message={errors.note?.message}
              >
                <TextareaControl
                  name="note"
                  placeholder={t('placeholder.note')}
                  maxLength={999}
                  invalid={!!errors.note}
                />
              </FormElement>
            </div>

            <div className="report-rehabilitation-form-modal__body-map">
              <BodyMapView />
            </div>
          </div>
        </div>
      </Dialog>
    </FormProvider>
  );
};
