import { createContext, use } from 'react';
import { IWorkoutsCalendarContext } from '../types';

export const WorkoutsCalendarContext = createContext<IWorkoutsCalendarContext>(
  {} as IWorkoutsCalendarContext
);

export const useWorkoutsCalendarContext = () => {
  return use(WorkoutsCalendarContext);
};
