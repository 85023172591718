import { CSSProperties, ReactNode, useContext, useMemo } from 'react';
import {
  ISelectContentViewModel,
  ISelectOption,
  ISelectTranslations,
  ISingleSelectOnChange,
  OptionType,
} from './types';
import { SelectOption } from './SelectOption';
import { FixedSizeList as List } from 'react-window';
import { useSelectSearch } from './useSelectSearch';
import { SelectContext } from './context';
import { DropdownContext } from '../Dropdown/context';

export const useViewModelContent = (): ISelectContentViewModel => {
  const {
    options,
    localValue,
    multiple,
    setIsOpen,
    onChange,
    onRenderOption,
    virtualScroll,
    translations,
  } = useContext(SelectContext);
  const { activeIndex, navigationRef, getItemProps, contentWidth } =
    useContext(DropdownContext);
  const { searchValue, filteredOptions, handleSearch, handleClearSearch } =
    useSelectSearch(options);

  const translationsText: ISelectTranslations = {
    emptyContent: translations?.emptyContent || 'Nothing found',
  };

  const selectedOptions: Record<string, boolean> = useMemo(() => {
    const selectedOptions: Record<string, boolean> = {};
    const onlyOptionTypeValues: ISelectOption[] = localValue.filter(
      (option: ISelectOption) =>
        option.type === OptionType.Option || option.type === undefined
    );

    for (let i = 0; i < onlyOptionTypeValues.length; i++) {
      const id = onlyOptionTypeValues[i].id;
      selectedOptions[id] = true;
    }

    return selectedOptions;
  }, [localValue]);

  const handleSelect = (index: number): void => {
    let nextValues: ISelectOption[] = [...localValue];
    const option: ISelectOption = filteredOptions[index];

    if (!multiple) {
      (onChange as ISingleSelectOnChange)(option);
      setIsOpen(false);
      return;
    }

    const indexInValues: number = nextValues.findIndex(
      (value: ISelectOption) => value.id === option.id
    );

    if (indexInValues < 0) {
      nextValues.push(option);
    } else {
      nextValues = nextValues.filter(
        (value: ISelectOption) => value.id !== option.id
      );
    }

    onChange(nextValues, option);
  };

  const emptyListJSX: ReactNode = (
    <div className="analog-select__empty-list analog-typography--body">
      {translationsText.emptyContent}
    </div>
  );

  function getScrollContent(): ReactNode {
    if (filteredOptions.length === 0) {
      return emptyListJSX;
    }

    return filteredOptions.map((option: ISelectOption, index: number) => {
      return (
        <SelectOption
          key={option.id}
          option={option}
          index={index}
          active={index === activeIndex}
          selected={selectedOptions[option.id]}
          handleSelect={handleSelect}
          ref={navigationRef(index)}
          getItemProps={getItemProps}
          onRenderOption={onRenderOption}
        />
      );
    });
  }

  function getVirtualScrollContent(): ReactNode {
    if (filteredOptions.length === 0) {
      return emptyListJSX;
    }

    const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
      return (
        <SelectOption
          key={filteredOptions[index].id}
          option={filteredOptions[index]}
          index={index}
          active={index === activeIndex}
          selected={selectedOptions[filteredOptions[index].id]}
          handleSelect={handleSelect}
          ref={navigationRef(index)}
          getItemProps={getItemProps}
          style={style}
        />
      );
    };

    return (
      <List
        height={400}
        itemCount={filteredOptions.length}
        itemSize={36}
        width={contentWidth}
      >
        {Row}
      </List>
    );
  }

  return {
    getContent: virtualScroll ? getVirtualScrollContent : getScrollContent,
    searchValue,
    handleSearch,
    handleClearSearch,
    filteredOptions,
  };
};
