import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Environment, getEnvironment } from '../utils/getEnvironment';
import { LS } from '../constants';

let appInsights: ApplicationInsights | undefined = undefined;

const cookiesLS = localStorage.getItem(LS.Cookies);
const cookies = cookiesLS ? JSON.parse(cookiesLS) : null;
const analyticalConsent = cookies?.optional?.analytical ?? false;

if (getEnvironment() === Environment.Production) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: import.meta.env.VITE_APPINSIGHTS_CONNECTION_STRING,
      autoTrackPageVisitTime: true,
      maxBatchInterval: 5000,
      enableAutoRouteTracking: true,
      disableTelemetry: !analyticalConsent,
      disableCookiesUsage: !analyticalConsent,
      isStorageUseDisabled: !analyticalConsent,
      cookieCfg: {
        enabled: false,
      },
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { appInsights };
