import './index.css';
import { Skeleton, Typography } from '@cycling-web/analog-ui';
import { EMPTY } from '../../../../../../constants';

type IProps = {
  name: string;
  value: number | string | undefined;
  loading?: boolean;
};

export const ThisWeekCard = ({ name, value, loading }: IProps) => {
  if (loading) {
    return (
      <div className="athlete-profile__this-week">
        <div className="athlete-profile__this-week-header">
          <Skeleton width="80px" height="26px" />
        </div>

        <div className="athlete-profile__this-week-feedback">
          <Skeleton width="32px" height="26px" />
        </div>
      </div>
    );
  }

  return (
    <div className="athlete-profile__this-week">
      <div className="athlete-profile__this-week-header">
        <Typography
          className="athlete-profile__this-week-name"
          weight="bold"
          variant="headline"
          text={name || EMPTY}
        />
      </div>

      <div className="athlete-profile__this-week-feedback">
        <Typography
          className="athlete-profile__this-week-feedback-value"
          weight="regular"
          variant="h4"
          text={value}
        />
      </div>
    </div>
  );
};
