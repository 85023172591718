import { PageFilters } from '@cycling-web/common';
import { useCallback } from 'react';
import { usePageFilters } from '../../../../context/PageFilters';
import { IAthletesFilters } from '../../../../types/athletes';

export const AthleteFilters = () => {
  const { filters, handleChangeFilter, removeFilter } =
    usePageFilters<IAthletesFilters>();

  const handleClearSearch = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
    />
  );
};
