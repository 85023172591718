import { useWorkoutStore } from './store/slice';
import { useEffect, useMemo, useState } from 'react';
import { ROUTES } from '../../router/routes';
import { useLocation } from 'react-router';
import { useAthletesStore } from '../../store/athletes/slice';
import { LS } from '../../constants';
import { useFetchWorkout } from './hooks/useFetchWorkout';
import { useFetchWorkoutAnalysis } from './hooks/useFetchWorkoutAnalysis';
import { useFetchDayWorkouts } from './hooks/useFetchDayWorkouts';
import { mapWorkoutsAndPrescriptions } from '../Workouts/utils/mapPrescriptionsToWorkouts';
import { IWorkoutCalendarWorkout } from '../../types/workouts';
import { useFetchTrainingZones } from '../../hooks/useFetchTrainingZones';
import { parseAthleteProfiles } from '../AthleteProfile/utils/profiles';

export const useViewModel = () => {
  useFetchWorkout();
  const { fetchWorkoutAnalysis } = useFetchWorkoutAnalysis();
  const { fetchDayWorkouts } = useFetchDayWorkouts();
  const { fetchTrainingZones } = useFetchTrainingZones();
  const { pathname } = useLocation();

  const [calendarWorkout, setCalendarWorkout] =
    useState<IWorkoutCalendarWorkout | null>(null);
  const [calendarWorkoutLoaded, setCalendarWorkoutLoaded] =
    useState<boolean>(false);

  const selectedLaps = useWorkoutStore((s) => s.selectedLaps);
  const positionOnMap = useWorkoutStore((s) => s.positionOnMap);

  const workout = useWorkoutStore((s) => s.workout);
  const workoutLoaded = useWorkoutStore((s) => s.workoutLoaded);

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const athlete = useMemo(() => {
    const athleteId = workout?.Athlete.AthleteId;

    if (!athleteId) {
      return undefined;
    }

    return athletes.find(
      (athlete) => athlete.id.toString() === athleteId?.toString()
    );
  }, [workout, athletes]);

  const backUrl = useMemo(() => {
    const prevPage = localStorage.getItem(LS.PreviousPage);

    if (prevPage) {
      return prevPage;
    }

    const tmp = pathname.split('/');
    const index = tmp.findIndex((s) => s === ROUTES.WORKOUT);

    if (index >= 0) {
      const tmp1 = tmp.filter((_, i) => i < index);
      tmp1.push(ROUTES.CALENDAR);
      if (athlete?.id) {
        tmp1.push(athlete.id.toString());
      }
      return tmp1.join('/');
    }

    return '';
  }, [athlete, pathname]);

  const gpxData = useMemo(() => {
    if (!workout?.WorkoutDetail?.WorkoutChannels?.Channels) {
      return [];
    }

    const channels = workout.WorkoutDetail.WorkoutChannels.Channels;
    let latIndex = -1;
    let longIndex = -1;

    for (let i = 0; i < channels.length; i++) {
      if (channels[i] === 'PositionLat') {
        latIndex = i;
      }
      if (channels[i] === 'PositionLong') {
        longIndex = i;
      }
    }

    if (latIndex === -1 || longIndex === -1) {
      return [];
    }

    return workout.WorkoutDetail.WorkoutChannels.Data.map((item) => {
      return {
        latitude: item.Values[latIndex],
        longitude: item.Values[longIndex],
      };
    });
  }, [workout]);

  useEffect(() => {
    if (!workout?.WorkoutDetail?.WorkoutId) {
      return;
    }

    fetchWorkoutAnalysis(
      +workout.WorkoutDetail.WorkoutId,
      +workout.Athlete.AthleteId
    );
  }, [fetchWorkoutAnalysis, workout]);

  useEffect(() => {
    if (!athlete || !workout) {
      return;
    }
    fetchDayWorkouts(workout.Workout.WorkoutDay, athlete.id)
      .then((response) => {
        const calendar = mapWorkoutsAndPrescriptions(response);
        const workouts: IWorkoutCalendarWorkout[] =
          Object.values(calendar)?.[0]?.workouts || [];
        const calendarWorkout = workouts.find(
          (item) => item.workoutId.toString() === workout.Workout.Id.toString()
        );
        if (calendarWorkout) {
          setCalendarWorkout(calendarWorkout);
        }
        setCalendarWorkoutLoaded(true);
      })
      .catch(() => {
        setCalendarWorkoutLoaded(true);
      });
  }, [fetchDayWorkouts, athlete, workout]);

  useEffect(() => {
    if (!workout && workoutLoaded) {
      setCalendarWorkoutLoaded(true);
    }
  }, [calendarWorkout, workout, workoutLoaded]);

  useEffect(() => {
    if (!athlete) {
      return;
    }

    fetchTrainingZones(athlete.id)
      .then((response) => {
        useWorkoutStore
          .getState()
          .setTrainingZoneProfiles(parseAthleteProfiles(response));
      })
      .catch(() => {
        useWorkoutStore.getState().setTrainingZoneProfilesLoaded(true);
      });
  }, [athlete, fetchTrainingZones]);

  const laps = useMemo((): GeoJSON.Feature[] => {
    if (
      !workout?.WorkoutDetail?.WorkoutChannels?.Channels ||
      !workout?.WorkoutDetail?.WorkoutChannels?.Data ||
      !selectedLaps?.length
    ) {
      return [];
    }

    const channels = workout.WorkoutDetail.WorkoutChannels.Channels;
    const data = workout.WorkoutDetail.WorkoutChannels.Data;

    const latIndex = channels.findIndex((c) => c === 'PositionLat');
    const longIndex = channels.findIndex((c) => c === 'PositionLong');

    if (latIndex === -1 || longIndex === -1) {
      return [];
    }

    return selectedLaps.map((lap) => {
      const coordinates: [number, number][] = [];

      for (const item of data) {
        if (
          item.MillisecondOffset >= lap.StartTimeMs &&
          item.MillisecondOffset <= lap.EndTimeMs
        ) {
          const lat = item.Values?.[latIndex];
          const lng = item.Values?.[longIndex];
          if (lat != null && lng != null) {
            coordinates.push([lng, lat]);
          }
        }
      }

      return {
        type: 'Feature',
        properties: {
          name: lap.Name,
          start: lap.StartTimeMs,
          end: lap.EndTimeMs,
        },
        geometry: {
          type: 'LineString',
          coordinates,
        },
      } as GeoJSON.Feature;
    });
  }, [workout, selectedLaps]);

  useEffect(() => {
    return () => {
      useWorkoutStore.getState().reset();
    };
  }, []);

  return {
    workout,
    workoutLoaded: workoutLoaded && calendarWorkoutLoaded,
    calendarWorkout,
    backUrl,
    athlete,
    athleteLoaded: athletesLoaded,
    gpxData,
    selectedLaps,
    laps,
    positionOnMap,
  };
};
