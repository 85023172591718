import { IAthlete } from '../../../types/athletes';
import { IGroupReportsFilters } from '../../GroupDetails/types';
import { Url } from '../../../utils/url';

export const filterAthletesDelegate = (filters: IGroupReportsFilters) => {
  return (item: IAthlete) => {
    let valid = true;
    const athletes = Url.parseCommaSeparatedIds(filters.athletes);

    if (
      filters.search &&
      !item.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    if (athletes.length > 0 && !athletes.includes(item.id)) {
      valid = false;
    }

    return valid;
  };
};
