import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { useAthletePerformanceStore } from '../store/slice';
import { format } from 'date-fns';
import { IPdCurve, IPdCurveRelative } from '../../../types/performance';
import { calculateDateRange } from '../../../utils/utils';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';

export const useFetchPowerDuration = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const { filters: sharedFilters } = usePageFilters<IAthleteDetailsFilters>();
  const setPowerDuration = useAthletePerformanceStore(
    (s) => s.setPowerDuration
  );
  const setPowerDurationLoaded = useAthletePerformanceStore(
    (s) => s.setPowerDurationLoaded
  );
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || sharedFilters?.period === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(sharedFilters.period);

    const request1 = performanceRepository.getPdCurve({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      athleteId: +athleteId,
    });

    const request2 = performanceRepository.getPdCurveRelative({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      athleteId: +athleteId,
    });

    Promise.all([request1, request2])
      .then((response: [IPdCurve, IPdCurveRelative]) => {
        setPowerDuration(response[0], response[1]);
      })
      .catch((error: AxiosError) => {
        setPowerDurationLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_power_duration_title'),
              message: t('error.get_power_duration_message'),
            },
            { toastId: 'get_power_duration' }
          );
        }
      });
  }, [
    athleteId,
    sharedFilters?.period,
    performanceRepository,
    setPowerDuration,
    setPowerDurationLoaded,
    t,
  ]);
};
