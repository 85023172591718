import { AnyValue } from './common';

/** Pinned Tabs */

export type IPinnedTab = {
  tab_id: string;
  user_id: number;
  tab_name: string;
  tab_type: string;
  tab_graphs: IPinnedTabGraph[];
  created_at: string;
  updated_at: string;
  tab_metadata: IPinnedTabMeta;
};

export type IPinnedTabGraph = {
  tab_graph_relation_id: string;
  tab_graph_relation_metadata: IPinnedTabGraphMetadata;
  tab_id: string;
  graph_id: string;
  user_id: number;
  created_at: string;
  updated_at: string;
};

export type IPinnedTabGraphMetadata = {
  random_value: string;
};

export type IPinnedTabMeta = {
  group_id: number;
  tab_linked_entity: string;
};

/** Smart Charts */

// OLD TYPE
export type IAthleteType = {
  type: string;
  athlete_id: string;
  athlete_full_name: string;
  athlete_image_identifier?: string;
  athlete_flag_identifier?: string;
  athlete_profile_pic?: string | null;
};

export type ISmartChartHistoryGroup = {
  name: string;
  items: ISmartChartConfigBase[];
};

export type ISmartChartConfigBase = {
  id: string;
  state: string;
  schema_version: number;
  ai_graph_meta_data: ISmartChartGraphMetaData;
  ai_graph_type_metadata: ISmartChartGraphTypeMetaData;
  ai_graph_user_metadata: ISmartChartUserMetaData;
  created_at: string;
  updated_at: string;
};

export type ISmartChartConfig = ISmartChartConfigBase & {
  cached_content: null;
  ai_graph_stats: null;
  conversation_turns: ISmartChartConversation;
  ai_graph_in_scope: ISmartChartInScope;
};

export type ISmartChartGraphTypeMetaData = {
  ai_graph_type: string; // 'custom_graph';
};

export type ISmartChartGraphMetaData = {
  title: ISmartChartGraphMetaDataTitle;
};

export type ISmartChartGraphMetaDataTitle = {
  value: string;
  is_ai_generated: boolean;
  is_user_updated: boolean;
};

export type ISmartChartUserMetaData = {
  creation_user: ISmartChartUserMetaDataCreationUser;
  current_user: ISmartChartUserMetaDataCurrentUser;
  shares: [];
};

export type ISmartChartUserMetaDataCreationUser = {
  user_id: number;
};

export type ISmartChartUserMetaDataCurrentUser = {
  user_id: number;
  graph_attributes: ISmartChartUserMetaDataCurrentUserAttributes;
  pinned_information: null;
};

export type ISmartChartUserMetaDataCurrentUserAttributes = {
  folders: string[];
  tags: string[];
};

export type ISmartChartConversation = {
  turns: ISmartChartTurn[];
  page_no: number;
  limit: number;
};

export type ISmartChartInScope = {
  graph_type: string; // 'athlete_level';
  data_sources: ISmartChartInScopeDataSources;
  tagged_entities?: ITaggedEntity[];
  ai_generated_graphs: ISmartChartGraph[];
};

export type ISmartChartInScopeDataSources = {
  datasources_in_all_groups: ISmartChartDataSourceAllGroup[];
  datasource_group_specific: Record<
    string,
    ISmartChartDataSourceSpecificGroup[]
  >;
  datasources_source: string; // 'configuration';
};

export type ISmartChartTurn = {
  id: string;
  actor: SmartChartActor;
  actor_user_id: number;
  created_at: string; // '2024-12-03T17:40:20.382672';
  is_in_progress: boolean;
  message: string;
  next_suggestions: ISmartChartTurnSuggestion[];
  generated_diff_message: string | null;
  turn_subprocesses: ISmartChartTurnSubprocess[];
  ai_graph_snapshot_data: null;
};

export type ISmartChartTurnSuggestion = {
  suggestion_type: string; // 'chat_input_text';
  suggestion_text: string;
  relative_web_url: string | null;
};

export type ISmartChartTurnSubprocess = {
  id: string;
  heading: ISmartChartTurnSubprocessHeading;
  created_at: string;
  icon: string;
  is_inside_execution_scope: boolean;
};

export type ISmartChartTurnSubprocessHeading = {
  value: string;
  is_ai_generated: boolean;
  is_user_updated: boolean;
};

export type ISmartChartDataSourceAllGroup = {
  id: string;
  metadata: {
    type: string; // 'GraphDateSourceMetadata';
    date_name: string; // 'graph_start_date';
    selected_date: string; // '2024-09-04';
  };
  created_at: string; //'2024-12-03T17:40:20.305305';
  is_mutable: boolean;
};

export type ISmartChartDataSourceSpecificGroup = {
  id: string;
  metadata: ISmartChartDataSourceSpecificGroupAthlete;
  created_at: string;
  is_mutable: boolean;
};

export type ISmartChartDataSourceSpecificGroupAthlete = {
  type: string; // 'AthleteDataSourceMetadata';
  athlete_id: string;
  athlete_full_name: string;
  athlete_image_identifier: string;
  athlete_flag_identifier: string;
  athlete_profile_pic: string | null;
};

export type ISmartChartPlot = {
  id: string;
  x_axis_meta_data: ISmartChartPlotAxisMeta;
  y_axis_meta_data: ISmartChartPlotAxisMeta;
  plot_view_metadata: ISmartChartPlotViewMeta;
  created_at: string;
  datasource_group_id: string;
  generated_sql_query: string;
  analysed_and_split_user_query: string;
};

export type ISmartChartPlotAxisMeta = {
  unit: string;
  unit_readable_short_name: string;
  range_automatic_min_value: boolean;
  range_min_value: number;
  range_automatic_max_value: boolean;
  range_max_value: null;
  range_log_scaled: boolean;
};

export type ISmartChartPlotViewMeta = {
  name: string;
  type: string;
  color_name: string;
  is_show_on_legend: boolean;
  stroke_width: null;
  is_area_shaded_plot: boolean;
  is_smoothening: boolean;
  area_shaded_color_name: null;
  line_mark_points_shape: string;
  line_style_type: string;
};

export type ISmartChartAxis = {
  id: string;
  readable_label: string;
  show_on_graph: boolean;
  range_automatic_min_value: boolean;
  range_min_value: null;
  range_automatic_max_value: boolean;
  range_max_value: null;
  range_log_scaled: boolean;
  unit: string;
  unit_readable_short_name: string;
  show_grid_lines: false;
  linked_plot_ids: string[];
};

export type ISmartChartWarning = AnyValue;

export type ISmartChartComputedData = {
  plots_data: ISmartChartComputedDataPlot[];
  x_axes_data: ISmartChartComputedDataAxes[];
  y_axes_data: ISmartChartComputedDataAxes[];
};

export type ISmartChartComputedDataPlot = {
  linked_plot_id: string;
  series_key_value_data: Record<string, AnyValue>;
  name: string;
  readable_name_with_datasource: string;
};

export type ISmartChartComputedDataAxes = {
  linked_axis_ids: string[];
  series_only_values_data: string[] | null;
  series_data_type: string;
  position: string;
  unit: string;
  unit_readable_short_name: string;
};

export type ISmartChartGraphTitle = {
  value: string;
  is_ai_generated: boolean;
  is_user_updated: boolean;
};

export type ISmartChartGraph = {
  title: ISmartChartGraphTitle;
  subtitle: null;
  exclusive_metric_graph_type: string; // 'general_day_wise_metrics';
  plots: ISmartChartPlot[];
  x_axes: ISmartChartAxis[];
  y_axes: ISmartChartAxis[];
  warnings: ISmartChartWarning[];
  computed_data: ISmartChartComputedData;
};

export enum SmartChartActor {
  Assistant = 'assistant',
  User = 'user',
}

export enum SmartChartsAthletes {
  Single = 'single_athlete',
  Multiple = 'multiple_athletes',
}

export enum SmartChartsDataSource {
  Athlete = 'AthleteDataSourceMetadata',
  Date = 'GraphDateSourceMetadata',
}

export type ISmartChartsIntro = {
  welcome_user_line: string;
  welcome_user_activity_line: string;
  welcome_user_ask_hint_line: string;
  next_suggestions: ISmartChartsIntroSuggestion[];
};

export type ISmartChartsIntroSuggestion = {
  suggestion_type: SmartChartsIntroSuggestionType;
  suggestion_text: string;
  suggestion_icon: string;
  suggestion_metadata: ISmartChartsIntroSuggestionMetadata;
};

export enum SmartChartsIntroSuggestionType {
  ChatInputText = 'chat_input_text',
}

export type ISmartChartsIntroSuggestionMetadata = {
  chat_input_text: string;
};

export enum IConversationEntity {
  Athlete = 'athlete',
  Group = 'group',
}

export type ITaggedEntity = {
  entity_type: IConversationEntity;
  entity_id: string;
  user_entry_point: ITaggedEntityUserEntryPoint;
  user_action: ITaggedEntityUserAction;
  entity_metadata: ITaggedEntityMeta;
  linked_tagged_entities: null | ITaggedEntity[];
};

export type ITaggedEntityMeta = {
  type: ITaggedEntityMetaType;
  athlete_id?: string;
  athlete_full_name?: string;
  group_id?: string;
  group_name?: string;
};

export enum ITaggedEntityMetaType {
  Athlete = 'AthleteDataSourceMetadata',
  Group = 'GroupDataSourceMetadata',
}

export type ITaggedEntityUserAction = 'add';

export enum ITaggedEntityUserEntryPoint {
  New = 'new_conversation',
  Existing = 'ask_in_conversation',
}
