import './index.css';
import { ReactNode, useCallback, useMemo } from 'react';
import { ArrowLeft } from 'lucide-react';
import {
  Avatar,
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
} from '@cycling-web/analog-ui';
import { useNavigate } from 'react-router';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '../../context/LayoutContext';

export type IPageHeaderProps = {
  backUrl?: string;
  onBackUrlClick?: () => void;
  title?: ReactNode;
  showHeaderDivider?: boolean;
  menu?: IPageHeaderMenuProps;
  onRenderMenu?: () => ReactNode;
};

type IPageHeaderMenuProps = {
  options: IMenuOption[];
  onMenuChange: (option: IMenuBaseOption) => void;
};

export const PageHeader = ({
  title,
  backUrl,
  onBackUrlClick,
  showHeaderDivider,
  menu,
  onRenderMenu,
}: IPageHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSignOut,
    user,
    userLoaded,
    userMenuOptions,
    handleUserMenuChange,
    leftAdornment,
  } = useLayoutContext();

  const handleBackClick = useCallback(() => {
    if (onBackUrlClick) {
      onBackUrlClick();
    }

    if (backUrl) {
      // TODO: Use LS
      localStorage.removeItem('previous_page');
      navigate(backUrl);
    }
  }, [backUrl, navigate, onBackUrlClick]);

  const rootClass = clsx(
    'page-header',
    showHeaderDivider && 'page-header--border'
  );

  const menuOptions: IMenuOption[] = useMemo(() => {
    const outsideOptions: IMenuOption[] = menu?.options || [];
    const contextOptions: IMenuOption[] = userMenuOptions || [];

    const options: IMenuOption[] = [...outsideOptions, ...contextOptions];

    if (options.length > 0) {
      options.push({
        type: 'divider',
      });
    }

    options.push({
      id: 'sign-out',
      text: t('action.sign_out'),
    });

    return options;
  }, [menu?.options, t, userMenuOptions]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'sign-out') {
        try {
          handleSignOut();
        } catch (e) {
          console.log(e);
        }
      } else {
        if (menu?.onMenuChange) {
          menu.onMenuChange(option);
        }
        if (handleUserMenuChange) {
          handleUserMenuChange(option);
        }
      }
    },
    [handleSignOut, handleUserMenuChange, menu]
  );

  return (
    <header className={rootClass}>
      <div className="page-header__content">
        {backUrl && (
          <IconButton
            variant="quietLayer2"
            content={<ArrowLeft />}
            onClick={handleBackClick}
          />
        )}
        {title && (
          <h4 className="page-header__title analog-typography--h4 bold">
            {title}
          </h4>
        )}
      </div>
      <div className="page-header__left">
        {leftAdornment && (
          <div className="page-header__left-adornment">{leftAdornment}</div>
        )}
        <div className="page-header__actions">
          {onRenderMenu ? (
            onRenderMenu()
          ) : (
            <Menu
              className="page-header__actions-menu"
              options={menuOptions}
              onChange={onMenuChange}
              dropdownProps={{
                anchor: (
                  <Avatar
                    size="m"
                    src={user?.picture}
                    text={user?.fullName}
                    direction="reverse"
                    loading={!userLoaded}
                  />
                ),
                minWidth: '160px',
                placement: 'bottom-end',
              }}
            />
          )}
        </div>
      </div>
    </header>
  );
};
