import { RefObject } from 'react';
import {
  IDatepickerMarker,
  IDatepickerMarkerVariant,
} from '../Datepicker/types';

export type ICalendarProps = ICalendarBaseProps &
  (ICalendarSingleProps | ICalendarRangeProps);

export type ICalendarBaseProps = {
  min?: Date;
  max?: Date;
  className?: string;
  translations?: ICalendarTranslations;
  /** Props from Datepicker */
  isOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
  innerRange?: RefObject<Date[]>;
  currentInnerRangePointer?: RefObject<number>;
  markers?: IDatepickerMarker[];
  /** Hook to subscribe to the current period displayed in the calendar */
  onPeriodChange?: (date: Date) => void;
};

export type ICalendarSingleProps = {
  range?: false;
  value: Date | undefined;
  onChange: (date: Date) => void;
};

export type ICalendarRangeProps = {
  range: true;
  value: Date[];
  onChange: (dates: Date[]) => void;
};

export type ICalendarContext = ICalendarBaseContext & ICalendarProps;

export type ICalendarBaseContext = {
  timePeriod: TimePeriod;
  handleTimePeriodChange: (timePeriod: TimePeriod) => void;
  /** Value to keep track of the current period in the calendar*/
  dateForPeriod: Date;
  onDateForPeriodChange: (value: Date) => void;
  innerValueInit: RefObject<boolean>;
  /** Props from Datepicker */
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  innerRange: RefObject<Date[]>;
  currentInnerRangePointer: RefObject<number>;
  markersMap: Record<number, IDatepickerMarkerVariant>;
};

export interface IMonthDays {
  month: number;
  year: number;
  days: IMonthDay[];
}

export interface IMonthDay {
  date: Date;
  period: MonthPeriod;
}

export enum MonthPeriod {
  Previous = 'previous',
  Current = 'current',
  Next = 'next',
}

export enum TimePeriod {
  Days = 'days',
  Months = 'months',
  Years = 'years',
}

export interface ICalendarTranslations {
  /** An array of translations for months */
  months?: string[];
  /** An array of translations for days of the week */
  week?: string[];
}
