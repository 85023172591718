import './index.css';
import { Button, IconButton, Tooltip } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { SelectedAthletes } from '../SelectedAthletes';
import MenuExpand from '../../assets/menu-expand';
import { useSmartChartsContext } from '../../context';
import { useMediaMatch } from 'rooks';
import { PlusIcon } from 'lucide-react';

export const SmartChartsHeader = () => {
  const { t } = useTranslation();
  const { startNewSession, showHistory, openHistory } = useSmartChartsContext();
  const isMobile = useMediaMatch('(max-width: 480px)');

  return (
    <div className="sc2__header">
      <div className="sc2__header-athletes">
        <SelectedAthletes />
      </div>

      <div className="sc2__header-actions">
        {isMobile ? (
          <IconButton
            variant="secondary"
            content={<PlusIcon />}
            onClick={startNewSession}
          />
        ) : (
          <Button
            variant="secondary"
            content={t('smart_charts.action.new_session')}
            onClick={startNewSession}
          />
        )}
        {!showHistory && (
          <Tooltip
            anchor={
              <IconButton
                variant="layer2"
                content={<MenuExpand />}
                onClick={openHistory}
              />
            }
            content={t('tooltip.open_history')}
            placement="bottom-end"
          />
        )}
      </div>
    </div>
  );
};
