import './index.css';
import { Skeleton, Tooltip, Typography } from '@cycling-web/analog-ui';
import { Info } from 'lucide-react';
import { FiltersBar } from '@cycling-web/common';
import { IChartHeaderProps } from '../../types';

export const ChartHeader = ({
  title,
  subtitle,
  tooltipProps,
  filtersBarProps,
  loading,
  adornmentLeft,
  adornmentRight,
  onChartHeaderRender,
}: IChartHeaderProps) => {
  if (onChartHeaderRender) {
    return onChartHeaderRender();
  }

  return (
    <header className="chart-header">
      <div className="chart-header__left">
        <div className="chart-header__left-title">
          {loading && !title ? (
            <Skeleton width="180px" height="26px" />
          ) : (
            <Typography
              className="chart-header__title"
              text={title}
              variant="h4"
              as="h4"
              weight="bold"
            />
          )}
          {tooltipProps && (
            <Tooltip
              {...tooltipProps}
              anchor={
                <Info className="chart-header__tooltip-anchor" size={20} />
              }
            />
          )}
        </div>
        {subtitle && subtitle}
      </div>

      <div className="chart-header__right-bar">
        {adornmentLeft && (
          <div className="chart-header__actions chart-header__actions--left">
            {adornmentLeft}
          </div>
        )}
        {filtersBarProps && (
          <div className="chart-header__filters">
            <FiltersBar {...filtersBarProps} />
          </div>
        )}
        {adornmentRight && (
          <div className="chart-header__actions chart-header__actions--right">
            {adornmentRight}
          </div>
        )}
      </div>
    </header>
  );
};
