import { MobileMenuToggle } from './Toggle';
import { useMediaMatch } from 'rooks';

export const useMobileMenu = () => {
  const isMobile = useMediaMatch('(max-width: 480px)');

  if (isMobile) {
    return () => {
      return <MobileMenuToggle />;
    };
  }

  return undefined;
};
