import './index.css';
import { Outlet, useLocation } from 'react-router';
import { useFetchEnums } from '../../hooks/useFetchEnums';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { useTranslation } from 'react-i18next';
import { INavigationItem } from '@cycling-web/common';
import { useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { Calendar, User, Zap } from 'lucide-react';
import { SideNavigation } from '../../components/SideNavigation';
import { useUsersStore } from '../../store/users/slice';
import { useFetchAthletes } from '../../hooks/useFetchAthletes';
import { useFetchFeatureFlags } from '../../hooks/useFetchFeatureFlags';
import { useFetchCustomPeriods } from '../../hooks/useFetchCustomPeriods';
import { clsx } from 'clsx';
import { useAnaContext } from '../../components/Ana/context';
import { Ana } from '../../components/Ana/components/Ana';
import { useFeatureFlagsStore } from '../../store/feature-flags/slice';
import { FeatureFlag } from '../../types/feature-flags';

export const EntryAthlete = () => {
  useFetchEnums();
  useFetchAthletes();
  useFetchFeatureFlags();
  useFetchCustomPeriods();

  const { pathname } = useLocation();
  const userProfile = useUsersStore((s) => s.userProfile);
  const baseUrl = useBaseUrl();
  const defaultUrl = `${baseUrl}/${ROUTES.ATHLETE}/${userProfile?.athleteId}`;
  const { showAna } = useAnaContext();
  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);

  const { t } = useTranslation();
  const items: INavigationItem[] = useMemo(() => {
    if (!userProfile) {
      return [];
    }

    const calendarLinks: INavigationItem[] = featureFlags.includes(
      FeatureFlag.Calendar_Page
    )
      ? [
          {
            url: `${defaultUrl}/${ROUTES.CALENDAR}`,
            icon: <Calendar />,
            text: t('label.calendar'),
            active:
              pathname.includes(`${defaultUrl}/${ROUTES.CALENDAR}`) ||
              pathname.includes(`${defaultUrl}/${ROUTES.WORKOUT}`) ||
              pathname.includes(`${defaultUrl}/${ROUTES.EVENT}`),
          },
        ]
      : [];

    return [
      {
        url: `${defaultUrl}/${ROUTES.PROFILE}`,
        icon: <User />,
        text: t('label.my_profile'),
      },
      ...calendarLinks,
      {
        url: `${defaultUrl}/${ROUTES.SMART_CHARTS}`,
        icon: <Zap />,
        text: t('label.smart_charts'),
      },
    ];
  }, [defaultUrl, t, userProfile, pathname]);

  const rootClass = clsx('athlete', showAna && 'ana-open');

  return (
    <div className={rootClass}>
      <SideNavigation defaultUrl={defaultUrl} items={items} />
      <div className="athlete-content">
        <Outlet />
      </div>
      {showAna && <Ana />}
    </div>
  );
};
