import {
  Datepicker,
  IconButton,
  Tooltip,
  Typography,
} from '@cycling-web/analog-ui';
import { ChevronDown } from 'lucide-react';
import { use, useCallback } from 'react';
import { WorkoutsCalendarContext } from '../../context';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const CalendarDatepicker = () => {
  const { t } = useTranslation();
  const { date, setDate } = use(WorkoutsCalendarContext);

  const onRenderAnchor = useCallback(() => {
    return (
      <Tooltip
        anchor={
          <IconButton
            size="s"
            variant="quietLayer3"
            content={<ChevronDown />}
          />
        }
        content={t('tooltip.open_what', {
          what: t('label.calendar').toLowerCase(),
        })}
        placement="bottom-end"
      />
    );
  }, [t]);

  return (
    <div className="workouts-calendar__header-datepicker">
      <Typography
        className="workouts-calendar__header-title"
        variant="headline"
        weight="bold"
        text={format(date, 'MMMM yyyy')}
      />
      <Datepicker
        value={date}
        onChange={setDate}
        onRenderAnchor={onRenderAnchor}
      />
    </div>
  );
};
