import './index.css';
import { IChatbotHistoryMessage } from '../../../../types/chatbot';
import { useAiAssistantStore } from '../../store/slice';
import { use, useEffect, useRef } from 'react';
import {
  ConversationContext,
  ConversationMessageSource,
  ConversationState,
  IAnaContentType,
  IConversationMessageAuthor,
  Message,
  MessageLoading,
} from '../../../Conversation';
import { MessageFeedback } from '../MessageFeedback';

export const Body = () => {
  const messages = useAiAssistantStore((s) => s.messages);
  const { conversationState, handleStopPlayMessage } = use(ConversationContext);
  const messagesScrollableElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const el = messagesScrollableElementRef.current;
      if (el) {
        el.scrollTo(0, el.scrollHeight);
      }
    });
  }, [messages]);

  const messagesJSX = messages.map(
    (message: IChatbotHistoryMessage, i: number) => {
      const isAgent = message.role === 'assistant';
      const isLast = i === messages.length - 1;
      const showMessageFeedback =
        isAgent && isLast && !message.feedback_type && message.id !== '';

      return message.content.map((content) => {
        return (
          <Message
            key={i}
            message={{
              id: message.id,
              text: content.text.value,
              content: [
                {
                  content_id: message.id,
                  content_type: IAnaContentType.TEXT,
                  text: content.text.value,
                },
              ],
              source: ConversationMessageSource.AnaCoach,
              author:
                message.role === 'assistant'
                  ? IConversationMessageAuthor.Agent
                  : IConversationMessageAuthor.User,
            }}
            adornmentEnd={
              showMessageFeedback && <MessageFeedback message={message} />
            }
          />
        );
      });
    }
  );

  const processingMessage = conversationState ===
    ConversationState.PROCESSING && <MessageLoading />;

  useEffect(() => {
    return () => {
      handleStopPlayMessage();
    };
  }, [handleStopPlayMessage]);

  return (
    <div className="ai-assistant__body" ref={messagesScrollableElementRef}>
      {messagesJSX}
      {processingMessage}
    </div>
  );
};
