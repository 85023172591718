import './index.css';
import { Page } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useViewModel } from './useViewModel';
import { TeamPowerProfileChart } from '../GroupCharts/components/TeamPowerProfileChart';
import { TssAndIfChart } from '../../components/charts/TssAndIfChart';
import { DurationAndDistanceChart } from '../../components/charts/DurationAndDistanceChart';
import { DurationAndElevationChart } from '../../components/charts/DurationAndElevationChart';
import { WorkAndIfChart } from '../../components/charts/WorkAndIfChart';
import { CriticalPowerChart } from '../GroupCharts/components/CriticalPowerChart';
import { DashboardFilters } from './components/Filters';
import { TsbChart } from '../../components/charts/TsbChart';
import { Summary } from './components/Summary';
import { DashboardContext } from './context';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { useMobileMenu } from '../../components/MobileMenu';

export const DashboardView = () => {
  const { t } = useTranslation();
  const {
    workoutDetailsSources,
    workoutDetailsLoading,
    criticalPowerSource,
    criticalPowerLoading,
    peaksSource,
    peaksLoading,
    trainingLoadSources,
    trainingLoadLoading,
    filters,
    handleChangeFilter,
    context,
  } = useViewModel();
  const onRenderMenu = useMobileMenu();

  return (
    <DashboardContext value={context}>
      <Page
        pageHeaderProps={{
          title: t('label.team_dashboard'),
          onRenderMenu,
        }}
      >
        <div className="dashboard-charts">
          <DashboardFilters
            filters={filters}
            handleChangeFilter={handleChangeFilter}
          />
          <Summary />
          <div className="dashboard-charts__charts">
            <TsbChart
              source={trainingLoadSources.tsb}
              loading={trainingLoadLoading}
            />
            <TeamPowerProfileChart
              source={peaksSource}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
              loading={peaksLoading}
            />
            <TssAndIfChart
              source={workoutDetailsSources.tssAndIf}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
              loading={workoutDetailsLoading}
            />
            <DurationAndDistanceChart
              source={workoutDetailsSources.durationAndDistance}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
              loading={workoutDetailsLoading}
            />
            <DurationAndElevationChart
              source={workoutDetailsSources.durationAndElevation}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
              loading={workoutDetailsLoading}
            />
            <WorkAndIfChart
              source={workoutDetailsSources.workAndIf}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
              loading={workoutDetailsLoading}
            />
            <CriticalPowerChart
              source={criticalPowerSource}
              loading={criticalPowerLoading}
              filters={filters}
              handleChangeFilter={handleChangeFilter}
            />
          </div>
        </div>
      </Page>
    </DashboardContext>
  );
};

export const Dashboard = () => {
  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().dashboard}
    >
      <DashboardView />
    </PageFiltersContextProvider>
  );
};
