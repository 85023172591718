import './index.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  IWorkout,
  IWorkoutChannelData,
  IWorkoutLapStat,
} from '../../../../types/workouts';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  ChartTooltip,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { hoursToHHMMSS } from '../../../../utils/date-time';
import { ChannelsChartHeader } from './ChannelsChartHeader';
import { ISegmentButton, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { roundToDecimals } from '../../../../utils/maths';
import { mapLapTimesToDistance } from './utils';
import throttle from 'lodash/throttle';
import { useWorkoutStore } from '../../store/slice';

type IProps = {
  data: IWorkout | null;
  selectedLaps?: IWorkoutLapStat[];
  loading?: boolean;
};

type ISamplingProps = {
  channels: string[];
  channelsData: IWorkoutChannelData[];
  xAxisKey: 'time' | 'distance';
  sampling: number;
};

const channelColors = {
  // Cycling
  Cadence: ChartColor.Yellow,
  Power: ChartColor.LightPurple,
  HeartRate: ChartColor.LightRed,
  Temperature: ChartColor.LightBlue,
  Speed: ChartColor.LightGreen,
  Elevation: ChartColor.Grey30,
  Altitude: ChartColor.Grey30,
  //-------
  EnhancedSpeed: ChartColor.Yellow,
  enhancedAltitude: ChartColor.LightCyan,
  Grade: ChartColor.LightPurple,
  Distance: ChartColor.LightBlue,
  PositionLong: ChartColor.Grey,
  PositionLat: ChartColor.Grey,
  gpsAccuracy: ChartColor.Grey,
};

const cyclingChannelsSeries: Record<string, any> = {
  Cadence: { yAxisIndex: 0 },
  Power: { yAxisIndex: 1 },
  HeartRate: { yAxisIndex: 2 },
  Temperature: { yAxisIndex: 3 },
  Speed: { yAxisIndex: 4 },
  Elevation: { yAxisIndex: 5, areaStyle: { color: ChartColor.Grey30 } },
  Altitude: { yAxisIndex: 5, areaStyle: { color: ChartColor.Grey30 } },
  PositionLong: { yAxisIndex: 6 },
  PositionLat: { yAxisIndex: 6 },
};

export const ChannelsChart = ({ data, selectedLaps, loading }: IProps) => {
  const { t } = useTranslation();

  const channelUnits = useMemo(() => {
    return {
      Cadence: t('units.rpm'),
      Power: t('units.w'),
      HeartRate: t('units.hr'),
      Temperature: t('units.celsius'),
      Speed: t('units.kmh'),
      Elevation: t('units.m'),
      Altitude: t('units.m'),
      Distance: t('units.km'),
    };
  }, [t]);

  const hideSeries: string[] = useMemo(() => {
    return ['PositionLong', 'PositionLat'];
  }, []);

  const channels = useMemo(() => {
    return data?.WorkoutDetail?.WorkoutChannels?.Channels ?? [];
  }, [data]);
  const channelsData = useMemo(() => {
    return data?.WorkoutDetail?.WorkoutChannels?.Data ?? [];
  }, [data]);

  const [activeButton, setActiveButton] = useState<number>(0);
  const switchButtons = useMemo((): ISegmentButton[] => {
    const buttons: ISegmentButton[] = [
      {
        id: ChartAxisName.Date,
        text: t('label.time'),
      },
    ];

    if (channels.includes('Distance')) {
      buttons.push({
        id: ChartAxisName.Distance,
        text: t('label.distance'),
      });
    }

    return buttons;
  }, [t, channels]);

  const samplingTimeOptions = [
    // Seconds
    1000, 5000, 10000, 15000, 20000, 25000, 30000,
    // Minutes (converted to milliseconds)
    60000, 300000,
  ];
  const samplingDistanceOptions = [50, 100, 250, 500, 1000, 2000];
  const samplingOptions =
    activeButton === 0 ? samplingTimeOptions : samplingDistanceOptions;
  const [sampling, setSampling] = useState<number>(5000);

  useEffect(() => {
    if (activeButton === 0) {
      // Every 5 sec
      setSampling(5000);
    } else {
      // Every 100m
      setSampling(100);
    }
  }, [activeButton]);

  const mappedLapRanges = useMemo(() => {
    if (!selectedLaps?.length || !channels.includes('Distance')) return [];

    const distanceIndex = channels.findIndex((c) => c === 'Distance');
    if (distanceIndex === -1) return [];

    return mapLapTimesToDistance(selectedLaps, channelsData, distanceIndex);
  }, [selectedLaps, channels, channelsData]);

  const generateSmoothedSource = useCallback(
    ({
      channels,
      channelsData,
      xAxisKey,
      sampling,
    }: ISamplingProps): IChartSource => {
      if (
        !channels ||
        !channelsData ||
        channels.length === 0 ||
        channelsData.length === 0
      ) {
        return [];
      }

      const source: IChartSource = [
        [xAxisKey === 'time' ? ChartAxisName.Date : ChartAxisName.Distance],
      ];
      const indexMap: Record<number, boolean> = {};
      let distanceIndex = -1;

      channels.forEach((c, i) => {
        if (c === 'Distance') distanceIndex = i;
        if (c in cyclingChannelsSeries) {
          indexMap[i] = true;
          source[0].push(c);
        }
      });

      let lastAcceptedX = -Infinity;

      for (let i = 0; i < channelsData.length; i++) {
        const item = channelsData[i];
        const values = item.Values ?? [];

        const currentX =
          xAxisKey === 'time'
            ? item.MillisecondOffset
            : distanceIndex >= 0
            ? values[distanceIndex]
            : undefined;

        if (
          typeof currentX === 'number' &&
          currentX - lastAcceptedX >= sampling
        ) {
          lastAcceptedX = currentX;
          const row = [currentX];

          for (let j = 0; j < values.length; j++) {
            if (indexMap[j]) {
              row.push(values[j]);
            }
          }

          source.push(row);
        }
      }

      return source;
    },
    []
  );

  // ---------------------------------------------------------------------------

  const source = useMemo(() => {
    const xAxisKey =
      switchButtons[activeButton].id === ChartAxisName.Date
        ? 'time'
        : 'distance';

    return generateSmoothedSource({
      channels,
      channelsData,
      xAxisKey,
      sampling,
    });
  }, [
    activeButton,
    channels,
    channelsData,
    generateSmoothedSource,
    sampling,
    switchButtons,
  ]);

  const series = useMemo(() => {
    const dummySeries = {
      name: 'Laps',
      type: 'line' as const,
      data: [],
      symbolSize: 0,
      lineStyle: {
        width: 0,
        color: 'rgba(0, 0, 0, 0)',
      },
      itemStyle: {
        color: 'rgba(0, 0, 0, 0)',
      },
      markArea: {
        silent: true,
        itemStyle: {
          color:
            selectedLaps?.length > 0
              ? 'rgba(68, 195, 243, 0.2)'
              : 'rgba(0, 0, 0, 0)',
        },
        data:
          selectedLaps && selectedLaps.length > 0
            ? activeButton === 0
              ? selectedLaps.map((lap) => [
                  { xAxis: lap.StartTimeMs },
                  { xAxis: lap.EndTimeMs },
                ])
              : mappedLapRanges.map((lap) => [
                  { xAxis: lap.start },
                  { xAxis: lap.end },
                ])
            : [
                [
                  { xAxis: source.length > 1 ? source[1][0] : 0 },
                  { xAxis: source.length > 1 ? source[1][0] : 0 },
                ],
              ],
      },
      showSymbol: false,
      animation: false,
      z: 10,
    };

    const dataSeries = channels
      .filter((channel) => channel in cyclingChannelsSeries)
      .map((channel) => {
        const isHidden = hideSeries.includes(channel);
        return {
          name: channel,
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            color: isHidden
              ? 'rgba(0, 0, 0, 0)'
              : channelColors[channel] || ChartColor.Grey,
          },
          lineStyle: {
            width: isHidden ? 0 : 1,
            color: isHidden
              ? 'rgba(0, 0, 0, 0)'
              : channelColors[channel] || ChartColor.Grey,
          },
          areaStyle: isHidden
            ? undefined
            : cyclingChannelsSeries[channel]?.areaStyle ?? undefined,
          yAxisIndex: cyclingChannelsSeries[channel]?.yAxisIndex ?? 0,
          encode: {
            x: 0,
            y: source[0].indexOf(channel),
          },
          tooltip: {
            show: !isHidden,
          },
        };
      });

    return [dummySeries, ...dataSeries];
  }, [
    channels,
    mappedLapRanges,
    selectedLaps,
    source,
    activeButton,
    hideSeries,
  ]);

  const getInterval = useCallback((maxValue: number, isTimeMode: boolean) => {
    if (isTimeMode) {
      // Time mode: maxValue is in milliseconds
      if (maxValue > 7_200_000) {
        // > 2 hours: 30 minutes
        return 1_800_000; // 30 * 60 * 1000
      } else if (maxValue > 3_600_000) {
        // > 1 hour: 20 minutes
        return 1_200_000; // 20 * 60 * 1000
      } else if (maxValue > 1_800_000) {
        // > 30 minutes: 15 minutes
        return 900_000; // 15 * 60 * 1000
      } else if (maxValue > 600_000) {
        // > 10 minutes: 5 minutes
        return 300_000; // 5 * 60 * 1000
      } else {
        // ≤ 10 minutes: Aim for 8–10 ticks
        const targetTicks = 10;
        const step = maxValue / targetTicks;
        // Round to nearest sensible time interval (e.g., 30s, 1m, 2m)
        const seconds = step / 1000;
        if (seconds > 120) return 120_000; // 2 minutes
        if (seconds > 60) return 60_000; // 1 minute
        if (seconds > 30) return 30_000; // 30 seconds
        if (seconds > 15) return 15_000; // 15 seconds
        return 10_000; // 10 seconds as minimum
      }
    } else {
      // Distance mode: maxValue is in meters
      if (maxValue > 100_000) {
        // > 100 km: 25 km
        return 25_000; // 25 * 1000 * 1000
      } else if (maxValue > 50_000) {
        // > 50 km: 5 km
        return 5_000; // 5 * 1000 * 1000
      } else {
        // ≤ 50 km: Aim for 8–10 ticks
        const targetTicks = 10;
        const step = maxValue / targetTicks;
        // Round to nearest sensible distance interval (e.g., 1km, 2km, 5km)
        const km = step / 1_000;
        if (km > 2.5) return 5_000; // 5 km
        if (km > 1) return 2_000; // 2 km
        if (km > 0.5) return 1_000; // 1 km
        return 500; // 0.5 km as minimum
      }
    }
  }, []);

  const maxXValue = useMemo(() => {
    let max = 0;
    for (let i = 1; i < source.length; i++) {
      const n = Number(source[i][0]);
      if (!isNaN(n) && n > max) {
        max = n;
      }
    }
    return max;
  }, [source]);

  const onRenderTooltipValue = useCallback(
    (value: number, name: string) => {
      return (
        <>
          {Math.round(value)}{' '}
          <Typography
            variant="subtitle"
            color="text-secondary"
            text={channelUnits[name]}
          />
        </>
      );
    },
    [channelUnits]
  );

  const onRenderTooltipHeader = useCallback(
    (params) => {
      if (activeButton === 0) {
        const time = params[0].value[0] / (1000 * 3600);
        return hoursToHHMMSS(time);
      }

      const km = roundToDecimals(params[0].value[0] / 1000, 1);
      return `${km} ${t('units.km')}`;
    },
    [activeButton, t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    const interval = getInterval(maxXValue, activeButton === 0);

    return {
      dataset: {
        source,
      },
      grid: {
        top: 50,
        left: 112,
        right: 124,
        bottom: 60,
      },
      xAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        interval,
        max: maxXValue,
        axisLabel: {
          formatter: (value) => {
            if (activeButton === 0) {
              return hoursToHHMMSS(value / (1000 * 3600));
            }
            return roundToDecimals(value / 1000, 1);
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: t('units.rpm'),
          nameTextStyle: {
            color: '#858e99',
            opacity: 1,
            align: 'right',
          },
        },
        {
          type: 'value',
          position: 'left',
          offset: 40,
          axisLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
            },
          },
          name: t('units.w'),
          nameTextStyle: {
            color: '#858e99',
            opacity: 1,
            align: 'right',
          },
        },
        {
          type: 'value',
          position: 'left',
          offset: 80,
          axisLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
            },
          },
          name: t('units.hr'),
          nameTextStyle: {
            color: '#858e99',
            opacity: 1,
            align: 'right',
          },
        },
        {
          type: 'value',
          position: 'right',
          name: t('units.celsius'),
          nameTextStyle: {
            color: '#858e99',
            opacity: 1,
            align: 'left',
          },
        },
        {
          type: 'value',
          position: 'right',
          offset: 40,
          axisLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
            },
          },
          name: t('units.kmh'),
          nameTextStyle: {
            color: '#858e99',
            opacity: 1,
            align: 'left',
          },
        },
        {
          type: 'value',
          position: 'right',
          offset: 80,
          axisLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
            },
          },
          name: t('units.m'),
          nameTextStyle: {
            color: '#858e99',
            opacity: 1,
            align: 'left',
          },
        },
        {
          type: 'value',
          position: 'right',
          offset: 80,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          name: '',
          nameTextStyle: {
            show: false,
          },
        },
      ],
      series: series,
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          return ReactDOMServer.renderToString(
            <ChartTooltip
              params={params}
              onRenderValue={onRenderTooltipValue}
              onRenderHeader={onRenderTooltipHeader}
              hideSeries={hideSeries}
            />
          );
        },
      },
    };
  }, [
    getInterval,
    maxXValue,
    activeButton,
    source,
    t,
    series,
    onRenderTooltipValue,
    onRenderTooltipHeader,
    hideSeries,
  ]);

  const onRenderLegendItem = useCallback(
    (item) => {
      if (!item) {
        return null;
      }

      if (item.name === 'PositionLat' || item.name === 'PositionLong') {
        return null;
      }

      if (item.name === 'Laps') {
        if (!selectedLaps?.length) {
          return null;
        }

        return (
          <ChartLegendItem
            item={{
              ...item,
              color: 'rgba(68, 195, 243, 0.2)',
            }}
          />
        );
      }

      return <ChartLegendItem item={item} />;
    },
    [selectedLaps]
  );

  const throttledGlobalMove = throttle((params) => {
    const series = params?.series || [];
    let longitude = undefined;
    let latitude = undefined;

    for (let i = 0; i < series.length; i++) {
      if (series[i].seriesName === 'PositionLong') {
        longitude = series[i]?.value;
      }
      if (series[i].seriesName === 'PositionLat') {
        latitude = series[i]?.value;
      }

      if (longitude && latitude) {
        break;
      }
    }

    if (longitude && latitude) {
      useWorkoutStore.getState().setPositionOnMap({
        longitude,
        latitude,
      });
    }
  }, 150);

  const onGlobalOut = useCallback(() => {
    useWorkoutStore.getState().setPositionOnMap(null);
  }, []);

  return (
    <ChartWrapper className="workout-channels-chart" minHeight="420px">
      <ChannelsChartHeader
        hasData={source.length > 1}
        loading={!!loading}
        buttons={switchButtons}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        sampling={sampling}
        setSampling={setSampling}
        samplingOptions={samplingOptions}
      />
      <Chart
        headerProps={{
          title: '',
          show: false,
        }}
        legendProps={{
          show: source.length > 1,
          onRenderItem: onRenderLegendItem,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.WorkoutGraph,
          }),
          onGlobalMove: throttledGlobalMove,
          onGlobalOut: onGlobalOut,
        }}
      />
    </ChartWrapper>
  );
};
