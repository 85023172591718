import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, Divider, FormElement, Toast } from '@cycling-web/analog-ui';
import {
  DatepickerControl,
  SegmentControl,
  SelectMultiControl,
  SelectSingleControl,
  TextareaControl,
} from '@cycling-web/common';
import { IAthlete } from '../../types/athletes';
import { IMedicalReport, IReportIllnessFormData } from '../../types/medical';
import { MedicalRepository } from '../../api/medical/repository';
import { MedicalService } from '../../api/medical/service';
import { useUsersStore } from '../../store/users/slice';
import { addWeeks } from 'date-fns';
import { BodyMapView } from './BodyMapView';
import { useDictionaryOptions } from '../../hooks/useDictionaryOptions';
import { trackUserInteractionEvent } from '../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../ms/tracking-entities';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
  onSubmitSuccess?: (report: IMedicalReport) => void;
};

export const ReportIllnessFormModal = ({
  athlete,
  onDismiss,
  onSubmitSuccess,
}: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);

  const illnessTypeOptions = useDictionaryOptions('illnessType');
  const symptomOptions = useDictionaryOptions('symptom');
  const ossicsCodeOptions = useDictionaryOptions('ossicsCode');
  const severityOptions = useDictionaryOptions('severity');

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        illnessType: z.string().min(1, { message: t('validation.required') }),
        symptom: z
          .array(z.string().min(1, { message: t('validation.required') }))
          .nonempty({ message: t('validation.required') }),
        severity: z.string().min(1, { message: t('validation.required') }),
        ossicsCode: z.string().min(1, { message: t('validation.required') }),
        onsetDate: z.date(),
        estimatedRecovery: z.date(),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IReportIllnessFormData>({
    defaultValues: {
      id: userProfile?.coachId || -1,
      athleteId: athlete.id,
      recordType: 'ILLNESS',
      illnessType: '',
      onsetDate: new Date(),
      estimatedRecovery: addWeeks(new Date(), 1),
      severity: 'MILD',
      symptom: [],
      ossicsCode: '',
      note: '',
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IReportIllnessFormData) => {
      setLoading(true);
      medicalRepository
        .reportIllness(formData)
        .then((report: IMedicalReport) => {
          if (onSubmitSuccess) {
            onSubmitSuccess(report);
          }
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.create_illness_report_title'),
              message: t('error.create_illness_report_message'),
            },
            { toastId: 'create_illness_report' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [medicalRepository, onDismiss, onSubmitSuccess, t]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.REPORT_ILLNESS,
    });

    handleSubmit(
      (formData: IReportIllnessFormData) => {
        handleSave(formData);
      },
      (errors) => console.log(errors)
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.report_illness')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="report-illness-form-modal">
          <div className="report-illness-form-modal__row">
            <div className="report-illness-form-modal__form">
              <FormElement
                label={t('label.illnessType')}
                message={errors.illnessType?.message}
              >
                <SelectSingleControl
                  name="illnessType"
                  options={illnessTypeOptions}
                  placeholder={t('placeholder.illnessType')}
                  invalid={!!errors.illnessType}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.symptom')}
                message={errors.symptom?.message}
              >
                <SelectMultiControl
                  name="symptom"
                  options={symptomOptions}
                  placeholder={t('placeholder.symptom')}
                  invalid={!!errors.symptom}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.severity')}
                message={errors.severity?.message}
              >
                <SegmentControl
                  name="severity"
                  buttons={severityOptions}
                  fullWidth
                />
              </FormElement>

              <Divider />

              <FormElement
                label={t('label.osiics_code')}
                message={errors.ossicsCode?.message}
              >
                <SelectSingleControl
                  name="ossicsCode"
                  options={ossicsCodeOptions}
                  placeholder={t('placeholder.osiics_code')}
                  invalid={!!errors.ossicsCode}
                  search={true}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <Divider />

              <FormElement
                label={t('label.onset_date')}
                message={errors.onsetDate?.message}
              >
                <DatepickerControl
                  name="onsetDate"
                  placeholder={t('placeholder.onset_date')}
                  invalid={!!errors.onsetDate}
                />
              </FormElement>

              <FormElement
                label={t('label.estimate_recovery_date')}
                message={errors.estimatedRecovery?.message}
              >
                <DatepickerControl
                  name="estimatedRecovery"
                  placeholder={t('placeholder.estimate_recovery_date')}
                  invalid={!!errors.estimatedRecovery}
                />
              </FormElement>

              <FormElement
                label={t('label.note')}
                message={errors.note?.message}
              >
                <TextareaControl
                  name="note"
                  placeholder={t('placeholder.note')}
                  maxLength={999}
                  invalid={!!errors.note}
                />
              </FormElement>
            </div>

            <div className="report-illness-form-modal__body-map">
              <BodyMapView />
            </div>
          </div>
        </div>
      </Dialog>
    </FormProvider>
  );
};
