import './index.css';
import {
  ILabelValueProps,
  LabelValue,
  Typography,
} from '@cycling-web/analog-ui';
import { EMPTY } from '../../../../constants';

type IProps = {
  params: ILabelValueProps[];
};

export const CalendarWorkoutStats = ({ params }: IProps) => {
  const paramsJSX = params.map((p, i: number) => {
    return (
      <div key={i} className="calendar-workout-stat">
        <Typography variant="subtitle-b" weight="bold" text={p.value} />
        {p.units && <Typography variant="subtitle-b" text={p.units} />}
      </div>
    );
  });

  if (paramsJSX.length === 0) {
    return null;
  }

  return <div className="calendar-workout-stats">{paramsJSX}</div>;
};
