const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export enum DateRange {
  Week = 'last_week',
  Month = 'last_month',
  TwoMonth = 'last_2_month',
  ThreeMonth = 'last_3_month',
  Year = 'last_year',
}

export enum TimeRange {
  TenSeconds = '10Seconds',
  ThirtySeconds = '30Seconds',
  OneMinute = '1Minutes',
  FiveMinutes = '5Minutes',
  TenMinutes = '10Minutes',
  TwentyMinutes = '20Minutes',
  ThirtyMinutes = '30Minutes',
  SixtyMinutes = '60Minutes',
}

export enum WorkOptions {
  kJ2000 = 2000,
  kJ3000 = 3000,
  kJ4000 = 4000,
  kJ1900 = 1900,
  kJ2800 = 2800,
  kJ3500 = 3500,
  kJ4500 = 4500,
}

export enum DateGroupBy {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

export enum Discipline {
  Cycling = 'cycling',
  Swimming = 'swimming',
  Running = 'running',
}
