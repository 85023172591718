import { useCallback } from 'react';
import { Card, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useTrainingPlanContext } from '../../context';
import { clsx } from 'clsx';

export const FitnessGoal = () => {
  const { t } = useTranslation();
  const { trainingPlan, onTrainingPlanChange } = useTrainingPlanContext();

  const buttons: number[] = [3, 6, 8];

  const onButtonClick = useCallback(
    (fitnessGoalTimeline: number) => {
      return () => {
        onTrainingPlanChange({
          fitnessGoalTimeline,
        });
      };
    },
    [onTrainingPlanChange]
  );

  const buttonsJSX = buttons.map((button) => {
    const rootClass = clsx(
      'training-plan__timeline-button',
      trainingPlan.fitnessGoalTimeline === button && 'active'
    );

    return (
      <button
        key={button}
        className={rootClass}
        onClick={onButtonClick(button)}
      >
        <Typography variant="h3" weight="bold" text={button} />
        <Typography variant="subtitle" text={t('label.weeks')} />
      </button>
    );
  });

  return (
    <Card className="training-plan__fitness-overview" variant="layer3">
      <Typography
        variant="subtitle"
        weight="bold"
        text={trainingPlan?.fitnessGoal?.text}
        color="text-primary"
      />
      <Typography
        className="training-plan__fitness-overview-description"
        variant="subtitle-b"
        text={trainingPlan?.fitnessGoal?.description}
        color="text-secondary"
      />

      <div className="training-plan__fitness-overview-timeline">
        <Typography
          variant="subtitle"
          weight="bold"
          text={t('label.timeline_to_enhance_fitness')}
          color="text-primary"
        />

        <div className="training-plan__timeline-buttons">{buttonsJSX}</div>
      </div>
    </Card>
  );
};
