import { CoachService } from './service';
import {
  IGetAnthropometryRequest,
  ISaveFeedbackRequest,
  ISetDateOfNextSFTResponse,
  ISetTargetPowerOfSFTResponse,
  IUpdateStaffRequest,
} from './types';
import {
  IAnthropometry,
  IAnthropometryFormData,
  IPerformanceFormData,
  IStaff,
} from '../../types/coach';
import { format } from 'date-fns';
import { IReportFeedbackForm } from '../../types/performance';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';

/** The repository is responsible for data transformation */
export interface ICoachRepository {
  getAnthropometry: (
    p: IGetAnthropometryRequest,
    c?: AxiosRequestConfig
  ) => Promise<IAnthropometry[]>;

  setAnthropometry: (
    p: IAnthropometryFormData,
    c?: AxiosRequestConfig
  ) => Promise<void>;

  setDateOfNextSFT: (
    p: IPerformanceFormData,
    c?: AxiosRequestConfig
  ) => Promise<ISetDateOfNextSFTResponse>;

  setTargetPowerOfSFT: (
    p: IPerformanceFormData,
    c?: AxiosRequestConfig
  ) => Promise<ISetTargetPowerOfSFTResponse>;

  getProfile: (c?: AxiosRequestConfig) => Promise<IStaff>;

  updateProfile: (
    p: IUpdateStaffRequest,
    c?: AxiosRequestConfig
  ) => Promise<IStaff>;

  saveFeedback: (
    p: IReportFeedbackForm,
    c?: AxiosRequestConfig
  ) => Promise<any>;
}

export class CoachRepository
  extends BaseRepository
  implements ICoachRepository
{
  constructor(
    private readonly service: CoachService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getAnthropometry(
    p: IGetAnthropometryRequest,
    c?: AxiosRequestConfig
  ): Promise<IAnthropometry[]> {
    const response = await this.handleRequest(
      this.service.getAnthropometry,
      p,
      c
    );
    return response.data.data;
  }

  async setAnthropometry(
    p: IAnthropometryFormData,
    c?: AxiosRequestConfig
  ): Promise<void> {
    return this.handleRequest(
      this.service.setAnthropometry,
      {
        ...p,
        athleteId: +p.athleteId,
        height: +p.height,
        weight: +p.weight,
        biceps: +p.biceps,
        triceps: +p.triceps,
        subscapula: +p.subscapula,
        iliacCrest: +p.iliacCrest,
        abdominal: +p.abdominal,
        thigh: +p.thigh,
        calf: +p.calf,
        supraspinale: +p.supraspinale,
        date: p.date,
      },
      c
    );
  }

  async setDateOfNextSFT(
    p: IPerformanceFormData,
    c?: AxiosRequestConfig
  ): Promise<ISetDateOfNextSFTResponse> {
    return this.handleRequest(
      this.service.setDateOfNextSFT,
      {
        dateOfNextSft: p.dateOfNextSft,
        athleteId: p.athleteId,
        updatedAt: format(new Date(), 'yyyy-MM-dd'),
      },
      c
    );
  }

  async setTargetPowerOfSFT(
    p: IPerformanceFormData,
    c?: AxiosRequestConfig
  ): Promise<ISetTargetPowerOfSFTResponse> {
    return this.handleRequest(
      this.service.setTargetPowerOfSFT,
      {
        targetPower: +p.targetPower,
        athleteId: p.athleteId,
        updatedAt: format(new Date(), 'yyyy-MM-dd'),
      },
      c
    );
  }

  async updateProfile(
    p: IUpdateStaffRequest,
    c?: AxiosRequestConfig
  ): Promise<IStaff> {
    const response = await this.handleRequest(this.service.updateProfile, p, c);
    return response.data;
  }

  async getProfile(c?: AxiosRequestConfig): Promise<IStaff> {
    const response = await this.handleRequest(this.service.getProfile, c);
    return response.data.data;
  }

  async saveFeedback(
    p: IReportFeedbackForm,
    c?: AxiosRequestConfig
  ): Promise<any> {
    const payload: ISaveFeedbackRequest = {
      ...p,
      performanceRating: Number(p.performanceRating),
    };

    const response = await this.handleRequest(
      this.service.saveFeedback,
      payload,
      c
    );
    return response.data.data;
  }
}
