import './index.css';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../hooks/useTitle';
import { ROUTES } from '../../../../router/routes';
import { Link, useLocation } from 'react-router';
import { CookiesSection } from '../Cookies/components/CookiesSection';
import { useCallback } from 'react';
import { DeepPartial, ICookieConsent } from '@cycling-web/common';
import { CookiesGroup } from '../Cookies/components/CookiesGroup';
import { AnalyticalCookies, EssentialCookies } from '../Cookies/constants';
import { Cookie } from '../Cookies/components/Cookie';
import { useCookieContext } from '../../context/cookieContext';

export const CookiesNotice = () => {
  const { t } = useTranslation();
  useTitle(t('label.cookies_notice'));
  const { pathname } = useLocation();
  const isLegalPage = pathname.includes(`/${ROUTES.LEGAL}`);

  const { cookieConsent, onSavePreference } = useCookieContext();

  const onConsentChange = useCallback(
    (section: string, group?: string) => {
      return () => {
        if (!cookieConsent) {
          return;
        }

        let next: DeepPartial<ICookieConsent> = {};

        if (group) {
          next = {
            [section]: {
              [group]: !cookieConsent[section][group],
            },
          };
        } else {
          if (section === 'optional') {
            const checked = cookieConsent[section].analytical;
            next = {
              [section]: {
                analytical: !checked,
              },
            };
          }
        }
        onSavePreference(next);
      };
    },
    [cookieConsent, onSavePreference]
  );

  const essentialCookiesJSX = (
    <CookiesGroup title={t('legal.essential_cookies_group_title')}>
      {EssentialCookies.map((cookie) => {
        return <Cookie key={cookie.name} cookie={cookie} />;
      })}
    </CookiesGroup>
  );

  const optionalCookiesJSX = (
    <CookiesGroup
      title={t('legal.analytical_cookies_group_title')}
      text={t('legal.analytical_cookies_group_text')}
      showToggle
      checked={cookieConsent?.optional.analytical}
      onChange={onConsentChange('optional', 'analytical')}
    >
      {AnalyticalCookies.map((cookie) => {
        return <Cookie key={cookie.name} cookie={cookie} />;
      })}
    </CookiesGroup>
  );

  return (
    <div className="cookies-notice">
      <p>
        Please check our{' '}
        <Link to={`/${ROUTES.LEGAL}/${ROUTES.GLOSSARY}`}>Glossary</Link> for the
        definitions of any capitalized terms.
      </p>
      <p>
        Our Platform use cookies to distinguish you from other Users. This helps
        us to provide you with a good experience when you browse our Platform,
        and also allows us to improve our Platform and Services.
      </p>
      <p>
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your computer if you agree. Cookies contain
        information that is transferred to your computer’s hard drive.
      </p>
      <p>We use the following cookies:</p>
      <ul>
        <li>
          <strong>Essential cookies</strong>. These are cookies that are
          required for the operation of our Platform. These essential cookies
          are always enabled because our Platform won’t work properly without
          them. They include, for example, cookies that enable you to log into
          your Account. You can switch off these cookies in your browser
          settings, but you may then not be able to access all or parts of our
          Platform.
        </li>
        <li>
          <strong>Optional Analytical cookies</strong>. These cookies track
          Platform performance, usage patterns, and any issues Users may
          encounter. We use this data to ensure the Platform run smoothly, and
          to improve your experience on our Platform.
        </li>
      </ul>
      <p>
        You can find more information about the individual cookies we use and
        the purposes for which we use them in the “Manage Cookies” settings
        table below.
      </p>
      <p>
        You can choose which optional cookies we can set by clicking on the
        button(s) in the “Manage Cookies” table below. However, if you reject
        any optional cookies, or if you use your browser settings to block all
        cookies (including essential cookies), you may not be able to access all
        or parts of our Platform.
      </p>
      <p>
        Certain cookies are used by Microsoft Entra ID to manage User login and
        logout functionality on our Platform. When Users authenticate through
        Microsoft Entra ID, anonymized diagnostic data, such as telemetry and
        logs, may be shared with Microsoft to support this service.
      </p>
      <div className="cookies-notice__sections">
        <CookiesSection
          type="essential"
          title={t('legal.essential_cookies')}
          text={t('legal.essential_cookies_description')}
          showToggle={!isLegalPage}
          children={essentialCookiesJSX}
        />
        <CookiesSection
          type="optional"
          title={t('legal.optional_cookies')}
          text={t('legal.optional_cookies_description')}
          showToggle={!isLegalPage}
          children={optionalCookiesJSX}
          checked={cookieConsent?.optional.analytical}
          onChange={onConsentChange('optional')}
        />
      </div>
      <p>
        If you have any questions or concerns about our use of cookies, please
        send us an email at{' '}
        <a href="mailto:dataprivacy-sports@analog.io" className="cookies__link">
          dataprivacy-sports@analog.io
        </a>
        .
      </p>
    </div>
  );
};
