import { WorkoutsService } from './service';
import {
  IAcceptPrescriptionRequest,
  ICreateEventRequest,
  ICreateRuleRequest,
  IDeleteAthleteDayOffRequest,
  IDeleteEventRequest,
  IDeletePrescriptionRequest,
  IDeleteRuleRequest,
  IGetAthleteDayOffsRequest,
  IGetAthleteRulesRequest,
  IGetCalendarRequest,
  IGetEventByIdRequest,
  IGetEventsRequest,
  IGetManyPrescriptionsRequest,
  IGetPrescriptionRequest,
  IGetRuleByIdRequest,
  IGetWorkoutAnalysisRequest,
  IGetWorkoutRequest,
  IUpdateEventRequest,
  IUpdateRuleRequest,
  IUploadGPXRequest,
} from './types';
import { AxiosRequestConfig } from 'axios';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import {
  ICalendarDayOffs,
  ICalendarEvent,
  ICalendarRule,
  IWorkoutAnalysis,
  IWorkoutCalendar,
  IWorkoutPrescription,
  IWorkoutPrescriptionBase,
} from '../../types/workouts';

export interface IWorkoutsRepository {
  getWorkout: (
    p: IGetWorkoutRequest,
    c?: AxiosRequestConfig
  ) => Promise<string>;
  getWorkoutAnalysis: (
    p: IGetWorkoutAnalysisRequest,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutAnalysis>;
  getCalendar: (
    p: IGetCalendarRequest,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutCalendar>;
  getCalendarRule: (
    p: IGetRuleByIdRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarRule>;
  updateCalendarRule: (
    p: IUpdateRuleRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarRule>;
  deleteCalendarRule: (
    p: IDeleteRuleRequest,
    c?: AxiosRequestConfig
  ) => Promise<void>;
  createCalendarRule: (
    p: ICreateRuleRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarRule>;
  getAthleteRules: (
    p: IGetAthleteRulesRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarRule[]>;
  getAthleteDayOffs: (
    p: IGetAthleteDayOffsRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarDayOffs>;
  deleteAthleteDayOffs: (
    p: IDeleteAthleteDayOffRequest,
    c?: AxiosRequestConfig
  ) => Promise<void>;
  getEventById: (
    p: IGetEventByIdRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarEvent>;
  createEvent: (
    p: ICreateEventRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarEvent>;
  updateEvent: (
    p: IUpdateEventRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarEvent>;
  deleteEvent: (
    p: IDeleteEventRequest,
    c?: AxiosRequestConfig
  ) => Promise<void>;
  getEvents: (
    p: IGetEventsRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarEvent[]>;
  uploadGPX: (
    p: IUploadGPXRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICalendarEvent>;

  getPrescription: (
    p: IGetPrescriptionRequest,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutPrescription>;

  createPrescription: (
    p: IWorkoutPrescriptionBase,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutPrescription>;

  updatePrescription: (
    p: IWorkoutPrescriptionBase,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutPrescription>;

  deletePrescription: (
    p: IDeletePrescriptionRequest,
    c?: AxiosRequestConfig
  ) => Promise<void>;

  acceptPrescription: (
    p: IAcceptPrescriptionRequest,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutPrescription>;

  getManyPrescriptions: (
    p: IGetManyPrescriptionsRequest,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutPrescription[]>;
}

export class WorkoutsRepository
  extends BaseRepository
  implements IWorkoutsRepository
{
  constructor(
    private readonly service: WorkoutsService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getWorkout(
    p: IGetWorkoutRequest,
    c?: AxiosRequestConfig
  ): Promise<string> {
    const response = await this.handleRequest(this.service.getWorkout, p, c);
    return response.data;
  }

  async getWorkoutAnalysis(
    p: IGetWorkoutAnalysisRequest,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutAnalysis> {
    const response = await this.handleRequest(
      this.service.getWorkoutAnalysis,
      p,
      c
    );
    return response.data;
  }

  async getCalendar(
    p: IGetCalendarRequest,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutCalendar> {
    const response = await this.handleRequest(this.service.getCalendar, p, c);
    return response.data;
  }

  async getCalendarRule(
    p: IGetRuleByIdRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarRule> {
    const response = await this.handleRequest(
      this.service.getCalendarRule,
      p,
      c
    );
    return response.data;
  }

  async updateCalendarRule(
    p: IUpdateRuleRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarRule> {
    const response = await this.handleRequest(
      this.service.updateCalendarRule,
      p,
      c
    );
    return response.data;
  }

  async deleteCalendarRule(
    p: IDeleteRuleRequest,
    c?: AxiosRequestConfig
  ): Promise<void> {
    const response = await this.handleRequest(
      this.service.deleteCalendarRule,
      p,
      c
    );
    return response.data;
  }

  async createCalendarRule(
    p: ICreateRuleRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarRule> {
    const response = await this.handleRequest(
      this.service.createCalendarRule,
      p,
      c
    );
    return response.data;
  }

  async getAthleteRules(
    p: IGetAthleteRulesRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarRule[]> {
    const response = await this.handleRequest(
      this.service.getAthleteRules,
      p,
      c
    );
    return response.data;
  }

  async getAthleteDayOffs(
    p: IGetAthleteDayOffsRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarDayOffs> {
    const response = await this.handleRequest(
      this.service.getAthleteDayOffs,
      p,
      c
    );
    return response.data;
  }

  async deleteAthleteDayOffs(
    p: IDeleteAthleteDayOffRequest,
    c?: AxiosRequestConfig
  ): Promise<void> {
    const response = await this.handleRequest(
      this.service.deleteAthleteDayOffs,
      p,
      c
    );
    return response.data;
  }

  async getEventById(
    p: IGetEventByIdRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarEvent> {
    const response = await this.handleRequest(this.service.getEventById, p, c);
    return response.data;
  }

  async createEvent(
    p: ICreateEventRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarEvent> {
    const response = await this.handleRequest(this.service.createEvent, p, c);
    return response.data;
  }

  async updateEvent(
    p: IUpdateEventRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarEvent> {
    const response = await this.handleRequest(this.service.updateEvent, p, c);
    return response.data;
  }

  async deleteEvent(
    p: IDeleteEventRequest,
    c?: AxiosRequestConfig
  ): Promise<void> {
    const response = await this.handleRequest(this.service.deleteEvent, p, c);
    return response.data;
  }

  async getEvents(
    p: IGetEventsRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarEvent[]> {
    const response = await this.handleRequest(this.service.getEvents, p, c);
    return response.data;
  }

  async uploadGPX(
    p: IUploadGPXRequest,
    c?: AxiosRequestConfig
  ): Promise<ICalendarEvent> {
    const response = await this.handleRequest(this.service.uploadGPX, p, c);
    return response.data;
  }

  async getPrescription(
    p: IGetPrescriptionRequest,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutPrescription> {
    const response = await this.handleRequest(
      this.service.getPrescription,
      p,
      c
    );
    return response.data;
  }

  async createPrescription(
    p: IWorkoutPrescriptionBase,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutPrescription> {
    const response = await this.handleRequest(
      this.service.createPrescription,
      p,
      c
    );
    return response.data;
  }

  async updatePrescription(
    p: IWorkoutPrescriptionBase,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutPrescription> {
    const response = await this.handleRequest(
      this.service.updatePrescription,
      p,
      c
    );
    return response.data;
  }

  async deletePrescription(
    p: IDeletePrescriptionRequest,
    c?: AxiosRequestConfig
  ): Promise<void> {
    const response = await this.handleRequest(
      this.service.deletePrescription,
      p,
      c
    );
    return response.data;
  }

  async acceptPrescription(
    p: IAcceptPrescriptionRequest,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutPrescription> {
    const response = await this.handleRequest(
      this.service.acceptPrescription,
      p,
      c
    );
    return response.data;
  }

  async getManyPrescriptions(
    p: IGetManyPrescriptionsRequest,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutPrescription[]> {
    const response = await this.handleRequest(
      this.service.getManyPrescriptions,
      p,
      c
    );
    return response.data;
  }
}
