import './index.css';
import {
  Badge,
  Button,
  IButtonProps,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@cycling-web/analog-ui';
import { FilterIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { useBreakpoints } from '../../hooks/useBreakpoints';

export type IFiltersBarProps = {
  onClear?: () => void;
  filters?: ReactNode;
  collapse?: boolean;
  buttonProps?: IButtonProps;
};

export const FiltersBar = ({
  onClear,
  filters,
  collapse = false,
  buttonProps,
}: IFiltersBarProps) => {
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpoints();

  const anchor = (collapse || isSmallTablet) && (
    <Badge
      hide={true}
      color="primary"
      badge={''}
      content={
        <IconButton
          content={<FilterIcon />}
          {...buttonProps}
          size={buttonProps?.size || 'm'}
          variant={buttonProps?.variant || 'quietLayer2'}
        />
      }
    />
  );

  const content = (collapse || isSmallTablet) && (
    <div className="filters-popover-content">
      <header className="filters-popover-content__header">
        <Typography
          variant="headline"
          weight="bold"
          text={t('label.filters')}
        />
        {onClear && (
          <Button
            size="s"
            variant="quietLayer2"
            content={t('action.reset')}
            onClick={onClear}
          />
        )}
      </header>
      <div className="filters-popover-content__filters">{filters}</div>
    </div>
  );

  return (
    <div className="filters-bar">
      {collapse || isSmallTablet ? (
        <Tooltip
          anchor={
            <Popover
              anchor={anchor}
              content={content}
              minWidth="320px"
              maxWidth="320px"
              placement="bottom-end"
            />
          }
          content={t('tooltip.filters')}
          placement="bottom-end"
        />
      ) : (
        filters
      )}
    </div>
  );
};
