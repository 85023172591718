import { GroupsService } from './service';
import { IEditGroupFormData, IGroup, IGroupReport } from '../../types/groups';
import {
  IDeleteGroupRequest,
  IGetGroupByIdRequest,
  IGetGroupReportRequest,
} from './types';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';

/** The repository is responsible for data transformation */
export interface IGroupsRepository {
  getGroups: (c?: AxiosRequestConfig) => Promise<IGroup[]>;
  getGroupById: (
    p: IGetGroupByIdRequest,
    c?: AxiosRequestConfig
  ) => Promise<IGroup>;
  createGroup: (
    p: IEditGroupFormData,
    c?: AxiosRequestConfig
  ) => Promise<IGroup>;
  updateGroup: (
    p: IEditGroupFormData,
    c?: AxiosRequestConfig
  ) => Promise<IGroup>;
  deleteGroup: (
    p: IDeleteGroupRequest,
    c?: AxiosRequestConfig
  ) => Promise<void>;
  getGroupReport: (
    p: IGetGroupReportRequest,
    c?: AxiosRequestConfig
  ) => Promise<IGroupReport[]>;
}

export class GroupsRepository
  extends BaseRepository
  implements IGroupsRepository
{
  constructor(
    private readonly service: GroupsService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getGroups(c?: AxiosRequestConfig): Promise<IGroup[]> {
    const response = await this.handleRequest(this.service.getGroups, c);
    return response.data;
  }

  async getGroupById(
    p: IGetGroupByIdRequest,
    c?: AxiosRequestConfig
  ): Promise<IGroup> {
    const response = await this.handleRequest(this.service.getGroupById, p, c);
    return response.data.data[0];
  }

  async createGroup(
    p: IEditGroupFormData,
    c?: AxiosRequestConfig
  ): Promise<IGroup> {
    const copy = { ...p };
    // @ts-ignore
    delete copy.id;

    const response = await this.handleRequest(
      this.service.createGroup,
      copy,
      c
    );
    return response.data;
  }

  async updateGroup(
    p: IEditGroupFormData,
    c?: AxiosRequestConfig
  ): Promise<IGroup> {
    const response = await this.handleRequest(this.service.updateGroup, p, c);
    return response.data;
  }

  async deleteGroup(
    p: IDeleteGroupRequest,
    c?: AxiosRequestConfig
  ): Promise<void> {
    await this.handleRequest(this.service.deleteGroup, p, c);
    return;
  }

  async getGroupReport(
    p: IGetGroupReportRequest,
    c?: AxiosRequestConfig
  ): Promise<IGroupReport[]> {
    const response = await this.handleRequest(
      this.service.getGroupReport,
      p,
      c
    );
    return response.data;
  }
}
