import { useEffect, useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { useAthleteStore } from '../../store/athlete/slice';
import { useUserRole } from '../../hooks/useUserRole';
import { useAthletePerformanceStore } from '../AthletePerformance/store/slice';
import { useTrainingLoadStore } from '../AthleteTrainingLoad/store/slice';
import { useAthleteMedicalReportsStore } from '../AthleteMedicalReports/store/slice';
import { useAthleteWellnessStore } from '../AthleteWellness/store/slice';
import { useTitle } from '../../hooks/useTitle';
import { useFetchAthlete } from './hooks/useFetchAthlete';
import { useFetchPinnedTabs } from './hooks/useFetchPinnedTabs';
import { usePinnedTabStore } from '../../store/pinned-tabs/slice';
import { LS, URL_PARAMS } from '../../constants';

export const useViewModel = () => {
  useFetchAthlete();
  useFetchPinnedTabs();

  const { isAthlete } = useUserRole();
  const backUrl = useMemo(() => {
    const prevPage = localStorage.getItem(LS.PreviousPage);

    if (prevPage) {
      return prevPage;
    }

    if (isAthlete) {
      return '';
    }

    const tmp = window.location.pathname.split('/');
    const index = tmp.findIndex((s) => s === ROUTES.ATHLETES);

    if (index >= 0) {
      const nextUrl = tmp.filter((_, i) => i <= index).join('/');
      return isAthlete ? '' : nextUrl;
    }

    return '';
  }, [isAthlete]);

  const athlete = useAthleteStore((s) => s.athlete);

  useTitle(athlete?.fullName);

  useEffect(() => {
    return () => {
      useTrainingLoadStore.getState().reset();
      useAthletePerformanceStore.getState().reset();
      useAthleteMedicalReportsStore.getState().reset();
      useAthleteWellnessStore.getState().reset();
      usePinnedTabStore.getState().setPinnedTabsLoaded(false);
      usePinnedTabStore.getState().setPinnedTabs([]);
      useAthleteStore.getState().reset();

      const urlParams = new URLSearchParams(window.location.search);
      const clear_ls = urlParams.get(URL_PARAMS.ClearLs);
      if (clear_ls !== 'false') {
        localStorage.removeItem(LS.PreviousPage);
      }
    };
  }, []);

  return {
    backUrl,
  };
};
