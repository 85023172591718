import {
  IWorkoutLapStat,
  IWorkoutChannelData,
} from '../../../../types/workouts';

export const mapLapTimesToDistance = (
  laps: IWorkoutLapStat[],
  data: IWorkoutChannelData[],
  distanceIndex: number
): { start: number; end: number }[] => {
  const findClosest = (target: number) => {
    let closest: number | null = null;
    let minDiff = Infinity;

    for (const item of data) {
      const diff = Math.abs(item.MillisecondOffset - target);
      const value = item.Values?.[distanceIndex];
      if (value != null && diff < minDiff) {
        closest = value;
        minDiff = diff;
      }
    }

    return closest ?? 0;
  };

  return laps.map((lap: IWorkoutLapStat) => ({
    start: findClosest(lap.StartTimeMs),
    end: findClosest(lap.EndTimeMs),
  }));
};
