import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Divider,
  FormElement,
  Message,
  Typography,
} from '@cycling-web/analog-ui';
import { WorkoutsRepository } from '../../../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../../../api/workouts/service';
import { IWorkoutForm } from './types';
import { useWorkoutsCalendarContext } from '../../../../context';
import { useCalendarAddContext } from '../../context';
import { useFetchWorkoutCalendar } from '../../../../hooks/useFetchWorkoutCalendar';
import {
  DatepickerControl,
  FormColumn,
  FormRow,
  InputControl,
  InputNumberControl,
  InputTimeControl,
  TextareaControl,
} from '@cycling-web/common';
import { addDays } from 'date-fns';
import { IWorkoutPrescriptionBase } from '../../../../../../types/workouts';
import {
  hhmmToHours,
  hoursToHHMM,
  localWithUTCMidnightString,
} from '../../../../../../utils/date-time';
import { useFetchTrainingZones } from '../../../../../../hooks/useFetchTrainingZones';
import { parseAthleteProfiles } from '../../../../../AthleteProfile/utils/profiles';
import { IAthleteProfile } from '../../../../../../types/athlete-profiles';
import { TrainingZoneProfiles } from '../../../../../AthleteProfile/components/TrainingZones/components/TrainingZoneProfiles';
import { TrainingZoneKeys } from '../../../../../AthleteProfile/utils/getDefaults';
import { TrainingZoneProfilesLoading } from '../../../../../AthleteProfile/components/TrainingZones/components/TrainingZoneProfilesLoading';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingForm,
} from '../../../../../../ms/tracking-entities';

export const WorkoutForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { athlete, date } = useWorkoutsCalendarContext();
  const { onDismiss, handleDismiss, defaultPrescription, defaultDate } =
    useCalendarAddContext();
  const { fetchWorkoutCalendar } = useFetchWorkoutCalendar();
  const { fetchTrainingZones } = useFetchTrainingZones();
  const [trainingZoneProfiles, setTrainingZoneProfiles] = useState<Record<
    string,
    IAthleteProfile
  > | null>(null);

  useEffect(() => {
    if (!athlete) {
      return;
    }

    fetchTrainingZones(athlete.id).then((response) => {
      setTrainingZoneProfiles(parseAthleteProfiles(response));
    });
  }, [athlete, fetchTrainingZones]);

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        title: z.string().min(1, { message: t('validation.required') }),
        description: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const defaultValues = useMemo((): IWorkoutForm => {
    if (defaultPrescription) {
      return {
        title: defaultPrescription.title,
        description: defaultPrescription.description,
        workoutDay: new Date(defaultPrescription.workoutDay),
        tss: defaultPrescription.tss || '',
        distanceInKms: defaultPrescription.distanceInKms || '',
        durationInHrs: hoursToHHMM(defaultPrescription.durationInHrs),
        elevationInMtrs: defaultPrescription.elevationInMtrs || '',
        energyInKJs: defaultPrescription.energyInKJs || '',
      };
    }

    return {
      title: '',
      description: '',
      workoutDay: defaultDate || addDays(new Date(), 1),
      tss: '',
      distanceInKms: '',
      durationInHrs: '',
      elevationInMtrs: '',
      energyInKJs: '',
    };
  }, [defaultDate, defaultPrescription]);

  const form = useForm<IWorkoutForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IWorkoutForm) => {
      if (!athlete) {
        return;
      }

      setLoading(true);

      const payload: IWorkoutPrescriptionBase = {
        athleteId: athlete.id,
        workoutDay: localWithUTCMidnightString(formData.workoutDay),
        title: formData.title,
        description: formData.description,
        tss: Number(formData.tss),
        distanceInKms: Number(formData.distanceInKms),
        durationInHrs: hhmmToHours(formData.durationInHrs),
        elevationInMtrs: Number(formData.elevationInMtrs),
        energyInKJs: Number(formData.energyInKJs),
      };

      if (defaultPrescription?.id) {
        payload.id = defaultPrescription.id;
      }

      const request =
        payload.id === undefined
          ? workoutsRepository.createPrescription(payload)
          : workoutsRepository.updatePrescription(payload);

      return request
        .then(() => {
          return fetchWorkoutCalendar(date, athlete.id.toString());
        })
        .then(() => {
          onDismiss();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      athlete,
      date,
      defaultPrescription,
      fetchWorkoutCalendar,
      onDismiss,
      workoutsRepository,
    ]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.WORKOUT_PRESCRIPTION,
    });

    handleSubmit(
      (formData: IWorkoutForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  const hasZones = useMemo(() => {
    return Object.values(trainingZoneProfiles ?? {}).some(
      (p) => p.zones.length > 0
    );
  }, [trainingZoneProfiles]);

  return (
    <FormProvider {...form}>
      <div className="workout-form">
        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.title')}
              message={errors.title?.message}
            >
              <InputControl
                name="title"
                placeholder={t('placeholder.name')}
                invalid={!!errors.title}
                autoComplete="off"
              />
            </FormElement>

            <FormElement label={t('label.date')}>
              <DatepickerControl name="workoutDay" min={new Date()} />
            </FormElement>
          </FormColumn>
          <FormColumn className="calendar-workout-form__col">
            <FormElement
              label={t('label.description')}
              message={errors.description?.message}
            >
              <TextareaControl
                name="description"
                placeholder={t('placeholder.description')}
                invalid={!!errors.description}
              />
            </FormElement>
          </FormColumn>
        </FormRow>
        <Divider />

        <Typography
          variant="headline"
          weight="bold"
          color="text-primary"
          text={t('label.target')}
        />

        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.duration')}
              message={errors.durationInHrs?.message}
            >
              <InputTimeControl
                name="durationInHrs"
                placeholder="hh:mm"
                invalid={!!errors.durationInHrs}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.distance')}
              message={errors.distanceInKms?.message}
            >
              <InputNumberControl
                name="distanceInKms"
                placeholder={t('placeholder.distance')}
                invalid={!!errors.distanceInKms}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.elevation')}
              message={errors.elevationInMtrs?.message}
            >
              <InputNumberControl
                name="elevationInMtrs"
                placeholder={t('placeholder.elevation_gain')}
                invalid={!!errors.elevationInMtrs}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
        </FormRow>

        <FormRow>
          <FormColumn>
            <FormElement label={t('label.tss')} message={errors.tss?.message}>
              <InputNumberControl
                name="tss"
                placeholder={t('placeholder.tss')}
                invalid={!!errors.tss}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              label={t('label.work')}
              message={errors.energyInKJs?.message}
            >
              <InputNumberControl
                name="energyInKJs"
                placeholder={t('placeholder.work_in_kj')}
                invalid={!!errors.energyInKJs}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
          <FormColumn />
        </FormRow>
        <Divider />
        <Typography
          variant="headline"
          weight="bold"
          color="text-primary"
          text={t('label.zones')}
        />
        {hasZones ? (
          <FormRow className="calendar-workout-form__zones">
            <FormColumn>
              {trainingZoneProfiles ? (
                <TrainingZoneProfiles
                  profiles={[
                    trainingZoneProfiles[TrainingZoneKeys.CyclingPowerMFTP],
                    trainingZoneProfiles[TrainingZoneKeys.CyclingPowerMCP],
                  ]}
                  athlete={athlete}
                  emptyStateProps={{
                    text: t('banner.empty_training_zones_message'),
                  }}
                />
              ) : (
                <TrainingZoneProfilesLoading />
              )}
            </FormColumn>
            <FormColumn>
              {trainingZoneProfiles ? (
                <TrainingZoneProfiles
                  profiles={[
                    trainingZoneProfiles[
                      TrainingZoneKeys.CyclingHeartRateDefault
                    ],
                  ]}
                  athlete={athlete}
                  emptyStateProps={{
                    text: t('banner.empty_training_zones_message'),
                  }}
                />
              ) : (
                <TrainingZoneProfilesLoading />
              )}
            </FormColumn>
          </FormRow>
        ) : (
          <Message text={t('banner.empty_training_zones_message')} />
        )}
        <footer className="workout-form__footer">
          <Button
            variant="quietLayer3"
            content={t('action.cancel')}
            onClick={handleDismiss}
          />
          <Button
            content={defaultPrescription ? t('action.save') : t('action.add')}
            onClick={onSubmit}
            loading={loading}
          />
        </footer>
      </div>
    </FormProvider>
  );
};
