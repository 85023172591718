import './index.css';
import { useEffect, useMemo, useState } from 'react';
import { RouteMapView } from './RouteMapView';
import { IRouteMapMarker, IRouteMapProps } from './types';
import { ViewState } from 'react-map-gl/mapbox';
import { EmptyState, Spinner } from '@cycling-web/analog-ui';
import { MapPinOff } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const RouteMap = ({
  data,
  laps,
  loading,
  activePosition,
}: IRouteMapProps) => {
  const { t } = useTranslation();
  const [viewState, setViewState] = useState<ViewState | undefined>(undefined);
  const [routeGeoJSON, setRouteGeoJSON] = useState<GeoJSON.GeoJSON | null>(
    null
  );

  useEffect(() => {
    if (data && data.length > 0) {
      const geoJSON: GeoJSON.GeoJSON = {
        type: 'Feature',
        properties: [],
        geometry: {
          type: 'LineString',
          coordinates: data.map((point) => [point.longitude, point.latitude]),
        },
      };
      setRouteGeoJSON(geoJSON);

      // Calculate bounds
      const bounds = data.reduce(
        (acc, point) => {
          return {
            minLng: Math.min(acc.minLng, point.longitude),
            maxLng: Math.max(acc.maxLng, point.longitude),
            minLat: Math.min(acc.minLat, point.latitude),
            maxLat: Math.max(acc.maxLat, point.latitude),
          };
        },
        {
          minLng: data[0].longitude,
          maxLng: data[0].longitude,
          minLat: data[0].latitude,
          maxLat: data[0].latitude,
        }
      );

      // Calculate center point of the route
      const centerLng = (bounds.minLng + bounds.maxLng) / 2;
      const centerLat = (bounds.minLat + bounds.maxLat) / 2;

      // Consider aspect ratio of the container (height: 400px, width is variable)
      // We assume the map container has roughly the same aspect ratio as the window
      const mapAspectRatio = window.innerWidth / 400;

      // Calculate the differences in coordinates
      const lngDiff = bounds.maxLng - bounds.minLng;
      const latDiff = bounds.maxLat - bounds.minLat;

      // Adjust differences based on the Mercator projection (latitude distortion)
      const latitudeAdjustedLngDiff =
        lngDiff * Math.cos((centerLat * Math.PI) / 180);

      // Determine which dimension is limiting based on aspect ratio
      let limitingDiff;
      if (latitudeAdjustedLngDiff / latDiff > mapAspectRatio) {
        // Width is the limiting factor
        limitingDiff = latitudeAdjustedLngDiff;
      } else {
        // Height is the limiting factor
        limitingDiff = latDiff;
      }

      // Calculate zoom with a small buffer
      let zoom = Math.log2(360 / (limitingDiff * 1.1)) - 1;

      // Ensure we have a reasonable zoom level
      zoom = Math.min(Math.max(zoom, 1), 18);

      // Update viewState with calculated values
      setViewState({
        longitude: centerLng,
        latitude: centerLat,
        zoom: zoom,
        pitch: 0,
        bearing: 0,
        padding: {},
      });
    } else {
      setRouteGeoJSON(null);
    }
  }, [data]);

  const markers: IRouteMapMarker[] =
    data && data.length > 0
      ? [
          {
            longitude: data[0].longitude,
            latitude: data[0].latitude,
            color: 'var(--analog-support-green)',
          },
          {
            longitude: data[data.length - 1].longitude,
            latitude: data[data.length - 1].latitude,
            color: 'var(--analog-support-red)',
          },
        ]
      : [];

  const activePointFeature = useMemo(() => {
    if (!activePosition) {
      return null;
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [activePosition.longitude, activePosition.latitude],
          },
          properties: {},
        },
      ],
    };
  }, [activePosition]);

  if (loading) {
    return (
      <div className="route-map-wrapper">
        <EmptyState size="l" icon={<Spinner size="l" />} />
      </div>
    );
  }

  if (!routeGeoJSON || !viewState || data?.length === 0) {
    return (
      <div className="route-map-wrapper">
        <EmptyState
          size="l"
          icon={<MapPinOff size={32} />}
          text={t('banner.empty_route_map_text')}
        />
      </div>
    );
  }

  return (
    <div className="route-map-wrapper">
      <RouteMapView
        viewState={viewState}
        routeGeoJSON={routeGeoJSON}
        setViewState={setViewState}
        markers={markers}
        laps={laps}
        activePosition={activePointFeature}
      />
    </div>
  );
};
