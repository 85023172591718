import './index.css';
import { useGroupsStore } from '../../../../store/groups/slice';
import { useContext, useMemo } from 'react';
import { filterGroupDelegate } from '../../utils/filterGroupDelegate';
import { IGroup, IGroupsFilters } from '../../../../types/groups';
import { GroupCard } from '../GroupCard';
import { EmptyState, IEmptyStateProps } from '@cycling-web/analog-ui';
import { Users } from 'lucide-react';
import { DEFAULT_GROUP, GroupsContext } from '../../context';
import { useTranslation } from 'react-i18next';
import { usePageFilters } from '../../../../context/PageFilters';

export const GroupList = () => {
  const { t } = useTranslation();
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);
  const items = useGroupsStore((s) => s.groups);
  const { filters } = usePageFilters<IGroupsFilters>();
  const { openGroupFormModal } = useContext(GroupsContext);

  const filteredItems = useMemo(() => {
    return items.filter(filterGroupDelegate(filters));
  }, [items, filters]);

  const groupsJSX = filteredItems.map((g: IGroup) => {
    return <GroupCard group={g} key={g.id} />;
  });

  const emptyStateProps: IEmptyStateProps = {
    title: t('banner.empty_groups_title'),
    text: t('banner.empty_groups_message'),
    icon: <Users size={32} />,
    buttonProps: {
      content: t('banner.empty_groups_action'),
      onClick: openGroupFormModal(DEFAULT_GROUP),
    },
  };

  const emptyFilteredStateProps: IEmptyStateProps = {
    title: t('banner.empty_filtered_groups_title'),
    text: t('banner.empty_filtered_groups_message'),
    icon: <Users size={32} />,
  };

  return groupsLoaded ? (
    items.length === 0 ? (
      <EmptyState {...emptyStateProps} />
    ) : filteredItems.length === 0 ? (
      <EmptyState {...emptyFilteredStateProps} />
    ) : (
      <div className="groups-list">{groupsJSX}</div>
    )
  ) : (
    <div className="groups-list">
      <GroupCard loading />
      <GroupCard loading />
    </div>
  );
};
