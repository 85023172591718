import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback } from 'react';
import {
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { useWorkoutStore } from '../../store/slice';
import { useTableColumns } from './useTableColumns';
import { IWorkoutLapStat } from '../../../../types/workouts';
import { hoursToHHMMSS } from '../../../../utils/date-time';
import { EMPTY } from '../../../../constants';
import { roundToDecimals } from '../../../../utils/maths';

export const useViewModel = () => {
  const { t } = useTranslation();

  const workout = useWorkoutStore((s) => s.workout);
  const loaded = useWorkoutStore((s) => s.workoutLoaded);

  const columns: ITableColumn[] = useTableColumns();
  const items = workout?.WorkoutDetail?.LapStats ?? [];

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IWorkoutLapStat;

    const renderMap: Record<string, ReactNode> = {
      Name: castedItem.Name,
      EndTimeMs: castedItem.ElapsedTimeMs
        ? hoursToHHMMSS(castedItem.ElapsedTimeMs / (1000 * 3600))
        : hoursToHHMMSS(
            (castedItem.EndTimeMs - castedItem.StartTimeMs) / (1000 * 3600)
          ),
      IF: castedItem.IF ? roundToDecimals(castedItem.IF, 2) : EMPTY,
      PowerAverage: castedItem.PowerAverage
        ? roundToDecimals(castedItem.PowerAverage, 1)
        : EMPTY,
      NP: EMPTY,
      SpeedAverage: castedItem.SpeedAverage
        ? roundToDecimals(castedItem.SpeedAverage, 1)
        : EMPTY,
      CadenceAverage: castedItem.CadenceAverage
        ? roundToDecimals(castedItem.CadenceAverage, 1)
        : EMPTY,
      HeartRateAverage: castedItem.HeartRateAverage
        ? roundToDecimals(castedItem.HeartRateAverage, 1)
        : EMPTY,
      Distance: EMPTY,
      Climbing: castedItem.ElevationGain
        ? roundToDecimals(castedItem.ElevationGain, 1)
        : EMPTY,
      WattsPerKg: castedItem.WattsPerKg || EMPTY,
      VAM: castedItem.VAM,
      EnergyRight: castedItem.EnergyRight,
      NM: EMPTY,
      NMKG: EMPTY,
      Grade: castedItem.Grade ? roundToDecimals(castedItem.Grade, 2) : EMPTY,
    };

    return renderMap[column.key];
  };

  const emptyStateProps: IEmptyStateProps | undefined =
    loaded && items.length === 0
      ? {
          text: t('banner.empty_laps_message'),
        }
      : undefined;

  const onRowsSelect = useCallback((items: ITableItem[]) => {
    useWorkoutStore.getState().selectLaps(items as IWorkoutLapStat);
  }, []);

  return {
    columns,
    items,
    loading: !loaded,
    emptyStateProps,
    onRenderCell,
    onRowsSelect,
  };
};
