import './index.css';
import { IEmptyStateProps, IEmptyStateSizeMap } from './types';
import { clsx } from 'clsx';
import { Button } from '../Button/Button';
import { Typography } from '../Typography';

export const EmptyState = ({
  icon,
  title,
  text,
  buttonProps,
  secondButtonProps,
  className,
  size = 'l',
}: IEmptyStateProps) => {
  const rootClass: string = clsx(
    'analog-emptyState',
    `analog-emptyState--${size}`,
    icon && `analog-emptyState--withIcon`,
    className
  );

  const sizeMap: IEmptyStateSizeMap = {
    xs: {
      title: {
        variant: 'body',
      },
      text: {
        variant: 'subtitle',
      },
      button: {
        size: 'xs',
      },
    },
    s: {
      title: {
        variant: 'headline',
      },
      text: {
        variant: 'body',
      },
      button: {
        size: 's',
      },
    },
    m: {
      title: {
        variant: 'h4',
      },
      text: {
        variant: 'body',
      },
      button: {
        size: 's',
      },
    },
    l: {
      title: {
        variant: 'h3',
      },
      text: {
        variant: 'headline',
      },
      button: {
        size: 'm',
      },
    },
  };

  return (
    <div className={rootClass}>
      <div className="analog-emptyState__inner">
        {!!icon && (
          <div className="analog-emptyState__illustration" role="presentation">
            {icon}
          </div>
        )}
        {!!title && (
          <Typography
            className="analog-emptyState__heading"
            as="h3"
            variant={sizeMap[size].title.variant}
            text={title}
          />
        )}
        {!!text && (
          <Typography
            className="analog-emptyState__text"
            as="p"
            variant={sizeMap[size].text.variant}
            text={text}
          />
        )}
        {!!buttonProps && (
          <div className="analog-emptyState__actions">
            {secondButtonProps && (
              <Button {...sizeMap[size].button} {...secondButtonProps} />
            )}
            <Button {...sizeMap[size].button} {...buttonProps} />
          </div>
        )}
      </div>
    </div>
  );
};
