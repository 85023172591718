import {
  AthleteProfileMethod,
  IAthleteProfile,
  TrainingZoneMethod,
  TrainingZoneParameter,
} from '../../../types/athlete-profiles';
import { getTrainingZoneKeys } from './getDefaults';
import { Discipline } from '../../../types/enums';

export const parseAthleteProfiles = (
  athleteProfiles: IAthleteProfile[]
): Record<string, IAthleteProfile> => {
  const keys = getTrainingZoneKeys();
  const trainingProfiles: Record<string, IAthleteProfile | undefined> = {};

  keys.forEach((key) => {
    trainingProfiles[key] = undefined;
  });

  athleteProfiles.forEach((p) => {
    const discipline = p.type;
    const parameter =
      discipline === Discipline.Cycling
        ? p.method === AthleteProfileMethod.HeartRate
          ? TrainingZoneParameter.HeartRate
          : TrainingZoneParameter.Power
        : p.method;
    const method =
      discipline === Discipline.Cycling
        ? p.method === AthleteProfileMethod.HeartRate
          ? TrainingZoneMethod.Default
          : p.method
        : TrainingZoneMethod.Default;

    const key = `${discipline}_${parameter}_${method}`;

    trainingProfiles[key] = {
      ...p,
      composeKey: key,
    };
  });

  Object.keys(trainingProfiles).forEach((key) => {
    if (trainingProfiles[key] === undefined) {
      const [discipline, parameter, method] = key.split('_');
      trainingProfiles[key] = {
        type: discipline as Discipline,
        method: (discipline === Discipline.Cycling
          ? method
          : parameter) as AthleteProfileMethod,
        zones: [],
        custom: false,
        composeKey: key,
      };
    }
  });

  return trainingProfiles as Record<string, IAthleteProfile>;
};
