import { WellnessService } from './service';
import {
  IBodyComposition,
  IHeartMeasurement,
  ISelfReportedWellness,
  ISubjectiveFeedbackFormData,
  IWellness,
} from '../../types/wellness';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  IGetBodyCompositionRequest,
  IGetHeartMeasurementsRequest,
  IGetSelfReportedWellnessRequest,
} from './types';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';

/** The repository is responsible for data transformation */
export interface IWellnessRepository {
  getLastUpdatedWellness: (
    athleteId: string | number,
    c?: AxiosRequestConfig
  ) => Promise<IWellness>;

  getSelfReportedWellness: (
    p: IGetSelfReportedWellnessRequest,
    c?: AxiosRequestConfig
  ) => Promise<ISelfReportedWellness>;

  getBodyComposition: (
    p: IGetBodyCompositionRequest,
    c?: AxiosRequestConfig
  ) => Promise<IBodyComposition>;

  getHeartMeasurements: (
    p: IGetHeartMeasurementsRequest,
    c?: AxiosRequestConfig
  ) => Promise<IHeartMeasurement>;

  reportSelfWellness: (
    p: ISubjectiveFeedbackFormData,
    c?: AxiosRequestConfig
  ) => Promise<AxiosResponse<void>>;
}

export class WellnessRepository
  extends BaseRepository
  implements IWellnessRepository
{
  constructor(
    private readonly service: WellnessService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getLastUpdatedWellness(
    athleteId: string | number,
    c?: AxiosRequestConfig
  ): Promise<IWellness> {
    const response = await this.handleRequest(
      this.service.getLastUpdatedWellness,
      { athleteId: +athleteId },
      c
    );
    return response.data;
  }

  async getSelfReportedWellness(
    p: IGetSelfReportedWellnessRequest,
    c?: AxiosRequestConfig
  ): Promise<ISelfReportedWellness> {
    const response = await this.handleRequest(
      this.service.getSelfReportedWellness,
      p,
      c
    );
    return response.data;
  }

  async getBodyComposition(
    p: IGetBodyCompositionRequest,
    c?: AxiosRequestConfig
  ): Promise<IBodyComposition> {
    const response = await this.handleRequest(
      this.service.getBodyComposition,
      p,
      c
    );
    return response.data;
  }

  async getHeartMeasurements(
    p: IGetHeartMeasurementsRequest,
    c?: AxiosRequestConfig
  ): Promise<IHeartMeasurement> {
    const response = await this.handleRequest(
      this.service.getHeartMeasurements,
      p,
      c
    );
    return response.data;
  }

  async reportSelfWellness(
    p: ISubjectiveFeedbackFormData,
    c?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return this.handleRequest(this.service.reportSelfWellness, p, c);
  }
}
