import './index.css';
import { ChevronUp, Ellipsis, Maximize2, Minimize2 } from 'lucide-react';
import { clsx } from 'clsx';
import {
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  Tooltip,
  Typography,
  useBoolean,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { MouseEvent, use, useCallback, useMemo } from 'react';
import { useAiAssistantStore } from '../../store/slice';
import { AIService } from '../../../../api/ai/service';
import { AIRepository } from '../../../../api/ai/repository';
import { useParams } from 'react-router';
import { FeedbackModal } from '../FeedbackModal';
import { DEFAULT_MESSAGE } from '../../constants';
import { ConversationContext } from '../../../Conversation';

export const Toggle = () => {
  const { t } = useTranslation();
  const { handleStopPlayMessage } = use(ConversationContext);
  const { athleteId } = useParams();
  const isOpen = useAiAssistantStore((s) => s.isOpen);
  const toggleIsOpen = useAiAssistantStore((s) => s.toggleIsOpen);
  const setMessages = useAiAssistantStore((s) => s.setMessages);
  const isExpanded = useAiAssistantStore((s) => s.isExpanded);
  const toggleIsExpanded = useAiAssistantStore((s) => s.toggleIsExpanded);

  const {
    value: showFeedbackModal,
    setTrue: openFeedbackModal,
    setFalse: dismissFeedbackModal,
  } = useBoolean(false);

  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);

  const rootClass = clsx(
    'ai-assistant__toggle',
    isOpen && 'ai-assistant__toggle--open'
  );

  const menuOptions: IMenuOption[] = [
    {
      id: 'reset_conversation',
      text: t('label.reset_conversation'),
    },
    {
      id: 'send_feedback',
      text: t('label.send_feedback'),
    },
  ];

  const handleResetConversation = useCallback(() => {
    if (!athleteId) {
      return;
    }
    handleStopPlayMessage();
    aiRepository
      .closeConversation({
        athleteId: +athleteId,
      })
      .then(() => {
        setMessages([DEFAULT_MESSAGE]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [aiRepository, athleteId, handleStopPlayMessage, setMessages]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'reset_conversation') {
        handleResetConversation();
      }
      if (option.id === 'send_feedback') {
        openFeedbackModal();
      }
    },
    [handleResetConversation, openFeedbackModal]
  );

  const handleOpen = useCallback(() => {
    if (isOpen) {
      handleStopPlayMessage();
      if (isExpanded) {
        toggleIsExpanded();
      }
    }
    toggleIsOpen();
  }, [
    handleStopPlayMessage,
    isExpanded,
    isOpen,
    toggleIsExpanded,
    toggleIsOpen,
  ]);

  const handleExpand = useCallback(() => {
    toggleIsExpanded();
  }, [toggleIsExpanded]);

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className={rootClass} onClick={handleOpen}>
      <div className="ai-assistant__toggle-title">
        <Typography
          variant="headline"
          weight="bold"
          text={t('label.coach_ana')}
          className="ai-assistant__toggle-titleText"
        />
      </div>
      <div className="ai-assistant__toggle-actions">
        {isOpen && (
          <div
            className="ai-assistant__toggle-open-actions"
            onClick={stopPropagation}
          >
            <Menu
              options={menuOptions}
              onChange={onMenuChange}
              dropdownProps={{
                anchor: (
                  <Tooltip
                    anchor={<IconButton content={<Ellipsis />} size="s" />}
                    content={t('tooltip.options')}
                    placement="bottom-end"
                  />
                ),
                placement: 'bottom-end',
              }}
            />
            <Tooltip
              anchor={
                <IconButton
                  size="s"
                  content={isExpanded ? <Minimize2 /> : <Maximize2 />}
                  onClick={handleExpand}
                />
              }
              content={isExpanded ? t('tooltip.collapse') : t('tooltip.expand')}
              placement="bottom-end"
            />
          </div>
        )}
        <Tooltip
          anchor={
            <IconButton
              size="s"
              content={<ChevronUp className="ai-assistant__toggle-chevron" />}
            />
          }
          content={isOpen ? t('tooltip.close') : t('tooltip.open')}
          placement="bottom-end"
        />
      </div>

      {showFeedbackModal && <FeedbackModal onDismiss={dismissFeedbackModal} />}
    </div>
  );
};
