import { WorkOptions } from '../types/enums';

export enum TsbStatus {
  Detraining = 'detraining',
  CompetitionReady = 'competition_ready',
  Transitional = 'transitional',
  ProductiveTraining = 'productive_training',
  Overreaching = 'overreaching',
}

export const DurabilityWorkMap: Record<string, WorkOptions> = {
  dataPointsAfter2kKJ: WorkOptions.kJ2000,
  dataPointsAfter3kKJ: WorkOptions.kJ3000,
  dataPointsAfter4kKJ: WorkOptions.kJ4000,
  dataPointsAfter1900KJ: WorkOptions.kJ1900,
  dataPointsAfter2800KJ: WorkOptions.kJ2800,
  dataPointsAfter3500KJ: WorkOptions.kJ3500,
  dataPointsAfter4500KJ: WorkOptions.kJ4500,
};
