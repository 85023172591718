import './index.css';
import { Skeleton, Tooltip } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../../../types/athletes';
import { Parameter } from '../Parameter';
import { useTranslation } from 'react-i18next';
import { differByPercent } from '../../../../../../utils/maths';

type IProps = {
  athlete: IAthlete | null | undefined;
  loading?: boolean;
};

export const SFTP = ({ athlete, loading }: IProps) => {
  const { t } = useTranslation();

  const getVariance = (value: number | undefined) => {
    if (!value) {
      return null;
    }

    const sign = value > 0 ? '+' : '';
    const signValue = `${sign}${value.toFixed(0)}`;
    return (
      <span className="analog-typography--subtitle regular">({signValue})</span>
    );
  };

  const sftpValue = athlete?.sftp ? Math.round(athlete.sftp).toString() : '--';
  const label = t('label.sftp');
  const value = (
    <>
      <span>{getVariance(0)}</span>
      <span>{sftpValue}</span>
    </>
  );

  const mftp = Math.abs(athlete?.mftp || 0);
  const sftp = Math.abs(athlete?.sftp || 0);
  const threshold = 7;
  const isMarked = differByPercent(mftp, sftp, threshold);

  if (loading) {
    return <Skeleton width="100px" height="24px" />;
  }

  if (isMarked) {
    return (
      <Tooltip
        anchor={
          <Parameter
            label={label}
            value={value}
            className={isMarked ? 'sftp__marked' : undefined}
          />
        }
        content={t('label.sftp_message')}
        placement="bottom-end"
        maxWidth="420px"
      />
    );
  }

  return (
    <Parameter
      label={label}
      value={value}
      className={isMarked ? 'sftp__marked' : undefined}
    />
  );
};
