import './index.css';
import { ZoneControl } from '../ZoneControl';
import { useFieldArray } from 'react-hook-form';
import { Button, EmptyState } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { use, useCallback } from 'react';
import { Plus } from 'lucide-react';
import { ZonesModalContext } from '../../context';
import { Discipline } from '../../../../../../types/enums';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../../../ms/tracking-entities';

export const ZonesForm = () => {
  const { t } = useTranslation();
  const { composeKey } = use(ZonesModalContext);
  const [discipline] = composeKey.split('_');
  const isCycling = discipline === Discipline.Cycling;

  const { fields, append, remove } = useFieldArray({
    name: 'zones',
  });

  const addZone = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.ADD_TRAINING_ZONE,
    });

    append({
      name: `Zone ${fields.length + 1}`,
      color: 'LightBlue',
      from: 0,
      to: 0,
    });
  }, [append, fields.length]);

  const removeZone = useCallback(
    (index: number) => {
      trackUserInteractionEvent(TrackingEvent.CLICK, {
        action: TrackingAction.DELETE_TRAINING_ZONE,
      });

      return () => {
        if (fields.length > 1) {
          remove(index);
        }
      };
    },
    [fields.length, remove]
  );

  return (
    <div className="training-zones-form">
      {fields.length === 0 && (
        <EmptyState
          size="s"
          text={
            isCycling
              ? t('banner.empty_zones_power_text')
              : t('banner.empty_zones_pace_text')
          }
        />
      )}

      {fields.map((field, index) => (
        <ZoneControl
          key={field.id}
          index={index}
          removeZone={removeZone(index)}
        />
      ))}

      {fields.length > 0 && fields.length <= 8 && (
        <div className="training-zones-form__actions">
          <Button
            variant="secondary"
            startIcon={<Plus />}
            content={t('action.add_what', {
              what: ` ${t('label.zone').toLowerCase()}`,
            })}
            onClick={addZone}
          />
        </div>
      )}
    </div>
  );
};
