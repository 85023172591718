import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { ISelfReportedWellness } from '../../../types/wellness';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteWellnessStore } from '../store/slice';
import { calculateDateRange } from '../../../utils/utils';
import { getUTCMidnight } from '../../../utils/date-time';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';

export const useFetchAthleteSelfReportedWellness = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const setSelfReportedWellness = useAthleteWellnessStore(
    (s) => s.setSelfReportedWellness
  );
  const setSelfReportedWellnessLoaded = useAthleteWellnessStore(
    (s) => s.setSelfReportedWellnessLoaded
  );
  const { filters } = usePageFilters<IAthleteDetailsFilters>();

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || filters === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    wellnessRepository
      .getSelfReportedWellness({
        athleteId: +athleteId,
        startTime: getUTCMidnight(startDate).getTime(),
        endTime: getUTCMidnight(endDate).getTime(),
      })
      .then((data: ISelfReportedWellness) => {
        setSelfReportedWellness(data);
      })
      .catch((error: AxiosError) => {
        setSelfReportedWellnessLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_athlete_wellness_title'),
              message: t('error.get_athlete_wellness_message'),
            },
            { toastId: 'get_athlete_wellness' }
          );
        }
      });
  }, [
    athleteId,
    filters,
    setSelfReportedWellness,
    setSelfReportedWellnessLoaded,
    t,
    wellnessRepository,
  ]);
};
