import { useCallback, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_HIGH,
  createCommand,
  KEY_DOWN_COMMAND,
  LexicalNode,
} from 'lexical';
import { $createHintNode, $isHintNode } from '../node/HintNode';
import { mergeRegister } from '@lexical/utils';

export const INSERT_HINT_COMMAND = createCommand('INSERT_HINT');
export const REMOVE_HINT_COMMAND = createCommand('REMOVE_HINT');

export default function HintPlugin() {
  const [editor] = useLexicalComposerContext();

  const removeHintNode = useCallback(() => {
    editor.update(() => {
      $getRoot()
        .getChildren()
        .forEach((node: LexicalNode) => {
          if ($isElementNode(node)) {
            node.getChildren().forEach((childNode) => {
              if ($isHintNode(childNode)) {
                childNode.remove();
              }
            });
          }
        });
    });
  }, [editor]);

  const insertHintNode = useCallback(
    (hintText: string) => {
      editor.update(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
          return;
        }

        const currentSelection = selection.clone();

        const anchorNode = selection.anchor.getNode();
        const parentNode = anchorNode.getParent();

        if (!parentNode) {
          return;
        }

        const hintNode = $createHintNode(hintText);
        selection.insertNodes([hintNode]);
        $setSelection(currentSelection);
      });
    },
    [editor]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_DOWN_COMMAND,
        () => {
          removeHintNode();
          return false;
        },
        COMMAND_PRIORITY_HIGH
      ),

      editor.registerCommand<string>(
        INSERT_HINT_COMMAND,
        (hintText) => {
          insertHintNode(hintText);
          return true;
        },
        COMMAND_PRIORITY_HIGH
      ),

      editor.registerCommand<string>(
        REMOVE_HINT_COMMAND,
        () => {
          removeHintNode();
          return true;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor, removeHintNode, insertHintNode]);

  return null;
}
