import { ForwardedRef, forwardRef, KeyboardEvent, useCallback } from 'react';
import { clsx } from 'clsx';
import { Tooltip } from '../Tooltip';
import { Check, Info } from 'lucide-react';
import { ISelectOptionProps, OptionType } from './types';
import { Tag } from '../Tag';
import { TruncatedText } from '../TruncatedText';
import { Typography } from '../Typography';

export const SelectOption = forwardRef(
  (
    {
      option,
      index,
      active,
      selected,
      handleSelect,
      getItemProps,
      style,
      onRenderOption,
    }: ISelectOptionProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const rootClass: string = clsx(
      'analog-select__option',
      selected && 'analog-select__option--active'
    );

    const onClick = useCallback((): void => {
      handleSelect(index);
    }, [handleSelect, index]);

    const onKeyDown = useCallback(
      (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSelect(index);
        }
      },
      [handleSelect, index]
    );

    if (option.type === OptionType.Divider) {
      return (
        <div className="analog-select__option-divider" style={style}>
          <div className="analog-select__option-dividerLine" />
        </div>
      );
    }

    if (option.type === OptionType.Heading) {
      return (
        <Typography
          as="div"
          className="analog-select__option-heading"
          style={style}
          variant="body"
          weight="bold"
          text={option.text}
        />
      );
    }

    if (option.type === OptionType.Message) {
      return (
        <Typography
          as="div"
          className="analog-select__option-heading-messages"
          style={style}
          variant="body"
          weight="regular"
          text={option.text}
        />
      );
    }

    const descriptionClass = clsx(
      'select__option-description',
      `select__option-description--${option.description?.variant || 'neutral'}`,
      'analog-typography--subtitle'
    );

    return (
      <div className="analog-select__option-wrapper">
        <div
          className={rootClass}
          ref={ref}
          role="option"
          data-option-id={option.id}
          tabIndex={active ? 0 : -1}
          aria-selected={selected && active}
          {...getItemProps({
            onClick,
            onKeyDown,
          })}
          style={style}
        >
          <div className="analog-select__option-left">
            {option.icon && (
              <div className="analog-select__option-icon">{option.icon}</div>
            )}
            <div className="analog-select__option-text analog-typography--body regular">
              {onRenderOption ? (
                onRenderOption(option)
              ) : (
                <TruncatedText text={option.text} />
              )}
            </div>
            {option.description && (
              <div className={descriptionClass}>{option.description.text}</div>
            )}
            {option.tooltip && (
              <Tooltip
                placement={option.tooltip.placement || 'right'}
                portal={
                  option.tooltip.portal !== undefined
                    ? option.tooltip.portal
                    : true
                }
                delay={option.tooltip.delay}
                content={
                  <div className="analog-select__option-tooltip analog-typography--subtitle">
                    {option.tooltip.content}
                  </div>
                }
                anchor={<Info size={16} color="var(--analog-icon-primary)" />}
              />
            )}
          </div>

          <div className="select__option-right">
            {option.tag && <Tag {...option.tag}>{option.tag.text}</Tag>}
            <div className="select__option-checkMark">
              {selected && (
                <Check className="select__option-right-icon" size={16} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

SelectOption.displayName = 'SelectOption';
