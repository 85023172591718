import { ElementType, ReactNode } from 'react';
import './index.css';
import { clsx } from 'clsx';
import { Typography } from '../Typography';
import { IconButton } from '../Button/IconButton';
import { Copy } from 'lucide-react';
import { ITypographyProps } from '../Typography/types';

export type ILabelValueProps = {
  label: ReactNode;
  value: ReactNode;
  units?: ReactNode;
  direction?: 'row' | 'column';
  labelWidth?: string;
  canCopy?: boolean;
  typographyProps?: Omit<ITypographyProps<ElementType>, 'text'>;
};

export const LabelValue = ({
  label,
  value,
  units,
  direction = 'row',
  labelWidth,
  canCopy = false,
  typographyProps = {},
}: ILabelValueProps) => {
  const rootClass = clsx('label-value', `label-value--${direction}`);

  return (
    <div className={rootClass}>
      <Typography
        as="div"
        {...typographyProps}
        className="label-value__label"
        style={{ minWidth: labelWidth }}
        text={label}
      />
      <div className="label-value__value-wrapper">
        <Typography
          as="div"
          {...typographyProps}
          text={value}
          className="label-value__value"
        />
        {units && (
          <Typography
            as="div"
            {...typographyProps}
            text={units}
            className="label-value__units"
          />
        )}
        {canCopy && (
          <div className="label-value__copyIcon">
            <IconButton size="xs" variant="quietLayer3" content={<Copy />} />
          </div>
        )}
      </div>
    </div>
  );
};
