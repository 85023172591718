import { IAnaThoughtContent } from '../../../types';
import { Spinner, Typography } from '@cycling-web/analog-ui';

type IProps = {
  content: IAnaThoughtContent;
  isLast: boolean;
};

export const ContentThought = ({ content, isLast }: IProps) => {
  return (
    <div className="conversation-message__thought">
      <div className="conversation-message__thought-line" />

      <div className="conversation-message__thought-header">
        <div className="conversation-message__thought-spinner">
          {content.loading ? (
            <Spinner size="xs" />
          ) : (
            <div className="conversation-message__thought-done" />
          )}
        </div>
        <Typography
          variant="subtitle"
          weight="bold"
          className="conversation-message__tool-title"
          text={content.title || ''}
        />
      </div>

      {isLast && content.text && (
        <div className="conversation-message__thought-body">
          <Typography
            className="conversation-message__tool-name"
            variant="subtitle"
            text={content.text}
          />
        </div>
      )}
    </div>
  );
};
