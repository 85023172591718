import './index.css';
import { Page } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { PrescriptionPageTitle } from './components/PageTitle';
import { Card } from '@cycling-web/analog-ui';
import { AthleteStats } from '../../components/AthleteStats';
import { PrescriptionOverview } from './components/Overview';
import { onTrackBackButtonClick } from '../../ms/utils';
import { useMobileMenu } from '../../components/MobileMenu';

export const WorkoutPrescriptionDetails = () => {
  const { backUrl, athlete, athleteLoaded } = useViewModel();
  const onRenderMenu = useMobileMenu();

  return (
    <Page
      pageHeaderProps={{
        title: <PrescriptionPageTitle />,
        backUrl,
        onBackUrlClick: onTrackBackButtonClick,
        onRenderMenu,
      }}
    >
      <div className="event-overview">
        <header className="event-header">
          <AthleteStats athlete={athlete} athleteLoaded={athleteLoaded} />
        </header>
        <Card variant="layer2" className="event-overview__cards">
          <PrescriptionOverview />
        </Card>
      </div>
    </Page>
  );
};
