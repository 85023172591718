import {
  addWeeks,
  format,
  getISOWeek,
  getISOWeekYear,
  isToday,
  isWithinInterval,
  isYesterday,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';

export function hoursToHHMMSS(value: number): string {
  const hours = Math.floor(value);
  const minutes = Math.floor((value - hours) * 60);
  const seconds = Math.floor(((value - hours) * 60 - minutes) * 60);

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':');
}

export function formatTime(numberOfSeconds: number): string {
  const hours = Math.floor(numberOfSeconds / 3600);
  const minutes = Math.floor((numberOfSeconds % 3600) / 60);
  const seconds = numberOfSeconds % 60;

  const hoursStr = hours > 0 ? `${hours}h` : '';
  const minutesStr = minutes > 0 ? `${minutes}m` : '';
  const secondsStr = seconds > 0 ? `${seconds}s` : '';

  return [hoursStr, minutesStr, secondsStr].filter(Boolean).join(' ');
}

export type ITimeZone = {
  offset: string;
  zone: string;
  displayName: string;
};

export function getTimeZones(): ITimeZone[] {
  return [
    {
      offset: '-12:00',
      zone: 'Etc/GMT+12',
      displayName: '(UTC-12:00) Baker Island, Howland Island',
    },
    {
      offset: '-11:00',
      zone: 'Pacific/Samoa',
      displayName: '(UTC-11:00) American Samoa, Midway Atoll',
    },
    {
      offset: '-10:00',
      zone: 'Pacific/Honolulu',
      displayName: '(UTC-10:00) Hawaii-Aleutian Time',
    },
    {
      offset: '-09:00',
      zone: 'America/Anchorage',
      displayName: '(UTC-09:00) Alaska Time',
    },
    {
      offset: '-08:00',
      zone: 'America/Los_Angeles',
      displayName: '(UTC-08:00) Pacific Time (US & Canada)',
    },
    {
      offset: '-07:00',
      zone: 'America/Denver',
      displayName: '(UTC-07:00) Mountain Time (US & Canada)',
    },
    {
      offset: '-06:00',
      zone: 'America/Chicago',
      displayName: '(UTC-06:00) Central Time (US & Canada), Mexico City',
    },
    {
      offset: '-05:00',
      zone: 'America/New_York',
      displayName: '(UTC-05:00) Eastern Time (US & Canada), Bogota, Lima',
    },
    {
      offset: '-04:00',
      zone: 'America/Halifax',
      displayName: '(UTC-04:00) Atlantic Time (Canada), Caracas, La Paz',
    },
    {
      offset: '-03:00',
      zone: 'America/Sao_Paulo',
      displayName: '(UTC-03:00) Buenos Aires, São Paulo',
    },
    {
      offset: '-02:00',
      zone: 'Atlantic/South_Georgia',
      displayName: '(UTC-02:00) South Georgia & South Sandwich Islands',
    },
    {
      offset: '-01:00',
      zone: 'Atlantic/Azores',
      displayName: '(UTC-01:00) Azores, Cape Verde Islands',
    },
    {
      offset: '+00:00',
      zone: 'Europe/London',
      displayName: '(UTC+00:00) Greenwich Mean Time (GMT), London, Lisbon',
    },
    {
      offset: '+01:00',
      zone: 'Europe/Berlin',
      displayName: '(UTC+01:00) Central European Time (Berlin, Paris, Madrid)',
    },
    {
      offset: '+02:00',
      zone: 'Europe/Athens',
      displayName:
        '(UTC+02:00) Eastern European Time (Athens, Istanbul, Cairo)',
    },
    {
      offset: '+03:00',
      zone: 'Europe/Moscow',
      displayName: '(UTC+03:00) Moscow, Nairobi, Riyadh',
    },
    {
      offset: '+03:30',
      zone: 'Asia/Tehran',
      displayName: '(UTC+03:30) Tehran',
    },
    {
      offset: '+04:00',
      zone: 'Asia/Dubai',
      displayName: '(UTC+04:00) Abu Dhabi, Baku, Samara',
    },
    { offset: '+04:30', zone: 'Asia/Kabul', displayName: '(UTC+04:30) Kabul' },
    {
      offset: '+05:00',
      zone: 'Asia/Karachi',
      displayName: '(UTC+05:00) Karachi, Tashkent',
    },
    {
      offset: '+05:30',
      zone: 'Asia/Kolkata',
      displayName: '(UTC+05:30) India Standard Time (New Delhi, Mumbai)',
    },
    {
      offset: '+05:45',
      zone: 'Asia/Kathmandu',
      displayName: '(UTC+05:45) Kathmandu',
    },
    {
      offset: '+06:00',
      zone: 'Asia/Dhaka',
      displayName: '(UTC+06:00) Dhaka, Almaty',
    },
    {
      offset: '+06:30',
      zone: 'Asia/Yangon',
      displayName: '(UTC+06:30) Yangon',
    },
    {
      offset: '+07:00',
      zone: 'Asia/Bangkok',
      displayName: '(UTC+07:00) Bangkok, Jakarta, Hanoi',
    },
    {
      offset: '+08:00',
      zone: 'Asia/Shanghai',
      displayName: '(UTC+08:00) Beijing, Singapore, Hong Kong, Perth',
    },
    {
      offset: '+09:00',
      zone: 'Asia/Tokyo',
      displayName: '(UTC+09:00) Tokyo, Seoul, Pyongyang',
    },
    {
      offset: '+09:30',
      zone: 'Australia/Adelaide',
      displayName: '(UTC+09:30) Adelaide, Darwin',
    },
    {
      offset: '+10:00',
      zone: 'Australia/Sydney',
      displayName: '(UTC+10:00) Sydney, Brisbane, Vladivostok',
    },
    {
      offset: '+11:00',
      zone: 'Pacific/Guadalcanal',
      displayName: '(UTC+11:00) Solomon Islands, Nouméa',
    },
    {
      offset: '+12:00',
      zone: 'Pacific/Auckland',
      displayName: '(UTC+12:00) Auckland, Fiji',
    },
    {
      offset: '+13:00',
      zone: 'Pacific/Tongatapu',
      displayName: '(UTC+13:00) Tonga, Samoa',
    },
    {
      offset: '+14:00',
      zone: 'Pacific/Kiritimati',
      displayName: '(UTC+14:00) Kiritimati Island',
    },
  ];
}

export function getClientTimeZone(): ITimeZone | undefined {
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const now = new Date();
  const clientOffsetMinutes = -now.getTimezoneOffset();

  const hours = Math.floor(Math.abs(clientOffsetMinutes) / 60);
  const minutes = Math.abs(clientOffsetMinutes) % 60;
  const sign = clientOffsetMinutes >= 0 ? '+' : '-';
  const clientOffset = `${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  const timeZones = getTimeZones();
  const exactMatch = timeZones.find((tz) => tz.zone === clientTimeZone);
  if (exactMatch) {
    return exactMatch;
  }

  const offsetMatch = timeZones.find((tz) => tz.offset === clientOffset);
  if (offsetMatch) {
    return offsetMatch;
  }

  return timeZones.find((tz) => tz.offset === '+00:00') || undefined;
}

export function localWithUTCMidnightString(d: Date | undefined): string {
  const date = d || new Date();

  const dd = date.getDate().toString().padStart(2, '0');
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}T00:00:00.000Z`;
}

export function localWithUTCMidnightTimestamp(d: Date | undefined): number {
  const utcMidnightString = localWithUTCMidnightString(d);
  const utcMidnightDate = new Date(utcMidnightString);
  return utcMidnightDate.getTime();
}

export function UTCMidnightToSameDate(timestamp: number): Date {
  const timezoneOffset = new Date().getTimezoneOffset();
  const adjustedTimestamp = timestamp + timezoneOffset * 60 * 1000;
  return new Date(adjustedTimestamp);
}

export function getUTCMidnight(date?: Date) {
  const now = date || new Date();

  const utcYear = now.getUTCFullYear();
  const utcMonth = now.getUTCMonth();
  const utcDate = now.getUTCDate();

  return new Date(Date.UTC(utcYear, utcMonth, utcDate));
}

export function getMonthFromWeek(weekNumber: number, year: number): string {
  const firstDayOfYear = new Date(year, 0, 1);
  const firstWeekStart = startOfWeek(firstDayOfYear, { weekStartsOn: 0 });
  const weekStartDate = new Date(firstWeekStart);
  weekStartDate.setDate(weekStartDate.getDate() + (weekNumber - 1) * 7);

  return format(weekStartDate, 'MMM');
}

export function groupItemsByDate<T>(
  items: T[],
  datePath: string | string[]
): Record<string, T[]> {
  const today = startOfDay(new Date());
  const yesterday = startOfDay(subDays(today, 1));
  const sevenDaysAgo = startOfDay(subDays(today, 7));
  const oneMonthAgo = startOfDay(subMonths(today, 1));

  const grouped: Record<string, T[]> = {
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
  };

  // Utility function to access nested properties dynamically
  const getValueByPath = (obj: T, path: string | string[]): string | null => {
    const keys = Array.isArray(path) ? path : path.split('.');
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
      obj
    ) as string | null;
  };

  items.forEach((item) => {
    const dateValue = getValueByPath(item, datePath);

    if (!dateValue) return;

    let date: Date;
    try {
      date = parseISO(dateValue);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
    } catch {
      return;
    }

    if (isToday(date)) {
      grouped.today.push(item);
    } else if (isYesterday(date)) {
      grouped.yesterday.push(item);
    } else if (
      isWithinInterval(date, { start: sevenDaysAgo, end: yesterday })
    ) {
      grouped.previous7Days.push(item);
    } else if (
      isWithinInterval(date, { start: oneMonthAgo, end: sevenDaysAgo })
    ) {
      grouped.previous30Days.push(item);
    } else {
      // Dynamically group by month name for older items
      const monthName = format(date, 'MMMM yyyy');
      if (!grouped[monthName]) {
        grouped[monthName] = [];
      }
      grouped[monthName].push(item);
    }
  });

  return grouped;
}

export function minutesToHHMM(minutes: number): string {
  const hrs = Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0');
  const mins = (minutes % 60).toString().padStart(2, '0');
  return `${hrs}:${mins}`;
}

export function hhmmToMinutes(hhmm: string): number {
  const [hrs, mins] = hhmm.split(':').map(Number);
  return hrs * 60 + mins;
}

export function hoursToHHMM(hours: number): string {
  const hrs = Math.floor(hours).toString().padStart(2, '0');
  const mins = Math.round((hours % 1) * 60)
    .toString()
    .padStart(2, '0');
  return `${hrs}:${mins}`;
}

export function hhmmToHours(hhmm: string): number {
  const [hrs, mins] = hhmm.split(':').map(Number);
  return hrs + mins / 60;
}

// -----------------------------------------------------------------------------

type WeekInfo = {
  week: number;
  year: number;
};

export function getSurroundingWeeks(date: Date): WeekInfo[] {
  const firstWeekStart = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
  const weeks = Array.from({ length: 6 }, (_, i) =>
    addWeeks(firstWeekStart, i - 1)
  );

  return weeks.map((d) => ({
    week: getISOWeek(d),
    year: getISOWeekYear(d),
  }));
}

// -----------------------------------------------------------------------------
