import { useTranslation } from 'react-i18next';
import { useWorkoutStore } from '../../store/slice';
import { EMPTY } from '../../../../constants';
import { Card } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import { roundToDecimals } from '../../../../utils/maths';

export const Table = () => {
  const { t } = useTranslation();
  const workout = useWorkoutStore((s) => s.workout);

  const getRoundedValue = useCallback(
    (value: string | number | undefined, decimals = 0) => {
      return value ? roundToDecimals(+value, decimals) : EMPTY;
    },
    []
  );

  return (
    <Card
      variant="layer2"
      className="workout-card__selection-table analog-typography--subtitle"
    >
      <div className="workout-card__selection-table-row">
        <div className="workout-card__selection-table-column"></div>
        <div className="workout-card__selection-table-column">
          {t('label.min')}
        </div>
        <div className="workout-card__selection-table-column">
          {t('label.avg')}
        </div>
        <div className="workout-card__selection-table-column">
          {t('label.max')}
        </div>
        <div className="workout-card__selection-table-column"></div>
      </div>

      <div className="workout-card__selection-table-row">
        <div className="workout-card__selection-table-column">
          {t('label.power')}
        </div>
        <div className="workout-card__selection-table-column">0</div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.PowerAverage)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.PowerMaximum)}
        </div>
        <div className="workout-card__selection-table-column">
          <div className="workout-card__selection-table-units">
            {t('units.w')}
          </div>
        </div>
      </div>

      <div className="workout-card__selection-table-row">
        <div className="workout-card__selection-table-column">
          {t('label.heart_rate')}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.HeartRateMinimum)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.HeartRateAverage)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.HeartRateMaximum)}
        </div>
        <div className="workout-card__selection-table-column">
          <div className="workout-card__selection-table-units">
            {t('units.bpm')}
          </div>
        </div>
      </div>

      <div className="workout-card__selection-table-row">
        <div className="workout-card__selection-table-column">
          {t('label.cadence')}
        </div>
        <div className="workout-card__selection-table-column">0</div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.CadenceAverage)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.CadenceMaximum)}
        </div>
        <div className="workout-card__selection-table-column">
          <div className="workout-card__selection-table-units">
            {t('units.rpm')}
          </div>
        </div>
      </div>

      <div className="workout-card__selection-table-row">
        <div className="workout-card__selection-table-column">
          {t('label.speed')}
        </div>
        <div className="workout-card__selection-table-column">0</div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.VelocityAverage, 1)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.VelocityMaximum, 1)}
        </div>
        <div className="workout-card__selection-table-column">
          <div className="workout-card__selection-table-units">
            {t('units.kph')}
          </div>
        </div>
      </div>

      <div className="workout-card__selection-table-row">
        <div className="workout-card__selection-table-column">
          {t('label.temperature')}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.TempMin)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.TempAvg)}
        </div>
        <div className="workout-card__selection-table-column">
          {getRoundedValue(workout?.Workout.TempMax)}
        </div>
        <div className="workout-card__selection-table-column">
          <div className="workout-card__selection-table-units">
            {t('units.celsius')}
          </div>
        </div>
      </div>
    </Card>
  );
};
