import clsx from 'clsx';
import './index.css';
import {
  CSSProperties,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react';

type IProps = {
  children: ReactNode;
  style: CSSProperties;
  ref: RefObject<HTMLDivElement | null>;
};

export const MentionMenu = ({ style, children, ref }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [adjustedStyle, setAdjustedStyle] = useState<CSSProperties>({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isVisible || !ref.current) {
      return;
    }

    const menu = ref.current;
    const rect = menu.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    const newStyle: CSSProperties = {};
    const VIEWPORT_GAP = 16;

    if (rect.right > viewportWidth) {
      newStyle.left = Math.max(0, viewportWidth - rect.width - VIEWPORT_GAP);
    }

    setAdjustedStyle(newStyle);
  }, [isVisible, ref]);

  const rootClass = clsx(
    'mention__menu',
    !isVisible && 'mention__menu--hidden'
  );

  return (
    <div className={rootClass} style={{ ...style, ...adjustedStyle }} ref={ref}>
      <ul className="mention__menu-list">{children}</ul>
    </div>
  );
};
