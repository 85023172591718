import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import CyclingIcon from '../../../../../assets/icons/cycling.svg?react';
import { useTranslation } from 'react-i18next';
import { IPerformanceReport } from '../../../../../types/performance';

type IProps = {
  report: IPerformanceReport | undefined;
};

export const CyclingSummary = ({ report }: IProps) => {
  const { t } = useTranslation();

  const duration =
    !report?.durationStr || report?.durationStr === '0.0'
      ? '00:00'
      : report?.durationStr;

  return (
    <div className="week-summary__cycling">
      <header className="week-summary__cycling-header">
        <CyclingIcon width={16} height={16} />
        <Typography
          variant="subtitle"
          weight="bold"
          text={t('label.cycling')}
        />
      </header>

      <div className="week-summary__cycling-card">
        <Typography
          variant="subtitle"
          color="text-placeholder"
          text={t('label.duration')}
        />

        <div className="week-summary__cycling-card-value">
          <Typography
            variant="subtitle"
            weight="bold"
            color="text-primary"
            text={duration}
          />
        </div>
      </div>

      <div className="week-summary__cycling-card">
        <Typography
          variant="subtitle"
          color="text-placeholder"
          text={t('label.distance')}
        />

        <div className="week-summary__cycling-card-value">
          <Typography
            variant="subtitle"
            weight="bold"
            color="text-primary"
            text={report?.distanceInKm || 0}
          />
          <Typography
            variant="subtitle"
            color="text-primary"
            text={t('units.km')}
          />
        </div>
      </div>

      <div className="week-summary__cycling-card">
        <Typography
          variant="subtitle"
          color="text-placeholder"
          text={t('label.tss')}
        />

        <div className="week-summary__cycling-card-value">
          <Typography
            variant="subtitle"
            weight="bold"
            color="text-primary"
            text={report?.tss || 0}
          />
        </div>
      </div>
    </div>
  );
};
