import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { TrainingZoneKeys } from '../utils/getDefaults';

type IText = {
  title: string;
  unit: string;
};

export const useTrainingZoneText = (): Record<string, IText> => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      [TrainingZoneKeys.CyclingPowerMFTP]: {
        title: t('label.power'),
        unit: t('units.w'),
      },
      [TrainingZoneKeys.CyclingPowerMCP]: {
        title: t('label.power'),
        unit: t('units.w'),
      },
      [TrainingZoneKeys.CyclingHeartRateDefault]: {
        title: t('label.heart_rate'),
        unit: t('units.hr'),
      },
      [TrainingZoneKeys.SwimmingCSSDefault]: {
        title: t('label.pace'),
        unit: t('units.sec_100'),
      },
      [TrainingZoneKeys.SwimmingHeartRateDefault]: {
        title: t('label.heart_rate'),
        unit: t('units.hr'),
      },
      [TrainingZoneKeys.RunningPaceDefault]: {
        title: t('label.pace'),
        unit: t('units.min_km'),
      },
      [TrainingZoneKeys.RunningHeartRateDefault]: {
        title: t('label.heart_rate'),
        unit: t('units.hr'),
      },
    };
  }, [t]);
};
