import { IGroupReportsSlice, IGroupReportsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ICriticalPower,
  IPeak,
  IWorkoutDetail,
} from '../../../types/performance';
import { IGroupReport } from '../../../types/groups';

export const initialState: IGroupReportsState = {
  peaks: [],
  peaksLoaded: false,
  criticalPower: [],
  criticalPowerLoaded: false,
  reports: [],
  reportsLoaded: false,
  workoutDetails: [],
  workoutDetailsLoaded: false,
};

export const useGroupReportsStore = create<IGroupReportsSlice>()(
  immer((set) => ({
    ...initialState,
    setReports: (reports: IGroupReport[]) => {
      set((state) => {
        state.reports = reports;
        state.reportsLoaded = true;
      });
    },
    setReportsLoaded: (loaded: boolean) => {
      set((state) => {
        state.reportsLoaded = loaded;
      });
    },
    setPeaks: (peaks: IPeak[]) => {
      set((state) => {
        state.peaks = peaks;
        state.peaksLoaded = true;
      });
    },
    setPeaksLoaded: (loaded: boolean) => {
      set((state) => {
        state.peaksLoaded = loaded;
      });
    },
    setCriticalPower: (criticalPower: ICriticalPower[]) => {
      set((state) => {
        state.criticalPower = criticalPower;
        state.criticalPowerLoaded = true;
      });
    },
    setCriticalPowerLoaded: (loaded: boolean) => {
      set((state) => {
        state.criticalPowerLoaded = loaded;
      });
    },
    setWorkoutDetails: (workoutDetails: IWorkoutDetail[]) => {
      set((state) => {
        state.workoutDetails = workoutDetails;
        state.workoutDetailsLoaded = true;
      });
    },
    setWorkoutDetailsLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutDetailsLoaded = loaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
