import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 44.5C12.954 44.5 4 35.546 4 24.5C4 13.454 12.954 4.5 24 4.5C35.046 4.5 44 13.454 44 24.5C44 35.546 35.046 44.5 24 44.5ZM22.006 32.5L36.146 18.358L33.318 15.53L22.006 26.844L16.348 21.186L13.52 24.014L22.006 32.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
