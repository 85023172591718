import './index.css';
import { Typography } from '@cycling-web/analog-ui';

type IProps = {
  title: string;
  subtitle?: string;
};

export const TrainingPlanHeader = ({ title, subtitle }: IProps) => {
  return (
    <header className="training-plan__header">
      <Typography
        variant="headline"
        weight="bold"
        color="text-primary"
        text={title}
      />
      {subtitle && (
        <Typography variant="subtitle" color="text-primary" text={subtitle} />
      )}
    </header>
  );
};
