import { PerformanceService } from './service';
import {
  ICriticalPower,
  IFatigueResistance,
  IFatigueResistanceRelative,
  IFitnessFatigue,
  IITrimpTss,
  ILastSFT,
  ILatestTrainingLoad,
  IPdCurve,
  IPdCurveRelative,
  IPeak,
  IPerformanceReport,
  IPowerDerivative,
  ITrainingLoad,
  ITrainingLoadFeedback,
  ITrainingZonePerformance,
  IWorkoutDetail,
} from '../../types/performance';
import {
  IGetAthletePerformanceReportRequest,
  IGetCriticalPowerRequest,
  IGetFatigueResistanceRelativeRequest,
  IGetFatigueResistanceRequest,
  IGetFitnessFatigueRequest,
  IGetITRIMPTSSRequest,
  IGetLastSFTRequest,
  IGetLatestTrainingLoadRequest,
  IGetPdCurveRelativeRequest,
  IGetPdCurveRequest,
  IGetPeakRequest,
  IGetPerformanceReportRequest,
  IGetPowerDerivativesRequest,
  IGetTrainingLoadFeedbackRequest,
  IGetTrainingLoadRequest,
  IGetTrainingZonesRequest,
  IGetWorkoutDetailsRequest,
} from './types';
import { AxiosRequestConfig } from 'axios';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';

/** The repository is responsible for data transformation */

export interface IPerformanceRepository {
  getPerformanceReport: (
    p: IGetPerformanceReportRequest,
    c?: AxiosRequestConfig
  ) => Promise<IPerformanceReport[]>;
  getAthletePerformanceReport: (
    p: IGetAthletePerformanceReportRequest,
    c?: AxiosRequestConfig
  ) => Promise<IPerformanceReport[]>;

  getTrainingLoad: (
    p: IGetTrainingLoadRequest,
    c?: AxiosRequestConfig
  ) => Promise<ITrainingLoad>;
  getLatestTrainingLoad: (
    p: IGetLatestTrainingLoadRequest,
    c?: AxiosRequestConfig
  ) => Promise<ILatestTrainingLoad[]>;
  getTrainingLoadFeedback: (
    p: IGetTrainingLoadFeedbackRequest,
    c?: AxiosRequestConfig
  ) => Promise<ITrainingLoadFeedback>;
  getWorkoutDetails: (
    p: IGetWorkoutDetailsRequest,
    c?: AxiosRequestConfig
  ) => Promise<IWorkoutDetail[]>;
  getPowerDerivatives: (
    p: IGetPowerDerivativesRequest,
    c?: AxiosRequestConfig
  ) => Promise<IPowerDerivative>;
  getPeak: (p: IGetPeakRequest, c?: AxiosRequestConfig) => Promise<IPeak[]>;
  getPdCurveRelative: (
    p: IGetPdCurveRelativeRequest,
    c?: AxiosRequestConfig
  ) => Promise<IPdCurveRelative>;
  getPdCurve: (
    p: IGetPdCurveRequest,
    c?: AxiosRequestConfig
  ) => Promise<IPdCurve>;
  getLastSFT: (
    p: IGetLastSFTRequest,
    c?: AxiosRequestConfig
  ) => Promise<ILastSFT>;
  getITRIMPTSS: (
    p: IGetITRIMPTSSRequest,
    c?: AxiosRequestConfig
  ) => Promise<IITrimpTss>;
  getFitnessFatigue: (
    p: IGetFitnessFatigueRequest,
    c?: AxiosRequestConfig
  ) => Promise<IFitnessFatigue>;
  getFatigueResistanceRelative: (
    p: IGetFatigueResistanceRelativeRequest,
    c?: AxiosRequestConfig
  ) => Promise<IFatigueResistanceRelative>;
  getFatigueResistance: (
    p: IGetFatigueResistanceRequest,
    c?: AxiosRequestConfig
  ) => Promise<IFatigueResistance>;
  getCriticalPower: (
    p: IGetCriticalPowerRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICriticalPower[]>;

  getTrainingZonesPerformance: (
    p: IGetTrainingZonesRequest,
    c?: AxiosRequestConfig
  ) => Promise<ITrainingZonePerformance[]>;
}

export class PerformanceRepository
  extends BaseRepository
  implements IPerformanceRepository
{
  constructor(
    private readonly service: PerformanceService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getPerformanceReport(
    p: IGetPerformanceReportRequest,
    c?: AxiosRequestConfig
  ): Promise<IPerformanceReport[]> {
    const response = await this.handleRequest(
      this.service.getPerformanceReport,
      p,
      c
    );
    return response.data;
  }

  async getAthletePerformanceReport(
    p: IGetAthletePerformanceReportRequest,
    c?: AxiosRequestConfig
  ): Promise<IPerformanceReport[]> {
    const response = await this.handleRequest(
      this.service.getAthletePerformanceReport,
      p,
      c
    );
    return response.data;
  }

  async getTrainingLoad(
    p: IGetTrainingLoadRequest,
    c?: AxiosRequestConfig
  ): Promise<ITrainingLoad> {
    const response = await this.handleRequest(
      this.service.getTrainingLoad,
      p,
      c
    );
    return response.data;
  }

  async getLatestTrainingLoad(
    p: IGetLatestTrainingLoadRequest,
    c?: AxiosRequestConfig
  ): Promise<ILatestTrainingLoad[]> {
    const response = await this.handleRequest(
      this.service.getLatestTrainingLoad,
      p,
      c
    );
    return response.data;
  }

  async getTrainingLoadFeedback(
    p: IGetTrainingLoadFeedbackRequest,
    c?: AxiosRequestConfig
  ): Promise<ITrainingLoadFeedback> {
    const response = await this.handleRequest(
      this.service.getTrainingLoadFeedback,
      p,
      c
    );
    return response.data;
  }

  async getWorkoutDetails(
    p: IGetWorkoutDetailsRequest,
    c?: AxiosRequestConfig
  ): Promise<IWorkoutDetail[]> {
    const response = await this.handleRequest(
      this.service.getWorkoutDetails,
      p,
      c
    );
    return response.data;
  }

  async getPowerDerivatives(
    p: IGetPowerDerivativesRequest,
    c?: AxiosRequestConfig
  ): Promise<IPowerDerivative> {
    const response = await this.handleRequest(
      this.service.getPowerDerivatives,
      p,
      c
    );
    return response.data;
  }

  async getPeak(p: IGetPeakRequest, c?: AxiosRequestConfig): Promise<IPeak[]> {
    const response = await this.handleRequest(this.service.getPeak, p, c);
    return response.data;
  }

  async getPdCurveRelative(
    p: IGetPdCurveRelativeRequest,
    c?: AxiosRequestConfig
  ): Promise<IPdCurveRelative> {
    const response = await this.handleRequest(
      this.service.getPdCurveRelative,
      p,
      c
    );
    return response.data;
  }

  async getPdCurve(
    p: IGetPdCurveRequest,
    c?: AxiosRequestConfig
  ): Promise<IPdCurve> {
    const response = await this.handleRequest(this.service.getPdCurve, p, c);
    return response.data;
  }

  async getLastSFT(
    p: IGetLastSFTRequest,
    c?: AxiosRequestConfig
  ): Promise<ILastSFT> {
    const response = await this.handleRequest(this.service.getLastSFT, p, c);
    return response.data;
  }

  async getITRIMPTSS(
    p: IGetITRIMPTSSRequest,
    c?: AxiosRequestConfig
  ): Promise<IITrimpTss> {
    const response = await this.handleRequest(this.service.getITRIMPTSS, p, c);
    return response.data;
  }

  async getFitnessFatigue(
    p: IGetFitnessFatigueRequest,
    c?: AxiosRequestConfig
  ): Promise<IFitnessFatigue> {
    const response = await this.handleRequest(
      this.service.getFitnessFatigue,
      p,
      c
    );
    return response.data;
  }

  async getFatigueResistanceRelative(
    p: IGetFatigueResistanceRelativeRequest,
    c?: AxiosRequestConfig
  ): Promise<IFatigueResistanceRelative> {
    const response = await this.handleRequest(
      this.service.getFatigueResistanceRelative,
      p,
      c
    );
    return response.data;
  }

  async getFatigueResistance(
    p: IGetFatigueResistanceRequest,
    c?: AxiosRequestConfig
  ): Promise<IFatigueResistance> {
    const response = await this.handleRequest(
      this.service.getFatigueResistance,
      p,
      c
    );
    return response.data;
  }

  async getCriticalPower(
    p: IGetCriticalPowerRequest,
    c?: AxiosRequestConfig
  ): Promise<ICriticalPower[]> {
    const response = await this.handleRequest(
      this.service.getCriticalPower,
      p,
      c
    );
    return response.data;
  }

  async getTrainingZonesPerformance(
    p: IGetTrainingZonesRequest,
    c?: AxiosRequestConfig
  ): Promise<ITrainingZonePerformance[]> {
    const response = await this.handleRequest(
      this.service.getTrainingZonesPerformance,
      p,
      c
    );
    return response.data;
  }
}
