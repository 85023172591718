import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { clsx } from 'clsx';
import { useCalendarAddContext } from '../../context';
import { CalendarAddModalPage } from '../../types';

export const CalendarAddAside = () => {
  const { t } = useTranslation();
  const { currentPage, setCurrentPage } = useCalendarAddContext();

  const links = [
    {
      id: CalendarAddModalPage.Event,
      text: t('label.event'),
    },
    {
      id: CalendarAddModalPage.Workout,
      text: t('label.create_workout'),
    },
    {
      id: CalendarAddModalPage.Availability,
      text: t('label.day_off'),
    },
    // {
    //   id: CalendarAddModalPage.Notes,
    //   text: t('label.notes'),
    // },
  ];

  const handleClick = useCallback(
    (page: CalendarAddModalPage) => {
      return () => {
        setCurrentPage(page);
      };
    },
    [setCurrentPage]
  );

  const linksJSX = links.map((link) => {
    const rootClass = clsx(
      'calendar-add-modal__aside-button',
      'analog-typography--subtitle',
      currentPage === link.id && 'active'
    );
    return (
      <button
        key={link.id}
        className={rootClass}
        onClick={handleClick(link.id)}
      >
        {link.text}
      </button>
    );
  });

  return <aside className="calendar-add-modal__aside">{linksJSX}</aside>;
};
