import { useCallback, useEffect, useMemo } from 'react';
import { WorkoutsRepository } from '../../../api/workouts/repository';
import { WorkoutsService } from '../../../api/workouts/service';
import { useParams } from 'react-router';
import { useEventStore } from '../store/slice';
import { IGpx } from '../../../types/workouts';

export const useFetchEvent = () => {
  const { eventId } = useParams();

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchEvent = useCallback(() => {
    if (!eventId) {
      return;
    }

    workoutsRepository
      .getEventById({
        eventId: +eventId,
      })
      .then((response) => {
        useEventStore.getState().setEvent(response);
        return fetch(response.gpxJsonUrl);
      })
      .then((data) => data.json())
      .then((gpx: IGpx) => {
        useEventStore.getState().setGpx(gpx.parsedWaypoints);
      })
      .catch(() => {
        useEventStore.getState().setEventLoaded(true);
        useEventStore.getState().setGpxLoaded(true);
      });
  }, [eventId, workoutsRepository]);

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent]);

  return {
    fetchEvent,
  };
};
