import './index.css';
import { TeamMembersFilters } from './components/Filters';
import { TeamUsersTable } from './components/TeamUsersTable';
import { useTitle } from '../../hooks/useTitle';
import { useTranslation } from 'react-i18next';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const AdminTeamMembers = () => {
  const { t } = useTranslation();
  useTitle(`${t('label.admin_panel')} - ${t('label.team_members')}`);

  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().adminTeamMembers}
    >
      <div className="admin-team-members-page">
        <TeamMembersFilters />
        <TeamUsersTable />
      </div>
    </PageFiltersContextProvider>
  );
};
