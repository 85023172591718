import { IAthlete } from '../../../types/athletes';
import { IGroupReportsFilters } from '../types';
import { IGroupReport } from '../../../types/groups';
import { Url } from '../../../utils/url';

export const filterGroupReportsDelegate = (
  filters: IGroupReportsFilters,
  athletesMap: Record<number, IAthlete>
) => {
  return (item: IGroupReport) => {
    let valid = true;

    const athlete = athletesMap[item.athleteId];
    const athletes = Url.parseCommaSeparatedIds(filters.athletes);

    if (
      filters.search &&
      !athlete?.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    if (athletes.length > 0 && !athletes.includes(item.athleteId)) {
      valid = false;
    }

    return valid;
  };
};
