import './index.css';
import { Card, CardHeader, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import { useWorkoutStore } from '../../store/slice';
import { useMemo } from 'react';
import { addMilliseconds, format } from 'date-fns';
import { LabelValueElement } from '../../../../components/LabelValueElement';
import { IWorkoutCalendarWorkout } from '../../../../types/workouts';

type IProps = {
  calendarWorkout: IWorkoutCalendarWorkout | null;
  loading: boolean;
};

export const WorkoutOverview = ({ calendarWorkout, loading }: IProps) => {
  const { t } = useTranslation();
  const workout = useWorkoutStore((s) => s.workout);

  const workoutDate = useMemo((): string => {
    if (!workout) {
      return '';
    }

    const totalTimeMs = workout.Workout.TotalTime * 60 * 60 * 1000;
    const finishTime = addMilliseconds(
      new Date(workout.Workout.StartTime),
      totalTimeMs
    );
    return format(finishTime, 'dd/MM/yyyy - hh:mm a');
  }, [workout]);

  const title =
    calendarWorkout?.prescription?.title || workout?.Workout.Title || '';
  const description = calendarWorkout?.prescription?.description || '';

  return (
    <Card variant="layer3" className="workout-card__overview">
      <CardHeader icon={<CyclingIcon />} title={t('label.cycling')} />
      <Typography
        as="div"
        variant="headline"
        color="text-placeholder"
        text={workoutDate}
        loading={loading}
      />

      <LabelValueElement
        name={t('label.title')}
        value={title}
        size="s"
        loading={loading}
      />

      <LabelValueElement
        name={t('label.description')}
        value={description}
        size="s"
        loading={loading}
        valueTypographyProps={{
          as: 'pre',
        }}
      />
    </Card>
  );
};
