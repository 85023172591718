import './index.css';
import { MedicalReportsTable } from './components/MedicalReportsTable';
import { useFetchMedicalReports } from './hooks/useFetchMedicalReports';

export const AthleteMedicalReports = () => {
  useFetchMedicalReports();

  return (
    <div className="medical-report-records-page">
      <MedicalReportsTable />
    </div>
  );
};
