import './index.css';
import { IconButton, Tooltip } from '@cycling-web/analog-ui';
import AnaImage from '../../../../assets/illustrations/ana.png';
import { useTranslation } from 'react-i18next';
import { useAnaContext } from '../../context';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useFeatureFlagsStore } from '../../../../store/feature-flags/slice';
import { FeatureFlag } from '../../../../types/feature-flags';

export const AnaButton = () => {
  const { t } = useTranslation();
  const { showAna, toggleAna, blacklistUrl, dismissAna } = useAnaContext();
  const { pathname } = useLocation();
  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);

  const isBlacklisted = blacklistUrl.some((url) => {
    return pathname.includes(url);
  });

  useEffect(() => {
    if (isBlacklisted) {
      dismissAna();
    }
  }, [isBlacklisted, dismissAna]);

  if (!featureFlags.includes(FeatureFlag.Ana_Everywhere)) {
    return null;
  }

  if (isBlacklisted) {
    return null;
  }

  const rootClass = clsx('ana-button', showAna && 'ana-button--active');

  return (
    <Tooltip
      anchor={
        <IconButton
          className={rootClass}
          variant="quietLayer2"
          size="l"
          content={
            <img className="ana-button__image" src={AnaImage} alt="Ana" />
          }
          onClick={toggleAna}
        />
      }
      content={t('tooltip.ana_ai_assistant')}
      placement="bottom-end"
    />
  );
};
