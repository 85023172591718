import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IWorkoutDetail } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import { useTrainingLoadStore } from '../store/slice';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';
import { getDefaultPageFilters } from '../../../constants/page-filters';

export const useFetchAthleteWorkoutDetails = () => {
  const { athleteId } = useParams();
  const { t } = useTranslation();
  const { filters } = usePageFilters<IAthleteDetailsFilters>();
  const setWorkoutDetails = useTrainingLoadStore((s) => s.setWorkoutDetails);
  const setWorkoutDetailsLoaded = useTrainingLoadStore(
    (s) => s.setWorkoutDetailsLoaded
  );

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || filters?.period === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getWorkoutDetails({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: [+athleteId],
        unit:
          filters.dateGroupBy ||
          getDefaultPageFilters().athleteDetails.dateGroupBy,
        aggregatePerAthlete: false,
      })
      .then((data: IWorkoutDetail[]) => {
        setWorkoutDetails(data);
      })
      .catch((error: AxiosError) => {
        setWorkoutDetailsLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_workout_details_title'),
              message: t('error.get_workout_details_message'),
            },
            { toastId: 'get_workout_details' }
          );
        }
      });
  }, [
    athleteId,
    filters.dateGroupBy,
    filters.period,
    performanceRepository,
    setWorkoutDetails,
    setWorkoutDetailsLoaded,
    t,
  ]);
};
