import './index.css';
import {
  Avatar,
  IconButton,
  ISelectOption,
  Tooltip,
} from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { ChevronDown } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router';
import { SingleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../router/routes';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IAthlete } from '../../../../types/athletes';
import { useEventStore } from '../../store/slice';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';
import { Url } from '../../../../utils/url';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingNavigationSource,
} from '../../../../ms/tracking-entities';

export const EventPageTitle = () => {
  const baseUrl = useBaseUrl();
  const event = useEventStore((s) => s.event);
  const eventLoaded = useEventStore((s) => s.eventLoaded);
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const athlete = useMemo(() => {
    if (state?.athlete) {
      return state.athlete;
    }

    const athleteId = event?.athleteId;

    if (!athleteId) {
      return undefined;
    }

    return athletes.find((a) => a.id.toString() === athleteId.toString());
  }, [state, event, athletes]);

  const athleteUrl = `${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}/${athlete?.id}/${ROUTES.PROFILE}`;

  const athletesOptions: ISelectOption[] = useMemo(() => {
    if (!athletesLoaded) {
      return [];
    }

    return athletes.map((a: IAthlete) => {
      return {
        id: a.id.toString(),
        text: a.fullName,
      };
    });
  }, [athletes, athletesLoaded]);

  const onAthleteChange = useCallback(
    (athleteId: number) => {
      const tmp = pathname.split('/');
      const index = tmp.findIndex((s) => s === ROUTES.EVENT);

      if (index >= 0) {
        const tmp1 = tmp.filter((_, i) => i < index);
        tmp1.push(ROUTES.CALENDAR);
        tmp1.push(athleteId.toString());
        const nextUrl = tmp1.join('/');

        trackUserInteractionEvent(TrackingEvent.NAVIGATE, {
          destination: nextUrl,
          source: TrackingNavigationSource.PAGE_TITLE_DROPDOWN,
        });

        navigate(nextUrl);
      }
    },
    [navigate, pathname]
  );

  const onRenderAnchor = useCallback(() => {
    return (
      <Tooltip
        anchor={
          <IconButton
            size="s"
            variant="quietLayer2"
            content={<ChevronDown />}
          />
        }
        content={t('tooltip.select_athlete')}
      />
    );
  }, [t]);

  const onLinkClick = useCallback(() => {
    Url.saveUrlToLS();

    trackUserInteractionEvent(TrackingEvent.NAVIGATE, {
      destination: athleteUrl,
      source: TrackingNavigationSource.PAGE_TITLE_AVATAR,
    });
  }, [athleteUrl]);

  return (
    <div className="event-details__page-title">
      <Link to={athleteUrl} onClick={onLinkClick}>
        <Avatar
          size="l"
          text={athlete?.fullName}
          src={athlete?.picture}
          loading={!athletesLoaded || !eventLoaded}
        />
      </Link>
      <SingleAthleteFilter
        value={athlete?.id}
        options={athletesOptions}
        onChange={onAthleteChange}
        selectProps={{ onRenderAnchor }}
        dropdownProps={{
          minWidth: '260px',
        }}
        skeletonProps={{ width: '32px', height: '32px' }}
        style={{ minWidth: '0' }}
      />
    </div>
  );
};
