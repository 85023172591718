import { createContext, Dispatch, SetStateAction, use } from 'react';
import { INavigationItem } from '@cycling-web/common';

export type ISideNavigationContext = {
  items: INavigationItem[];
  showMobileMenu: boolean;
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
};

export const SideNavigationContext = createContext(
  {} as ISideNavigationContext
);

export const useSideNavigationContext = () => {
  return use(SideNavigationContext);
};
