import {
  IAnaContentType,
  IAnaMessageContent,
  IAnaTextContent,
  IAnaThoughtContent,
  IAnaToolUseContent,
} from '../../types';
import { ContentTool } from './ContentTool';
import { ContentText } from './ContentText';
import { ContentThought } from './Thoughts/ContentThought';

type IProps = {
  content: IAnaMessageContent;
};

export const MessageContent = (props: IProps) => {
  if (props.content.content_type === IAnaContentType.TEXT) {
    const content = props.content as unknown as IAnaTextContent;
    return <ContentText text={content.text} />;
  }

  if (props.content.content_type === IAnaContentType.TOOL_USE) {
    const content = props.content as unknown as IAnaToolUseContent;
    return <ContentTool content={content} />;
  }

  if (props.content.content_type === IAnaContentType.THOUGHT) {
    const content = props.content as unknown as IAnaThoughtContent;
    return <ContentThought content={content} />;
  }

  return null;
};
