import { EMPTY } from '../../../../../../constants';
import { HrvRhrUtils } from '../../../../../../utils/hrv-rhr';

type IProps = {
  value: number | undefined;
  valueDiff: number | undefined;
  rhrDiff: number | undefined;
  hrvDiff: number | undefined;
};

export const TooltipValue = ({
  value,
  valueDiff,
  hrvDiff,
  rhrDiff,
}: IProps) => {
  if (!value) {
    return EMPTY;
  }
  const pDiff = valueDiff
    ? valueDiff > 0
      ? `+${Math.floor(valueDiff)}`
      : `${Math.floor(valueDiff)}`
    : '0';

  const status = HrvRhrUtils.getStatus({
    hrvVariance: hrvDiff || 0,
    rhrVariance: rhrDiff || 0,
  });

  let color = '#f3f3f6';

  if (status) {
    color = HrvRhrUtils.getContent(status).color;
  }

  return (
    <>
      <span>{value}</span>(<span style={{ color }}>{pDiff}</span>)
    </>
  );
};
