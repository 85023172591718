import './index.css';
import {
  IExtendSelectProps,
  ISelectOption,
  Select,
} from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Discipline } from '../../../types/enums';

type IProps = {
  options?: Discipline[];
  value: Discipline;
  onChange: (value: Discipline) => void;
  selectProps?: IExtendSelectProps;
};

export const DisciplineFilter = ({
  value,
  onChange,
  options = [Discipline.Cycling, Discipline.Running, Discipline.Swimming],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();

  const disciplineMap: Record<string, string> = useMemo(() => {
    return {
      [Discipline.Cycling]: t('label.cycling'),
      [Discipline.Running]: t('label.running'),
      [Discipline.Swimming]: t('label.swimming'),
    };
  }, [t]);

  const selectOptions: ISelectOption[] = useMemo(() => {
    return options.map((o: Discipline) => {
      return {
        id: o.toString(),
        text: disciplineMap[o],
      };
    });
  }, [disciplineMap, options]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id as Discipline);
    },
    [onChange]
  );

  return (
    <div className="discipline-filter">
      <Select
        options={selectOptions}
        value={selectOptions.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.discipline_option')}
        {...selectProps}
      />
    </div>
  );
};
