import './index.css';
import { useWeekDays } from '../../../../hooks/useWeekDays';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';

export const WeekHeader = () => {
  const { t } = useTranslation();

  const weekDays = useWeekDays();
  const weekDaysJSX = weekDays.map((day) => {
    return (
      <div key={day.id} className="workouts-calendar__week-day">
        <Typography variant="subtitle" text={day.text} />
      </div>
    );
  });

  return (
    <div className="workouts-calendar__week-header">
      {weekDaysJSX}
      <div className="workouts-calendar__week-day">
        <Typography variant="subtitle" text={t('label.week_summary')} />
      </div>
    </div>
  );
};
