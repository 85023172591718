import { SmartChartsService } from './service';
import {
  IAddAiGraphPinTabRequest,
  ICreateConversationRequest,
  IDeleteAiGraphPinTabRequest,
  IDeleteSmartChartConversationRequest,
  IGetAiGraphAllPinTabsRequest,
  IGetComputeDataRequest,
  IGetConfigurationRequest,
  IGetIntroRequest,
  IGetSimplifiedComputeDataRequest,
  ISendMessageRequest,
  IUpdateConfigRequest,
  IUpdateMultipleAthleteConfigRequest,
} from './types';
import {
  IAthleteType,
  IPinnedTab,
  ISmartChartConfig,
  ISmartChartConfigBase,
  ISmartChartsIntro,
  ITaggedEntity,
} from '../../types/smart-charts';
import { IAthlete } from '../../types/athletes';
import { AxiosRequestConfig } from 'axios';

/** The repository is responsible for data transformation */

export interface ISmartChartsRepository {
  getHistory: () => Promise<ISmartChartConfigBase[]>;
  createConversation: (athletes: IAthlete[]) => Promise<ISmartChartConfigBase>;
  deleteConversation: (
    p: IDeleteSmartChartConversationRequest
  ) => Promise<void>;
  getConfiguration: (
    p: IGetConfigurationRequest,
    c?: AxiosRequestConfig
  ) => Promise<ISmartChartConfig>;
  getComputeData: (
    p: IGetComputeDataRequest,
    c?: AxiosRequestConfig
  ) => Promise<ISmartChartConfig>;
  sendMessage: (p: ISendMessageRequest, c?: AxiosRequestConfig) => Promise<any>;
  updateMultipleAthleteConfig: (
    p: IUpdateMultipleAthleteConfigRequest
  ) => Promise<any>;
  updateConfig: (p: IUpdateConfigRequest) => Promise<ISmartChartConfig>;
  getPinnedTabs: (p: IGetAiGraphAllPinTabsRequest) => Promise<IPinnedTab[]>;
  addPinnedTab: (p: IAddAiGraphPinTabRequest) => Promise<void>;
  getSimplifiedComputeData: (
    p: IGetSimplifiedComputeDataRequest
  ) => Promise<ISmartChartConfig>;
  getIntroRequest: (p: IGetIntroRequest) => Promise<ISmartChartsIntro>;
}

export class SmartChartsRepository implements ISmartChartsRepository {
  constructor(private readonly service: SmartChartsService) {}

  async getHistory(): Promise<ISmartChartConfigBase[]> {
    const response = await this.service.getHistory();
    return response.data;
  }

  async createConversation(
    athletes: IAthlete[]
  ): Promise<ISmartChartConfigBase> {
    const data_sources: IAthleteType[] = athletes.map((a: IAthlete) => {
      return {
        type: 'AthleteDataSourceMetadata',
        athlete_id: a.id.toString(),
        athlete_image_identifier: a.picture,
        athlete_flag_identifier: a.countryCode,
        athlete_full_name: a.fullName,
      };
    });

    const payload: ICreateConversationRequest = {
      data_sources,
    };
    const response = await this.service.createConversation(payload);
    return response.data;
  }

  async deleteConversation(
    p: IDeleteSmartChartConversationRequest
  ): Promise<void> {
    const response = await this.service.deleteConversation(p);
    return response.data;
  }

  async getConfiguration(
    p: IGetConfigurationRequest,
    c?: AxiosRequestConfig
  ): Promise<ISmartChartConfig> {
    const response = await this.service.getConfiguration(p, c);
    return response.data;
  }

  async getComputeData(
    p: IGetComputeDataRequest,
    c?: AxiosRequestConfig
  ): Promise<ISmartChartConfig> {
    const response = await this.service.getComputeData(p, c);
    return response.data;
  }

  async sendMessage(
    p: ISendMessageRequest,
    c?: AxiosRequestConfig
  ): Promise<any> {
    const response = await this.service.sendMessage(p, c);
    return response.data;
  }

  async updateMultipleAthleteConfig(
    p: IUpdateMultipleAthleteConfigRequest
  ): Promise<ISmartChartConfig> {
    const response = await this.service.updateMultipleAthleteConfig(p);
    return response.data;
  }

  async updateConfig(p: IUpdateConfigRequest): Promise<ISmartChartConfig> {
    const response = await this.service.updateConfig(p);
    return response.data;
  }

  async getPinnedTabs(p: IGetAiGraphAllPinTabsRequest): Promise<IPinnedTab[]> {
    const response = await this.service.getPinnedTabs(p);
    return response.data;
  }

  async addPinnedTab(p: IAddAiGraphPinTabRequest): Promise<void> {
    const response = await this.service.addPinnedTab(p);
    return response.data;
  }

  async getSimplifiedComputeData(
    p: IGetSimplifiedComputeDataRequest
  ): Promise<ISmartChartConfig> {
    const response = await this.service.getSimplifiedComputeData(p);
    return response.data;
  }

  async deletePinnedTab(p: IDeleteAiGraphPinTabRequest): Promise<void> {
    const response = await this.service.deletePinnedTab(p);
    return response.data;
  }

  async getIntroRequest(p: IGetIntroRequest): Promise<ISmartChartsIntro> {
    const response = await this.service.getIntroRequest(p);
    return response.data;
  }
}
