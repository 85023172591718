import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useCallback, useEffect, useMemo } from 'react';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { IBodyComposition } from '../../../types/wellness';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteWellnessStore } from '../store/slice';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';

export const useFetchAthleteBodyComposition = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const setBodyComposition = useAthleteWellnessStore(
    (s) => s.setBodyComposition
  );
  const setBodyCompositionLoaded = useAthleteWellnessStore(
    (s) => s.setBodyCompositionLoaded
  );
  const { filters } = usePageFilters<IAthleteDetailsFilters>();

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService(), { abort: true });
  }, []);

  const fetchBodyComposition = useCallback(() => {
    if (!athleteId || filters?.period === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    wellnessRepository
      .getBodyComposition({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteId: +athleteId,
      })
      .then((data: IBodyComposition) => {
        setBodyComposition(data);
      })
      .catch((error: AxiosError) => {
        setBodyCompositionLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_athlete_body_composition_title'),
              message: t('error.get_athlete_body_composition_message'),
            },
            { toastId: 'get_athlete_body_composition' }
          );
        }
      });
  }, [
    athleteId,
    filters?.period,
    setBodyComposition,
    setBodyCompositionLoaded,
    t,
    wellnessRepository,
  ]);

  useEffect(() => {
    fetchBodyComposition();
  }, [fetchBodyComposition]);

  return {
    fetchBodyComposition,
  };
};
