import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelfReportedWellness } from '../../../../types/wellness';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { format } from 'date-fns';
import { DateRange } from '../../../../types/enums';
import { IHandleChangeFilter, useBreakpoints } from '@cycling-web/common';

type IProps = {
  data: ISelfReportedWellness | null;
  loading: boolean;
  filters: {
    period: string;
  };
  handleChangeFilter: IHandleChangeFilter;
};

export const OverallWellnessChart = ({
  data,
  loading,
  filters,
  handleChangeFilter,
}: IProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const source = useMemo(() => {
    const source: IChartSource = [
      [
        ChartAxisName.Date,
        ChartAxisName.OverallScore,
        ChartAxisName.General,
        ChartAxisName.Fatigue,
        ChartAxisName.Stress,
        ChartAxisName.Mood,
        ChartAxisName.Sleep,
      ],
    ];

    if (!data) {
      return source;
    }

    const dataPoints = data.points || [];

    for (let i = 0; i < dataPoints.length; i++) {
      const p = dataPoints[i];
      const date = format(new Date(p.timeSinceEpoch), 'dd/MM/yyyy');
      source.push([
        date,
        p.data.totalScore,
        p.data.overallScore,
        p.data.fatigueScore,
        p.data.stressScore,
        p.data.moodScore,
        p.data.sleepScore,
      ]);
    }

    return source;
  }, [data]);

  const onRenderTooltipValue = useCallback(
    (value: number, series: string) => {
      if (series === ChartAxisName.OverallScore) {
        return `${value}%`;
      }
      return `${value} ${t('label.out_of_n', { n: 20 })}`;
    },
    [t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: [
        {
          ...(isMobile
            ? {
                left: 40,
                right: 0,
              }
            : {}),
          height: '35%',
        },
        {
          ...(isMobile
            ? {
                left: 40,
                right: 0,
              }
            : {}),
          top: '50%',
          height: '35%',
        },
      ],
      axisPointer: {
        link: [
          {
            xAxisIndex: 'all',
          },
        ],
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
          axisLine: {
            show: false,
          },
          gridIndex: 0,
        },
        {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
          axisLine: {
            show: false,
          },
          gridIndex: 1,
        },
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          max: 100,
          gridIndex: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
        {
          type: 'value',
          min: 0,
          max: 20,
          gridIndex: 1,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
      ],
      series: [
        {
          name: ChartAxisName.OverallScore,
          type: 'line',
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.LightPurple,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.General,
          type: 'line',
          yAxisIndex: 1,
          xAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightYellow,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.Fatigue,
          type: 'line',
          yAxisIndex: 1,
          xAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightRed,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.Stress,
          type: 'line',
          yAxisIndex: 1,
          xAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightBlue,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.Mood,
          type: 'line',
          yAxisIndex: 1,
          xAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightGreen,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.Sleep,
          type: 'line',
          yAxisIndex: 1,
          xAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightRose,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [onRenderTooltipValue, source, isMobile]);

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'layer3' }}
      />
    ),
  };

  return (
    <ChartWrapper minHeight="600px">
      <Chart
        headerProps={{
          title: t('label.wellness'),
          filtersBarProps,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.AthleteWellness,
          }),
        }}
      />
    </ChartWrapper>
  );
};
