import './index.css';
import { Button } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrainingPlanContext } from '../../context';
import { TrainingPlanWizardStep } from '../../types';

export const TrainingPlanFooter = () => {
  const { t } = useTranslation();
  const { wizardStep, setWizardStep, handleDismiss } = useTrainingPlanContext();

  const handleNextStepClick = useCallback(() => {
    if (wizardStep === TrainingPlanWizardStep.Step1) {
      setWizardStep(TrainingPlanWizardStep.Step2);
    } else if (wizardStep === TrainingPlanWizardStep.Step2) {
      setWizardStep(TrainingPlanWizardStep.Step3);
    } else {
      console.log('Action');
    }
  }, [wizardStep, setWizardStep]);

  const handlePrevStepClick = useCallback(() => {
    if (wizardStep === TrainingPlanWizardStep.Step3) {
      setWizardStep(TrainingPlanWizardStep.Step2);
    } else if (wizardStep === TrainingPlanWizardStep.Step2) {
      setWizardStep(TrainingPlanWizardStep.Step1);
    } else {
      handleDismiss();
    }
  }, [wizardStep, setWizardStep, handleDismiss]);

  return (
    <footer className="training-plan__footer">
      <Button
        variant="quietLayer3"
        content={
          wizardStep === TrainingPlanWizardStep.Step1
            ? t('action.cancel')
            : t('action.back')
        }
        onClick={handlePrevStepClick}
      />
      <Button
        content={
          wizardStep === TrainingPlanWizardStep.Step3
            ? t('label.generate_workout_plan')
            : t('action.next')
        }
        onClick={handleNextStepClick}
      />
    </footer>
  );
};
