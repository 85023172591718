import './index.css';
import { Outlet } from 'react-router';
import { AthleteTabs } from './components/AthleteTabs';
import { Page } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { IconButton, Tooltip } from '@cycling-web/analog-ui';
import { Plus } from 'lucide-react';
import { AddDataMenu } from './components/AddDataMenu';
import { useMediaMatch } from 'rooks';
import { AthleteDetailsPageTitle } from './components/PageTitle';
import { useTranslation } from 'react-i18next';
import { AiAssistant } from '../../components/AiAssistant';
import { useFeatureFlagsStore } from '../../store/feature-flags/slice';
import { FeatureFlag } from '../../types/feature-flags';
import { AthleteStats } from '../../components/AthleteStats';
import { useAthleteStore } from '../../store/athlete/slice';
import { onTrackBackButtonClick } from '../../ms/utils';
import { useMobileMenu } from '../../components/MobileMenu';

export const AthleteDetails = () => {
  const { t } = useTranslation();
  const { backUrl } = useViewModel();
  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);
  const athlete = useAthleteStore((s) => s.athlete);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);

  const onRenderMenu = useMobileMenu();
  const shrinkButton = useMediaMatch('(max-width: 1400px)');

  return (
    <Page
      pageHeaderProps={{
        title: <AthleteDetailsPageTitle />,
        backUrl: backUrl,
        onBackUrlClick: onTrackBackButtonClick,
        onRenderMenu,
      }}
    >
      <header className="athlete-details__header">
        <AthleteStats athlete={athlete} athleteLoaded={athleteLoaded} />
        <AddDataMenu
          anchor={
            shrinkButton && (
              <Tooltip
                anchor={<IconButton content={<Plus />} />}
                content={t('tooltip.add_data')}
                placement="bottom-end"
              />
            )
          }
        />
      </header>
      <AthleteTabs />
      <Outlet />
      {!featureFlags.includes(FeatureFlag.Ana_Everywhere) && <AiAssistant />}
    </Page>
  );
};
