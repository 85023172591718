import './index.css';
import { ToolHeader } from '../ToolHeader';
import { useAnaContext } from '../../context';
import { ContentText } from '../../../Conversation/components/Message/ContentText';
import { IAnaToolName } from '../../../Conversation';

export const AnaTool = () => {
  const { activeTool } = useAnaContext();

  if (!activeTool) {
    return null;
  }

  return (
    <div className="ana-tool">
      <ToolHeader />
      <div className="ana-tool__content">
        {activeTool.tool_name === IAnaToolName.TEXT_CANVAS_DISPLAY && (
          <ContentText text={activeTool.text} />
        )}
      </div>
    </div>
  );
};
