import { createContext, use } from 'react';
import { ICalendarAddContext } from '../types';

export const CalendarAddContext = createContext<ICalendarAddContext>(
  {} as ICalendarAddContext
);

export const useCalendarAddContext = () => {
  return use(CalendarAddContext);
};
