import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ILatestTrainingLoad } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../types/athletes';
import { useDashboardStore } from '../store/slice';
import { useAthletesStore } from '../../../store/athletes/slice';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IDashboardFilters } from '../types';

export const useFetchTeamTrainingLoad = () => {
  const { t } = useTranslation();
  const { filters } = usePageFilters<IDashboardFilters>();
  const setTrainingLoad = useDashboardStore((s) => s.setTrainingLoad);
  const setTrainingLoadLoaded = useDashboardStore(
    (s) => s.setTrainingLoadLoaded
  );

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const loading = !athletesLoaded;

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (loading || athletes.length === 0 || filters.period === undefined) {
      setTrainingLoadLoaded(true);
      return;
    }

    performanceRepository
      .getLatestTrainingLoad({
        athleteIds: athletes.map((a: IAthlete) => a.id),
      })
      .then((data: ILatestTrainingLoad[]) => {
        setTrainingLoad(data);
      })
      .catch((error: AxiosError) => {
        setTrainingLoadLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_training_load_title'),
              message: t('error.get_training_load_message'),
            },
            { toastId: 'get_team_critical_power' }
          );
        }
      });
  }, [
    loading,
    athletes,
    filters.period,
    performanceRepository,
    t,
    setTrainingLoad,
    setTrainingLoadLoaded,
  ]);
};
