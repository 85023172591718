import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, FormElement } from '@cycling-web/analog-ui';
import {
  DatepickerControl,
  FormColumn,
  FormRow,
  InputControl,
  InputNumberControl,
  InputTimeControl,
  TextareaControl,
} from '@cycling-web/common';
import { WorkoutsRepository } from '../../../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../../../api/workouts/service';
import { IEventForm } from './types';
import { useWorkoutsCalendarContext } from '../../../../context';
import { useCalendarAddContext } from '../../context';
import {
  ICreateEventRequest,
  IUpdateEventRequest,
} from '../../../../../../api/workouts/types';
import {
  ICalendarEvent,
  ICalendarEventType,
} from '../../../../../../types/workouts';
import { useFetchEvents } from '../../../../hooks/useFetchEvents';
import { addDays } from 'date-fns';
import {
  hhmmToMinutes,
  localWithUTCMidnightTimestamp,
  minutesToHHMM,
  UTCMidnightToSameDate,
} from '../../../../../../utils/date-time';
import { GpxFileInput } from './GPXFileInput';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingForm,
} from '../../../../../../ms/tracking-entities';
import { PriorityControl } from './PriorityControl';

export const EventForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { athlete, date } = useWorkoutsCalendarContext();
  const {
    onDismiss,
    handleDismiss,
    dismissCallback,
    defaultEvent,
    defaultDate,
  } = useCalendarAddContext();
  const { fetchEvents } = useFetchEvents();
  const [gpxFileLoading, setGpxFileLoading] = useState<boolean>(false);
  const [gpxFile, setGpxFile] = useState<FormData | null>(null);
  const [hasFile, setHasFile] = useState<boolean>(false);

  useEffect(() => {
    setHasFile(
      !!(defaultEvent?.gpxFileUrl || defaultEvent?.gpxJsonUrl || gpxFile)
    );
  }, [defaultEvent?.gpxFileUrl, defaultEvent?.gpxJsonUrl, gpxFile]);

  const createByGpxFile = useRef<boolean>(false);

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        title: z.string().min(1, { message: t('validation.required') }),
        description: z.string().min(1, { message: t('validation.required') }),
        durationInMinutes: z
          .string()
          .min(1, { message: t('validation.required') }),
        ...(hasFile
          ? {}
          : {
              distanceKms: z
                .string()
                .min(1, { message: t('validation.required') }),
              elevationGainMts: z
                .string()
                .min(1, { message: t('validation.required') }),
            }),
      })
      .passthrough();
  }, [t, hasFile]);

  const defaultValues = useMemo((): IEventForm => {
    if (defaultEvent) {
      return {
        ...defaultEvent,
        title: defaultEvent.title,
        description: defaultEvent.description,
        priority: defaultEvent.priority,
        eventDateTimeUtc: UTCMidnightToSameDate(defaultEvent.eventDateTimeUtc),
        eventType: defaultEvent.eventType,
        distanceKms: defaultEvent.distanceKms
          ? defaultEvent.distanceKms.toString()
          : '',
        elevationGainMts: defaultEvent.elevationGainMts
          ? defaultEvent.elevationGainMts.toString()
          : '',
        elevationLossMts: defaultEvent.elevationLossMts
          ? defaultEvent.elevationLossMts.toString()
          : '',
        durationInMinutes: minutesToHHMM(defaultEvent.durationInMinutes),
      };
    }

    return {
      title: '',
      description: '',
      eventDateTimeUtc: defaultDate || addDays(new Date(), 1),
      eventType: ICalendarEventType.RACE,
      distanceKms: '',
      priority: '',
      elevationGainMts: '',
      elevationLossMts: '',
      durationInMinutes: '',
    };
  }, [defaultEvent, defaultDate]);

  const form = useForm<IEventForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = form;

  const handleSave = useCallback(
    (formData: IEventForm) => {
      if (!athlete) {
        return;
      }

      setLoading(true);

      const payload: ICreateEventRequest = {
        ...formData,
        eventDateTimeUtc: localWithUTCMidnightTimestamp(
          formData.eventDateTimeUtc
        ),
        eventType: ICalendarEventType.RACE,
        distanceKms: Number(formData.distanceKms),
        elevationGainMts: Number(formData.elevationGainMts),
        elevationLossMts: Number(formData.elevationLossMts),
        durationInMinutes: hhmmToMinutes(formData.durationInMinutes),
        athleteId: athlete.id,
      };

      if (!gpxFile) {
        payload.gpxJsonUrl = '';
        payload.gpxFileUrl = '';
      }

      const request =
        formData.id === undefined
          ? workoutsRepository.createEvent(payload)
          : workoutsRepository.updateEvent(payload);

      return request
        .then((response) => {
          if (createByGpxFile.current) {
            createByGpxFile.current = false;
            return Promise.resolve();
          }

          if (gpxFile) {
            return workoutsRepository.uploadGPX({
              id: response.id,
              formData: gpxFile,
            });
          }
        })
        .then(() => {
          setGpxFile(null);
          return fetchEvents(date, athlete.id.toString());
        })
        .then(() => {
          onDismiss();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      athlete,
      workoutsRepository,
      fetchEvents,
      date,
      onDismiss,
      gpxFile,
      setGpxFile,
    ]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.WORKOUT_EVENT,
    });

    handleSubmit(
      (formData: IEventForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  // ---------------------------------------------------------------------------
  /** When user adds a GPX file, we create a new event. If user dismisses the modal,
   * without saving, we delete this event */
  const onGpxChange = useCallback(
    (file: File) => {
      if (!athlete) {
        return;
      }

      setGpxFileLoading(true);
      const gpxFile = new FormData();
      gpxFile.append('gpxFile', file);
      setGpxFile(gpxFile);

      const formData = getValues();
      const payload: ICreateEventRequest = {
        title: formData.title || `Event ${Date.now()}`,
        description: formData.description || 'Description',
        durationInMinutes: (formData.durationInMinutes = 0),
        eventDateTimeUtc: localWithUTCMidnightTimestamp(
          formData.eventDateTimeUtc
        ),
        eventType: ICalendarEventType.RACE,
        athleteId: athlete.id,
      };

      const onUpdate = (event: ICalendarEvent | undefined) => {
        if (event) {
          setValue('id', event.id);
          setValue(
            'distanceKms',
            event.distanceKms ? event.distanceKms.toString() : ''
          );
          setValue(
            'elevationGainMts',
            event.elevationGainMts ? event.elevationGainMts.toString() : ''
          );
          setValue(
            'elevationLossMts',
            event.elevationLossMts ? event.elevationLossMts.toString() : ''
          );
        }
      };

      if (!formData.id) {
        workoutsRepository
          .createEvent(payload)
          .then((response) => {
            createByGpxFile.current = true;
            dismissCallback.current = () => {
              if (createByGpxFile.current) {
                const formData = getValues();
                workoutsRepository.deleteEvent({
                  eventId: formData.id,
                });
                createByGpxFile.current = false;
              }
            };

            if (gpxFile) {
              return workoutsRepository.uploadGPX({
                id: response.id,
                formData: gpxFile,
              });
            }
          })
          .then((event) => {
            onUpdate(event);
          })
          .finally(() => {
            setGpxFileLoading(false);
          });
      } else {
        workoutsRepository
          .updateEvent({
            ...payload,
            id: formData.id,
            elevationGainMts: '',
            elevationLossMts: '',
            distanceKms: '',
          })
          .then(() => {
            return workoutsRepository.uploadGPX({
              id: formData.id,
              formData: gpxFile,
            });
          })
          .then((event) => {
            onUpdate(event);
            dismissCallback.current = () => {
              workoutsRepository.updateEvent(defaultEvent).then(() => {
                fetchEvents(date, athlete.id.toString());
              });
            };
          })
          .finally(() => {
            setGpxFileLoading(false);
          });
      }
      console.log(payload);
    },
    [
      athlete,
      getValues,
      setValue,
      workoutsRepository,
      dismissCallback,
      defaultEvent,
      fetchEvents,
      date,
    ]
  );

  const onGpxClear = useCallback(() => {
    setGpxFile(null);
    setHasFile(false);
    setGpxFileLoading(true);

    const formData = getValues();
    const payload: IUpdateEventRequest = {
      ...formData,
      eventDateTimeUtc: localWithUTCMidnightTimestamp(
        formData.eventDateTimeUtc
      ),
      distanceKms: Number(formData.distanceKms),
      elevationGainMts: Number(formData.elevationGainMts),
      elevationLossMts: Number(formData.elevationLossMts),
      durationInMinutes: Number(formData.durationInMinutes),
      athleteId: athlete.id,
      gpxFileUrl: '',
      gpxJsonUrl: '',
    };

    workoutsRepository
      .updateEvent(payload)
      .then(() => {
        setGpxFile(null);
        dismissCallback.current = () => {
          workoutsRepository.updateEvent(defaultEvent).then(() => {
            fetchEvents(date, athlete.id.toString());
          });
        };
      })
      .finally(() => {
        setGpxFileLoading(false);
      });
  }, [
    athlete.id,
    date,
    defaultEvent,
    dismissCallback,
    fetchEvents,
    getValues,
    workoutsRepository,
  ]);

  // ---------------------------------------------------------------------------

  return (
    <FormProvider {...form}>
      <div className="calendar-events-form">
        <FormRow>
          <FormColumn>
            <FormElement
              label={t('label.title')}
              message={errors.title?.message}
            >
              <InputControl
                name="title"
                placeholder={t('placeholder.name')}
                invalid={!!errors.title}
                autoComplete="off"
              />
            </FormElement>

            <FormRow>
              <FormColumn>
                <FormElement
                  label={t('label.date')}
                  message={errors.eventDateTimeUtc?.message}
                >
                  <DatepickerControl
                    name="eventDateTimeUtc"
                    placeholder={t('placeholder.date')}
                    min={new Date()}
                    invalid={!!errors.eventDateTimeUtc}
                  />
                </FormElement>
              </FormColumn>
              <FormColumn>
                <PriorityControl />
              </FormColumn>
            </FormRow>

            <FormElement
              label={t('label.expected_what', {
                what: t('label.duration').toLowerCase(),
              })}
              message={errors.durationInMinutes?.message}
            >
              <InputTimeControl
                name="durationInMinutes"
                placeholder="hh:mm"
                invalid={!!errors.durationInMinutes}
                autoComplete="off"
                restrictToSingleDigitHours
              />
            </FormElement>
          </FormColumn>
          <FormColumn>
            <FormElement
              className="calendar-events-form__description"
              label={t('label.description')}
              message={errors.description?.message}
            >
              <TextareaControl
                name="description"
                placeholder={t('placeholder.description')}
                invalid={!!errors.description}
                autoComplete="off"
              />
            </FormElement>
          </FormColumn>
        </FormRow>

        <Divider />

        <GpxFileInput
          onChange={onGpxChange}
          onClear={onGpxClear}
          hasFile={hasFile}
          loading={gpxFileLoading}
        />
        <FormElement
          label={t('label.distance')}
          message={errors.distanceKms?.message}
        >
          <InputNumberControl
            name="distanceKms"
            placeholder={t('placeholder.distance')}
            invalid={!!errors.distanceKms}
            autoComplete="off"
            readOnly={hasFile}
          />
        </FormElement>

        <FormElement
          label={t('label.elevation_gain')}
          message={errors.elevationGainMts?.message}
        >
          <InputNumberControl
            name="elevationGainMts"
            placeholder={t('placeholder.elevation_gain')}
            invalid={!!errors.elevationGainMts}
            autoComplete="off"
            readOnly={hasFile}
          />
        </FormElement>

        <footer className="calendar-events-form__footer">
          <Button
            variant="quietLayer3"
            content={t('action.cancel')}
            onClick={handleDismiss}
          />
          <Button
            content={defaultEvent ? t('action.save') : t('action.add')}
            onClick={onSubmit}
            loading={loading}
          />
        </footer>
      </div>
    </FormProvider>
  );
};
