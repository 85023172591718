import { WaitlistService } from './service';
import { IJoinWaitlistRequest } from './types';

export interface IWaitlistRepository {
  joinWaitlist: (p: IJoinWaitlistRequest) => Promise<void>;
}

export class WaitlistRepository implements IWaitlistRepository {
  constructor(private readonly service: WaitlistService) {}

  async joinWaitlist(p: IJoinWaitlistRequest): Promise<void> {
    const response = await this.service.joinWaitlist(p);
    return response.data;
  }
}
