import './index.css';
import { Ellipsis, FileText } from 'lucide-react';
import {
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useEventStore } from '../../store/slice';

export const WorkoutFile = () => {
  const { t } = useTranslation();
  const event = useEventStore((s) => s.event);

  const menuOptions: IMenuOption[] = useMemo(() => {
    const options: IMenuOption[] = [];

    if (event?.gpxFileUrl) {
      options.push({
        id: 'gpx',
        text: t('action.download_what', { what: t('label.gpx') }),
      });
    }

    return options;
  }, [event, t]);

  const handleDownload = useCallback(
    (type: string) => {
      if (!event) {
        return;
      }

      const url = type === 'json' ? event.gpxJsonUrl : event.gpxFileUrl;

      if (!url) {
        return;
      }

      try {
        const link = document.createElement('a');
        link.href = url;
        link.download = `${event.title}_${type}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
      }
    },
    [event]
  );

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'json') {
        handleDownload(option.id);
      }

      if (option.id === 'gpx') {
        handleDownload(option.id);
      }
    },
    [handleDownload]
  );

  const menuJSX = (
    <Menu
      options={menuOptions}
      onChange={onMenuChange}
      dropdownProps={{
        anchor: <IconButton variant="layer2" size="s" content={<Ellipsis />} />,
        placement: 'bottom-end',
      }}
    />
  );

  return (
    <div className="event__workout-file">
      <div className="event__workout-file-box">
        <FileText size={16} />
      </div>
      <Typography variant="subtitle" weight="bold" text={t('label.gpx_file')} />
      <div className="event__workout-file__actions">{menuJSX}</div>
    </div>
  );
};
