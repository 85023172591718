import './index.css';
import { IconButton, useBoolean } from '@cycling-web/analog-ui';
import { MenuIcon } from 'lucide-react';
import { MobileMenu } from '../Menu';

export const MobileMenuToggle = () => {
  const {
    value: isOpen,
    setTrue: openMenu,
    setFalse: dismissMenu,
  } = useBoolean(false);

  return (
    <>
      <IconButton
        size="l"
        variant="quietLayer2"
        content={<MenuIcon />}
        onClick={openMenu}
      />
      <MobileMenu isOpen={isOpen} onDismiss={dismissMenu} />
    </>
  );
};
