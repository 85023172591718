import { useTranslation } from 'react-i18next';
import { use, useCallback } from 'react';
import { ZonesModalContext } from '../../context';
import { clsx } from 'clsx';
import { TrainingZoneKeys } from '../../../../utils/getDefaults';
import { useFormContext } from 'react-hook-form';

export const ZonesModalAside = () => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const { composeKey, setComposeKey, openDiscardDialog, resetToDefault } =
    use(ZonesModalContext);

  const links = [
    {
      id: TrainingZoneKeys.CyclingPowerMFTP,
      text: t('label.cycling'),
    },
    {
      id: TrainingZoneKeys.RunningPaceDefault,
      text: t('label.running'),
    },
    {
      id: TrainingZoneKeys.SwimmingCSSDefault,
      text: t('label.swimming'),
    },
  ];

  const handleClick = useCallback(
    (composeKey: string) => {
      return () => {
        if (formState.isDirty) {
          openDiscardDialog({
            submitAction: () => {
              setComposeKey(composeKey);
              return resetToDefault();
            },
          });
        } else {
          setComposeKey(composeKey);
        }
      };
    },
    [formState.isDirty, openDiscardDialog, setComposeKey, resetToDefault]
  );

  const linksJSX = links.map((link) => {
    const [composeKeyDiscipline] = composeKey.split('_');
    const [idDiscipline] = link.id.split('_');

    const rootClass = clsx(
      'training-zones-form-modal__aside-button',
      'analog-typography--subtitle',
      composeKeyDiscipline === idDiscipline && 'active'
    );
    return (
      <button
        key={link.id}
        className={rootClass}
        onClick={handleClick(link.id)}
      >
        {link.text}
      </button>
    );
  });

  return <aside className="training-zones-form-modal__aside">{linksJSX}</aside>;
};
