import { IconButton, Tooltip } from '@cycling-web/analog-ui';
import { CircleStop, Volume2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { use, useCallback } from 'react';
import { AudioState, IConversationMessage } from '../../types';
import { ConversationContext } from '../../context/ConversationContext';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';

type IProps = {
  message: IConversationMessage;
};

export const PlayMessageButton = ({ message }: IProps) => {
  const { t } = useTranslation();
  const {
    handlePlayMessage,
    handleStopPlayMessage,
    activeAudioState,
    playingMessage,
  } = use(ConversationContext);

  const isPlaying =
    playingMessage?.id === message.id &&
    activeAudioState === AudioState.PLAYING;
  const isLoading =
    playingMessage?.id === message.id &&
    activeAudioState === AudioState.LOADING;

  const onPlayMessage = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.READ_MESSAGE_ALOUD,
    });
    handlePlayMessage(message);
  }, [handlePlayMessage, message]);

  return isPlaying ? (
    <Tooltip
      anchor={
        <IconButton
          variant="quietLayer2"
          size="s"
          content={<CircleStop />}
          onClick={handleStopPlayMessage}
        />
      }
      content={t('action.stop')}
    />
  ) : (
    <Tooltip
      anchor={
        <IconButton
          variant="quietLayer2"
          size="s"
          content={<Volume2 />}
          onClick={onPlayMessage}
          loading={isLoading}
        />
      }
      content={t('action.read_aloud')}
    />
  );
};
