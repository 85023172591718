import { IWorkoutCalendarSlice, IWorkoutCalendarState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ICalendarDayOffs,
  ICalendarEvent,
  ICalendarEvents,
  IWorkoutCalendar,
} from '../../../types/workouts';
import { format } from 'date-fns';
import { mapWorkoutsAndPrescriptions } from '../utils/mapPrescriptionsToWorkouts';

export const initialState: IWorkoutCalendarState = {
  workouts: {},
  workoutsLoaded: false,
  dayOffs: {},
  dayOffsLoaded: false,
  events: {},
  eventsLoaded: false,
  weekSummary: {},
};

export const useWorkoutCalendarStore = create<IWorkoutCalendarSlice>()(
  immer((set) => ({
    ...initialState,
    setWorkouts: (workouts: IWorkoutCalendar) => {
      set((state) => {
        state.workouts = mapWorkoutsAndPrescriptions(workouts);
        state.workoutsLoaded = true;
      });
    },
    setWorkoutsLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutsLoaded = loaded;
      });
    },

    setDayOffs: (dayOffs: ICalendarDayOffs) => {
      set((state) => {
        state.dayOffs = dayOffs;
        state.dayOffsLoaded = true;
      });
    },
    setDayOffsLoaded: (loaded: boolean) => {
      set((state) => {
        state.dayOffsLoaded = loaded;
      });
    },
    setEvents: (events: ICalendarEvent[]) => {
      set((state) => {
        const eventsMap: ICalendarEvents = {};

        for (let i = 0; i < events.length; i++) {
          const key = format(events[i].eventDateTimeUtc, 'yyyy-MM-dd');
          if (eventsMap[key] === undefined) {
            eventsMap[key] = [];
          }
          eventsMap[key].push(events[i]);
        }

        state.events = eventsMap;
        state.eventsLoaded = true;
      });
    },
    setEventsLoaded: (loaded: boolean) => {
      set((state) => {
        state.eventsLoaded = loaded;
      });
    },
    deleteEvent: (event: ICalendarEvent) => {
      set((state) => {
        const key = format(event.eventDateTimeUtc, 'yyyy-MM-dd');
        state.events[key] = state.events[key].filter((e) => e.id !== event.id);

        if (state.events[key].length === 0) {
          delete state.events[key];
        }
      });
    },
    setWeekSummary: (week: string, report: IPerformanceReport) => {
      set((state) => {
        state.weekSummary[week] = report;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
