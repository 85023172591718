import { OnSpeak } from '../types';

export class VoiceActivityDetector {
  private audioContext: AudioContext | null = null;
  private analyserNode: AnalyserNode | null = null;
  private dataArray: Uint8Array = new Uint8Array();
  private isThrottled = false;
  private throttleInterval = 50;
  private isDetecting = false;

  /**
   * Starts the voice activity detection by setting up the Web Audio API.
   * @param stream The audio stream from the user's microphone.
   * @param onSpeak The callback function.
   */
  public async start(stream: MediaStream, onSpeak?: OnSpeak): Promise<void> {
    try {
      this.audioContext = new AudioContext();

      const source = this.audioContext.createMediaStreamSource(stream);
      this.analyserNode = this.audioContext.createAnalyser();
      this.analyserNode.fftSize = 256;

      source.connect(this.analyserNode);

      this.dataArray = new Uint8Array(this.analyserNode.frequencyBinCount);

      this.isDetecting = true; // Start detection
      this.detectVoiceActivity(onSpeak);
    } catch (error) {
      console.error('Failed to start voice detection:', error);
    }
  }

  /**
   * Detects voice activity by analyzing the audio signal for volume changes.
   */
  private detectVoiceActivity(onSpeak?: OnSpeak): void {
    if (!this.analyserNode || !this.dataArray || !this.isDetecting) return;

    const detect = (): void => {
      if (!this.isDetecting) return; // Stop the loop if detection is stopped

      if (this.isThrottled) {
        requestAnimationFrame(detect); // Continue the loop but skip execution
        return;
      }

      this.isThrottled = true; // Mark as throttled

      this.analyserNode!.getByteTimeDomainData(this.dataArray);

      let sum = 0;
      for (let i = 0; i < this.dataArray.length; i++) {
        sum += Math.abs(this.dataArray[i] - 128); // Centered around 128
      }
      const averageVolume = sum / this.dataArray.length;

      if (onSpeak) {
        onSpeak({
          averageVolume,
        });
      }

      // Reset throttling state after the interval
      setTimeout(() => {
        this.isThrottled = false;
      }, this.throttleInterval);

      requestAnimationFrame(detect); // Continue the loop
    };

    detect();
  }

  /**
   * Stops the voice activity detection and cleans up resources.
   */
  public stop(): void {
    this.isDetecting = false; // Stop the detection loop

    if (this.audioContext) {
      this.audioContext.close().catch((error) => {
        console.error('Error closing AudioContext:', error);
      });
      this.audioContext = null;
    }

    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.analyserNode = null;
    }

    this.dataArray = new Uint8Array(); // Clear the data array
  }
}
