import './index.css';
import { TrainingPeaks } from '../../components/data-sharing/TrainingPeaks';
import { useCallback, useMemo } from 'react';
import { UltraHuman } from '../../components/data-sharing/UltraHuman';
import { Whoop } from '../../components/data-sharing/Whoop';
import { Wahoo } from '../../components/data-sharing/Wahoo';
import { Oura } from '../../components/data-sharing/Oura';
import { useFetchPlatforms } from '../../hooks/useFetchPlatforms';
import { usePlatformsStore } from '../../store/platforms/slice';
import { PlatformsGroup } from './components/PlatformsGroup';
import { Divider } from '@cycling-web/analog-ui';
import { IPlatform, IPlatformEnum } from '../../types/platform';
import { MyWhoosh } from '../../components/data-sharing/MyWhoosh';
import { Garmin } from '../../components/data-sharing/Garmin';
import { Environment, getEnvironment } from '../../utils/getEnvironment';

export const SettingsDataSharing = () => {
  const { fetchPlatforms } = useFetchPlatforms();
  const platforms = usePlatformsStore((s) => s.platforms);
  const platformsLoaded = usePlatformsStore((s) => s.platformsLoaded);

  const connectedPlatforms: Record<IPlatformEnum, IPlatform> = useMemo(() => {
    return platforms.reduce((acc: Record<string, IPlatform>, p: IPlatform) => {
      acc[p.platformName as IPlatformEnum] = p;
      return acc;
    }, {});
  }, [platforms]);

  const onConnect = useCallback(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  const onDisconnect = useCallback(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  return (
    <div className="settings__platforms">
      <PlatformsGroup title="Devices">
        <UltraHuman
          platform={connectedPlatforms[IPlatformEnum.UltraHuman]}
          platformLoaded={platformsLoaded}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          showDetails={true}
        />
        <Whoop
          platform={connectedPlatforms[IPlatformEnum.Whoop]}
          platformLoaded={platformsLoaded}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          showDetails={true}
        />
        <Wahoo
          platform={connectedPlatforms[IPlatformEnum.Wahoo]}
          platformLoaded={platformsLoaded}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          showDetails={true}
        />
        <Oura
          platform={connectedPlatforms[IPlatformEnum.Oura]}
          platformLoaded={platformsLoaded}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          showDetails={true}
        />
        {getEnvironment() !== Environment.Production && (
          <Garmin
            platform={connectedPlatforms[IPlatformEnum.Garmin]}
            platformLoaded={platformsLoaded}
            onConnect={onConnect}
            onDisconnect={onDisconnect}
            showDetails={true}
          />
        )}
      </PlatformsGroup>

      <Divider />

      <PlatformsGroup title="Apps">
        <TrainingPeaks
          platform={connectedPlatforms[IPlatformEnum.TrainingPeaks]}
          platformLoaded={platformsLoaded}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          showDetails={true}
        />
        <MyWhoosh
          platform={connectedPlatforms[IPlatformEnum.MyWhoosh]}
          platformLoaded={platformsLoaded}
          onConnect={onConnect}
          onDisconnect={onDisconnect}
          showDetails={true}
        />
      </PlatformsGroup>
    </div>
  );
};
