import { ChartColor } from '../components/Chart';
import { roundToDecimals } from './maths';

type IResult = {
  color: string;
};

export const McpUtils = {
  getRelativeColor(value: number): IResult {
    let color = '#f3f3f6';
    const absValue = Math.abs(value);

    if (value > 0) {
      if (absValue >= 1 && absValue <= 5) {
        color = ChartColor.LightGreen;
      } else if (absValue > 5 && absValue <= 10) {
        color = ChartColor.Cyan;
      } else {
        color = ChartColor.Green;
      }
    } else if (value < 0) {
      if (absValue >= 1 && absValue <= 5) {
        color = ChartColor.LightRed;
      } else if (absValue > 5 && absValue <= 10) {
        color = ChartColor.Orange;
      } else {
        color = ChartColor.Red;
      }
    }

    return {
      color,
    };
  },
  getAbsoluteColor(value: number): IResult {
    let color = '#f3f3f6';
    const absValue = Math.abs(value);

    if (value > 0) {
      if (absValue >= 1 && absValue <= 10) {
        color = ChartColor.LightGreen;
      } else if (absValue > 10 && absValue <= 20) {
        color = ChartColor.Cyan;
      } else {
        color = ChartColor.Green;
      }
    } else if (value < 0) {
      if (absValue >= 1 && absValue <= 10) {
        color = ChartColor.LightRed;
      } else if (absValue > 11 && absValue <= 20) {
        color = ChartColor.Orange;
      } else {
        color = ChartColor.Red;
      }
    }
    return {
      color,
    };
  },
  computeCPFromFTP(ftp: number): number {
    return roundToDecimals(ftp / 0.95, 2);
  },

  computeFTPFromCP(cp: number): number {
    return roundToDecimals(cp * 0.95, 2);
  },
};
