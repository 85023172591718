import './index.css';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  IChartSource,
  IEChartOption,
} from '../../../../../../components/Chart';
import { ChartWrapper } from '../../../../../../components/ChartWrapper';
import React, { useMemo } from 'react';
import { handleChartMouseOver } from '../../../../../../components/Chart/utils/chartTracking';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../../../constants/charts';
import { IHeartMeasurement } from '../../../../../../types/wellness';
import { useTranslation } from 'react-i18next';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import ReactDOMServer from 'react-dom/server';
import { LabelValue, Typography } from '@cycling-web/analog-ui';
import { format, parse } from 'date-fns';
import { TooltipValue } from './TooltipValue';

type IProps = {
  heartMeasurements: IHeartMeasurement | null;
  loading?: boolean;
};

export const HRVvsRHRChart = ({ heartMeasurements, loading }: IProps) => {
  const { t } = useTranslation();

  const { source, maxDiff, totalItems } = useMemo(() => {
    const source: IChartSource = [
      [
        ChartAxisName.HRV,
        ChartAxisName.RHR,
        'actualHRV',
        'actualRHR',
        ChartAxisName.Date,
        'day',
      ],
    ];

    let maxRhrDiff = 1;
    let maxHrvDiff = 1;

    if (heartMeasurements) {
      heartMeasurements.points.forEach((p, day) => {
        const hrvDiff = p.data.hrv - p.data.hrvBaseline;
        const rhrDiff = p.data.rhr - p.data.rhrBaseline;

        maxRhrDiff = Math.max(Math.abs(maxRhrDiff), Math.abs(rhrDiff));
        maxHrvDiff = Math.max(Math.abs(maxHrvDiff), Math.abs(hrvDiff));

        const parsedDate = parse(p.startDate, 'yyyy-MM-dd', new Date());
        const formattedDate = format(parsedDate, 'dd/MM/yyyy');

        source.push([
          hrvDiff,
          rhrDiff,
          p.data.hrv,
          p.data.rhr,
          formattedDate,
          day,
        ]);
      });
    }

    const totalItems = source.length - 1;

    const circleRadius = Math.max(maxRhrDiff, maxHrvDiff);
    const clampedSource = source.map((row, index) => {
      if (index === 0) {
        return row;
      }

      const hrvDiff = row[0] as number;
      const rhrDiff = row[1] as number;

      const dist = Math.sqrt(hrvDiff * hrvDiff + rhrDiff * rhrDiff);
      if (dist > circleRadius) {
        const scale = circleRadius / dist;
        row[0] = hrvDiff * scale;
        row[1] = rhrDiff * scale;
      }
      return row;
    });

    return {
      source: clampedSource.filter((item) => !(item[2] === 0 || item[3] === 0)),
      maxDiff: circleRadius,
      totalItems,
    };
  }, [heartMeasurements]);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        containLabel: false,
      },
      xAxis: {
        type: 'value',
        min: -maxDiff,
        max: maxDiff,
        axisLine: {
          show: true,
          lineStyle: {
            type: 'solid',
            color: ChartColor.GridLine,
          },
        },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
      },
      yAxis: {
        type: 'value',
        min: -maxDiff,
        max: maxDiff,
        axisLine: {
          show: true,
          lineStyle: {
            type: 'solid',
            color: ChartColor.GridLine,
          },
        },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        axisPointer: {
          show: true,
          label: {
            show: false,
          },
        },
      },
      series: [
        {
          name: ChartAxisName.HRV,
          type: 'scatter',
          zIndex: 30,
          symbolSize: (_, params) => {
            const index = params.value[5];

            if (index === totalItems - 1) {
              return 20;
            }

            if (index > totalItems - 7) {
              return 8;
            }

            return 8;
          },
          itemStyle: {
            color: (params) => {
              const index = params.value[5];

              if (index === totalItems - 1) {
                return ChartColor.LightBlue;
              }

              if (index > totalItems - 7) {
                return ChartColor.LightPurple;
              }
              return ChartColor.LightGreen;
            },
            opacity: 1,
            borderWidth: 0,
            borderColor: 'transparent',
          },
        },
      ],
      tooltip: {
        formatter: (params: CallbackDataParams[]) => {
          const hrvDiff = params[0]?.value?.[0];
          const rhrDiff = params[0]?.value?.[1];
          const hrv = params[0]?.value?.[2];
          const rhr = params[0]?.value?.[3];

          return ReactDOMServer.renderToString(
            <div className="hrv-vs-rhr-chart__tooltip">
              <Typography text={params[0]?.value?.[4]} />
              <LabelValue
                label={params[0]?.dimensionNames?.[0]}
                value={
                  <TooltipValue
                    value={hrv}
                    valueDiff={hrvDiff}
                    hrvDiff={hrvDiff}
                    rhrDiff={rhrDiff}
                  />
                }
              />
              <LabelValue
                label={params[0]?.dimensionNames?.[1]}
                value={
                  <TooltipValue
                    value={rhr}
                    valueDiff={rhrDiff}
                    hrvDiff={hrvDiff}
                    rhrDiff={rhrDiff}
                  />
                }
              />
            </div>
          );
        },
      },
    };
  }, [maxDiff, source, totalItems]);

  const legendJSX = (
    <div className="hrv-vs-rhr-chart__legend analog-typography--subtitle">
      <div className="hrv-vs-rhr-chart__legend-item">
        <ChartLegendItem
          item={{
            name: t('label.today'),
            color: ChartColor.LightBlue,
            type: 'solid',
            selected: true,
          }}
        />
        <ChartLegendItem
          item={{
            name: t('label.last_n_days', { d: 7 }),
            color: ChartColor.LightPurple,
            type: 'solid',
            selected: true,
          }}
        />
        <ChartLegendItem
          item={{
            name: t('label.last_n_days', { d: 30 }),
            color: ChartColor.LightGreen,
            type: 'solid',
            selected: true,
          }}
        />
      </div>
    </div>
  );

  const hasData = source.length > 1;

  return (
    <ChartWrapper minHeight="254px" className="hrv-vs-rhr-chart__wrapper">
      <div className="hrv-vs-rhr-chart">
        <Chart
          headerProps={{ title: '', show: false }}
          option={option}
          legendProps={{ show: false }}
          loading={loading}
          events={{
            onMouseOver: handleChartMouseOver({
              name: ChartTrackingNames.HRVvsRHR,
            }),
          }}
        />
        {hasData && (
          <>
            <div className="hrv-vs-rhr-chart__label left analog-typography--subtitle light">
              {`${t('label.low')} ${t('label.hrv')}`}
            </div>
            <div className="hrv-vs-rhr-chart__label top analog-typography--subtitle light">
              {`${t('label.high')} ${t('label.rhr')}`}
            </div>
            <div className="hrv-vs-rhr-chart__label right analog-typography--subtitle light">
              {`${t('label.high')} ${t('label.hrv')}`}
            </div>
            <div className="hrv-vs-rhr-chart__label bottom analog-typography--subtitle light">
              {`${t('label.low')} ${t('label.rhr')}`}
            </div>
          </>
        )}
      </div>
      {hasData && legendJSX}
    </ChartWrapper>
  );
};
