import './index.css';
import { ReactNode } from 'react';
import { ITooltipProps } from '../../Tooltip/types';
import { Info } from 'lucide-react';
import { Typography } from '../../Typography';
import { Tooltip } from '../../Tooltip';

type IProps = {
  icon?: ReactNode;
  title: ReactNode;
  tooltipProps?: Omit<ITooltipProps, 'anchor'>;
  endContent?: ReactNode;
};

export const CardHeader = ({
  icon,
  title,
  tooltipProps,
  endContent,
}: IProps) => {
  return (
    <div className="analog-card-header">
      {icon && <div className="analog-card-header__icon">{icon}</div>}
      <Typography
        variant="headline"
        weight="bold"
        text={title}
        className="analog-card-header__title"
      />
      {tooltipProps && (
        <Tooltip
          className="analog-card-header__tooltip"
          anchor={
            <Info className="analog-card-header__tooltip-anchor" size="16" />
          }
          {...tooltipProps}
        />
      )}
      {endContent && (
        <div className="analog-card-header__end">{endContent}</div>
      )}
    </div>
  );
};
