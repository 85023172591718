import './index.css';
import { Card, CardHeader, EmptyState, Skeleton } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useWorkoutStore } from '../../store/slice';
import { Comment } from './Comment';

export const WorkoutComments = () => {
  const { t } = useTranslation();
  const workoutAnalysis = useWorkoutStore((s) => s.workoutAnalysis);
  const workoutAnalysisLoaded = useWorkoutStore((s) => s.workoutAnalysisLoaded);

  return (
    <Card variant="layer3" className="workout-card__comments">
      <CardHeader title={t('label.workout_comments')} />

      {workoutAnalysis ? (
        <Comment
          text={workoutAnalysis?.analysis_result}
          author={t('label.ana')}
        />
      ) : !workoutAnalysisLoaded ? (
        <>
          <Skeleton width="220px" />
          <Skeleton width="170px" />
        </>
      ) : (
        <EmptyState
          size="s"
          text={t('banner.empty_workout_comment_text')}
          // buttonProps={{
          //   variant: 'secondary',
          //   content: t('banner.empty_workout_comment_action'),
          // }}
        />
      )}
    </Card>
  );
};
