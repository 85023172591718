import './index.css';
import { useAthleteStore } from '../../../../store/athlete/slice';
import {
  Avatar,
  IconButton,
  ISelectOption,
  Tooltip,
} from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { ChevronDown } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useUserRole } from '../../../../hooks/useUserRole';
import { SingleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useTranslation } from 'react-i18next';
import { useGroupsStore } from '../../../../store/groups/slice';
import { ROUTES } from '../../../../router/routes';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IAthlete } from '../../../../types/athletes';
import orderBy from 'lodash/orderBy';

export const AthleteDetailsPageTitle = () => {
  const { groupId } = useParams();
  const groups = useGroupsStore((s) => s.groups);
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);
  const athletes = useAthletesStore((s) => s.athletes);
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const athlete = useAthleteStore((s) => s.athlete);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentGroup = useMemo(() => {
    if (!groupId) {
      return undefined;
    }

    return groups.find((g) => g.id === +groupId);
  }, [groupId, groups]);

  const athletesOptions: ISelectOption[] = useMemo(() => {
    if (!athletesLoaded || !groupsLoaded) {
      return [];
    }

    if (!currentGroup) {
      return athletes.map((a: IAthlete) => {
        return {
          id: a.id.toString(),
          text: a.fullName,
        };
      });
    }

    const sortedAthleteIds = orderBy(
      currentGroup.athleteIds,
      (id) => athletesMap[id]?.lastName,
      ['asc']
    );

    return sortedAthleteIds.map((id: number) => {
      return {
        id: id.toString(),
        text: athletesMap[id]?.fullName,
      };
    });
  }, [athletes, athletesLoaded, athletesMap, currentGroup, groupsLoaded]);

  const onAthleteChange = useCallback(
    (athleteId: number) => {
      const tmp = pathname.split('/');
      const index = tmp.findIndex((s) => s === ROUTES.ATHLETES);

      if (index >= 0) {
        tmp[index + 1] = athleteId.toString();
      }

      const searchParams = window.location.search;
      const newPath = tmp.join('/') + (searchParams ? searchParams : '');

      navigate(newPath);
    },
    [navigate, pathname]
  );

  const onRenderAnchor = useCallback(() => {
    return (
      <Tooltip
        anchor={
          <IconButton
            size="s"
            variant="quietLayer2"
            content={<ChevronDown />}
          />
        }
        content={t('tooltip.select_athlete')}
      />
    );
  }, [t]);

  if (isAthlete) {
    return <>{t('label.my_profile')}</>;
  }

  return (
    <div className="athlete-details__page-title">
      <Avatar
        className="athlete-details__page-title-avatar"
        size="l"
        text={athlete?.fullName}
        subtext={currentGroup?.name}
        src={athlete?.picture}
        loading={!athleteLoaded}
      />
      <SingleAthleteFilter
        value={athlete?.id}
        options={athletesOptions}
        onChange={onAthleteChange}
        selectProps={{ onRenderAnchor }}
        dropdownProps={{
          minWidth: '260px',
        }}
        skeletonProps={{ width: '32px', height: '32px' }}
        style={{ minWidth: '0' }}
      />
    </div>
  );
};
