import './index.css';
import { ReactNode, RefObject } from 'react';
import { clsx } from 'clsx';

type IProps = {
  children: ReactNode;
  minHeight?: string;
  className?: string;
  ref?: RefObject<HTMLDivElement | null>;
};

export const ChartWrapper = ({
  children,
  minHeight,
  className,
  ref,
}: IProps) => {
  const rootClass = clsx('chart-wrapper', className);

  return (
    <div
      className={rootClass}
      style={{ minHeight: minHeight || '450px' }}
      ref={ref}
    >
      {children}
    </div>
  );
};
