import './index.css';
import { Button, ConfirmDialog, Dialog, Tooltip } from '@cycling-web/analog-ui';
import { FormProvider } from 'react-hook-form';
import { useViewModel } from './useViewModel';
import { ZonesModalAside } from './components/Aside';
import { ZonesModalContext } from './context';
import { IZonesModalProps } from '../../types';
import { ZonesModalTabs } from './components/Tabs';
import { ZonesModalFilters } from './components/Filters';
import { ZonesForm } from './components/Form';
import { useCallback } from 'react';

export const TrainingZonesModal = (props: IZonesModalProps) => {
  const { t, form, onDismiss, onSubmit, loading, context } =
    useViewModel(props);

  const { onReset, discardDialogProps, dismissDiscardDialog, currentProfile } =
    context;

  const canReset = currentProfile?.custom || form.formState.isDirty;

  const onRenderFooter = useCallback(() => {
    return (
      <footer className="training-zones-modal__footer">
        <div className="training-zones-modal__footer-left">
          {canReset && (
            <Tooltip
              anchor={
                <Button
                  variant="secondary"
                  content={t('action.reset')}
                  onClick={onReset}
                />
              }
              content={t('tooltip.reset_to_analog_method')}
              placement="top-start"
            />
          )}
        </div>
        <div className="training-zones-modal__footer-right">
          <Button
            variant="quietLayer3"
            content={t('action.cancel')}
            onClick={onDismiss}
          />
          <Button
            content={t('action.save')}
            onClick={onSubmit}
            loading={loading}
          />
        </div>
      </footer>
    );
  }, [canReset, t, onReset, onDismiss, onSubmit, loading]);

  return (
    <ZonesModalContext value={context}>
      <FormProvider {...form}>
        <Dialog
          className="training-zones-modal"
          title={t('label.zones')}
          onDismiss={onDismiss}
          outsidePress={false}
          onRenderFooter={onRenderFooter}
        >
          <div className="training-zones-form-modal">
            <ZonesModalAside />
            <div className="training-zones-form-modal__main">
              <ZonesModalTabs />
              <ZonesModalFilters />
              <ZonesForm />
            </div>
          </div>
          {discardDialogProps && (
            <ConfirmDialog
              onDismiss={dismissDiscardDialog}
              title={t('confirm.discard_changes_title')}
              subtitle={t('confirm.discard_changes_message')}
              submitText={t('action.discard')}
              submitAction={discardDialogProps.submitAction || Promise.resolve}
            />
          )}
        </Dialog>
      </FormProvider>
    </ZonesModalContext>
  );
};
