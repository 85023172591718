import { useCallback, useMemo, useRef, useState } from 'react';
import { IConfirmDialogProps } from '@cycling-web/analog-ui';
import {
  CalendarAddModalPage,
  ICalendarAddContext,
  ICalendarAddModalProps,
} from './types';

export const useViewModel = (props: ICalendarAddModalProps) => {
  const dismissCallback = useRef<(() => void) | null>(null);

  const [currentPage, setCurrentPage] = useState<CalendarAddModalPage>(() => {
    if (props.defaultDayOff) {
      return CalendarAddModalPage.Availability;
    }

    if (props.defaultEvent) {
      return CalendarAddModalPage.Event;
    }

    if (props.defaultPrescription) {
      return CalendarAddModalPage.Workout;
    }

    return CalendarAddModalPage.Event;
  });

  const [discardDialogProps, setDiscardDialogProps] = useState<
    Partial<IConfirmDialogProps> | undefined
  >(undefined);

  const openDiscardDialog = useCallback(
    (props: Partial<IConfirmDialogProps>) => {
      setDiscardDialogProps(props);
    },
    []
  );

  const dismissDiscardDialog = useCallback(() => {
    setDiscardDialogProps(undefined);
  }, []);

  const handleDismiss = useCallback(() => {
    if (dismissCallback.current) {
      dismissCallback.current();
    }
    dismissCallback.current = null;
    props.onDismiss();
  }, [props]);

  const context = useMemo((): ICalendarAddContext => {
    return {
      ...props,
      handleDismiss,
      discardDialogProps,
      openDiscardDialog,
      dismissDiscardDialog,
      currentPage,
      setCurrentPage,
      dismissCallback,
    };
  }, [
    props,
    handleDismiss,
    discardDialogProps,
    openDiscardDialog,
    dismissDiscardDialog,
    currentPage,
    setCurrentPage,
  ]);

  return {
    context,
  };
};
