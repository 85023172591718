import { FiltersService } from './service';
import {
  ICreateCustomPeriodFilterRequest,
  ICreateCustomPeriodFilterResponse,
  IDeleteCustomPeriodFilterRequest,
  IDeleteCustomPeriodFilterResponse,
  IGetCustomPeriodFilterResponse,
  IGetMetricSeasonsFilterRequest,
  IGetMetricSeasonsFilterResponse,
  IGetWorkoutSeasonsFilterRequest,
  IGetWorkoutSeasonsFilterResponse,
  IUpdateCustomPeriodFilterRequest,
  IUpdateCustomPeriodFilterResponse,
} from './types';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';

export interface IFiltersRepository {
  getWorkoutSeasons: (
    p: IGetWorkoutSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ) => Promise<IGetWorkoutSeasonsFilterResponse>;
  getMetricSeasons: (
    p: IGetMetricSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ) => Promise<IGetMetricSeasonsFilterResponse>;
  getCustomPeriodFilters: (
    c?: AxiosRequestConfig
  ) => Promise<IGetCustomPeriodFilterResponse>;
  createCustomPeriodFilter: (
    p: ICreateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ) => Promise<ICreateCustomPeriodFilterResponse>;
  updateCustomPeriodFilter: (
    p: IUpdateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ) => Promise<IUpdateCustomPeriodFilterResponse>;
  deleteCustomPeriodFilter: (
    p: IDeleteCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ) => Promise<IDeleteCustomPeriodFilterResponse>;
}

export class FiltersRepository
  extends BaseRepository
  implements IFiltersRepository
{
  constructor(
    private readonly service: FiltersService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getWorkoutSeasons(
    p: IGetWorkoutSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ): Promise<IGetWorkoutSeasonsFilterResponse> {
    const response = await this.handleRequest(
      this.service.getWorkoutSeasons,
      p,
      c
    );
    return response.data;
  }

  async getMetricSeasons(
    p: IGetMetricSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ): Promise<IGetMetricSeasonsFilterResponse> {
    const response = await this.handleRequest(
      this.service.getMetricSeasons,
      p,
      c
    );
    return response.data;
  }

  async getCustomPeriodFilters(
    c?: AxiosRequestConfig
  ): Promise<IGetCustomPeriodFilterResponse> {
    const response = await this.handleRequest(
      this.service.getCustomPeriodFilters,
      c
    );
    return response.data;
  }

  async createCustomPeriodFilter(
    p: ICreateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ): Promise<ICreateCustomPeriodFilterResponse> {
    const response = await this.handleRequest(
      this.service.createCustomPeriodFilter,
      p,
      c
    );
    return response.data;
  }

  async updateCustomPeriodFilter(
    p: IUpdateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ): Promise<IUpdateCustomPeriodFilterResponse> {
    const response = await this.handleRequest(
      this.service.updateCustomPeriodFilter,
      p,
      c
    );
    return response.data;
  }

  async deleteCustomPeriodFilter(
    p: IDeleteCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ): Promise<IDeleteCustomPeriodFilterResponse> {
    const response = await this.handleRequest(
      this.service.deleteCustomPeriodFilter,
      p,
      c
    );
    return response.data;
  }
}
