import { useTranslation } from 'react-i18next';
import { PageFilters } from '@cycling-web/common';
import { Button, ISelectOption, ITableColumn } from '@cycling-web/analog-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { AnyValue } from '../../../../types/common';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IPerformanceReport } from '../../../../types/performance';
import { EMPTY } from '../../../../constants';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useTableColumns } from '../../hooks/useTableColumns';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useGroupReportsStore } from '../../../GroupDetails/store/slice';
import { IGroupReportsFilters } from '../../../GroupDetails/types';
import { downloadCSV } from '../../../../utils/csv';
import { IAthlete } from '../../../../types/athletes';
import { useGroupAthletes } from '../../../../hooks/useGroupAthletes';
import { usePageFilters } from '../../../../context/PageFilters';
import { getDefaultPageFilters } from '../../../../constants/page-filters';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';
import { useMediaMatch } from 'rooks';

export const GroupOverviewFilters = () => {
  const { t } = useTranslation();
  const isMobile = useMediaMatch('(max-width: 480px)');

  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const reports = useGroupReportsStore((s) => s.reports);
  const { filters, handleChangeFilter, removeFilter, initPageFilters } =
    usePageFilters<IGroupReportsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().group);
  }, [initPageFilters]);

  const { groupAthletes } = useGroupAthletes();

  const athleteOptions: ISelectOption[] = useMemo(() => {
    return groupAthletes.map((a: IAthlete) => {
      return {
        id: a.id.toString(),
        text: a.fullName,
      };
    });
  }, [groupAthletes]);

  const columns: ITableColumn[] = useTableColumns();

  const handleClearSearch = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  const handleClearAthletes = useCallback(() => {
    removeFilter('athletes');
  }, [removeFilter]);

  const handleExportReport = useCallback(() => {
    const headers = columns.map((column: ITableColumn) => {
      return { label: column.name, field: column.key };
    });

    const mappedData: AnyValue[] = reports.map((item: IPerformanceReport) => {
      const athlete = athletesMap[item.athleteId]
        ? athletesMap[item.athleteId].fullName
        : EMPTY;

      return {
        athlete: athlete,
        total_duration: item.durationStr || EMPTY,
        elevation_gain: item.climbInMeter || EMPTY,
        work: item.workInKj || EMPTY,
        distance: item.distanceInKm || EMPTY,
        tss: item.tss || EMPTY,
        ctl: item.ctl || EMPTY,
        tsb: item.tsb || EMPTY,
        coach_rating: item.coachRating || EMPTY,
        coach_comment: item.comments || EMPTY,
        next_race: item.nextRace || EMPTY,
        mcp: item.mCPRelative || EMPTY,
      };
    });

    const name = `${t('label.group_report')}`;

    downloadCSV(headers, mappedData, name);

    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.EXPORT_PERFORMANCE_REPORT,
    });
  }, [columns, reports, t, athletesMap]);

  const filtersJSX = (
    <>
      <MultipleAthleteFilter
        options={athleteOptions}
        value={filters.athletes?.split(',').map(Number) || []}
        onChange={handleChangeFilter('athletes')}
        onClear={handleClearAthletes}
        style={{ minWidth: '220px' }}
        className="group-table__athletes-filters"
      />
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
      />
    </>
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
      actionElements={
        isMobile ? undefined : (
          <Button content={t('action.export')} onClick={handleExportReport} />
        )
      }
    />
  );
};
