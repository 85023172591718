import './index.css';
import { IGroup } from '../../../../types/groups';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';
import { Link } from 'react-router';
import {
  AvatarStack,
  IAvatarStackAvatar,
  Typography,
} from '@cycling-web/analog-ui';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { ROUTES } from '../../../../router/routes';
import { Actions } from './Actions';
import { MouseEvent, useCallback } from 'react';

type IProps = {
  group?: IGroup;
  loading?: boolean;
};

export const GroupCard = ({ group, loading }: IProps) => {
  const baseUrl = useBaseUrl();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const url = `${baseUrl}/${ROUTES.COACH}/${ROUTES.GROUPS}/${group?.id}`;
  const avatars: IAvatarStackAvatar[] = (group?.athleteIds || []).map(
    (id: number) => {
      const athlete = athletesMap[id];
      return {
        text: athlete?.fullName,
        src: athlete?.picture,
      };
    }
  );

  const onLinkClick = useCallback(
    (e: MouseEvent) => {
      if (loading) {
        e.preventDefault();
      }
    },
    [loading]
  );

  return (
    <Link to={url} onClick={onLinkClick} className="group-card__link-wrapper">
      <div className="group-card">
        {!loading && group && (
          <div className="group-card__menu-toggle">
            <Actions group={group} />
          </div>
        )}
        <Typography
          as="h3"
          variant="headline"
          weight="bold"
          text={group?.name}
          loading={loading}
          className="group-card__title"
        />
        <Typography
          as="div"
          variant="subtitle"
          weight="light"
          text={group?.description}
          loading={loading}
          skeletonProps={{ width: '130px' }}
          className="group-card__description"
        />
        <div className="group-card__athletes">
          <AvatarStack avatars={avatars} loading={loading} />
        </div>
      </div>
    </Link>
  );
};
