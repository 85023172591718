import './index.css';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import {
  IconButton,
  ILabelValueProps,
  IMenuOption,
  Menu,
  TruncatedText,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { IWorkoutCalendarWorkout } from '../../../../types/workouts';
import { clsx } from 'clsx';
import { CalendarWorkoutStats } from '../CalendarWorkoutStats';
import { hoursToHHMMSS } from '../../../../utils/date-time';
import { roundToDecimals } from '../../../../utils/maths';
import { useCallback, useMemo } from 'react';
import { WorkoutsService } from '../../../../api/workouts/service';
import { WorkoutsRepository } from '../../../../api/workouts/repository';
import { Ellipsis } from 'lucide-react';

type IProps = {
  workout: IWorkoutCalendarWorkout;
};

export const CalendarWorkout = ({ workout }: IProps) => {
  const { t } = useTranslation();

  const rootClass = clsx('calendar-workout');

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
  };

  const menuOptions: IMenuOption[] = useMemo(() => {
    const options: IMenuOption[] = [];

    options.push({
      id: 'delete',
      text: t('action.delete'),
    });

    return options;
  }, [t]);

  const handleDeleteWorkout = useCallback(() => {
    //
  }, []);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'delete') {
        handleDeleteWorkout();
      }
    },
    [handleDeleteWorkout]
  );

  const menuJSX = (
    <Menu
      options={menuOptions}
      onChange={onMenuChange}
      dropdownProps={{
        anchor: (
          <IconButton
            size="xs"
            content={<Ellipsis />}
            onClick={preventDefault}
          />
        ),
        placement: 'bottom-end',
      }}
    />
  );

  // ---------------------------------------------------------------------------

  const totalTime = workout.totalTime
    ? hoursToHHMMSS(workout.totalTime)
    : '00:00:00';

  const distance = workout.distance
    ? roundToDecimals(workout.distance / 1000, 0)
    : 0;

  const tss = workout.tssActual ? roundToDecimals(workout.tssActual, 0) : 0;
  const elevationMaximum = workout.elevationMaximum
    ? roundToDecimals(workout.elevationMaximum, 0)
    : 0;
  const energy = workout.energy ? roundToDecimals(workout.energy, 0) : 0;
  const powerAverage = workout.powerAverage
    ? roundToDecimals(workout.powerAverage, 0)
    : 0;
  const intensityFactor = workout.intensityFactor ? workout.intensityFactor : 0;

  const params: ILabelValueProps[] = [
    {
      label: t('label.distance'),
      value: distance,
      units: t('units.km'),
    },
    {
      label: t('label.tss'),
      value: tss,
      units: t('label.tss'),
    },
    {
      label: t('label.elevation'),
      value: elevationMaximum,
      units: t('units.m'),
    },
    {
      label: t('label.energy'),
      value: energy,
      units: t('units.kj'),
    },
    {
      label: t('label.power'),
      value: powerAverage,
      units: `${t('units.w')} ${t('label.avg')}`,
    },
    {
      label: t('label.if'),
      value: intensityFactor,
      units: t('label.if'),
    },
  ];

  return (
    <div className={rootClass}>
      <header className="calendar-workout__header">
        <CyclingIcon width={16} height={16} />
        <Typography
          variant="subtitle-b"
          weight="bold"
          text={
            workout.prescription?.title || workout.title || t('label.cycling')
          }
        />
        {/*<div className="calendar-workout__header-actions">{menuJSX}</div>*/}
      </header>

      <div className="calendar-workout__body">
        {totalTime && (
          <Typography
            as="p"
            variant="subtitle"
            weight="bold"
            text={totalTime}
            className="calendar-workout__body-time"
          />
        )}

        {workout.prescription?.description && (
          <TruncatedText
            className="analog-typography--subtitle-b"
            text={workout.prescription.description}
            lines={3}
            tooltipProps={{
              maxWidth: '200px',
              contentClassName: 'calendar-workout__tooltip-content',
            }}
            typographyProps={{
              as: 'pre',
            }}
          />
        )}

        <CalendarWorkoutStats params={params} />
      </div>
    </div>
  );
};
