import { useCallback, useEffect, useMemo } from 'react';
import { FiltersRepository } from '../api/filters/repository';
import { FiltersService } from '../api/filters/service';
import { useFiltersStore } from '../store/filters/slice';

type IProps = {
  /** Comma-separated IDs to not trigger extra call */
  athleteIds: string | undefined;
};

export const useFetchWorkoutSeasons = ({ athleteIds }: IProps) => {
  const filtersRepository = useMemo(() => {
    return new FiltersRepository(new FiltersService(), { abort: true });
  }, []);

  const fetchSeasons = useCallback(() => {
    if (athleteIds === undefined) {
      return;
    }

    filtersRepository
      .getWorkoutSeasons({
        athleteIds: athleteIds.split(','),
      })
      .then((response) => {
        useFiltersStore.getState().setSeasons(response);
      })
      .catch((error) => {
        //
      });
  }, [athleteIds, filtersRepository]);

  useEffect(() => {
    fetchSeasons();
  }, [fetchSeasons]);

  return {
    fetchSeasons,
  };
};
