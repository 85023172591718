import { IWorkoutSlice, IWorkoutState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  IWorkout,
  IWorkoutAnalysis,
  IWorkoutLapStat,
} from '../../../types/workouts';
import { IAthleteProfile } from '../../../types/athlete-profiles';
import { ICoordinates } from '@cycling-web/common';

export const initialState: IWorkoutState = {
  workout: null,
  workoutLoaded: false,
  workoutAnalysis: null,
  workoutAnalysisLoaded: false,
  trainingZoneProfiles: {},
  trainingZoneProfilesLoaded: false,
  selectedLaps: [],
  positionOnMap: null,
};

export const useWorkoutStore = create<IWorkoutSlice>()(
  immer((set) => ({
    ...initialState,
    setWorkout: (workout: IWorkout | null) => {
      set((state) => {
        state.workout = workout;
        state.workoutLoaded = true;
      });
    },
    setWorkoutLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutLoaded = loaded;
      });
    },
    setWorkoutAnalysis: (workoutAnalysis: IWorkoutAnalysis | null) => {
      set((state) => {
        state.workoutAnalysis = workoutAnalysis;
        state.workoutAnalysisLoaded = true;
      });
    },
    setWorkoutAnalysisLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutAnalysisLoaded = loaded;
      });
    },
    setTrainingZoneProfiles: (p: Record<string, IAthleteProfile>) => {
      set((state) => {
        state.trainingZoneProfiles = p;
        state.trainingZoneProfilesLoaded = true;
      });
    },
    setTrainingZoneProfilesLoaded: (p: boolean) => {
      set((state) => {
        state.trainingZoneProfilesLoaded = p;
      });
    },
    selectLaps: (p: IWorkoutLapStat[]) => {
      set((state) => {
        state.selectedLaps = p;
      });
    },
    setPositionOnMap: (p: ICoordinates | null) => {
      set((state) => {
        state.positionOnMap = p;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
