import React, { useCallback, useMemo } from 'react';
import { DateGroupBy, DateRange, TimeRange } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { DataType } from '../../../../types/common';
import {
  IHandleChangeFilter,
  useBreakpoints,
  useFilters,
} from '@cycling-web/common';
import { DataTypeFilter } from '../../../../components/filters/DataTypeFilter';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  getDateAxisInterval,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { TimeRangeFilter } from '../../../../components/filters/TimeRangeFilter';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';

type IFilters = {
  timeRange: TimeRange;
  dataType: DataType;
};

type IProps = {
  source: IChartSource;
  filters: {
    dateGroupBy?: DateGroupBy;
    period: string;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const TeamPowerProfileChart = ({
  source,
  filters,
  handleChangeFilter,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const { filters: localFilters, handleChangeFilter: handleChangeLocalFilter } =
    useFilters<IFilters>({
      timeRange: TimeRange.TenSeconds,
      dataType: DataType.Absolute,
    });

  const onRenderTooltipValue = useCallback(
    (value: number, _: string) => {
      if (localFilters.dataType === DataType.Relative) {
        return `${value} ${t('units.w')}/${t('units.kg')}`;
      } else if (localFilters.dataType === DataType.Absolute) {
        return `${value} ${t('units.w')}`;
      }
      return `${value} ${t('units.sqr_w')}/${t('units.kg')}`;
    },
    [localFilters.dataType, t]
  );

  const onRenderSeriesName = useCallback((_: string) => {
    return ChartAxisName.MMP;
  }, []);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: isMobile
        ? {
            left: 40,
            right: 0,
          }
        : {},
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 30,
          interval: getDateAxisInterval(filters),
        },
      },
      yAxis: [
        {
          name: `${ChartAxisName.maximumOfMmp}${localFilters.timeRange}${localFilters.dataType}`,
          type: 'value',
          min: 0,
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
          axisLabel: isMobile ? { fontSize: 10 } : undefined,
        },
      ],
      series: [
        {
          name: `${ChartAxisName.maximumOfMmp}${localFilters.timeRange}${localFilters.dataType}`,
          type: 'bar',
          yAxisIndex: 0,
          z: 2,
          encode: {
            x: ChartAxisName.Athlete,
            y: `${ChartAxisName.maximumOfMmp}${localFilters.timeRange}${localFilters.dataType}`,
          },
          itemStyle: {
            color: (params) =>
              params.dataIndex % 2 === 0
                ? ChartColor.LightPurple
                : ChartColor.LightRose,
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
          onRenderSeriesName,
        }),
      },
    };
  }, [
    filters,
    localFilters.dataType,
    localFilters.timeRange,
    onRenderSeriesName,
    onRenderTooltipValue,
    source,
    isMobile,
  ]);

  const filtersBarProps = {
    filters: (
      <>
        <TimeRangeFilter
          value={localFilters.timeRange}
          onChange={handleChangeLocalFilter('timeRange')}
          selectProps={{ variant: 'layer3' }}
        />
        <DataTypeFilter
          value={localFilters.dataType}
          options={[DataType.Absolute, DataType.Relative, DataType.Composite]}
          onChange={handleChangeLocalFilter('dataType')}
          selectProps={{ variant: 'layer3' }}
        />
        <LastNDaysFilter
          value={filters.period}
          onChange={handleChangeFilter('period')}
          selectProps={{ variant: 'layer3' }}
        />
      </>
    ),
  };

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.team_power_profile'),
          filtersBarProps,
        }}
        option={option}
        legendProps={{
          show: false,
        }}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.TeamPowerProfile,
          }),
        }}
      />
    </ChartWrapper>
  );
};
