import { IJoinWaitlistRequest, IJoinWaitlistResponse } from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';

interface IWaitlistService {
  joinWaitlist: (p: IJoinWaitlistRequest) => R<IJoinWaitlistResponse>;
}

export class WaitlistService implements IWaitlistService {
  async joinWaitlist(p: IJoinWaitlistRequest): R<IJoinWaitlistResponse> {
    try {
      const params = qs.stringify(p);
      return axiosInstance.post(`/v1/api/signupInterest/register?${params}`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
