import './index.css';
import { TimeInZonesChart } from '../../../../components/charts/TimeInZonesChart';
import { useMemo } from 'react';
import { IChartSource } from '../../../../components/Chart';
import {
  generateComposeKey,
  getTrainingZoneKeys,
  TrainingZoneKeys,
} from '../../../AthleteProfile/utils/getDefaults';
import { ChartAxisName } from '../../../../constants/charts';
import { useWorkoutStore } from '../../store/slice';
import { Discipline } from '../../../../types/enums';
import { AthleteProfileMethod } from '../../../../types/athlete-profiles';
import { Card, CardHeader } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { PowerInZones } from './PowerInZones';

export const TimeInZones = () => {
  const { t } = useTranslation();
  const workout = useWorkoutStore((s) => s.workout);
  const workoutLoaded = useWorkoutStore((s) => s.workoutLoaded);
  const trainingZoneProfiles = useWorkoutStore((s) => s.trainingZoneProfiles);
  const trainingZoneProfilesLoaded = useWorkoutStore(
    (s) => s.trainingZoneProfilesLoaded
  );
  const loading = !workoutLoaded && !trainingZoneProfilesLoaded;

  const source = useMemo(() => {
    const zones = workout?.AthleteZoneTime ?? [];
    const source: Record<string, IChartSource> = {};

    getTrainingZoneKeys().forEach((key) => {
      source[key] = [];
    });

    for (let i = 0; i < zones.length; i++) {
      const p = zones[i];
      const composeKey = generateComposeKey({
        method: p.Method as AthleteProfileMethod,
        type: p.Type as Discipline,
      });
      source[composeKey].push([ChartAxisName.Date, ChartAxisName.Time]);

      const trainingZones = trainingZoneProfiles?.[composeKey]?.zones ?? [];
      const workoutZones = Object.keys(p)
        .filter((zone) => zone.startsWith('Z'))
        .filter((_, i) => {
          if (trainingZones.length > 0) {
            return i < trainingZones.length;
          }

          return true;
        });

      workoutZones.forEach((key) => {
        const zoneIndex = Number(key.replace('Z', '')) - 1;
        const trainingZoneName = trainingZones[zoneIndex];
        const trainingZoneDisplayName = trainingZoneName?.name ?? key;
        const hours = p[key] / (1000 * 3600);
        source[composeKey].push([trainingZoneDisplayName, hours]);
      });
    }

    return source;
  }, [trainingZoneProfiles, workout?.AthleteZoneTime]);

  const chartsJSX = useMemo(() => {
    if (workout?.Workout.WorkoutType === 'Bike') {
      return (
        <>
          <PowerInZones
            sourceMFTP={source[TrainingZoneKeys.CyclingPowerMFTP]}
            sourceMCP={source[TrainingZoneKeys.CyclingPowerMCP]}
          />
          <Card variant="layer3">
            <CardHeader
              title={t('label.parameter_in_zones', {
                what: t('label.heart_rate'),
              })}
            />
            <TimeInZonesChart
              source={source[TrainingZoneKeys.CyclingHeartRateDefault]}
              color="Purple"
              loading={loading}
            />
          </Card>
        </>
      );
    }

    return null;
  }, [workout, source, t]);

  return <div className="workout-time-in-zones">{chartsJSX}</div>;
};
