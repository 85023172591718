import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ITableColumn } from '@cycling-web/analog-ui';
import { sortByDelegate } from '../../../../utils/sortByDelegate';

export const useTableColumns = () => {
  const { t } = useTranslation();

  return useMemo((): ITableColumn[] => {
    return [
      {
        key: 'Name',
        name: t('label.name'),
        minWidth: 100,
      },
      {
        key: 'EndTimeMs',
        name: t('label.duration'),
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'EndTimeMs');
        },
      },
      {
        key: 'IF',
        name: t('label.if'),
        minWidth: 64,
        maxWidth: 64,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'IF');
        },
      },
      {
        key: 'PowerAverage',
        name: `${t('label.avg')} ${t('label.power').toLowerCase()}`,
        minWidth: 96,
        maxWidth: 96,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'PowerAverage');
        },
      },
      {
        key: 'NP',
        name: 'NP',
        minWidth: 64,
        maxWidth: 64,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'NP');
        },
      },
      {
        key: 'SpeedAverage',
        name: `${t('label.avg')} ${t('label.speed').toLowerCase()}`,
        minWidth: 96,
        maxWidth: 96,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'SpeedAverage');
        },
      },
      {
        key: 'CadenceAverage',
        name: `${t('label.avg')} ${t('label.cadence').toLowerCase()}`,
        minWidth: 110,
        maxWidth: 110,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'CadenceAverage');
        },
      },
      {
        key: 'HeartRateAverage',
        name: `${t('label.avg')} HR`,
        minWidth: 80,
        maxWidth: 80,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'HeartRateAverage');
        },
      },
      {
        key: 'Distance',
        name: t('label.distance'),
        minWidth: 92,
        maxWidth: 92,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'Distance');
        },
      },
      {
        key: 'Climbing',
        name: t('label.climbing'),
        minWidth: 92,
        maxWidth: 92,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'Climbing');
        },
      },
      {
        key: 'WattsPerKg',
        name: `W/kg ${t('label.avg')}`,
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'WattsPerKg');
        },
      },
      {
        key: 'VAM',
        name: 'VAM',
        minWidth: 72,
        maxWidth: 72,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'VAM');
        },
      },
      {
        key: 'EnergyRight',
        name: 'kj',
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'EnergyRight');
        },
      },
      {
        key: 'NM',
        name: 'Nm',
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'NM');
        },
      },
      {
        key: 'NMKG',
        name: 'Nm/kg',
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'NMKG');
        },
      },
      {
        key: 'Grade',
        name: 'Grade',
        minWidth: 100,
        maxWidth: 100,
        allowSort: true,
        onSort: (direction) => {
          return sortByDelegate(direction, 'Grade');
        },
      },
    ];
  }, [t]);
};
