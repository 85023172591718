import './index.css';
import { MobileMenuPopover } from './MobileMenuPopover';
import { INavigationGroup, INavigationItem } from '@cycling-web/common';
import { MenuLink } from '../MenuLink';
import { isGroup } from '../../utils';
import { useEffect, useState } from 'react';
import { useSideNavigationContext } from '../../context';
import { CollapseButton } from '../CollapseButton';

export const MenuMobile = () => {
  const { items } = useSideNavigationContext();
  const [activeGroup, setActiveGroup] = useState<string | undefined>(undefined);

  useEffect(() => {
    let activeGroup: string | undefined = undefined;
    for (let i = 0; i < items.length; i++) {
      if (isGroup(items[i])) {
        const group = items[i] as INavigationGroup;
        if (group.group && group.active) {
          activeGroup = group.group;
          break;
        }
      }
    }
    setActiveGroup(activeGroup);
  }, [items]);

  const itemsJSX = items.map((item: INavigationItem) => {
    if (!isGroup(item)) {
      return <MenuLink key={item.url} item={item} />;
    }

    return (
      <MobileMenuPopover
        key={item.group}
        selected={activeGroup === item.group}
        toggleIcon={item.icon}
        options={item.items}
      />
    );
  }, []);

  return (
    <div className="side-navigation__menu-mobile">
      {itemsJSX}
      <div className="side-navigation__menu-mobile-bottom">
        <CollapseButton />
      </div>
    </div>
  );
};
