import {
  Card,
  CardHeader,
  ISegmentButton,
  Segment,
} from '@cycling-web/analog-ui';
import { TimeInZonesChart } from '../../../../components/charts/TimeInZonesChart';
import { TrainingZoneKeys } from '../../../AthleteProfile/utils/getDefaults';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { TrainingZoneMethod } from '../../../../types/athlete-profiles';
import { IChartSource } from '../../../../components/Chart';

type IProps = {
  sourceMFTP: IChartSource;
  sourceMCP: IChartSource;
};

export const PowerInZones = ({ sourceMFTP, sourceMCP }: IProps) => {
  const { t } = useTranslation();

  const [composeKey, setComposeKey] = useState<string>(
    TrainingZoneKeys.CyclingPowerMFTP
  );

  const buttons = useMemo((): ISegmentButton[] => {
    return [
      {
        id: TrainingZoneKeys.CyclingPowerMFTP,
        text: t('label.ftp'),
      },
      {
        id: TrainingZoneKeys.CyclingPowerMCP,
        text: t('label.cp'),
      },
    ];
  }, [t]);

  const onChangeMethod = useCallback(
    (index: number) => {
      setComposeKey(buttons[index].id as TrainingZoneMethod);
    },
    [buttons]
  );

  return (
    <Card variant="layer3">
      <CardHeader
        title={t('label.parameter_in_zones', {
          what: t('label.power'),
        })}
        endContent={
          <Segment
            buttons={buttons}
            activeButton={buttons.findIndex((b) => {
              return b.id === composeKey;
            })}
            onChange={onChangeMethod}
            size="s"
          />
        }
      />
      {composeKey === TrainingZoneKeys.CyclingPowerMFTP ? (
        <TimeInZonesChart source={sourceMFTP} color="Blue" loading={false} />
      ) : (
        <TimeInZonesChart source={sourceMCP} color="Blue" loading={false} />
      )}
    </Card>
  );
};
