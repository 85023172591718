import './index.css';
import { IAnaThoughtContent } from '../../../types';
import { ContentThought } from './ContentThought';

type IProps = {
  items: IAnaThoughtContent[];
};

export const Thoughts = ({ items }: IProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="conversation-message__thought-content">
      {items.map((content, i) => {
        const isLast = i === items.length - 1;
        return (
          <ContentThought
            key={content.content_id}
            content={content}
            isLast={isLast}
          />
        );
      })}
    </div>
  );
};
