import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, FormElement, Toast } from '@cycling-web/analog-ui';
import {
  DatepickerControl,
  SegmentControl,
  SelectMultiControl,
  SelectSingleControl,
  TextareaControl,
} from '@cycling-web/common';
import { IAthlete } from '../../types/athletes';
import { IMedicalReport, IReportInjuryFormData } from '../../types/medical';
import { MedicalRepository } from '../../api/medical/repository';
import { MedicalService } from '../../api/medical/service';
import { useUsersStore } from '../../store/users/slice';
import { addWeeks } from 'date-fns';
import { BodyMapView } from './BodyMapView';
import { useDictionaryOptions } from '../../hooks/useDictionaryOptions';
import { trackUserInteractionEvent } from '../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../ms/tracking-entities';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
  onSubmitSuccess?: (report: IMedicalReport) => void;
};

export const ReportInjuryFormModal = ({
  athlete,
  onDismiss,
  onSubmitSuccess,
}: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);

  const bodyAreaOptions = useDictionaryOptions('bodyArea');
  const severityOptions = useDictionaryOptions('severity');
  const ossicsCodeOptions = useDictionaryOptions('ossicsCode');
  const onsetModeOptions = useDictionaryOptions('onsetMode');
  const sideOptions = useDictionaryOptions('side');

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        onsetDate: z.date(),
        estimatedRecovery: z.date(),
        side: z.string().min(1, { message: t('validation.required') }),
        severity: z.string().min(1, { message: t('validation.required') }),
        area: z.string().min(1, { message: t('validation.required') }),
        ossicsCode: z.string().min(1, { message: t('validation.required') }),
        onsetMode: z
          .array(z.string())
          .nonempty({ message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IReportInjuryFormData>({
    defaultValues: {
      id: userProfile?.coachId || -1,
      athleteId: athlete.id,
      recordType: 'INJURY',
      onsetDate: new Date(),
      estimatedRecovery: addWeeks(new Date(), 1),
      side: 'LEFT',
      severity: 'MILD',
      area: '',
      ossicsCode: '',
      onsetMode: [],
      note: '',
    },
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IReportInjuryFormData) => {
      setLoading(true);
      medicalRepository
        .reportInjury(formData)
        .then((report: IMedicalReport) => {
          if (onSubmitSuccess) {
            onSubmitSuccess(report);
          }
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.create_injury_report_title'),
              message: t('error.create_injury_report_message'),
            },
            { toastId: 'create_injury_report' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [medicalRepository, onDismiss, onSubmitSuccess, t]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.REPORT_INJURY,
    });

    handleSubmit(
      (formData: IReportInjuryFormData) => {
        handleSave(formData);
      },
      (errors) => console.log(errors)
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.report_injury')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="report-injury-form-modal">
          <div className="report-injury-form-modal__row">
            <div className="report-injury-form-modal__form">
              <FormElement
                label={t('label.area')}
                message={errors.area?.message}
              >
                <SelectSingleControl
                  name="area"
                  options={bodyAreaOptions}
                  placeholder={t('placeholder.area')}
                  invalid={!!errors.area}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.side')}
                message={errors.side?.message}
              >
                <SegmentControl name="side" buttons={sideOptions} fullWidth />
              </FormElement>

              <FormElement
                label={t('label.severity')}
                message={errors.severity?.message}
              >
                <SegmentControl
                  name="severity"
                  buttons={severityOptions}
                  fullWidth
                />
              </FormElement>

              <FormElement
                label={t('label.osiics_code')}
                message={errors.ossicsCode?.message}
              >
                <SelectSingleControl
                  name="ossicsCode"
                  options={ossicsCodeOptions}
                  placeholder={t('placeholder.osiics_code')}
                  invalid={!!errors.ossicsCode}
                  search={true}
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.onset_mode')}
                message={errors.onsetMode?.message}
              >
                <SelectMultiControl
                  name="onsetMode"
                  options={onsetModeOptions}
                  placeholder={t('placeholder.onset_mode')}
                  invalid={!!errors.onsetMode}
                  tags
                  dropdownProps={{
                    maxHeight: '240px',
                  }}
                />
              </FormElement>

              <FormElement
                label={t('label.onset_date')}
                message={errors.onsetDate?.message}
              >
                <DatepickerControl
                  name="onsetDate"
                  placeholder={t('placeholder.onset_date')}
                  invalid={!!errors.onsetDate}
                />
              </FormElement>

              <FormElement
                label={t('label.estimate_recovery_date')}
                message={errors.estimatedRecovery?.message}
              >
                <DatepickerControl
                  name="estimatedRecovery"
                  placeholder={t('placeholder.estimate_recovery_date')}
                  invalid={!!errors.estimatedRecovery}
                />
              </FormElement>

              <FormElement
                label={t('label.note')}
                message={errors.note?.message}
              >
                <TextareaControl
                  name="note"
                  placeholder={t('placeholder.note')}
                  maxLength={999}
                  invalid={!!errors.note}
                />
              </FormElement>
            </div>

            <div className="report-injury-form-modal__body-map">
              <BodyMapView />
            </div>
          </div>
        </div>
      </Dialog>
    </FormProvider>
  );
};
