import { useTrainingLoadStore } from './store/slice';
import { useEffect, useMemo } from 'react';
import { formatDate, parseDate } from '../../utils/utils';
import { ITrainingLoad, IWorkoutDetail } from '../../types/performance';
import { useFetchAthleteWorkoutDetails } from './hooks/useFetchAthleteWorkoutDetails';
import { useFetchAthleteTrainingLoad } from './hooks/useFetchAthleteTrainingLoad';
import { IChartSource } from '../../components/Chart';
import { ChartAxisName } from '../../constants/charts';
import { useFetchWorkoutSeasons } from '../../hooks/useFetchWorkoutSeasons';
import { useParams } from 'react-router';
import { usePageFilters } from '../../context/PageFilters';
import { IAthleteDetailsFilters } from '../AthleteDetails/types';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const useViewModel = () => {
  const { athleteId } = useParams();
  useFetchAthleteWorkoutDetails();
  useFetchAthleteTrainingLoad();
  useFetchWorkoutSeasons({ athleteIds: athleteId });

  const { filters, handleChangeFilter, initPageFilters } =
    usePageFilters<IAthleteDetailsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().athleteDetails);
  }, [initPageFilters]);

  const workoutDetails: IWorkoutDetail[] = useTrainingLoadStore(
    (s) => s.workoutDetails
  );
  const workoutDetailsLoaded: boolean = useTrainingLoadStore(
    (s) => s.workoutDetailsLoaded
  );
  const trainingLoad: ITrainingLoad | null = useTrainingLoadStore(
    (s) => s.trainingLoad
  );
  const trainingLoadLoaded: boolean = useTrainingLoadStore(
    (s) => s.trainingLoadLoaded
  );

  const workoutDetailsSources = useMemo(() => {
    const tssAndIf: IChartSource = [
      [ChartAxisName.Date, ChartAxisName.TSS, ChartAxisName.IF],
    ];
    const durationAndDistance: IChartSource = [
      [ChartAxisName.Date, ChartAxisName.Duration, ChartAxisName.Distance],
    ];
    const durationAndElevation: IChartSource = [
      [ChartAxisName.Date, ChartAxisName.Duration, ChartAxisName.Elevation],
    ];
    const workAndIf: IChartSource = [
      [ChartAxisName.Date, ChartAxisName.Work, ChartAxisName.IF],
    ];

    workoutDetails.forEach((item: IWorkoutDetail) => {
      const date = formatDate(parseDate(item.startDate));
      tssAndIf.push([date]);
      durationAndDistance.push([date]);
      durationAndElevation.push([date]);
      workAndIf.push([date]);

      tssAndIf[tssAndIf.length - 1].push(
        item.tss || 0,
        item.intensityFactor || 0
      );

      durationAndDistance[durationAndDistance.length - 1].push(
        item.durationInHour || 0,
        item.distanceInKm || 0
      );

      durationAndElevation[durationAndElevation.length - 1].push(
        item.durationInHour || 0,
        item.climbInMeter || 0
      );

      workAndIf[workAndIf.length - 1].push(
        item.workInKj || 0,
        item.intensityFactor || 0
      );
    });

    return {
      tssAndIf,
      durationAndDistance,
      durationAndElevation,
      workAndIf,
    };
  }, [workoutDetails]);

  return {
    filters,
    handleChangeFilter,
    workoutDetailsSources,
    workoutDetailsLoading: !workoutDetailsLoaded,
    trainingLoad,
    trainingLoadLoading: !trainingLoadLoaded,
  };
};
