import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IConfirmDialogProps, Toast } from '@cycling-web/analog-ui';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  IZonesFormData,
  IZonesModalContext,
  IZonesModalProps,
} from '../../types';
import { Discipline } from '../../../../types/enums';
import { useThreshold } from '../../hooks/useThreshold';
import {
  IAthleteProfile,
  IAthleteTrainingZone,
  IAthleteTrainingZoneForm,
} from '../../../../types/athlete-profiles';
import { AthleteProfileRepository } from '../../../../api/athletes-profiles/repository';
import { AthleteProfileService } from '../../../../api/athletes-profiles/service';
import { useParams } from 'react-router';
import {
  MAX_ZONE_VALUE,
  MAX_ZONE_VALUE_PLACEHOLDER,
  TrainingZoneKeys,
} from '../../utils/getDefaults';
import { useFetchTrainingZones } from '../../../../hooks/useFetchTrainingZones';
import { useFetchTrainingZonesPerformance } from '../../hooks/useFetchTrainingZonesPerformance';
import { parseAthleteProfiles } from '../../utils/profiles';
import { useAthleteProfileStore } from '../../store/slice';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
  TrackingForm,
} from '../../../../ms/tracking-entities';

type IHandleSaveProps = {
  formData: IZonesFormData;
  reset?: boolean;
};

/** This code is problematic. It would be much easier if backend returned the default profiles
 * for analog method without modifying entities in the DB. */

export const useViewModel = (props: IZonesModalProps) => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const { onDismiss } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const [discardDialogProps, setDiscardDialogProps] = useState<
    Partial<IConfirmDialogProps> | undefined
  >(undefined);

  const openDiscardDialog = useCallback(
    (props: Partial<IConfirmDialogProps>) => {
      setDiscardDialogProps(props);
    },
    []
  );

  const dismissDiscardDialog = useCallback(() => {
    setDiscardDialogProps(undefined);
  }, []);

  const defaultFormAthleteProfilesRef = useRef<Record<string, IAthleteProfile>>(
    {}
  );
  const [formAthleteProfiles, setFormAthleteProfiles] = useState<
    Record<string, IAthleteProfile>
  >({});

  const athleteProfileRepository = useMemo(() => {
    return new AthleteProfileRepository(new AthleteProfileService());
  }, []);

  const fetchFormTrainingZones = useCallback(
    (props?: { reset: boolean }) => {
      if (!athleteId) {
        return Promise.resolve();
      }

      return athleteProfileRepository
        .getAthleteProfiles({
          athleteId: +athleteId,
        })
        .then((response) => {
          const parsedProfiles = parseAthleteProfiles(response);
          if (!props?.reset) {
            defaultFormAthleteProfilesRef.current = parsedProfiles;
          }
          setFormAthleteProfiles(parsedProfiles);
        });
    },
    [athleteId, athleteProfileRepository]
  );

  useEffect(() => {
    fetchFormTrainingZones();
  }, [fetchFormTrainingZones]);

  const { fetchTrainingZones } = useFetchTrainingZones();
  const { fetchTrainingZonesPerformance } = useFetchTrainingZonesPerformance();

  const [composeKey, setComposeKey] = useState<string>(() => {
    if (props.composeKey) {
      return props.composeKey;
    }

    if (props.defaultDiscipline === Discipline.Cycling) {
      return TrainingZoneKeys.CyclingPowerMFTP;
    } else if (props.defaultDiscipline === Discipline.Swimming) {
      return TrainingZoneKeys.SwimmingCSSDefault;
    } else {
      return TrainingZoneKeys.RunningPaceDefault;
    }
  });

  const currentProfile = useMemo(() => {
    if (!formAthleteProfiles[composeKey]) {
      return undefined;
    }

    return formAthleteProfiles[composeKey];
  }, [composeKey, formAthleteProfiles]);

  const { threshold, setThreshold } = useThreshold({
    composeKey: currentProfile?.composeKey,
  });

  // ---------------------------------------------------------------------------
  /** Form */

  const schema = useMemo(() => {
    return z
      .object({
        zones: z.array(
          z.object({
            name: z.string().nonempty('Name is required'),
            from: z.string().nonempty('From is required'),
            to: z.string().nonempty('To is required'),
            color: z.string().nonempty('Color is required'),
          })
        ),
      })
      .superRefine((data, ctx) => {
        const { zones } = data;

        for (let i = 1; i < zones.length; i++) {
          const currentFrom = parseInt(zones[i].from, 10);
          const previousTo = parseInt(zones[i - 1].to, 10);

          if (currentFrom - previousTo !== 1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('validation.zone_gaps'),
              path: ['zones', i, 'to'],
            });
          }
        }
      });
  }, [t]);

  const prepareZones = useCallback(
    (zones: IAthleteTrainingZone[]): IAthleteTrainingZoneForm[] => {
      try {
        return zones.map((zone) => {
          return {
            ...zone,
            from:
              zone.from.toString() === MAX_ZONE_VALUE.toString()
                ? MAX_ZONE_VALUE_PLACEHOLDER
                : zone.from.toString(),
            to:
              zone.to.toString() === MAX_ZONE_VALUE.toString()
                ? MAX_ZONE_VALUE_PLACEHOLDER
                : zone.to.toString(),
          };
        });
      } catch (e) {
        return [];
      }
    },
    []
  );

  const getFormDefaultValues = useCallback(
    (currentProfile: IAthleteProfile | undefined): IZonesFormData => {
      if (!currentProfile) {
        return {
          zones: [],
        };
      }

      return {
        zones: prepareZones(currentProfile.zones),
      };
    },
    [prepareZones]
  );

  const form = useForm<IZonesFormData>({
    defaultValues: getFormDefaultValues(undefined),
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const preparePayload = useCallback(
    ({ formData, reset }: IHandleSaveProps): IAthleteProfile[] => {
      const nextProfiles: IAthleteProfile[] = [];
      Object.keys(formAthleteProfiles).forEach((key) => {
        const profile = { ...formAthleteProfiles[key] };

        if (currentProfile?.composeKey === key) {
          if (form.formState.isDirty) {
            profile.custom = true;
          }

          if (reset) {
            profile.custom = false;
          }

          profile.zones = formData.zones.map((zone) => {
            return {
              ...zone,
              from:
                zone.from.toString().toLowerCase() ===
                MAX_ZONE_VALUE_PLACEHOLDER.toLowerCase()
                  ? MAX_ZONE_VALUE
                  : Number(zone.from),
              to:
                zone.to.toString().toLowerCase() ===
                MAX_ZONE_VALUE_PLACEHOLDER.toLowerCase()
                  ? MAX_ZONE_VALUE
                  : Number(zone.to),
            };
          });
        }
        delete profile.composeKey;

        if (!(reset && currentProfile?.composeKey === key)) {
          nextProfiles.push(profile);
        }
      });

      return nextProfiles;
    },
    [currentProfile?.composeKey, form.formState.isDirty, formAthleteProfiles]
  );

  useEffect(() => {
    reset(getFormDefaultValues(currentProfile));
  }, [currentProfile, getFormDefaultValues, reset]);

  const handleSave = useCallback(
    ({ formData }: IHandleSaveProps) => {
      if (!athleteId) {
        return Promise.resolve();
      }

      const nextProfiles: IAthleteProfile[] = preparePayload({
        formData,
        reset: false,
      });

      return athleteProfileRepository
        .saveAthleteProfiles({
          athleteId: +athleteId,
          body: nextProfiles,
        })
        .then(() => {
          const promises: Promise<void>[] = [
            fetchTrainingZones(athleteId).then((data: IAthleteProfile[]) => {
              useAthleteProfileStore.getState().setAthleteProfiles(data);
            }),
            fetchTrainingZonesPerformance(),
          ];
          return Promise.allSettled(promises);
        })
        .then(() => {
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.save_zones_title'),
            },
            { toastId: 'save_zones_title' }
          );
        });
    },
    [
      athleteId,
      preparePayload,
      athleteProfileRepository,
      fetchTrainingZones,
      fetchTrainingZonesPerformance,
      onDismiss,
      t,
    ]
  );

  const handleReset = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.RESET_THRESHOLD,
    });

    if (!athleteId) {
      return Promise.resolve();
    }

    const nextProfiles: IAthleteProfile[] = preparePayload({
      formData: {
        zones: [],
      },
      reset: true,
    });

    return athleteProfileRepository
      .saveAthleteProfiles({
        athleteId: +athleteId,
        body: nextProfiles,
      })
      .then(() => {
        return fetchFormTrainingZones({ reset: true });
      });
  }, [
    athleteId,
    preparePayload,
    athleteProfileRepository,
    fetchFormTrainingZones,
  ]);

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.TRAINING_ZONES,
    });

    handleSubmit((formData: IZonesFormData) => {
      setLoading(true);
      handleSave({ formData, reset: false }).finally(() => {
        setLoading(false);
      });
    })();
  }, [handleSave, handleSubmit]);

  const resetToDefault = useCallback(() => {
    if (!athleteId) {
      return Promise.resolve();
    }

    return athleteProfileRepository
      .saveAthleteProfiles({
        athleteId: +athleteId,
        body: Object.values(defaultFormAthleteProfilesRef.current).map((p) => {
          const copy = { ...p };
          delete copy.composeKey;
          return copy;
        }),
      })
      .then(() => {
        return fetchFormTrainingZones({ reset: true });
      });
  }, [athleteId, athleteProfileRepository, fetchFormTrainingZones]);

  const handleDismiss = useCallback(() => {
    return resetToDefault().finally(() => {
      onDismiss();
    });
  }, [onDismiss, resetToDefault]);

  const context = useMemo((): IZonesModalContext => {
    return {
      ...props,
      threshold,
      setThreshold,
      composeKey,
      setComposeKey,
      onReset: handleReset,
      currentProfile,
      defaultFormAthleteProfilesRef,
      formAthleteProfiles,
      setFormAthleteProfiles,
      discardDialogProps,
      openDiscardDialog,
      dismissDiscardDialog,
      resetToDefault,
    };
  }, [
    props,
    threshold,
    setThreshold,
    composeKey,
    handleReset,
    currentProfile,
    formAthleteProfiles,
    discardDialogProps,
    openDiscardDialog,
    dismissDiscardDialog,
    resetToDefault,
  ]);

  return {
    t,
    form,
    onDismiss: handleDismiss,
    onSubmit,
    loading,
    errors,
    context,
  };
};
