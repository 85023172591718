import { LS } from '../constants';
import { IPageFilters } from '../context/PageFilters/types';

export const Url = {
  parseCommaSeparatedIds(value: string | undefined): number[] {
    if (typeof value !== 'string' || value.trim().length === 0) {
      return [];
    }

    return value
      .split(',')
      .map((str) => str.trim())
      .filter((str) => /^\d+$/.test(str))
      .map(Number);
  },

  stringifyFilters(filters: IPageFilters): string {
    const searchParams = new URLSearchParams(filters as Record<string, string>);
    return `?${searchParams.toString()}`;
  },

  saveUrlToLS(): void {
    const url =
      window.location.pathname + window.location.search + window.location.hash;
    localStorage.setItem(LS.PreviousPage, url);
  },
};
