import { IPerformanceReportFilters } from '../types';
import { IPerformanceReport } from '../../../types/performance';
import { IAthlete } from '../../../types/athletes';
import { Url } from '../../../utils/url';

export const filterReportsDelegate = (
  filters: IPerformanceReportFilters,
  athletesMap: Record<number, IAthlete>
) => {
  return (report: IPerformanceReport) => {
    let valid = true;
    const athlete: IAthlete | undefined = athletesMap[report.athleteId];
    const athletes = Url.parseCommaSeparatedIds(filters.athletes);

    if (!athlete) {
      return false;
    }

    if (
      filters.search &&
      !athlete?.fullName.toLowerCase().includes(filters.search.toLowerCase())
    ) {
      valid = false;
    }

    if (athletes.length > 0 && !athletes.includes(report.athleteId)) {
      valid = false;
    }

    return valid;
  };
};
