import './index.css';
import { Card, CardHeader, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import { useEventStore } from '../../store/slice';
import {
  hoursToHHMMSS,
  UTCMidnightToSameDate,
} from '../../../../utils/date-time';
import { format } from 'date-fns';
import { LabelValueElement } from '../../../../components/LabelValueElement';
import { FormColumn, FormRow } from '@cycling-web/common';
import { WorkoutFile } from '../WorkoutFile';

export const EventOverview = () => {
  const { t } = useTranslation();
  const event = useEventStore((s) => s.event);

  const eventDate = event?.eventDateTimeUtc
    ? format(UTCMidnightToSameDate(event.eventDateTimeUtc), 'dd/MM/yyyy')
    : '';
  const distance = event?.distanceKms
    ? `${event.distanceKms} ${t('units.km')}`
    : '';
  const elevationGain = event?.elevationGainMts
    ? `${event.elevationGainMts} ${t('units.m')}`
    : '';
  const duration = event?.durationInMinutes
    ? hoursToHHMMSS(event.durationInMinutes / 60)
    : '';

  const hasFile = event?.gpxFileUrl || event?.gpxJsonUrl;

  return (
    <Card variant="layer3" className="event-details__overview">
      <CardHeader icon={<CyclingIcon />} title={t('label.event')} />

      <Typography
        color="text-placeholder"
        variant="headline"
        text={eventDate}
      />

      <FormRow>
        <FormColumn>
          <LabelValueElement name={t('label.title')} value={event?.title} />
          <LabelValueElement name={t('label.distance')} value={distance} />
          <LabelValueElement
            name={t('label.expected_what', {
              what: t('label.duration').toLowerCase(),
            })}
            value={duration}
          />
          <LabelValueElement
            name={t('label.elevation_gain')}
            value={elevationGain}
          />
        </FormColumn>
        <FormColumn className="event-details__overview-description">
          <LabelValueElement
            name={t('label.description')}
            value={event?.description}
          />
        </FormColumn>
      </FormRow>
      {hasFile && (
        <FormRow className="event-details__overview-file-row">
          <FormColumn>
            <WorkoutFile />
          </FormColumn>
          <FormColumn />
        </FormRow>
      )}
    </Card>
  );
};
