import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { Toast } from '@cycling-web/analog-ui';
import { AxiosError } from 'axios';
import { IPinnedTab, SmartChartsAthletes } from '../../../types/smart-charts';
import { SmartChartsRepository } from '../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../api/smart-charts/service';
import { ApiErrorCode } from '../../../constants';
import { usePinnedTabStore } from '../../../store/pinned-tabs/slice';
import { useParams } from 'react-router';

export const useFetchPinnedTabs = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const { groupId } = useParams();
  const setPinnedTabs = usePinnedTabStore((s) => s.setPinnedTabs);
  const setPinnedTabsLoaded = usePinnedTabStore((s) => s.setPinnedTabsLoaded);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  useEffect(() => {
    if (fetching.current || !groupId) {
      return;
    }

    setPinnedTabsLoaded(false);
    fetching.current = true;
    smartChartsRepository
      .getPinnedTabs({ type: SmartChartsAthletes.Multiple, groupId })
      .then((tabs: IPinnedTab[]) => {
        setPinnedTabs(tabs);
      })
      .catch((error: AxiosError) => {
        setPinnedTabsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_pinned_tabs_title'),
              message: t('error.get_pinned_tabs_message'),
            },
            { toastId: 'get_pinned_tabs' }
          );
        }
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [t, smartChartsRepository, setPinnedTabs, setPinnedTabsLoaded, groupId]);
};
