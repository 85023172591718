import { AthletesService } from './service';
import { IAthlete, IHeightWeightFormData } from '../../types/athletes';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IGetAthleteByIdRequest, IUpdateAthleteRequest } from './types';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';

export interface IAthletesRepository {
  getAthletes: (c?: AxiosRequestConfig) => Promise<IAthlete[]>;

  getAthleteById: (
    p: IGetAthleteByIdRequest,
    c?: AxiosRequestConfig
  ) => Promise<IAthlete>;

  setWeight: (
    p: IHeightWeightFormData,
    c?: AxiosRequestConfig
  ) => Promise<AxiosResponse<void>>;

  updateProfile: (
    p: IUpdateAthleteRequest,
    c?: AxiosRequestConfig
  ) => Promise<IAthlete>;
}

export class AthletesRepository
  extends BaseRepository
  implements IAthletesRepository
{
  constructor(
    private readonly service: AthletesService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getAthletes(c?: AxiosRequestConfig): Promise<IAthlete[]> {
    const response = await this.handleRequest(this.service.getAthletes, c);
    return response.data.data;
  }

  async getAthleteById(
    p: IGetAthleteByIdRequest,
    c?: AxiosRequestConfig
  ): Promise<IAthlete> {
    const response = await this.handleRequest(
      this.service.getAthleteById,
      p,
      c
    );
    return response.data.data;
  }

  async setWeight(
    p: IHeightWeightFormData,
    c?: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> {
    return this.handleRequest(
      this.service.setWeight,
      {
        ...p,
        weight: +p.weight,
        height: +p.height,
      },
      c
    );
  }

  async updateProfile(
    p: IUpdateAthleteRequest,
    c?: AxiosRequestConfig
  ): Promise<IAthlete> {
    const response = await this.handleRequest(this.service.updateAthlete, p, c);
    return response.data;
  }
}
