import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICalendarFilters, IWorkoutsCalendarContext } from './types';
import { useAthletesStore } from '../../store/athletes/slice';
import { useNavigate, useParams } from 'react-router';
import { useFetchWorkoutCalendar } from './hooks/useFetchWorkoutCalendar';
import { format, isSameMonth, isSameYear } from 'date-fns';
import { useWorkoutCalendarStore } from './store/slice';
import { useFetchDayOffs } from './hooks/useFetchDayOffs';
import { useBoolean } from '@cycling-web/analog-ui';
import { useFetchEvents } from './hooks/useFetchEvents';
import { ICalendarAddModalProps } from './components/CalendarAddModal/types';
import { usePageFilters } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { trackUserInteractionEvent } from '../../ms/log-insights';
import { TrackingEvent, TrackingModal } from '../../ms/tracking-entities';
import { useFetchWeeklyPerformanceReport } from './hooks/useFetchWeeklyPerformanceReport';
import { getSurroundingWeeks } from '../../utils/date-time';
import { IGetAthletePerformanceReportRequest } from '../../api/performance/types';

export const useViewModel = () => {
  const { athleteId } = useParams();
  const navigate = useNavigate();
  const { fetchWorkoutCalendar } = useFetchWorkoutCalendar();
  const { fetchDayOffs } = useFetchDayOffs();
  const { fetchEvents } = useFetchEvents();
  const { fetchWeeklyPerformanceReport } = useFetchWeeklyPerformanceReport();

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const { initPageFilters, filters, setFilter } =
    usePageFilters<ICalendarFilters>();

  const date = useMemo(() => {
    if (!filters?.date) {
      return new Date();
    }

    return new Date(filters.date);
  }, [filters]);
  const setDate = useCallback(
    (date: Date) => {
      setFilter('date', format(date, 'yyyy-MM-dd'));
    },
    [setFilter]
  );

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().calendar);
  }, [initPageFilters]);

  const {
    value: showTrainingPlanModal,
    setTrue: openTrainingPlanModal,
    setFalse: dismissTrainingPlanModal,
  } = useBoolean(false);

  const handleOpenTrainingPlanModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
      modal: TrackingModal.TRAINING_PLAN,
    });
    openTrainingPlanModal();
  }, [openTrainingPlanModal]);

  const handleDismissTrainingPlanModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.TRAINING_PLAN,
    });

    dismissTrainingPlanModal();
  }, [dismissTrainingPlanModal]);

  const {
    value: showCalendarAddModal,
    setTrue: openCalendarAddModal,
    setFalse: dismissCalendarAddModal,
  } = useBoolean(false);

  const handleOpenCalendarAddModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
      modal: TrackingModal.CALENDAR_ADD_DATA,
    });
    openCalendarAddModal();
  }, [openCalendarAddModal]);

  const handleDismissCalendarAddModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.CALENDAR_ADD_DATA,
    });

    dismissCalendarAddModal();
    setAddModalProps(null);
  }, [dismissCalendarAddModal]);

  const [addModalProps, setAddModalProps] =
    useState<Partial<ICalendarAddModalProps> | null>(null);

  const athlete = useMemo(() => {
    return athletes.find(
      (athlete) => athlete.id.toString() === athleteId?.toString()
    );
  }, [athleteId, athletes]);

  const prevDateRef = useRef<Date | null>(null);
  const prevAthleteIdRef = useRef<string>('');

  useEffect(() => {
    if (!athleteId) {
      return;
    }

    const shouldCall =
      prevAthleteIdRef.current === '' ||
      prevDateRef.current === null ||
      prevAthleteIdRef.current !== athleteId ||
      !(
        isSameMonth(prevDateRef.current, date) &&
        isSameYear(prevDateRef.current, date)
      );

    if (shouldCall) {
      fetchWorkoutCalendar(date, athleteId);
      fetchDayOffs(date, athleteId);
      fetchEvents(date, athleteId);

      getSurroundingWeeks(date)
        .map((item) => {
          return {
            ...item,
            athleteId,
          };
        })
        .forEach((payload: IGetAthletePerformanceReportRequest) => {
          fetchWeeklyPerformanceReport(payload).then((report) => {
            const week = `${payload.week}_${payload.year}`;
            useWorkoutCalendarStore.getState().setWeekSummary(week, report);
          });
        });
    }

    prevDateRef.current = date;
    prevAthleteIdRef.current = athleteId;
  }, [
    athleteId,
    fetchWorkoutCalendar,
    fetchDayOffs,
    fetchEvents,
    fetchWeeklyPerformanceReport,
    date,
  ]);

  useEffect(() => {
    if (!athleteId && athletes.length > 0) {
      navigate(`${athletes[0].id}`);
    }
  }, [athleteId, athletes, navigate]);

  const context: IWorkoutsCalendarContext =
    useMemo((): IWorkoutsCalendarContext => {
      return {
        date,
        setDate,
        athlete,
        athleteLoaded: athletesLoaded,
        showCalendarAddModal,
        openCalendarAddModal: handleOpenCalendarAddModal,
        dismissCalendarAddModal: handleDismissCalendarAddModal,
        showTrainingPlanModal,
        openTrainingPlanModal: handleOpenTrainingPlanModal,
        dismissTrainingPlanModal: handleDismissTrainingPlanModal,
        addModalProps,
        setAddModalProps,
      };
    }, [
      date,
      setDate,
      athlete,
      athletesLoaded,
      showCalendarAddModal,
      handleOpenCalendarAddModal,
      handleDismissCalendarAddModal,
      showTrainingPlanModal,
      handleOpenTrainingPlanModal,
      handleDismissTrainingPlanModal,
      addModalProps,
    ]);

  useEffect(() => {
    return () => {
      useWorkoutCalendarStore.getState().reset();
    };
  }, []);

  return {
    context,
  };
};
