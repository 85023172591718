import './index.css';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { Dialog, FormElement, Toast } from '@cycling-web/analog-ui';
import { IAthlete, IHeightWeightFormData } from '../../types/athletes';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AthletesRepository } from '../../api/athletes/repository';
import { AthletesService } from '../../api/athletes/service';
import { format } from 'date-fns';
import { InputNumberControl } from '@cycling-web/common';
import { useAthletesStore } from '../../store/athletes/slice';
import { useAthleteStore } from '../../store/athlete/slice';
import { useAthleteWellnessStore } from '../../pages/AthleteWellness/store/slice';
import { trackUserInteractionEvent } from '../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../ms/tracking-entities';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
};

export const HeightWeightFormModal = ({ athlete, onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        height: z.string().min(1, { message: t('validation.required') }),
        weight: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IHeightWeightFormData>({
    defaultValues: {
      athleteId: athlete.id,
      height: athlete.height.toString(),
      weight: athlete.weight.toString(),
      date: format(new Date(), 'yyyy-MM-dd'),
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IHeightWeightFormData) => {
      setLoading(true);
      const updatedFields = {
        weight: +formData.weight,
        height: +formData.height,
      };
      athletesRepository
        .updateProfile({
          ...athlete,
          ...updatedFields,
        })
        .then((updatedAthlete: IAthlete) => {
          useAthletesStore.getState().updateAthlete({
            id: updatedAthlete.id,
            height: updatedAthlete.height,
            weight: updatedAthlete.weight,
          });
          useAthleteStore.getState().updateAthlete({
            id: updatedAthlete.id,
            height: updatedAthlete.height,
            weight: updatedAthlete.weight,
          });
          useAthleteWellnessStore.getState().updateWellness({
            height: {
              data: updatedFields.height,
            },
            bodyMass: {
              data: updatedFields.weight,
            },
          });
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.save_changes_title'),
              message: t('error.save_changes_message'),
            },
            { toastId: 'save_weight' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [athletesRepository, athlete, onDismiss, t]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.HEIGHT_AND_WEIGHT,
    });

    handleSubmit(
      (formData: IHeightWeightFormData) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.height_weight')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="weight-form-modal">
          <FormElement
            label={t('label.height')}
            message={errors.height?.message}
          >
            <InputNumberControl
              name="height"
              placeholder={t('placeholder.height')}
              invalid={!!errors.height}
              decimalPlaces={1}
            />
          </FormElement>
          <FormElement
            label={t('label.weight')}
            message={errors.weight?.message}
          >
            <InputNumberControl
              name="weight"
              placeholder={t('placeholder.weight')}
              invalid={!!errors.weight}
              decimalPlaces={1}
            />
          </FormElement>
        </div>
      </Dialog>
    </FormProvider>
  );
};
