import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FiltersRepository } from '../api/filters/repository';
import { FiltersService } from '../api/filters/service';
import { useFiltersStore } from '../store/filters/slice';

type IProps = {
  /** Comma-separated IDs to not trigger extra call */
  athleteIds: string | undefined;
};

export const useFetchMetricSeasons = ({ athleteIds }: IProps) => {
  const fetchingRef = useRef<boolean>(false);

  const filtersRepository = useMemo(() => {
    return new FiltersRepository(new FiltersService());
  }, []);

  const fetchSeasons = useCallback(() => {
    if (fetchingRef.current || !athleteIds) {
      return;
    }

    fetchingRef.current = true;

    filtersRepository
      .getMetricSeasons({
        athleteIds: athleteIds.split(','),
      })
      .then((response) => {
        useFiltersStore.getState().setSeasons(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [athleteIds, filtersRepository]);

  useEffect(() => {
    fetchSeasons();
  }, [fetchSeasons]);

  return {
    fetchSeasons,
  };
};
