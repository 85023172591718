import {
  $applyNodeReplacement,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  type Spread,
  TextNode,
} from 'lexical';

export type SerializedMentionNode = Spread<
  {
    mentionName: string;
    optionId: string;
    optionEntity?: string;
  },
  SerializedTextNode
>;

function $convertMentionElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  const mentionName = domNode.getAttribute('data-option-text');
  const optionId = domNode.getAttribute('data-option-id') ?? '';
  const optionEntity = domNode.getAttribute('data-option-entity') ?? '';

  if (textContent !== null) {
    const node = $createMentionNode(
      typeof mentionName === 'string' ? mentionName : textContent,
      optionId,
      optionEntity
    );
    return { node };
  }

  return null;
}

export class MentionNode extends TextNode {
  __mention: string;
  __optionId: string;
  __optionEntity?: string;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(
      node.__mention,
      node.__optionId,
      node.__optionEntity,
      node.__text,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(
      serializedNode.mentionName,
      serializedNode.optionId,
      serializedNode.optionEntity
    ).updateFromJSON(serializedNode);
  }

  constructor(
    mentionName: string,
    optionId: string,
    optionEntity?: string,
    text?: string,
    key?: NodeKey
  ) {
    super(text ?? mentionName, key);
    this.__mention = mentionName;
    this.__optionId = optionId;
    this.__optionEntity = optionEntity;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      optionId: this.__optionId,
      optionEntity: this.__optionEntity,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.className = 'conversation__mention';
    dom.spellcheck = false;
    dom.setAttribute('data-option-id', this.__optionId);
    if (this.__optionEntity) {
      dom.setAttribute('data-option-entity', this.__optionEntity);
    }
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.setAttribute('data-option-id', this.__optionId);

    if (this.__optionEntity) {
      element.setAttribute('data-option-entity', this.__optionEntity);
    }

    if (this.__text !== this.__mention) {
      element.setAttribute('data-option-text', this.__mention);
    }
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: $convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMentionNode(
  mentionName: string,
  optionId: string,
  optionEntity?: string
): MentionNode {
  const mentionNode = new MentionNode(mentionName, optionId, optionEntity);
  mentionNode.setMode('segmented').toggleDirectionless();
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode {
  return node instanceof MentionNode;
}
