import { Dialog, FormElement } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { DatepickerControl, InputControl } from '@cycling-web/common';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ICustomPeriodFilter } from '../../../types/filters';
import { FiltersRepository } from '../../../api/filters/repository';
import { FiltersService } from '../../../api/filters/service';
import { useFiltersStore } from '../../../store/filters/slice';
import { localWithUTCMidnightString } from '../../../utils/date-time';
import { trackUserInteractionEvent } from '../../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../../ms/tracking-entities';

type IProps = {
  filter?: ICustomPeriodFilter;
  onDismiss: () => void;
  onSubmitSuccess: (period: ICustomPeriodFilter) => void;
};

type IForm = {
  id?: number;
  filterName: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export const CustomDatesModal = ({
  filter,
  onDismiss,
  onSubmitSuccess,
}: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const filtersRepository = useMemo(() => {
    return new FiltersRepository(new FiltersService());
  }, []);

  const defaultValues = useMemo((): IForm => {
    return {
      id: filter?.id || undefined,
      filterName: filter?.filterName || '',
      startDate: filter?.startDate ? new Date(filter?.startDate) : undefined,
      endDate: filter?.endDate ? new Date(filter?.endDate) : undefined,
    };
  }, [filter]);

  const schema = useMemo(() => {
    return z
      .object({
        filterName: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const form = useForm<IForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { formState, handleSubmit, watch } = form;
  const startDate = watch('startDate');

  const createPeriod = useCallback(
    (period: ICustomPeriodFilter) => {
      setLoading(true);
      return filtersRepository
        .createCustomPeriodFilter(period)
        .then((response) => {
          useFiltersStore.getState().appendCustomPeriod(response);
          onSubmitSuccess(response);
          onDismiss();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filtersRepository, onDismiss, onSubmitSuccess]
  );

  const updatePeriod = useCallback(
    (period: ICustomPeriodFilter) => {
      return filtersRepository
        .updateCustomPeriodFilter(period)
        .then((response) => {
          useFiltersStore.getState().updateCustomPeriod(response);
          onSubmitSuccess(response);
          onDismiss();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filtersRepository, onDismiss, onSubmitSuccess]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.CUSTOM_PERIOD,
    });

    handleSubmit((formData: IForm) => {
      // @ts-ignore
      const payload: ICustomPeriodFilter = {
        ...formData,
        startDate: localWithUTCMidnightString(formData.startDate),
        endDate: localWithUTCMidnightString(formData.endDate),
      };

      if (payload.id) {
        updatePeriod(payload);
      } else {
        createPeriod(payload);
      }
    })();
  }, [createPeriod, handleSubmit, updatePeriod]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.choose_custom_dates')}
        onDismiss={onDismiss}
        submitButtonProps={{
          content: `${t('action.save_and_apply')}`,
          onClick: onSubmit,
          loading,
        }}
      >
        <div className="last-n-days-filter__form">
          <FormElement
            label={t('label.name')}
            message={formState?.errors?.filterName?.message}
          >
            <InputControl
              name="filterName"
              placeholder={t('placeholder.name')}
              invalid={!!formState.errors.filterName}
              autoFocus={true}
            />
          </FormElement>

          <div className="last-n-days-filter__form-row">
            <FormElement
              label={t('label.from')}
              message={formState?.errors?.startDate?.message}
            >
              <DatepickerControl
                name="startDate"
                placeholder={t('placeholder.date_from')}
                invalid={!!formState.errors.startDate}
                max={new Date()}
              />
            </FormElement>

            <FormElement
              label={t('label.to')}
              message={formState?.errors?.endDate?.message}
            >
              <DatepickerControl
                name="endDate"
                placeholder={t('placeholder.date_to')}
                invalid={!!formState.errors.endDate}
                min={startDate}
                max={new Date()}
              />
            </FormElement>
          </div>
        </div>
      </Dialog>
    </FormProvider>
  );
};
