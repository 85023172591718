import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { ITabsItem } from '@cycling-web/analog-ui';
import { ROUTES } from '../../../../router/routes';
import { PageTabs } from '@cycling-web/common';
import { IPinnedTab } from '../../../../types/smart-charts';
import { useEffect, useMemo } from 'react';
import { usePinnedTabStore } from '../../../../store/pinned-tabs/slice';

export const GroupTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const url = pathname.split('/');

  const pinnedTabs = usePinnedTabStore((s) => s.pinnedTabs);
  const pinnedTabsLoaded = usePinnedTabStore((s) => s.pinnedTabsLoaded);

  const items: ITabsItem[] = useMemo(() => {
    const defaultTabs: ITabsItem[] = [
      {
        path: ROUTES.ATHLETES,
        text: t('label.profiles'),
        isActive: url.at(-1) === ROUTES.ATHLETES,
        preserveSearchParams: true,
      },
      {
        path: ROUTES.OVERVIEW,
        text: t('label.overview'),
        isActive: url.at(-1) === ROUTES.OVERVIEW,
        preserveSearchParams: true,
      },
      {
        path: ROUTES.CHARTS,
        text: t('label.charts'),
        isActive: url.at(-1) === ROUTES.CHARTS,
        preserveSearchParams: true,
      },
    ];

    pinnedTabs.forEach((tab: IPinnedTab) => {
      defaultTabs.push({
        path: tab.tab_id,
        text: tab.tab_name,
        isActive: url.at(-1) === tab.tab_id,
        preserveSearchParams: true,
      });
    });

    return defaultTabs;
  }, [pinnedTabs, t, url]);

  useEffect(() => {
    if (!pinnedTabsLoaded) {
      return;
    }

    const activeTabIndex = items.findIndex((tab) => tab.isActive);

    if (activeTabIndex === -1) {
      navigate(items[0].path || '');
    }
  }, [url, items, navigate, pinnedTabsLoaded]);

  return <PageTabs tabs={items} />;
};
