import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { useAthletePerformanceStore } from '../store/slice';
import { format } from 'date-fns';
import {
  IFatigueResistance,
  IFatigueResistanceRelative,
} from '../../../types/performance';
import { calculateDateRange } from '../../../utils/utils';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';

export const useFetchFatigueResistance = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();

  const { filters: sharedFilters } = usePageFilters<IAthleteDetailsFilters>();

  const setFatigueResistance = useAthletePerformanceStore(
    (s) => s.setFatigueResistance
  );
  const setFatigueResistanceLoaded = useAthletePerformanceStore(
    (s) => s.setFatigueResistanceLoaded
  );
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || sharedFilters?.period === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(sharedFilters.period);

    const request1 = performanceRepository.getFatigueResistance({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      athleteId: +athleteId,
    });

    const request2 = performanceRepository.getFatigueResistanceRelative({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      athleteId: +athleteId,
    });

    Promise.all([request1, request2])
      .then((response: [IFatigueResistance, IFatigueResistanceRelative]) => {
        setFatigueResistance(response[0], response[1]);
      })
      .catch((error: AxiosError) => {
        setFatigueResistanceLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_power_duration_title'),
              message: t('error.get_power_duration_message'),
            },
            { toastId: 'get_power_duration' }
          );
        }
      });
  }, [
    athleteId,
    sharedFilters?.period,
    performanceRepository,
    setFatigueResistance,
    setFatigueResistanceLoaded,
    t,
  ]);
};
