import { useUsersStore } from '../../store/users/slice';
import { useUserRole } from '../../hooks/useUserRole';
import { useNavigate } from 'react-router';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { useEffect, useMemo, useState } from 'react';
import { OnboardingStep } from '@cycling-web/common';
import { ROUTES } from '../../router/routes';
import { IOnboardingContext } from './context';

export const useViewModel = () => {
  const userProfile = useUsersStore((s) => s.userProfile);
  const { isAthlete } = useUserRole();
  const navigate = useNavigate();
  const baseUrl = useBaseUrl();

  useEffect(() => {
    if (!userProfile?.onboardingStatus) {
      return;
    }

    const urlMap: Record<string, string> = {
      [OnboardingStep.InvitationAccepted]: ROUTES.PRIVACY_NOTICE,
      [OnboardingStep.PrivacyAccepted]: isAthlete
        ? ROUTES.ONBOARDING_PROFILE
        : ROUTES.ONBOARDING_COMPLETE,
      [OnboardingStep.ProfileInfo]: ROUTES.ONBOARDING_PERFORMANCE,
      [OnboardingStep.PerformanceInfo]: ROUTES.ONBOARDING_PLATFORMS,
      [OnboardingStep.Platforms]: ROUTES.ONBOARDING_DEVICES,
      [OnboardingStep.Devices]: isAthlete
        ? `${baseUrl}/${ROUTES.ATHLETE}/${userProfile.athleteId}`
        : `${baseUrl}/${ROUTES.COACH}/${ROUTES.DASHBOARD}`,
      [OnboardingStep.Completed]: isAthlete
        ? `${baseUrl}/${ROUTES.ATHLETE}/${userProfile.athleteId}`
        : `${baseUrl}/${ROUTES.COACH}/${ROUTES.DASHBOARD}`,
    };

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const search = code ? params : '';
    const url = urlMap[userProfile.onboardingStatus];
    const urlWithParams = `${url}?${search}`;
    navigate(urlWithParams);
  }, [isAthlete, userProfile, baseUrl, navigate]);

  const [termsAndConditionsConfirmed, setTermsAndConditionsConfirmed] =
    useState<boolean>(
      userProfile?.onboardingStatus !== OnboardingStep.InvitationAccepted
    );
  const [privacyNoticeConfirmed, setPrivacyNoticeConfirmed] = useState<boolean>(
    userProfile?.onboardingStatus !== OnboardingStep.InvitationAccepted
  );

  const context: IOnboardingContext = useMemo((): IOnboardingContext => {
    return {
      termsAndConditionsConfirmed,
      setTermsAndConditionsConfirmed,
      privacyNoticeConfirmed,
      setPrivacyNoticeConfirmed,
    };
  }, [privacyNoticeConfirmed, termsAndConditionsConfirmed]);

  return {
    context,
  };
};
