import './index.css';
import { IChartLegendItemProps } from '../../types';
import { CSSProperties } from 'react';

export const ChartLegendItem = ({
  item,
  onRenderItem,
}: IChartLegendItemProps) => {
  if (onRenderItem) {
    return onRenderItem(item);
  }

  const borderColor =
    item.type === 'dashed'
      ? Array.isArray(item.color)
        ? item.color[0]
        : item.color
      : 'transparent';

  const backgroundStyle: CSSProperties = Array.isArray(item.color)
    ? {
        backgroundImage: `linear-gradient(to right, ${item.color
          .map((color, index) => {
            const prevPercent = (100 / item.color.length) * index;
            const percent = (100 / item.color.length) * (index + 1);
            return index === 0
              ? `${color} ${percent}%`
              : `${color} ${prevPercent}%, ${color} ${percent}%`;
          })
          .join(', ')})`,
      }
    : { backgroundColor: item.color };

  const borderStyle: CSSProperties =
    borderColor === 'transparent'
      ? {}
      : {
          borderColor,
          borderStyle: item.type,
        };

  return (
    <>
      <span
        className="chart-legend-item__mark"
        style={{
          ...backgroundStyle,
          ...borderStyle,
          backgroundRepeat: 'no-repeat',
          backgroundClip: 'border-box',
          backgroundPosition: 'center',
        }}
      />
      <span className="chart-legend-item__text">{item.name}</span>
    </>
  );
};
