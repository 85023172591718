import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { format } from 'date-fns';
import { use } from 'react';
import { VoiceRecordingContext } from '../../context/VoiceRecordingContext';

export const RecordingTimer = () => {
  const { recordingTime } = use(VoiceRecordingContext);
  const formattedTime = format(new Date(recordingTime * 1000), 'mm:ss');

  return (
    <div className="voice-recording__timer">
      <Typography text={formattedTime} />
    </div>
  );
};
