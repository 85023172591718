import './index.css';
import { useEventStore } from '../../store/slice';
import { useMemo } from 'react';
import { IChartSource } from '../../../../components/Chart';
import { ChartAxisName } from '../../../../constants/charts';
import { ClimbingChart } from '../ClimbingChart';

export const ClimbingChartWrapper = () => {
  const gpx = useEventStore((s) => s.gpx);
  const gpxLoaded = useEventStore((s) => s.gpxLoaded);

  const source = useMemo((): IChartSource => {
    const source: IChartSource = [
      [
        ChartAxisName.Distance,
        ChartAxisName.Elevation,
        ChartAxisName.Gradient,
        'Latitude',
        'Longitude',
      ],
    ];

    gpx.forEach((item, i) => {
      if (i === 0) {
        source.push([item.cumulative_distance_km, item.elevation, 0]);
      } else {
        const prev = gpx[i - 1];

        const deltaElevation = item.elevation - prev.elevation;
        const deltaDistanceKm =
          item.cumulative_distance_km - prev.cumulative_distance_km;
        const deltaDistanceM = deltaDistanceKm * 1000;

        const gradient =
          deltaDistanceM !== 0 ? (deltaElevation / deltaDistanceM) * 100 : 0;

        source.push([
          item.cumulative_distance_km,
          item.elevation,
          gradient,
          item.latitude,
          item.longitude,
        ]);
      }
    });

    return source;
  }, [gpx]);

  if (gpxLoaded && gpx.length === 0) {
    return null;
  }

  return <ClimbingChart source={source} loading={!gpxLoaded} />;
};
