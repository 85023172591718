import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import { IAnthropometry, IAnthropometryFormData } from '../../types/coach';
import { IDatepickerMarker, Toast } from '@cycling-web/analog-ui';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { CoachRepository } from '../../api/coach/repository';
import { CoachService } from '../../api/coach/service';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IAthlete } from '../../types/athletes';
import { useAthletesStore } from '../../store/athletes/slice';
import { IWellness } from '../../types/wellness';
import { WellnessRepository } from '../../api/wellness/repository';
import { WellnessService } from '../../api/wellness/service';
import { useAthleteWellnessStore } from '../../pages/AthleteWellness/store/slice';
import { UTCMidnightToSameDate } from '../../utils/date-time';
import { trackUserInteractionEvent } from '../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../ms/tracking-entities';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
};

export const useViewModel = ({ athlete, onDismiss }: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [anthropometry, setAnthropometry] = useState<IAnthropometry[]>([]);
  const [anthropometryLoading, setAnthropometryLoading] =
    useState<boolean>(false);
  const fetchingRef = useRef<boolean>(false);
  const markers = useMemo((): IDatepickerMarker[] => {
    return anthropometry.map((a: IAnthropometry) => {
      return {
        date: new Date(a.date),
        variant: 'primary',
      };
    });
  }, [anthropometry]);

  const anthropometryMap: Record<string, IAnthropometryFormData> =
    useMemo(() => {
      const map: Record<string, IAnthropometryFormData> = {};

      anthropometry.forEach((a: IAnthropometry) => {
        map[a.date] = {
          athleteId: a.athleteId,
          height: a.height.toString(),
          weight: a.weight.toString(),
          biceps: a.biceps.toString(),
          triceps: a.triceps.toString(),
          subscapula: a.subscapula.toString(),
          iliacCrest: a.iliacCrest.toString(),
          abdominal: a.abdominal.toString(),
          thigh: a.thigh.toString(),
          calf: a.calf.toString(),
          supraspinale: a.supraspinale.toString(),
          date: a.date,
        };
      });

      return map;
    }, [anthropometry]);

  const formatDate = useCallback((date: Date) => {
    return format(date, 'yyyy-MM-dd');
  }, []);

  const coachRepository = useMemo(() => {
    return new CoachRepository(new CoachService());
  }, []);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService());
  }, []);

  const onPeriodChange = useCallback(
    (date: Date) => {
      if (fetchingRef.current) {
        return;
      }
      setAnthropometryLoading(true);
      fetchingRef.current = true;

      const startDate = format(startOfMonth(date), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(date), 'yyyy-MM-dd');

      coachRepository
        .getAnthropometry({
          athleteId: athlete.id,
          startDate,
          endDate,
        })
        .then((anthropometry: IAnthropometry[]) => {
          setAnthropometry(anthropometry);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setAnthropometryLoading(false);
          fetchingRef.current = false;
        });
    },
    [athlete.id, coachRepository]
  );

  const schema = useMemo(() => {
    return z
      .object({
        height: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 150 && v <= 200, {
            message: t('validation.out_of_range', { min: 150, max: 200 }),
          }),
        weight: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 40 && v <= 100, {
            message: t('validation.out_of_range', { min: 40, max: 100 }),
          }),
        biceps: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        triceps: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        subscapula: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        supraspinale: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        iliacCrest: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        abdominal: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        thigh: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
        calf: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v >= 1 && v <= 30, {
            message: t('validation.out_of_range', { min: 1, max: 30 }),
          }),
      })
      .passthrough();
  }, [t]);

  const defaultValues: IAnthropometryFormData = useMemo(() => {
    return {
      athleteId: athlete.id,
      height: athlete.height.toString(),
      weight: '',
      biceps: '',
      triceps: '',
      subscapula: '',
      iliacCrest: '',
      abdominal: '',
      thigh: '',
      calf: '',
      supraspinale: '',
      date: formatDate(UTCMidnightToSameDate(Date.now())),
    };
  }, [athlete.id, athlete.height, formatDate]);

  const form = useForm<IAnthropometryFormData>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = form;
  const [date] = watch(['date']);

  const onDateChange = useCallback(
    (date: Date) => {
      const strDate = formatDate(date);
      setValue('date', strDate);

      if (anthropometryMap[strDate]) {
        reset({
          ...anthropometryMap[strDate],
        });
      } else {
        reset({
          ...defaultValues,
          date: strDate,
        });
      }
    },
    [anthropometryMap, defaultValues, formatDate, reset, setValue]
  );

  const handleSave = useCallback(
    (formData: IAnthropometryFormData) => {
      setLoading(true);
      coachRepository
        .setAnthropometry(formData)
        .then(() => {
          useAthletesStore.getState().updateAthlete({
            id: formData.athleteId,
            height: +formData.height,
            weight: +formData.weight,
          });

          return wellnessRepository.getLastUpdatedWellness(formData.athleteId);
        })
        .then((wellness: IWellness) => {
          useAthleteWellnessStore.getState().setWellness(wellness);
          onDismiss();
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.save_anthropometry_title'),
              message: t('error.save_anthropometry_message'),
            },
            { toastId: 'save_anthropometry' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [coachRepository, onDismiss, t, wellnessRepository]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.ANTHROPOMETRY,
    });

    handleSubmit(
      (formData: IAnthropometryFormData) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  return {
    t,
    form,
    onDismiss,
    onSubmit,
    loading,
    errors,
    markers,
    anthropometryLoading,
    onPeriodChange,
    date,
    onDateChange,
  };
};
