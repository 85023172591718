import './index.css';
import { Page } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { ChannelsChart } from './components/ChannelsChart';
import { IntervalDataTable } from './components/IntervalDataTable';
import { TimeInZones } from './components/TimeInZones';
import { WorkoutPageTitle } from './components/PageTitle';
import { WorkoutOverview } from './components/WorkoutOverview';
import { Card, CardHeader } from '@cycling-web/analog-ui';
import { WorkoutComments } from './components/WorkoutComments';
import { WorkoutSelection } from './components/WorkoutSelection';
import { AthleteStats } from '../../components/AthleteStats';
import { onTrackBackButtonClick } from '../../ms/utils';
import { RouteMap } from '../../components/RouteMap';
import { useTranslation } from 'react-i18next';
import { useMobileMenu } from '../../components/MobileMenu';

export const WorkoutDetails = () => {
  const { t } = useTranslation();
  const {
    workout,
    workoutLoaded,
    calendarWorkout,
    backUrl,
    athlete,
    athleteLoaded,
    gpxData,
    selectedLaps,
    laps,
    positionOnMap,
  } = useViewModel();
  const onRenderMenu = useMobileMenu();

  return (
    <Page
      pageHeaderProps={{
        title: <WorkoutPageTitle />,
        backUrl,
        onBackUrlClick: onTrackBackButtonClick,
        onRenderMenu,
      }}
    >
      <div className="workout-overview">
        <header className="workout-header">
          <AthleteStats athlete={athlete} athleteLoaded={athleteLoaded} />
        </header>

        <Card variant="layer2" className="workout-overview__cards">
          <div className="workout-overview__cards-row">
            <WorkoutOverview
              calendarWorkout={calendarWorkout}
              loading={!workoutLoaded}
            />
            <WorkoutComments />
            <WorkoutSelection />
          </div>
          {workoutLoaded && (
            <Card className="workout-route-map-wrapper" variant="layer3">
              <CardHeader title={t('label.map')} />
              {/* @ts-ignore */}
              <RouteMap
                data={gpxData}
                laps={laps}
                loading={!workoutLoaded}
                activePosition={positionOnMap}
              />
            </Card>
          )}
          <ChannelsChart
            data={workout}
            loading={!workoutLoaded}
            selectedLaps={selectedLaps}
          />
          <Card className="workout-laps-table" variant="layer3">
            <CardHeader title={t('label.interval_data')} />
            <IntervalDataTable />
          </Card>
          <TimeInZones />
        </Card>
      </div>
    </Page>
  );
};
