import { ICalendarContext, ICalendarProps, TimePeriod } from './types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDatepickerMarkerVariant } from '../Datepicker/types';

export const useViewModel = (props: ICalendarProps): ICalendarContext => {
  const {
    value,
    range,
    isOpen,
    onOpenChange,
    innerRange: outsideInnerRange,
    currentInnerRangePointer: outsideCurrentInnerRangePointer,
    markers = [],
    onPeriodChange,
    max,
  } = props;

  const localIsOpen = isOpen || true;
  const localOnOpenChange = onOpenChange || ((_: boolean) => {});
  const localInnerRange = useRef<Date[]>([]);
  const localCurrentInnerRangePointer = useRef<number>(0);

  const innerRange = outsideInnerRange || localInnerRange;
  const currentInnerRangePointer =
    outsideCurrentInnerRangePointer || localCurrentInnerRangePointer;

  const innerValueInit = useRef<boolean>(false);

  const [dateForPeriod, setDateForPeriod] = useState<Date>(max || new Date());
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Days);

  useEffect(() => {
    if (onPeriodChange) {
      onPeriodChange(dateForPeriod);
    }
  }, [dateForPeriod, onPeriodChange]);

  const onDateForPeriodChange = useCallback((value: Date) => {
    const next = new Date(value);
    next.setDate(1);
    setDateForPeriod(next);
  }, []);

  const handleTimePeriodChange = useCallback((timePeriod: TimePeriod): void => {
    setTimePeriod(timePeriod);
  }, []);

  useEffect(() => {
    if (!innerValueInit.current) {
      const next: Date | undefined = range ? value[0] : value;
      onDateForPeriodChange(next || max || new Date());
      innerValueInit.current = true;
      handleTimePeriodChange(TimePeriod.Days);
    }
  }, [onDateForPeriodChange, value, range, handleTimePeriodChange, max]);

  useEffect(() => {
    return () => {
      innerValueInit.current = false;
    };
  }, []);

  const markersMap: Record<number, IDatepickerMarkerVariant> = useMemo(() => {
    if (!markers) {
      return {};
    }

    const map: Record<number, IDatepickerMarkerVariant> = {};

    for (let i = 0; i < markers.length; i++) {
      const date = markers[i].date;
      date.setHours(0, 0, 0, 0);
      map[date.getTime()] = markers[i].variant;
    }

    return map;
  }, [markers]);

  return {
    ...props,
    timePeriod,
    handleTimePeriodChange,
    dateForPeriod,
    onDateForPeriodChange,
    innerValueInit,
    isOpen: localIsOpen,
    onOpenChange: localOnOpenChange,
    innerRange,
    currentInnerRangePointer,
    markersMap,
  };
};
