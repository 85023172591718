import { IWorkoutCalendar } from '../../../types/workouts';
import { isPast, isToday, parseISO } from 'date-fns';

export function mapWorkoutsAndPrescriptions(
  calendar: IWorkoutCalendar
): IWorkoutCalendar {
  const mappedCalendar: IWorkoutCalendar = {};

  for (const [date, record] of Object.entries(calendar)) {
    const workouts = record.workouts ?? [];
    const prescription = record.prescription ?? [];

    const newRecord: IWorkoutCalendarRecord = {
      workouts: [],
      prescription: [],
    };

    if (!workouts.length && !prescription.length) {
      mappedCalendar[date] = record;
      continue;
    }

    for (let i = 0; i < workouts.length; i++) {
      const workout = workouts[i];
      const matchedPrescription = prescription[i];

      if (
        matchedPrescription &&
        workout.date === matchedPrescription.workoutDay
      ) {
        newRecord.workouts.push({
          ...workout,
          prescription: matchedPrescription,
        });
      } else {
        newRecord.workouts.push({ ...workout });
      }
    }

    if (prescription.length > workouts.length) {
      for (let i = workouts.length; i < prescription.length; i++) {
        const p = prescription[i];
        if (!p) continue;

        const prescriptionDate = parseISO(p.workoutDay);
        const isPrescriptionPast =
          !isToday(prescriptionDate) && isPast(prescriptionDate);

        newRecord.prescription.push({
          ...p,
          skipped: isPrescriptionPast,
        });
      }
    }

    mappedCalendar[date] = newRecord;
  }

  return mappedCalendar;
}
