import { IDashboardSlice, IDashboardState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ICriticalPower,
  ILatestTrainingLoad,
  IPeak,
  IWorkoutDetail,
} from '../../../types/performance';

export const initialState: IDashboardState = {
  peaks: [],
  peaksLoaded: false,
  trainingLoad: [],
  trainingLoadLoaded: false,
  criticalPower: [],
  criticalPowerLoaded: false,
  workoutDetails: [],
  workoutDetailsLoaded: false,
};

export const useDashboardStore = create<IDashboardSlice>()(
  immer((set) => ({
    ...initialState,
    setTrainingLoad: (trainingLoad: ILatestTrainingLoad[]) => {
      set((state) => {
        state.trainingLoad = trainingLoad;
        state.trainingLoadLoaded = true;
      });
    },
    setTrainingLoadLoaded: (loaded: boolean) => {
      set((state) => {
        state.trainingLoadLoaded = loaded;
      });
    },
    setPeaks: (peaks: IPeak[]) => {
      set((state) => {
        state.peaks = peaks;
        state.peaksLoaded = true;
      });
    },
    setPeaksLoaded: (loaded: boolean) => {
      set((state) => {
        state.peaksLoaded = loaded;
      });
    },
    setCriticalPower: (criticalPower: ICriticalPower[]) => {
      set((state) => {
        state.criticalPower = criticalPower;
        state.criticalPowerLoaded = true;
      });
    },
    setCriticalPowerLoaded: (loaded: boolean) => {
      set((state) => {
        state.criticalPowerLoaded = loaded;
      });
    },
    setWorkoutDetails: (workoutDetails: IWorkoutDetail[]) => {
      set((state) => {
        state.workoutDetails = workoutDetails;
        state.workoutDetailsLoaded = true;
      });
    },
    setWorkoutDetailsLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutDetailsLoaded = loaded;
      });
    },
    reset: () => {
      set({
        peaks: initialState.peaks,
        peaksLoaded: initialState.peaksLoaded,
        trainingLoad: initialState.trainingLoad,
        trainingLoadLoaded: initialState.trainingLoadLoaded,
        criticalPower: initialState.criticalPower,
        criticalPowerLoaded: initialState.criticalPowerLoaded,
        workoutDetails: initialState.workoutDetails,
        workoutDetailsLoaded: initialState.workoutDetailsLoaded,
      });
    },
  }))
);
