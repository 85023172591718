import './index.css';
import { ConfirmDialog, Dialog } from '@cycling-web/analog-ui';
import { useViewModel } from './useViewModel';
import { TrainingPlanContext } from './context';
import { ITrainingPlanModalProps } from './types';
import { useCallback } from 'react';
import { TrainingPlanAside } from './components/Aside';
import { TrainingPlanMain } from './components/MainView';

export const TrainingZonesModal = (props: ITrainingPlanModalProps) => {
  const { t, context } = useViewModel(props);

  const { discardDialogProps, dismissDiscardDialog, handleDismiss } = context;

  const onRenderFooter = useCallback(() => {
    return null;
  }, []);

  return (
    <TrainingPlanContext value={context}>
      <Dialog
        className="training-plan-modal"
        title={t('label.training_plan')}
        onDismiss={handleDismiss}
        outsidePress={false}
        preventDefaultOnClick={false}
        onRenderFooter={onRenderFooter}
      >
        <div className="training-plan-modal__inner">
          <TrainingPlanAside />
          <TrainingPlanMain />
        </div>
        {discardDialogProps && (
          <ConfirmDialog
            onDismiss={dismissDiscardDialog}
            title={t('confirm.discard_changes_title')}
            subtitle={t('confirm.discard_changes_message')}
            submitText={t('action.discard')}
            submitAction={discardDialogProps.submitAction || Promise.resolve}
          />
        )}
      </Dialog>
    </TrainingPlanContext>
  );
};
