import React from 'react';
import { Navigate, RouteObject } from 'react-router';
import { ROUTES } from './routes';
import { SignOut } from '../components/SignOut';
import { Auth } from '../components/Auth';
import { SignIn } from '../components/SignIn';
import { SignUp } from '../components/SignUp';
import { AuthOtp } from '../components/AuthOtp';
import { AuthPassword } from '../components/AuthPassword';
import { ForgotPassword } from '../components/ForgotPassword';
import { ForgotPasswordOtp } from '../components/ForgotPasswordOtp';
import { ResetPassword } from '../components/ResetPassword';
import { Waitlist } from '../components/Waitlist';

export const routes: RouteObject[] = [
  {
    path: `/${ROUTES.AUTH}`,
    children: [
      {
        path: '*',
        element: <Auth />,
        children: [
          {
            path: '',
            element: <Navigate to={ROUTES.SIGN_IN} />,
          },
          {
            path: ROUTES.WAITLIST,
            element: <Waitlist />,
          },
          {
            path: ROUTES.SIGN_IN,
            element: <SignIn />,
          },
          {
            path: ROUTES.SIGN_UP,
            element: <SignUp />,
          },
          {
            path: ROUTES.OTP,
            element: <AuthOtp />,
          },
          {
            path: ROUTES.PASSWORD,
            element: <AuthPassword />,
          },
          {
            path: ROUTES.FORGOT_PASSWORD,
            element: <ForgotPassword />,
          },
          {
            path: ROUTES.FORGOT_PASSWORD_OTP,
            element: <ForgotPasswordOtp />,
          },
          {
            path: ROUTES.NEW_PASSWORD,
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
  {
    path: `/${ROUTES.SIGN_OUT}`,
    element: <SignOut />,
  },
];
