import {
  IAcceptPrescriptionRequest,
  IAcceptPrescriptionResponse,
  ICreateEventRequest,
  ICreateEventResponse,
  ICreatePrescriptionRequest,
  ICreatePrescriptionResponse,
  ICreateRuleRequest,
  ICreateRuleResponse,
  IDeleteAthleteDayOffRequest,
  IDeleteAthleteDayOffResponse,
  IDeleteEventRequest,
  IDeleteEventResponse,
  IDeletePrescriptionRequest,
  IDeletePrescriptionResponse,
  IDeleteRuleRequest,
  IDeleteRuleResponse,
  IGetAthleteDayOffsRequest,
  IGetAthleteDayOffsResponse,
  IGetAthleteRulesRequest,
  IGetAthleteRulesResponse,
  IGetCalendarRequest,
  IGetCalendarResponse,
  IGetEventByIdRequest,
  IGetEventByIdResponse,
  IGetEventsRequest,
  IGetEventsResponse,
  IGetManyPrescriptionsRequest,
  IGetManyPrescriptionsResponse,
  IGetPrescriptionRequest,
  IGetPrescriptionResponse,
  IGetRuleByIdRequest,
  IGetRuleByIdResponse,
  IGetWorkoutAnalysisRequest,
  IGetWorkoutAnalysisResponse,
  IGetWorkoutRequest,
  IGetWorkoutResponse,
  IUpdateEventRequest,
  IUpdateEventResponse,
  IUpdatePrescriptionRequest,
  IUpdatePrescriptionResponse,
  IUpdateRuleRequest,
  IUpdateRuleResponse,
  IUploadGPXRequest,
  IUploadGPXResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { qsOptions } from '../utils/qsOptions';

interface IWorkoutsService {
  getWorkout: (
    p: IGetWorkoutRequest,
    c?: AxiosRequestConfig
  ) => R<IGetWorkoutResponse>;
  getWorkoutAnalysis: (
    p: IGetWorkoutAnalysisRequest,
    c?: AxiosRequestConfig
  ) => R<IGetWorkoutAnalysisResponse>;
  getCalendar: (
    p: IGetCalendarRequest,
    c?: AxiosRequestConfig
  ) => R<IGetCalendarResponse>;
  getCalendarRule: (
    p: IGetRuleByIdRequest,
    c?: AxiosRequestConfig
  ) => R<IGetRuleByIdResponse>;
  updateCalendarRule: (
    p: IUpdateRuleRequest,
    c?: AxiosRequestConfig
  ) => R<IUpdateRuleResponse>;
  deleteCalendarRule: (
    p: IDeleteRuleRequest,
    c?: AxiosRequestConfig
  ) => R<IDeleteRuleResponse>;
  createCalendarRule: (
    p: ICreateRuleRequest,
    c?: AxiosRequestConfig
  ) => R<ICreateRuleResponse>;
  getAthleteRules: (
    p: IGetAthleteRulesRequest,
    c?: AxiosRequestConfig
  ) => R<IGetAthleteRulesResponse>;
  getAthleteDayOffs: (
    p: IGetAthleteDayOffsRequest,
    c?: AxiosRequestConfig
  ) => R<IGetAthleteDayOffsResponse>;
  deleteAthleteDayOffs: (
    p: IDeleteAthleteDayOffRequest,
    c?: AxiosRequestConfig
  ) => R<IDeleteAthleteDayOffResponse>;
  getEventById: (
    p: IGetEventByIdRequest,
    c?: AxiosRequestConfig
  ) => R<IGetEventByIdResponse>;
  createEvent: (
    p: ICreateEventRequest,
    c?: AxiosRequestConfig
  ) => R<ICreateEventResponse>;
  updateEvent: (
    p: IUpdateEventRequest,
    c?: AxiosRequestConfig
  ) => R<IUpdateEventResponse>;
  deleteEvent: (
    p: IDeleteEventRequest,
    c?: AxiosRequestConfig
  ) => R<IDeleteEventResponse>;
  getEvents: (
    p: IGetEventsRequest,
    c?: AxiosRequestConfig
  ) => R<IGetEventsResponse>;
  uploadGPX: (
    p: IUploadGPXRequest,
    c?: AxiosRequestConfig
  ) => R<IUploadGPXResponse>;

  // Prescription methods
  getPrescription: (
    p: IGetPrescriptionRequest,
    c?: AxiosRequestConfig
  ) => R<IGetPrescriptionResponse>;

  createPrescription: (
    p: ICreatePrescriptionRequest,
    c?: AxiosRequestConfig
  ) => R<ICreatePrescriptionResponse>;

  updatePrescription: (
    p: IUpdatePrescriptionRequest,
    c?: AxiosRequestConfig
  ) => R<IUpdatePrescriptionResponse>;

  deletePrescription: (
    p: IDeletePrescriptionRequest,
    c?: AxiosRequestConfig
  ) => R<IDeletePrescriptionResponse>;

  acceptPrescription: (
    p: IAcceptPrescriptionRequest,
    c?: AxiosRequestConfig
  ) => R<IAcceptPrescriptionResponse>;

  getManyPrescriptions: (
    p: IGetManyPrescriptionsRequest,
    c?: AxiosRequestConfig
  ) => R<IGetManyPrescriptionsResponse>;
}

export class WorkoutsService implements IWorkoutsService {
  async getWorkout(
    p: IGetWorkoutRequest,
    c?: AxiosRequestConfig
  ): R<IGetWorkoutResponse> {
    try {
      return axiosInstance.get(
        `/v1/performance/workoutAnalysis/${p.workoutId}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getWorkoutAnalysis(
    p: IGetWorkoutAnalysisRequest,
    c?: AxiosRequestConfig
  ): R<IGetWorkoutAnalysisResponse> {
    try {
      return axiosInstance.post(
        `/v1/ai/v2/ana/workout_analysis/${p.workout_id}`,
        p,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Day Offs */

  async getCalendar(
    p: IGetCalendarRequest,
    c?: AxiosRequestConfig
  ): R<IGetCalendarResponse> {
    try {
      const params = qs.stringify(
        {
          startDate: p.startDate,
          endDate: p.endDate,
        },
        qsOptions
      );

      return axiosInstance.get(
        `/v1/calendar/calendar/${p.athleteId}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCalendarRule(
    p: IGetRuleByIdRequest,
    c?: AxiosRequestConfig
  ): R<IGetRuleByIdResponse> {
    try {
      return axiosInstance.get(`/v1/dayOff/rule/${p.ruleId}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCalendarRule(
    p: IUpdateRuleRequest,
    c?: AxiosRequestConfig
  ): R<IUpdateRuleResponse> {
    try {
      return axiosInstance.put(`/v1/dayOff/rule/${p.id}`, p, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteCalendarRule(
    p: IDeleteRuleRequest,
    c?: AxiosRequestConfig
  ): R<IDeleteRuleResponse> {
    try {
      return axiosInstance.delete(`/v1/dayOff/rule/${p.ruleId}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createCalendarRule(
    p: ICreateRuleRequest,
    c?: AxiosRequestConfig
  ): R<ICreateRuleResponse> {
    try {
      return axiosInstance.post(`/v1/dayOff`, p, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAthleteRules(
    p: IGetAthleteRulesRequest,
    c?: AxiosRequestConfig
  ): R<IGetAthleteRulesResponse> {
    try {
      return axiosInstance.get(`/v1/dayOff/${p.athleteId}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAthleteDayOffs(
    p: IGetAthleteDayOffsRequest,
    c?: AxiosRequestConfig
  ): R<IGetAthleteDayOffsResponse> {
    try {
      const params = qs.stringify(p, qsOptions);

      return axiosInstance.get(`/v1/dayOff/days?${params}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteAthleteDayOffs(
    p: IDeleteAthleteDayOffRequest,
    c?: AxiosRequestConfig
  ): R<IDeleteAthleteDayOffResponse> {
    try {
      return axiosInstance.delete(`/v1/dayOff/remove/${p.dayOffId}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Events */

  async getEventById(
    p: IGetEventByIdRequest,
    c?: AxiosRequestConfig
  ): R<IGetEventByIdResponse> {
    return axiosInstance.get(`/v1/athleteEvents/${p.eventId}`, c);
  }

  async createEvent(
    p: ICreateEventRequest,
    c?: AxiosRequestConfig
  ): R<ICreateEventResponse> {
    return axiosInstance.post(`/v1/athleteEvents`, p, c);
  }

  async updateEvent(
    p: IUpdateEventRequest,
    c?: AxiosRequestConfig
  ): R<IUpdateEventResponse> {
    return axiosInstance.put(`/v1/athleteEvents/${p.id}`, p, c);
  }

  async deleteEvent(
    p: IDeleteEventRequest,
    c?: AxiosRequestConfig
  ): R<IDeleteEventResponse> {
    return axiosInstance.delete(`/v1/athleteEvents/${p.eventId}`, c);
  }

  async getEvents(
    p: IGetEventsRequest,
    c?: AxiosRequestConfig
  ): R<IGetEventsResponse> {
    const params = qs.stringify(p, qsOptions);
    return axiosInstance.get(`/v1/athleteEvents?${params}`, c);
  }

  async uploadGPX(p: IUploadGPXRequest): R<IUploadGPXResponse> {
    try {
      return axiosInstance.post(
        `/v1/athleteEvents/${p.id}/uploadGpx`,
        {
          gpxFile: p.formData.get('gpxFile'),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Prescriptions */

  async getPrescription(
    p: IGetPrescriptionRequest,
    c?: AxiosRequestConfig
  ): R<IGetPrescriptionResponse> {
    try {
      return axiosInstance.get(`/v1/workoutPrescription/${p.id}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createPrescription(
    p: ICreatePrescriptionRequest,
    c?: AxiosRequestConfig
  ): R<ICreatePrescriptionResponse> {
    try {
      return axiosInstance.post(`/v1/workoutPrescription`, p, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updatePrescription(
    p: IUpdatePrescriptionRequest,
    c?: AxiosRequestConfig
  ): R<IUpdatePrescriptionResponse> {
    try {
      return axiosInstance.put(`/v1/workoutPrescription/${p.id}`, p, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deletePrescription(
    p: IDeletePrescriptionRequest,
    c?: AxiosRequestConfig
  ): R<IDeletePrescriptionResponse> {
    try {
      return axiosInstance.delete(`/v1/workoutPrescription/${p.id}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async acceptPrescription(
    p: IAcceptPrescriptionRequest,
    c?: AxiosRequestConfig
  ): R<IAcceptPrescriptionResponse> {
    try {
      return axiosInstance.put(
        `/v1/workoutPrescription/${p.id}/accept?athleteId=${p.athleteId}`,
        {},
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getManyPrescriptions(
    p: IGetManyPrescriptionsRequest,
    c?: AxiosRequestConfig
  ): R<IGetManyPrescriptionsResponse> {
    try {
      const params = qs.stringify(
        {
          athleteId: p.athleteId,
          startDate: p.startDate,
          endDate: p.endDate,
        },
        qsOptions
      );

      return axiosInstance.get(
        `/v1/workoutPrescription/athlete/all?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
