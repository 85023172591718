import './index.css';
import { IconButton, Tooltip } from '@cycling-web/analog-ui';
import MenuCollapse from '../../../../assets/icons/menu-collapse.svg?react';
import MenuExpand from '../../../../assets/icons/menu-expand.svg?react';
import { useSideNavigationContext } from '../../context';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import { TrackingEvent, TrackingModal } from '../../../../ms/tracking-entities';

export const CollapseButton = () => {
  const { t } = useTranslation();
  const { showMobileMenu, setShowMobileMenu } = useSideNavigationContext();

  const handleClick = useCallback(() => {
    setShowMobileMenu((f) => {
      trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
        modal: TrackingModal.SIDE_NAVIGATION,
      });

      return !f;
    });
  }, [setShowMobileMenu]);

  return (
    <div className="side-navigation__toggle-menu">
      <Tooltip
        anchor={
          <IconButton
            variant="quietLayer3"
            content={showMobileMenu ? <MenuExpand /> : <MenuCollapse />}
            onClick={handleClick}
          />
        }
        content={showMobileMenu ? t('tooltip.expand') : t('tooltip.collapse')}
        placement="right"
      />
    </div>
  );
};
