import {
  IGetAthleteProfilesRequest,
  IGetAthleteProfilesResponse,
  ISaveAthleteProfilesRequest,
  ISaveAthleteProfilesResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import { AxiosRequestConfig } from 'axios';

interface IAthleteProfileService {
  getAthleteProfiles: (
    p: IGetAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ) => R<IGetAthleteProfilesResponse>;

  saveAthleteProfiles: (
    p: ISaveAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ) => R<ISaveAthleteProfilesResponse>;
}

export class AthleteProfileService implements IAthleteProfileService {
  async getAthleteProfiles(
    p: IGetAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ): R<IGetAthleteProfilesResponse> {
    try {
      return axiosInstance.get(`/v1/athletes/zones/${p.athleteId}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveAthleteProfiles(
    p: ISaveAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ): R<ISaveAthleteProfilesResponse> {
    try {
      return axiosInstance.put(`/v1/athletes/zones/${p.athleteId}`, p.body, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
