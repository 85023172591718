import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { WellnessRepository } from '../../../../../api/wellness/repository';
import { WellnessService } from '../../../../../api/wellness/service';
import { DateGroupBy, DateRange } from '../../../../../types/enums';
import { IHeartMeasurement } from '../../../../../types/wellness';
import { calculateDateRange } from '../../../../../utils/utils';

export const useFetchAthleteHeartMeasurements = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();

  const [heartMeasurements, setHeartMeasurements] =
    useState<IHeartMeasurement | null>(null);
  const [heartMeasurementsLoaded, setHeartMeasurementsLoaded] =
    useState<boolean>(false);

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(DateRange.Month);

    wellnessRepository
      .getHeartMeasurements({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteId: +athleteId,
        unit: DateGroupBy.Day,
      })
      .then((data: IHeartMeasurement) => {
        setHeartMeasurements(data);
      })
      .finally(() => {
        setHeartMeasurementsLoaded(true);
      });
  }, [
    athleteId,
    setHeartMeasurements,
    setHeartMeasurementsLoaded,
    t,
    wellnessRepository,
  ]);

  return {
    heartMeasurements,
    heartMeasurementsLoaded,
  };
};
