import './index.css';

import { Widget } from '../Widget';
import { ThisWeekCard } from './components/ThisWeekCard';
import { useTranslation } from 'react-i18next';
import { useFetchWeeklyPerformanceReport } from './hooks/useFetchWeeklyPerformanceReports';

export const ThisWeek = () => {
  const { t } = useTranslation();
  const { report, reportLoaded } = useFetchWeeklyPerformanceReport();

  const this_week_duration = `${t('label.this_week')} ${t(
    'label.duration'
  ).toLowerCase()}`;

  const this_week_distance = `${t('label.this_week')} ${t(
    'label.distance'
  ).toLowerCase()}`;

  const this_week_tss = `${t('label.this_week')} ${t('label.tss')}`;
  const this_week_climbing = `${t('label.this_week')} ${t(
    'label.climbing'
  ).toLowerCase()}`;

  return (
    <Widget className="athlete-profile__this-week-widget">
      <ThisWeekCard
        name={this_week_duration}
        value={
          !report?.durationStr || report?.durationStr === '0.0'
            ? '00:00'
            : report?.durationStr
        }
        loading={!reportLoaded}
      />
      <ThisWeekCard
        name={this_week_distance}
        value={`${
          report?.distanceInKm ? Math.round(report.distanceInKm) : 0
        } ${t('units.km')}`}
        loading={!reportLoaded}
      />
      <ThisWeekCard
        name={this_week_tss}
        value={report?.tss ? Math.round(report.tss) : 0}
        loading={!reportLoaded}
      />
      <ThisWeekCard
        name={this_week_climbing}
        value={`${
          report?.climbInMeter ? Math.round(report.climbInMeter) : 0
        } ${t('units.m')}`}
        loading={!reportLoaded}
      />
    </Widget>
  );
};
