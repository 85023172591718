import './index.css';
import { CSSProperties, ReactNode } from 'react';
import { clsx } from 'clsx';

type IProps = {
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
};

export const Widget = ({ children, style, className }: IProps) => {
  const rootClass = clsx('athlete-profile__widget', className);

  return (
    <div className={rootClass} style={style}>
      {children}
    </div>
  );
};
