import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { IConfirmDialogProps } from '@cycling-web/analog-ui';
import {
  IFitnessGoal,
  ITrainingPlanBase,
  ITrainingPlanContext,
  ITrainingPlanModalProps,
  TrainingPlanWizardStep,
} from './types';
import { useFitnessGoals } from './hooks/useFitnessGoals';

export const useViewModel = (props: ITrainingPlanModalProps) => {
  const { t } = useTranslation();
  const { onDismiss } = props;

  const [wizardStep, setWizardStep] = useState<TrainingPlanWizardStep>(
    TrainingPlanWizardStep.Step1
  );

  const fitnessGoals: IFitnessGoal[] = useFitnessGoals();
  const [trainingPlan, setTrainingPlan] = useState<ITrainingPlanBase>({
    date: new Date(),
    competition: null,
    fitnessGoal: fitnessGoals[0],
    fitnessGoalTimeline: 6,
  });

  const onTrainingPlanChange = useCallback(
    (next: Partial<ITrainingPlanBase>) => {
      setTrainingPlan((prev) => {
        return {
          ...prev,
          ...next,
        };
      });
    },
    []
  );

  // ---------------------------------------------------------------------------
  // Discard dialog

  const [discardDialogProps, setDiscardDialogProps] = useState<
    Partial<IConfirmDialogProps> | undefined
  >(undefined);

  const openDiscardDialog = useCallback(
    (props: Partial<IConfirmDialogProps>) => {
      setDiscardDialogProps(props);
    },
    []
  );

  const dismissDiscardDialog = useCallback(() => {
    setDiscardDialogProps(undefined);
    onDismiss();
  }, [onDismiss]);

  // ---------------------------------------------------------------------------

  const handleDismiss = useCallback(() => {
    onDismiss();
  }, [onDismiss]);

  const context = useMemo((): ITrainingPlanContext => {
    return {
      ...props,
      handleDismiss,
      discardDialogProps,
      openDiscardDialog,
      dismissDiscardDialog,
      wizardStep,
      setWizardStep,
      trainingPlan,
      onTrainingPlanChange,
    };
  }, [
    props,
    handleDismiss,
    discardDialogProps,
    openDiscardDialog,
    dismissDiscardDialog,
    wizardStep,
    setWizardStep,
    trainingPlan,
    onTrainingPlanChange,
  ]);

  return {
    t,
    context,
  };
};
