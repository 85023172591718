import './index.css';
import { List } from './components/List';
import { useTranslation } from 'react-i18next';
import { Page } from '@cycling-web/common';
import { AthleteFilters } from './components/AthleteFilters';
import { useTitle } from '../../hooks/useTitle';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { useMobileMenu } from '../../components/MobileMenu';

export const Athletes = () => {
  const { t } = useTranslation();
  useTitle(t('label.athletes'));

  const onRenderMenu = useMobileMenu();

  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().athletes}
    >
      <Page pageHeaderProps={{ title: t('label.athletes'), onRenderMenu }}>
        <AthleteFilters />
        <List />
      </Page>
    </PageFiltersContextProvider>
  );
};
