import {
  createRef,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ITabsItem, ITabsProps } from './types';
import './index.css';
import { Tag } from '../Tag';
import { Tooltip } from '../Tooltip';
import { Spinner } from '../Spinner';
import { Ellipsis, Info } from 'lucide-react';
import { clsx } from 'clsx';
import { IconButton } from '../Button/IconButton';
import { Select } from '../Select';
import { ISelectBaseOption, ISelectOption } from '../Select/types';

export const Tabs = ({ items, onChange, content, className }: ITabsProps) => {
  const underlineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<RefObject<HTMLButtonElement | null>[]>([]);
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const tabsItemsRef = useRef<HTMLDivElement>(null);
  const [lastVisibleIndex, setLastVisibleIndex] = useState(-1);
  const [hiddenItems, setHiddenItems] = useState<ISelectOption[]>([]);

  const activeTab = useMemo(() => {
    return items.findIndex((tab: ITabsItem) => tab.isActive);
  }, [items]);

  const setLinePosition = useCallback((element: HTMLButtonElement) => {
    if (underlineRef.current) {
      const width: number = element.offsetWidth;
      const x: number = element.offsetLeft;

      underlineRef.current.style.left = `${x}px`;
      underlineRef.current.style.width = `${width}px`;
    }
  }, []);

  const calculateVisibleTabs = useCallback(() => {
    if (!tabsContainerRef.current || !tabRefs.current.length) {
      return;
    }

    const BUTTON_WIDTH = 40;
    const containerWidth = tabsContainerRef.current.clientWidth;
    let totalWidth = BUTTON_WIDTH;
    let visibleIndex = -1;

    for (let i = 0; i < tabRefs.current.length; i++) {
      const tabElement = tabRefs.current[i].current;
      if (tabElement) {
        totalWidth += tabElement.offsetWidth;

        if (totalWidth <= containerWidth) {
          visibleIndex = i;
        } else {
          break;
        }
      }
    }

    setHiddenItems(
      items.slice(visibleIndex + 1).map((item, i) => {
        return {
          id: item.path || i.toString(),
          text: item.text,
        };
      })
    );
    setLastVisibleIndex(visibleIndex);
  }, [items]);

  useLayoutEffect(() => {
    if (!tabsContainerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      calculateVisibleTabs();
    });

    resizeObserver.observe(tabsContainerRef.current);

    setTimeout(calculateVisibleTabs, 250);

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateVisibleTabs]);

  useEffect(() => {
    if (activeTab < 0 || items.length === 0) return;

    if (activeTab <= lastVisibleIndex) {
      const element = tabRefs.current[activeTab]?.current;
      if (element && underlineRef.current) {
        underlineRef.current.style.display = 'block';
        setLinePosition(element);
      }
    } else if (underlineRef.current) {
      underlineRef.current.style.display = 'none';
    }
  }, [activeTab, items, setLinePosition, lastVisibleIndex]);

  const handleChange = useCallback(
    (index: number) => (e: MouseEvent<HTMLButtonElement>) => {
      onChange(index);
      e.currentTarget.blur();
    },
    [onChange]
  );

  const itemsJSX = items.map((item: ITabsItem, index: number) => {
    if (!tabRefs.current[index]) {
      tabRefs.current[index] = createRef();
    }

    const itemClass: string = clsx(
      'analog-tabs__tab-item',
      'analog-typography--body bold',
      item.isActive && 'analog-tabs__tab-item--active',
      index > lastVisibleIndex && 'analog-tabs__tab-item--hidden'
    );

    return (
      <button
        key={index}
        className={itemClass}
        onClick={handleChange(index)}
        disabled={item.disabled}
        ref={tabRefs.current[index]}
      >
        {item.text}
        {!item.disabled && item.tooltipProps && (
          <div className="analog-tabs__tab-item-tooltip">
            <Tooltip
              {...item.tooltipProps}
              anchor={
                <Info className="analog-tabs__tab-tooltipIcon" size={20} />
              }
            />
          </div>
        )}
        {item.tag && (
          <div className="analog-tabs__tab-item-tag">
            <Tag
              size={item.tag.size}
              shape={item.tag.shape}
              variant={item.tag.variant}
              text={item.tag.text}
            />
          </div>
        )}
        {!item.disabled && item.loading && (
          <div className="analog-tabs__tab-item-spinner">
            <Spinner size="s" />
          </div>
        )}
      </button>
    );
  });

  const activeHiddenItem = useMemo(() => {
    if (activeTab > lastVisibleIndex && activeTab !== -1) {
      return hiddenItems.find(
        (item) => item.id === (items[activeTab].path || activeTab.toString())
      );
    }
    return undefined;
  }, [activeTab, lastVisibleIndex, hiddenItems, items]);

  const onRenderAnchor = useCallback(() => {
    return (
      <Tooltip
        anchor={<IconButton variant="quietLayer3" content={<Ellipsis />} />}
        content="More options"
        placement="bottom-end"
      />
    );
  }, []);

  const onSelectHiddenItem = useCallback(
    (option: ISelectBaseOption) => {
      const index = items.findIndex((item) => (item.path || '') === option.id);
      if (index > -1) {
        onChange(index);
      }
    },
    [items, onChange]
  );

  const rootClass: string = clsx('analog-tabs', className);
  const tabsContainerClass: string = clsx('analog-tabs__tabs');

  const contentJSX =
    content || (items[activeTab] ? items[activeTab].content : null);

  return (
    <div className={rootClass}>
      <div className={tabsContainerClass} ref={tabsContainerRef}>
        <div className="analog-tabs__tabs-items" ref={tabsItemsRef}>
          {itemsJSX}
          {hiddenItems.length > 0 && (
            <div className="analog-tabs__tabs-more">
              <Select
                options={hiddenItems}
                value={activeHiddenItem}
                onChange={onSelectHiddenItem}
                onRenderAnchor={onRenderAnchor}
                dropdownProps={{ placement: 'bottom-end', minWidth: '180px' }}
              />
            </div>
          )}
        </div>
        <div className="analog-tabs__tabs-divider" />
        <div className="analog-tabs__active-underline" ref={underlineRef} />
      </div>
      {contentJSX && <div className="analog-tabs__content">{contentJSX}</div>}
    </div>
  );
};
