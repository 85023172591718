import './index.css';
import { ReactNode } from 'react';
import { IMentionOption } from '../../types';
import { clsx } from 'clsx';
import { Tag, TruncatedText, Typography } from '@cycling-web/analog-ui';
import { Check } from 'lucide-react';

type IProps = {
  option: IMentionOption;
  isSelected: boolean;
  onSelect: () => void;
  onRenderOption?: (option: IMentionOption, selected: boolean) => ReactNode;
  index: number;
};

export const MentionMenuItem = ({
  option,
  isSelected,
  onSelect,
  onRenderOption,
  index,
}: IProps) => {
  const rootClass = clsx(
    'mention__option-wrapper',
    'analog-typography--body',
    isSelected && 'selected'
  );

  const optionClass = clsx(
    'mention__option',
    isSelected && 'mention__option--active'
  );

  const descriptionClass = clsx(
    'mention__option-description',
    'analog-typography--subtitle'
  );

  if (option.type === 'divider') {
    return (
      <div className="mention__option-divider">
        <div className="mention__option-dividerLine" />
      </div>
    );
  }

  if (option.type === 'heading') {
    return (
      <Typography
        as="div"
        className="mention__option-heading"
        variant="body"
        weight="bold"
        text={option.text}
        tabIndex={-1}
      />
    );
  }

  return (
    <li
      tabIndex={-1}
      role="option"
      aria-selected={isSelected}
      className={rootClass}
      onClick={onSelect}
      data-mention-index={index}
    >
      <div className={optionClass}>
        <div className="mention__option-left">
          {option.icon && (
            <div className="mention__option-icon">{option.icon}</div>
          )}
          <div className="mention__option-text analog-typography--body regular">
            {onRenderOption && !option.useDefaultRenderer ? (
              onRenderOption(option, isSelected)
            ) : (
              <TruncatedText text={option.text} />
            )}
          </div>
          {option.description && (
            <div className={descriptionClass}>{option.description}</div>
          )}
        </div>

        <div className="mention__option-right">
          {option.tag && <Tag {...option.tag}>{option.tag.text}</Tag>}
          <div className="mention__option-checkMark">
            {isSelected && (
              <Check className="mention__option-right-icon" size={16} />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
