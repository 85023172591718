import './index.css';
import { AnaHeader } from '../Header';
import { AnaContent } from '../Content';
import { ConversationContextProvider } from '../../../Conversation';
import { MathJaxContext } from 'better-react-mathjax';
import { AnaFooter } from '../Footer';
import { useAnaContext } from '../../context';
import { AnaTool } from '../Tool';
import { ContextEmitter } from '../ContextEmitter';

export const Ana = () => {
  const { onSocketMessage, activeTool } = useAnaContext();

  return (
    <ConversationContextProvider onSocketMessage={onSocketMessage}>
      <MathJaxContext>
        <div className="ana">
          <AnaHeader />
          <AnaContent />
          <AnaFooter />
        </div>
        {activeTool && <AnaTool />}
        <ContextEmitter />
      </MathJaxContext>
    </ConversationContextProvider>
  );
};
