import './index.css';
import { use, useCallback, useMemo } from 'react';
import {
  ConversationContext,
  ConversationInput,
  ConversationMessageSource,
  ConversationState,
  IAnaContentType,
  IAnaContextEntity,
  IConversationMessageAuthor,
  IConversationMessageBase,
  IConversationSendOptions,
} from '../../../Conversation';
import { ConversationRepository } from '../../../../api/conversation/repository';
import { ConversationService } from '../../../../api/conversation/service';
import { AnaContext } from '../../context';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { prepareAnaContextEntities } from '../../../../utils/conversation';

export const AnaFooter = () => {
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const { setConversationState, emit } = use(ConversationContext);
  const { addMessage, incrementSkip } = use(AnaContext);

  const conversationRepository = useMemo(() => {
    return new ConversationRepository(new ConversationService());
  }, []);

  const sendMessage = useCallback(
    (query: string, options?: IConversationSendOptions) => {
      trackUserInteractionEvent(TrackingEvent.CLICK, {
        action: TrackingAction.SEND_ANA_TEXT_MESSAGE,
      });

      setConversationState(ConversationState.PROCESSING);
      addMessage({
        id: Date.now().toString(),
        source: ConversationMessageSource.AnaCoach,
        author: IConversationMessageAuthor.User,
        text: '',
        content: [
          {
            content_id: Date.now().toString(),
            content_type: IAnaContentType.TEXT,
            text: query,
          },
        ],
      });

      const entities: IAnaContextEntity[] = prepareAnaContextEntities({
        mentions: options?.mentions || [],
      });

      incrementSkip();
      emit(query, entities);

      // TODO: Processing now happens in websockets. Remove this later.
      return Promise.resolve(undefined);
    },
    [setConversationState, addMessage, emit, incrementSkip]
  );

  const sendAudioMessage = useCallback(
    (base64: string): Promise<IConversationMessageBase | undefined> => {
      setConversationState(ConversationState.PROCESSING);
      return conversationRepository
        .generateTranscribeTicket()
        .then(({ ticket_id }) => {
          return conversationRepository.transcribeAudioMessage({
            ticket: ticket_id,
            audio_data: base64,
          });
        })
        .then(({ text }) => {
          return sendMessage(text);
        })
        .catch(() => {
          setConversationState(ConversationState.IDLE);
          return undefined;
        });
    },
    [conversationRepository, sendMessage, setConversationState]
  );

  return (
    <div className="ana__footer">
      <ConversationInput
        onSendText={sendMessage}
        onSendAudio={sendAudioMessage}
        variant="layer1"
      />
    </div>
  );
};
