import * as React from 'react';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { createPortal } from 'react-dom';
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_CRITICAL,
  KEY_DOWN_COMMAND,
  NodeKey,
} from 'lexical';
import { IMentionProps } from '../../types';
import { IMentionOption } from '../types';
import { $createMentionNode, MentionNode } from '../node/MentionNode';
import { MentionMenu } from '../components/MentionMenu';
import { MentionMenuItem } from '../components/MentionMenuItem';
import { useOutsideClick } from 'rooks';

export default function MentionPlugin({
  mentions,
  onMentionAdd,
  onMentionRemove,
}: IMentionProps) {
  const [editor] = useLexicalComposerContext();
  const [isOpen, setIsOpen] = useState(false);
  const [style, setStyle] = useState<CSSProperties>({});
  const [mentionQuery, setMentionQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activeTrigger, setActiveTrigger] = useState<string | null>(null);
  const previousMentionsRef = useRef(new Map<NodeKey, IMentionOption>());
  const menuRef = useRef<HTMLDivElement | null>(null);

  const mentionProps = activeTrigger ? mentions[activeTrigger] : null;

  const preparedOptions = useMemo(() => {
    if (!mentionProps?.options) {
      return [];
    }

    return mentionProps.options.map((option) => {
      return {
        ...option,
        text: option.text.trim(),
      };
    });
  }, [mentionProps?.options]);

  const filteredOptions = useMemo(() => {
    return preparedOptions.filter((option) => {
      if (option.type && option.type !== 'default') {
        return true;
      }

      return option.text.toLowerCase().includes(mentionQuery.toLowerCase());
    });
  }, [mentionQuery, preparedOptions]);

  const onRenderOption = mentionProps?.onRenderOption;

  useEffect(() => {
    setSelectedIndex(1);
  }, [filteredOptions]);

  const handleScrollOnArrowPress = useCallback((index: number) => {
    requestAnimationFrame(() => {
      const selectedItem = document.querySelector(
        `[data-mention-index="${index}"]`
      );
      if (selectedItem) {
        selectedItem.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    });
  }, []);

  const handleSelect = useCallback(
    (option: IMentionOption) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchor = selection.anchor;
          const focus = selection.focus;

          if (anchor.key === focus.key) {
            const node = selection.anchor.getNode();

            if (!$isTextNode(node)) {
              return;
            }

            const text = node.getTextContent();
            const triggerIndex = text.lastIndexOf(activeTrigger ?? '');

            if (triggerIndex !== -1) {
              node.setTextContent(text.slice(0, triggerIndex));
            }
          }

          selection.insertNodes([
            $createMentionNode(
              option.text,
              option.id.toString(),
              option.entity
            ),
          ]);
          selection.insertText(' ');
        }
      });

      setIsOpen(false);
      setActiveTrigger(null);
    },
    [editor, activeTrigger]
  );

  useEffect(() => {
    return editor.registerMutationListener(
      MentionNode,
      (mutations) => {
        mutations.forEach((mutation, key) => {
          if (mutation === 'created') {
            const node = editor.getElementByKey(key);

            if (node && onMentionAdd) {
              const option = {
                id: node.getAttribute('data-option-id') || '',
                text: node.textContent || '',
                entity: node.getAttribute('data-option-entity') || '',
              };

              previousMentionsRef.current.set(key, option);
              onMentionAdd(option);
            }
          } else if (mutation === 'destroyed') {
            if (onMentionRemove) {
              const option = previousMentionsRef.current.get(key);
              if (option && onMentionRemove) {
                onMentionRemove(option);
              }
              previousMentionsRef.current.delete(key);
            }
          }
        });
      },
      { skipInitialization: false }
    );
  }, [editor, onMentionAdd, onMentionRemove]);

  useEffect(() => {
    const triggers = Object.keys(mentions);

    const removeListener = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event) => {
        if (triggers.includes(event.key)) {
          setIsOpen(true);
          setActiveTrigger(event.key);
          // TODO: Quick solution
          setSelectedIndex(1);

          requestAnimationFrame(() => {
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
              const range = selection.getRangeAt(0);
              const rect = range.getBoundingClientRect();
              setStyle({
                position: 'absolute',
                top: rect.top,
                left: rect.left,
              });
              setMentionQuery('');
            }
          });
        } else if (isOpen) {
          if (event.key === 'ArrowDown') {
            setSelectedIndex((prev) => {
              let nextIndex = prev;

              do {
                nextIndex++;
              } while (
                nextIndex < filteredOptions.length &&
                filteredOptions[nextIndex].type !== undefined
              );

              handleScrollOnArrowPress(nextIndex);
              return nextIndex < filteredOptions.length ? nextIndex : prev;
            });

            event.preventDefault();
          } else if (event.key === 'ArrowUp') {
            setSelectedIndex((prev) => {
              let nextIndex = prev - 1;

              while (
                nextIndex >= 0 &&
                filteredOptions[nextIndex].type !== undefined
              ) {
                nextIndex--;
              }

              handleScrollOnArrowPress(nextIndex);
              return nextIndex >= 0 ? nextIndex : prev;
            });
            event.preventDefault();
          } else if (event.key === 'Enter' || event.key === 'Tab') {
            handleSelect(filteredOptions[selectedIndex]);
            event.preventDefault();
            return true;
          } else if (event.key === 'Escape') {
            setIsOpen(false);
            setActiveTrigger(null);
          } else {
            setTimeout(() => {
              const selection = window.getSelection();
              if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const container = range.startContainer;
                let text = '';

                if (container.nodeType === Node.TEXT_NODE) {
                  text = container.textContent || '';
                } else if (container instanceof HTMLElement) {
                  text = container.innerText;
                }

                if (activeTrigger) {
                  const triggerIndex = text.lastIndexOf(activeTrigger);
                  if (
                    triggerIndex === -1 ||
                    range.startOffset <= triggerIndex
                  ) {
                    setIsOpen(false);
                    setActiveTrigger(null);
                    return;
                  }

                  const query = text.slice(triggerIndex + 1, range.startOffset);
                  const spaceCount = (query.match(/ /g) || []).length;

                  if (spaceCount > 1) {
                    setIsOpen(false);
                    setActiveTrigger(null);
                    return;
                  }

                  setMentionQuery(query);
                }
              }
            });
          }
        }
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );

    return () => {
      removeListener();
    };
  }, [
    editor,
    mentions,
    isOpen,
    selectedIndex,
    activeTrigger,
    handleSelect,
    filteredOptions,
    handleScrollOnArrowPress,
  ]);

  useOutsideClick(menuRef, () => {
    setIsOpen(false);
  });

  if (!isOpen || !mentionProps) {
    return null;
  }

  return createPortal(
    <MentionMenu style={style} ref={menuRef}>
      {filteredOptions.map((option, i: number) => (
        <MentionMenuItem
          key={option.id || i}
          option={option}
          isSelected={i === selectedIndex}
          onSelect={() => handleSelect(option)}
          onRenderOption={onRenderOption}
          index={i}
        />
      ))}
    </MentionMenu>,
    document.body
  );
}
