import './index.css';
import { SummaryCard } from '../SummaryCard';
import { Activity, BriefcaseMedical, Gauge, HeartPulse } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useDashboardStore } from '../../store/slice';
import { useCallback, useContext, useMemo } from 'react';
import { IAthlete } from '../../../../types/athletes';
import { HealthSummaryModal } from '../HealthSummaryModal';
import { ILatestTrainingLoad } from '../../../../types/performance';
import { TsbSummaryModal } from '../TsbSummaryModal';
import { RecoverySummaryModal } from '../RecoverySummaryModal';
import { CheckInSummaryModal } from '../CheckInSummaryModal';
import { HrvRhrUtils } from '../../../../utils/hrv-rhr';
import { DashboardContext } from '../../context';
import { useSearchParams } from 'react-router';
import { URL_PARAMS } from '../../../../constants';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingEntities,
  TrackingEvent,
} from '../../../../ms/tracking-entities';

enum Card {
  Health = 'Health',
  CheckIn = 'CheckIn',
  Recovery = 'Recovery',
  TSBWatch = 'TSBWatch',
}

export const Summary = () => {
  const { t } = useTranslation();
  const { filterByGroupDelegate, filterAthletesDelegate } =
    useContext(DashboardContext);

  const _athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const filteredAthletes = useMemo(() => {
    return _athletes.filter(filterAthletesDelegate);
  }, [_athletes, filterAthletesDelegate]);

  const trainingLoad = useDashboardStore((s) => s.trainingLoad);
  const trainingLoadLoaded = useDashboardStore((s) => s.trainingLoadLoaded);

  const loading = !athletesLoaded || !trainingLoadLoaded;

  const [searchParams, setSearchParams] = useSearchParams();
  const modalParam = searchParams.get(URL_PARAMS.Modal) as Card | null;
  const openModal = useCallback(
    (name: Card) => () => {
      if (!loading) {
        trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
          modal: name,
        });

        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev);
          newParams.set(URL_PARAMS.Modal, name);
          return newParams;
        });
      }
    },
    [loading, setSearchParams]
  );

  const dismissModal = useCallback(() => {
    if (modalParam) {
      trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
        modal: modalParam,
      });
    }
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete(URL_PARAMS.Modal);
      return newParams;
    });
  }, [modalParam, setSearchParams]);

  const injuredAthletes = useMemo(() => {
    return filteredAthletes.filter((a: IAthlete) => {
      return !!a.medicalReport;
    });
  }, [filteredAthletes]);

  const subjectiveFeedbackAthletes = useMemo(() => {
    return filteredAthletes.filter((a: IAthlete) => {
      return a.selfReportedWellnessScore !== undefined;
    });
  }, [filteredAthletes]);

  const recoveryAthletes = useMemo(() => {
    return filteredAthletes.filter((a: IAthlete) => {
      return (
        HrvRhrUtils.getStatus({
          hrvVariance: a.hrvVariance,
          rhrVariance: a.rhrVariance,
        }) !== undefined
      );
    });
  }, [filteredAthletes]);

  const tsbWatchTrainingLoad = useMemo(() => {
    return trainingLoad
      .filter(filterByGroupDelegate)
      .filter((r: ILatestTrainingLoad) => {
        return r.tsb < -30 || r.tsb > 25;
      });
  }, [filterByGroupDelegate, trainingLoad]);

  return (
    <div className="dashboard-summary">
      <SummaryCard
        title={t('label.health')}
        icon={<BriefcaseMedical />}
        count={injuredAthletes.length}
        total={filteredAthletes.length}
        loading={loading}
        onClick={openModal(Card.Health)}
      />
      <SummaryCard
        title={t('label.check_in')}
        icon={<Gauge />}
        count={subjectiveFeedbackAthletes.length}
        total={filteredAthletes.length}
        loading={loading}
        onClick={openModal(Card.CheckIn)}
      />
      <SummaryCard
        title={t('label.recovery')}
        icon={<HeartPulse />}
        count={recoveryAthletes.length}
        total={filteredAthletes.length}
        loading={loading}
        onClick={openModal(Card.Recovery)}
      />
      <SummaryCard
        title={t('label.tsb_watch')}
        icon={<Activity />}
        count={tsbWatchTrainingLoad.length}
        total={filteredAthletes.length}
        loading={loading}
        onClick={openModal(Card.TSBWatch)}
      />

      {modalParam === Card.Health && (
        <HealthSummaryModal
          onDismiss={dismissModal}
          athletes={injuredAthletes}
        />
      )}
      {modalParam === Card.CheckIn && (
        <CheckInSummaryModal
          onDismiss={dismissModal}
          athletes={subjectiveFeedbackAthletes}
        />
      )}
      {modalParam === Card.Recovery && (
        <RecoverySummaryModal
          onDismiss={dismissModal}
          athletes={recoveryAthletes}
        />
      )}
      {modalParam === Card.TSBWatch && (
        <TsbSummaryModal
          onDismiss={dismissModal}
          trainingLoad={tsbWatchTrainingLoad}
        />
      )}
    </div>
  );
};
