import './index.css';
import { clsx } from 'clsx';
import {
  ConversationState,
  IAnaAdminContent,
  IAnaContentType,
  IAnaMessageContent,
  IAnaThoughtContent,
  IConversationMessage,
  IConversationMessageAuthor,
} from '../../types';
import { ReactNode, use } from 'react';
import { parseMessage } from './parseMessage';
import { PlayMessageButton } from '../PlayMessageButton';
import { ConversationContext } from '../../context/ConversationContext';
import { MessageContent } from './MessageContent';
import { MessagePart } from './MessagePart';
import { Thoughts } from './Thoughts';
import { AdminMessage } from './AdminMessage';

type IProps = {
  message: IConversationMessage;
  variant?: 'canvas' | 'layer1';
  /** TODO: Perhaps can be part of Message component*/
  adornmentEnd?: ReactNode;
  allowPlay?: boolean;
  isLast?: boolean;
  // For debugging
  streaming?: boolean;
};

export const Message = ({
  message,
  variant = 'layer1',
  adornmentEnd,
  allowPlay = true,
  isLast = false,
  streaming = false,
}: IProps) => {
  const { playingMessage, conversationState } = use(ConversationContext);

  const isUser = message.author === IConversationMessageAuthor.User;

  const rootClass = clsx(
    'conversation-message',
    playingMessage?.id === message.id && 'conversation-message--active',
    `conversation-message--${variant}`,
    isUser ? 'conversation-message--right' : 'conversation-message--left',
    'analog-typography--body',
    streaming && 'conversation-message--streaming'
  );

  let partsJSX: ReactNode;
  const adminContents: IAnaThoughtContent[] = [];
  const thoughtContents: IAnaThoughtContent[] = [];
  const otherContents: IAnaMessageContent[] = [];

  if (message.text) {
    const parts = parseMessage(message.text);
    partsJSX = parts.map((part, index: number) => {
      return <MessagePart key={`wrapper-${index}`} index={index} part={part} />;
    });
  }

  if (message.content) {
    for (let i = 0; i < message.content.length; i++) {
      if (message.content[i].content_type === IAnaContentType.THOUGHT) {
        if (streaming) {
          thoughtContents.push(message.content[i] as IAnaThoughtContent);
        }
      } else if (message.content[i].content_type === IAnaContentType.ADMIN) {
        adminContents.push(message.content[i] as IAnaAdminContent);
      } else {
        otherContents.push(message.content[i]);
      }
    }

    partsJSX = (
      <div className="conversation-message__content-parts">
        {otherContents.map((content) => (
          <MessageContent key={content.content_id} content={content} />
        ))}
      </div>
    );
  }

  if (
    otherContents.length === 0 &&
    thoughtContents.length === 0 &&
    adminContents.length === 0
  ) {
    return null;
  }

  const isThinking =
    conversationState === ConversationState.PROCESSING ||
    (conversationState === ConversationState.STREAMING &&
      thoughtContents.length > 0);

  return (
    <div className={rootClass}>
      <div className="conversation-message__content">
        <div className="conversation-message__content-wrapper">
          {isThinking && <Thoughts items={thoughtContents} />}
          {adminContents.length > 0 && (
            <AdminMessage contents={adminContents} />
          )}
          {otherContents.length > 0 && partsJSX}
        </div>
      </div>
      {message.id && (
        <div className="conversation-message__actions">
          {allowPlay && !isUser && <PlayMessageButton message={message} />}
          {adornmentEnd}
        </div>
      )}
    </div>
  );
};
