import { useCallback, useMemo, useRef, useState } from 'react';
import { ConversationRepository } from '../../../api/conversation/repository';
import { ConversationService } from '../../../api/conversation/service';
import { IConversationPagination } from '../types';
import { IAnaHistory } from '../../Conversation';
import { useAnaAssistantStore } from '../store/slice';
import { PAGINATION_SIZE } from '../../../constants';

export const useFetchPaginatedHistory = () => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const abortController = useRef<AbortController | null>(null);
  const fetchingRef = useRef<boolean>(false);
  const doneRef = useRef<boolean>(false);

  const conversationRepository = useMemo(() => {
    return new ConversationRepository(new ConversationService(), {
      abort: true,
    });
  }, []);

  const fetchConversationHistory = useCallback(
    (pagination: IConversationPagination) => {
      if (doneRef.current || fetchingRef.current) {
        return Promise.resolve();
      }

      fetchingRef.current = true;
      setIsPageLoading(true);

      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();
      const signal = abortController.current.signal;

      return conversationRepository
        .getHistory(pagination, { signal })
        .then((response: IAnaHistory) => {
          if (
            response.turns.length === 0 ||
            response.turns.length < pagination.turn_take
          ) {
            doneRef.current = true;
          }

          pagination.turn_skip = pagination.turn_skip + PAGINATION_SIZE;

          useAnaAssistantStore.getState().prependMessages(response);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          fetchingRef.current = false;
          setIsPageLoading(false);
        });
    },
    [conversationRepository]
  );

  return {
    fetchConversationHistory,
    isPageLoading,
  };
};
