import './index.css';
import { useTrainingPlanContext } from '../../context';
import { Radio, Typography } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import { IFitnessGoal } from '../../types';
import { FitnessGoal } from './FitnessGoal';
import { useFitnessGoals } from '../../hooks/useFitnessGoals';

export const Fitness = () => {
  const { trainingPlan, onTrainingPlanChange } = useTrainingPlanContext();
  const fitnessGoals: IFitnessGoal[] = useFitnessGoals();

  const onFitnessGoalChange = useCallback(
    (fitnessGoal: IFitnessGoal) => {
      onTrainingPlanChange({
        fitnessGoal,
      });
    },
    [onTrainingPlanChange]
  );

  // ---------------------------------------------------------------------------

  const fitnessGoalsJSX = fitnessGoals.map((goal) => {
    const onRenderLabel = () => {
      return <Typography variant="subtitle" text={goal.text} />;
    };

    return (
      <Radio
        key={goal.id}
        name="goal"
        className="training-plan__fitness-goal"
        onRenderLabel={onRenderLabel}
        value={goal.id}
        checked={goal.id === trainingPlan?.fitnessGoal?.id}
        onChange={() => onFitnessGoalChange(goal)}
      />
    );
  });

  return (
    <div className="training-plan__fitness">
      <div className="training-plan__fitness-goals">{fitnessGoalsJSX}</div>
      <FitnessGoal />
    </div>
  );
};
