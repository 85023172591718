import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Datepicker,
  Divider,
  FormElement,
  ISegmentButton,
  Segment,
  Switch,
} from '@cycling-web/analog-ui';
import {
  DatepickerControl,
  FormColumn,
  FormRow,
  TextareaControl,
} from '@cycling-web/common';
import { WorkoutsRepository } from '../../../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../../../api/workouts/service';
import { IDayOffForm } from './types';
import { useWorkoutsCalendarContext } from '../../../../context';
import { useCalendarAddContext } from '../../context';
import { ICreateRuleRequest } from '../../../../../../api/workouts/types';
import { localWithUTCMidnightString } from '../../../../../../utils/date-time';
import {
  ICalendarRuleRecurringType,
  ICalendarRuleWeek,
} from '../../../../../../types/workouts';
import { useFetchDayOffs } from '../../../../hooks/useFetchDayOffs';
import { addDays } from 'date-fns';
import { DayOfWeekControl } from './DayOfWeekControl';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingForm,
} from '../../../../../../ms/tracking-entities';

export const DayOffForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { athlete, date } = useWorkoutsCalendarContext();
  const { onDismiss, handleDismiss, defaultDayOff, defaultDate } =
    useCalendarAddContext();
  const { fetchDayOffs } = useFetchDayOffs();

  const [isOneDay, setIsOneDay] = useState<boolean>(() => {
    if (
      defaultDayOff &&
      defaultDayOff.recurrenceType === ICalendarRuleRecurringType.DAILY &&
      defaultDayOff.startDate !== defaultDayOff.endDate
    ) {
      return false;
    }
    return true;
  });
  const toggleOneDay = useCallback(() => {
    setIsOneDay((f) => !f);
  }, []);

  const [activeRecurrenceType, setActiveRecurrenceType] = useState<number>(
    () => {
      return defaultDayOff &&
        defaultDayOff.recurrenceType === ICalendarRuleRecurringType.WEEKLY &&
        defaultDayOff.startDate !== defaultDayOff.endDate
        ? 1
        : 0;
    }
  );
  const isRecurring = activeRecurrenceType === 1;

  const recurrenceButtons = useMemo((): ISegmentButton[] => {
    return [
      {
        id: 'once',
        text: t('label.once'),
      },
      {
        id: 'recurring',
        text: t('label.recurring'),
      },
    ];
  }, [t]);

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const schema = useMemo(() => {
    return z.object({}).passthrough();
  }, []);

  const form = useForm<IDayOffForm>({
    defaultValues: defaultDayOff || {
      startDate: localWithUTCMidnightString(
        defaultDate || addDays(new Date(), 1)
      ),
      endDate: localWithUTCMidnightString(
        defaultDate || addDays(new Date(), 1)
      ),
      description: '',
      recurrenceType: ICalendarRuleRecurringType.WEEKLY,
      dayOfWeek: ICalendarRuleWeek.MONDAY,
      dayOfMonth: 1,
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = form;

  const [startDate, recurrenceType] = watch(['startDate', 'recurrenceType']);

  const handleSave = useCallback(
    (formData: IDayOffForm) => {
      if (!athlete) {
        return;
      }

      setLoading(true);

      const payload: ICreateRuleRequest = {
        ...formData,
        startDate: localWithUTCMidnightString(new Date(formData.startDate)),
        endDate: localWithUTCMidnightString(new Date(formData.endDate)),
        athleteId: athlete.id,
      };

      if (!isRecurring) {
        delete payload.dayOfWeek;
        delete payload.dayOfMonth;
        payload.recurrenceType = ICalendarRuleRecurringType.DAILY;
        if (isOneDay) {
          payload.endDate = payload.startDate;
        }
      }

      if (formData.recurrenceType !== ICalendarRuleRecurringType.WEEKLY) {
        delete payload.dayOfWeek;
      }

      if (formData.recurrenceType !== ICalendarRuleRecurringType.MONTHLY) {
        delete payload.dayOfMonth;
      }

      const request =
        formData.id === undefined
          ? workoutsRepository.createCalendarRule(payload)
          : workoutsRepository.updateCalendarRule(payload);

      return request
        .then(() => {
          return fetchDayOffs(date, athlete.id.toString());
        })
        .then(() => {
          onDismiss();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      athlete,
      isRecurring,
      workoutsRepository,
      isOneDay,
      fetchDayOffs,
      date,
      onDismiss,
    ]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.WORKOUT_DAY_OFF,
    });

    handleSubmit(
      (formData: IDayOffForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  const onRecurrenceChange = useCallback(
    (index: number) => {
      setActiveRecurrenceType(index);
      if (index === 0) {
        setValue('recurrenceType', ICalendarRuleRecurringType.WEEKLY);
      } else {
        setValue('endDate', localWithUTCMidnightString(addDays(startDate, 1)));
        setValue('recurrenceType', ICalendarRuleRecurringType.WEEKLY);
      }
    },
    [setValue, startDate]
  );

  const onStartDateChange = useCallback(
    (date: Date) => {
      const newStartDate = localWithUTCMidnightString(date);
      const currentEndDate = getValues('endDate');

      setValue('startDate', newStartDate);

      if (isRecurring) {
        if (!currentEndDate || new Date(currentEndDate) < date) {
          setValue('endDate', localWithUTCMidnightString(addDays(date, 1)));
        }
      } else {
        if (!currentEndDate || new Date(currentEndDate) < date) {
          setValue('endDate', newStartDate);
        }
      }
    },
    [setValue, getValues, isRecurring]
  );

  return (
    <FormProvider {...form}>
      <div className="day-off-form">
        <FormRow>
          <FormColumn>
            <FormElement
              label={
                isOneDay && !isRecurring
                  ? t('label.date')
                  : t('label.start_date')
              }
            >
              <Datepicker
                placeholder={t('placeholder.date')}
                min={new Date()}
                value={new Date(startDate)}
                onChange={onStartDateChange}
              />
            </FormElement>
          </FormColumn>
          {!isOneDay && (
            <FormColumn>
              <FormElement label={t('label.end_date')}>
                <DatepickerControl
                  name="endDate"
                  placeholder={t('placeholder.date')}
                  min={new Date(startDate)}
                />
              </FormElement>
            </FormColumn>
          )}
        </FormRow>

        <FormRow>
          <Switch
            checked={isOneDay}
            onChange={toggleOneDay}
            label={t('label.one_day')}
          />
        </FormRow>

        <Divider />

        {isOneDay && (
          <FormRow>
            <Segment
              buttons={recurrenceButtons}
              onChange={onRecurrenceChange}
              activeButton={activeRecurrenceType}
            />
          </FormRow>
        )}

        {isOneDay && isRecurring && (
          <>
            {recurrenceType === ICalendarRuleRecurringType.WEEKLY && (
              <FormElement label={t('label.repeat_on')}>
                <DayOfWeekControl />
              </FormElement>
            )}
          </>
        )}
        {isOneDay && isRecurring && (
          <FormRow>
            <FormColumn>
              <FormElement label={t('label.end_date')}>
                <DatepickerControl
                  name="endDate"
                  placeholder={t('placeholder.date')}
                />
              </FormElement>
            </FormColumn>
          </FormRow>
        )}

        <FormElement
          label={t('label.description')}
          message={errors.description?.message}
        >
          <TextareaControl
            name="description"
            placeholder={t('placeholder.description')}
            invalid={!!errors.description}
          />
        </FormElement>

        <footer className="day-off-form__footer">
          <Button
            variant="quietLayer3"
            content={t('action.cancel')}
            onClick={handleDismiss}
          />
          <Button
            content={defaultDayOff ? t('action.save') : t('action.add')}
            onClick={onSubmit}
            loading={loading}
          />
        </footer>
      </div>
    </FormProvider>
  );
};
