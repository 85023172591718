export const getEventName = (eventName: string): string => {
  const eventMap: Record<string, string> = {
    onClick: 'click',
    onDoubleClick: 'dblclick',
    onMouseDown: 'mousedown',
    onMouseMove: 'mousemove',
    onMouseUp: 'mouseup',
    onMouseOver: 'mouseover',
    onMouseOut: 'mouseout',
    onGlobalOut: 'globalout',
    onContextMenu: 'contextmenu',
    onLegendSelectChanged: 'legendselectchanged',
    onRendered: 'rendered',
    onFinished: 'finished',
    onGlobalMove: 'mousemove',
  };
  return eventMap[eventName];
};
