import './index.css';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@cycling-web/analog-ui';
import { X } from 'lucide-react';
import { useAnaContext } from '../../context';

export const AnaHeader = () => {
  const { t } = useTranslation();
  const { dismissAna } = useAnaContext();

  return (
    <header className="ana-header">
      <Typography
        weight="bold"
        className="ana-header__title"
        text={t('label.ana')}
      />
      <div className="ana-header__right">
        <Tooltip
          anchor={
            <IconButton
              size="s"
              variant="quietLayer3"
              content={<X />}
              onClick={dismissAna}
            />
          }
          content={t('tooltip.close')}
          placement="bottom-end"
        />
      </div>
    </header>
  );
};
