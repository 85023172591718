import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

type IProps = {
  content: string;
};

export const MarkdownPart = ({ content }: IProps) => {
  const customRendered = {
    table(props: any) {
      return (
        <div className="conversation-message__table-wrapper">
          <table className="conversation-message__table" {...props} />
        </div>
      );
    },

    code({ node, inline, className, children, ...props }: any) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        // @ts-ignore
        <SyntaxHighlighter
          style={darcula}
          language={match[1]}
          PreTag="div"
          className="code-block"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={customRendered}>
      {content}
    </ReactMarkdown>
  );
};
