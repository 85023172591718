import './index.css';
import { TrainingPlanFooter } from '../Footer';
import { EventAndGoals } from '../EventAndGoals';
import { Availability } from '../Availability';
import { Overview } from '../Overview';
import { useTrainingPlanContext } from '../../context';
import { TrainingPlanWizardStep } from '../../types';

export const TrainingPlanMain = () => {
  const { wizardStep } = useTrainingPlanContext();

  return (
    <main className="training-plan__main">
      {wizardStep === TrainingPlanWizardStep.Step1 && <EventAndGoals />}
      {wizardStep === TrainingPlanWizardStep.Step2 && <Availability />}
      {wizardStep === TrainingPlanWizardStep.Step3 && <Overview />}
      <TrainingPlanFooter />
    </main>
  );
};
