import type { ITruncatedTextProps } from './types';
import './index.css';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import { clsx } from 'clsx';
import debounce from 'lodash/debounce';

export const TruncatedText = ({
  text,
  className,
  tooltipProps,
  timeout = 0,
  typographyProps,
  lines = 1,
}: ITruncatedTextProps) => {
  const rootClass: string = clsx(
    'analog-truncated-text',
    lines > 1 && 'analog-truncated-text--multi-line',
    className
  );

  const ref = useRef<HTMLDivElement>(null);
  const [withTooltip, setWithTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) return;

    const handleResize = debounce(() => {
      if (!ref.current) return;

      const isOverflowing =
        lines === 1
          ? ref.current.scrollWidth > ref.current.clientWidth
          : ref.current.scrollHeight > ref.current.clientHeight;

      setWithTooltip(isOverflowing);
    }, timeout);

    handleResize();

    const observer = new ResizeObserver(handleResize);
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      handleResize.cancel();
    };
  }, [timeout, lines, text]);

  return (
    <div
      className={rootClass}
      ref={ref}
      style={lines > 1 ? { WebkitLineClamp: lines } : {}}
    >
      {text}
      {withTooltip && (
        <Tooltip
          {...tooltipProps}
          placement={tooltipProps?.placement || 'bottom-start'}
          delay={
            tooltipProps?.delay || {
              open: 500,
              close: 0,
            }
          }
          showArrow={tooltipProps?.showArrow || false}
          contentClassName={tooltipProps?.contentClassName}
          content={
            tooltipProps?.content || (
              <Typography
                {...typographyProps}
                variant={typographyProps?.variant || 'subtitle'}
                text={text}
              />
            )
          }
          portal
          anchor={<div className="analog-truncated-text__tooltip-anchor" />}
        />
      )}
    </div>
  );
};
