import { useEffect, useRef } from 'react';
import { useFetchAthleteMedicalReport } from './hooks/useFetchAthleteMedicalReport';
import { useAthleteMedicalReportStore } from './store/slice';
import { LS } from '../../constants';
import { useNavigate, useParams } from 'react-router';

export const useViewModel = () => {
  const reportLoaded = useAthleteMedicalReportStore((s) => s.reportLoaded);
  const report = useAthleteMedicalReportStore((s) => s.report);
  const reset = useAthleteMedicalReportStore((s) => s.reset);
  const setReport = useAthleteMedicalReportStore((s) => s.setReport);
  const setReportLoaded = useAthleteMedicalReportStore(
    (s) => s.setReportLoaded
  );
  useFetchAthleteMedicalReport();

  const { athleteId } = useParams();
  const navigate = useNavigate();
  const prevAthleteIdRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (
      prevAthleteIdRef.current !== undefined &&
      prevAthleteIdRef.current !== athleteId
    ) {
      const tmp = window.location.pathname.split('/');
      tmp.pop();
      const url = tmp.join('/');
      navigate(url);
    }

    prevAthleteIdRef.current = athleteId;
  }, [athleteId, navigate]);

  useEffect(() => {
    return () => {
      setReport(null);
      setReportLoaded(false);

      localStorage.removeItem(LS.PreviousPage);
    };
  }, [setReport, setReportLoaded]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return {
    reportLoaded,
    report,
  };
};
