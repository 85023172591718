import './index.css';
import { useCallback, useMemo } from 'react';
import { ChartWrapper } from '../../ChartWrapper';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IChartSource,
  IEChartOption,
} from '../../Chart';
import { handleChartMouseOver } from '../../Chart/utils/chartTracking';
import { ChartAxisName, ChartTrackingNames } from '../../../constants/charts';
import { hoursToHHMMSS } from '../../../utils/date-time';

type IProps = {
  source: IChartSource;
  color?: string;
  loading?: boolean;
};

export const TimeInZonesChart = ({ source, loading, color }: IProps) => {
  const onRenderTooltipValue = useCallback((value: number) => {
    return hoursToHHMMSS(value);
  }, []);

  const getStepSize = useCallback((maxValue: number) => {
    if (maxValue > 4000) return 1000;
    if (maxValue > 1600) return 500;
    if (maxValue > 1000) return 200;
    if (maxValue > 400) return 100;
    if (maxValue > 160) return 50;
    if (maxValue > 100) return 20;
    if (maxValue > 50) return 10;
    if (maxValue > 20) return 5;
    if (maxValue > 10) return 4;
    if (maxValue > 5) return 2;
    if (maxValue > 1) return 0.5;
    return 0.25;
  }, []);

  const maxYValue = useMemo(() => {
    let max = 0;
    for (let i = 1; i < source.length; i++) {
      const n = Number(source[i][1]);
      if (!isNaN(n) && n > max) {
        max = n;
      }
    }
    return max;
  }, [source]);

  const option: IEChartOption = useMemo((): IEChartOption => {
    const stepSize = getStepSize(maxYValue);

    return {
      dataset: {
        source,
      },
      grid: {
        left: 40,
        right: 0,
        bottom: 40,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            return value.split(' ').join('\n');
          },
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: [
        {
          type: 'value',
          min: 0,
          interval: stepSize,
          position: 'left',
          axisPointer: {
            show: true,
            label: {
              show: false,
            },
          },
          axisLabel: {
            formatter: function (value) {
              if (value === 0) {
                return '';
              }

              if (value < 1) {
                return `${Math.floor(value * 60)}m`;
              }

              return `${value}h`;
            },
          },
        },
      ],
      series: [
        {
          name: ChartAxisName.Time,
          type: 'bar',
          yAxisIndex: 0,
          itemStyle: {
            color: (params) => {
              if (color) {
                return params.dataIndex % 2 === 0
                  ? ChartColor[`Light${color}`]
                  : ChartColor[color];
              }

              return params.dataIndex % 2 === 0
                ? ChartColor.LightBlue
                : ChartColor.Blue;
            },
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [color, onRenderTooltipValue, source, getStepSize, maxYValue]);

  return (
    <ChartWrapper className="time-in-zones-chart" minHeight="292px">
      <Chart
        headerProps={{
          title: '',
          show: false,
        }}
        legendProps={{
          show: false,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.TimeInZones,
          }),
        }}
      />
    </ChartWrapper>
  );
};
