import './index.css';
import {
  ISmartChartTurnSuggestion,
  SmartChartActor,
} from '../../../../types/smart-charts';
import { Button } from '@cycling-web/analog-ui';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';
import { useCallback, useMemo } from 'react';
import { useSmartChartsStore } from '../../store/slice';
import { useSmartChartsContext } from '../../context';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';

export const Suggestions = () => {
  const config = useSmartChartsStore((s) => s.config);
  const lastMessage = useMemo(() => {
    const messages = config?.conversation_turns.turns || [];
    return messages.at(-1);
  }, [config?.conversation_turns.turns]);
  const { sendMessage } = useSmartChartsContext();

  const baseUrl = useBaseUrl();

  const handleSendMessage = useCallback(
    (suggestion: any) => {
      trackUserInteractionEvent(TrackingEvent.CLICK, {
        action: TrackingAction.APPLY_SUGGESTION,
      });

      if (suggestion?.suggestion_type === 'web_url') {
        const url = `${baseUrl}/${suggestion.relative_web_url}}`;

        window.open(url, '_blank');
      } else {
        sendMessage(suggestion?.suggestion_text);
      }
    },
    [sendMessage, baseUrl]
  );

  if (!lastMessage) {
    return null;
  }

  const isUser = lastMessage.actor === SmartChartActor.User;
  const showSuggestions = !isUser && lastMessage.next_suggestions?.length > 0;

  if (!showSuggestions) {
    return null;
  }

  const suggestionsJSX = lastMessage.next_suggestions?.map(
    (suggestion: ISmartChartTurnSuggestion, i: number) => {
      return (
        <Button
          key={i}
          variant="secondary"
          size="xs"
          content={suggestion?.suggestion_text}
          onClick={() => handleSendMessage(suggestion)}
        />
      );
    }
  );

  return <div className="sc2__suggestions">{suggestionsJSX}</div>;
};
