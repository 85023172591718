import { createContext, ReactNode } from 'react';
import { IConversationMessageBase, IVoiceRecordingContext } from '../types';
import { useVoiceRecording } from './useVoiceRecording';

export const VoiceRecordingContext = createContext<IVoiceRecordingContext>(
  {} as IVoiceRecordingContext
);

type IProps = {
  children: ReactNode;
  onSendAudio?: (
    base64: string
  ) => Promise<IConversationMessageBase | undefined>;
};

export const VoiceRecordingContextProvider = ({
  onSendAudio,
  children,
}: IProps) => {
  const { context } = useVoiceRecording({
    onSendAudio,
  });
  return (
    <VoiceRecordingContext value={context}>{children}</VoiceRecordingContext>
  );
};
