import { IAnaAssistantSlice, IAnaAssistantState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ConversationMessageSource,
  IAnaContentType,
  IAnaHistory,
  IAnaMessageContent,
  IAnaStreamMessage,
  IAnaTextContent,
  IAnaThoughtContent,
  IAnaToolUseContent,
  IConversationMessage,
  IConversationMessageAuthor,
} from '../../Conversation';
import { mergeStreamDeltas } from '../../Conversation/utils/deltas';

export const initialState: IAnaAssistantState = {
  messages: [],
  streamingMessages: {},
  activeTool: null,
};

export const useAnaAssistantStore = create<IAnaAssistantSlice>()(
  immer((set) => ({
    ...initialState,
    addMessage: (message) =>
      set((state) => {
        state.messages.push(message);
      }),
    prependMessages: (history: IAnaHistory) =>
      set((state) => {
        const messages: IConversationMessage[] = history.turns
          .reverse()
          .map((turn) => {
            return {
              id: turn.id,
              source: ConversationMessageSource.AnaCoach,
              author:
                turn.actor === 'user'
                  ? IConversationMessageAuthor.User
                  : IConversationMessageAuthor.Assistant,
              text: '',
              content: turn.content,
            };
          });

        state.messages = [...messages, ...state.messages];
      }),
    updateStream: (message: IAnaStreamMessage) =>
      set((state) => {
        const messageId = message.id;
        const contentType = message.content.content_type as
          | IAnaContentType.ADMIN
          | IAnaContentType.TEXT
          | IAnaContentType.TOOL_USE
          | IAnaContentType.THOUGHT;

        const conversationMessage = state.streamingMessages[messageId] || {
          id: messageId,
          source: ConversationMessageSource.AnaCoach,
          author: message.actor,
          text: '',
          content: [],
        };

        if (!conversationMessage.content) {
          conversationMessage.content = [];
        }

        if (
          contentType === IAnaContentType.TEXT ||
          contentType === IAnaContentType.ADMIN
        ) {
          const messageContent = message.content as IAnaTextContent;

          const contentIndex = conversationMessage.content.findIndex((c) => {
            return c.content_id === messageContent.content_id;
          });

          if (contentIndex < 0) {
            conversationMessage.content.push(messageContent);
          } else {
            if (conversationMessage.content[contentIndex]) {
              conversationMessage.content[contentIndex] =
                mergeStreamDeltas<IAnaMessageContent>(
                  messageContent,
                  conversationMessage.content[contentIndex],
                  { keysToUpdate: ['text'] }
                ) as IAnaMessageContent;
            }
          }
        }

        if (contentType === IAnaContentType.TOOL_USE) {
          const content = message.content as IAnaToolUseContent;
          content.loading = true;

          let contentIndex = conversationMessage.content.findIndex((c) => {
            return c.content_id === content.content_id;
          });

          if (contentIndex < 0) {
            conversationMessage.content.push(content);
            contentIndex = conversationMessage.content.length - 1;
          } else {
            if (conversationMessage.content[contentIndex]) {
              conversationMessage.content[contentIndex] =
                mergeStreamDeltas<IAnaMessageContent>(
                  content,
                  conversationMessage.content[contentIndex],
                  { keysToUpdate: ['text', 'title'] }
                ) as IAnaMessageContent;
            }
          }

          if (content.content_id === state.activeTool?.content_id) {
            state.activeTool = conversationMessage.content[
              contentIndex
            ] as IAnaToolUseContent;
          }
        }

        if (contentType === IAnaContentType.THOUGHT) {
          const content = message.content as IAnaThoughtContent;
          content.loading = true;

          const contentIndex = conversationMessage.content.findIndex((c) => {
            return c.content_id === content.content_id;
          });

          if (contentIndex < 0) {
            conversationMessage.content.push(content);
          } else {
            if (conversationMessage.content[contentIndex]) {
              conversationMessage.content[contentIndex] =
                mergeStreamDeltas<IAnaMessageContent>(
                  content,
                  conversationMessage.content[contentIndex],
                  { keysToUpdate: ['text', 'title'] }
                ) as IAnaMessageContent;
            }
          }
        }

        state.streamingMessages[messageId] = conversationMessage;
      }),
    completeStream: (messageId: string) =>
      set((state) => {
        const completedMessage = state.streamingMessages[messageId];

        if (completedMessage) {
          state.messages.push(completedMessage);
          delete state.streamingMessages[messageId];
        }
      }),
    completeStreamContent: (messageId: string, contentId: string) =>
      set((state) => {
        const contents = state.streamingMessages[messageId]?.content;
        const index =
          contents?.findIndex((c) => {
            return c.content_id === contentId;
          }) ?? -1;
        if (contents && index >= 0) {
          contents[index].loading = false;
        }
      }),
    setActiveTool: (activeTool: IAnaToolUseContent | null) =>
      set((state) => {
        state.activeTool = activeTool;
      }),
    reset: () => {
      set(initialState);
    },
  }))
);
