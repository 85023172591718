import { IAnaToolName, IAnaToolUseContent } from '../../types';
import { Spinner, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useAnaContext } from '../../../Ana/context';
import { useCallback } from 'react';

type IProps = {
  content: IAnaToolUseContent;
};

export const ContentTool = ({ content }: IProps) => {
  const { t } = useTranslation();
  const { openActiveTool } = useAnaContext();

  const toolName: Record<IAnaToolName, string> = {
    [IAnaToolName.GRAPH_DISPLAY]: t('label.chart'),
    [IAnaToolName.TEXT_CANVAS_DISPLAY]: t('label.text'),
    [IAnaToolName.DEEP_APP_NAVIGATION]: t('label.navigation'),
  };

  const handleOpenTool = useCallback(() => {
    if (content.tool_name !== IAnaToolName.DEEP_APP_NAVIGATION) {
      openActiveTool(content);
    }
  }, [content, openActiveTool]);

  return (
    <div className="conversation-message__tool" onClick={handleOpenTool}>
      <div className="conversation-message__tool-left">
        {content.title && (
          <Typography
            className="conversation-message__tool-title"
            weight="bold"
            text={content.title}
          />
        )}
        <Typography
          className="conversation-message__tool-name"
          variant="caption"
          text={toolName[content.tool_name]}
        />
      </div>

      {content.loading && (
        <div className="conversation-message__tool-right">
          <Spinner />
        </div>
      )}
    </div>
  );
};
