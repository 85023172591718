import './index.css';
import { useEffect, useMemo, useRef } from 'react';
import { useSmartChartsStore } from '../../store/slice';
import {
  ISmartChartTurn,
  SmartChartActor,
} from '../../../../types/smart-charts';
import { Suggestions } from '../Suggestions';
import { useSmartChartsContext } from '../../context';
import { MathJaxContext } from 'better-react-mathjax';
import {
  ConversationMessageSource,
  IAnaContentType,
  IConversationMessageAuthor,
  Message,
  MessageLoading,
} from '../../../../components/Conversation';

export const ChatBody = () => {
  const config = useSmartChartsStore((s) => s.config);
  const processingBufferMessage = useSmartChartsStore(
    (s) => s.processingBufferMessage
  );
  const loadingText =
    processingBufferMessage?.turn_subprocesses.at(-1)?.heading.value;
  const { expanded } = useSmartChartsContext();

  const messages = useMemo(() => {
    const messages = config?.conversation_turns.turns || [];
    const sorted = [...messages];
    sorted.sort((a, b) => {
      return (
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
    });

    return sorted;
  }, [config?.conversation_turns.turns]);

  const messagesScrollableElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const el = messagesScrollableElementRef.current;
      if (el) {
        el.scrollTo(0, el.scrollHeight);
      }
    });
  }, [messages, processingBufferMessage, expanded]);

  const messagesJSX = messages.map((message: ISmartChartTurn, i: number) => {
    return (
      <Message
        key={i}
        variant="canvas"
        message={{
          id: message.id,
          text: message.message,
          content: [
            {
              content_id: message.id,
              content_type: IAnaContentType.TEXT,
              text: message.message,
            },
          ],
          source: ConversationMessageSource.SmartCharts,
          author:
            message.actor === SmartChartActor.User
              ? IConversationMessageAuthor.User
              : IConversationMessageAuthor.Agent,
        }}
      />
    );
  });

  return (
    <MathJaxContext>
      <div className="sc2__chat-body" ref={messagesScrollableElementRef}>
        <div className="sc2__chat-body-messages">
          {messagesJSX}
          {!!processingBufferMessage && <MessageLoading text={loadingText} />}
        </div>
        <Suggestions />
      </div>
    </MathJaxContext>
  );
};
