import './index.css';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { INavigationItem, PageHeader } from '@cycling-web/common';
import React, { use, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../store/app/slice';
import { useNavigationLinks } from './hooks/useNavigationLinks';
import { Button } from '@cycling-web/analog-ui';
import { ROUTES } from '../../router/routes';
import { SideNavigation } from '../../components/SideNavigation';
import { Cookie, Pencil, ReceiptText, ShieldCheck } from 'lucide-react';
import { AnalogAuthContext } from '@cycling-web/auth';
import { UsersRepository } from '../../api/users/repository';
import { UsersService } from '../../api/users/service';
import { useUsersStore } from '../../store/users/slice';
import { useMobileMenu } from '../../components/MobileMenu';

export const Legal = () => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);
  const links = useNavigationLinks();
  const { pathname } = useLocation();
  const defaultUrl = `/${ROUTES.TEAMS}`;
  const navigate = useNavigate();
  const { authenticated } = use(AnalogAuthContext);
  const onRenderMobileMenu = useMobileMenu();

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    usersRepository.getUserProfile().then((profile) => {
      useUsersStore.getState().setUserProfile(profile);
    });
  }, [authenticated, usersRepository]);

  useEffect(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        const banner = document.querySelector('.cookies');
        if (banner) {
          const height = banner.getBoundingClientRect().height;
          scrollRef.current.style.paddingBottom = `${height}px`;
        }
        scrollRef.current.scrollTo(0, 0);
      }
    });
  }, [pathname]);

  const title = useMemo(() => {
    const map = {
      [links.terms_and_conditions]: t('label.terms_and_conditions'),
      [links.privacy_notice]: t('label.privacy_notice'),
      [links.cookies_notice]: t('label.cookies_notice'),
      [links.glossary]: t('label.glossary'),
    };
    return map[pathname];
  }, [
    links.cookies_notice,
    links.glossary,
    links.privacy_notice,
    links.terms_and_conditions,
    pathname,
    t,
  ]);

  const handleLogin = useCallback(() => {
    navigate(`/auth/sign-in`);
  }, [navigate]);

  const onRenderMenu = useCallback(() => {
    return (
      <Button
        variant="quietLayer2"
        content={t('action.login')}
        onClick={handleLogin}
      />
    );
  }, [handleLogin, t]);

  useEffect(() => {
    useAppStore.getState().setLoading(false);
  }, []);

  const items: INavigationItem[] = useMemo(() => {
    const tabs: INavigationItem[] = [];

    if (authenticated) {
      tabs.push({
        url: links.terms_and_conditions,
        icon: <ReceiptText />,
        text: t('label.terms_and_conditions'),
      });
    }

    tabs.push(
      {
        url: links.privacy_notice,
        icon: <ShieldCheck />,
        text: t('label.privacy_notice'),
      },
      {
        url: links.cookies_notice,
        icon: <Cookie />,
        text: t('label.cookies_notice'),
      },
      {
        url: links.glossary,
        icon: <Pencil />,
        text: t('label.glossary'),
      }
    );

    return tabs;
  }, [authenticated, links, t]);

  return (
    <div className="legal">
      <SideNavigation defaultUrl={defaultUrl} items={items} />
      <div className="legal-content">
        <PageHeader
          title={title}
          onRenderMenu={!authenticated ? onRenderMenu : onRenderMobileMenu}
        />
        <div className="legal-content__main-wrapper">
          <main
            className="legal-content__main analog-typography--body"
            ref={scrollRef}
          >
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};
