import './index.css';
import { MouseEvent, useCallback, useMemo } from 'react';
import {
  IconButton,
  ILabelValueProps,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  TruncatedText,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { Ellipsis, Trophy } from 'lucide-react';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import { ICalendarEvent, ICalendarEventType } from '../../../../types/workouts';
import { isAfter, isToday } from 'date-fns';
import { WorkoutsRepository } from '../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../api/workouts/service';
import { useWorkoutCalendarStore } from '../../store/slice';
import { useWorkoutsCalendarContext } from '../../context';
import { hoursToHHMMSS } from '../../../../utils/date-time';
import { CalendarWorkoutStats } from '../CalendarWorkoutStats';

type IProps = {
  event: ICalendarEvent;
};

export const CalendarEvent = ({ event }: IProps) => {
  const { t } = useTranslation();
  const { setAddModalProps } = useWorkoutsCalendarContext();
  const enableEdit =
    isToday(event.eventDateTimeUtc) ||
    isAfter(event.eventDateTimeUtc, new Date());

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
  };

  const menuOptions: IMenuOption[] = useMemo(() => {
    const options: IMenuOption[] = [];

    if (enableEdit) {
      options.push({
        id: 'edit',
        text: t('action.edit'),
      });
    }

    options.push({
      id: 'delete',
      text: t('action.delete'),
    });

    return options;
  }, [enableEdit, t]);

  const handleDeleteEvent = useCallback(() => {
    return workoutsRepository
      .deleteEvent({
        eventId: event.id,
      })
      .then(() => {
        useWorkoutCalendarStore.getState().deleteEvent(event);
      });
  }, [event, workoutsRepository]);

  const handleEditEvent = useCallback(() => {
    setAddModalProps({
      defaultEvent: event,
    });
  }, [event, setAddModalProps]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'delete') {
        handleDeleteEvent();
      }

      if (option.id === 'edit') {
        handleEditEvent();
      }
    },
    [handleDeleteEvent, handleEditEvent]
  );

  const menuJSX = (
    <Menu
      options={menuOptions}
      onChange={onMenuChange}
      dropdownProps={{
        anchor: (
          <IconButton
            size="xs"
            content={<Ellipsis />}
            onClick={preventDefault}
          />
        ),
        placement: 'bottom-end',
      }}
    />
  );

  const distance = event.distanceKms ?? 0;
  const duration = event.durationInMinutes
    ? hoursToHHMMSS(event.durationInMinutes / 60)
    : '';
  const elevation = event.elevationGainMts ?? 0;

  const params: ILabelValueProps[] = [
    {
      label: t('label.distance'),
      value: distance,
      units: t('units.km'),
    },
    {
      label: t('label.elevation'),
      value: elevation,
      units: t('units.m'),
    },
  ];

  return (
    <div className="calendar-event">
      <header className="calendar-event__header">
        {event.eventType === ICalendarEventType.RACE ? (
          <CyclingIcon width={16} height={16} />
        ) : (
          <Trophy width={16} height={16} />
        )}
        <Typography variant="subtitle-b" weight="bold" text={event.title} />

        <div className="calendar-event__header-actions">{menuJSX}</div>
      </header>

      <div className="calendar-event__body">
        <Typography as="p" variant="subtitle" weight="bold" text={duration} />
        {event?.description && (
          <TruncatedText
            className="analog-typography--subtitle-b"
            text={event.description}
            lines={3}
            tooltipProps={{
              maxWidth: '200px',
              contentClassName: 'calendar-event__tooltip-content',
            }}
            typographyProps={{
              as: 'pre',
            }}
          />
        )}
        <CalendarWorkoutStats params={params} />
      </div>
    </div>
  );
};
