import './index.css';
import {
  IconButton,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  Typography,
} from '@cycling-web/analog-ui';
import { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, Ellipsis } from 'lucide-react';
import { ICalendarDayOff } from '../../../../types/workouts';
import { WorkoutsRepository } from '../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../api/workouts/service';
import { isAfter } from 'date-fns';
import { useWorkoutsCalendarContext } from '../../context';
import { useFetchDayOffs } from '../../hooks/useFetchDayOffs';

type IProps = {
  dayOff: ICalendarDayOff;
  children: ReactNode;
};

export const CalendarDayOff = ({ children, dayOff }: IProps) => {
  const { t } = useTranslation();
  const { date, athlete, setAddModalProps } = useWorkoutsCalendarContext();
  const { fetchDayOffs } = useFetchDayOffs();

  const isFuture = isAfter(new Date(dayOff.dayOffDate), new Date());

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
  };

  const menuOptions: IMenuOption[] = [
    {
      id: 'delete',
      text: t('action.delete_what', { what: t('label.day_off').toLowerCase() }),
    },
    {
      type: 'divider',
    },
    {
      id: 'edit_rule',
      text: t('action.edit_what', { what: t('label.rule').toLowerCase() }),
    },
    {
      id: 'delete_rule',
      text: t('action.delete_what', { what: t('label.rule').toLowerCase() }),
    },
  ];

  const handleDeleteDayOff = useCallback(() => {
    return workoutsRepository
      .deleteAthleteDayOffs({
        dayOffId: dayOff.dayOffId,
      })
      .then(() => {
        if (athlete) {
          fetchDayOffs(date, athlete.id.toString());
        }
      });
  }, [athlete, date, dayOff.dayOffId, fetchDayOffs, workoutsRepository]);

  const handleDeleteDayOffRule = useCallback(() => {
    return workoutsRepository
      .deleteCalendarRule({
        ruleId: dayOff.ruleId,
      })
      .then(() => {
        if (athlete) {
          fetchDayOffs(date, athlete.id.toString());
        }
      });
  }, [athlete, date, dayOff.ruleId, fetchDayOffs, workoutsRepository]);

  const handleEditDayOffRule = useCallback(() => {
    return workoutsRepository
      .getCalendarRule({
        ruleId: dayOff.ruleId,
      })
      .then((response) => {
        setAddModalProps({
          defaultDayOff: response,
        });
      });
  }, [dayOff.ruleId, setAddModalProps, workoutsRepository]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'delete') {
        handleDeleteDayOff();
      }

      if (option.id === 'delete_rule') {
        handleDeleteDayOffRule();
      }

      if (option.id === 'edit_rule') {
        handleEditDayOffRule();
      }
    },
    [handleDeleteDayOff, handleDeleteDayOffRule, handleEditDayOffRule]
  );

  const menuJSX = (
    <Menu
      options={menuOptions}
      onChange={onMenuChange}
      dropdownProps={{
        anchor: (
          <IconButton
            size="xs"
            content={<Ellipsis />}
            onClick={preventDefault}
          />
        ),
        placement: 'bottom-end',
      }}
    />
  );

  return (
    <div className="calendar-dayOff">
      <header className="calendar-dayOff__header">
        <Calendar width={16} height={16} />
        <Typography
          variant="subtitle"
          weight="bold"
          text={t('label.day_off')}
        />

        <div className="calendar-dayOff__header-actions">
          {isFuture && menuJSX}
        </div>
      </header>

      <div className="calendar-dayOff__body">{children}</div>
    </div>
  );
};
