import { IGetFeatureFlagsResponse } from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';

interface IFeatureFlagsService {
  getFeatureFlags: () => R<IGetFeatureFlagsResponse>;
}

export class FeatureFlagsService implements IFeatureFlagsService {
  async getFeatureFlags(): R<IGetFeatureFlagsResponse> {
    try {
      return axiosInstance.get(`/v1/feature/flags`);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
