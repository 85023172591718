import { useFetchAthleteWellness } from './hooks/useFetchAthleteWellness';
import { useFetchAthleteSelfReportedWellness } from './hooks/useFetchAthleteSelfReportedWellness';
import { useAthleteWellnessStore } from './store/slice';
import { IHeartMeasurement, ISelfReportedWellness } from '../../types/wellness';
import { useFetchAthleteHeartMeasurements } from './hooks/useFetchAthleteHeartMeasurements';
import { useParams } from 'react-router';
import { useFetchMetricSeasons } from '../../hooks/useFetchMetricSeasons';
import { usePageFilters } from '../../context/PageFilters';
import { IAthleteDetailsFilters } from '../AthleteDetails/types';
import { useEffect } from 'react';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const useViewModel = () => {
  const { athleteId } = useParams();
  useFetchMetricSeasons({ athleteIds: athleteId });
  useFetchAthleteWellness();
  useFetchAthleteSelfReportedWellness();
  useFetchAthleteHeartMeasurements();

  const selfReportedWellness: ISelfReportedWellness | null =
    useAthleteWellnessStore((s) => s.selfReportedWellness);
  const selfReportedWellnessLoaded: boolean = useAthleteWellnessStore(
    (s) => s.selfReportedWellnessLoaded
  );

  const heartMeasurements: IHeartMeasurement | null = useAthleteWellnessStore(
    (s) => s.heartMeasurements
  );
  const heartMeasurementsLoaded: boolean = useAthleteWellnessStore(
    (s) => s.heartMeasurementsLoaded
  );

  const { filters, handleChangeFilter, initPageFilters } =
    usePageFilters<IAthleteDetailsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().athleteDetails);
  }, [initPageFilters]);

  return {
    selfReportedWellness,
    selfReportedWellnessLoading: !selfReportedWellnessLoaded,
    heartMeasurements,
    heartMeasurementsLoading: !heartMeasurementsLoaded,
    filters,
    handleChangeFilter,
  };
};
