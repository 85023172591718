import { Navigate, Outlet } from 'react-router';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';
import { useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { LS } from '../../constants';
import qs from 'qs';

export const PrivatePage = () => {
  const { authenticated } = useAnalogAuthContext();

  const currentPagePathname: string = useMemo(() => {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }, []);

  const queryParams = useMemo(() => {
    return qs.parse(window.location.search, { ignoreQueryPrefix: true });
  }, []);

  const { email, newUser } = queryParams;

  if (!authenticated) {
    if (!window.location.hostname.includes(ROUTES.AUTH)) {
      localStorage.setItem(LS.PreviousPage, currentPagePathname);
    }

    const qsEmail = email ? `?email=${email}` : '';

    if (newUser === 'true') {
      return (
        <Navigate
          to={`/${ROUTES.AUTH}/${ROUTES.SIGN_UP}${qsEmail}`}
          replace={true}
        />
      );
    }

    return (
      <Navigate
        to={`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}${qsEmail}`}
        replace={true}
      />
    );
  }

  return <Outlet />;
};
