export const ROUTES = {
  SIGN_OUT: 'logout',
  RECEPTION: '',
  TEAMS: 'teams',
  TEAM_INVITATION: 'team-invitation',

  STAFF: 'staff',
  ATHLETE: 'athlete',
  WELLNESS: 'wellness',
  PERFORMANCE: 'performance',
  TRAINING_LOAD: 'training-load',
  HEALTH: 'health',
  HEALTH_DETAILS: 'health-details',
  COACH: 'coach',
  SMART_CHARTS: 'smart-charts',
  SMART_CHARTS2: 'smart-charts2',
  ADMIN_PANEL: 'admin-panel',
  DASHBOARD: 'dashboard',
  ATHLETES: 'athletes',
  GROUPS: 'groups',
  PROFILES: 'profiles',
  OVERVIEW: 'overview',
  CHARTS: 'charts',
  PERFORMANCE_REPORT: 'performance-report',
  PERFORMANCE_TABLE: 'table',
  PERFORMANCE_CHART: 'chart',
  MEDICAL_REPORT_TABLE: 'medical-report-table',
  MEDICAL_REPORT_DETAILS: 'medical-report-details',
  TEAM_MEMBERS: 'team-members',
  TEAM_MAPPING: 'team-mapping',
  COMMUNICATION_SETTINGS: 'communication-settings',
  PROFILE: 'profile',

  SETTINGS: 'settings',
  SETTINGS_PROFILE: 'profile',
  DATA_SHARING: 'data-sharing',

  INTEGRATIONS: 'integrations',
  LEGAL: 'legal',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  PRIVACY_NOTICE: 'privacy-notice',
  COOKIES_NOTICE: 'cookies-notice',
  GLOSSARY: 'glossary',

  ONBOARDING: 'onboarding',
  ONBOARDING_PROFILE: 'profile',
  ONBOARDING_PERFORMANCE: 'performance',
  ONBOARDING_PLATFORMS: 'platforms',
  ONBOARDING_DEVICES: 'devices',
  ONBOARDING_COMPLETE: 'complete',

  CONSENT: 'consent',

  CALENDAR: 'calendar',
  WORKOUT: 'workout',
  EVENT: 'event',
  PRESCRIPTION: 'prescription',
};
