import { useFetchTrainingLoadFeedback } from './hooks/useFetchTraingLoadFeedback';
import { useFetchTrainingZones } from '../../hooks/useFetchTrainingZones';
import { usePageFilters } from '../../context/PageFilters';
import { useEffect, useMemo } from 'react';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { IAthleteProfileFilters } from './types';
import { useFetchTrainingZonesPerformance } from './hooks/useFetchTrainingZonesPerformance';
import { useAthleteProfileStore } from './store/slice';
import { IChartSource } from '../../components/Chart';
import { generateComposeKey, getTrainingZoneKeys } from './utils/getDefaults';
import { ChartAxisName } from '../../constants/charts';
import { useFetchWorkoutSeasons } from '../../hooks/useFetchWorkoutSeasons';
import { useParams } from 'react-router';
import { IAthleteProfile } from '../../types/athlete-profiles';

export const useViewModel = () => {
  const { athleteId } = useParams();
  useFetchTrainingLoadFeedback();
  const { fetchTrainingZones } = useFetchTrainingZones();
  const { fetchTrainingZonesPerformance } = useFetchTrainingZonesPerformance();
  useFetchWorkoutSeasons({ athleteIds: athleteId });

  const trainingProfiles = useAthleteProfileStore((s) => s.trainingProfiles);
  const zonePerformance = useAthleteProfileStore((s) => s.zonePerformance);
  const zonePerformanceLoaded = useAthleteProfileStore(
    (s) => s.zonePerformanceLoaded
  );

  useEffect(() => {
    if (!athleteId) {
      return;
    }
    fetchTrainingZones(athleteId)
      .then((data: IAthleteProfile[]) => {
        useAthleteProfileStore.getState().setAthleteProfiles(data);
      })
      .catch(() => {
        useAthleteProfileStore.getState().setAthleteProfilesLoaded(true);
      });
  }, [fetchTrainingZones, athleteId]);

  useEffect(() => {
    fetchTrainingZonesPerformance();
  }, [fetchTrainingZonesPerformance]);

  const { filters, initPageFilters } = usePageFilters<IAthleteProfileFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().athleteProfile);
  }, [initPageFilters]);

  const source = useMemo(() => {
    const source: Record<string, IChartSource> = {};

    getTrainingZoneKeys().forEach((key) => {
      source[key] = [];
    });

    for (let i = 0; i < zonePerformance.length; i++) {
      const p = zonePerformance[i];
      // @ts-ignore
      const composeKey = generateComposeKey(p);
      const profile = trainingProfiles[composeKey];

      if (!profile) {
        continue;
      }
      source[composeKey].push([ChartAxisName.Date, ChartAxisName.Time]);

      Object.keys(p).forEach((key) => {
        if (key.includes('totalZ')) {
          const index = Number(key.at(-1)) - 1;
          const name = profile.zones[index]?.name;
          if (name) {
            const hours = p[key] / (1000 * 3600);
            source[composeKey].push([name, hours]);
          }
        }
      });
    }

    return source;
  }, [zonePerformance, trainingProfiles]);

  return {
    source,
    filters,
    zonePerformanceLoading: !zonePerformanceLoaded,
    zonePerformanceSource: source,
  };
};
