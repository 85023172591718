export const LS = {
  AccessToken: 'access_token',
  RefreshToken: 'refresh_token',
  PreviousPage: 'previous_page',
  Team: 'team',
  Cookies: 'cookies',
  CookiesVersion: 'cookiesVersion',
  ShowAna: 'show_ana',
};

export const URL_PARAMS = {
  ClearLs: 'clear_ls',
  AthleteId: 'athlete_id',
  GroupId: 'group_id',
  Modal: 'modal',
};

export const EMPTY = '-';

export const DEFAULT_CDN_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';
export const DEFAULT_CDN_SUFFIX = 'svg';

export const ALL_FILTER_OPTION_ID = '-1';

export const ApiErrorCode = {
  Unauthorized: 401,
  Forbidden: 403,
};

export const AbortError = 'CanceledError';

export const SUPPORT_EMAIL = 'support-sports@analog.io';

export const PAGINATION_SIZE = 25;
