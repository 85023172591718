import { useCallback, useMemo } from 'react';
import { AthleteProfileRepository } from '../api/athletes-profiles/repository';
import { AthleteProfileService } from '../api/athletes-profiles/service';

export const useFetchTrainingZones = () => {
  const athleteProfileRepository = useMemo(() => {
    return new AthleteProfileRepository(new AthleteProfileService());
  }, []);

  const fetchTrainingZones = useCallback(
    (athleteId: string | number) => {
      return athleteProfileRepository.getAthleteProfiles({
        athleteId: +athleteId,
      });
    },
    [athleteProfileRepository]
  );

  return {
    fetchTrainingZones,
  };
};
