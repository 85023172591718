import { PageFilters } from '@cycling-web/common';
import { useCallback } from 'react';
import { IMedicalReportsFilters } from '../../types';
import { usePageFilters } from '../../../../context/PageFilters';

export const MedicalReportsFilters = () => {
  const { filters, handleChangeFilter, removeFilter } =
    usePageFilters<IMedicalReportsFilters>();

  const handleSearchClear = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleSearchClear,
      }}
    />
  );
};
