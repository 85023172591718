import { useCallback, useMemo } from 'react';
import { IPerformanceReport } from '../../../../types/performance';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IGetAthletePerformanceReportRequest } from '../../../api/performance/types';

export const useFetchWeeklyPerformanceReport = () => {
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  const fetchWeeklyPerformanceReport = useCallback(
    (
      payload: IGetAthletePerformanceReportRequest
    ): Promise<IPerformanceReport> => {
      return performanceRepository
        .getAthletePerformanceReport(payload)
        .then((reports: IPerformanceReport[]) => {
          return reports[0];
        })
        .catch(() => {
          //
        });
    },
    [performanceRepository]
  );

  return {
    fetchWeeklyPerformanceReport,
  };
};
