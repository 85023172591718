import { IPrescriptionSlice, IPrescriptionState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IWorkoutPrescription } from '../../../types/workouts';

export const initialState: IPrescriptionState = {
  prescription: null,
  prescriptionLoaded: false,
};

export const usePrescriptionStore = create<IPrescriptionSlice>()(
  immer((set) => ({
    ...initialState,
    setPrescription: (prescription: IWorkoutPrescription | null) => {
      set((state) => {
        state.prescription = prescription;
        state.prescriptionLoaded = true;
      });
    },
    setPrescriptionLoaded: (loaded: boolean) => {
      set((state) => {
        state.prescriptionLoaded = loaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
