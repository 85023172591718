import { useCallback, useMemo } from 'react';
import { WorkoutsRepository } from '../../../api/workouts/repository';
import { WorkoutsService } from '../../../api/workouts/service';
import { addWeeks, endOfMonth, format, startOfMonth, subWeeks } from 'date-fns';
import { useWorkoutCalendarStore } from '../store/slice';

export const useFetchDayOffs = () => {
  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchDayOffs = useCallback(
    (date: Date, athleteId: string) => {
      const startDate = format(subWeeks(startOfMonth(date), 1), 'yyyy-MM-dd');
      const endDate = format(addWeeks(endOfMonth(date), 1), 'yyyy-MM-dd');

      workoutsRepository
        .getAthleteDayOffs({
          athleteId: +athleteId,
          startDate,
          endDate,
        })
        .then((response) => {
          useWorkoutCalendarStore.getState().setDayOffs(response);
        })
        .catch(() => {
          useWorkoutCalendarStore.getState().setDayOffsLoaded(true);
        });
    },
    [workoutsRepository]
  );

  return {
    fetchDayOffs,
  };
};
