import './index.css';
import {
  Button,
  Checkbox,
  FormElement,
  Message,
  Typography,
} from '@cycling-web/analog-ui';
import { Trans, useTranslation } from 'react-i18next';
import { InputControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import React, { use, useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { WaitlistCompleted } from './Completed';
import { AnalogAuthContext } from '../../context/AnalogAuthContext';
import { FormView } from '../FormView';
import { Link } from 'react-router';
import { ROUTES } from '../../router/routes';

type IForm = {
  name: string;
  email: string;
};

export const Waitlist = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [acceptTermsValue, setAcceptTermsValue] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const { onJoinWaitlist } = use(AnalogAuthContext);

  const onAcceptTermsChange = useCallback(() => {
    setAcceptTermsValue((f) => !f);
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        email: z
          .string()
          .min(1, { message: t('validation.required') })
          .email({ message: t('validation.invalid_email') }),
      })
      .passthrough();
  }, [t]);

  const defaultValues = useMemo((): IForm => {
    return {
      name: '',
      email: '',
    };
  }, []);

  const form = useForm<IForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      if (!acceptTermsValue || !onJoinWaitlist) {
        return;
      }

      setLoading(true);

      onJoinWaitlist(formData)
        .then(() => {
          setCompleted(true);
        })
        .catch((e: { error: string }) => {
          const error = e?.error || 'invalid_email';
          setMessage(t(`validation.${error}`));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [acceptTermsValue, t, onJoinWaitlist]
  );

  const onRenderLabel = useCallback(() => {
    return (
      <Trans i18nKey="legal.accept_legal_document">
        I agree that I have read the&nbsp;
        <a
          className="analog-auth__common-footer-button"
          href={`${window.location.origin}/legal/privacy-notice`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
      </Trans>
    );
  }, []);

  if (completed) {
    return <WaitlistCompleted />;
  }

  return (
    <FormProvider {...form}>
      <FormView
        title={t('label.join_waitlist_title')}
        subtitle={t('label.join_waitlist_subtitle')}
      >
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.name')}
            message={formState.errors.name?.message}
          >
            <InputControl
              name="name"
              size="l"
              placeholder={t('placeholder.full_name')}
              invalid={!!formState.errors.name}
              autoFocus={true}
            />
          </FormElement>

          <FormElement
            label={t('label.email')}
            message={formState.errors.email?.message}
          >
            <InputControl
              name="email"
              size="l"
              placeholder={t('placeholder.email')}
              invalid={!!formState.errors.email}
            />
          </FormElement>
          <Typography
            as="p"
            variant="subtitle"
            className="waitlist-email-notice"
            text={
              <Trans i18nKey="label.waitlist_email_notice">
                Your details will be held in accordance with our{` `}
                <a
                  className="analog-auth__common-footer-button"
                  href={`${window.location.origin}/legal/privacy-notice`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Notice
                </a>
                .
              </Trans>
            }
          />

          {message && (
            <div className="analog-auth-common-form__message">
              <Message text={message} variant="error" />
            </div>
          )}

          <div className="analog-auth-common-form__extras">
            <Checkbox
              label={t('label.accept_terms_and_conditions')}
              size="m"
              checked={acceptTermsValue}
              onChange={onAcceptTermsChange}
              onRenderLabel={onRenderLabel}
            />
          </div>

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              disabled={!acceptTermsValue}
              size="l"
              content={t('label.join_waitlist_action')}
              fullWidth
              loading={loading}
            />
          </div>
        </form>

        <div className="analog-auth__common-footer">
          <div className="analog-auth__common-footer-message analog-typography--button-m">
            <span>{t('label.have_account_message')}</span>
            <Link
              to={`/${ROUTES.AUTH}/${ROUTES.SIGN_IN}`}
              replace={true}
              className="analog-auth__common-footer-button"
            >
              {t('action.login')}
            </Link>
          </div>
        </div>
      </FormView>
    </FormProvider>
  );
};
