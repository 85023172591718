import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { WellnessRepository } from '../../../api/wellness/repository';
import { WellnessService } from '../../../api/wellness/service';
import { IHeartMeasurement } from '../../../types/wellness';
import { Toast } from '@cycling-web/analog-ui';
import { useAthleteWellnessStore } from '../store/slice';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';
import { getDefaultPageFilters } from '../../../constants/page-filters';

export const useFetchAthleteHeartMeasurements = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const setHeartMeasurements = useAthleteWellnessStore(
    (s) => s.setHeartMeasurements
  );
  const setHeartMeasurementsLoaded = useAthleteWellnessStore(
    (s) => s.setHeartMeasurementsLoaded
  );
  const { filters } = usePageFilters<IAthleteDetailsFilters>();

  const wellnessRepository = useMemo(() => {
    return new WellnessRepository(new WellnessService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || filters === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    wellnessRepository
      .getHeartMeasurements({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteId: +athleteId,
        unit:
          filters.dateGroupBy ||
          getDefaultPageFilters().athleteDetails.dateGroupBy,
      })
      .then((data: IHeartMeasurement) => {
        setHeartMeasurements(data);
      })
      .catch((error: AxiosError) => {
        setHeartMeasurementsLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_athlete_heart_measurements_title'),
              message: t('error.get_athlete_heart_measurements_message'),
            },
            { toastId: 'get_athlete_heart_measurements' }
          );
        }
      });
  }, [
    athleteId,
    filters,
    setHeartMeasurements,
    setHeartMeasurementsLoaded,
    t,
    wellnessRepository,
  ]);
};
