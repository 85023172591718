import './index.css';
import { ReactNode } from 'react';
import {
  ITooltipProps,
  Skeleton,
  Tooltip,
  Typography,
} from '@cycling-web/analog-ui';
import { Info } from 'lucide-react';
import { FiltersBar, IFiltersBarProps } from '@cycling-web/common';

type IProps = {
  title: string;
  subtitle?: ReactNode;
  loading?: boolean;
  tooltipProps?: Omit<ITooltipProps, 'anchor'>;
  filtersBarProps?: IFiltersBarProps;
  contentAfterFilter?: ReactNode;
};

export const WidgetHeader = ({
  title,
  subtitle,
  filtersBarProps,
  contentAfterFilter,
  loading,
  tooltipProps,
}: IProps) => {
  return (
    <header className="athlete-profile-widget-header">
      <div className="athlete-profile-widget-header__left">
        <div className="athlete-profile-widget-header__left-title">
          {loading && !title ? (
            <Skeleton width="180px" height="26px" />
          ) : (
            <Typography
              className="athlete-profile-widget-header__title"
              text={title}
              variant="headline"
              as="h4"
              weight="bold"
            />
          )}
          {tooltipProps && (
            <Tooltip
              {...tooltipProps}
              anchor={
                <Info
                  className="athlete-profile-widget-header__tooltip-anchor"
                  size={20}
                />
              }
            />
          )}
        </div>
        {subtitle && subtitle}
      </div>

      {filtersBarProps && (
        <div className="athlete-profile-widget-header__right-bar">
          <div className="athlete-profile-widget-header__filters">
            {loading ? (
              <Skeleton width="162px" height="40px" />
            ) : (
              <FiltersBar {...filtersBarProps} />
            )}
            {contentAfterFilter && contentAfterFilter}
          </div>
        </div>
      )}
    </header>
  );
};
