import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ICriticalPower } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { IAthlete } from '../../../types/athletes';
import { useDashboardStore } from '../store/slice';
import { useAthletesStore } from '../../../store/athletes/slice';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IDashboardFilters } from '../types';

export const useFetchTeamCriticalPower = () => {
  const { t } = useTranslation();
  const { filters } = usePageFilters<IDashboardFilters>();
  const setCriticalPower = useDashboardStore((s) => s.setCriticalPower);
  const setCriticalPowerLoaded = useDashboardStore(
    (s) => s.setCriticalPowerLoaded
  );

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const loading = !athletesLoaded;

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (loading || athletes.length === 0 || filters.period === undefined) {
      setCriticalPowerLoaded(true);
      return;
    }

    const filteredAthletes = athletes;
    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getCriticalPower({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: filteredAthletes.map((a: IAthlete) => a.id),
      })
      .then((data: ICriticalPower[]) => {
        setCriticalPower(data);
      })
      .catch((error: AxiosError) => {
        setCriticalPowerLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_team_critical_power_title'),
              message: t('error.get_team_critical_power_message'),
            },
            { toastId: 'get_team_critical_power' }
          );
        }
      });
  }, [
    loading,
    athletes,
    filters.period,
    performanceRepository,
    setCriticalPower,
    setCriticalPowerLoaded,
    t,
  ]);
};
