/**
 * Options for merging stream deltas
 */
type MergeOptions = {
  /**
   * Optional array of keys to update. If not provided, all keys will be updated.
   */
  keysToUpdate?: string[];
};

/**
 * Merges stream deltas into a single object by appending delta values to corresponding keys.
 * Supports nested objects and arrays with optimized performance.
 *
 * @param delta - The current delta object to merge
 * @param result - The accumulated result object (optional, defaults to empty object)
 * @param options - Optional configuration options
 * @returns The updated result object with merged deltas
 */
function mergeStreamDeltas<T extends Record<string, any>>(
  delta: Partial<T>,
  result: Partial<T> = {},
  options?: MergeOptions
): Partial<T> {
  if (!delta || Object.keys(delta).length === 0) {
    return result;
  }

  const keysToUpdate = options?.keysToUpdate;
  const newResult = { ...result } as Record<string, any>;
  const shouldUpdateAllKeys = !keysToUpdate || keysToUpdate.length === 0;

  for (const key in delta) {
    if (!Object.prototype.hasOwnProperty.call(delta, key)) {
      continue;
    }

    // Skip keys not in keysToUpdate if the array is provided
    if (!shouldUpdateAllKeys && !keysToUpdate.includes(key)) {
      continue;
    }

    const deltaValue = delta[key as keyof Partial<T>];
    const resultValue = newResult[key];

    // Early return for undefined values
    if (deltaValue === undefined) {
      continue;
    }

    // Handle string values
    if (typeof deltaValue === 'string') {
      if (typeof resultValue === 'string') {
        newResult[key] = resultValue + deltaValue;
      } else {
        newResult[key] = deltaValue;
      }
      continue;
    }

    // Handle null values
    if (deltaValue === null) {
      newResult[key] = null;
      continue;
    }

    // Handle arrays
    if (Array.isArray(deltaValue)) {
      if (Array.isArray(resultValue)) {
        newResult[key] = resultValue.concat(deltaValue);
      } else {
        newResult[key] = deltaValue.slice();
      }
      continue;
    }

    // Handle objects - for nested objects, we pass down the keysToUpdate as well
    if (
      typeof deltaValue === 'object' &&
      typeof resultValue === 'object' &&
      resultValue !== null &&
      !Array.isArray(resultValue)
    ) {
      // For nested objects, we process all keys since filtering is done at the top level
      newResult[key] = mergeStreamDeltas(deltaValue, resultValue);
      continue;
    }

    newResult[key] = deltaValue;
  }

  return newResult as Partial<T>;
}

/**
 * Processes a stream of deltas and merges them into a single result object.
 * Uses a loop instead of reduce for better performance with large arrays.
 *
 * @param deltas - Array of delta objects to process
 * @param options - Optional configuration options
 * @returns A merged result object
 */
function processDeltaStream<T extends Record<string, any>>(
  deltas: Partial<T>[],
  options?: MergeOptions
): Partial<T> {
  if (!deltas || deltas.length === 0) {
    return {};
  }

  const keysToUpdate = options?.keysToUpdate;

  if (deltas.length === 1) {
    // If keysToUpdate is provided, filter the first delta
    if (keysToUpdate && keysToUpdate.length > 0) {
      const result = {} as Partial<T>;
      const delta = deltas[0];

      for (const key of keysToUpdate) {
        if (Object.prototype.hasOwnProperty.call(delta, key)) {
          // Using type assertion to handle the generic constraint
          (result as any)[key] = delta[key];
        }
      }

      return result;
    }

    // Otherwise, return the full delta
    return { ...deltas[0] };
  }

  let result = {} as Partial<T>;

  for (let i = 0; i < deltas.length; i++) {
    result = mergeStreamDeltas(deltas[i], result, options);
  }

  return result;
}

type StreamDelta<T> = Partial<T>;
type StreamResult<T> = Partial<T>;

export {
  mergeStreamDeltas,
  processDeltaStream,
  type StreamDelta,
  type StreamResult,
  type MergeOptions,
};
