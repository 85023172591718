import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { useMedicalReportsStore } from '../store/slice';
import { MedicalRepository } from '../../../api/medical/repository';
import { MedicalService } from '../../../api/medical/service';
import { IMedicalReportRecord } from '../../../types/medical';
import { Toast } from '@cycling-web/analog-ui';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../constants';

export const useFetchMedicalReports = () => {
  const { t } = useTranslation();
  const setReports = useMedicalReportsStore((s) => s.setReports);
  const setReportsLoaded = useMedicalReportsStore((s) => s.setReportsLoaded);

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  useEffect(() => {
    medicalRepository
      .getTeamReport({
        page: 0,
        size: 100,
      })
      .then((reports: IMedicalReportRecord[]) => {
        setReports(reports);
      })
      .catch((error: AxiosError) => {
        setReportsLoaded(true);
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.get_team_medical_reports_title'),
              message: t('error.get_team_medical_reports_message'),
            },
            { toastId: 'get_team_medical_reports' }
          );
        }
      });
  }, [medicalRepository, setReports, setReportsLoaded, t]);
};
