import { useCallback, useEffect, useMemo } from 'react';
import { WorkoutsRepository } from '../../../api/workouts/repository';
import { WorkoutsService } from '../../../api/workouts/service';
import { useParams } from 'react-router';
import { usePrescriptionStore } from '../store/slice';

export const useFetchPrescription = () => {
  const { prescriptionId } = useParams();

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchPrescription = useCallback(() => {
    if (!prescriptionId) {
      return;
    }

    workoutsRepository
      .getPrescription({
        id: +prescriptionId,
      })
      .then((response) => {
        usePrescriptionStore.getState().setPrescription(response);
      })
      .catch(() => {
        usePrescriptionStore.getState().setPrescriptionLoaded(true);
      });
  }, [prescriptionId, workoutsRepository]);

  useEffect(() => {
    fetchPrescription();
  }, [fetchPrescription]);

  return {
    fetchPrescription,
  };
};
