import './index.css';
import { format, formatDate, isAfter, isToday } from 'date-fns';
import { Typography } from '@cycling-web/analog-ui';
import { Link } from 'react-router';
import { Url } from '../../../../utils/url';
import { CalendarWorkout } from '../CalendarWorkout';
import { useWorkoutCalendarStore } from '../../store/slice';
import { use, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { ROUTES } from '../../../../router/routes';
import { WorkoutsCalendarContext } from '../../context';
import { CalendarDayOff } from '../CalendarDayOff';
import { CalendarEvent } from '../CalendarEvent';
import { CalendarPrescription } from '../CalendarPrescription';
import { PlusIcon } from 'lucide-react';

type IProps = {
  date: Date;
};

export const CalendarDay = ({ date }: IProps) => {
  const workoutsRecord = useWorkoutCalendarStore((s) => s.workouts);
  const workoutsLoaded = useWorkoutCalendarStore((s) => s.workoutsLoaded);
  const dayOffs = useWorkoutCalendarStore((s) => s.dayOffs);
  const eventsMap = useWorkoutCalendarStore((s) => s.events);

  const formatedDate = formatDate(date, 'yyyy-MM-dd');
  const rootRef = useRef<HTMLDivElement>(null);

  const context = use(WorkoutsCalendarContext);
  const { athlete, setAddModalProps } = context;

  const onOpenAddModal = useCallback(() => {
    setAddModalProps({
      defaultDate: date,
    });
  }, [setAddModalProps, date]);

  const enableEdit = isToday(date) || isAfter(date, new Date());

  const dayOff = dayOffs[formatedDate]
    ? dayOffs[formatedDate][0] || undefined
    : undefined;

  useLayoutEffect(() => {
    if (isToday(date)) {
      rootRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  }, [date]);

  const workouts = useMemo(() => {
    return workoutsRecord[formatedDate]?.workouts ?? [];
  }, [workoutsRecord, formatedDate]);

  const prescription = useMemo(() => {
    return workoutsRecord[formatedDate]?.prescription ?? [];
  }, [workoutsRecord, formatedDate]);

  const events = useMemo(() => {
    return eventsMap[formatedDate] ?? [];
  }, [eventsMap, formatedDate]);

  const addButton = enableEdit && (
    <button
      className="workout-calendar-day__add-toggle"
      onClick={onOpenAddModal}
    >
      <PlusIcon size={14} />
    </button>
  );

  const emptyStateJSX = (
    <div className="workout-calendar-day__empty">
      <Typography variant="h2" weight="regular" text={format(date, 'dd')} />
      <Typography variant="body" weight="light" text={format(date, 'MMM')} />
      {addButton}
    </div>
  );

  const workoutsJSX = workouts.map((w) => {
    return (
      <Link
        key={w.workoutId}
        to={`../../${ROUTES.WORKOUT}/${w.workoutId}`}
        onClick={Url.saveUrlToLS}
        state={{ athlete }}
      >
        <CalendarWorkout workout={w} />
      </Link>
    );
  });

  const prescriptionsJSX = prescription.map((item, i) => {
    return (
      <Link
        key={i}
        to={`../../${ROUTES.PRESCRIPTION}/${item.id}`}
        onClick={Url.saveUrlToLS}
        state={{ athlete }}
      >
        <CalendarPrescription prescription={item} />
      </Link>
    );
  });

  const eventsJSX = events.map((e) => {
    return (
      <Link
        key={e.id}
        to={`../../${ROUTES.EVENT}/${e.id}`}
        onClick={Url.saveUrlToLS}
        state={{ athlete }}
      >
        <CalendarEvent event={e} />
      </Link>
    );
  });

  const isEmptyState =
    workoutsLoaded &&
    (workouts.length > 0 ||
      events.length > 0 ||
      prescription.length > 0 ||
      dayOff);

  const calendarItemsJSX = (
    <div className="workout-calendar-day__workouts">
      {isEmptyState ? (
        <>
          {workoutsJSX}
          {prescriptionsJSX}
          {eventsJSX}
          {addButton}
        </>
      ) : (
        emptyStateJSX
      )}
    </div>
  );

  return (
    <div className="workout-calendar-day" ref={rootRef}>
      <div className="workout-calendar-day__main">
        {isEmptyState && (
          <div className="workout-calendar-day__date">
            <Typography
              variant="headline"
              weight="bold"
              text={format(date, 'dd')}
            />
            <Typography
              variant="body"
              weight="light"
              text={format(date, 'MMM')}
            />
          </div>
        )}
        {dayOff ? (
          <CalendarDayOff dayOff={dayOff}>{calendarItemsJSX}</CalendarDayOff>
        ) : (
          <div className="workout-calendar-day__workouts">
            {calendarItemsJSX}
          </div>
        )}
      </div>

      {/*<footer className="workout-calendar-day__footer"></footer>*/}
    </div>
  );
};
