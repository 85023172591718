import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AthletesRepository } from '../../../../api/athletes/repository';
import { AthletesService } from '../../../../api/athletes/service';
import { useUsersStore } from '../../../../store/users/slice';
import { IAthlete } from '../../../../types/athletes';

export const useViewModel = () => {
  const { t } = useTranslation();
  const fetching = useRef<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);
  const [loading, setLoading] = useState<boolean>(true);

  const [athlete, setAthlete] = useState<IAthlete | null>(null);

  const athletesRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  useEffect(() => {
    if (fetching.current || !userProfile?.athleteId) {
      return;
    }

    fetching.current = true;
    athletesRepository
      .getAthleteById({
        id: userProfile.athleteId,
      })
      .then((athlete: IAthlete) => {
        setAthlete(athlete);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        fetching.current = false;
        setLoading(false);
      });
  }, [athletesRepository, t, userProfile?.athleteId]);

  return {
    athlete,
    loading,
  };
};
