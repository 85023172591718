import { useCallback, useEffect, useMemo } from 'react';
import { WorkoutsRepository } from '../../../api/workouts/repository';
import { WorkoutsService } from '../../../api/workouts/service';
import { useParams } from 'react-router';
import { useWorkoutStore } from '../store/slice';

export const useFetchWorkout = () => {
  const { workoutId } = useParams();

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchWorkout = useCallback(() => {
    if (!workoutId) {
      return;
    }

    workoutsRepository
      .getWorkout({
        workoutId: +workoutId,
      })
      .then((url: string | null) => {
        if (!url) {
          throw new Error('Workout analysis URL is null.');
        }

        return fetch(url);
      })
      .then((data) => data.json())
      .then((workout) => {
        console.log(workout);
        useWorkoutStore.getState().setWorkout(workout);
      })
      .catch(() => {
        useWorkoutStore.getState().setWorkoutLoaded(true);
      });
  }, [workoutId, workoutsRepository]);

  useEffect(() => {
    fetchWorkout();
  }, [fetchWorkout]);

  return {
    fetchWorkout,
  };
};
