import { Discipline } from '../../../types/enums';
import {
  AthleteProfileMethod,
  TrainingZoneMethod,
  TrainingZoneParameter,
} from '../../../types/athlete-profiles';
import { IAthlete } from '../../../types/athletes';

export const TrainingZoneKeys = {
  CyclingPowerMFTP: `${Discipline.Cycling}_${TrainingZoneParameter.Power}_${TrainingZoneMethod.MFTP}`,
  CyclingPowerMCP: `${Discipline.Cycling}_${TrainingZoneParameter.Power}_${TrainingZoneMethod.MCP}`,
  CyclingHeartRateDefault: `${Discipline.Cycling}_${TrainingZoneParameter.HeartRate}_${TrainingZoneMethod.Default}`,
  SwimmingCSSDefault: `${Discipline.Swimming}_${TrainingZoneParameter.CSS}_${TrainingZoneMethod.Default}`,
  SwimmingHeartRateDefault: `${Discipline.Swimming}_${TrainingZoneParameter.HeartRate}_${TrainingZoneMethod.Default}`,
  RunningPaceDefault: `${Discipline.Running}_${TrainingZoneParameter.Pace}_${TrainingZoneMethod.Default}`,
  RunningHeartRateDefault: `${Discipline.Running}_${TrainingZoneParameter.HeartRate}_${TrainingZoneMethod.Default}`,
};

export const getTrainingZoneKeys = () => {
  return [
    TrainingZoneKeys.CyclingPowerMFTP,
    TrainingZoneKeys.CyclingPowerMCP,
    TrainingZoneKeys.CyclingHeartRateDefault,
    TrainingZoneKeys.SwimmingCSSDefault,
    TrainingZoneKeys.SwimmingHeartRateDefault,
    TrainingZoneKeys.RunningPaceDefault,
    TrainingZoneKeys.RunningHeartRateDefault,
  ];
};

export const getProfileParameter = (composeKey: string): keyof IAthlete => {
  const map: Record<string, keyof IAthlete> = {
    [TrainingZoneKeys.CyclingPowerMFTP]: 'ftp',
    [TrainingZoneKeys.CyclingPowerMCP]: 'ftp',
    [TrainingZoneKeys.CyclingHeartRateDefault]: 'lthrCycling',
    [TrainingZoneKeys.SwimmingCSSDefault]: 'swimSpeedHundredMts',
    [TrainingZoneKeys.SwimmingHeartRateDefault]: 'lthrSwimming',
    [TrainingZoneKeys.RunningPaceDefault]: 'tpInSeconds',
    [TrainingZoneKeys.RunningHeartRateDefault]: 'lthrRunning',
  };

  if (!map[composeKey]) {
    throw new Error('Could not find a parameter in the athlete profile');
  }

  return map[composeKey];
};

export const generateComposeKey = ({
  type,
  method,
}: {
  type: Discipline;
  method: AthleteProfileMethod;
}): string => {
  if (type === Discipline.Cycling) {
    if (method === AthleteProfileMethod.MFTP) {
      return TrainingZoneKeys.CyclingPowerMFTP;
    } else if (method === AthleteProfileMethod.MCP) {
      return TrainingZoneKeys.CyclingPowerMCP;
    } else {
      return TrainingZoneKeys.CyclingHeartRateDefault;
    }
  } else if (type === Discipline.Swimming) {
    if (method === AthleteProfileMethod.CSS) {
      return TrainingZoneKeys.SwimmingCSSDefault;
    } else {
      return TrainingZoneKeys.SwimmingHeartRateDefault;
    }
  } else if (type === Discipline.Running) {
    if (method === AthleteProfileMethod.CSS) {
      return TrainingZoneKeys.RunningPaceDefault;
    } else {
      return TrainingZoneKeys.RunningHeartRateDefault;
    }
  }

  return '';
};

export const MAX_ZONE_VALUE = 2147483647;
export const MAX_ZONE_VALUE_PLACEHOLDER = 'Max';
