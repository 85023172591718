import './index.css';
import { FormProvider } from 'react-hook-form';
import {
  Calendar,
  Dialog,
  FormElement,
  Typography,
} from '@cycling-web/analog-ui';
import { IAthlete } from '../../types/athletes';
import { InputNumberControl } from '@cycling-web/common';
import { useViewModel } from './useViewModel';

type IProps = {
  athlete: IAthlete;
  onDismiss: () => void;
};

export const AnthropometryFormModal = (props: IProps) => {
  const {
    t,
    form,
    onDismiss,
    onSubmit,
    loading,
    errors,
    markers,
    onPeriodChange,
    date,
    onDateChange,
  } = useViewModel(props);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.anthropometry')}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
      >
        <div className="anthropometry-form-modal">
          <Calendar
            className="anthropometry-form__calendar"
            value={new Date(date)}
            onChange={onDateChange}
            max={new Date()}
            markers={markers}
            onPeriodChange={onPeriodChange}
          />

          <div className="anthropometry-form-modal-inner">
            <div className="anthropometry-form__row">
              <FormElement
                label={t('label.height')}
                message={errors.height?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="cm (150.0 - 200.0)"
                  />
                }
              >
                <InputNumberControl
                  name="height"
                  placeholder={t('placeholder.height')}
                  invalid={!!errors.height}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>

              <FormElement
                label={t('label.weight')}
                message={errors.weight?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="kg (40.0 - 100.0)"
                  />
                }
              >
                <InputNumberControl
                  name="weight"
                  placeholder={t('placeholder.weight')}
                  invalid={!!errors.weight}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>
            </div>

            <div className="anthropometry-form__row">
              <FormElement
                label={t('label.biceps')}
                message={errors.biceps?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="biceps"
                  placeholder={t('placeholder.biceps')}
                  invalid={!!errors.biceps}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>

              <FormElement
                label={t('label.triceps')}
                message={errors.triceps?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="triceps"
                  placeholder={t('placeholder.triceps')}
                  invalid={!!errors.triceps}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>
            </div>

            <div className="anthropometry-form__row">
              <FormElement
                label={t('label.subscapula')}
                message={errors.subscapula?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="subscapula"
                  placeholder={t('placeholder.subscapula')}
                  invalid={!!errors.subscapula}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>

              <FormElement
                label={t('label.supraspinale')}
                message={errors.supraspinale?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="supraspinale"
                  placeholder={t('placeholder.supraspinale')}
                  invalid={!!errors.supraspinale}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>
            </div>

            <div className="anthropometry-form__row">
              <FormElement
                label={t('label.iliacCrest')}
                message={errors.iliacCrest?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="iliacCrest"
                  placeholder={t('placeholder.iliacCrest')}
                  invalid={!!errors.iliacCrest}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>

              <FormElement
                label={t('label.abdominal')}
                message={errors.abdominal?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="abdominal"
                  placeholder={t('placeholder.abdominal')}
                  invalid={!!errors.abdominal}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>
            </div>

            <div className="anthropometry-form__row">
              <FormElement
                label={t('label.thigh')}
                message={errors.thigh?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="thigh"
                  placeholder={t('placeholder.thigh')}
                  invalid={!!errors.thigh}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>

              <FormElement
                label={t('label.calf')}
                message={errors.calf?.message}
                adornment={
                  <Typography
                    className="anthropometry-formElement-hint"
                    text="mm (1.0 - 30.0)"
                  />
                }
              >
                <InputNumberControl
                  name="calf"
                  placeholder={t('placeholder.calf')}
                  invalid={!!errors.calf}
                  decimalPlaces={1}
                  autoComplete="off"
                />
              </FormElement>
            </div>
          </div>
        </div>
      </Dialog>
    </FormProvider>
  );
};
