import './index.css';
import { use, useCallback, useMemo } from 'react';
import { useAiAssistantStore } from '../../store/slice';
import { AIRepository } from '../../../../api/ai/repository';
import { AIService } from '../../../../api/ai/service';
import { IChatbotHistoryMessage } from '../../../../types/chatbot';
import { ExamplePrompts } from '../ExamplePrompts';
import {
  ConversationContext,
  ConversationInput,
  ConversationMessageSource,
  ConversationState,
  IConversationMessageBase,
  IConversationSendOptions,
} from '../../../Conversation';
import { ConversationRepository } from '../../../../api/conversation/repository';
import { ConversationService } from '../../../../api/conversation/service';

export const Footer = () => {
  const config = useAiAssistantStore((s) => s.config);
  const { setConversationState } = use(ConversationContext);
  const appendMessages = useAiAssistantStore((s) => s.appendMessages);

  const conversationRepository = useMemo(() => {
    return new ConversationRepository(new ConversationService());
  }, []);

  const aiRepository = useMemo(() => {
    return new AIRepository(new AIService());
  }, []);

  const sendMessage = useCallback(
    (
      query: string,
      options?: IConversationSendOptions
    ): Promise<IConversationMessageBase | undefined> => {
      if (config?.athlete_id === undefined) {
        return Promise.resolve(undefined);
      }
      appendMessages([
        {
          id: `msg_${Date.now()}`,
          created_at: Date.now() / 1000,
          assistant_id: config.assistant_id,
          thread_id: config.thread_id,
          role: 'user',
          content: [
            {
              type: 'text',
              text: {
                value: query,
                annotations: [],
              },
            },
          ],
          feedback_type: '',
        },
      ]);

      setConversationState(ConversationState.PROCESSING);

      return aiRepository
        .sendChatMessage({
          athleteId: config.athlete_id,
          query,
        })
        .then((messages: IChatbotHistoryMessage[]) => {
          // TODO: It can also be an error. This is an extra check.
          if (Array.isArray(messages)) {
            appendMessages(messages);
            setConversationState(ConversationState.IDLE);

            if (messages.length > 0) {
              useAiAssistantStore.getState().updateConfig({
                thread_id: messages[0].thread_id,
                assistant_id: messages[0].assistant_id,
              });
            }

            return {
              id: messages[0].id,
              source: ConversationMessageSource.AnaCoach,
            };
          }

          return undefined;
        })
        .catch(() => {
          return undefined;
        })
        .finally(() => {
          setConversationState(ConversationState.IDLE);
        });
    },
    [
      aiRepository,
      appendMessages,
      config?.assistant_id,
      config?.athlete_id,
      config?.thread_id,
      setConversationState,
    ]
  );

  const sendAudioMessage = useCallback(
    (
      base64: string,
      options?: IConversationSendOptions
    ): Promise<IConversationMessageBase | undefined> => {
      return conversationRepository
        .generateTranscribeTicket()
        .then(({ ticket_id }) => {
          return conversationRepository.transcribeAudioMessage({
            ticket: ticket_id,
            audio_data: base64,
          });
        })
        .then(({ text }) => {
          return sendMessage(text, options);
        })
        .catch(() => {
          setConversationState(ConversationState.IDLE);
          return undefined;
        });
    },
    [conversationRepository, sendMessage, setConversationState]
  );

  const onSelectExample = useCallback(
    (example: string) => {
      sendMessage(example);
    },
    [sendMessage]
  );

  return (
    <div className="ai-assistant__footer">
      <ConversationInput
        onSendText={sendMessage}
        onSendAudio={sendAudioMessage}
        variant="layer1"
      />
      <ExamplePrompts onSelect={onSelectExample} />
    </div>
  );
};
