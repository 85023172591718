import { forwardRef, useCallback, useContext, useMemo } from 'react';
import { IMultiSelectOnChange, ISelectOption, ISelectSize } from './types';
import { ChevronDown, X } from 'lucide-react';
import { IconButton } from '../Button/IconButton';
import { clsx } from 'clsx';
import { Spinner } from '../Spinner';
import { TruncatedTags } from '../TruncatedTags';
import { TruncatedText } from '../TruncatedText';
import { ISpinnerSize } from '../Spinner/types';
import { ITagProps, ITagSize, ITagVariant } from '../Tag/types';
import { SelectContext } from './context';
import {
  textFieldButtonSize,
  textFieldButtonVariant,
} from '../../utils/common';
import { ITextFieldVariant } from '../../types';

export const SelectToggle = forwardRef<HTMLDivElement>((_, ref) => {
  const {
    isOpen,
    localValue: value,
    handleClear,
    onChange,
    placeholder,
    invalid,
    disabled,
    loading,
    size = 'm',
    variant = 'layer2',
    showClearIcon,
    truncateTimeout = 0,
    formatDisplayValue,
    onRenderDisplayValue,
    ...context
  } = useContext(SelectContext);
  const tags = context.multiple && context.tags;

  const fontSizeMap: Record<ISelectSize, string> = {
    s: 'analog-typography--body',
    m: 'analog-typography--body',
    l: 'analog-typography--body',
  };

  const rootClass: string = clsx(
    'analog-select__toggle',
    disabled && 'analog-select__toggle--disabled',
    fontSizeMap[size],
    isOpen && 'analog-select__toggle--focused',
    invalid && 'analog-select__toggle--invalid'
  );

  const spinnerSizeMap: Record<ISelectSize, ISpinnerSize> = {
    s: 'xs',
    m: 's',
    l: 'm',
  };

  const tagsSizeMap: Record<ISelectSize, ITagSize> = {
    s: 'm',
    m: 'm',
    l: 'm',
  };

  const tagsVariantMap: Record<ITextFieldVariant, ITagVariant> = {
    layer1: 'layer2',
    layer2: 'layer3',
    layer3: 'layer4',
    layer4: 'layer5',
    light: 'layer2',
    dark: 'layer3',
  };

  const chevronClass: string = clsx(
    'analog-select__chevron',
    isOpen && 'analog-select__chevron--rotate'
  );

  const displayValue: string = useMemo(() => {
    if (value.length === 0) {
      return '';
    }
    const displayValue = value
      .map((option: ISelectOption) => option.text)
      .join(', ');

    if (formatDisplayValue) {
      return formatDisplayValue(displayValue);
    } else {
      return displayValue;
    }
  }, [value, formatDisplayValue]);

  const onTagRemove = useCallback(
    (option: ITagProps) => {
      if (!context.multiple) {
        return;
      }
      const nextOptions: ISelectOption[] = value.filter(
        (value: ISelectOption) => value.id !== option.id
      );
      if (onChange) {
        (onChange as IMultiSelectOnChange)(nextOptions);
      }
    },
    [context.multiple, onChange, value]
  );

  return (
    <div className={rootClass} tabIndex={0} ref={ref}>
      {placeholder && value.length === 0 && (
        <div className="analog-select__toggle-placeholder">{placeholder}</div>
      )}

      {!tags && displayValue ? (
        onRenderDisplayValue ? (
          onRenderDisplayValue(displayValue)
        ) : (
          <TruncatedText
            text={displayValue}
            className="analog-select__toggle-value"
            timeout={truncateTimeout}
          />
        )
      ) : null}

      {value.length > 0 && tags && (
        <TruncatedTags
          items={value}
          onRemove={onTagRemove}
          disabled={disabled}
          tagProps={{
            size: tagsSizeMap[size],
            variant: tagsVariantMap[variant],
          }}
        />
      )}

      {!disabled && (
        <div className="analog-select__toggle-actions">
          {(showClearIcon || (value.length > 0 && handleClear)) && (
            <IconButton
              size={textFieldButtonSize[size]}
              variant={textFieldButtonVariant[variant]}
              onClick={handleClear}
              content={<X />}
            />
          )}

          {loading ? (
            <div className="analog-select__toggle-spinner">
              <Spinner size={spinnerSizeMap[size]} />
            </div>
          ) : (
            <IconButton
              tabIndex={-1}
              size={textFieldButtonSize[size]}
              variant={textFieldButtonVariant[variant]}
              disabled={disabled}
              content={<ChevronDown className={chevronClass} />}
            />
          )}
        </div>
      )}
    </div>
  );
});

SelectToggle.displayName = 'SelectToggle';
