import './index.css';
import { useTranslation } from 'react-i18next';
import { Page } from '@cycling-web/common';
import React from 'react';
import { SmartChartsHeader } from '../Header';
import { useViewModel } from './useViewModel';
import { SmartChartsContext } from '../../context';
import { AthleteModal } from '../AthletesModal';
import { Chat } from '../Chat';
import { History } from '../History';
import { ChartContainer } from '../Chart';
import { clsx } from 'clsx';
import { onTrackBackButtonClick } from '../../../../ms/utils';
import { useMobileMenu } from '../../../../components/MobileMenu';

export const SmartChartsView = () => {
  const { t } = useTranslation();
  const { context } = useViewModel();
  const {
    expanded,
    showHistory,
    showAthletesModal,
    dismissAthletesModal,
    onSelectAthletes,
    selectedAthletes,
    backUrl,
  } = context;
  const onRenderMenu = useMobileMenu();

  const rootClass = clsx(
    'sc2__wrapper',
    showHistory && 'sc2__wrapper--history',
    expanded && 'sc2__wrapper--expanded'
  );

  return (
    <SmartChartsContext.Provider value={context}>
      <Page
        pageHeaderProps={{
          title: t('label.smart_charts'),
          backUrl,
          onBackUrlClick: onTrackBackButtonClick,
          onRenderMenu,
        }}
      >
        <div className="sc2__container">
          <div className={rootClass}>
            <SmartChartsHeader />
            <div className="sc2__main">
              {selectedAthletes.length > 0 && <ChartContainer />}
              <Chat />
            </div>
          </div>
          {showHistory && <History />}
        </div>

        {showAthletesModal && (
          <AthleteModal
            onDismiss={dismissAthletesModal}
            onSubmit={onSelectAthletes}
            selectedAthletes={selectedAthletes}
          />
        )}
      </Page>
    </SmartChartsContext.Provider>
  );
};
