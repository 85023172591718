import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton, Toast, useBoolean } from '@cycling-web/analog-ui';
import { OnboardingIntegrationItem } from '../IntegrationItem';
import { ConsentDialog } from '../ConsentDialog';
import { ConnectedDialog } from '../ConnectedDialog';
import OuraLogo from '../../../assets/illustrations/oura-logo.svg?react';
import { Device } from '@cycling-web/common';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';
import { getIntegrationsLink } from '../../../utils/getIntegrationsLink';
import { LS } from '../../../constants';
import { IPlatform } from '../../../types/platform';
import { usePlatformsStore } from '../../../store/platforms/slice';

type IProps = {
  platform: IPlatform;
  platformLoaded: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
  showDetails?: boolean;
};

export const Oura = ({
  platform,
  platformLoaded,
  onConnect,
  onDisconnect,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const code = params.get('code');
  const deviceParam = params.get('device');

  const integrationUrl = useRef<string | undefined>(undefined);
  const fetchingRef = useRef<boolean>(false);
  const isConsentGiven = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    value: showConsentDialog,
    setTrue: openConsentDialog,
    setFalse: dismissConsentDialog,
  } = useBoolean(false);

  const {
    value: showConnectedDialog,
    setTrue: openConnectedDialog,
    setFalse: dismissConnectedDialog,
  } = useBoolean(false);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const connectDevice = useCallback((): Promise<void> => {
    if (!code || fetchingRef.current) {
      return Promise.reject();
    }

    setLoading(true);
    fetchingRef.current = true;

    return platformRepository
      .addOuraAccount({
        code,
      })
      .then(() => {
        onConnect();
        openConnectedDialog();
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        fetchingRef.current = false;
      });
  }, [code, platformRepository, openConnectedDialog, onConnect]);

  useEffect(() => {
    if (deviceParam === Device.Oura) {
      connectDevice();
    }
  }, [connectDevice, deviceParam]);

  const handleConnect = useCallback(() => {
    setLoading(true);
    platformRepository
      .getOuraClientId()
      .then((clientId: string) => {
        const url = getIntegrationsLink({
          integration: Device.Oura,
          redirectUrl: `${window.location.origin}/integrations`,
          clientId,
        });
        localStorage.setItem(
          LS.PreviousPage,
          `${window.location.href}?device=${Device.Oura}`
        );
        integrationUrl.current = url;
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [platformRepository]);

  const handleIntegration = useCallback(() => {
    if (isConsentGiven.current || code) {
      connectDevice();
    } else {
      handleConnect();
      openConsentDialog();
    }
  }, [code, connectDevice, handleConnect, openConsentDialog]);

  const handleGiveConsent = useCallback((): Promise<void> => {
    dismissConsentDialog();
    window.open(integrationUrl.current);
    return Promise.resolve();
  }, [dismissConsentDialog]);

  const handleDisconnect = useCallback(() => {
    usePlatformsStore.getState().disconnect(platform);
    return platformRepository
      .deleteOuraAccount()
      .then(() => {
        if (onDisconnect) {
          onDisconnect();
        }
      })
      .catch(() => {
        Toast.error({
          title: t('error.disconnect_device_title'),
        });
        usePlatformsStore.getState().setConnected(platform);
      });
  }, [onDisconnect, platform, platformRepository, t]);

  return (
    <>
      {platformLoaded ? (
        <OnboardingIntegrationItem
          icon={<OuraLogo />}
          type="device"
          handleIntegration={handleIntegration}
          loading={loading}
          error={error}
          platform={platform}
          showDetails={showDetails}
          handleDisconnect={handleDisconnect}
        />
      ) : (
        <Skeleton width="100%" height="88px" />
      )}
      {showConsentDialog && (
        <ConsentDialog
          onDismiss={dismissConsentDialog}
          onSubmit={handleGiveConsent}
        />
      )}
      {showConnectedDialog && (
        <ConnectedDialog
          integrationName={t('label.oura')}
          onDismiss={dismissConnectedDialog}
        />
      )}
    </>
  );
};
