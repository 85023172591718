import './index.css';
import { Page } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { Calendar } from './components/Calendar';
import { WorkoutsCalendarContext } from './context';
import { CalendarPageTitle } from './components/PageTitle';
import { AthleteStats } from '../../components/AthleteStats';
import { CalendarAddModal } from './components/CalendarAddModal';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { useMobileMenu } from '../../components/MobileMenu';

export const WorkoutsView = () => {
  const { context } = useViewModel();
  const {
    athlete,
    athleteLoaded,
    showCalendarAddModal,
    dismissCalendarAddModal,
    addModalProps,
  } = context;
  const onRenderMenu = useMobileMenu();

  return (
    <WorkoutsCalendarContext value={context}>
      <Page pageHeaderProps={{ title: <CalendarPageTitle />, onRenderMenu }}>
        <header className="workouts-header">
          <AthleteStats athlete={athlete} athleteLoaded={athleteLoaded} />
        </header>
        <Calendar />
      </Page>
      {(addModalProps || showCalendarAddModal) && (
        <CalendarAddModal
          {...addModalProps}
          onDismiss={dismissCalendarAddModal}
        />
      )}
    </WorkoutsCalendarContext>
  );
};

export const Workouts = () => {
  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().calendar}
    >
      <WorkoutsView />
    </PageFiltersContextProvider>
  );
};
