import './index.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ITabsItem } from '@cycling-web/analog-ui';
import { ROUTES } from '../../../../router/routes';
import { PageTabs } from '@cycling-web/common';
import { useMemo } from 'react';
import { IPinnedTab } from '../../../../types/smart-charts';
import { usePinnedTabStore } from '../../../../store/pinned-tabs/slice';

export const AthleteTabs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const url = pathname.split('/');

  const pinnedTabs = usePinnedTabStore((s) => s.pinnedTabs);

  const items: ITabsItem[] = useMemo(() => {
    const defaultTabs: ITabsItem[] = [
      {
        path: `../${ROUTES.PROFILE}`,
        text: t('label.profile'),
        isActive: url.at(-1) === ROUTES.PROFILE,
        preserveSearchParams: true,
      },
      {
        path: `../${ROUTES.TRAINING_LOAD}`,
        text: t('label.training_load'),
        isActive: url.at(-1) === ROUTES.TRAINING_LOAD,
        preserveSearchParams: true,
      },
      {
        path: `../${ROUTES.PERFORMANCE}`,
        text: t('label.performance'),
        isActive: url.at(-1) === ROUTES.PERFORMANCE,
        preserveSearchParams: true,
      },
      {
        path: `../${ROUTES.WELLNESS}`,
        text: t('label.wellness'),
        isActive: url.at(-1) === ROUTES.WELLNESS,
        preserveSearchParams: true,
      },
      {
        path: `../${ROUTES.HEALTH}`,
        text: t('label.health'),
        isActive: url.at(-1) === ROUTES.HEALTH || url.at(-2) === ROUTES.HEALTH,
        preserveSearchParams: true,
      },
    ];

    pinnedTabs.forEach((tab: IPinnedTab) => {
      defaultTabs.push({
        path: `../${tab.tab_id}`,
        text: tab.tab_name,
        isActive: url.at(-1) === tab.tab_id,
        preserveSearchParams: true,
      });
    });

    return defaultTabs;
  }, [t, url, pinnedTabs]);

  return <PageTabs tabs={items} />;
};
