import { MouseEvent } from 'react';
import {
  IconButton,
  ISelectOption,
  TruncatedText,
} from '@cycling-web/analog-ui';
import { PencilLine, Trash2 } from 'lucide-react';

type IProps = {
  option: ISelectOption;
  onEdit?: (option: ISelectOption) => void;
  onDelete?: (option: ISelectOption) => void;
};

export const PeriodSelectOption = ({ option, onEdit, onDelete }: IProps) => {
  const handleEdit = (e: MouseEvent) => {
    e.stopPropagation();
    if (onEdit) {
      onEdit(option);
    }
  };

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete(option);
    }
  };

  return (
    <div className="period-select-option">
      <div className="period-select-option__text">
        <TruncatedText text={option.text} />
      </div>
      {(onEdit || onDelete) && (
        <div className="period-select-option__actions">
          {onEdit && (
            <IconButton
              variant="quietLayer3"
              size="xs"
              content={<PencilLine />}
              onClick={handleEdit}
            />
          )}
          {onDelete && (
            <IconButton
              variant="quietLayer3"
              size="xs"
              content={<Trash2 />}
              onClick={handleDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};
