import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AudioState,
  ConversationState,
  IAnaResponsePayload,
  IConversationContext,
  IConversationMessageBase,
} from '../types';
import { getApiUrl } from '../../../utils/getEnvironment';
import { LS } from '../../../constants';
import { parseTeamName } from '../../../utils/teamName';
import { useWebSockets } from './useWebSockets';

export const ConversationContext = createContext<IConversationContext>(
  {} as IConversationContext
);

type IProps = {
  children: ReactNode;
  onSocketMessage?: (message: IAnaResponsePayload) => void;
};

export const ConversationContextProvider = ({
  children,
  onSocketMessage,
}: IProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioAbortControllerRef = useRef<AbortController | null>(null);

  const [activeAudioState, setActiveAudioState] = useState<AudioState>(
    AudioState.IDLE
  );
  const [playingMessage, setPlayingMessage] = useState<
    IConversationMessageBase | undefined
  >(undefined);

  const [conversationState, setConversationState] = useState<ConversationState>(
    ConversationState.IDLE
  );

  const { emit, emitContext, socketConnected } = useWebSockets({
    onSocketMessage,
    setConversationState,
  });

  const handleCanPlayThrough = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.play();
      setActiveAudioState(AudioState.PLAYING);
    }
  }, [audioRef]);

  const handleEnded = useCallback(() => {
    setPlayingMessage(undefined);
    setActiveAudioState(AudioState.IDLE);
  }, []);

  const handleError = useCallback(() => {
    setPlayingMessage(undefined);
    setActiveAudioState(AudioState.IDLE);
  }, []);

  const removeAudioListeners = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.removeEventListener(
        'canplaythrough',
        handleCanPlayThrough
      );
      audioRef.current.removeEventListener('ended', handleEnded);
      audioRef.current.removeEventListener('error', handleError);
    }
  }, [handleCanPlayThrough, handleEnded, handleError]);

  const addAudioListeners = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('canplaythrough', handleCanPlayThrough);
      audioRef.current.addEventListener('ended', handleEnded);
      audioRef.current.addEventListener('error', handleError);
    }
  }, [handleCanPlayThrough, handleEnded, handleError]);

  const handleStopPlayMessage = useCallback(() => {
    if (!audioRef.current) {
      return;
    }

    audioRef.current.pause();
    audioRef.current.currentTime = 0;

    removeAudioListeners();
    audioRef.current = null;

    setPlayingMessage(undefined);
    setActiveAudioState(AudioState.IDLE);
  }, [removeAudioListeners]);

  const fetchAudio = useCallback(
    async (message: IConversationMessageBase) => {
      if (audioAbortControllerRef.current) {
        audioAbortControllerRef.current.abort();
      }

      audioAbortControllerRef.current = new AbortController();

      const baseUrl = getApiUrl();
      const url = `${baseUrl}/v1/ai/v1/ana_voice/speak_a_message?stream=true`;
      const team = localStorage.getItem(LS.Team) || '';

      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem(LS.AccessToken)}`,
          'x-team-name': parseTeamName(team),
        },
        body: JSON.stringify({
          message_id: message.id,
          source: message.source,
        }),
        signal: audioAbortControllerRef.current.signal,
      });
    },
    [audioAbortControllerRef]
  );

  const playMp3 = useCallback(
    (message: IConversationMessageBase) => {
      if (!audioRef.current) {
        return;
      }

      const mediaSource = new MediaSource();

      audioRef.current.src = URL.createObjectURL(mediaSource);
      audioRef.current.controls = false;

      removeAudioListeners();
      addAudioListeners();

      mediaSource.addEventListener('sourceopen', async () => {
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');

        try {
          const response = await fetchAudio(message);

          const reader = response.body?.getReader();

          function processChunk({
            done,
            value,
          }: {
            done: boolean;
            value?: Uint8Array;
          }) {
            if (done) {
              mediaSource.endOfStream();
              return;
            }

            sourceBuffer.addEventListener(
              'updateend',
              () => {
                reader?.read().then(processChunk);
              },
              { once: true }
            );

            if (value) {
              sourceBuffer.appendBuffer(value);
            }
          }

          reader?.read().then(processChunk);
        } catch (error: any) {
          if (error.name === 'AbortError') {
            return null;
          }
          setPlayingMessage(undefined);
          setActiveAudioState(AudioState.IDLE);
        }
      });

      audioRef.current?.play();
    },
    [addAudioListeners, fetchAudio, removeAudioListeners]
  );

  const playMp3Fallback = useCallback(
    async (message: IConversationMessageBase) => {
      if (!audioRef.current) {
        return;
      }
      console.warn('MediaSource does not support MP3. Using fallback.');

      try {
        const response = await fetchAudio(message);
        const blob = await response.blob();

        if (audioRef.current.src) {
          URL.revokeObjectURL(audioRef.current.src);
        }

        audioRef.current.src = URL.createObjectURL(blob);

        removeAudioListeners();
        addAudioListeners();

        audioRef.current.play();
      } catch (error: any) {
        if (error.name === 'AbortError') {
          return null;
        }
        setPlayingMessage(undefined);
        setActiveAudioState(AudioState.IDLE);
      }
    },
    [addAudioListeners, fetchAudio, removeAudioListeners]
  );

  const handlePlayMessage = useCallback(
    (message: IConversationMessageBase) => {
      if (audioRef.current) {
        handleStopPlayMessage();
      }

      setPlayingMessage(message);
      setActiveAudioState(AudioState.LOADING);

      audioRef.current = new Audio();

      const isMSESupported = MediaSource.isTypeSupported('audio/mpeg');

      if (isMSESupported) {
        playMp3(message);
      } else {
        playMp3Fallback(message);
      }
    },
    [audioRef, handleStopPlayMessage, playMp3, playMp3Fallback]
  );

  // ---------------------------------------------------------------------------

  const context = useMemo((): IConversationContext => {
    return {
      audioRef,
      audioAbortControllerRef,
      conversationState,
      setConversationState,
      playingMessage,
      setPlayingMessage,
      handlePlayMessage,
      handleStopPlayMessage,
      activeAudioState,
      emit,
      emitContext,
      onSocketMessage,
      socketConnected,
    };
  }, [
    conversationState,
    playingMessage,
    handlePlayMessage,
    handleStopPlayMessage,
    activeAudioState,
    emit,
    emitContext,
    onSocketMessage,
    socketConnected,
  ]);

  return <ConversationContext value={context}>{children}</ConversationContext>;
};
