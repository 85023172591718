import './locales';
import './styles.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { App } from './App';
import { intercept } from './axios-instance';
import { StrictMode } from 'react';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

intercept();

async function enableMocking() {
  const enabled = false;

  if (enabled) {
    const { worker } = await import('./msw/browser');
    return worker.start();
  }
}

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
});
