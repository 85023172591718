import { ChartColor } from './colors';
import {
  IEChartAxisPointerOption,
  IEChartLegendOption,
  IEChartOption,
  IEChartSeriesOption,
  IEChartTooltipOption,
  IEChartXAXisOption,
  IEChartYAXisOption,
} from '../types';

export const defaultChartText = {
  color: ChartColor.Text,
  fontSize: 12,
  fontFamily: 'ESKlarheitKurrent, sans-serif',
};

export const defaultAxisPointer: IEChartAxisPointerOption = {
  type: 'cross',
  label: {
    show: false,
  },
};

export const defaultGrid = {
  left: 60,
  right: 60,
  top: 24,
  show: false,
};

const defaultAxis = {
  splitLine: {
    show: false,
  },
  axisPointer: {
    type: 'line',
    label: {
      ...defaultChartText,
      show: false,
    },
    lineStyle: {
      color: ChartColor.PointerLine,
    },
  },
  axisLine: {
    show: true,
    lineStyle: {
      type: 'dashed',
      dashOffset: 8,
      color: ChartColor.AxisLine,
      width: 1,
    },
  },
  axisLabel: {
    show: true,
    ...defaultChartText,
    margin: 12,
  },
  axisTick: {
    show: false,
    alignWithLabel: true,
    lineStyle: {
      color: ChartColor.AxisLine,
      width: 1,
    },
  },
  nameTextStyle: {
    ...defaultChartText,
    opacity: 0,
  },
} as IEChartXAXisOption & IEChartYAXisOption;

export const defaultXAxis: IEChartXAXisOption = {
  ...defaultAxis,
  axisLabel: {
    margin: 10,
  },
};

export const defaultYAxis: IEChartYAXisOption = {
  ...defaultAxis,
  axisPointer: { show: false },
  axisLine: { show: false },
};

export const defaultLegend: IEChartLegendOption = {
  show: false,
  top: 'bottom',
  right: 0,
  textStyle: {
    ...defaultChartText,
  },
};

export const defaultTooltip: IEChartTooltipOption = {
  trigger: 'axis',
  padding: 8,
  borderRadius: 8,
  borderColor: ChartColor.TooltipBorder,
  backgroundColor: ChartColor.TooltipBackground,
  textStyle: {
    ...defaultChartText,
  },
  axisPointer: { type: 'cross' },
  position: function (point, params, dom, rect, size) {
    const tooltipWidth = size.contentSize[0];
    const tooltipHeight = size.contentSize[1];
    const chartWidth = size.viewSize[0];

    let x = point[0] + 16;
    const y = point[1] - tooltipHeight / 2;

    if (x + tooltipWidth > chartWidth) {
      x = point[0] - tooltipWidth - 16;
    }

    return [x, y];
  },
};

export const defaultSeries: IEChartSeriesOption = {
  barMaxWidth: 24,
  markArea: {
    silent: true,
    animation: false,
  },
  markLine: {
    symbol: ['none', 'none'],
    silent: true,
    animation: false,
    label: {
      color: ChartColor.AxisLabelText,
    },
  },
};

export const defaultOption: IEChartOption = {
  color: [
    ChartColor.LightBlue,
    ChartColor.Blue,
    ChartColor.LightPurple,
    ChartColor.Purple,
    ChartColor.Orange,
    ChartColor.Red,
    ChartColor.LightRed,
    ChartColor.Rose,
    ChartColor.LightRose,
    ChartColor.Magenta,
    ChartColor.LightMagenta,
    ChartColor.LightCyan,
    ChartColor.Cyan,
    ChartColor.LightGreen,
    ChartColor.Green,
    ChartColor.LightYellow,
    ChartColor.Yellow,
  ],
  legend: defaultLegend,
  grid: defaultGrid,
  xAxis: defaultXAxis,
  yAxis: defaultYAxis,
  tooltip: defaultTooltip,
  series: defaultSeries,
  axisPointer: defaultAxisPointer,
};
