import './index.css';
import { IMentionOption } from '../ConversationTextEditor/mention/types';
import { Avatar } from '@cycling-web/analog-ui';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IAthlete } from '../../../../types/athletes';

type IProps = {
  option: IMentionOption;
  selected: boolean;
};

export const MentionOption = ({ option, selected }: IProps) => {
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  const athlete: IAthlete | null = !isNaN(Number(option?.id))
    ? athletesMap[+option.id]
    : null;

  if (!athlete) {
    return null;
  }

  return (
    <div className="conversation__mention-option">
      <Avatar size="s" src={athlete.picture} text={athlete.fullName} />
    </div>
  );
};
