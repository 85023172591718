import { useTranslation } from 'react-i18next';
import {
  Button,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  Skeleton,
} from '@cycling-web/analog-ui';
import { ChevronDown } from 'lucide-react';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { AnthropometryFormModal } from '../../../../components/AnthropometryFormModal';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { HeightWeightFormModal } from '../../../../components/HeightWeightFormModal';
import { useUserRole } from '../../../../hooks/useUserRole';
import { ReportIllnessFormModal } from '../../../../components/ReportIllnessFormModal';
import { ReportRehabilitationFormModal } from '../../../../components/ReportRehabilitationFormModal';
import { ReportInjuryFormModal } from '../../../../components/ReportInjuryFormModal';
import {
  IMedicalReport,
  IMedicalReportRecord,
} from '../../../../types/medical';
import { MedicalRepository } from '../../../../api/medical/repository';
import { MedicalService } from '../../../../api/medical/service';
import { useAthleteMedicalReportsStore } from '../../../AthleteMedicalReports/store/slice';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import { TrackingEvent } from '../../../../ms/tracking-entities';

type IProps = {
  anchor?: ReactNode;
};

export const AddDataMenu = ({ anchor }: IProps) => {
  const { t } = useTranslation();
  const { isAdmin, isDoctor, isSecondaryDoctor, isPhysiotherapist } =
    useUserRole();
  const showMedicalOptions =
    isAdmin || isDoctor || isSecondaryDoctor || isPhysiotherapist;

  const athlete = useAthleteStore((s) => s.athlete);
  const athleteLoaded = useAthleteStore((s) => s.athleteLoaded);

  const [showAddDataModal, setShowAddDataModal] = useState<string>('');

  const dismissAddDataModal = useCallback(() => {
    setShowAddDataModal((modalName) => {
      trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
        modal: modalName,
      });

      return '';
    });
  }, []);

  const options: IMenuOption[] = useMemo(() => {
    const options: IMenuOption[] = [];

    options.push(
      { id: 'anthropometry', text: t('label.anthropometry') },
      { id: 'height_weight', text: t('label.height_weight') }
    );

    if (showMedicalOptions) {
      options.push(
        { type: 'divider' },
        { id: 'report_illness', text: t('label.report_illness') },
        { id: 'report_rehabilitation', text: t('label.report_rehabilitation') },
        { id: 'report_injury', text: t('label.report_injury') }
      );
    }

    return options;
  }, [t, showMedicalOptions]);

  const onMenuChange = useCallback((option: IMenuBaseOption) => {
    if (option.id) {
      trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
        modal: option.id,
      });

      setShowAddDataModal(option.id);
    }
  }, []);

  const handleAppendReport = useCallback((report: IMedicalReport) => {
    const medicalRepository = new MedicalRepository(new MedicalService());
    const record: IMedicalReportRecord =
      medicalRepository.convertReportToRecord(report);

    useAthleteMedicalReportsStore.getState().appendReport(record);
    useAthletesStore.getState().updateAthlete({
      id: record.athleteId,
      medicalReport: report,
    });
  }, []);

  if (!athleteLoaded) {
    return (
      <div className="athlete-tabs__actions">
        <Skeleton width="40px" height="40px" />
      </div>
    );
  }

  return (
    <div className="athlete-tabs__actions">
      <Menu
        options={options}
        onChange={onMenuChange}
        dropdownProps={{
          anchor: anchor || (
            <Button endIcon={<ChevronDown />} content={t('action.add_data')} />
          ),
        }}
      />

      {athlete && (
        <>
          {showAddDataModal === 'anthropometry' && (
            <AnthropometryFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
            />
          )}
          {showAddDataModal === 'height_weight' && (
            <HeightWeightFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
            />
          )}
          {showAddDataModal === 'report_illness' && (
            <ReportIllnessFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
          {showAddDataModal === 'report_rehabilitation' && (
            <ReportRehabilitationFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
          {showAddDataModal === 'report_injury' && (
            <ReportInjuryFormModal
              athlete={athlete}
              onDismiss={dismissAddDataModal}
              onSubmitSuccess={handleAppendReport}
            />
          )}
        </>
      )}
    </div>
  );
};
