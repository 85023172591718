import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IPeak } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { useGroupReportsStore } from '../../GroupDetails/store/slice';
import { filterAthletesDelegate } from '../utils/filterAthletesDelegate';
import { IAthlete } from '../../../types/athletes';
import { useGroupAthletes } from '../../../hooks/useGroupAthletes';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IGroupReportsFilters } from '../../GroupDetails/types';

export const useFetchGroupPeaks = () => {
  const { t } = useTranslation();
  const { filters } = usePageFilters<IGroupReportsFilters>();
  const setPeaks = useGroupReportsStore((s) => s.setPeaks);
  const setPeaksLoaded = useGroupReportsStore((s) => s.setPeaksLoaded);
  const { groupAthletes, loading } = useGroupAthletes();

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (loading || groupAthletes.length === 0) {
      return;
    }

    const filteredAthletes = groupAthletes.filter(
      filterAthletesDelegate(filters)
    );

    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getPeak({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: filteredAthletes.map((a: IAthlete) => a.id),
        allAthletes: false,
      })
      .then((peaks: IPeak[]) => {
        setPeaks(peaks);
      })
      .catch((error: AxiosError) => {
        setPeaksLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_team_power_profile_title'),
              message: t('error.get_team_power_profile_message'),
            },
            { toastId: 'get_team_power_profile' }
          );
        }
      });
  }, [
    loading,
    groupAthletes,
    filters,
    performanceRepository,
    setPeaks,
    setPeaksLoaded,
    t,
  ]);
};
