import { use, useCallback, useEffect } from 'react';
import {
  ConversationContext,
  IAnaContextEntity,
  IAnaContextEntityMetaData,
  IAnaContextType,
} from '../../../Conversation';
import { useLocation } from 'react-router';
import { useUsersStore } from '../../../../store/users/slice';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { ROUTES } from '../../../../router/routes';
import { IAthlete } from '../../../../types/athletes';
import { useGroupsStore } from '../../../../store/groups/slice';
import { IGroup } from '../../../../types/groups';

export const ContextEmitter = () => {
  const { pathname } = useLocation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const groups = useGroupsStore((s) => s.groups);
  const userProfile = useUsersStore((s) => s.userProfile);
  const { emitContext, socketConnected } = use(ConversationContext);

  const buildNavEntity = useCallback((url: string[]): IAnaContextEntity => {
    const entity: IAnaContextEntity = {
      entity_metadata: {
        context_type: IAnaContextType.NAV,
        main_page: {
          page_name: url[0],
        },
      },
      user_action: 'add',
    };

    if (url[0] === ROUTES.ATHLETES) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.athlete_id = Number(url[1]);
      }

      if (url[2] !== undefined) {
        entity.entity_metadata.main_page.sub_page = {
          sub_page_name: url[2],
        };
      }

      if (url[2] === ROUTES.HEALTH) {
        if (url[3] !== undefined) {
          entity.entity_metadata.main_page.sub_page.medical_report_id = Number(
            url[3]
          );
        }
      }
    } else if (url[0] === ROUTES.GROUPS) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.group_id = Number(url[1]);
      }

      // Athlete inside group
      if (url[2] === ROUTES.ATHLETES && url[3] !== undefined) {
        entity.entity_metadata.main_page.page_name = url[2];
        entity.entity_metadata.main_page.athlete_id = Number(url[1]);

        if (url[4] !== undefined) {
          entity.entity_metadata.main_page.sub_page = {
            sub_page_name: url[4],
          };
        }

        if (url[4] === ROUTES.HEALTH) {
          if (url[5] !== undefined) {
            entity.entity_metadata.main_page.sub_page.medical_report_id =
              Number(url[5]);
          }
        }
      }
    } else if (url[0] === ROUTES.CALENDAR) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.athlete_id = Number(url[1]);
      }
    } else if (url[0] === ROUTES.WORKOUT) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.workout_id = Number(url[1]);
      }
    } else if (url[0] === ROUTES.EVENT) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.event_id = Number(url[1]);
      }
    } else if (url[0] === ROUTES.PRESCRIPTION) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.prescription_id = Number(url[1]);
      }
    } else if (url[0] === ROUTES.PERFORMANCE_REPORT) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.sub_page = {
          sub_page_name: url[1],
        };
      }
    } else if (url[0] === ROUTES.MEDICAL_REPORT_TABLE) {
      if (url[1] !== undefined) {
        entity.entity_metadata.main_page.medical_report_id = Number(url[1]);
      }
    }

    return entity;
  }, []);

  const buildAthleteEntity = useCallback(
    (url: string[]): IAnaContextEntity | undefined => {
      const isGroupPage = url[0] === ROUTES.GROUPS;
      const isAthletePage = url[0] === ROUTES.ATHLETES;

      if (!isGroupPage && !isAthletePage) {
        return undefined;
      }

      if (!url[1]) {
        return undefined;
      }

      const athleteId = isGroupPage ? url[3] : url[1];
      const athlete: IAthlete | undefined = athletesMap[athleteId];

      if (!athlete) {
        return undefined;
      }

      return {
        entity_metadata: {
          context_type: IAnaContextType.ATHLETE,
          athlete_id: Number(athlete.id),
          athlete_full_name: athlete.fullName,
        },
        user_action: 'add',
      };
    },
    [athletesMap]
  );

  const buildGroupEntity = useCallback(
    (url: string[]): IAnaContextEntity | undefined => {
      if (url[0] !== ROUTES.GROUPS || !url[1]) {
        return undefined;
      }

      const groupId = url[1];
      const group: IGroup | undefined = groups.find(
        (g) => g.id.toString() === groupId.toString()
      );

      if (!group) {
        return undefined;
      }

      const linked_entities: IAnaContextEntityMetaData[] = group.athleteIds
        .map((id) => {
          const athlete = athletesMap[id];

          return {
            context_type: IAnaContextType.ATHLETE,
            athlete_id: athlete?.id || null,
            athlete_full_name: athlete?.fullName || null,
          };
        })
        .filter((e) => e.athlete_id !== null);

      return {
        entity_metadata: {
          context_type: IAnaContextType.GROUP,
          group_id: group.id,
          group_name: group.name,
          linked_entities,
        },
        user_action: 'add',
      };
    },
    [groups, athletesMap]
  );

  useEffect(() => {
    if (!userProfile || !socketConnected) {
      return;
    }

    const url = pathname.split('/').filter((_, i) => i > 2);
    const navEntity = buildNavEntity(url);
    const athleteEntity = buildAthleteEntity(url);
    const groupEntity = buildGroupEntity(url);

    const entities: IAnaContextEntity[] = [navEntity];

    if (athleteEntity) {
      entities.push(athleteEntity);
    }

    if (groupEntity) {
      entities.push(groupEntity);
    }

    emitContext(entities);
  }, [
    emitContext,
    pathname,
    userProfile,
    socketConnected,
    buildNavEntity,
    buildAthleteEntity,
    buildGroupEntity,
    athletesMap,
    groups,
  ]);

  return null;
};
