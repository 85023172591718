import { useFetchPeak } from './hooks/useFetchPeak';
import { useFetchPowerDuration } from './hooks/useFetchPowerDuration';
import { useFetchPowerDerivatives } from './hooks/useFetchPowerDerivatives';
import { useFetchFatigueResistance } from './hooks/useFetchFatigueResistance';
import { initialFilters, useAthletePerformanceStore } from './store/slice';
import { useEffect, useMemo } from 'react';
import {
  IFatigueResistance,
  IPdCurve,
  IPowerDerivative,
} from '../../types/performance';
import { useAthleteStore } from '../../store/athlete/slice';
import { useParams } from 'react-router';
import { useFetchWorkoutSeasons } from '../../hooks/useFetchWorkoutSeasons';
import { usePageFilters } from '../../context/PageFilters';
import { IAthleteDetailsFilters } from '../AthleteDetails/types';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const useViewModel = () => {
  const { athleteId } = useParams();
  useFetchWorkoutSeasons({ athleteIds: athleteId });
  useFetchPeak();
  useFetchPowerDuration();
  useFetchPowerDerivatives();
  useFetchFatigueResistance();

  const { filters, handleChangeFilter, initPageFilters } =
    usePageFilters<IAthleteDetailsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().athleteDetails);
  }, [initPageFilters]);

  const athlete = useAthleteStore((s) => s.athlete);

  const peakRecord = useAthletePerformanceStore((s) => s.peak);
  const peak = useMemo(() => {
    return filters?.period ? peakRecord[filters.period] || [] : [];
  }, [filters?.period, peakRecord]);
  const peakLoaded = useAthletePerformanceStore((s) => s.peakLoaded);

  const pdCurve: IPdCurve | null = useAthletePerformanceStore((s) => s.pdCurve);
  const pdCurveRelative: IPdCurve | null = useAthletePerformanceStore(
    (s) => s.pdCurveRelative
  );
  const pdCurveLoaded: boolean = useAthletePerformanceStore(
    (s) => s.pdCurveLoaded
  );

  const powerDerivatives: IPowerDerivative | null = useAthletePerformanceStore(
    (s) => s.powerDerivatives
  );
  const powerDerivativesLoaded: boolean = useAthletePerformanceStore(
    (s) => s.powerDerivativesLoaded
  );

  const fatigueResistance: IFatigueResistance | null =
    useAthletePerformanceStore((s) => s.fatigueResistance);
  const fatigueResistanceRelative: IFatigueResistance | null =
    useAthletePerformanceStore((s) => s.fatigueResistanceRelative);
  const fatigueResistanceLoaded: boolean = useAthletePerformanceStore(
    (s) => s.fatigueResistanceLoaded
  );

  useEffect(() => {
    return () => {
      useAthletePerformanceStore.getState().setFilters(initialFilters);
    };
  }, []);

  return {
    filters,
    handleChangeFilter,
    peak,
    peakLoading: !peakLoaded,
    pdCurve,
    pdCurveRelative,
    pdCurveLoading: !pdCurveLoaded,
    powerDerivatives,
    powerDerivativesLoading: !powerDerivativesLoaded,
    fatigueResistance,
    fatigueResistanceRelative,
    fatigueResistanceLoading: !fatigueResistanceLoaded,
    athlete,
  };
};
