import './index.css';
import { WidgetHeader } from '../WidgetHeader';
import { Widget } from '../Widget';
import { useTranslation } from 'react-i18next';
import { HRVvsRHRChart } from './components/HRVvsRHRChart';
import { useFetchAthleteHeartMeasurements } from './hooks/useFetchAthleteHeartMeasurements';

export const HrvVsRhr = () => {
  const { t } = useTranslation();
  const { heartMeasurements, heartMeasurementsLoaded } =
    useFetchAthleteHeartMeasurements();

  return (
    <Widget className="hrv-vs-rhr-widget">
      <WidgetHeader title={t('label.hrv_vs_rhr')} />
      <HRVvsRHRChart
        heartMeasurements={heartMeasurements}
        loading={!heartMeasurementsLoaded}
      />
    </Widget>
  );
};
