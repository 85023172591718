import './index.css';
import { Card, CardHeader } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useWorkoutStore } from '../../store/slice';
import { hoursToHHMMSS } from '../../../../utils/date-time';
import { EMPTY } from '../../../../constants';
import { Table } from './Table';
import { LabelValueElement } from '../../../../components/LabelValueElement';

export const WorkoutSelection = () => {
  const { t } = useTranslation();
  const workout = useWorkoutStore((s) => s.workout);
  const workoutLoaded = useWorkoutStore((s) => s.workoutLoaded);

  const duration = workout?.Workout.TotalTime
    ? hoursToHHMMSS(workout.Workout.TotalTime)
    : EMPTY;

  const distance = workout?.Workout.Distance
    ? Math.round(workout.Workout.Distance / 1000)
    : EMPTY;

  const tss = workout?.Workout.TssActual
    ? Math.round(workout.Workout.TssActual)
    : EMPTY;

  const work = workout?.Workout.Energy
    ? Math.round(workout.Workout.Energy)
    : EMPTY;

  const elevation = workout?.Workout.ElevationGain
    ? Math.round(workout.Workout.ElevationGain)
    : EMPTY;

  const avgPower = workout?.Workout.PowerAverage
    ? Math.round(workout.Workout.PowerAverage)
    : EMPTY;

  const nPower = workout?.Workout.NormalizedPower
    ? Math.round(workout.Workout.NormalizedPower)
    : EMPTY;

  return (
    <Card variant="layer3" className="workout-card__selection">
      <CardHeader title={t('label.entire_workout')} />

      <div className="workout-card__selection-stats">
        <LabelValueElement
          name={t('label.duration')}
          value={duration}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={t('label.distance')}
          value={`${distance} ${t('units.km')}`}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={'Avg P'}
          value={`${avgPower} ${t('units.w')}`}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={'NP'}
          value={`${nPower} ${t('units.w')}`}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={t('label.tss')}
          value={tss}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={t('label.if')}
          value={workout?.Workout.IF ?? EMPTY}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={t('label.elevation')}
          value={`${elevation} ${t('units.m')}`}
          size="s"
          loading={!workoutLoaded}
        />

        <LabelValueElement
          name={t('label.work')}
          value={`${work} ${t('units.kj')}`}
          size="s"
          loading={!workoutLoaded}
        />
      </div>

      <Table />
    </Card>
  );
};
