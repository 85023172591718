import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
} from '@cycling-web/analog-ui';
import { ReactNode, use, useCallback, useMemo } from 'react';
import { IAthlete } from '../../../../types/athletes';
import { Athlete } from '../../../../components/columns/Athlete';
import { ArrowRight, Heart } from 'lucide-react';
import { ROUTES } from '../../../../router/routes';
import { RhrHrvTag } from '../../../../components/columns/RhrHrvTag';
import { Url } from '../../../../utils/url';
import { DashboardContext } from '../../context';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import { TrackingEvent } from '../../../../ms/tracking-entities';

type IProps = {
  athletes: IAthlete[];
};

export const useViewModel = ({ athletes }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { baseAthleteUrl } = use(DashboardContext);

  const items = athletes;

  const columns: ITableColumn[] = useMemo(() => {
    const columns: ITableColumn[] = [
      {
        key: 'athlete',
        name: t('label.athlete'),
        minWidth: 220,
        grow: 1,
      },
      {
        key: 'recovery_condition',
        name: t('label.recovery_condition'),
        minWidth: 100,
        grow: 100,
      },
      {
        key: 'details',
        name: '',
        minWidth: 110,
        maxWidth: 110,
        align: 'right',
      },
    ];

    return columns;
  }, [t]);

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IAthlete;
    const url = `${baseAthleteUrl}/${castedItem.id}/${ROUTES.WELLNESS}`;

    const renderMap: Record<string, ReactNode> = {
      athlete: <Athlete athlete={castedItem} url={url} />,
      recovery_condition: (
        <RhrHrvTag
          rhrVariance={castedItem.rhrVariance}
          hrvVariance={castedItem.hrvVariance}
        />
      ),
      details: <ArrowRight />,
    };

    return renderMap[column.key];
  };

  const onRowClick = useCallback(
    (item: ITableItem) => {
      const castedItem = item as IAthlete;
      const nextUrl = `${baseAthleteUrl}/${castedItem.id}/${ROUTES.WELLNESS}`;

      Url.saveUrlToLS();

      trackUserInteractionEvent(TrackingEvent.NAVIGATE, {
        destination: nextUrl,
      });

      navigate(nextUrl);
    },
    [baseAthleteUrl, navigate]
  );

  const emptyStateProps = useMemo((): IEmptyStateProps | undefined => {
    return items.length === 0
      ? {
          icon: <Heart size={32} />,
          text: t('banner.empty_dashboard_recovery_message'),
        }
      : undefined;
  }, [items.length, t]);

  return {
    columns,
    items,
    onRenderCell,
    onRowClick,
    emptyStateProps,
  };
};
