import './index.css';
import { useViewModel } from './useViewModel';
import { Table } from '@cycling-web/analog-ui';

export const IntervalDataTable = () => {
  const {
    columns,
    items,
    loading,
    emptyStateProps,
    onRenderCell,
    onRowsSelect,
  } = useViewModel();

  return (
    <Table
      className="workout__interval-data-table"
      columns={columns}
      items={items}
      loading={loading}
      skeletonCount={4}
      emptyState={emptyStateProps}
      onRenderCell={onRenderCell}
      onRowsSelect={onRowsSelect}
      checkbox={true}
    />
  );
};
