import './index.css';
import {
  Avatar,
  IconButton,
  ISelectOption,
  Tooltip,
} from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';
import { ChevronDown } from 'lucide-react';
import { Link, useLocation, useNavigate, useParams } from 'react-router';
import { useUserRole } from '../../../../hooks/useUserRole';
import { SingleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../router/routes';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { IAthlete } from '../../../../types/athletes';
import { useBaseUrl } from '../../../../hooks/useBaseUrl';
import { Url } from '../../../../utils/url';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingNavigationSource,
} from '../../../../ms/tracking-entities';

export const CalendarPageTitle = () => {
  const { athleteId } = useParams();
  const baseUrl = useBaseUrl();
  const athleteUrl = `${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}/${athleteId}/${ROUTES.PROFILE}`;

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const athlete = useMemo(() => {
    if (!athleteId) {
      return undefined;
    }

    return athletes.find((a) => a.id.toString() === athleteId);
  }, [athleteId, athletes]);

  const athletesOptions: ISelectOption[] = useMemo(() => {
    if (!athletesLoaded) {
      return [];
    }

    return athletes.map((a: IAthlete) => {
      return {
        id: a.id.toString(),
        text: a.fullName,
      };
    });
  }, [athletes, athletesLoaded]);

  const onAthleteChange = useCallback(
    (athleteId: number) => {
      const tmp = pathname.split('/');
      const index = tmp.findIndex((s) => s === ROUTES.CALENDAR);

      if (index >= 0) {
        tmp[index + 1] = athleteId.toString();
      }

      const nextUrl = tmp.join('/');

      trackUserInteractionEvent(TrackingEvent.NAVIGATE, {
        destination: nextUrl,
        source: TrackingNavigationSource.PAGE_TITLE_DROPDOWN,
      });

      navigate(nextUrl);
    },
    [navigate, pathname]
  );

  const onRenderAnchor = useCallback(() => {
    return (
      <Tooltip
        anchor={
          <IconButton
            size="s"
            variant="quietLayer2"
            content={<ChevronDown />}
          />
        }
        content={t('tooltip.select_athlete')}
      />
    );
  }, [t]);

  const onLinkClick = useCallback(() => {
    Url.saveUrlToLS();

    trackUserInteractionEvent(TrackingEvent.NAVIGATE, {
      destination: athleteUrl,
      source: TrackingNavigationSource.PAGE_TITLE_AVATAR,
    });
  }, [athleteUrl]);

  if (isAthlete) {
    return <>{t('label.my_calendar')}</>;
  }

  return (
    <div className="calendar-details__page-title">
      <Link to={athleteUrl} onClick={onLinkClick}>
        <Avatar
          className="athlete-details__page-title-avatar"
          size="l"
          text={athlete?.fullName}
          src={athlete?.picture}
          loading={!athletesLoaded}
        />
      </Link>
      <SingleAthleteFilter
        value={athlete?.id}
        options={athletesOptions}
        onChange={onAthleteChange}
        selectProps={{ onRenderAnchor }}
        dropdownProps={{
          minWidth: '260px',
        }}
        skeletonProps={{ width: '32px', height: '32px' }}
        style={{ minWidth: '0' }}
      />
    </div>
  );
};
