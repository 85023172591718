import './index.css';
import {
  ITooltipProps,
  ITypographyProps,
  ITypographyVariant,
  Skeleton,
  Tooltip,
  Typography,
} from '@cycling-web/analog-ui';
import { Info } from 'lucide-react';
import { clsx } from 'clsx';

type IProps = {
  name: string;
  value: string | number | undefined;
  loading?: boolean;
  tooltipProps?: Omit<ITooltipProps, 'anchor'>;
  variant?: 'layer1' | 'layer2' | 'layer3' | 'layer4';
  size?: 's' | 'm' | 'l';
  labelTypographyProps?: Partial<ITypographyProps<HTMLElement>>;
  valueTypographyProps?: Partial<ITypographyProps<HTMLElement>>;
};

export const LabelValueElement = ({
  name,
  value,
  loading,
  tooltipProps,
  variant = 'layer2',
  size = 'm',
  labelTypographyProps,
  valueTypographyProps,
}: IProps) => {
  const rootClass = clsx(
    'label-value-element',
    `label-value-element--${variant}`,
    `label-value-element--${size}`
  );

  const typographyMap: Record<string, ITypographyVariant> = {
    s: 'subtitle',
    m: 'body',
    l: 'headline',
  };

  const skeletonSize: Record<string, any> = {
    s: {
      nameHeight: '18px',
      valueHeight: '34px',
    },
    m: {
      nameHeight: '21px',
      valueHeight: '37px',
    },
    l: {
      nameHeight: '24px',
      valueHeight: '40px',
    },
  };

  if (loading) {
    return (
      <div className={rootClass}>
        <Skeleton width="50%" height={skeletonSize[size].nameHeight} />
        <Skeleton width="100%" height={skeletonSize[size].valueHeight} />
      </div>
    );
  }

  return (
    <div className={rootClass}>
      <div className="label-value-element__header">
        <Typography
          className="label-value-element__name"
          variant={typographyMap[size]}
          text={name}
          {...labelTypographyProps}
        />
        {tooltipProps && (
          <Tooltip
            className="label-value-element__tooltip"
            anchor={
              <Info className="label-value-element__tooltip-anchor" size={16} />
            }
            {...tooltipProps}
          />
        )}
      </div>

      <div className="label-value-element__value">
        <Typography
          className="label-value-element__value-text"
          weight="regular"
          variant={typographyMap[size]}
          text={value}
          {...valueTypographyProps}
        />
      </div>
    </div>
  );
};
