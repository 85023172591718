import { use, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ConversationState,
  IConversationMessageBase,
  IVoiceParams,
  IVoiceRecordingContext,
} from '../types';
import { VoiceActivityDetector } from '../utils/voiceActivity';
import { ConversationContext } from './ConversationContext';
import { trackUserInteractionEvent } from '../../../ms/log-insights';
import { TrackingAction, TrackingEvent } from '../../../ms/tracking-entities';

type IProps = {
  onSendAudio?: (
    base64: string
  ) => Promise<IConversationMessageBase | undefined>;
};

type OnStopRecording = (p: { shouldSend: boolean }) => void;

export const useVoiceRecording = (props: IProps) => {
  const { onSendAudio } = props;
  const {
    conversationState,
    setConversationState,
    handlePlayMessage,
    handleStopPlayMessage,
  } = use(ConversationContext);

  const [voiceParams, setVoiceParams] = useState<IVoiceParams>({
    averageVolume: 0,
  });
  const vadRef = useRef(new VoiceActivityDetector());

  const recordingTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);

  const mediaStreamRef = useRef<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const shouldSendRef = useRef<boolean>(true);

  const startRecording = useCallback(async () => {
    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.START_RECORDING,
    });

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('getUserMedia is not supported in this browser.');
      return;
    }

    try {
      handleStopPlayMessage();
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const mediaRecorder = new MediaRecorder(mediaStreamRef.current);

      await vadRef.current.start(
        mediaStreamRef.current,
        ({ averageVolume }) => {
          setVoiceParams({ averageVolume });
        }
      );

      mediaRecorder.ondataavailable = (e) => {
        chunksRef.current.push(e.data);
      };

      mediaRecorder.onstop = async () => {
        setConversationState(ConversationState.PROCESSING);

        const blob = new Blob(chunksRef.current, {
          type: 'audio/ogg; codecs=opus',
        });
        chunksRef.current = [];

        function blobToBase64(blob: Blob): Promise<string> {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            // @ts-ignore
            reader.onloadend = () => resolve(reader.result?.split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
          });
        }

        if (shouldSendRef.current) {
          blobToBase64(blob)
            .then((base64String: string) => {
              setConversationState(ConversationState.PROCESSING);
              if (onSendAudio) {
                onSendAudio(base64String).then(
                  (message: IConversationMessageBase | undefined) => {
                    if (message) {
                      handlePlayMessage(message);
                    }
                  }
                );
              }
            })
            .catch((error) => {
              console.error('Error converting Blob to Base64:', error);
            });
        } else {
          setConversationState(ConversationState.IDLE);
        }
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
      setConversationState(ConversationState.LISTENING);

      setRecordingTime(0);
      recordingTimerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error('Error accessing microphone:', error);
      setConversationState(ConversationState.IDLE);
    }
  }, [
    handleStopPlayMessage,
    setConversationState,
    onSendAudio,
    handlePlayMessage,
  ]);

  const stopRecording: OnStopRecording = useCallback(
    ({ shouldSend = true }) => {
      trackUserInteractionEvent(TrackingEvent.CLICK, {
        action: TrackingAction.STOP_RECORDING,
      });

      shouldSendRef.current = shouldSend;

      vadRef.current.stop();
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }

      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }

      if (recordingTimerRef.current) {
        clearInterval(recordingTimerRef.current);
        recordingTimerRef.current = null;
      }
    },
    []
  );

  const cancelRecording = useCallback(() => {
    stopRecording({ shouldSend: false });
  }, [stopRecording]);

  const toggleRecording = useCallback(async () => {
    if (conversationState === ConversationState.IDLE) {
      await startRecording();
    } else if (conversationState === ConversationState.LISTENING) {
      await stopRecording({ shouldSend: true });
    }
  }, [conversationState, startRecording, stopRecording]);

  useEffect(() => {
    return () => {
      if (recordingTimerRef.current) {
        clearInterval(recordingTimerRef.current);
        recordingTimerRef.current = null;
      }
    };
  }, []);

  const context: IVoiceRecordingContext =
    useMemo((): IVoiceRecordingContext => {
      return {
        toggleRecording,
        voiceParams,
        recordingTime,
        cancelRecording,
      };
    }, [toggleRecording, voiceParams, recordingTime, cancelRecording]);

  return {
    context,
  };
};
