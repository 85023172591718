import { ITrainingLoadSlice, ITrainingLoadState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ITrainingLoad, IWorkoutDetail } from '../../../types/performance';

export const initialState: ITrainingLoadState = {
  workoutDetails: [],
  workoutDetailsLoaded: false,
  trainingLoad: null,
  trainingLoadLoaded: false,
};

export const useTrainingLoadStore = create<ITrainingLoadSlice>()(
  immer((set) => ({
    ...initialState,
    setWorkoutDetails: (workoutDetails: IWorkoutDetail[]) => {
      set((state) => {
        state.workoutDetails = workoutDetails;
        state.workoutDetailsLoaded = true;
      });
    },
    setWorkoutDetailsLoaded: (loaded: boolean) => {
      set((state) => {
        state.workoutDetailsLoaded = loaded;
      });
    },
    setTrainingLoad: (trainingLoad: ITrainingLoad | null) => {
      set((state) => {
        state.trainingLoad = trainingLoad;
        state.trainingLoadLoaded = true;
      });
    },
    setTrainingLoadLoaded: (loaded: boolean) => {
      set((state) => {
        state.trainingLoadLoaded = loaded;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
