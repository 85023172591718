import './index.css';
import { Divider, Skeleton } from '@cycling-web/analog-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { IAthlete } from '../../types/athletes';
import { CTL } from '../AthleteCard/features/Stats/components/CTL';
import { ATL } from '../AthleteCard/features/Stats/components/ATL';
import { TSB } from '../AthleteCard/features/Stats/components/TSB';
import { RHR } from '../AthleteCard/features/Stats/components/RHR';
import { HRV } from '../AthleteCard/features/Stats/components/HRV';
import { MCP } from '../AthleteCard/features/Stats/components/MCP';
import { VO2Max } from '../AthleteCard/features/Stats/components/VO2Max';
import { MFTP } from '../AthleteCard/features/Stats/components/MFTP';
import { SFTP } from '../AthleteCard/features/Stats/components/SFTP';

type IProps = {
  athlete: IAthlete | null | undefined;
  athleteLoaded: boolean;
};

export const AthleteStats = ({ athlete, athleteLoaded }: IProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const stepRef = useRef<number>(0);
  const [showScrollButtons, setShowScrollButtons] = useState({
    left: false,
    right: false,
  });

  const updateScrollButtonsVisibility = useCallback(() => {
    if (!athlete || !scrollContainerRef.current || !contentRef.current) {
      return;
    }

    const containerWidth =
      scrollContainerRef.current.getBoundingClientRect().width;
    const contentWidth = contentRef.current.getBoundingClientRect().width;

    stepRef.current = Math.min(containerWidth * 0.7, 300);

    if (contentWidth <= containerWidth) {
      setShowScrollButtons({
        left: false,
        right: false,
      });
      contentRef.current.style.transform = `translateX(0px)`;
      return;
    }

    const currentTransform =
      contentRef.current.style.transform || 'translateX(0px)';
    const currentX =
      parseInt(
        currentTransform.replace('translateX(', '').replace('px)', '')
      ) || 0;

    if (currentX < -(contentWidth - containerWidth)) {
      const newX = -(contentWidth - containerWidth);
      contentRef.current.style.transform = `translateX(${newX}px)`;

      setShowScrollButtons({
        left: newX < 0,
        right: false,
      });
    } else {
      setShowScrollButtons({
        left: currentX < 0,
        right: contentWidth + currentX > containerWidth,
      });
    }
  }, [athlete]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      updateScrollButtonsVisibility();
    });

    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
    }

    window.addEventListener('resize', updateScrollButtonsVisibility);

    updateScrollButtonsVisibility();

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateScrollButtonsVisibility);
    };
  }, [athlete, updateScrollButtonsVisibility]);

  const onScroll = useCallback((direction: number) => {
    return () => {
      if (!contentRef.current || !scrollContainerRef.current) return;

      const containerWidth =
        scrollContainerRef.current.getBoundingClientRect().width;
      const contentWidth = contentRef.current.getBoundingClientRect().width;

      const currentTransform =
        contentRef.current.style.transform || 'translateX(0px)';
      const currentX =
        parseInt(
          currentTransform.replace('translateX(', '').replace('px)', '')
        ) || 0;

      const stepSize = stepRef.current;

      let newX = currentX;
      if (direction > 0) {
        newX = Math.max(-(contentWidth - containerWidth), currentX - stepSize);
      } else {
        newX = Math.min(0, currentX + stepSize);
      }

      contentRef.current.style.transition = 'transform 0.3s ease';
      contentRef.current.style.transform = `translateX(${newX}px)`;

      setShowScrollButtons({
        left: newX < 0,
        right: contentWidth + newX > containerWidth,
      });
    };
  }, []);

  if (!athleteLoaded) {
    return (
      <div className="athlete-stats-wrapper">
        <div className="athlete-stats">
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Divider direction="vertical" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Divider direction="vertical" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
          <Divider direction="vertical" />
          <Skeleton width="60px" height="24px" />
          <Skeleton width="60px" height="24px" />
        </div>
      </div>
    );
  }

  return (
    <div className="athlete-stats-wrapper" ref={scrollContainerRef}>
      {showScrollButtons.left && (
        <button
          className="athlete-stats__scrollButton scrollButton--left"
          onClick={onScroll(-1)}
        >
          <div className="athlete-stats__scrollButton-iconBox">
            <ChevronLeft size={16} />
          </div>
        </button>
      )}
      <div className="athlete-stats" ref={contentRef}>
        <CTL athlete={athlete} />
        <ATL athlete={athlete} />
        <TSB athlete={athlete} />
        <Divider direction="vertical" />
        <RHR athlete={athlete} />
        <HRV athlete={athlete} />
        <Divider direction="vertical" />
        <MCP athlete={athlete} />
        <VO2Max athlete={athlete} />
        <Divider direction="vertical" />
        <MFTP athlete={athlete} />
        <SFTP athlete={athlete} />
      </div>
      {showScrollButtons.right && (
        <button
          className="athlete-stats__scrollButton scrollButton--right"
          onClick={onScroll(1)}
        >
          <div className="athlete-stats__scrollButton-iconBox">
            <ChevronRight size={16} />
          </div>
        </button>
      )}
    </div>
  );
};
