import './index.css';
import { getDaysInMonth } from '@cycling-web/analog-ui';
import { ReactNode, use, useMemo } from 'react';
import { clsx } from 'clsx';
import { CalendarDay } from '../CalendarDay';
import { WeekSummary } from '../WeekSummary';
import { getWeek } from 'date-fns';
import { WorkoutsCalendarContext } from '../../context';

export const CalendarDays = () => {
  const { date } = use(WorkoutsCalendarContext);

  const daysInMonth = useMemo(() => {
    return getDaysInMonth(date);
  }, [date]);

  const daysJSX = useMemo(() => {
    const daysJSX: ReactNode[] = [];

    for (let i = 0; i < daysInMonth.days.length; i++) {
      const day = daysInMonth.days[i];

      daysJSX.push(
        <div
          key={day.date.getTime()}
          className={clsx(
            'workouts-calendar__day',
            `workouts-calendar__day--${day.period}`
          )}
        >
          <CalendarDay date={day.date} />
        </div>
      );

      if ((i + 1) % 7 === 0) {
        const week = getWeek(day.date, { weekStartsOn: 1 });
        daysJSX.push(
          <div
            key={week}
            className="workouts-calendar__day workouts-calendar__week-summary"
          >
            <WeekSummary week={week} year={day.date.getFullYear()} />
          </div>
        );
      }
    }

    return daysJSX;
  }, [daysInMonth.days]);

  return <div className="workouts-calendar__days">{daysJSX}</div>;
};
