import { ISmartChartConfig } from '../../../types/smart-charts';
import { IAthlete } from '../../../types/athletes';
import { computeSource } from './computeSource';
import { computeXAxis } from './computeXAxis';
import { computeYAxis } from './computeYAxis';
import { computeSeries } from './computeSeries';
import { computeTooltip } from './computeTooltip';
import { computeGrid } from './computeGrid';
import { IEChartOption } from '../../../components/Chart';

export const computeGraphOption = (
  config: ISmartChartConfig,
  athletes: IAthlete[] = []
): IEChartOption => {
  const graph = config?.ai_graph_in_scope?.ai_generated_graphs[0];

  const isLogScale = graph?.x_axes[0]?.range_log_scaled;

  // 0. Compute source
  const source = computeSource({ config, athletes });

  const props = {
    config,
    source,
    isLogScale,
    athletes,
  };

  // 1. Map the X Axis data
  const xAxis = computeXAxis(props);

  // 2. Map the Y Axis data
  const yAxis = computeYAxis(props);

  // 3. Map the Series data
  const series = computeSeries(props);

  // 4. Grid
  const grid = computeGrid({ xAxis, yAxis });

  // 5. Tooltip
  const tooltip = computeTooltip(props);

  return {
    dataset: {
      source,
    },
    grid: grid,
    xAxis: xAxis,
    yAxis: yAxis,
    series: series,
    tooltip: tooltip,
    animation: false,
  };
};
