import './index.css';
import { Button } from '@cycling-web/analog-ui';
import { useUsersStore } from '../../../../store/users/slice';
import { useCallback, useMemo } from 'react';
import { OnboardingStep } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { UsersRepository } from '../../../../api/users/repository';
import { UsersService } from '../../../../api/users/service';
import { useUserRole } from '../../../../hooks/useUserRole';

type IProps = {
  stepCompleted?: boolean;
  disabled?: boolean;
  loading?: boolean;
  nextText?: string;
  onNextClick?: () => Promise<boolean>;
};

export const OnboardingFooter = ({
  stepCompleted,
  nextText,
  disabled,
  onNextClick,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const onboardingStep = useUsersStore((s) => s.userProfile?.onboardingStatus);
  const setOnboardingStep = useUsersStore((s) => s.setOnboardingStep);
  const [_, setSearchParams] = useSearchParams();

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const steps: OnboardingStep[] = useMemo(() => {
    return isAthlete
      ? [
          OnboardingStep.InvitationAccepted,
          OnboardingStep.PrivacyAccepted,
          OnboardingStep.ProfileInfo,
          OnboardingStep.PerformanceInfo,
          OnboardingStep.Platforms,
          OnboardingStep.Completed,
        ]
      : [
          OnboardingStep.InvitationAccepted,
          OnboardingStep.PrivacyAccepted,
          OnboardingStep.Completed,
        ];
  }, [isAthlete]);

  const onNextStepClick = useCallback(() => {
    const currentStepIndex = steps.findIndex(
      (step: OnboardingStep) => step === onboardingStep
    );
    const nextStepIndex = currentStepIndex + 1;

    if (nextStepIndex < steps.length) {
      const nextStep = steps[nextStepIndex];
      usersRepository
        .setOnboardingStatus({
          onboardingStatus: nextStep,
        })
        .then(() => {
          setSearchParams({});
          setOnboardingStep(nextStep);
        });
    }
  }, [
    onboardingStep,
    setOnboardingStep,
    setSearchParams,
    steps,
    usersRepository,
  ]);

  const handleNextStepClick = useCallback(() => {
    if (onNextClick) {
      onNextClick().then((valid: boolean) => {
        if (valid) {
          onNextStepClick();
        }
      });
    } else {
      onNextStepClick();
    }
  }, [onNextStepClick, onNextClick]);

  const handlePrevStepClick = useCallback(() => {
    const currentStepIndex = steps.findIndex(
      (step: OnboardingStep) => step === onboardingStep
    );
    const prevStepIndex = currentStepIndex - 1;

    if (currentStepIndex >= 0) {
      const nextStep = steps[prevStepIndex];

      usersRepository
        .setOnboardingStatus({
          onboardingStatus: nextStep,
        })
        .then(() => {
          setSearchParams({});
          setOnboardingStep(nextStep);
        });
    }
  }, [
    onboardingStep,
    setOnboardingStep,
    setSearchParams,
    steps,
    usersRepository,
  ]);

  return (
    <footer className="onboarding__footer">
      {onboardingStep !== steps[0] && onboardingStep !== steps.at(-2) && (
        <Button
          variant="quietLayer3"
          content={t('action.back')}
          onClick={handlePrevStepClick}
        />
      )}
      <Button
        className="onboarding__next-step-button"
        disabled={disabled}
        variant={
          onboardingStep === steps.at(-2) || stepCompleted ? 'primary' : 'quiet'
        }
        content={
          onboardingStep === steps.at(-2) || stepCompleted
            ? nextText || t('action.next')
            : t('action.skip_for_now')
        }
        onClick={handleNextStepClick}
        loading={loading}
      />
    </footer>
  );
};
