import {
  ChartColor,
  ChartTooltip,
  IChartFixedCursor,
} from '../../../../components/Chart';
import { useChartContext } from '../../context';

type IProps = {
  cursor: IChartFixedCursor;
};

export const ChartFixedCursor = ({ cursor }: IProps) => {
  const { fixedCursorProps } = useChartContext();
  const TOOLTIP_OFFSET = 16;

  return (
    <div
      className="chart-fixed-cursor-tooltip analog-typography--subtitle"
      style={{
        background: ChartColor.TooltipBackground,
        left: cursor.pixels[0] + TOOLTIP_OFFSET,
        top: cursor.pixels[1] + TOOLTIP_OFFSET,
      }}
    >
      <ChartTooltip
        // @ts-ignore
        params={cursor.series.map((s) => {
          if (!s) {
            return null;
          }

          return {
            ...s,
            dimensionNames: cursor.dimensionNames,
            data: cursor.data,
            value: cursor.data,
            name: cursor.data[0],
            seriesName: s.name,
            // @ts-ignore
            color: s.itemStyle?.color,
          };
        })}
        onRenderValue={fixedCursorProps?.onRenderValue}
        onRenderHeader={fixedCursorProps?.onRenderHeader}
      />
    </div>
  );
};
