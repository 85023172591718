import './index.css';
import { IButtonVariant, IconButton, Tooltip } from '@cycling-web/analog-ui';
import { ConversationState, IConversationInputVariant } from '../../types';
import { Mic, Send } from 'lucide-react';
import { use } from 'react';
import { VoiceRecordingContext } from '../../context/VoiceRecordingContext';
import { ConversationContext } from '../../context/ConversationContext';
import { useTranslation } from 'react-i18next';

type IProps = {
  variant?: IConversationInputVariant;
};

export const RecordingButton = ({ variant = 'layer2' }: IProps) => {
  const { t } = useTranslation();
  const { conversationState } = use(ConversationContext);
  const { toggleRecording } = use(VoiceRecordingContext);

  const buttonVariant: Record<IConversationInputVariant, IButtonVariant> = {
    layer1: 'quietLayer3',
    layer2: 'quietLayer2',
    layer3: 'quietLayer2',
  };

  return (
    <Tooltip
      anchor={
        <IconButton
          variant={buttonVariant[variant] || 'quietLayer2'}
          content={
            conversationState === ConversationState.LISTENING ? (
              <Send />
            ) : (
              <Mic />
            )
          }
          onClick={toggleRecording}
        />
      }
      content={
        conversationState === ConversationState.LISTENING
          ? t('action.send')
          : t('action.use_voice_mode')
      }
      placement="top-end"
    />
  );
};
