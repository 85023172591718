import './index.css';
import { TrainingPlanHeader } from '../Header';
import { useTranslation } from 'react-i18next';

export const Availability = () => {
  const { t } = useTranslation();

  return (
    <div className="training-plan__availability">
      <TrainingPlanHeader
        title={t('label.availability')}
        subtitle={t('label.training_plan_step2_subtitle')}
      />
    </div>
  );
};
