import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormElement, ISelectOption, Select } from '@cycling-web/analog-ui';
import { useCallback, useMemo } from 'react';

export const PriorityControl = () => {
  const { formState, setValue } = useFormContext<{
    priority?: string | null;
  }>();
  const priority = useWatch({ name: 'priority' });
  const { t } = useTranslation();
  const options: ISelectOption[] = useMemo(() => {
    return [
      {
        id: 'A',
        text: 'A',
      },
      {
        id: 'B',
        text: 'B',
      },
      {
        id: 'C',
        text: 'C',
      },
    ];
  }, []);

  const value = useMemo(() => {
    return options.find((o: ISelectOption) => o.id === priority);
  }, [options, priority]);

  const onChange = useCallback(
    (option: ISelectOption) => {
      setValue('priority', option.id);
    },
    [setValue]
  );

  return (
    <FormElement
      label={t('label.priority')}
      message={formState.errors.priority?.message}
    >
      <Select
        options={options}
        value={value}
        onChange={onChange}
        placeholder={t('placeholder.priority')}
        invalid={!!formState.errors.priority}
      />
    </FormElement>
  );
};
