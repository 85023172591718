import { useTranslation } from 'react-i18next';
import { PageFilters } from '@cycling-web/common';
import { WeeksFilter } from '../../../../components/filters/WeeksFilter';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { Button, ITableColumn } from '@cycling-web/analog-ui';
import { usePerformanceReportStore } from '../../../PerformanceReport/store/slice';
import { useCallback, useEffect } from 'react';
import { AnyValue } from '../../../../types/common';
import { IPerformanceReportFilters } from '../../../PerformanceReport/types';
import { IPerformanceReport } from '../../../../types/performance';
import { EMPTY } from '../../../../constants';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useTableColumns } from '../../hooks/useTableColumns';
import { downloadCSV } from '../../../../utils/csv';
import { usePageFilters } from '../../../../context/PageFilters';
import { getDefaultPageFilters } from '../../../../constants/page-filters';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';
import { useMediaMatch } from 'rooks';

export const PerformanceTableFilters = () => {
  const { t } = useTranslation();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const reports = usePerformanceReportStore((s) => s.reports);
  const isMobile = useMediaMatch('(max-width: 480px)');

  const { filters, handleChangeFilter, removeFilter, initPageFilters } =
    usePageFilters<IPerformanceReportFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().performanceReport);
  }, [initPageFilters]);

  const columns = useTableColumns();

  const handleExportReport = useCallback(() => {
    const headers = columns.map((column: ITableColumn) => {
      return { label: column.name, field: column.key };
    });

    const mappedData: AnyValue[] = reports.map((item: IPerformanceReport) => {
      const athlete = athletesMap[item.athleteId]
        ? athletesMap[item.athleteId].fullName
        : EMPTY;

      return {
        athlete: athlete,
        total_duration: item.durationStr || EMPTY,
        elevation_gain: item.climbInMeter || EMPTY,
        work: item.workInKj || EMPTY,
        distance: item.distanceInKm || EMPTY,
        tss: item.tss || EMPTY,
        ctl: item.ctl || EMPTY,
        tsb: item.tsb || EMPTY,
        coach_rating: item.coachRating || EMPTY,
        coach_comment: item.comments || EMPTY,
        next_race: item.nextRace || EMPTY,
        mcp: item.mCPRelative || EMPTY,
        coach: item.coachName || EMPTY,
      };
    });

    const name = `${t('label.weekly_report')} W${filters.week}`;

    trackUserInteractionEvent(TrackingEvent.CLICK, {
      action: TrackingAction.EXPORT_PERFORMANCE_REPORT,
    });

    downloadCSV(headers, mappedData, name);
  }, [columns, reports, t, filters.week, athletesMap]);

  const handleClearSearch = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  const handleClearAthletes = useCallback(() => {
    removeFilter('athletes');
  }, [removeFilter]);

  const filtersJSX = (
    <>
      <MultipleAthleteFilter
        value={filters.athletes?.split(',').map(Number) || []}
        onChange={handleChangeFilter('athletes')}
        style={{ minWidth: '220px' }}
        className="performance-table__athletes-filters"
        onClear={handleClearAthletes}
      />
      <WeeksFilter value={filters.week} onChange={handleChangeFilter('week')} />
    </>
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
      actionElements={
        !isMobile && (
          <Button content={t('action.export')} onClick={handleExportReport} />
        )
      }
    />
  );
};
