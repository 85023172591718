import { useCallback, useMemo } from 'react';
import { WorkoutsRepository } from '../../../api/workouts/repository';
import { WorkoutsService } from '../../../api/workouts/service';
import { useWorkoutStore } from '../store/slice';

export const useFetchWorkoutAnalysis = () => {
  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchWorkoutAnalysis = useCallback(
    (workout_id: number, athlete_id: number) => {
      workoutsRepository
        .getWorkoutAnalysis({
          workout_id,
          athlete_id,
        })
        .then((workoutAnalysis) => {
          useWorkoutStore.getState().setWorkoutAnalysis(workoutAnalysis);
        })
        .catch(() => {
          useWorkoutStore.getState().setWorkoutAnalysisLoaded(true);
        });
    },
    [workoutsRepository]
  );

  return {
    fetchWorkoutAnalysis,
  };
};
