import { IConfirmDialogProps } from '@cycling-web/analog-ui';
import { Dispatch, SetStateAction } from 'react';
import { ICalendarEvent } from '../../../types/workouts';

export type ITrainingPlanModalProps = {
  athleteId: string | number;
  onDismiss: () => void;
};

export type ITrainingPlanContext = ITrainingPlanModalProps & {
  handleDismiss: () => void;
  /** Discard dialog when trying to move with unsaved changes */
  discardDialogProps: Partial<IConfirmDialogProps> | undefined;
  openDiscardDialog: (props: Partial<IConfirmDialogProps>) => void;
  dismissDiscardDialog: () => void;
  wizardStep: TrainingPlanWizardStep;
  setWizardStep: Dispatch<SetStateAction<TrainingPlanWizardStep>>;
  trainingPlan: ITrainingPlanBase;
  onTrainingPlanChange: (trainingPlan: Partial<ITrainingPlanBase>) => void;
};

export enum TrainingPlanWizardStep {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
}

export type IWizardStep = {
  id: TrainingPlanWizardStep;
  text: string;
};

export enum TrainingPlanFitnessGoal {
  CyclingEconomy = 'CyclingEconomy',
  IncreaseFTP = 'IncreaseFTP',
  ImproveClimbingPerformance = 'ImproveClimbingPerformance',
  ImproveVO2Max = 'ImproveVO2Max',
  SprintPower = 'SprintPower',
  LactateTolerance = 'LactateTolerance',
  ExcelInGroupRides = 'ExcelInGroupRides',
}

export type IFitnessGoal = {
  id: string;
  text: string;
  description: string;
};

export type ITrainingPlanBase = {
  date: Date;
  competition: ICalendarEvent | null;
  fitnessGoal: IFitnessGoal | null;
  fitnessGoalTimeline: number;
};
