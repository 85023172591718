import { PageFilters } from '@cycling-web/common';
import { SingleGroupFilter } from '../../../../components/filters/GroupFilter';
import { IDashboardFilters } from '../../types';

type IProps = {
  filters: IDashboardFilters;
  handleChangeFilter: any;
};

export const DashboardFilters = ({ filters, handleChangeFilter }: IProps) => {
  const filtersJSX = (
    <SingleGroupFilter
      value={filters.groupId !== undefined ? +filters.groupId : undefined}
      onChange={handleChangeFilter('groupId')}
      dropdownProps={{
        minWidth: '260px',
      }}
      skeletonProps={{ width: '32px', height: '32px' }}
      showAllOption
    />
  );

  return (
    <PageFilters
      filterProps={{
        filters: filtersJSX,
      }}
    />
  );
};
