import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, FormElement } from '@cycling-web/analog-ui';
import { WorkoutsRepository } from '../../../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../../../api/workouts/service';
import { IWorkoutForm } from './types';
import { useWorkoutsCalendarContext } from '../../../../context';
import { useCalendarAddContext } from '../../context';
import {
  DatepickerControl,
  InputControl,
  TextareaControl,
} from '@cycling-web/common';
import { addDays } from 'date-fns';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingForm,
} from '../../../../../../ms/tracking-entities';

export const NoteForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { athlete, date } = useWorkoutsCalendarContext();
  const { onDismiss, handleDismiss, defaultNote } = useCalendarAddContext();

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        title: z.string().min(1, { message: t('validation.required') }),
        description: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const defaultValues = useMemo((): IWorkoutForm => {
    if (defaultNote) {
      return {
        title: defaultNote.title,
        description: defaultNote.description,
        date: new Date(defaultNote.date),
      };
    }

    return {
      title: '',
      description: '',
      date: addDays(new Date(), 1),
    };
  }, [defaultNote]);

  const form = useForm<IWorkoutForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  const handleSave = useCallback(
    (formData: IWorkoutForm) => {
      if (!athlete) {
        return;
      }

      onDismiss();

      // setLoading(true);
      //
      // const payload = {
      //   athleteId: athlete.id,
      //   da: localWithUTCMidnightString(formData.date),
      //   title: formData.title,
      //   description: formData.description,
      // };
      //
      // if (defaultNote?.id) {
      //   payload.id = defaultNote.id;
      // }
      //
      // const request =
      //   payload.id === undefined
      //     ? workoutsRepository.createPrescription(payload)
      //     : workoutsRepository.updatePrescription(payload);
      //
      // return request
      //   .then(() => {
      //     onDismiss();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    },
    [athlete, onDismiss]
  );

  const onSubmit = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.WORKOUT_PRESCRIPTION,
    });

    handleSubmit(
      (formData: IWorkoutForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  return (
    <FormProvider {...form}>
      <div className="note-form">
        <FormElement label={t('label.date')}>
          <DatepickerControl name="date" min={new Date()} />
        </FormElement>

        <Divider />

        <FormElement label={t('label.title')} message={errors.title?.message}>
          <InputControl
            name="title"
            placeholder={t('placeholder.name')}
            invalid={!!errors.title}
            autoComplete="off"
          />
        </FormElement>

        <FormElement
          label={t('label.description')}
          message={errors.description?.message}
        >
          <TextareaControl
            name="description"
            placeholder={t('placeholder.description')}
            invalid={!!errors.description}
          />
        </FormElement>

        <footer className="note-form__footer">
          <Button
            variant="quietLayer3"
            content={t('action.cancel')}
            onClick={handleDismiss}
          />
          <Button
            content={defaultNote ? t('action.save') : t('action.add')}
            onClick={onSubmit}
            loading={loading}
          />
        </footer>
      </div>
    </FormProvider>
  );
};
