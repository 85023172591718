import { Skeleton, Typography } from '@cycling-web/analog-ui';

export const TrainingZoneProfilesLoading = () => {
  return (
    <div className="athlete-profiles__training-zones--profile">
      <header className="training-zones--profile__header">
        <div className="training-zones--profile__header-text">
          <Typography
            className="training-zones--profile__method-name"
            text=""
            loading
          />
        </div>
      </header>
      <div className="training-zones--profile__zones">
        <Skeleton width="100%" height="34px" />
        <Skeleton width="100%" height="34px" />
        <Skeleton width="100%" height="34px" />
        <Skeleton width="100%" height="34px" />
        <Skeleton width="100%" height="34px" />
        <Skeleton width="100%" height="34px" />
      </div>
    </div>
  );
};
