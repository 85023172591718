import { ConversationContextProvider } from '../../components/Conversation';
import { SmartChartsView } from './components/SmartChartsView';

export const SmartCharts = () => {
  return (
    <ConversationContextProvider>
      <SmartChartsView />
    </ConversationContextProvider>
  );
};
