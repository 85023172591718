import { useFetchEvent } from './hooks/useFetchEvent';
import { useEventStore } from './store/slice';
import { useEffect, useMemo } from 'react';
import { ROUTES } from '../../router/routes';
import { useLocation } from 'react-router';
import { useAthletesStore } from '../../store/athletes/slice';
import { LS } from '../../constants';

export const useViewModel = () => {
  useFetchEvent();
  const { pathname } = useLocation();

  const event = useEventStore((s) => s.event);
  const eventLoaded = useEventStore((s) => s.eventLoaded);

  const positionOnMap = useEventStore((s) => s.positionOnMap);

  const gpx = useEventStore((s) => s.gpx);
  const gpxLoaded = useEventStore((s) => s.gpxLoaded);

  const athletes = useAthletesStore((s) => s.athletes);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const athlete = useMemo(() => {
    const athleteId = event?.athleteId;

    if (!athleteId) {
      return undefined;
    }

    return athletes.find(
      (athlete) => athlete.id.toString() === athleteId?.toString()
    );
  }, [event, athletes]);

  const backUrl = useMemo(() => {
    const prevPage = localStorage.getItem(LS.PreviousPage);

    if (prevPage) {
      return prevPage;
    }

    const tmp = pathname.split('/');
    const index = tmp.findIndex((s) => s === ROUTES.WORKOUT);

    if (index >= 0) {
      const tmp1 = tmp.filter((_, i) => i < index);
      tmp1.push(ROUTES.CALENDAR);
      if (athlete?.id) {
        tmp1.push(athlete.id.toString());
      }
      return tmp1.join('/');
    }

    return '';
  }, [athlete, pathname]);

  useEffect(() => {
    return () => {
      useEventStore.getState().reset();
    };
  }, []);

  return {
    event,
    eventLoaded,
    backUrl,
    athlete,
    athleteLoaded: athletesLoaded,
    gpx,
    gpxLoaded,
    positionOnMap,
  };
};
