import './index.css';
import { Toggle } from './components/Toggle';
import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { useViewModel } from './useViewModel';
import { MathJaxContext } from 'better-react-mathjax';
import { ConversationContextProvider } from '../Conversation';

export const AiAssistant = () => {
  const { rootClass } = useViewModel();

  return (
    <ConversationContextProvider>
      <MathJaxContext>
        <div className={rootClass}>
          <Toggle />
          <div className="ai-assistant__content">
            <Body />
            <Footer />
          </div>
        </div>
      </MathJaxContext>
    </ConversationContextProvider>
  );
};
