import './index.css';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import {
  IconButton,
  ILabelValueProps,
  IMenuBaseOption,
  IMenuOption,
  Menu,
  TruncatedText,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { IWorkoutPrescription } from '../../../../types/workouts';
import { clsx } from 'clsx';
import { MouseEvent, useCallback, useMemo } from 'react';
import { WorkoutsRepository } from '../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../api/workouts/service';
import { Ellipsis } from 'lucide-react';
import { useWorkoutsCalendarContext } from '../../context';
import { useFetchWorkoutCalendar } from '../../hooks/useFetchWorkoutCalendar';
import { CalendarWorkoutStats } from '../CalendarWorkoutStats';
import { hoursToHHMMSS } from '../../../../utils/date-time';
import { EMPTY } from '../../../../constants';
import { isAfter, isToday } from 'date-fns';

type IProps = {
  prescription: IWorkoutPrescription;
};

export const CalendarPrescription = ({ prescription }: IProps) => {
  const { t } = useTranslation();
  const { date, athlete, setAddModalProps } = useWorkoutsCalendarContext();
  const { fetchWorkoutCalendar } = useFetchWorkoutCalendar();
  const enableEdit =
    isToday(prescription.workoutDay) ||
    isAfter(prescription.workoutDay, new Date());

  const rootClass = clsx(
    'calendar-prescription',
    prescription.skipped && 'calendar-prescription--skipped'
  );

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService());
  }, []);

  const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
  };

  const menuOptions: IMenuOption[] = useMemo(() => {
    const options: IMenuOption[] = [];

    if (enableEdit) {
      options.push({
        id: 'edit',
        text: t('action.edit'),
      });
    }

    options.push({
      id: 'delete',
      text: t('action.delete'),
    });

    return options;
  }, [enableEdit, t]);

  const handleDeleteEvent = useCallback(() => {
    return workoutsRepository
      .deletePrescription({
        id: prescription.id,
      })
      .then(() => {
        if (athlete) {
          fetchWorkoutCalendar(date, athlete.id.toString());
        }
      });
  }, [athlete, date, fetchWorkoutCalendar, prescription, workoutsRepository]);

  const handleEditEvent = useCallback(() => {
    setAddModalProps({
      defaultPrescription: prescription,
    });
  }, [prescription, setAddModalProps]);

  const onMenuChange = useCallback(
    (option: IMenuBaseOption) => {
      if (option.id === 'delete') {
        handleDeleteEvent();
      }

      if (option.id === 'edit') {
        handleEditEvent();
      }
    },
    [handleDeleteEvent, handleEditEvent]
  );

  const menuJSX = (
    <Menu
      options={menuOptions}
      onChange={onMenuChange}
      dropdownProps={{
        anchor: (
          <IconButton
            size="xs"
            content={<Ellipsis />}
            onClick={preventDefault}
          />
        ),
        placement: 'bottom-end',
      }}
    />
  );

  const params: ILabelValueProps[] = [
    {
      label: t('label.distance'),
      value: prescription.distanceInKms || EMPTY,
      units: t('units.km'),
    },
    {
      label: t('label.tss'),
      value: prescription.tss || EMPTY,
      units: t('label.tss'),
    },
    {
      label: t('label.elevation'),
      value: prescription.elevationInMtrs || EMPTY,
      units: t('units.m'),
    },
    {
      label: t('label.work'),
      value: prescription.energyInKJs || EMPTY,
      units: t('units.kj'),
    },
  ];

  return (
    <div className={rootClass}>
      <header className="calendar-prescription__header">
        <CyclingIcon width={16} height={16} />
        <Typography
          variant="subtitle-b"
          weight="bold"
          text={prescription?.title || t('label.cycling')}
        />

        <div className="calendar-event__header-actions">{menuJSX}</div>
      </header>

      <div className="calendar-prescription__body">
        {prescription?.durationInHrs && (
          <Typography
            as="p"
            variant="subtitle"
            weight="bold"
            text={hoursToHHMMSS(prescription?.durationInHrs)}
            className="calendar-prescription__body-time"
          />
        )}

        {prescription?.description && (
          <TruncatedText
            className="analog-typography--subtitle-b"
            text={prescription.description}
            lines={3}
            tooltipProps={{
              maxWidth: '200px',
              contentClassName: 'calendar-prescription__tooltip-content',
            }}
            typographyProps={{
              as: 'pre',
            }}
          />
        )}

        <CalendarWorkoutStats params={params} />
      </div>
    </div>
  );
};
