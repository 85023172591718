import { DateGroupBy, DateRange } from '../../../../types/enums';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { formatDate } from '../../../../utils/utils';
import { IPowerDerivative } from '../../../../types/performance';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { useAthletePerformanceStore } from '../../store/slice';
import { IAthleteMmpFilters, IAthletePerformanceFilters } from '../../types';
import { AnyValue, DataType } from '../../../../types/common';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IHandleChangeFilter, useBreakpoints } from '@cycling-web/common';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  chartTooltipFormatter,
  getDateAxisInterval,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import { DataTypeFilter } from '../../../../components/filters/DataTypeFilter';
import { GroupByPeriodFilter } from '../../../../components/filters/GroupByPeriodFilter';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';

type IProps = {
  data: IPowerDerivative | null;
  filters: {
    period: string;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const MeanMaxPowerChart = ({
  data,
  filters,
  handleChangeFilter,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const setFilters = useAthletePerformanceStore((s) => s.setFilters);
  const mmpFilters = useAthletePerformanceStore((s) => s.filters.mmp);
  const mmpHandleChangeFilter = useCallback(
    (key: keyof IAthleteMmpFilters) => {
      return (value: AnyValue) => {
        setFilters({
          mmp: {
            [key]: value,
          },
        } as Partial<IAthletePerformanceFilters>);
      };
    },
    [setFilters]
  );

  const isAbsolute = mmpFilters.dataType === DataType.Absolute;
  const dataPoints = useMemo(() => {
    if (!data) {
      return [];
    }

    return isAbsolute
      ? data?.powerAbsolutePoints
      : data?.powerToWeightAbsolutePoints;
  }, [data, isAbsolute]);

  const source = useMemo(() => {
    const source: IChartSource = [
      [
        ChartAxisName.Date,
        ChartAxisName.MMP5s,
        ChartAxisName.MMP1m,
        ChartAxisName.MMP5m,
        ChartAxisName.MMP10m,
        ChartAxisName.MMP20m,
        ChartAxisName.MMP60m,
      ],
    ];

    for (let i = 0; i < dataPoints.length; i++) {
      source.push([
        formatDate(new Date(dataPoints[i].startDate)),
        dataPoints[i].data.mmp5Sec || null,
        dataPoints[i].data.mmp1Min || null,
        dataPoints[i].data.mmp5Min || null,
        dataPoints[i].data.mmp10Min || null,
        dataPoints[i].data.mmp20Min || null,
        dataPoints[i].data.mmp60Min || null,
      ]);
    }

    return source;
  }, [dataPoints]);

  const onRenderTooltipValue = useCallback(
    (value: number, _: string) => {
      if (isAbsolute) {
        return `${Math.round(value)} ${t('units.w')}`;
      }
      return `${(Math.round(value * 100) / 100).toFixed(2)} ${t('units.w')}/${t(
        'units.kg'
      )}`;
    },
    [isAbsolute, t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: isMobile
        ? {
            left: 40,
            right: 0,
          }
        : {},
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 30,
          interval: getDateAxisInterval({
            period: filters.period,
            dateGroupBy: mmpFilters.dateGroupBy,
          }),
        },
        axisLine: {
          show: true,
        },
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: isMobile ? { fontSize: 10 } : undefined,
        },
      ],
      series: [
        {
          name: ChartAxisName.MMP5s,
          type: 'line',
          connectNulls: true,
          itemStyle: {
            color: ChartColor.LightRed,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.MMP1m,
          type: 'line',
          connectNulls: true,
          itemStyle: {
            color: ChartColor.LightCyan,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.MMP5m,
          type: 'line',
          connectNulls: true,
          itemStyle: {
            color: ChartColor.LightBlue,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.MMP10m,
          type: 'line',
          connectNulls: true,
          itemStyle: {
            color: ChartColor.LightPurple,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.MMP20m,
          type: 'line',
          connectNulls: true,
          itemStyle: {
            color: ChartColor.LightMagenta,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.MMP60m,
          type: 'line',
          connectNulls: true,
          itemStyle: {
            color: ChartColor.LightYellow,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [
    filters.period,
    mmpFilters.dateGroupBy,
    onRenderTooltipValue,
    source,
    isMobile,
  ]);

  const onRenderLegendItem = (item) => {
    return <ChartLegendItem item={item} />;
  };

  const filtersBarProps = {
    filters: (
      <>
        <LastNDaysFilter
          value={filters.period}
          onChange={handleChangeFilter('period')}
          selectProps={{ variant: 'layer3' }}
        />
        <DataTypeFilter
          value={mmpFilters.dataType}
          onChange={mmpHandleChangeFilter('dataType')}
          selectProps={{
            variant: 'layer3',
          }}
        />
        <GroupByPeriodFilter
          options={[DateGroupBy.Day, DateGroupBy.Week]}
          value={mmpFilters.dateGroupBy}
          onChange={mmpHandleChangeFilter('dateGroupBy')}
          selectProps={{
            variant: 'layer3',
          }}
        />
      </>
    ),
  };

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.mean_max_power'),
          filtersBarProps,
        }}
        option={option}
        legendProps={{
          onRenderItem: onRenderLegendItem,
        }}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.MeanMaxPower,
          }),
        }}
      />
    </ChartWrapper>
  );
};
