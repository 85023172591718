import { useEffect, useMemo, useRef } from 'react';
import { useDictionariesStore } from '../store/dictionaries/slice';
import { DictionariesRepository } from '../api/dictionaries/repository';
import { DictionariesService } from '../api/dictionaries/service';
import { IEnums } from '../types/dictionaries';

export const useFetchEnums = () => {
  const setEnums = useDictionariesStore((s) => s.setEnums);
  const fetchingRef = useRef<boolean>(false);

  const dictionariesRepository = useMemo(() => {
    return new DictionariesRepository(new DictionariesService(), {
      abort: true,
    });
  }, []);

  useEffect(() => {
    if (fetchingRef.current) {
      return;
    }

    fetchingRef.current = true;
    dictionariesRepository
      .getEnums()
      .then((enums: IEnums) => {
        setEnums(enums);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [dictionariesRepository, setEnums]);
};
