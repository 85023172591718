import './index.css';
import { ISegmentProps, Segment, Typography } from '@cycling-web/analog-ui';

type IProps = {
  title: string;
  threshold: string | number;
  units: string;
  method: string;
  segmentProps?: ISegmentProps;
};

export const TrainingZonesHeader = ({
  title,
  units,
  threshold,
  method,
  segmentProps,
}: IProps) => {
  return (
    <header className="training-zones--profile__header">
      <div className="training-zones--profile__header-text">
        <Typography
          className="training-zones--profile__method-name"
          text={title}
        />
        <Typography
          className="training-zones--profile__method-threshold"
          text={threshold}
        />
        <Typography
          className="training-zones--profile__method-units"
          text={units}
        />
        {method && (
          <Typography
            className="training-zones--profile__method-author"
            text={method}
          />
        )}
      </div>

      <div className="training-zones--profile__header-actions">
        {segmentProps && <Segment size="s" {...segmentProps} />}
      </div>
    </header>
  );
};
