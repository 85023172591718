import './index.css';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import React, {
  CSSProperties,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IPinnedTabsFilters } from '../../types';
import { usePinnedTabStore } from '../../../../store/pinned-tabs/slice';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { Chart } from '../../../../components/Chart';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { ISmartChartConfig } from '../../../../types/smart-charts';
import { computeGraphOption } from '../../../SmartCharts/utils/graphConfig';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useGroupsStore } from '../../../../store/groups/slice';
import { PinnedChartMenu } from '../PinnedChartMenu';
import { IconButton, Tooltip } from '@cycling-web/analog-ui';
import { Maximize2, Minimize2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { IFiltersBarProps } from '@cycling-web/common';
import { usePageFilters } from '../../../../context/PageFilters';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import { TrackingEvent, TrackingModal } from '../../../../ms/tracking-entities';

type IProps = {
  chart: ISmartChartConfig;
};

export const PinnedChart = ({ chart }: IProps) => {
  const { t } = useTranslation();
  const athlete = useAthleteStore((s) => s.athlete);
  const { filters, handleChangeFilter } = usePageFilters<IPinnedTabsFilters>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [wrapperStyle, setWrapperStyle] = useState<CSSProperties>({});

  const toggleExpand = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();

      if (expanded) {
        trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
          modal: TrackingModal.EXPAND_CHART,
        });
      } else {
        trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
          modal: TrackingModal.EXPAND_CHART,
        });
      }

      if (wrapperRef.current) {
        if (!expanded) {
          const rect = wrapperRef.current.getBoundingClientRect();

          wrapperRef.current.classList.add('chart-wrapper--expanded');

          setWrapperStyle({
            width: `${rect.width}px`,
            height: `${rect.height}px`,
          });
        } else {
          wrapperRef.current.classList.remove('chart-wrapper--expanded');
          setWrapperStyle({});
        }
      }
      setExpanded(!expanded);
    },
    [expanded, setExpanded]
  );

  const chartsLoaded = usePinnedTabStore((s) => s.chartsLoaded);
  const isChartsLoading = usePinnedTabStore((s) => s.isChartsLoading);
  const group = useGroupsStore((s) => s.group);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);
  const athletesMap = useAthletesStore((s) => s.athletesMap);

  const selectedAthletes = useMemo(() => {
    if (!athletesLoaded) {
      return [];
    }

    if (group) {
      return group.athleteIds.map((id: number) => athletesMap[id]);
    }

    if (athlete) {
      return [athlete];
    }

    return [];
  }, [athletesLoaded, group, athlete, athletesMap]);

  const option = useMemo(() => {
    return computeGraphOption(chart, selectedAthletes);
  }, [chart, selectedAthletes]);

  const filtersBarProps: IFiltersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'layer3' }}
      />
    ),
    buttonProps: {
      variant: 'layer3',
    },
  };

  const adornmentLeftJSX = (
    <Tooltip
      anchor={
        <IconButton
          variant="layer3"
          content={expanded ? <Minimize2 /> : <Maximize2 />}
          onClick={toggleExpand}
        />
      }
      content={expanded ? t('tooltip.collapse') : t('tooltip.expand')}
      placement="bottom-end"
    />
  );
  const adornmentRightJSX = <PinnedChartMenu chart={chart} />;

  const chartName = chart.ai_graph_meta_data.title.value;
  const trackingChartName = `${athlete?.fullName} - ${chart?.id}`;

  return (
    <>
      <ChartWrapper ref={wrapperRef}>
        <Chart
          headerProps={{
            title: chartName,
            filtersBarProps,
            adornmentLeft: adornmentLeftJSX,
            adornmentRight: adornmentRightJSX,
          }}
          option={option}
          loading={!chartsLoaded || isChartsLoading}
          events={{
            onMouseOver: handleChartMouseOver({
              name: trackingChartName,
            }),
          }}
          forceReinit={true}
        />
      </ChartWrapper>
      {expanded && (
        <>
          <div className="pinned-chart__expanded-underlay" />
          <div
            className="pinned-chart__expanded-placeholder"
            style={wrapperStyle}
          />
        </>
      )}
    </>
  );
};
