import { useCallback, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useUsersStore } from '../../../store/users/slice';
import { LS } from '../../../constants';
import { Logger } from '../../../utils/logger';
import { parseTeamName } from '../../../utils/teamName';
import {
  ConversationState,
  IAnaContextEntity,
  IAnaContextPayload,
  IAnaRequestPayload,
  IAnaResponsePayload,
  IAnaSocketMessageType,
  IAnaUser,
  IAnaUserType,
  IUseWebSocketsProps,
} from '../types';
import { useFeatureFlagsStore } from '../../../store/feature-flags/slice';
import { FeatureFlag } from '../../../types/feature-flags';
import { Environment, getEnvironment } from '../../../utils/getEnvironment';

export const useWebSockets = (props: IUseWebSocketsProps) => {
  const { onSocketMessage, setConversationState } = props;
  const [socketConnected, setSocketConnected] = useState<boolean>(false);

  const userProfile = useUsersStore((s) => s.userProfile);
  const socketRef = useRef<Socket | null>(null);
  /** Check streaming without re-rendering */
  const isStreamingRef = useRef<boolean>(false);
  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);

  const emit = useCallback(
    (query: string, entities?: IAnaContextEntity[] = []) => {
      if (socketRef.current && userProfile) {
        const teamName = localStorage.getItem(LS.Team) || '';
        const accessToken = localStorage.getItem(LS.AccessToken) || '';

        const user: IAnaUser = {
          user_type: IAnaUserType.AUTHENTICATED,
          user_id: userProfile.id,
          tenant_name: parseTeamName(teamName),
          auth_token: `Bearer ${accessToken}`,
        };

        const payload: IAnaRequestPayload = {
          user,
          message: {
            message_type: IAnaSocketMessageType.ASK,
            query,
            entities,
          },
        };

        socketRef.current.emit('message', payload);
      }
    },
    [userProfile]
  );

  const emitContext = useCallback(
    (entities: IAnaContextEntity[]) => {
      if (socketRef.current && userProfile) {
        const teamName = localStorage.getItem(LS.Team) || '';
        const accessToken = localStorage.getItem(LS.AccessToken) || '';

        const user: IAnaUser = {
          user_type: IAnaUserType.AUTHENTICATED,
          user_id: userProfile.id,
          tenant_name: parseTeamName(teamName),
          auth_token: `Bearer ${accessToken}`,
        };

        const payload: IAnaContextPayload = {
          user,
          message: {
            message_type: IAnaSocketMessageType.PROVIDE_CONTEXT,
            entities,
          },
        };

        Logger.info('[Context] Emit');
        console.log(payload);
        socketRef.current.emit('message', payload);
      }
    },
    [userProfile]
  );

  useEffect(() => {
    if (
      !userProfile?.id ||
      !featureFlags.includes(FeatureFlag.Ana_Everywhere)
    ) {
      return;
    }

    const BASE_URL =
      getEnvironment() === Environment.Production
        ? 'wss://cyclingsports-ai-backend.analog.io'
        : 'wss://dev-cyclingsports-ai-backend.analog.io';

    const teamName = localStorage.getItem(LS.Team) || '';
    const accessToken = localStorage.getItem(LS.AccessToken) || '';

    socketRef.current = io(BASE_URL, {
      transports: ['websocket'],
      autoConnect: true,
      query: {
        'x-ana-analog-sports-user-id': userProfile.id.toString(),
        Authorization: `Bearer ${accessToken}`,
        'x-team-name': parseTeamName(teamName),
        'x-ana-analog-sports-user-validation-off': 'True',
      },
    });

    socketRef.current.on('connect', () => {
      setSocketConnected(true);
      Logger.success('[Websocket] Connected');
    });

    socketRef.current.on('connect_error', (error) => {
      Logger.error('[Websocket] Error');
      console.log(error);
    });

    socketRef.current.on('message', (data: string) => {
      const parsed: IAnaResponsePayload = JSON.parse(
        data
      ) as IAnaResponsePayload;
      const type = parsed.message.message_type;

      if (
        !isStreamingRef.current &&
        type === IAnaSocketMessageType.STREAMING_RESPONSE
      ) {
        isStreamingRef.current = true;
        setConversationState(ConversationState.STREAMING);
      }

      if (onSocketMessage) {
        onSocketMessage(parsed);
      }

      if (type === IAnaSocketMessageType.RESPONSE_STOP) {
        isStreamingRef.current = false;
        setConversationState(ConversationState.IDLE);
      }
    });

    return () => {
      if (socketRef.current) {
        Logger.info('[Websocket] Disconnected');
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [emit, onSocketMessage, setConversationState, userProfile, featureFlags]);

  return {
    emit,
    emitContext,
    socketConnected,
  };
};
