import {
  $addUpdateTag,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_TAB_COMMAND,
  LexicalNode,
  TextNode,
} from 'lexical';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useRef } from 'react';
import { mergeRegister } from '@lexical/utils';
import {
  $createAutocompleteNode,
  $isAutocompleteNode,
} from '../node/AutocompleteNode';
import { IAutocompleteFoundMatch, IAutocompleteProps } from '../types';
import { $createMentionNode } from '../../mention/node/MentionNode';

type IAutocompleteOption = {
  id: any;
  text: string;
  entity?: string;
};

const AUTOCOMPLETE_UPDATE_TAG = 'autocomplete-update';
const MIN_CHARS = 2;

export default function AutocompletePlugin({ options }: IAutocompleteProps) {
  const [editor] = useLexicalComposerContext();
  const matchInfoRef = useRef<IAutocompleteOption | null>(null);
  const activeAutocompleteRef = useRef<boolean>(false);

  const removeAutocompleteNode = useCallback(() => {
    $getRoot()
      .getChildren()
      .forEach((node: LexicalNode) => {
        if ($isElementNode(node)) {
          node.getChildren().forEach((childNode) => {
            if ($isAutocompleteNode(childNode)) {
              childNode.remove();
            }
          });
        }
      });
  }, []);

  const findMatchingSuggestion = useCallback(
    (text: string, cursorPosition: number): IAutocompleteOption | null => {
      if (!text || text.length < MIN_CHARS) {
        return null;
      }

      const textUpToCursor = text.substring(0, cursorPosition);

      // Find the last word
      const lastSpaceIndex = textUpToCursor.lastIndexOf(' ');
      const startOfLastWord = lastSpaceIndex >= 0 ? lastSpaceIndex + 1 : 0;
      const lastWord = textUpToCursor.substring(startOfLastWord).toLowerCase();

      // If the last word is too short, check if we should look for two-word match
      if (lastWord.length < MIN_CHARS && lastSpaceIndex <= 0) {
        return null;
      }

      // Find the second-to-last word (if it exists)
      let secondLastWord = '';
      let twoWordPhrase = '';

      if (lastSpaceIndex > 0) {
        const secondLastSpaceIndex = textUpToCursor.lastIndexOf(
          ' ',
          lastSpaceIndex - 1
        );
        const startOfSecondLastWord =
          secondLastSpaceIndex >= 0 ? secondLastSpaceIndex + 1 : 0;
        secondLastWord = textUpToCursor
          .substring(startOfSecondLastWord, lastSpaceIndex)
          .toLowerCase();

        // Create two-word phrase for matching
        if (secondLastWord.length >= MIN_CHARS) {
          twoWordPhrase = (secondLastWord + ' ' + lastWord).toLowerCase();
        }
      }

      // Prepare to track best matches for both single and two-word scenarios
      let singleWordMatch: IAutocompleteFoundMatch | null = null;
      let twoWordMatch: IAutocompleteFoundMatch | null = null;

      for (const option of options) {
        const optionText = option.text.toLowerCase();

        // 1. Check single word match (last word)
        if (lastWord.length >= MIN_CHARS && optionText.startsWith(lastWord)) {
          const matchLength = lastWord.length;
          const matchQuality = matchLength / optionText.length; // Favor complete matches

          if (!singleWordMatch || matchQuality > singleWordMatch.matchQuality) {
            singleWordMatch = {
              option,
              matchLength,
              matchQuality,
            };
          }
        }

        // 2. Check two-word match
        if (twoWordPhrase && optionText.startsWith(twoWordPhrase)) {
          const matchLength = twoWordPhrase.length;
          const matchQuality = matchLength / optionText.length + 0.5; // Add bonus for two-word match

          if (!twoWordMatch || matchQuality > twoWordMatch.matchQuality) {
            twoWordMatch = {
              option,
              matchLength,
              matchQuality,
            };
          }
        }
      }

      // 3. Compare and select the best match
      let bestMatch: IAutocompleteFoundMatch | null = null;

      if (twoWordMatch && singleWordMatch) {
        // Prefer the two-word match if it has higher quality
        bestMatch =
          twoWordMatch.matchQuality >= singleWordMatch.matchQuality
            ? twoWordMatch
            : singleWordMatch;
      } else {
        bestMatch = twoWordMatch || singleWordMatch;
      }

      if (bestMatch) {
        // Get the remaining text to suggest
        const remainingText = bestMatch.option.text.slice(
          bestMatch.matchLength
        );

        return {
          id: bestMatch.option.id.toString(),
          text: remainingText,
          entity: bestMatch.option.entity,
        };
      }

      return null;
    },
    [options]
  );

  const updateAutocomplete = useCallback(
    (match: IAutocompleteOption | null) => {
      if (match === matchInfoRef.current) {
        return;
      }

      matchInfoRef.current = match;

      editor.update(() => {
        $addUpdateTag(AUTOCOMPLETE_UPDATE_TAG);

        removeAutocompleteNode();

        if (match) {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const anchor = selection.anchor;
            const anchorNode = anchor.getNode();

            if (anchorNode instanceof TextNode) {
              const autocompleteNode = $createAutocompleteNode(
                match.id,
                match.text
              );

              // Get the text content of the node
              const text = anchorNode.getTextContent();

              // Split the text at the cursor position
              const textBeforeCursor = text.substring(0, anchor.offset);
              const textAfterCursor = text.substring(anchor.offset);

              // Update the text node to contain only text before cursor
              anchorNode.setTextContent(textBeforeCursor);

              // Insert autocomplete node after the text node
              anchorNode.insertAfter(autocompleteNode);

              // Create a new text node for content after cursor (if any)
              if (textAfterCursor.length > 0) {
                const newTextNode = new TextNode(textAfterCursor);
                autocompleteNode.insertAfter(newTextNode);
              }

              // Keep selection at the end of the original text node
              selection.setTextNodeRange(
                anchorNode,
                anchor.offset,
                anchorNode,
                anchor.offset
              );

              activeAutocompleteRef.current = true;
            }
          }
        } else {
          activeAutocompleteRef.current = false;
        }
      });
    },
    [removeAutocompleteNode, editor]
  );

  const applySuggestion = useCallback(() => {
    const match = matchInfoRef.current;
    if (match && activeAutocompleteRef.current) {
      editor.update(() => {
        $addUpdateTag(AUTOCOMPLETE_UPDATE_TAG);

        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchor = selection.anchor;
          const anchorNode = anchor.getNode();

          if (anchorNode instanceof TextNode) {
            const text = anchorNode.getTextContent();
            const cursorPosition = anchor.offset;

            // Find the full matching option
            let fullOption: IAutocompleteOption | null = null;
            for (const option of options) {
              if (option.id.toString() === match.id) {
                fullOption = option;
                break;
              }
            }

            if (fullOption) {
              // Find how much of the option is already in the text
              const fullText = fullOption.text;
              const typedPart = fullText.substring(
                0,
                fullText.length - match.text.length
              );

              // Find where the typed part starts in the text
              const typedPartPos = text
                .toLowerCase()
                .lastIndexOf(typedPart.toLowerCase());

              if (typedPartPos !== -1) {
                // Remove the typed part from the text node
                const textBeforeMatch = text.substring(0, typedPartPos);
                const textAfterCursor = text.substring(cursorPosition);

                // Set the text without the typed part
                anchorNode.setTextContent(textBeforeMatch + textAfterCursor);

                // Position selection where the mention should be inserted
                selection.setTextNodeRange(
                  anchorNode,
                  textBeforeMatch.length,
                  anchorNode,
                  textBeforeMatch.length
                );

                // Insert only the mention node
                selection.insertNodes([
                  $createMentionNode(
                    fullOption.text,
                    fullOption.id.toString(),
                    fullOption.entity
                  ),
                ]);

                // Insert a space after the mention
                selection.insertText(' ');
              }
            }

            removeAutocompleteNode();
            matchInfoRef.current = null;
            activeAutocompleteRef.current = false;
          }
        }
      });
    }
  }, [editor, options, removeAutocompleteNode]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState, tags }) => {
      if (tags.has(AUTOCOMPLETE_UPDATE_TAG)) {
        return;
      }

      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode();

          if (anchorNode instanceof TextNode) {
            const text = anchorNode.getTextContent();
            const offset = selection.anchor.offset;

            const isAtEndOfWord =
              offset === text.length ||
              text.charAt(offset) === '\n' ||
              text.charAt(offset) === ' ';

            if (isAtEndOfWord && text && text.length >= MIN_CHARS) {
              const match = findMatchingSuggestion(text, offset);
              updateAutocomplete(match);
            } else {
              updateAutocomplete(null);
            }
          } else {
            updateAutocomplete(null);
          }
        } else {
          updateAutocomplete(null);
        }
      });
    });
  }, [editor, findMatchingSuggestion, updateAutocomplete]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_TAB_COMMAND,
        (event) => {
          if (activeAutocompleteRef.current) {
            event.preventDefault();
            applySuggestion();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        KEY_ARROW_RIGHT_COMMAND,
        (event) => {
          if (!activeAutocompleteRef.current) {
            return false;
          }

          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            const anchor = selection.anchor;
            const anchorNode = anchor.getNode();

            if (anchorNode instanceof TextNode) {
              const text = anchorNode.getTextContent();
              const match = matchInfoRef.current;

              if (match) {
                const fullText =
                  options.find((opt) => opt.id.toString() === match.id)?.text ||
                  '';
                const typedPart = fullText.substring(
                  0,
                  fullText.length - match.text.length
                );
                const typedPartPos = text
                  .toLowerCase()
                  .lastIndexOf(typedPart.toLowerCase());

                if (
                  typedPartPos !== -1 &&
                  anchor.offset === typedPartPos + typedPart.length
                ) {
                  event.preventDefault();
                  applySuggestion();
                  return true;
                }
              }
            }
          }

          return false;
        },
        COMMAND_PRIORITY_NORMAL
      ),

      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (activeAutocompleteRef.current) {
            updateAutocomplete(null);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_NORMAL
      )
    );
  }, [
    editor,
    findMatchingSuggestion,
    updateAutocomplete,
    applySuggestion,
    options,
  ]);

  return null;
}
