import './index.css';
import { User, X, Zap } from 'lucide-react';
import { Divider, IconButton, Typography } from '@cycling-web/analog-ui';
import { clsx } from 'clsx';
import { Link, useParams } from 'react-router';
import { ROUTES } from '../../../router/routes';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useBaseUrl } from '../../../hooks/useBaseUrl';
import { useUserRole } from '../../../hooks/useUserRole';
import { Url } from '../../../utils/url';
import { getDefaultPageFilters } from '../../../constants/page-filters';
import { useFeatureFlagsStore } from '../../../store/feature-flags/slice';
import { FeatureFlag } from '../../../types/feature-flags';
import { useUsersStore } from '../../../store/users/slice';
import AnalogSportLogo from '../../../assets/illustrations/analog-sport-logo.svg?react';

type IProps = {
  isOpen: boolean;
  onDismiss: () => void;
};

export const MobileMenu = ({ isOpen, onDismiss }: IProps) => {
  const { t } = useTranslation();
  const baseUrl = useBaseUrl();
  const { isAthlete, isAdmin } = useUserRole();
  const { athleteId } = useParams();
  const userProfile = useUsersStore((s) => s.userProfile);
  const defaultUrl = `${baseUrl}/${ROUTES.ATHLETE}/${userProfile?.athleteId}`;
  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);
  const hasCalendar = featureFlags.includes(FeatureFlag.Calendar_Page);

  const rootClass = clsx('mobile-menu', isOpen && 'mobile-menu--open');

  const links = useMemo(() => {
    const links: any[] = [];

    if (!isAthlete) {
      const defaultUrl = `${baseUrl}/${ROUTES.COACH}`;

      links.push(
        {
          url: `${defaultUrl}/${ROUTES.DASHBOARD}${Url.stringifyFilters(
            getDefaultPageFilters().dashboard
          )}`,
          text: t('label.team_dashboard'),
        },
        {
          url: `${defaultUrl}/${ROUTES.ATHLETES}`,
          text: t('label.athletes'),
        },
        {
          url: `${defaultUrl}/${ROUTES.GROUPS}`,
          text: t('label.groups'),
        },
        hasCalendar && {
          url: `${defaultUrl}/${ROUTES.CALENDAR}${
            athleteId ? `/${athleteId}` : ''
          }`,
          text: t('label.calendar'),
        },
        {
          url: `${defaultUrl}/${ROUTES.PERFORMANCE_REPORT}`,
          text: t('label.performance_report'),
        },
        {
          url: `${defaultUrl}/${ROUTES.MEDICAL_REPORT_TABLE}`,
          text: t('label.medical_report'),
        },
        {
          url: `${defaultUrl}/${ROUTES.SMART_CHARTS}`,
          text: t('label.smart_charts'),
        },
        isAdmin && {
          url: `${defaultUrl}/${ROUTES.ADMIN_PANEL}`,
          text: t('label.admin_panel'),
        },
        {
          text: 'divider',
        },
        {
          url: `${defaultUrl}/${ROUTES.SETTINGS}`,
          text: t('label.settings'),
        },
        {
          url: `/${ROUTES.LEGAL}`,
          text: t('label.legal'),
        },
        {
          url: '',
          text: 'divider',
        },
        {
          url: `/${ROUTES.SIGN_OUT}`,
          text: t('action.sign_out'),
        }
      );
    } else {
      links.push(
        {
          url: `${defaultUrl}/${ROUTES.PROFILE}`,
          icon: <User />,
          text: t('label.my_profile'),
        },
        hasCalendar && {
          url: `${defaultUrl}/${ROUTES.CALENDAR}${
            athleteId ? `/${athleteId}` : ''
          }`,
          text: t('label.calendar'),
        },
        {
          url: `${defaultUrl}/${ROUTES.SMART_CHARTS}`,
          icon: <Zap />,
          text: t('label.smart_charts'),
        },
        {
          url: '',
          text: 'divider',
        },
        {
          url: `${defaultUrl}/${ROUTES.SETTINGS}`,
          text: t('label.settings'),
        },
        {
          url: `/${ROUTES.LEGAL}`,
          text: t('label.legal'),
        },
        {
          text: 'divider',
        },
        {
          url: `/${ROUTES.SIGN_OUT}`,
          text: t('action.sign_out'),
        }
      );
    }

    return links;
  }, [isAthlete, baseUrl, t, hasCalendar, athleteId, isAdmin, defaultUrl]);

  const linksJSX = links
    .filter((link) => !!link)
    .map((link, i) => {
      if (link.text === 'divider') {
        return (
          <div key={i} className="mobile-menu__link">
            <Divider />
          </div>
        );
      }

      return (
        <Link
          key={i}
          className="mobile-menu__link"
          to={link.url}
          onClick={onDismiss}
        >
          <Typography variant="h4" text={link.text} />
        </Link>
      );
    });

  return (
    <div className={rootClass}>
      <header className="mobile-menu__header">
        <div className="mobile-menu__header-logo">
          <AnalogSportLogo />
        </div>
        <IconButton
          size="l"
          variant="quietLayer2"
          content={<X />}
          onClick={onDismiss}
        />
      </header>
      <div className="mobile-menu__main">{linksJSX}</div>
    </div>
  );
};
