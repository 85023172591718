import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPerformanceReport } from '../../../../../types/performance';
import { PerformanceRepository } from '../../../../../api/performance/repository';
import { PerformanceService } from '../../../../../api/performance/service';
import { useParams } from 'react-router';
import { format, startOfWeek } from 'date-fns';

export const useFetchWeeklyPerformanceReport = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();

  const [report, setReport] = useState<IPerformanceReport | undefined>(
    undefined
  );
  const [reportLoaded, setReportLoaded] = useState<boolean>(false);

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId) {
      return;
    }

    const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
    const week = format(currentWeekStart, 'w');
    const year = format(currentWeekStart, 'RRRR');

    const p = {
      athleteId,
      week,
      year,
    };

    performanceRepository
      .getAthletePerformanceReport(p)
      .then((reports: IPerformanceReport[]) => {
        setReport(reports[0]);
        setReportLoaded(true);
      })
      .catch(() => {
        setReportLoaded(true);
      });
  }, [athleteId, performanceRepository, t]);

  return {
    report,
    reportLoaded,
  };
};
