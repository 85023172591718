import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Page } from '@cycling-web/common';
import { MedicalReportsFilters } from './components/MedicalReportsFilters';
import { MedicalReportsTable } from './components/MedicalReportsTable';
import { useFetchMedicalReports } from './hooks/useFetchMedicalReports';
import { useMedicalReportsStore } from './store/slice';
import { useTitle } from '../../hooks/useTitle';
import { LS } from '../../constants';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { onTrackBackButtonClick } from '../../ms/utils';
import { useMobileMenu } from '../../components/MobileMenu';

export const MedicalReports = () => {
  const { t } = useTranslation();
  useTitle(t('label.medical_report'));
  useFetchMedicalReports();

  const onRenderMenu = useMobileMenu();
  const reset = useMedicalReportsStore((s) => s.reset);

  const backUrl = localStorage.getItem(LS.PreviousPage) || undefined;

  useEffect(() => {
    return () => {
      reset();
      localStorage.removeItem(LS.PreviousPage);
    };
  }, [reset]);

  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().medicalReports}
    >
      <Page
        pageHeaderProps={{
          title: t('label.medical_report'),
          backUrl,
          onBackUrlClick: onTrackBackButtonClick,
          onRenderMenu,
        }}
      >
        <MedicalReportsFilters />
        <MedicalReportsTable />
      </Page>
    </PageFiltersContextProvider>
  );
};
