import './index.css';
import { WidgetHeader } from '../WidgetHeader';
import { Widget } from '../Widget';
import { useTranslation } from 'react-i18next';
import { DisciplineFilter } from '../../../../components/filters/DisciplineFilter';
import { EmptyState, IconButton, Tooltip } from '@cycling-web/analog-ui';
import { Settings } from 'lucide-react';
import { IFiltersBarProps } from '@cycling-web/common';
import { TrainingZonesModal } from '../TrainingZonesModal';
import { usePageFilters } from '../../../../context/PageFilters';
import { IAthleteProfileFilters } from '../../types';
import { useAthleteProfileStore } from '../../store/slice';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { TrainingZoneProfiles } from './components/TrainingZoneProfiles';
import { TrainingZoneProfilesLoading } from './components/TrainingZoneProfilesLoading';
import { Discipline } from '../../../../types/enums';
import { TrainingZoneKeys } from '../../utils/getDefaults';
import { getDefaultPageFilters } from '../../../../constants/page-filters';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  FallbackValue,
  TrackingEvent,
  TrackingModal,
} from '../../../../ms/tracking-entities';

type IOpenModal = {
  discipline: Discipline;
  composeKey?: string;
};

export const TrainingZones = () => {
  const { t } = useTranslation();
  const trainingProfiles = useAthleteProfileStore((s) => s.trainingProfiles);
  const athleteProfilesLoaded = useAthleteProfileStore(
    (s) => s.athleteProfilesLoaded
  );

  const { filters, initPageFilters } = usePageFilters<IAthleteProfileFilters>();

  const [showZonesModal, setShowZonesModal] = useState<IOpenModal | null>(null);

  const openZonesModal = useCallback(
    (composeKey?: string) => {
      trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
        modal: TrackingModal.TRAINING_ZONES,
      });
      setShowZonesModal({
        discipline: filters.discipline,
        composeKey: composeKey,
      });
    },
    [filters.discipline]
  );

  const dismissZonesModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.TRAINING_ZONES,
    });
    setShowZonesModal(null);
  }, []);

  const onDisciplineChange = useCallback(
    (discipline: Discipline) => {
      if (discipline === Discipline.Cycling) {
        initPageFilters(
          {
            ...getDefaultPageFilters().athleteProfile,
            discipline,
            p1: TrainingZoneKeys.CyclingPowerMFTP,
            p2: TrainingZoneKeys.CyclingHeartRateDefault,
          },
          true
        );
      } else if (discipline === Discipline.Swimming) {
        initPageFilters(
          {
            ...getDefaultPageFilters().athleteProfile,
            discipline,
            p1: TrainingZoneKeys.SwimmingCSSDefault,
            p2: TrainingZoneKeys.SwimmingHeartRateDefault,
          },
          true
        );
      } else {
        initPageFilters(
          {
            ...getDefaultPageFilters().athleteProfile,
            discipline,
            p1: TrainingZoneKeys.RunningPaceDefault,
            p2: TrainingZoneKeys.RunningHeartRateDefault,
          },
          true
        );
      }
    },
    [initPageFilters]
  );

  const filtersBarProps: IFiltersBarProps = {
    filters: (
      <DisciplineFilter
        value={filters.discipline}
        onChange={onDisciplineChange}
        selectProps={{
          variant: 'layer4',
          dropdownProps: { minWidth: '180px', placement: 'bottom-end' },
        }}
      />
    ),
    buttonProps: {
      variant: 'layer4',
    },
  };

  const contentAfterFilter = (
    <Tooltip
      anchor={
        <IconButton
          variant="layer4"
          content={<Settings />}
          onClick={() => openZonesModal()}
        />
      }
      content={t('tooltip.edit_zones')}
      placement="bottom-end"
    />
  );

  const trainingZonesJSX = useMemo((): ReactNode => {
    if (filters.discipline === Discipline.Cycling) {
      const mftp = trainingProfiles[TrainingZoneKeys.CyclingPowerMFTP];
      const mcp = trainingProfiles[TrainingZoneKeys.CyclingPowerMCP];
      const hr = trainingProfiles[TrainingZoneKeys.CyclingHeartRateDefault];

      if (mftp?.zones.length === 0 && hr?.zones.length === 0) {
        return (
          <EmptyState
            size="s"
            text={t('banner.empty_zones_power_text')}
            buttonProps={{
              content: t('banner.empty_zones_action'),
              onClick: () => openZonesModal(),
            }}
          />
        );
      }

      return (
        <>
          <TrainingZoneProfiles
            profiles={[mftp, mcp].filter(Boolean)}
            param="p1"
            openZonesModal={openZonesModal}
          />
          <TrainingZoneProfiles
            profiles={[hr].filter(Boolean)}
            param="p2"
            openZonesModal={openZonesModal}
          />
        </>
      );
    }

    if (filters.discipline === Discipline.Swimming) {
      const pace = trainingProfiles[TrainingZoneKeys.SwimmingCSSDefault];
      const hr = trainingProfiles[TrainingZoneKeys.SwimmingHeartRateDefault];

      if (pace?.zones.length === 0 && hr?.zones.length === 0) {
        return (
          <EmptyState
            size="s"
            text={t('banner.empty_zones_pace_text')}
            buttonProps={{
              content: t('banner.empty_zones_action'),
              onClick: () => openZonesModal(),
            }}
          />
        );
      }

      return (
        <>
          <TrainingZoneProfiles
            profiles={[pace].filter(Boolean)}
            param="p1"
            openZonesModal={openZonesModal}
          />
          <TrainingZoneProfiles
            profiles={[hr].filter(Boolean)}
            param="p2"
            openZonesModal={openZonesModal}
          />
        </>
      );
    }

    if (filters.discipline === Discipline.Running) {
      const pace = trainingProfiles[TrainingZoneKeys.RunningPaceDefault];
      const hr = trainingProfiles[TrainingZoneKeys.RunningHeartRateDefault];

      if (pace?.zones.length === 0 && hr?.zones.length === 0) {
        return (
          <EmptyState
            size="s"
            text={t('banner.empty_zones_pace_text')}
            buttonProps={{
              content: t('banner.empty_zones_action'),
              onClick: () => openZonesModal(),
            }}
          />
        );
      }

      return (
        <>
          <TrainingZoneProfiles
            profiles={[pace].filter(Boolean)}
            param="p1"
            openZonesModal={openZonesModal}
          />
          <TrainingZoneProfiles
            profiles={[hr].filter(Boolean)}
            param="p2"
            openZonesModal={openZonesModal}
          />
        </>
      );
    }

    return null;
  }, [filters.discipline, openZonesModal, trainingProfiles, t]);

  return (
    <Widget>
      <WidgetHeader
        title={t('label.zones')}
        filtersBarProps={filtersBarProps}
        contentAfterFilter={contentAfterFilter}
      />

      <div className="athlete-profiles__discipline-profiles">
        {athleteProfilesLoaded ? (
          trainingZonesJSX
        ) : (
          <>
            <TrainingZoneProfilesLoading />
            <TrainingZoneProfilesLoading />
          </>
        )}
      </div>

      {showZonesModal && (
        <TrainingZonesModal
          onDismiss={dismissZonesModal}
          defaultDiscipline={showZonesModal.discipline}
          composeKey={showZonesModal.composeKey}
        />
      )}
    </Widget>
  );
};
