import { IFiltersSlice, IFiltersState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ICustomPeriodFilter } from '../../types/filters';

export const initialState: IFiltersState = {
  seasons: [],
  customPeriods: [],
};

export const useFiltersStore = create<IFiltersSlice>()(
  immer((set) => ({
    ...initialState,
    setSeasons: (seasons: number[]) => {
      set((state) => {
        state.seasons = seasons.sort((a, b) => b - a);
      });
    },
    setCustomPeriods: (customPeriods: ICustomPeriodFilter[]) => {
      set((state) => {
        state.customPeriods = customPeriods;
      });
    },
    appendCustomPeriod: (period: ICustomPeriodFilter) => {
      set((state) => {
        state.customPeriods.push(period);
      });
    },
    updateCustomPeriod: (period: ICustomPeriodFilter) => {
      set((state) => {
        const index = state.customPeriods.findIndex((p) => p.id === period.id);

        if (index >= 0) {
          state.customPeriods[index] = period;
        }
      });
    },
    deleteCustomPeriod: (id: number) => {
      set((state) => {
        state.customPeriods = state.customPeriods.filter((p) => p.id !== id);
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
