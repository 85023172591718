import { t } from 'i18next';

export const ChartAxisName = {
  Date: 'Date',
  Athlete: 'Athlete',
  mCP: 'mCP',
  MMP: 'MMP',
  MMP5s: 'MMP 5s',
  MMP1m: 'MMP 1m',
  MMP5m: 'MMP 5m',
  MMP10m: 'MMP 10m',
  MMP20m: 'MMP 20m',
  MMP60m: 'MMP 60m',
  PowerProfile10s: '10s',
  PowerProfile30s: '30s',
  PowerProfile1m: '1m',
  PowerProfile5m: '5m',
  PowerProfile10m: '10m',
  PowerProfile20m: '20m',
  PowerProfile30m: '30m',
  PowerProfile60m: '60m',
  mCPRelative: 'mCPRelative',
  W: "W'",
  mCP_WKg: 'mCP (W/kg)',
  maximumOfMmp: 'maximumOfMmp',
  CP: 'CP',
  nm: '(Nm)',
  nm_kg: '(Nm/kg)',
  percent_decline: `% ${t('label.decline')}`,
  OverallScore: t('label.overall_score'),
  General: t('label.general'),
  Fatigue: t('label.fatigue'),
  Stress: t('label.stress'),
  Mood: t('label.mood'),
  Sleep: t('label.sleep'),
  Weight: t('label.weight'),
  Fat_Percent: `${t('label.fat')} %`,
  Skin_Folds_Sum: t('label.skin_folds_sum'),
  HRV: t('label.hrv'),
  RHR: t('label.rhr'),
  Baseline_HRV: t('label.baseline_hrv'),
  Baseline_RHR: t('label.baseline_rhr'),
  ATL: t('label.atl'),
  CTL: t('label.ctl'),
  TSB: t('label.tsb'),
  PD_Curve: t('label.pd_curve'),
  MMP_Curve: t('label.mmp_curve'),
  Distance: t('label.distance'),
  Duration: t('label.duration'),
  Elevation: t('label.elevation'),
  ElevationGain: t('label.elevation_gain'),
  Work: t('label.work'),
  PostWorkMMP: t('label.post_work_mmp'),
  IF: t('label.if'),
  TSS: t('label.tss'),
  CompoundScore: t('label.compound_score'),
  FreshMMP: t('label.fresh_mmp'),
  Time: t('label.time'),
  Climbing: t('label.climbing'),
  Gradient: t('label.gradient'),
  Latitude: t('label.latitude'),
  Longitude: t('label.longitude'),
};

export const ChartTrackingNames = {
  Durability: 'Durability',
  CompoundScore: 'Compound score',
  PowerDurationCurve: 'Power duration curve',
  TorqueDurationCurve: 'Torque duration curve',
  MeanMaxPower: 'Mean max power',
  PerformanceManagement: 'Performance management',
  TssAndIf: 'TSS and IF',
  DurationAndDistance: 'Duration and Distance',
  DurationAndElevation: 'Duration and Elevation',
  WorkAndIF: 'Work and IF',
  TSB: 'TSB',
  AthletePowerProfile: 'Athlete power profile',
  TeamPowerProfile: 'Team power profile',
  CriticalPower: 'Critical power',
  CompletedDuration: 'Completed duration',
  Distance: 'Distance',
  ElevationGain: 'Elevation gain',
  TSS: 'TSS',
  CTL: 'CTL',
  MCP: 'MCP',
  AthleteWellness: 'Athlete wellness',
  AthleteBodyComposition: 'Athlete body composition',
  AthleteRHR: 'Athlete RHR',
  AthleteHRV: 'Athlete HRV',
  HRVvsRHR: 'HRV v RHR',
  TimeInZones: 'Time in zones',
  WorkoutGraph: 'Workout graph',
  Climbing: 'Climbing',
};

export const ChartGroup = {
  HeartMeasurements: 'HeartMeasurements',
};
