import { IConfirmDialogProps } from '@cycling-web/analog-ui';
import { Dispatch, RefObject, SetStateAction } from 'react';
import {
  ICalendarEvent,
  ICalendarRule,
  IWorkoutNote,
  IWorkoutPrescription,
} from '../../../../../types/workouts';

export type ICalendarAddModalProps = {
  defaultDayOff?: ICalendarRule;
  defaultEvent?: ICalendarEvent;
  defaultPrescription?: IWorkoutPrescription;
  defaultNote?: IWorkoutNote;
  defaultDate?: Date;
  onDismiss: () => void;
};

export type ICalendarAddContext = ICalendarAddModalProps & {
  currentPage: CalendarAddModalPage;
  setCurrentPage: Dispatch<SetStateAction<CalendarAddModalPage>>;
  handleDismiss: () => void;
  dismissCallback: RefObject<(() => void) | null>;
  /** Discard dialog when trying to move with unsaved changes */
  discardDialogProps: Partial<IConfirmDialogProps> | undefined;
  openDiscardDialog: (props: Partial<IConfirmDialogProps>) => void;
  dismissDiscardDialog: () => void;
};

export enum CalendarAddModalPage {
  Event = 'event',
  Workout = 'create_workout',
  Availability = 'availability',
  Notes = 'notes',
}
