import './index.css';
import {
  IAthleteProfile,
  TrainingZoneMethod,
} from '../../../../../../types/athlete-profiles';
import { useTranslation } from 'react-i18next';
import {
  EmptyState,
  IEmptyStateProps,
  ISegmentButton,
} from '@cycling-web/analog-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useThreshold } from '../../../../hooks/useThreshold';
import { TrainingZoneKeys } from '../../../../utils/getDefaults';
import { usePageFilters } from '../../../../../../context/PageFilters';
import { IAthleteProfileFilters } from '../../../../types';
import { McpUtils } from '../../../../../../utils/mcp';
import { TrainingZonesHeader } from '../TraininZonesHeader';
import { TrainingZone } from '../TrainingZone';
import { useTrainingZoneText } from '../../../../hooks/useTrainingZoneText';
import { IAthlete } from '../../../../../../types/athletes';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  FallbackValue,
  TrackingEvent,
} from '../../../../../../ms/tracking-entities';

type IProps = {
  profiles: IAthleteProfile[];
  athlete?: IAthlete;
  param?: keyof IAthleteProfileFilters;
  openZonesModal?: (composeKey?: string) => void;
  emptyStateProps?: IEmptyStateProps;
};

export const TrainingZoneProfiles = ({
  profiles,
  param,
  openZonesModal,
  athlete,
  emptyStateProps,
}: IProps) => {
  const { t } = useTranslation();

  const parametersTitle: Record<string, string> = {
    [TrainingZoneMethod.MFTP]: t('label.ftp'),
    [TrainingZoneMethod.MCP]: t('label.cp'),
  };

  const segmentButtons: ISegmentButton[] = profiles.map((p) => {
    const profile = p as IAthleteProfile;
    return {
      id: profile.method,
      text: parametersTitle[profile.method],
    };
  });

  const { filters, handleChangeFilter } =
    usePageFilters<IAthleteProfileFilters>();
  const [activeProfileIndex, setActiveProfileIndex] = useState<number>(0);

  useEffect(() => {
    if (!param) {
      return;
    }

    const composeKey = filters[param];
    if (composeKey) {
      const index = profiles.findIndex((p) => p.composeKey === composeKey);
      if (index >= 0) {
        setActiveProfileIndex(index);
      }
    }
  }, [filters, param, profiles]);

  const onProfileChange = useCallback(
    (index: number) => {
      trackUserInteractionEvent(TrackingEvent.FILTER, {
        param: profiles[index].composeKey || FallbackValue.Unknown,
      });

      if (param) {
        handleChangeFilter(param)(profiles[index].composeKey);
      }
      setActiveProfileIndex(index);
    },
    [handleChangeFilter, param, profiles]
  );

  const profile = profiles[activeProfileIndex];

  const composeKey = profile?.composeKey as string;
  const { threshold } = useThreshold({ composeKey, athlete });

  const thresholdValue = useMemo(() => {
    if (composeKey === TrainingZoneKeys.CyclingPowerMCP) {
      return threshold
        ? Math.round(McpUtils.computeCPFromFTP(Number(threshold))).toString()
        : '';
    }
    return Math.round(Number(threshold ?? 0));
  }, [composeKey, threshold]);

  const texts = useTrainingZoneText();

  if (!profile) {
    return null;
  }

  const zonesJSX = profile.zones.map((zone, i) => {
    return <TrainingZone key={i} zone={zone} units={texts[composeKey].unit} />;
  });

  return (
    <div className="athlete-profiles__training-zones--profile">
      <TrainingZonesHeader
        title={texts[composeKey].title}
        threshold={thresholdValue}
        units={texts[composeKey].unit}
        method={
          profile.zones.length > 0
            ? profile.custom
              ? t('label.custom')
              : t('label.analog')
            : ''
        }
        segmentProps={
          profiles.length > 1
            ? {
                buttons: segmentButtons,
                activeButton: activeProfileIndex,
                onChange: onProfileChange,
              }
            : undefined
        }
      />

      <div className="training-zones--profile__zones">
        {zonesJSX.length === 0 ? (
          <EmptyState
            size="s"
            text={t('banner.empty_zones_text')}
            buttonProps={
              openZonesModal
                ? {
                    content: t('banner.empty_zones_action'),
                    onClick: () => openZonesModal(composeKey),
                  }
                : undefined
            }
            {...emptyStateProps}
          />
        ) : (
          zonesJSX
        )}
      </div>
    </div>
  );
};
