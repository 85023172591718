import { createContext, use, useCallback, useMemo } from 'react';
import {
  IPageFilterKey,
  IPageFilters,
  IPageFiltersContext,
  IPageFiltersProps,
} from './types';
import { useSearchParams } from 'react-router';
import { AnyValue } from '../../types/common';

export const PageFiltersContext = createContext<IPageFiltersContext<any>>(
  {} as IPageFiltersContext<any>
);

export const usePageFilters = <T extends IPageFilters>() => {
  return use(PageFiltersContext) as IPageFiltersContext<T>;
};

const isInvalidValue = (value: any): boolean => {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'number' && isNaN(value))
  );
};

export const PageFiltersContextProvider = (props: IPageFiltersProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilter = useCallback(
    (key: IPageFilterKey) => {
      return searchParams.get(key.toString()) || '';
    },
    [searchParams]
  );

  const setFilter = useCallback(
    (key: string | number | symbol, value: AnyValue) => {
      const newParams = new URLSearchParams(searchParams);

      if (isInvalidValue(value)) {
        newParams.delete(key.toString());
      } else {
        newParams.set(key.toString(), value);
      }
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const handleChangeFilter = useCallback(
    (key: IPageFilterKey) => {
      return (value: string | undefined) => {
        setFilter(key, value);
      };
    },
    [setFilter]
  );

  const removeFilter = useCallback(
    (key: IPageFilterKey) => {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete(key.toString());
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams(new URLSearchParams());
  }, [setSearchParams]);

  const initPageFilters = useCallback(
    <T extends IPageFilters>(defaultFilters: T, update?: boolean): void => {
      const newParams = new URLSearchParams(searchParams);

      let hasChanges = false;

      Object.entries(defaultFilters).forEach(([key, value]) => {
        if ((update || !searchParams.has(key)) && !isInvalidValue(value)) {
          newParams.set(key, value!);
          hasChanges = true;
        }
      });

      if (hasChanges) {
        setSearchParams(newParams);
      }
    },
    [searchParams, setSearchParams]
  );

  const context = useMemo((): IPageFiltersContext<any> => {
    const filters = Object.fromEntries(searchParams.entries());
    const isEmpty = Object.keys(filters).length === 0;

    return {
      getFilter,
      setFilter,
      removeFilter,
      clearFilters,
      handleChangeFilter,
      initPageFilters,
      filters: isEmpty ? props.defaultFilters || {} : filters,
    };
  }, [
    clearFilters,
    getFilter,
    handleChangeFilter,
    initPageFilters,
    props.defaultFilters,
    removeFilter,
    searchParams,
    setFilter,
  ]);

  return (
    <PageFiltersContext value={context}>{props.children}</PageFiltersContext>
  );
};
