import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { useCallback, useMemo } from 'react';
import {
  Chart,
  ChartColor,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import { getGradient } from '../../../../components/Chart/utils/getGradient';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import ReactDOMServer from 'react-dom/server';
import { LabelValue } from '@cycling-web/analog-ui';
import { roundToDecimals } from '../../../../utils/maths';
import throttle from 'lodash/throttle';
import { useEventStore } from '../../store/slice';

type IProps = {
  source: IChartSource;
  loading?: boolean;
};

export const ClimbingChart = ({ source, loading }: IProps) => {
  const { t } = useTranslation();

  const getDummySeries = useCallback((name: string) => {
    return {
      name,
      type: 'line' as const,
      symbolSize: 0,
      lineStyle: {
        width: 0,
        color: 'rgba(0, 0, 0, 0)',
      },
      itemStyle: {
        color: 'rgba(0, 0, 0, 0)',
      },
      showSymbol: false,
      animation: false,
      z: 10,
    };
  }, []);

  const { maxX } = useMemo(() => {
    let maxX = 0;

    for (let i = 1; i < source.length; i++) {
      if (source[i][0]) {
        maxX = Math.max(Number(source[i][0]), maxX);
      }
    }

    return { maxX };
  }, [source]);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: {
        bottom: 24,
        left: 16,
        right: 48,
      },
      xAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        min: 0,
        max: maxX,
        interval: 25,
        axisLabel: {
          formatter: (value) => {
            return roundToDecimals(value, 0);
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          position: 'right',
          axisLine: {
            show: false,
          },
        },
        {
          type: 'value',
          position: 'right',
          axisLine: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: ChartAxisName.Elevation,
          type: 'line',
          yAxisIndex: 0,
          areaStyle: {
            ...getGradient({ from: ChartColor.LightBlue }),
            origin: 'start',
          },
          lineStyle: {
            width: 2,
          },
          symbol: 'none',
        },
        getDummySeries('Longitude'),
        getDummySeries('Latitude'),
      ],
      tooltip: {
        formatter: (params: CallbackDataParams[]) => {
          const values = params?.[0]?.value ?? [];
          const distance =
            values[0] !== null || values[0] !== undefined
              ? roundToDecimals(values[0], 1)
              : null;
          const elevation =
            values[1] !== null || values[1] !== undefined ? values[1] : null;

          const gradient =
            values[2] !== null || values[2] !== undefined
              ? roundToDecimals(values[2], 2)
              : null;

          return ReactDOMServer.renderToString(
            <>
              <LabelValue
                label={t('label.distance')}
                value={distance}
                units={t('units.km')}
              />
              <LabelValue
                label={t('label.elevation')}
                value={elevation}
                units={t('units.m')}
              />
              <LabelValue
                label={t('label.gradient')}
                value={gradient}
                units="%"
              />
            </>
          );
        },
      },
    };
  }, [maxX, source, t, getDummySeries]);

  const throttledGlobalMove = throttle((params) => {
    const series = params?.series || [];
    let longitude = undefined;
    let latitude = undefined;

    for (let i = 0; i < series.length; i++) {
      if (series[i].seriesName === 'Longitude') {
        longitude = series[i]?.value;
      }
      if (series[i].seriesName === 'Latitude') {
        latitude = series[i]?.value;
      }

      if (longitude && latitude) {
        break;
      }
    }

    if (longitude && latitude) {
      useEventStore.getState().setPositionOnMap({
        longitude,
        latitude,
      });
    }
  }, 150);

  const onGlobalOut = useCallback(() => {
    useEventStore.getState().setPositionOnMap(null);
  }, []);

  return (
    <ChartWrapper className="climbing-chart" minHeight="300px">
      <Chart
        headerProps={{
          title: t('label.profile'),
        }}
        legendProps={{
          show: false,
        }}
        hasData={true}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.Climbing,
          }),
          onGlobalMove: throttledGlobalMove,
          onGlobalOut: onGlobalOut,
        }}
      />
    </ChartWrapper>
  );
};
