import './index.css';
import { IconButton, Skeleton, Tooltip } from '@cycling-web/analog-ui';
import { useCallback } from 'react';
import { ChevronDown } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router';
import { useUserRole } from '../../../../hooks/useUserRole';
import { useGroupsStore } from '../../../../store/groups/slice';
import { SingleGroupFilter } from '../../../../components/filters/GroupFilter';
import { useTranslation } from 'react-i18next';

export const GroupDetailsPageTitle = () => {
  const { t } = useTranslation();
  const group = useGroupsStore((s) => s.group);
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);
  const { isStaff } = useUserRole();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onGroupChange = useCallback(
    (athleteId: number) => {
      const tmp = pathname.split('/');
      tmp[4] = athleteId.toString();

      const searchParams = window.location.search;
      const newPath = tmp.join('/') + (searchParams ? searchParams : '');

      navigate(newPath);
    },
    [navigate, pathname]
  );

  const onRenderAnchor = useCallback(() => {
    return (
      <Tooltip
        anchor={
          <IconButton
            size="s"
            variant="quietLayer2"
            content={<ChevronDown />}
          />
        }
        content={t('tooltip.select_group')}
      />
    );
  }, [t]);

  const pageTitleJSX =
    groupsLoaded && group ? (
      group.name
    ) : (
      <Skeleton height="26px" width="180px" />
    );

  return (
    <div className="athlete-details__page-title">
      {pageTitleJSX}
      {isStaff && (
        <SingleGroupFilter
          value={group?.id}
          onChange={onGroupChange}
          selectProps={{ onRenderAnchor }}
          dropdownProps={{
            minWidth: '260px',
          }}
          skeletonProps={{ width: '32px', height: '32px' }}
          style={{ minWidth: '0' }}
        />
      )}
    </div>
  );
};
