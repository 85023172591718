import './index.css';
import { IButtonVariant, IconButton, Tooltip } from '@cycling-web/analog-ui';
import { ConversationState, IConversationInputVariant } from '../../types';
import { XCircle } from 'lucide-react';
import { use } from 'react';
import { VoiceRecordingContext } from '../../context/VoiceRecordingContext';
import { ConversationContext } from '../../context/ConversationContext';
import { useTranslation } from 'react-i18next';

type IProps = {
  variant?: IConversationInputVariant;
};

export const StopRecordingButton = ({ variant = 'layer2' }: IProps) => {
  const { t } = useTranslation();
  const { conversationState } = use(ConversationContext);
  const { cancelRecording } = use(VoiceRecordingContext);

  const buttonVariant: Record<IConversationInputVariant, IButtonVariant> = {
    layer1: 'quietLayer3',
    layer2: 'quietLayer2',
    layer3: 'quietLayer2',
  };

  if (conversationState !== ConversationState.LISTENING) {
    return null;
  }

  return (
    <Tooltip
      anchor={
        <IconButton
          variant={buttonVariant[variant] || 'quietLayer2'}
          content={<XCircle />}
          onClick={cancelRecording}
        />
      }
      content={t('action.cancel')}
      placement="top"
    />
  );
};
