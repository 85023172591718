import { PageFilters } from '@cycling-web/common';
import { useCallback, useEffect, useMemo } from 'react';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { IGroupReportsFilters } from '../../../GroupDetails/types';
import { ISelectOption } from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../types/athletes';
import { useGroupAthletes } from '../../../../hooks/useGroupAthletes';
import { usePageFilters } from '../../../../context/PageFilters';
import { getDefaultPageFilters } from '../../../../constants/page-filters';

export const GroupChartsFilters = () => {
  const { filters, handleChangeFilter, removeFilter, initPageFilters } =
    usePageFilters<IGroupReportsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().group);
  }, [initPageFilters]);

  const { groupAthletes } = useGroupAthletes();

  const athleteOptions: ISelectOption[] = useMemo(() => {
    return groupAthletes.map((a: IAthlete) => {
      return {
        id: a.id.toString(),
        text: a.fullName,
      };
    });
  }, [groupAthletes]);

  const handleClearSearch = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  const handleClearAthletes = useCallback(() => {
    removeFilter('athletes');
  }, [removeFilter]);

  const filtersJSX = (
    <MultipleAthleteFilter
      options={athleteOptions}
      value={filters.athletes?.split(',').map(Number) || []}
      onChange={handleChangeFilter('athletes')}
      onClear={handleClearAthletes}
      style={{ minWidth: '220px' }}
      className="group-charts__athletes-filters"
    />
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
    />
  );
};
