import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { IPinnedTab, SmartChartsAthletes } from '../../../types/smart-charts';
import { SmartChartsRepository } from '../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../api/smart-charts/service';
import { usePinnedTabStore } from '../../../store/pinned-tabs/slice';
import { useParams } from 'react-router';

export const useFetchPinnedTabs = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const fetching = useRef<boolean>(false);
  const setPinnedTabs = usePinnedTabStore((s) => s.setPinnedTabs);
  const setPinnedTabsLoaded = usePinnedTabStore((s) => s.setPinnedTabsLoaded);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  useEffect(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    smartChartsRepository
      .getPinnedTabs({ type: SmartChartsAthletes.Single })
      .then((tabs: IPinnedTab[]) => {
        setPinnedTabs(tabs);
      })
      .catch(() => {
        setPinnedTabsLoaded(true);
      })
      .finally(() => {
        fetching.current = false;
      });
    //   Keep athleteId here
  }, [t, smartChartsRepository, setPinnedTabs, setPinnedTabsLoaded, athleteId]);
};
