import './index.css';
import { GroupOverviewTable } from './components/GroupOverviewTable';
import { GroupOverviewFilters } from './components/Filters';
import { useGroupAthletes } from '../../hooks/useGroupAthletes';
import { useEffect, useMemo } from 'react';
import { filterAthletesDelegate } from '../GroupCharts/utils/filterAthletesDelegate';
import { useFetchWorkoutSeasons } from '../../hooks/useFetchWorkoutSeasons';
import {
  PageFiltersContextProvider,
  usePageFilters,
} from '../../context/PageFilters';
import { IGroupReportsFilters } from '../GroupDetails/types';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { useFetchGroupReports } from './hooks/useFetchGroupReports';

export const GroupOverviewView = () => {
  const { groupAthletes } = useGroupAthletes();
  useFetchGroupReports();

  const { filters, initPageFilters } = usePageFilters<IGroupReportsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().group);
  }, [initPageFilters]);

  const filteredGroupAthletes = useMemo(() => {
    if (groupAthletes.length === 0) {
      return undefined;
    }

    return groupAthletes
      .filter(filterAthletesDelegate(filters))
      .map((a) => a.id)
      .join(',');
  }, [filters, groupAthletes]);

  useFetchWorkoutSeasons({ athleteIds: filteredGroupAthletes });

  return (
    <div className="overview-page">
      <GroupOverviewFilters />
      <GroupOverviewTable />
    </div>
  );
};

export const GroupOverview = () => {
  return (
    <PageFiltersContextProvider defaultFilters={getDefaultPageFilters().group}>
      <GroupOverviewView />
    </PageFiltersContextProvider>
  );
};
