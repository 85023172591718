import './index.css';
import { useAthleteProfileStore } from '../../store/slice';
import { TrainingLoadCard } from '../TrainingLoadCard';
import { useTranslation } from 'react-i18next';
import { useAthleteStore } from '../../../../store/athlete/slice';
import { Typography } from '@cycling-web/analog-ui';

export const TrainingLoadFeedback = () => {
  const { t } = useTranslation();
  const athlete = useAthleteStore((s) => s.athlete);
  const trainingLoadFeedback = useAthleteProfileStore(
    (s) => s.trainingLoadFeedback
  );
  const trainingLoadFeedbackLoaded = useAthleteProfileStore(
    (s) => s.trainingLoadFeedbackLoaded
  );

  return (
    <div className="athlete-profile__training-load-feedback-container">
      <TrainingLoadCard
        name={t('label.ctl')}
        text={trainingLoadFeedback?.ctlChangeFeedback}
        value={athlete?.ctl}
        delta={trainingLoadFeedback?.ctlChange}
        loading={!trainingLoadFeedbackLoaded}
      />
      <TrainingLoadCard
        name={t('label.atl')}
        text={trainingLoadFeedback?.atlChangeFeedback}
        value={athlete?.atl}
        delta={trainingLoadFeedback?.atlChange}
        loading={!trainingLoadFeedbackLoaded}
      />
      <TrainingLoadCard
        name={t('label.tsb')}
        text={trainingLoadFeedback?.tsbChangeFeedback}
        value={athlete?.tsb}
        delta={trainingLoadFeedback?.tsbChange}
        loading={!trainingLoadFeedbackLoaded}
      />

      <div className="athlete-profile__feedback-container-footer">
        <Typography
          variant="subtitle"
          text={t('label.based_on', {
            what: t('label.last_n_days', { d: 7 }).toLowerCase(),
          })}
        />
      </div>
    </div>
  );
};
