import { IWizardStep } from '../../types';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useTrainingPlanContext } from '../../context';
import { clsx } from 'clsx';
import { useCallback } from 'react';

type IProps = {
  step: IWizardStep;
};

export const Step = ({ step }: IProps) => {
  const { t } = useTranslation();
  const { wizardStep, setWizardStep } = useTrainingPlanContext();

  const rootClass = clsx(
    'training-plan__nav-step',
    wizardStep === step.id && 'training-plan__nav-step--active'
  );

  const handleClick = useCallback(() => {
    setWizardStep(step.id);
  }, [setWizardStep, step]);

  return (
    <div className={rootClass} onClick={handleClick}>
      <div className="training-plan__nav-step-number analog-typography--subtitle bold">
        {step.id}
      </div>
      <div className="training-plan__nav-step-details">
        <Typography
          variant="caption"
          className="training-plan__nav-order"
          text={`${t('label.step')} ${step.id}`}
        />
        <Typography
          variant="subtitle"
          className="training-plan__nav-step-label"
          text={step.text}
        />
      </div>
    </div>
  );
};
