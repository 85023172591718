import './index.css';
import { TeamPowerProfileChart } from './components/TeamPowerProfileChart';
import { TssAndIfChart } from '../../components/charts/TssAndIfChart';
import { DurationAndDistanceChart } from '../../components/charts/DurationAndDistanceChart';
import { DurationAndElevationChart } from '../../components/charts/DurationAndElevationChart';
import { WorkAndIfChart } from '../../components/charts/WorkAndIfChart';
import { CriticalPowerChart } from './components/CriticalPowerChart';
import { GroupChartsFilters } from './components/Filters';
import { useViewModel } from './useViewModel';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const GroupChartsView = () => {
  const {
    workoutDetailsSources,
    workoutDetailsLoading,
    criticalPowerSource,
    criticalPowerLoading,
    peaksSource,
    peaksLoading,
    filters,
    handleChangeFilter,
  } = useViewModel();

  return (
    <div className="charts-page">
      <GroupChartsFilters />
      <div className="charts-page__charts">
        <TeamPowerProfileChart
          source={peaksSource}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={peaksLoading}
        />
        <TssAndIfChart
          source={workoutDetailsSources.tssAndIf}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={workoutDetailsLoading}
        />
        <DurationAndDistanceChart
          source={workoutDetailsSources.durationAndDistance}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={workoutDetailsLoading}
        />
        <DurationAndElevationChart
          source={workoutDetailsSources.durationAndElevation}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={workoutDetailsLoading}
        />
        <WorkAndIfChart
          source={workoutDetailsSources.workAndIf}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
          loading={workoutDetailsLoading}
        />
        <CriticalPowerChart
          source={criticalPowerSource}
          loading={criticalPowerLoading}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
        />
      </div>
    </div>
  );
};

export const GroupCharts = () => {
  return (
    <PageFiltersContextProvider defaultFilters={getDefaultPageFilters().group}>
      <GroupChartsView />
    </PageFiltersContextProvider>
  );
};
