import './index.css';
import { IAthlete } from '../../../types/athletes';
import { EMPTY } from '../../../constants';
import { Avatar, IAvatarProps } from '@cycling-web/analog-ui';
import { Link } from 'react-router';
import { useBaseUrl } from '../../../hooks/useBaseUrl';
import { ROUTES } from '../../../router/routes';
import { MouseEvent, useCallback, useMemo } from 'react';
import { Url } from '../../../utils/url';
import { useUserRole } from '../../../hooks/useUserRole';
import { trackUserInteractionEvent } from '../../../ms/log-insights';
import {
  TrackingEvent,
  TrackingNavigationSource,
} from '../../../ms/tracking-entities';

type IProps = {
  athlete: IAthlete | undefined;
  url?: string;
  avatarProps?: IAvatarProps;
};

export const Athlete = ({ athlete, avatarProps, url }: IProps) => {
  const baseUrl = useBaseUrl();
  const { isAthlete } = useUserRole();

  const path = useMemo((): string => {
    if (!athlete) {
      return '';
    }

    let path = '';

    if (url) {
      path = url;
    } else {
      path = isAthlete
        ? `${baseUrl}/${ROUTES.ATHLETE}/${athlete.id}/${ROUTES.PROFILE}/${ROUTES.PROFILE}`
        : `${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}/${athlete.id}/${ROUTES.PROFILE}`;
    }

    return path;
  }, [athlete, baseUrl, isAthlete, url]);

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      Url.saveUrlToLS();
      trackUserInteractionEvent(TrackingEvent.NAVIGATE, {
        destination: path,
        source: TrackingNavigationSource.AVATAR,
      });
    },
    [path]
  );

  if (!athlete) {
    return EMPTY;
  }

  return (
    <Link to={path} className="analog__athlete-avatar" onClick={onClick}>
      <Avatar
        {...avatarProps}
        src={avatarProps?.src || athlete.picture}
        text={avatarProps?.text || athlete.fullName}
        alt={avatarProps?.alt || athlete.fullName}
        direction={avatarProps?.direction || 'default'}
        size={avatarProps?.size || 's'}
      />
    </Link>
  );
};
