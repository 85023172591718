import {
  ICreateCustomPeriodFilterRequest,
  ICreateCustomPeriodFilterResponse,
  IDeleteCustomPeriodFilterRequest,
  IDeleteCustomPeriodFilterResponse,
  IGetCustomPeriodFilterResponse,
  IGetMetricSeasonsFilterRequest,
  IGetMetricSeasonsFilterResponse,
  IGetWorkoutSeasonsFilterRequest,
  IGetWorkoutSeasonsFilterResponse,
  IUpdateCustomPeriodFilterRequest,
  IUpdateCustomPeriodFilterResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';
import { qsOptions } from '../utils/qsOptions';
import { AxiosRequestConfig } from 'axios';

interface IFiltersService {
  getWorkoutSeasons: (
    p: IGetWorkoutSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ) => R<IGetWorkoutSeasonsFilterResponse>;
  getMetricSeasons: (
    p: IGetMetricSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ) => R<IGetMetricSeasonsFilterResponse>;
  getCustomPeriodFilters: (
    c?: AxiosRequestConfig
  ) => R<IGetCustomPeriodFilterResponse>;
  createCustomPeriodFilter: (
    p: ICreateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ) => R<ICreateCustomPeriodFilterResponse>;
  updateCustomPeriodFilter: (
    p: IUpdateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ) => R<IUpdateCustomPeriodFilterResponse>;
  deleteCustomPeriodFilter: (
    p: IDeleteCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ) => R<IDeleteCustomPeriodFilterResponse>;
}

export class FiltersService implements IFiltersService {
  async getWorkoutSeasons(
    p: IGetWorkoutSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ): R<IGetWorkoutSeasonsFilterResponse> {
    const params = qs.stringify(p, qsOptions);
    try {
      return axiosInstance.get(`/v1/calendar/workoutSeasons?${params}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getMetricSeasons(
    p: IGetMetricSeasonsFilterRequest,
    c?: AxiosRequestConfig
  ): R<IGetMetricSeasonsFilterResponse> {
    const params = qs.stringify(p, qsOptions);
    try {
      return axiosInstance.get(`/v1/calendar/metricSeasons?${params}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCustomPeriodFilters(
    c?: AxiosRequestConfig
  ): R<IGetCustomPeriodFilterResponse> {
    try {
      return axiosInstance.get('/v1/calendar/filter', c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createCustomPeriodFilter(
    p: ICreateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ): R<ICreateCustomPeriodFilterResponse> {
    try {
      return axiosInstance.post('/v1/calendar/filter', p, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCustomPeriodFilter(
    p: IUpdateCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ): R<IUpdateCustomPeriodFilterResponse> {
    try {
      return axiosInstance.put(`/v1/calendar/filter`, p, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteCustomPeriodFilter(
    p: IDeleteCustomPeriodFilterRequest,
    c?: AxiosRequestConfig
  ): R<IDeleteCustomPeriodFilterResponse> {
    try {
      return axiosInstance.delete(`/v1/calendar/filter/${p.id}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
