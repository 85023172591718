import './index.css';
import { useSmartChartsContext } from '../../context';
import {
  ConversationContext,
  ConversationInput,
  ConversationState,
} from '../../../../components/Conversation';
import { Typography } from '@cycling-web/analog-ui';
import { useFetchHistory } from '../../hooks/useFetchIntro';
import { useSmartChartsStore } from '../../store/slice';
import { use, useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  $createTextNode,
  $getSelection,
  $insertNodes,
  LexicalEditor,
} from 'lexical';
import AnalyseImage from '../../../../assets/illustrations/analyse_1.png';
import Athlete1Image from '../../../../assets/illustrations/athlete_1.png';
import Athlete2Image from '../../../../assets/illustrations/athlete_2.png';
import GraphCurve1Image from '../../../../assets/illustrations/graph_curve_1.png';
import GraphCurve2Image from '../../../../assets/illustrations/graph_curve_2.png';
import { INSERT_HINT_COMMAND } from '../../../../components/Conversation/components/ConversationTextEditor/hint/plugin/HintPlugin';
import { useTranslation } from 'react-i18next';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
} from '../../../../ms/tracking-entities';

export const WelcomeBanner = () => {
  const { t } = useTranslation();
  useFetchHistory();
  const intro = useSmartChartsStore((s) => s.intro);
  const introLoaded = useSmartChartsStore((s) => s.introLoaded);
  const { sendMessage, sendAudioMessage } = useSmartChartsContext();
  const { conversationState } = use(ConversationContext);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);
  const editorRef = useRef<LexicalEditor | null>(null);

  const images = {
    'analyse_1.png': AnalyseImage,
    'athlete_1.png': Athlete1Image,
    'athlete_2.png': Athlete2Image,
    'graph_curve_1.png': GraphCurve1Image,
    'graph_curve_2.png': GraphCurve2Image,
  };

  const handleSendAudioMessage = useCallback(
    (base64: string) => {
      setShowSuggestions(false);
      return sendAudioMessage(base64);
    },
    [sendAudioMessage]
  );

  const handleChange = useCallback((text: string) => {
    setShowSuggestions(text.length === 0);
  }, []);

  const handleClick = useCallback(
    (text: string) => {
      return () => {
        trackUserInteractionEvent(TrackingEvent.CLICK, {
          action: TrackingAction.APPLY_SUGGESTION,
        });

        if (editorRef.current) {
          editorRef.current.update(() => {
            const selection = $getSelection();
            if (selection) {
              $insertNodes([$createTextNode(text + ' ')]);
              editorRef.current?.dispatchCommand(
                INSERT_HINT_COMMAND,
                t('placeholder.mention_hint')
              );
            }
          });
        }

        setTimeout(() => {
          const textarea = document.querySelector<HTMLTextAreaElement>(
            '.conversation__footer-textarea'
          );
          if (textarea) {
            textarea.focus();
          }
        });
      };
    },
    [t]
  );

  const suggestionsClass = clsx(
    'sc2__welcome-banner__suggestions',
    (!showSuggestions || conversationState !== ConversationState.IDLE) &&
      'hidden'
  );

  const suggestionsJSX = (intro?.next_suggestions || []).map(
    (suggestion, i: number) => {
      return (
        <button
          key={i}
          className="sc2__welcome-banner__suggestion analog-typography--subtitle"
          onClick={handleClick(suggestion.suggestion_metadata.chat_input_text)}
        >
          {suggestion.suggestion_icon && (
            <img
              className="sc2__welcome-banner__suggestion-img"
              src={images[suggestion.suggestion_icon]}
              alt={suggestion.suggestion_text}
            />
          )}
          {suggestion.suggestion_text}
        </button>
      );
    }
  );

  return (
    <div className="sc2__welcome-banner">
      <div className="sc2__welcome-banner__title">
        <Typography
          variant="h4"
          weight="bold"
          loading={!introLoaded}
          text={intro?.welcome_user_line}
        />
        <Typography
          className="sc2__welcome-banner__title-message"
          variant="h4"
          weight="regular"
          loading={!introLoaded}
          text={intro?.welcome_user_activity_line}
        />
      </div>

      <ConversationInput
        onSendText={sendMessage}
        onSendAudio={handleSendAudioMessage}
        placeholder={intro?.welcome_user_ask_hint_line}
        editorRef={editorRef}
        onChange={handleChange}
        autofocus
      />

      <div className={suggestionsClass}>{suggestionsJSX}</div>
    </div>
  );
};
