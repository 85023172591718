import { Discipline } from './enums';

export type IAthleteProfile = {
  id?: number;
  athleteId?: number;
  type: Discipline;
  method: AthleteProfileMethod;
  custom: boolean;
  zones: IAthleteTrainingZone[];

  // Below are frontend props for better composition: disciple_parameter_method
  composeKey?: string;
};

export type IAthleteTrainingZone = {
  name: string;
  from: number;
  to: number;
  color: string;
};

export type IAthleteTrainingZoneForm = {
  name: string;
  from: string;
  to: string;
  color: string;
};

export enum AthleteProfileMethod {
  HeartRate = 'heartRate',
  Pace = 'pace',
  CSS = 'css',
  MFTP = 'mftp',
  MCP = 'mcp',
}

/** Below are the types that needed for better Frontend composition*/

export enum TrainingZoneParameter {
  Power = 'power',
  Pace = 'pace',
  CSS = 'css',
  HeartRate = 'heartRate',
}

export enum TrainingZoneMethod {
  MFTP = 'mftp',
  MCP = 'mcp',
  Default = 'default',
}

export type IAthleteTrainingZoneColor =
  | 'Grey'
  | 'LightBlue'
  | 'Blue'
  | 'LightPurple'
  | 'Purple'
  | 'Orange'
  | 'Red'
  | 'LightRed'
  | 'Rose'
  | 'LightRose'
  | 'Magenta'
  | 'LightMagenta'
  | 'LightCyan'
  | 'Cyan'
  | 'LightGreen'
  | 'Green'
  | 'LightYellow'
  | 'Yellow';
