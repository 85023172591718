import { DictionariesService } from './service';
import { IEnums } from '../../types/dictionaries';
import {
  BaseRepository,
  IRepositoryOptions,
  UserRole,
} from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';

export interface IDictionariesRepository {
  getUserRoles: (c?: AxiosRequestConfig) => Promise<UserRole[]>;

  getEnums: (c?: AxiosRequestConfig) => Promise<IEnums>;
}

export class DictionariesRepository
  extends BaseRepository
  implements IDictionariesRepository
{
  constructor(
    private readonly service: DictionariesService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getUserRoles(c?: AxiosRequestConfig): Promise<UserRole[]> {
    const response = await this.handleRequest(this.service.getUserRoles, c);
    return response.data;
  }

  async getEnums(c?: AxiosRequestConfig): Promise<IEnums> {
    const response = await this.handleRequest(this.service.getEnums, c);
    return response.data;
  }
}
