import { IRepositoryOptions } from '../types/common';
import { AxiosRequestConfig } from 'axios';

type IMethodWithParams<TResponse, TParams> = (
  params: TParams,
  config?: AxiosRequestConfig
) => Promise<TResponse>;

type IMethodWithoutParams<TResponse> = (
  config?: AxiosRequestConfig
) => Promise<TResponse>;

export class BaseRepository {
  private abortControllers: Map<string, AbortController> = new Map();
  protected readonly options?: IRepositoryOptions;

  constructor(options?: IRepositoryOptions) {
    this.options = options;
  }

  /** Method that handles request with abort controller */
  protected async handleRequest<TResponse>(
    method: IMethodWithoutParams<TResponse>,
    config?: AxiosRequestConfig
  ): Promise<TResponse>;

  protected async handleRequest<TResponse, TParams>(
    method: IMethodWithParams<TResponse, TParams>,
    params: TParams,
    config?: AxiosRequestConfig
  ): Promise<TResponse>;

  protected async handleRequest<TResponse, TParams = void>(
    method:
      | IMethodWithoutParams<TResponse>
      | IMethodWithParams<TResponse, TParams>,
    paramsOrConfig?: TParams | AxiosRequestConfig,
    config?: AxiosRequestConfig
  ): Promise<TResponse> {
    const hasParams =
      paramsOrConfig !== undefined &&
      !(paramsOrConfig as AxiosRequestConfig)?.signal;
    const params = hasParams ? (paramsOrConfig as TParams) : undefined;
    const finalConfig = hasParams
      ? config
      : (paramsOrConfig as AxiosRequestConfig | undefined);

    const key = `${method.name}_${
      params ? JSON.stringify(params) : 'no_params'
    }`;

    const controller = this.createAbortController(key);

    try {
      const c = { signal: controller?.signal, ...finalConfig };

      return hasParams
        ? await (method as IMethodWithParams<TResponse, TParams>)(params!, c)
        : await (method as IMethodWithoutParams<TResponse>)(c);
    } finally {
      this.cleanupAbortController(key);
    }
  }

  protected createAbortController(key: string): AbortController | undefined {
    if (!this.options?.abort) {
      return undefined;
    }

    if (this.abortControllers.get(key)) {
      this.abortControllers.get(key)?.abort();
    }

    const controller = new AbortController();
    this.abortControllers.set(key, controller);
    return controller;
  }

  protected cleanupAbortController(key: string) {
    this.abortControllers.delete(key);
  }

  abortRequest(key: string): void {
    this.abortControllers.get(key)?.abort();
    this.abortControllers.delete(key);
  }

  abortAllRequests(): void {
    this.abortControllers.forEach((controller) => controller.abort());
    this.abortControllers.clear();
  }
}
