import './index.css';
import {
  Button,
  ConfirmDialog,
  IconButton,
  Skeleton,
} from '@cycling-web/analog-ui';
import { ChevronLeft, Pencil, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { UserFormModal } from '../UserFormModal';
import { FileUploadButton, ProfilePictureDialog } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { RoleTag } from '../../../../components/columns/RoleTag';
import { useMediaMatch } from 'rooks';

export const Header = () => {
  const { t } = useTranslation();
  const {
    backUrl,
    currentUser,
    loading,
    onImageChange,
    openUserFormModal,
    showConfirmDelete,
    dismissConfirmDelete,
    handleConfirmDelete,
    showUserFormModal,
    dismissUserFormModal,
    showUploadPictureModal,
    file,
    base64,
    dismissUploadPictureModal,
    onImageUpload,
  } = useViewModel();
  const isMobile = useMediaMatch('(max-width: 480px)');

  return (
    <header className="team-member__header">
      <Link to={backUrl}>
        <IconButton variant="quietLayer2" content={<ChevronLeft />} />
      </Link>
      <div className="team-member__header-image analog-typography--headline">
        {currentUser?.picture ? (
          <img
            className="team-member__header-image-img"
            src={currentUser.picture}
            alt={currentUser?.name}
          />
        ) : (
          <User size={42} />
        )}
        <FileUploadButton
          inputProps={{ accept: 'image/jpeg image/png' }}
          onChange={onImageChange}
        >
          <button className="team-member__header-image-button analog-typography--button-s">
            {t('action.change')}
          </button>
        </FileUploadButton>
      </div>

      <div className="team-member__header-details">
        <div className="team-member__header-details-name analog-typography--headline bold">
          {currentUser?.name}
        </div>
        {currentUser?.roles && (
          <div className="team-member__header-details-role analog-typography--body">
            <RoleTag
              role={currentUser.roles}
              tagProps={{ variant: 'layer4' }}
            />
          </div>
        )}
      </div>

      {currentUser?.athleteId !== undefined && (
        <div className="team-member__header-actions">
          {loading ? (
            <Skeleton width="124px" height="40px" />
          ) : isMobile ? (
            <IconButton
              content={<Pencil />}
              variant="secondary"
              onClick={openUserFormModal}
            />
          ) : (
            <Button
              content={t('action.edit')}
              variant="secondary"
              startIcon={<Pencil />}
              onClick={openUserFormModal}
            />
          )}
        </div>
      )}

      {showConfirmDelete && (
        <ConfirmDialog
          onDismiss={dismissConfirmDelete}
          title={t('confirm.delete_team_user_title')}
          subtitle={t('confirm.delete_team_user_message', {
            name: 'Mikhail Kutateladze',
          })}
          submitText={t('action.delete')}
          submitAction={handleConfirmDelete}
        />
      )}
      {showUserFormModal && currentUser && (
        <UserFormModal user={currentUser} onDismiss={dismissUserFormModal} />
      )}

      {showUploadPictureModal && (
        <ProfilePictureDialog
          file={file}
          src={base64}
          onDismiss={dismissUploadPictureModal}
          onSubmit={onImageUpload}
        />
      )}
    </header>
  );
};
