import './index.css';
import { NavLink } from 'react-router';
import { clsx } from 'clsx';
import { INavigationLink } from '@cycling-web/common';

type IProps = {
  item: INavigationLink;
};

export const OptionLink = ({ item }: IProps) => {
  const optionLinkClass = ({ isActive }: { isActive: boolean }) => {
    return clsx(
      'side-navigation__option-link',
      'analog-typography--body',
      'bold',
      (isActive || item.active) && 'side-navigation__option-link--active'
    );
  };

  return (
    <NavLink className={optionLinkClass} to={item.url}>
      {item.icon && item.icon}
      {item.text}
    </NavLink>
  );
};
