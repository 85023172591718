import { IPerformanceReportSlice, IPerformanceReportState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IPerformanceReport } from '../../../types/performance';

export const initialState: IPerformanceReportState = {
  reports: [],
  reportsLoaded: false,
};

export const usePerformanceReportStore = create<IPerformanceReportSlice>()(
  immer((set) => ({
    ...initialState,
    setReports: (reports: IPerformanceReport[]) => {
      set((state) => {
        state.reports = reports;
        state.reportsLoaded = true;
      });
    },
    setReportsLoaded: (weeklyReportsLoaded: boolean) => {
      set((state) => {
        state.reportsLoaded = weeklyReportsLoaded;
      });
    },
    updateReport: (report: Partial<IPerformanceReport>) => {
      set((state) => {
        const index = state.reports.findIndex(
          (r) => report.athleteId === r.athleteId
        );
        if (index >= 0) {
          state.reports[index] = {
            ...state.reports[index],
            ...report,
          };
        }
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
