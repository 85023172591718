// Calendar

export type IWorkoutCalendar = Record<string, IWorkoutCalendarRecord>;

export type IWorkoutCalendarRecord = {
  workouts: IWorkoutCalendarWorkout[];
  prescription: IWorkoutPrescription[];
};

export type IWorkoutCalendarWorkout = {
  workoutId: string;
  title: string;
  distance: number;
  totalTime: number;
  tssActual: number;
  energy: number;
  elevationMaximum: number;
  elevationMinimum: number;
  intensityFactor: number;
  powerAverage: number;
  date: string;
  // Frontend field
  prescription?: IWorkoutPrescription;
};

// Workout

export type IWorkout = {
  Hrv: number;
  Rhr: number;
  Atl: number;
  Ctl: number;
  Tsb: number;
  Mcp: number;
  McpRelative: number;
  Source: string;
  HrvTrend: number[];
  RhrTrend: number[];
  AtlTrend: number[];
  CtlTrend: number[];
  TsbTrend: number[];
  McpTrend: number[];
  McpRelativeTrend: number[];
  Athlete: IWorkoutAthlete;
  AthleteZoneTime: IWorkoutAthleteAthleteZoneTime[];
  Workout: IWorkoutDetails;
  WorkoutMmp: IWorkoutMmp;
  WorkoutMmt: IWorkoutMmt;
  WorkoutPerSecondMmp: number[][];
  Vo2Max: number;
  Vo2Trend: number[][];
  WprimeTrend: number[][];
  Wprime: number;
  WorkoutDetail: IWorkoutDetail;
};

export type IWorkoutAthlete = {
  AthleteId: number;
  FirstName: string;
  LastName: string;
  FullName: string;
  Gender: string;
  Ftp: number;
  SuggestedFtp: number;
  SwimSpeedHundredMts: number;
  TpInSeconds: number;
  LthrCycling: number;
  LthrRunning: number;
  LthrSwimming: number;
};

export type IWorkoutAthleteAthleteZoneTime = {
  AthleteId: number;
  WorkoutId: string;
  Type: string;
  Method: string;
  WorkoutDay: string;
  Z1: number;
  Z2: number;
  Z3: number;
  Z4: number;
  Z5: number;
  Z6: number;
  Z7: number;
  Z8: number;
  Z9: number;
};

export type IWorkoutDetails = {
  Id: string;
  AthleteId: number;
  WorkoutDay: string;
  Completed: boolean;
  Distance: number;
  DistancePlanned: number;
  LastModifiedDate: number;
  StartTime: number;
  StartTimePlanned: number;
  Structure: string;
  Title: string;
  TotalTime: number;
  TotalTimePlanned: number;
  WorkoutType: string;
  Url: string;
  CadenceAverage: number;
  CadenceMaximum: number;
  Calories: number;
  CaloriesPlanned: number;
  ElevationAverage: number;
  ElevationGain: number;
  ElevationGainPlanned: number;
  ElevationLoss: number;
  ElevationMaximum: number;
  ElevationMinimum: number;
  Energy: number;
  EnergyPlanned: number;
  Feeling: number;
  HeartRateAverage: number;
  HeartRateMaximum: number;
  HeartRateMinimum: number;
  NormalizedPower: number;
  NormalizedSpeed: number;
  PowerAverage: number;
  PowerMaximum: number;
  TempAvg: number;
  TempMax: number;
  TempMin: number;
  TorqueAverage: number;
  TssCalculationMethod: string;
  TssActual: number;
  TssPlanned: number;
  Itrimp: number;
  TssiTrimpRatio: number;
  VelocityAverage: number;
  VelocityMaximum: number;
  VelocityPlanned: number;
  IF: number;
  Rpe: number;
  TorqueMaximum: number;
};

export type IWorkoutMmp = {
  WorkoutId: string;
  AthleteId: number;
  WorkoutDay: number;
  FiveSeconds: number;
  TenSeconds: number;
  TwelveSeconds: number;
  TwentySeconds: number;
  ThirtySeconds: number;
  OneMinute: number;
  TwoMinutes: number;
  FiveMinutes: number;
  SixMinutes: number;
  TenMinutes: number;
  TwelveMinutes: number;
  TwentyMinutes: number;
  ThirtyMinutes: number;
  OneHour: number;
  NinetyMinutes: number;
  ThreeHours: number;
  WeightInKilograms: number;
};

export type IWorkoutMmt = {
  WorkoutId: string;
  AthleteId: number;
  MmpId: number;
  WorkoutDay: number;
  WeightInKilograms: number;
  FiveSeconds: number;
  TenSeconds: number;
  TwelveSeconds: number;
  TwentySeconds: number;
  ThirtySeconds: number;
  FortySeconds: number;
  OneMinute: number;
  TwoMinutes: number;
  FourMinutes: number;
  FiveMinutes: number;
  SixMinutes: number;
  TenMinutes: number;
  TwelveMinutes: number;
  TwentyMinutes: number;
  ThirtyMinutes: number;
  OneHour: number;
  NinetyMinutes: number;
  ThreeHours: number;
};

export type IWorkoutDetail = {
  WorkoutId: string;
  WorkoutChannels: IWorkoutChannels;
  LapStats: IWorkoutLapStat[];
};

export type IWorkoutChannels = {
  Channels: string[];
  Data: IWorkoutChannelData[];
};

export type IWorkoutChannelData = {
  Event: string;
  MillisecondOffset: number;
  Values: (number | null)[];
};

export type IWorkoutLapStat = {
  Name: string;
  StartTimeMs: number;
  EndTimeMs: number;
  ElapsedTimeMs: number;
  StoppedTimeMs: number;
  Tss: number;
  IF: number;
  HeartRateMinimum: number;
  HeartRateMaximum: number;
  HeartRateAverage: number;
  SpeedAverage: number;
  SpeedMaximum: number;
  SpeedMinimum: number;
  NormalizedPower: number;
  PowerAverage: number;
  PowerMaximum: number;
  PowerMinimum: number;
  TempMin: number;
  TempAvg: number;
  TempMax: number;
  CadenceAverage: number;
  CadenceMaximum: number;
  CadenceMinimum: number;
  Energy: number;
  ElevationGain: number;
  ElevationLoss: number;
  ElevationMinimum: number;
  ElevationAverage: number;
  ElevationMaximum: number;
  Grade: number;
  EfficiencyFactor: number;
  EnergyRight: number;
  PowerPulseDecoupling: number;
  SpeedPulseDecoupling: number;
  PowerBalanceRight: number;
  PowerBalanceLeft: number;
  VAM: number;
  VAMWattsPerKg: number;
  WattsPerKg: number;
  VI: number;
};

export type IWorkoutAnalysis = {
  id: string;
  athlete_id: number;
  linked_data_engine_workout_id: number;
  linked_data_engine_workout_created_at: string;
  linked_data_engine_workout_updated_at: string;
  is_marked_for_update: false;
  analysis_result: string;
  analysis_request_json: string;
  created_at: string;
  updated_at: string;
};

/** Day Offs  */

export type ICalendarRule = {
  id: number;
  startDate: string;
  endDate: string;
  athleteId: number;
  description: string;
  recurrenceType?: ICalendarRuleRecurringType;
  dayOfWeek?: ICalendarRuleWeek;
  dayOfMonth?: number;
  createdOn?: string;
  updatedOn?: string;
  createdBy?: number;
  updatedBy?: number;
};

export enum ICalendarRuleWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum ICalendarRuleRecurringType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export type ICalendarDayOffs = Record<string, ICalendarDayOff[]>;

export type ICalendarDayOff = {
  dayOffId: number;
  dayOffDate: string;
  ruleId: number;
  description: string;
};

/** Events */

export type ICalendarEvents = Record<string, ICalendarEvent[]>;

export type ICalendarEvent = {
  id: number;
  athleteId: number;
  title: string;
  description: string;
  priority: string;
  eventDateTimeUtc: number;
  eventType: ICalendarEventType;
  durationInMinutes: number;
  gpxFileUrl?: string;
  gpxJsonUrl?: string;
  distanceKms?: number;
  elevationGainMts?: number;
  elevationLossMts?: number;
  createdBy?: number;
  updatedBy?: number;
  createdOn?: string;
  updatedOn?: string;
};

export enum ICalendarEventType {
  RACE = 'RACE',
}

/** GPX */

export type IGpx = {
  parsedWaypoints: IGpxRecord[];
};

export type IGpxRecord = {
  latitude: number;
  longitude: number;
  elevation: number;
  cumulative_distance_km: number;
  cumulative_elevation_gain_mts: number;
  cumulative_elevation_loss_mts: number;
  final_totals: IGpxRecordTotals;
};

export type IGpxRecordTotals = {
  totalDistanceKm: number;
  elevationLossMts: number;
  elevationGainMts: number;
};

/** Workout Prescription */

export type IWorkoutPrescription = {
  id: number;
  athleteId: number;
  workoutDay: string;
  title: string;
  description: string;
  tss: number;
  distanceInKms: number;
  durationInHrs: number;
  elevationInMtrs: number;
  energyInKJs: number;
  aiSummary: string;
  aiGenerated: boolean;
  accepted: boolean;
  updatedAfterAccepted: boolean;
  acceptedBy?: number;
  acceptedOn?: string;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  // Frontend field
  skipped?: boolean;
};

export type IWorkoutPrescriptionBase = {
  id?: number;
  athleteId: number;
  workoutDay: string;
  title: string;
  description: string;
};

/** Note */
export type IWorkoutNote = {
  id: number;
  athleteId: number;
  title: string;
  description: string;
  date: string;
};
