import { IEventSlice, IEventState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ICalendarEvent, IGpxRecord } from '../../../types/workouts';
import { ICoordinates } from '@cycling-web/common';

export const initialState: IEventState = {
  event: null,
  eventLoaded: false,
  gpx: [],
  gpxLoaded: false,
  positionOnMap: null,
};

export const useEventStore = create<IEventSlice>()(
  immer((set) => ({
    ...initialState,
    setEvent: (event: ICalendarEvent | null) => {
      set((state) => {
        state.event = event;
        state.eventLoaded = true;
      });
    },
    setEventLoaded: (loaded: boolean) => {
      set((state) => {
        state.eventLoaded = loaded;
      });
    },
    setGpx: (gpx: IGpxRecord[]) => {
      set((state) => {
        state.gpx = gpx;
        state.gpxLoaded = true;
      });
    },
    setGpxLoaded: (loaded: boolean) => {
      set((state) => {
        state.gpxLoaded = loaded;
      });
    },
    setPositionOnMap: (p: ICoordinates | null) => {
      set((state) => {
        state.positionOnMap = p;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
