import { ITableItem, TruncatedText } from '@cycling-web/analog-ui';
import { IPerformanceReport } from '../../../types/performance';
import { ReactNode, useCallback } from 'react';
import { Athlete } from '../../../components/columns/Athlete';
import { EMPTY } from '../../../constants';
import { TSBToken } from '../../../components/TSBToken';
import { Comment } from '../components/Comment';
import { useAthletesStore } from '../../../store/athletes/slice';
import { ROUTES } from '../../../router/routes';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

type ILocalColumn = {
  key: string;
};

export const useTableRenderMap = () => {
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const baseUrl = useBaseUrl();

  return useCallback(
    (column: ILocalColumn, item: ITableItem) => {
      const castedItem = item as IPerformanceReport;
      const url = `${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}/${castedItem.athleteId}/${ROUTES.PERFORMANCE}`;

      const renderMap: Record<string, ReactNode> = {
        athlete: (
          <Athlete athlete={athletesMap[castedItem.athleteId]} url={url} />
        ),
        total_duration: castedItem.durationStr || EMPTY,
        elevation_gain: castedItem.climbInMeter
          ? Math.round(castedItem.climbInMeter)
          : EMPTY,
        work: castedItem.workInKj ? Math.round(castedItem.workInKj) : EMPTY,
        distance: castedItem.distanceInKm
          ? Math.round(castedItem.distanceInKm)
          : EMPTY,
        tss: castedItem.tss ? Math.round(castedItem.tss) : EMPTY,
        ctl: castedItem.ctl ? Math.round(castedItem.ctl) : EMPTY,
        tsb: <TSBToken value={castedItem.tsb} />,
        coach_rating: castedItem.coachRating || EMPTY,
        coach_comment: <Comment report={castedItem} />,
        next_race: (
          <TruncatedText
            text={castedItem.nextRace || EMPTY}
            tooltipProps={{ maxWidth: '400px' }}
          />
        ),
        mcp: castedItem.mCPRelative || EMPTY,
        coach: castedItem.coachName || EMPTY,
      };

      return renderMap[column.key];
    },
    [athletesMap, baseUrl]
  );
};
