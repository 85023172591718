import { useMemo } from 'react';
import { ISelectOption } from '@cycling-web/analog-ui';
import { ICalendarRuleWeek } from '../../../../../../types/workouts';
import { useTranslation } from 'react-i18next';

export const useDayOfWeek = (): ISelectOption[] => {
  const { t } = useTranslation();

  return useMemo((): ISelectOption[] => {
    return [
      {
        id: ICalendarRuleWeek.MONDAY,
        text: t('label.monday'),
      },
      {
        id: ICalendarRuleWeek.TUESDAY,
        text: t('label.tuesday'),
      },
      {
        id: ICalendarRuleWeek.WEDNESDAY,
        text: t('label.wednesday'),
      },
      {
        id: ICalendarRuleWeek.THURSDAY,
        text: t('label.thursday'),
      },
      {
        id: ICalendarRuleWeek.FRIDAY,
        text: t('label.friday'),
      },
      {
        id: ICalendarRuleWeek.SATURDAY,
        text: t('label.saturday'),
      },
      {
        id: ICalendarRuleWeek.SUNDAY,
        text: t('label.sunday'),
      },
    ];
  }, [t]);
};
