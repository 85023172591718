import './index.css';
import { ConfirmDialog, Dialog } from '@cycling-web/analog-ui';
import { useViewModel } from './useViewModel';
import { useTranslation } from 'react-i18next';
import { CalendarAddContext } from './context';
import { CalendarAddAside } from './components/Aside';
import { CalendarAddModalPage, ICalendarAddModalProps } from './types';
import { DayOffForm } from './components/DayOffForm';
import { EventForm } from './components/EventForm';
import { WorkoutForm } from './components/WorkoutForm';
import { NoteForm } from './components/NoteForm';

export const CalendarAddModal = (props: ICalendarAddModalProps) => {
  const { t } = useTranslation();
  const { context } = useViewModel(props);

  const {
    currentPage,
    discardDialogProps,
    dismissDiscardDialog,
    handleDismiss,
  } = context;

  return (
    <CalendarAddContext value={context}>
      <Dialog
        className="calendar-add-modal"
        title={t('action.add')}
        onDismiss={handleDismiss}
        outsidePress={false}
        onRenderFooter={() => null}
        preventDefaultOnClick={false}
      >
        <div className="calendar-add-modal__content">
          <CalendarAddAside />
          <div className="calendar-add-modal__main">
            {currentPage === CalendarAddModalPage.Event && <EventForm />}
            {currentPage === CalendarAddModalPage.Workout && <WorkoutForm />}
            {currentPage === CalendarAddModalPage.Notes && <NoteForm />}
            {currentPage === CalendarAddModalPage.Availability && (
              <DayOffForm />
            )}
          </div>
        </div>
        {discardDialogProps && (
          <ConfirmDialog
            onDismiss={dismissDiscardDialog}
            title={t('confirm.discard_changes_title')}
            subtitle={t('confirm.discard_changes_message')}
            submitText={t('action.discard')}
            submitAction={discardDialogProps.submitAction || Promise.resolve}
          />
        )}
      </Dialog>
    </CalendarAddContext>
  );
};
