import { PlatformService } from './service';
import {
  IAddGarminAccountRequest,
  IAddOuraAccountRequest,
  IAddTrainingPeaksAccountRequest,
  IAddUltrahumanAccountRequest,
  IAddWahooAccountRequest,
  IAddWhoopAccountRequest,
  IConsentMyWhooshRequest,
  IDeleteUltrahumanAccountRequest,
} from './types';
import { IPlatform } from '../../types/platform';
import { AnyValue } from '../../types/common';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';

/** The repository is responsible for data transformation */

export interface IPlatformRepository {
  getPlatforms: () => Promise<IPlatform[]>;

  getTrainingPeaksAccount: () => Promise<string>;
  removeTrainingPeaksAccount: () => Promise<void>;
  addTrainingPeaksAccount: (
    p: IAddTrainingPeaksAccountRequest
  ) => Promise<void>;

  addWahooAccount: (p: IAddWahooAccountRequest) => Promise<void>;
  getWahooClientId: () => Promise<string>;
  deleteWahooAccount: () => Promise<AnyValue>;

  addWhoopAccount: (p: IAddWhoopAccountRequest) => Promise<void>;
  getWhoopClientId: () => Promise<string>;
  deleteWhoopAccount: () => Promise<AnyValue>;

  addOuraAccount: (p: IAddOuraAccountRequest) => Promise<void>;
  getOuraClientId: () => Promise<string>;
  deleteOuraAccount: () => Promise<AnyValue>;

  addUltrahumanAccount: (p: IAddUltrahumanAccountRequest) => Promise<void>;
  getUltrahumanAccessCode: () => Promise<string>;
  deleteUltrahumanAccount: (
    p: IDeleteUltrahumanAccountRequest
  ) => Promise<void>;

  consentMyWhoosh: (p: IConsentMyWhooshRequest) => Promise<void>;
  removeMyWhooshAccount: () => Promise<void>;

  getGarminUrl: () => Promise<string>;
  addGarminAccount: (p: IAddGarminAccountRequest) => Promise<void>;
  deleteGarminAccount: () => Promise<void>;

  eraseData: () => Promise<void>;
}

export class PlatformRepository
  extends BaseRepository
  implements IPlatformRepository
{
  constructor(
    private readonly service: PlatformService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getPlatforms(): Promise<IPlatform[]> {
    const response = await this.service.getPlatforms();
    return response.data;
  }

  async getTrainingPeaksAccount(): Promise<string> {
    const response = await this.service.getTrainingPeaksAccount();
    return response.data;
  }

  async removeTrainingPeaksAccount(): Promise<void> {
    const response = await this.service.removeTrainingPeaksAccount();
    return response.data;
  }

  async addTrainingPeaksAccount(p: IAddWahooAccountRequest): Promise<void> {
    const response = await this.service.addTrainingPeaksAccount(p);
    return response.data;
  }

  async addWahooAccount(p: IAddWahooAccountRequest): Promise<void> {
    const response = await this.service.addWahooAccount(p);
    return response.data.data;
  }

  async getWahooClientId(): Promise<string> {
    const response = await this.service.getWahooClientId();
    return response.data;
  }

  async deleteWahooAccount(): Promise<AnyValue> {
    const response = await this.service.deleteWahooAccount();
    return response.data.data;
  }

  async addOuraAccount(p: IAddOuraAccountRequest): Promise<void> {
    const response = await this.service.addOuraAccount(p);
    return response.data.data;
  }

  async getOuraClientId(): Promise<string> {
    const response = await this.service.getOuraClientId();
    return response.data;
  }

  async deleteOuraAccount(): Promise<AnyValue> {
    const response = await this.service.deleteOuraAccount();
    return response.data.data;
  }

  async addUltrahumanAccount(p: IAddUltrahumanAccountRequest): Promise<void> {
    const response = await this.service.addUltrahumanAccount(p);
    return response.data.data;
  }

  async getUltrahumanAccessCode(): Promise<string> {
    const response = await this.service.getUltrahumanAccessCode();
    return response.data;
  }

  async deleteUltrahumanAccount(
    p: IDeleteUltrahumanAccountRequest
  ): Promise<AnyValue> {
    const response = await this.service.deleteUltrahumanAccount(p);
    return response.data.data;
  }

  async addWhoopAccount(p: IAddWhoopAccountRequest): Promise<void> {
    const response = await this.service.addWhoopAccount(p);
    return response.data.data;
  }

  async getWhoopClientId(): Promise<string> {
    const response = await this.service.getWhoopClientId();
    return response.data;
  }

  async deleteWhoopAccount(): Promise<AnyValue> {
    const response = await this.service.deleteWhoopAccount();
    return response.data.data;
  }

  async consentMyWhoosh(p: IConsentMyWhooshRequest): Promise<void> {
    const response = await this.service.consentMyWhoosh(p);
    return response.data;
  }

  async removeMyWhooshAccount(): Promise<void> {
    const response = await this.service.removeMyWhooshAccount();
    return response.data;
  }

  async getGarminUrl(): Promise<string> {
    const response = await this.service.getGarminUrl();
    return response.data;
  }

  async addGarminAccount(p: IAddGarminAccountRequest): Promise<void> {
    const response = await this.service.addGarminAccount(p);
    return response.data;
  }

  async deleteGarminAccount(): Promise<void> {
    const response = await this.service.deleteGarminAccount();
    return response.data;
  }

  async eraseData(): Promise<void> {
    const response = await this.service.eraseData();
    return response.data;
  }
}
