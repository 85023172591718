import './index.css';
import { $getRoot, EditorState } from 'lexical';

import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { useTranslation } from 'react-i18next';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { use } from 'react';
import { ConversationContext } from '../../context/ConversationContext';
import { ConversationState } from '../../types';
import { OnSubmitPlugin } from './submit/plugin/OnSubmitPlugin';
import { clsx } from 'clsx';
import { MentionNode } from './mention/node/MentionNode';
import MentionPlugin from './mention/plugin/MentionPlugin';
import { IConversationTextEditorProps } from './types';
import { AutocompleteNode } from './autocomplete/node/AutocompleteNode';
import AutocompletePlugin from './autocomplete/plugin/AutocompletePlugin';
import { HintNode } from './hint/node/HintNode';
import HintPlugin from './hint/plugin/HintPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';

function onError(error) {
  console.error(error);
}

export const ConversationTextEditor = ({
  onChange,
  onSubmit,
  editorRef,
  mentions,
  autocompleteProps,
  autofocus = false,
}: IConversationTextEditorProps) => {
  const { t } = useTranslation();
  const { conversationState } = use(ConversationContext);

  const rootClass = clsx(
    'conversation__text-editor',
    conversationState === ConversationState.LISTENING &&
      'conversation__text-editor--hidden',
    'analog-typography--body'
  );

  const initialConfig: InitialConfigType = {
    namespace: 'ConversationTextEditor',
    onError,
    nodes: [HintNode, AutocompleteNode, MentionNode],
  };

  const handleChange = (state: EditorState) => {
    state.read(() => {
      const text = $getRoot().getTextContent();
      onChange(text);
    });
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className={rootClass}
            aria-placeholder={t('placeholder.message')}
            placeholder={
              <div className="conversation__text-editor-placeholder analog-typography--body">
                {conversationState !== ConversationState.LISTENING && (
                  <p>{t('placeholder.message')}</p>
                )}
              </div>
            }
          />
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      {autofocus && <AutoFocusPlugin />}
      <EditorRefPlugin editorRef={editorRef} />
      <OnChangePlugin onChange={handleChange} />
      <OnSubmitPlugin onSubmit={onSubmit} />
      <ClearEditorPlugin />
      {mentions && <MentionPlugin {...mentions} />}
      {autocompleteProps && <AutocompletePlugin {...autocompleteProps} />}
      <HintPlugin />
    </LexicalComposer>
  );
};
