import './index.css';
import { Card, CardHeader, Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import { usePrescriptionStore } from '../../store/slice';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { LabelValueElement } from '../../../../components/LabelValueElement';
import { FormColumn, FormRow } from '@cycling-web/common';
import { hoursToHHMMSS } from '../../../../utils/date-time';

export const PrescriptionOverview = () => {
  const { t } = useTranslation();
  const prescription = usePrescriptionStore((s) => s.prescription);
  const prescriptionLoaded = usePrescriptionStore((s) => s.prescriptionLoaded);

  const prescriptionDate = useMemo((): string => {
    if (!prescription) {
      return '';
    }

    return format(prescription.workoutDay, 'dd/MM/yyyy');
  }, [prescription]);

  const duration = prescription?.durationInHrs
    ? hoursToHHMMSS(prescription.durationInHrs)
    : '00:00:00';
  const distance = prescription?.distanceInKms
    ? `${prescription.distanceInKms} ${t('units.km')}`
    : 0;
  const tss = prescription?.tss ? prescription.tss : 0;
  const elevation = prescription?.elevationInMtrs
    ? `${prescription.elevationInMtrs} ${t('units.m')}`
    : 0;
  const energy = prescription?.energyInKJs
    ? `${prescription.energyInKJs} ${t('units.kj')}`
    : 0;

  return (
    <Card variant="layer3" className="prescription-card__overview">
      <CardHeader icon={<CyclingIcon />} title={t('label.cycling')} />
      <Typography
        as="div"
        variant="headline"
        color="text-placeholder"
        text={prescriptionDate}
        loading={!prescriptionLoaded}
      />

      <LabelValueElement
        name={t('label.title')}
        value={prescription?.title || ''}
        size="s"
        loading={!prescriptionLoaded}
      />

      <LabelValueElement
        name={t('label.description')}
        value={prescription?.description}
        size="s"
        loading={!prescriptionLoaded}
        valueTypographyProps={{
          as: 'pre',
        }}
      />

      <FormRow>
        <FormColumn>
          <LabelValueElement
            name={t('label.duration')}
            value={duration}
            size="s"
            loading={!prescriptionLoaded}
          />
        </FormColumn>
        <FormColumn>
          <LabelValueElement
            name={t('label.distance')}
            value={distance}
            size="s"
            loading={!prescriptionLoaded}
          />
        </FormColumn>
        <FormColumn>
          <LabelValueElement
            name={t('label.tss')}
            value={tss}
            size="s"
            loading={!prescriptionLoaded}
          />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <LabelValueElement
            name={t('label.elevation')}
            value={elevation}
            size="s"
            loading={!prescriptionLoaded}
          />
        </FormColumn>
        <FormColumn>
          <LabelValueElement
            name={t('label.work')}
            value={energy}
            size="s"
            loading={!prescriptionLoaded}
          />
        </FormColumn>
        <FormColumn />
      </FormRow>
    </Card>
  );
};
