import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useAthleteProfileStore } from '../store/slice';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ITrainingZonePerformance } from '../../../types/performance';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteProfileFilters } from '../types';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';

export const useFetchTrainingZonesPerformance = () => {
  const { athleteId } = useParams();

  const { filters } = usePageFilters<IAthleteProfileFilters>();

  const setZonePerformance = useAthleteProfileStore(
    (s) => s.setZonePerformance
  );
  const setZonePerformanceLoaded = useAthleteProfileStore(
    (s) => s.setZonePerformanceLoaded
  );

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService());
  }, []);

  const fetchTrainingZonesPerformance = useCallback(() => {
    if (!athleteId) {
      return Promise.resolve();
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    return performanceRepository
      .getTrainingZonesPerformance({
        athleteId: +athleteId,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      })
      .then((data: ITrainingZonePerformance[]) => {
        setZonePerformance(data);
      })
      .catch(() => {
        setZonePerformanceLoaded(true);
      });
  }, [
    athleteId,
    filters.period,
    performanceRepository,
    setZonePerformance,
    setZonePerformanceLoaded,
  ]);

  return {
    fetchTrainingZonesPerformance,
  };
};
