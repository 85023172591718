import './index.css';
import { Collapsible } from '@cycling-web/analog-ui';
import { useCallback, useEffect, useState } from 'react';
import { MenuLink } from '../MenuLink';
import { OptionLink } from '../OptionLink';
import { useTeamsStore } from '../../../../store/teams/slice';
import { INavigationGroup, INavigationItem } from '@cycling-web/common';
import { isGroup } from '../../utils';
import { useSideNavigationContext } from '../../context';
import { CollapseButton } from '../CollapseButton';

export const MenuDesktop = () => {
  const { items } = useSideNavigationContext();
  const selectedTeam = useTeamsStore((s) => s.selectedTeam);

  const [activeGroup, setActiveGroup] = useState<string | undefined>(undefined);

  useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      if (isGroup(items[i])) {
        const group = items[i] as INavigationGroup;
        if (group.group && group.active) {
          setActiveGroup(group.group);
          break;
        }
      }
    }
  }, [items]);

  const onGroupChange = useCallback((isOpen: boolean, id?: string) => {
    if (isOpen) {
      setActiveGroup(id);
    } else {
      setActiveGroup(undefined);
    }
  }, []);

  const itemsJSX = items.map((item: INavigationItem) => {
    if (!isGroup(item)) {
      return <MenuLink key={item.url} item={item} />;
    }

    const optionsJSX = item.items.map((option) => {
      return <OptionLink key={option.url} item={option} />;
    });

    return (
      <Collapsible
        key={item.group}
        id={item.group}
        isOpen={activeGroup === item.group}
        onChange={onGroupChange}
        icon={item.icon}
        text={item.text}
        content={
          <div className="side-navigation__option-content">{optionsJSX}</div>
        }
      />
    );
  }, []);

  return (
    <div className="side-navigation__menu-desktop">
      {itemsJSX}

      <div className="side-navigation__menu-desktop-bottom">
        <CollapseButton />
        {selectedTeam && (
          <div
            className="side-navigation__team-logo"
            style={{ backgroundImage: `url(${selectedTeam.teamLogo})` }}
          />
        )}
      </div>
    </div>
  );
};
