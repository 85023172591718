import './index.css';
import { TrainingPlanHeader } from '../Header';
import { useTranslation } from 'react-i18next';

export const Overview = () => {
  const { t } = useTranslation();

  return (
    <div className="training-plan__overview">
      <TrainingPlanHeader
        title={t('label.overview')}
        subtitle={t('label.training_plan_step3_subtitle')}
      />
    </div>
  );
};
