import { ConversationService } from './service';
import {
  IGetConversationHistoryRequest,
  IGetTranscribeTicketResponse,
  ITranscribeAudioMessageRequest,
  ITranscribeAudioMessageResponse,
} from './types';
import { IAnaHistory } from '../../components/Conversation';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import { AxiosRequestConfig } from 'axios';

export interface IConversationRepository {
  generateTranscribeTicket: () => Promise<IGetTranscribeTicketResponse>;
  transcribeAudioMessage: (
    p: ITranscribeAudioMessageRequest
  ) => Promise<ITranscribeAudioMessageResponse>;
  getHistory: (
    p: IGetConversationHistoryRequest,
    c?: AxiosRequestConfig
  ) => Promise<IAnaHistory>;
}

export class ConversationRepository
  extends BaseRepository
  implements IConversationRepository
{
  constructor(
    private readonly service: ConversationService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async generateTranscribeTicket(): Promise<IGetTranscribeTicketResponse> {
    const response = await this.service.generateTranscribeTicket();
    return response.data;
  }

  async transcribeAudioMessage(
    p: ITranscribeAudioMessageRequest
  ): Promise<ITranscribeAudioMessageResponse> {
    const response = await this.service.transcribeAudioMessage(p);
    return response.data;
  }

  async getHistory(
    p: IGetConversationHistoryRequest,
    c?: AxiosRequestConfig
  ): Promise<IAnaHistory> {
    const response = await this.service.getHistory(p, c);
    return response.data;
  }
}
