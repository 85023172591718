import {
  IGetAthletePerformanceReportRequest,
  IGetAthletePerformanceReportResponse,
  IGetCriticalPowerRequest,
  IGetCriticalPowerResponse,
  IGetFatigueResistanceRelativeRequest,
  IGetFatigueResistanceRelativeResponse,
  IGetFatigueResistanceRequest,
  IGetFatigueResistanceResponse,
  IGetFitnessFatigueRequest,
  IGetFitnessFatigueResponse,
  IGetITRIMPTSSRequest,
  IGetITRIMPTSSResponse,
  IGetLastSFTRequest,
  IGetLastSFTResponse,
  IGetLatestTrainingLoadRequest,
  IGetLatestTrainingLoadResponse,
  IGetPdCurveRelativeRequest,
  IGetPdCurveRelativeResponse,
  IGetPdCurveRequest,
  IGetPdCurveResponse,
  IGetPeakRequest,
  IGetPeakResponse,
  IGetPerformanceReportRequest,
  IGetPerformanceReportResponse,
  IGetPowerDerivativesRequest,
  IGetPowerDerivativesResponse,
  IGetTrainingLoadFeedbackRequest,
  IGetTrainingLoadFeedbackResponse,
  IGetTrainingLoadRequest,
  IGetTrainingLoadResponse,
  IGetTrainingZonesRequest,
  IGetTrainingZonesResponse,
  IGetWorkoutDetailsRequest,
  IGetWorkoutDetailsResponse,
} from './types';
import { axiosInstance } from '../../axios-instance';
import { R } from '../../types/common';
import qs from 'qs';
import { qsOptions } from '../utils/qsOptions';
import { AxiosRequestConfig } from 'axios';

interface IPerformanceService {
  getPerformanceReport: (
    p: IGetPerformanceReportRequest,
    c?: AxiosRequestConfig
  ) => R<IGetPerformanceReportResponse>;
  getAthletePerformanceReport: (
    p: IGetAthletePerformanceReportRequest,
    c?: AxiosRequestConfig
  ) => R<IGetAthletePerformanceReportResponse>;

  getTrainingLoad: (
    p: IGetTrainingLoadRequest,
    c?: AxiosRequestConfig
  ) => R<IGetTrainingLoadResponse>;
  getLatestTrainingLoad: (
    p: IGetLatestTrainingLoadRequest,
    c?: AxiosRequestConfig
  ) => R<IGetLatestTrainingLoadResponse>;
  getTrainingLoadFeedback: (
    p: IGetTrainingLoadFeedbackRequest,
    c?: AxiosRequestConfig
  ) => R<IGetTrainingLoadFeedbackResponse>;

  getWorkoutDetails: (
    p: IGetWorkoutDetailsRequest,
    c?: AxiosRequestConfig
  ) => R<IGetWorkoutDetailsResponse>;
  getPowerDerivatives: (
    p: IGetPowerDerivativesRequest,
    c?: AxiosRequestConfig
  ) => R<IGetPowerDerivativesResponse>;
  getPeak: (p: IGetPeakRequest) => R<IGetPeakResponse>;
  getPdCurveRelative: (
    p: IGetPdCurveRelativeRequest,
    c?: AxiosRequestConfig
  ) => R<IGetPdCurveRelativeResponse>;
  getPdCurve: (
    p: IGetPdCurveRequest,
    c?: AxiosRequestConfig
  ) => R<IGetPdCurveResponse>;
  getLastSFT: (
    p: IGetLastSFTRequest,
    c?: AxiosRequestConfig
  ) => R<IGetLastSFTResponse>;
  getITRIMPTSS: (
    p: IGetITRIMPTSSRequest,
    c?: AxiosRequestConfig
  ) => R<IGetITRIMPTSSResponse>;
  getFitnessFatigue: (
    p: IGetFitnessFatigueRequest,
    c?: AxiosRequestConfig
  ) => R<IGetFitnessFatigueResponse>;
  getFatigueResistanceRelative: (
    p: IGetFatigueResistanceRelativeRequest,
    c?: AxiosRequestConfig
  ) => R<IGetFatigueResistanceRelativeResponse>;
  getFatigueResistance: (
    p: IGetFatigueResistanceRequest,
    c?: AxiosRequestConfig
  ) => R<IGetFatigueResistanceResponse>;
  getCriticalPower: (
    p: IGetCriticalPowerRequest,
    c?: AxiosRequestConfig
  ) => R<IGetCriticalPowerResponse>;

  getTrainingZonesPerformance: (
    p: IGetTrainingZonesRequest
  ) => R<IGetTrainingZonesResponse>;
}

export class PerformanceService implements IPerformanceService {
  async getPerformanceReport(
    p: IGetPerformanceReportRequest,
    c?: AxiosRequestConfig
  ): R<IGetPerformanceReportResponse> {
    try {
      return axiosInstance.get(`/v1/report/weekly/${p.year}/${p.week}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAthletePerformanceReport(
    p: IGetAthletePerformanceReportRequest,
    c?: AxiosRequestConfig
  ): R<IGetAthletePerformanceReportResponse> {
    try {
      return axiosInstance.get(
        `/v1/report/weekly/${p.athleteId}/${p.year}/${p.week}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getWorkoutDetails(
    p: IGetWorkoutDetailsRequest,
    c?: AxiosRequestConfig
  ): R<IGetWorkoutDetailsResponse> {
    try {
      const params = qs.stringify(
        {
          athleteIds: p.athleteIds,
          unit: p.unit,
          aggregatePerAthlete: p.aggregatePerAthlete,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/workoutDetails/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTrainingLoad(
    p: IGetTrainingLoadRequest,
    c?: AxiosRequestConfig
  ): R<IGetTrainingLoadResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/trainingLoad/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPowerDerivatives(
    p: IGetPowerDerivativesRequest,
    c?: AxiosRequestConfig
  ): R<IGetPowerDerivativesResponse> {
    try {
      const params = qs.stringify(
        { athleteIds: p.athleteIds, unit: p.unit },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/powerDerivatives/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPeak(
    p: IGetPeakRequest,
    c?: AxiosRequestConfig
  ): R<IGetPeakResponse> {
    try {
      const params = qs.stringify(
        {
          athleteIds: p.athleteIds,
          allAthletes: p.allAthletes,
        },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/peak/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPdCurveRelative(
    p: IGetPdCurveRelativeRequest,
    c?: AxiosRequestConfig
  ): R<IGetPdCurveRelativeResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/pdCurveRelative/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPdCurve(
    p: IGetPdCurveRequest,
    c?: AxiosRequestConfig
  ): R<IGetPdCurveResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/pdCurve/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getLastSFT(
    p: IGetLastSFTRequest,
    c?: AxiosRequestConfig
  ): R<IGetLastSFTResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(`/v1/performance/lastSFT?${params}`, c);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getITRIMPTSS(
    p: IGetITRIMPTSSRequest,
    c?: AxiosRequestConfig
  ): R<IGetITRIMPTSSResponse> {
    try {
      const params = qs.stringify(
        { athleteIds: p.athleteIds, unit: p.unit },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/iTRIMP-TSS/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFitnessFatigue(
    p: IGetFitnessFatigueRequest,
    c?: AxiosRequestConfig
  ): R<IGetFitnessFatigueResponse> {
    try {
      const params = qs.stringify(
        { athleteIds: p.athleteIds, unit: p.unit },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/fitness-fatigue/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFatigueResistanceRelative(
    p: IGetFatigueResistanceRelativeRequest,
    c?: AxiosRequestConfig
  ): R<IGetFatigueResistanceRelativeResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/fatigueResistanceRelative/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getFatigueResistance(
    p: IGetFatigueResistanceRequest,
    c?: AxiosRequestConfig
  ): R<IGetFatigueResistanceResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/fatigueResistance/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCriticalPower(
    p: IGetCriticalPowerRequest,
    c?: AxiosRequestConfig
  ): R<IGetCriticalPowerResponse> {
    try {
      const params = qs.stringify({ athleteIds: p.athleteIds }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/criticalPower/${p.startDate}/${p.endDate}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getLatestTrainingLoad(
    p: IGetLatestTrainingLoadRequest,
    c?: AxiosRequestConfig
  ): R<IGetLatestTrainingLoadResponse> {
    try {
      const params = qs.stringify({ athleteIds: p.athleteIds }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/latestTrainingLoad?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTrainingLoadFeedback(
    p: IGetTrainingLoadFeedbackRequest,
    c?: AxiosRequestConfig
  ): R<IGetTrainingLoadFeedbackResponse> {
    try {
      const params = qs.stringify({ athleteId: p.athleteId }, qsOptions);
      return axiosInstance.get(
        `/v1/performance/trainingLoadFeedback?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTrainingZonesPerformance(
    p: IGetTrainingZonesRequest,
    c?: AxiosRequestConfig
  ): R<IGetTrainingZonesResponse> {
    try {
      const params = qs.stringify(
        { startDate: p.startDate, endDate: p.endDate, workoutId: p.workoutId },
        qsOptions
      );
      return axiosInstance.get(
        `/v1/performance/zones/${p.athleteId}?${params}`,
        c
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
