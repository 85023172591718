import './index.css';
import { useViewModel } from './useViewModel';
import { PerformanceManagementChart } from './components/PerformanceManagementChart';
import { TssAndIfChart } from '../../components/charts/TssAndIfChart';
import { DurationAndDistanceChart } from '../../components/charts/DurationAndDistanceChart';
import { DurationAndElevationChart } from '../../components/charts/DurationAndElevationChart';
import { WorkAndIfChart } from '../../components/charts/WorkAndIfChart';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';

export const AthleteTrainingLoadView = () => {
  const {
    filters,
    handleChangeFilter,
    workoutDetailsSources,
    workoutDetailsLoading,
    trainingLoad,
    trainingLoadLoading,
  } = useViewModel();

  return (
    <div className="training-load-page">
      <PerformanceManagementChart
        data={trainingLoad}
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        loading={trainingLoadLoading}
      />
      <TssAndIfChart
        source={workoutDetailsSources.tssAndIf}
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        loading={workoutDetailsLoading}
      />
      <DurationAndDistanceChart
        source={workoutDetailsSources.durationAndDistance}
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        loading={workoutDetailsLoading}
      />
      <DurationAndElevationChart
        source={workoutDetailsSources.durationAndElevation}
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        loading={workoutDetailsLoading}
      />
      <WorkAndIfChart
        source={workoutDetailsSources.workAndIf}
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        loading={workoutDetailsLoading}
      />
    </div>
  );
};

export const AthleteTrainingLoad = () => {
  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().athleteDetails}
    >
      <AthleteTrainingLoadView />
    </PageFiltersContextProvider>
  );
};
