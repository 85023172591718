import { TrainingZoneKeys } from '../../../../utils/getDefaults';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@cycling-web/analog-ui';
import { RefreshCcw } from 'lucide-react';
import { useAthleteStore } from '../../../../../../store/athlete/slice';
import { useCallback } from 'react';
import { McpUtils } from '../../../../../../utils/mcp';
import { roundToDecimals } from '../../../../../../utils/maths';

type IProps = {
  composeKey: string;
  onReset: (value: string) => void;
};

export const ThresholdMatching = ({ composeKey, onReset }: IProps) => {
  const { t } = useTranslation();
  const athlete = useAthleteStore((s) => s.athlete);

  const isMFTP = composeKey === TrainingZoneKeys.CyclingPowerMFTP;
  const isMCP = composeKey === TrainingZoneKeys.CyclingPowerMCP;

  const mftp = athlete?.mftp ? roundToDecimals(athlete.mftp, 2) : 0;
  const mcp = athlete?.mcp ? roundToDecimals(athlete.mcp, 2) : 0;
  const ftp = athlete?.ftp ?? 0;

  const handleReset = useCallback(() => {
    if (isMFTP) {
      if (athlete?.mftp !== undefined) {
        const mftp = roundToDecimals(athlete.mftp, 2).toString();
        onReset(mftp);
      }
    } else if (isMCP) {
      if (athlete?.mcp !== undefined) {
        const mcp = roundToDecimals(athlete.mcp, 2).toString();
        onReset(mcp);
      }
    }
  }, [athlete, isMCP, isMFTP, onReset]);

  const isMFTPMatching = mftp.toString() === ftp.toString();

  if (isMFTP) {
    return isMFTPMatching ? (
      <Typography
        variant="subtitle"
        text={t('label.matching_mftp')}
        className="training-zones-form-modal__filters-matching"
      />
    ) : (
      <Tooltip
        anchor={
          <IconButton
            variant="quietLayer3"
            content={<RefreshCcw />}
            onClick={handleReset}
          />
        }
        content={t('tooltip.reset_to', {
          what: t('label.mftp'),
        })}
      />
    );
  }

  const isMCPMatching = mcp === McpUtils.computeCPFromFTP(ftp);

  if (isMCP) {
    return isMCPMatching ? (
      <Typography
        variant="subtitle"
        text={t('label.matching_mcp')}
        className="training-zones-form-modal__filters-matching"
      />
    ) : (
      <Tooltip
        anchor={
          <IconButton
            variant="quietLayer3"
            content={<RefreshCcw />}
            onClick={handleReset}
          />
        }
        content={t('tooltip.reset_to', {
          what: t('label.mcp'),
        })}
      />
    );
  }

  return null;
};
