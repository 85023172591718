import React from 'react';
import { Navigate, RouteObject } from 'react-router';
import { ROUTES } from './routes';
import { EntryAthlete } from '../pages/EntryAthlete';
import { EntryCoach } from '../pages/EntryCoach';
import { UserTeams } from '../pages/UserTeams';
import { Reception } from '../pages/Reception';
import { Athletes } from '../pages/Athletes';
import { Groups } from '../pages/Groups';
import { PerformanceReport } from '../pages/PerformanceReport';
import { MedicalReports } from '../pages/MedicalReports';
import { AdminPanel } from '../pages/AdminPanel';
import { AthleteDetails } from '../pages/AthleteDetails';
import { AthletePerformance } from '../pages/AthletePerformance';
import { AthleteWellness } from '../pages/AthleteWellness';
import { AthleteHealth } from '../pages/AthleteHealth';
import { GroupDetails } from '../pages/GroupDetails';
import { GroupCharts } from '../pages/GroupCharts';
import { GroupOverview } from '../pages/GroupOverview';
import { GroupProfiles } from '../pages/GroupProfiles';
import { Settings } from '../pages/Settings';
import { PerformanceReportTable } from '../pages/PerformanceReportTable';
import { PerformanceReportCharts } from '../pages/PerformanceReportCharts';
import { AthleteMedicalReports } from '../pages/AthleteMedicalReports';
import { MedicalReport } from '../pages/MedicalReport';
import { AthleteMedicalReport } from '../pages/AthleteMedicalReport';
import { AthleteTrainingLoad } from '../pages/AthleteTrainingLoad';
import { AdminTeamMembers } from '../pages/AdminTeamMembers';
import { AdminTeamMemberDetails } from '../pages/AdminTeamMemberDetails';
import { AdminTeamMapping } from '../pages/AdminTeamMapping';
import { AdminTeamCommunicationSettings } from '../pages/AdminTeamCommunicationSettings';
import { PinnedTab } from '../pages/PinnedTab';
import { Integrations } from '../pages/Integrations';
import { Consent } from '../pages/Consent';
import { Onboarding } from '../pages/Onboarding';
import { OnboardingPrivacyNotice } from '../pages/Onboarding/components/PrivacyNotice';
import { OnboardingPersonalInfo } from '../pages/Onboarding/components/PersonalInfo';
import { OnboardingPerformanceInfo } from '../pages/Onboarding/components/PerformanceInfo';
import { OnboardingPlatforms } from '../pages/Onboarding/components/Platforms';
import { OnboardingDevices } from '../pages/Onboarding/components/Devices';
import { OnboardingCompleted } from '../pages/Onboarding/components/Completed';
import { Legal } from '../pages/Legal';
import { TermsAndConditions } from '../pages/Legal/components/TermsAndConditions';
import { PrivacyNotice } from '../pages/Legal/components/PrivacyNotice';
import { CookiesNotice } from '../pages/Legal/components/CookiesNotice';
import { Glossary } from '../pages/Legal/components/Glossary';
import { Dashboard } from '../pages/Dashboard';
import { SettingsProfile } from '../pages/SettingsProfile';
import { SettingsDataSharing } from '../pages/SettingsDataSharing';
import { AthletePage } from '../pages/AthletePage';
import { TeamInvitation } from '../pages/TeamInvitation';
import { SmartCharts } from '../pages/SmartCharts';
import { authRoutes, PrivatePage } from '@cycling-web/auth';
import { AthleteProfile } from '../pages/AthleteProfile';
import { Url } from '../utils/url';
import { getDefaultPageFilters } from '../constants/page-filters';
import { Workouts } from '../pages/Workouts';
import { WorkoutDetails } from '../pages/WorkoutDetails';
import { EventDetails } from '../pages/EventDetails';
import { CalendarPrescription } from '../pages/Workouts/components/CalendarPrescription';
import { WorkoutPrescriptionDetails } from '../pages/WorkoutPrescriptionDetails';

const legalRoutes: RouteObject[] = [
  {
    path: ROUTES.LEGAL,
    children: [
      {
        path: '*',
        element: <Legal />,
        children: [
          {
            path: '',
            element: <Navigate to={ROUTES.TERMS_AND_CONDITIONS} />,
          },
          {
            path: ROUTES.TERMS_AND_CONDITIONS,
            element: <TermsAndConditions />,
          },
          {
            path: ROUTES.PRIVACY_NOTICE,
            element: <PrivacyNotice />,
          },
          {
            path: ROUTES.COOKIES_NOTICE,
            element: <CookiesNotice />,
          },
          {
            path: ROUTES.GLOSSARY,
            element: <Glossary />,
          },
        ],
      },
    ],
  },
];

const onboardingRoutes: RouteObject[] = [
  {
    path: ROUTES.ONBOARDING,
    element: <Onboarding />,
    children: [
      {
        path: ROUTES.PRIVACY_NOTICE,
        element: <OnboardingPrivacyNotice />,
      },
      {
        path: ROUTES.ONBOARDING_PROFILE,
        element: <OnboardingPersonalInfo />,
      },
      {
        path: ROUTES.ONBOARDING_PERFORMANCE,
        element: <OnboardingPerformanceInfo />,
      },
      {
        path: ROUTES.ONBOARDING_PLATFORMS,
        element: <OnboardingPlatforms />,
      },
      {
        path: ROUTES.ONBOARDING_DEVICES,
        element: <OnboardingDevices />,
      },
      {
        path: ROUTES.ONBOARDING_COMPLETE,
        element: <OnboardingCompleted />,
      },
    ],
  },
];

const athleteRoutes: RouteObject[] = [
  {
    path: '*',
    element: <AthleteDetails />,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.PROFILE} />,
      },
      {
        path: ROUTES.PROFILE,
        element: <AthleteProfile />,
      },
      {
        path: ROUTES.TRAINING_LOAD,
        element: <AthleteTrainingLoad />,
      },
      {
        path: ROUTES.PERFORMANCE,
        element: <AthletePerformance />,
      },
      {
        path: ROUTES.WELLNESS,
        element: <AthleteWellness />,
      },
      {
        path: ROUTES.HEALTH,
        element: <AthleteHealth />,
        children: [
          {
            path: '',
            element: <AthleteMedicalReports />,
          },
          {
            path: ':reportId',
            element: <AthleteMedicalReport />,
          },
        ],
      },
      {
        path: ':pinnedTabId',
        element: <PinnedTab />,
      },
    ],
  },
];

const smartChartsRoutes: RouteObject[] = [
  {
    path: `${ROUTES.SMART_CHARTS}`,
    element: <SmartCharts />,
  },
  {
    path: `${ROUTES.SMART_CHARTS}/:chartId`,
    element: <SmartCharts />,
  },
];

const adminRoutes: RouteObject[] = [
  {
    path: ROUTES.ADMIN_PANEL,
    element: <AdminPanel />,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.TEAM_MEMBERS} />,
      },
      {
        path: ROUTES.TEAM_MEMBERS,
        element: <AdminTeamMembers />,
      },
      {
        path: ROUTES.TEAM_MAPPING,
        element: <AdminTeamMapping />,
      },
      {
        path: ROUTES.COMMUNICATION_SETTINGS,
        element: <AdminTeamCommunicationSettings />,
      },
      {
        path: `${ROUTES.TEAM_MEMBERS}/:role/:userId`,
        element: <AdminTeamMemberDetails />,
      },
      {
        path: `${ROUTES.TEAM_MEMBERS}/:role`,
        element: <Navigate to={`../${ROUTES.TEAM_MEMBERS}`} />,
      },
    ],
  },
];

const groupRoutes: RouteObject[] = [
  {
    path: `${ROUTES.GROUPS}/:groupId`,
    element: <GroupDetails />,
    children: [
      {
        path: '',
        element: <Navigate to={ROUTES.ATHLETES} />,
      },
      {
        path: ROUTES.ATHLETES,
        element: <GroupProfiles />,
      },
      {
        path: ROUTES.OVERVIEW,
        element: <GroupOverview />,
      },
      {
        path: ROUTES.CHARTS,
        element: <GroupCharts />,
      },
      {
        path: ':pinnedTabId',
        element: <PinnedTab />,
      },
    ],
  },
  {
    path: `${ROUTES.GROUPS}/:groupId/${ROUTES.ATHLETES}/:athleteId`,
    children: [...athleteRoutes],
  },
];

const calendarRoutes: RouteObject[] = [
  {
    path: ROUTES.CALENDAR,
    element: <Workouts />,
  },
  {
    path: `${ROUTES.CALENDAR}/:athleteId`,
    element: <Workouts />,
  },
];

const workoutRoutes: RouteObject[] = [
  {
    path: `${ROUTES.WORKOUT}/:workoutId`,
    element: <WorkoutDetails />,
  },
];

const eventRoutes: RouteObject[] = [
  {
    path: `${ROUTES.EVENT}/:eventId`,
    element: <EventDetails />,
  },
];

const prescriptionRoutes: RouteObject[] = [
  {
    path: `${ROUTES.PRESCRIPTION}/:prescriptionId`,
    element: <WorkoutPrescriptionDetails />,
  },
];

export const routes: RouteObject[] = [
  {
    path: '',
    element: <Navigate to={ROUTES.TEAMS} />,
  },
  {
    path: `/${ROUTES.CONSENT}`,
    element: <Consent />,
  },
  ...authRoutes,
  ...legalRoutes,
  {
    path: `*`,
    element: <PrivatePage />,
    children: [
      {
        path: ROUTES.TEAM_INVITATION,
        element: <TeamInvitation />,
      },
      {
        path: ROUTES.TEAMS,
        children: [
          {
            path: '*',
            element: <UserTeams />,
          },
        ],
      },
      {
        path: `${ROUTES.INTEGRATIONS}`,
        element: <Integrations />,
      },
      {
        path: `:teamId`,
        children: [
          {
            path: '*',
            element: <Reception />,
            children: [
              ...onboardingRoutes,
              {
                path: ROUTES.ATHLETE,
                children: [
                  {
                    path: '*',
                    element: <EntryAthlete />,
                    children: [
                      {
                        path: `:athleteId`,
                        children: [
                          {
                            path: '*',
                            element: <AthletePage />,
                            children: [
                              {
                                path: '',
                                element: <Navigate to={ROUTES.PROFILE} />,
                              },
                              {
                                path: ROUTES.PROFILE,
                                children: [...athleteRoutes],
                              },
                              ...smartChartsRoutes,
                              ...calendarRoutes,
                              ...workoutRoutes,
                              ...eventRoutes,
                              ...prescriptionRoutes,
                            ],
                          },
                        ],
                      },
                      {
                        path: ROUTES.SETTINGS,
                        element: <Settings />,
                        children: [
                          {
                            path: '',
                            element: <Navigate to={ROUTES.SETTINGS_PROFILE} />,
                          },
                          {
                            path: ROUTES.SETTINGS_PROFILE,
                            element: <SettingsProfile />,
                          },
                          {
                            path: ROUTES.DATA_SHARING,
                            element: <SettingsDataSharing />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: ROUTES.COACH,
                children: [
                  {
                    path: '*',
                    element: <EntryCoach />,
                    children: [
                      {
                        path: '',
                        element: (
                          <Navigate
                            to={`${ROUTES.DASHBOARD}${Url.stringifyFilters(
                              getDefaultPageFilters().dashboard
                            )}`}
                          />
                        ),
                      },
                      {
                        path: ROUTES.DASHBOARD,
                        element: <Dashboard />,
                      },
                      ...adminRoutes,
                      {
                        path: ROUTES.ATHLETES,
                        element: <Athletes />,
                      },
                      {
                        path: `${ROUTES.ATHLETES}/:athleteId`,
                        children: [...athleteRoutes],
                      },
                      {
                        path: ROUTES.GROUPS,
                        element: <Groups />,
                      },
                      ...groupRoutes,
                      ...calendarRoutes,
                      ...workoutRoutes,
                      ...eventRoutes,
                      ...prescriptionRoutes,
                      {
                        path: ROUTES.PERFORMANCE_REPORT,
                        element: <PerformanceReport />,
                        children: [
                          {
                            path: '',
                            element: <Navigate to={ROUTES.PERFORMANCE_TABLE} />,
                          },
                          {
                            path: ROUTES.PERFORMANCE_TABLE,
                            element: <PerformanceReportTable />,
                          },
                          {
                            path: ROUTES.PERFORMANCE_CHART,
                            element: <PerformanceReportCharts />,
                          },
                        ],
                      },
                      {
                        path: ROUTES.MEDICAL_REPORT_TABLE,
                        element: <MedicalReports />,
                      },
                      {
                        path: `${ROUTES.MEDICAL_REPORT_TABLE}/:reportId`,
                        element: <MedicalReport />,
                      },
                      {
                        path: ROUTES.SETTINGS,
                        element: <Settings />,
                        children: [
                          {
                            path: '',
                            element: <Navigate to={ROUTES.SETTINGS_PROFILE} />,
                          },
                          {
                            path: ROUTES.SETTINGS_PROFILE,
                            element: <SettingsProfile />,
                          },
                        ],
                      },
                      ...smartChartsRoutes,
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
