import './index.css';
import { Button, FormElement, Message } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputControl, InputPasswordControl } from '@cycling-web/common';
import { FormProvider, useForm } from 'react-hook-form';
import { use, useCallback, useMemo, useState } from 'react';
import { AuthRepository } from '../../api/auth/repository';
import { AuthPageContext } from '../../context/AuthPageContext';
import { Link } from 'react-router';
import { ROUTES } from '../../router/routes';
import { FormView } from '../FormView';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { IOAuthTokenResponse } from '../../api/auth/types';
import { LS } from '../../constants';
import { useAnalogAuthContext } from '../../context/AnalogAuthContext';

type IForm = {
  email: string;
  password: string;
};

export const SignIn = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { continuationTokenRef, usernameRef } = use(AuthPageContext);

  const {
    setAuthenticated,
    onSignIn,
    authOptions,
    allowSignUp,
    allowWaitList,
    allowResetPassword,
    translations,
  } = useAnalogAuthContext();

  const authRepository = useMemo(() => {
    return AuthRepository.getInstance(authOptions);
  }, [authOptions]);

  const schema = useMemo(() => {
    return z.object({
      email: z.string().min(1, { message: t('validation.required') }),
      password: z.string().min(1, { message: t('validation.required') }),
    });
  }, [t]);

  const emailParam = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('email') || '';
  }, []);

  const defaultValues = useMemo((): IForm => {
    return {
      email: emailParam,
      password: '',
    };
  }, [emailParam]);

  const form = useForm<IForm>({
    defaultValues,
    resolver: zodResolver(schema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit = useCallback(
    (formData: IForm) => {
      setLoading(true);
      setMessage('');

      usernameRef.current = formData.email;

      authRepository
        .signInStart({
          challenge_type: 'password redirect',
          username: formData.email,
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          return authRepository.signInChallenge({
            challenge_type: 'password redirect',
            continuation_token: continuationTokenRef.current,
          });
        })
        .then(({ continuation_token }) => {
          continuationTokenRef.current = continuation_token;
          return authRepository.getOAuthToken({
            continuation_token: continuationTokenRef.current,
            password: formData.password,
            grant_type: 'password',
          });
        })
        .then((response: IOAuthTokenResponse) => {
          localStorage.setItem(LS.AccessToken, response.id_token);
          localStorage.setItem(LS.RefreshToken, response.refresh_token);

          if (onSignIn) {
            return onSignIn(response);
          }
          return Promise.resolve();
        })
        .then(() => {
          setAuthenticated(true);
        })
        .catch((e: { suberror: string }) => {
          const suberror = e?.suberror || 'invalid_email_or_password';
          setMessage(t(`validation.${suberror}`));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      authRepository,
      continuationTokenRef,
      onSignIn,
      setAuthenticated,
      t,
      usernameRef,
    ]
  );

  return (
    <FormProvider {...form}>
      <FormView
        title={translations?.login_welcome_title}
        subtitle={translations?.login_welcome_subtitle}
      >
        <form
          className="analog-auth__common-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormElement
            label={t('label.email')}
            message={formState.errors.email?.message}
          >
            <InputControl
              name="email"
              size="l"
              placeholder={t('placeholder.email')}
              invalid={!!formState.errors.email}
              autoFocus={!emailParam}
            />
          </FormElement>

          <FormElement
            label={t('label.password')}
            message={formState.errors.password?.message}
          >
            <InputPasswordControl
              name="password"
              size="l"
              placeholder={t('placeholder.password')}
              invalid={!!formState.errors.password}
              autoFocus={!!emailParam}
            />
          </FormElement>

          {message && (
            <div className="analog-auth-common-form__message">
              <Message text={message} variant="error" />
            </div>
          )}

          {allowResetPassword && (
            <div className="sign-in-form__extras">
              {allowResetPassword && (
                <Link
                  to={`/${ROUTES.AUTH}/${ROUTES.FORGOT_PASSWORD}`}
                  replace={true}
                  className="sign-in-form__forgot-password-button analog-typography--button-m"
                >
                  {t('label.forgot_password')}
                </Link>
              )}
            </div>
          )}

          <div className="analog-auth__common-form-actions">
            <Button
              type="submit"
              size="l"
              content={t('action.login')}
              fullWidth
              loading={loading}
            />
          </div>
        </form>

        {(allowSignUp || allowWaitList) && (
          <div className="analog-auth__common-footer">
            <div className="analog-auth__common-footer-message analog-typography--button-m">
              <span>
                {translations?.login_footer_text ||
                  t('label.no_account_message')}
              </span>
              <Link
                to={
                  allowSignUp
                    ? `/${ROUTES.AUTH}/${ROUTES.SIGN_UP}`
                    : `/${ROUTES.AUTH}/${ROUTES.WAITLIST}`
                }
                replace={true}
                className="analog-auth__common-footer-button"
              >
                {translations?.login_footer_link ||
                  (allowSignUp
                    ? t('label.create_account_message')
                    : t('label.join_waitlist'))}
              </Link>
            </div>
          </div>
        )}
      </FormView>
    </FormProvider>
  );
};
