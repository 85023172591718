import { Environment, getEnvironment } from './getEnvironment';

export const Logger = {
  default(...data: any[]): void {
    if (getEnvironment() !== Environment.Production) {
      console.log(data);
    }
  },

  info(...data: any[]): void {
    if (getEnvironment() !== Environment.Production) {
      console.log(`%c${data}`, 'color: #00BFFF');
    }
  },

  success(...data: any[]): void {
    if (getEnvironment() !== Environment.Production) {
      console.log(`%c${data}`, 'color: #80E27E');
    }
  },

  warning(...data: any[]): void {
    if (getEnvironment() !== Environment.Production) {
      console.log(`%c${data}`, 'color: #FFA726');
    }
  },

  error(...data: any[]): void {
    if (getEnvironment() !== Environment.Production) {
      console.log(`%c${data}`, 'color: #FF5252');
    }
  },

  purple(...data: any[]): void {
    if (getEnvironment() !== Environment.Production) {
      console.log(`%c${data}`, 'color: #C084FC');
    }
  },
};
