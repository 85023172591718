import { ALL_FILTER_OPTION_ID } from './index';
import { IDashboardFilters } from '../pages/Dashboard/types';
import { DateGroupBy, DateRange, Discipline } from '../types/enums';
import { IAthletesFilters } from '../types/athletes';
import { IGroupsFilters } from '../types/groups';
import { IPerformanceReportFilters } from '../pages/PerformanceReport/types';
import { format, startOfWeek, subWeeks } from 'date-fns';
import { IMedicalReportsFilters } from '../pages/MedicalReports/types';
import { IAdminTeamMemberFilters } from '../pages/AdminPanel/types';
import { IGroupReportsFilters } from '../pages/GroupDetails/types';
import { IAthleteDetailsFilters } from '../pages/AthleteDetails/types';
import { IPinnedTabsFilters } from '../pages/PinnedTab/types';
import { IAthleteProfileFilters } from '../pages/AthleteProfile/types';
import { TrainingZoneKeys } from '../pages/AthleteProfile/utils/getDefaults';
import { ICalendarFilters } from '../pages/Workouts/types';

export type IPageFilters = {
  dashboard: IDashboardFilters;
  athletes: IAthletesFilters;
  groups: IGroupsFilters;
  performanceReport: IPerformanceReportFilters;
  medicalReports: IMedicalReportsFilters;
  adminTeamMembers: IAdminTeamMemberFilters;
  group: IGroupReportsFilters;
  athleteDetails: IAthleteDetailsFilters;
  athleteProfile: IAthleteProfileFilters;
  pinnedTabs: IPinnedTabsFilters;
  calendar: ICalendarFilters;
};

export const getDefaultPageFilters = (): IPageFilters => {
  const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const previousWeekStart = subWeeks(currentWeekStart, 1);
  const week = format(previousWeekStart, 'w');
  const year = format(previousWeekStart, 'RRRR');

  return {
    dashboard: {
      groupId: ALL_FILTER_OPTION_ID,
      period: DateRange.ThreeMonth,
    },
    athletes: {
      search: '',
    },
    groups: {
      search: '',
    },
    performanceReport: {
      athletes: '',
      search: '',
      week: `${week}_${year}`,
    },
    medicalReports: {
      search: '',
    },
    adminTeamMembers: {
      search: '',
    },
    group: {
      athletes: '',
      search: '',
      period: DateRange.ThreeMonth,
    },
    athleteDetails: {
      period: DateRange.ThreeMonth,
      dateGroupBy: DateGroupBy.Day,
    },
    athleteProfile: {
      period: DateRange.ThreeMonth,
      discipline: Discipline.Cycling,
      p1: TrainingZoneKeys.CyclingPowerMFTP,
      p2: TrainingZoneKeys.CyclingHeartRateDefault,
    },
    pinnedTabs: {
      period: DateRange.ThreeMonth,
    },
    calendar: {
      date: format(new Date(), 'yyyy-MM-dd'),
    },
  };
};
