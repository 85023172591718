import './index.css';
import {
  Button,
  IExtendSelectProps,
  ISelectOption,
  Select,
} from '@cycling-web/analog-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '../../../types/enums';
import { Plus } from 'lucide-react';
import { CustomDatesModal } from './CustomDatesModal';
import { useFiltersStore } from '../../../store/filters/slice';
import { ICustomPeriodFilter } from '../../../types/filters';
import { PeriodSelectOption } from './PeriodSelectOption';
import { FiltersRepository } from '../../../api/filters/repository';
import { FiltersService } from '../../../api/filters/service';
import { trackUserInteractionEvent } from '../../../ms/log-insights';
import { TrackingEvent, TrackingModal } from '../../../ms/tracking-entities';

type IProps = {
  days?: string[];
  value: string;
  onChange: (value: string) => void;
  selectProps?: IExtendSelectProps;
};

export const LastNDaysFilter = ({
  value,
  onChange,
  days = [
    DateRange.Week,
    DateRange.Month,
    DateRange.TwoMonth,
    DateRange.ThreeMonth,
    DateRange.Year,
  ],
  selectProps,
}: IProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const seasons = useFiltersStore((s) => s.seasons);
  const customPeriods = useFiltersStore((s) => s.customPeriods);

  const [customPeriodForEdit, setCustomPeriodForEdit] = useState<
    ICustomPeriodFilter | undefined | null
  >(null);

  const openCustomDatesModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
      modal: TrackingModal.CUSTOM_PERIOD,
    });

    setCustomPeriodForEdit(undefined);
  }, []);

  const dismissCustomDatesModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.CUSTOM_PERIOD,
    });

    setCustomPeriodForEdit(null);
  }, []);

  const defaultRangeMap: Record<DateRange, number> = useMemo(() => {
    return {
      [DateRange.Week]: 7,
      [DateRange.Month]: 30,
      [DateRange.TwoMonth]: 60,
      [DateRange.ThreeMonth]: 90,
      [DateRange.Year]: 365,
    };
  }, []);

  const options: ISelectOption[] = useMemo(() => {
    const options: ISelectOption[] = days.map((d: string) => {
      return {
        id: d,
        text:
          d === DateRange.Week.toString()
            ? t('label.last_week')
            : t('label.last_n_days', { d: defaultRangeMap[d] }),
      };
    });

    if (seasons.length > 0) {
      options.push({
        id: 'seasons-divider',
        text: '',
        type: 'divider',
      });

      seasons.forEach((season: number) => {
        options.push({
          id: `season_${season}`,
          text: `${t('label.season')} ${season}`,
        });
      });
    }

    if (customPeriods.length > 0) {
      options.push({
        id: 'custom-divider',
        text: '',
        type: 'divider',
      });

      customPeriods.forEach((period: ICustomPeriodFilter) => {
        options.push({
          id: period.id.toString(),
          text: period.filterName,
        });
      });
    }

    return options;
  }, [days, seasons, customPeriods, t, defaultRangeMap]);

  const handleChange = useCallback(
    (option: ISelectOption) => {
      onChange(option.id);
    },
    [onChange]
  );

  const handleOpenCustomDatesModal = useCallback(() => {
    setDropdownOpen(false);
    openCustomDatesModal();
  }, [openCustomDatesModal]);

  const footerJSX = (
    <Button
      size="s"
      variant="quiet"
      content={t('action.add_custom_filter')}
      startIcon={<Plus />}
      onClick={handleOpenCustomDatesModal}
    />
  );

  const onEdit = useCallback(
    (option: ISelectOption) => {
      setDropdownOpen(false);
      setCustomPeriodForEdit(
        customPeriods.find((p) => p.id.toString() === option.id)
      );
    },
    [customPeriods]
  );

  const onDelete = useCallback(
    (option: ISelectOption) => {
      const filtersRepository = new FiltersRepository(new FiltersService());
      const id = Number(option.id);

      if (value === option.id) {
        onChange(DateRange.ThreeMonth);
      }

      filtersRepository
        .deleteCustomPeriodFilter({
          id,
        })
        .then(() => {
          useFiltersStore.getState().deleteCustomPeriod(id);
        });
    },
    [onChange, value]
  );

  const handleSubmitNewPeriod = useCallback(
    (period: ICustomPeriodFilter) => {
      onChange(period.id.toString());
    },
    [onChange]
  );

  const onRenderOption = useCallback(
    (option: ISelectOption) => {
      const isCustom =
        !option.id.toString().includes('season_') &&
        defaultRangeMap[option.id] === undefined;

      return (
        <PeriodSelectOption
          option={option}
          onEdit={isCustom ? onEdit : undefined}
          onDelete={isCustom ? onDelete : undefined}
        />
      );
    },
    [defaultRangeMap, onDelete, onEdit]
  );

  return (
    <div className="last-n-days-filter">
      <Select
        options={options}
        value={options.find((o: ISelectOption) => o.id === value)}
        onChange={handleChange}
        placeholder={t('placeholder.last_n_days')}
        footer={footerJSX}
        {...selectProps}
        dropdownProps={{
          isOpen: isDropdownOpen,
          setIsOpen: setDropdownOpen,
          minWidth: '194px',
          maxHeight: '270px',
          contentClassName: 'last-n-days-filter__dropdown',
          placement: 'bottom-end',
          ...selectProps?.dropdownProps,
        }}
        onRenderOption={onRenderOption}
      />
      {customPeriodForEdit !== null && (
        <CustomDatesModal
          onDismiss={dismissCustomDatesModal}
          onSubmitSuccess={handleSubmitNewPeriod}
          filter={customPeriodForEdit}
        />
      )}
    </div>
  );
};
