import './index.css';
import {
  FormElement,
  IconButton,
  Message,
  Tooltip,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { InputControl } from '@cycling-web/common';
import { ColorControl } from '../../../../../../components/controls/ColorControl';
import { Trash2 } from 'lucide-react';
import { useFormState } from 'react-hook-form';
import { IZonesFormData } from '../../../../types';

type IProps = {
  index: number;
  removeZone: () => void;
};

export const ZoneControl = ({ index, removeZone }: IProps) => {
  const { t } = useTranslation();
  const { errors } = useFormState<IZonesFormData>();
  const errorMessage = errors?.zones?.[index]?.to?.message || '';

  return (
    <div className="training-zones-form__zone">
      <div className="training-zones-form__zone-inner">
        <FormElement
          className="training-zones-form__zone-name"
          label={t('label.zone')}
        >
          <InputControl
            name={`zones.${index}.name`}
            placeholder={t('placeholder.name')}
            autoComplete="off"
          />
        </FormElement>

        <ColorControl
          name={`zones.${index}.color`}
          className="training-zones-form__zone-color"
        />

        <FormElement
          className="training-zones-form__zone-range"
          label={t('label.from')}
        >
          <InputControl
            name={`zones.${index}.from`}
            placeholder="0"
            autoComplete="off"
          />
        </FormElement>

        <FormElement
          className="training-zones-form__zone-range"
          label={t('label.to')}
        >
          <InputControl
            name={`zones.${index}.to`}
            placeholder="0"
            autoComplete="off"
          />
        </FormElement>

        <FormElement label="">
          <Tooltip
            anchor={
              <IconButton
                variant="quietLayer3"
                content={<Trash2 />}
                onClick={removeZone}
              />
            }
            content={t('tooltip.delete', {
              what: ` ${t('label.zone').toLowerCase()}`,
            })}
            placement="bottom-end"
          />
        </FormElement>
      </div>
      {errorMessage && <Message text={errorMessage} variant="error" />}
    </div>
  );
};
