import { parseMessage } from './parseMessage';
import { MessagePart } from './MessagePart';

type IProps = {
  text: string;
};

export const ContentText = ({ text }: IProps) => {
  const parts = text ? parseMessage(text) : [];
  const partsJSX = parts.map((part, index: number) => {
    return <MessagePart key={`wrapper-${index}`} index={index} part={part} />;
  });

  return <div className="conversation-message__text">{partsJSX}</div>;
};
