import { FileUploadButton } from '@cycling-web/common';
import {
  FormElement,
  IconButton,
  Spinner,
  TruncatedText,
  Typography,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { IEventForm } from './types';
import { MouseEvent, useCallback, useState } from 'react';
import { PaperclipIcon, XIcon } from 'lucide-react';

type IProps = {
  hasFile: boolean;
  onChange: (f: File) => void;
  onClear: () => void;
  loading: boolean;
};

export const GpxFileInput = ({
  hasFile,
  onChange,
  onClear,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<IEventForm>();
  const [file, setFile] = useState<File | null>(null);

  const onGPXChange = useCallback(
    (file: File, base64: string) => {
      setFile(file);
      onChange(file);
    },
    [onChange]
  );

  const onGPXClear = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setFile(null);
      onClear();
    },
    [onClear]
  );

  return (
    <FormElement label={t('label.gpx_file')}>
      <FileUploadButton
        inputProps={{ accept: '.gpx', disabled: loading }}
        onChange={onGPXChange}
      >
        <div className="event-form__gpx-file-input">
          {hasFile || file ? (
            <TruncatedText text={file?.name || t('label.gpx_file')} />
          ) : (
            <Typography text={t('placeholder.gpx_file')} />
          )}
          <span className="event-form__gpx-file-actions">
            {!loading && hasFile && (
              <IconButton
                variant="layer2"
                size="s"
                content={<XIcon />}
                onClick={onGPXClear}
              />
            )}
            {loading && (
              <div className="event-form__gpx-file-input-spinner">
                <Spinner size="s" />
              </div>
            )}
            <span className="event-form__gpx-file-input-icon">
              <PaperclipIcon size={16} />
            </span>
          </span>
        </div>
      </FileUploadButton>
    </FormElement>
  );
};
