import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useSmartChartsContext } from '../../context';
import { ConversationInput } from '../../../../components/Conversation';

export const Footer = () => {
  const { t } = useTranslation();
  const { sendMessage, sendAudioMessage } = useSmartChartsContext();

  return (
    <div className="sc2__footer">
      <ConversationInput
        onSendText={sendMessage}
        onSendAudio={sendAudioMessage}
        autofocus
      />
      <div className="sc2__footer-note">
        <Typography
          variant="caption"
          weight="light"
          text={t('smart_charts.label.footer_text')}
        />
      </div>
    </div>
  );
};
