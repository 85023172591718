import './index.css';
import { use, useCallback, useEffect, useMemo, useState } from 'react';
import { ZonesModalContext } from '../../context';
import {
  Divider,
  InputNumber,
  ISegmentButton,
  Segment,
  Typography,
} from '@cycling-web/analog-ui';
import { Discipline } from '../../../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { AthletesRepository } from '../../../../../../api/athletes/repository';
import { AthletesService } from '../../../../../../api/athletes/service';
import { useAthleteStore } from '../../../../../../store/athlete/slice';
import { AthleteProfileRepository } from '../../../../../../api/athletes-profiles/repository';
import { AthleteProfileService } from '../../../../../../api/athletes-profiles/service';
import {
  IAthleteProfile,
  TrainingZoneMethod,
  TrainingZoneParameter,
} from '../../../../../../types/athlete-profiles';
import {
  getProfileParameter,
  TrainingZoneKeys,
} from '../../../../utils/getDefaults';
import { useAthletesStore } from '../../../../../../store/athletes/slice';
import { parseAthleteProfiles } from '../../../../utils/profiles';
import { useFormContext } from 'react-hook-form';
import { IAthlete } from '../../../../../../types/athletes';
import { ThresholdMatching } from './ThresholdMatching';
import { McpUtils } from '../../../../../../utils/mcp';
import { trackUserInteractionEvent } from '../../../../../../ms/log-insights';
import {
  FallbackValue,
  TrackingEvent,
} from '../../../../../../ms/tracking-entities';

export const ZonesModalFilters = () => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const {
    composeKey,
    setComposeKey,
    threshold,
    currentProfile,
    defaultFormAthleteProfilesRef,
    setFormAthleteProfiles,
    openDiscardDialog,
    resetToDefault,
  } = use(ZonesModalContext);

  const [thresholdValue, setThresholdValue] = useState<string>('');

  useEffect(() => {
    if (composeKey === TrainingZoneKeys.CyclingPowerMCP) {
      setThresholdValue(
        threshold ? McpUtils.computeCPFromFTP(Number(threshold)).toString() : ''
      );
    } else {
      setThresholdValue(threshold ?? '');
    }
  }, [composeKey, threshold]);

  const [loading, setLoading] = useState<boolean>(false);
  const athlete = useAthleteStore((s) => s.athlete);
  const athleteRepository = useMemo(() => {
    return new AthletesRepository(new AthletesService());
  }, []);

  const athleteProfileRepository = useMemo(() => {
    return new AthleteProfileRepository(new AthleteProfileService());
  }, []);

  const showSegment = composeKey.includes(
    `${Discipline.Cycling}_${TrainingZoneParameter.Power}`
  );

  const text: Record<string, any> = {
    [TrainingZoneKeys.CyclingPowerMFTP]: {
      placeholder: '0',
      units: t('units.w'),
    },
    [TrainingZoneKeys.CyclingPowerMCP]: {
      placeholder: '0',
      units: t('units.w'),
    },
    [TrainingZoneKeys.CyclingHeartRateDefault]: {
      placeholder: '0',
      units: t('units.hr'),
    },
    [TrainingZoneKeys.SwimmingCSSDefault]: {
      placeholder: '0',
      units: t('units.sec_100'),
    },
    [TrainingZoneKeys.SwimmingHeartRateDefault]: {
      placeholder: '0',
      units: t('units.hr'),
    },
    [TrainingZoneKeys.RunningPaceDefault]: {
      placeholder: '0',
      units: t('units.min_km'),
    },
    [TrainingZoneKeys.RunningHeartRateDefault]: {
      placeholder: '0',
      units: t('units.hr'),
    },
  };

  const buttons = useMemo((): ISegmentButton[] => {
    return [
      {
        id: TrainingZoneKeys.CyclingPowerMFTP,
        text: t('label.ftp'),
      },
      {
        id: TrainingZoneKeys.CyclingPowerMCP,
        text: t('label.cp'),
      },
    ];
  }, [t]);

  const onChangeMethod = useCallback(
    (index: number) => {
      if (formState.isDirty) {
        openDiscardDialog({
          submitAction: () => {
            setComposeKey(buttons[index].id as TrainingZoneMethod);
            return resetToDefault();
          },
        });
      } else {
        setComposeKey(buttons[index].id as TrainingZoneMethod);
      }

      trackUserInteractionEvent(TrackingEvent.FILTER, {
        param: buttons[index].id || FallbackValue.Unknown,
      });
    },
    [
      buttons,
      formState.isDirty,
      openDiscardDialog,
      setComposeKey,
      resetToDefault,
    ]
  );

  const onThresholdChange = useCallback((value: string) => {
    setThresholdValue(value);
  }, []);

  const onThresholdBlur = useCallback(
    (manualThreshold?: string) => {
      let _threshold = manualThreshold || thresholdValue;
      if (!athlete || _threshold === '' || !currentProfile) {
        return;
      }
      setLoading(true);

      let key = getProfileParameter(composeKey);

      if (key === 'mcp') {
        key = 'ftp';
      }

      if (composeKey === TrainingZoneKeys.CyclingPowerMCP) {
        _threshold = McpUtils.computeFTPFromCP(Number(_threshold)).toString();
      }

      if (athlete[key]?.toString() === _threshold?.toString()) {
        setLoading(false);
        return Promise.resolve();
      }

      return athleteRepository
        .updateProfile({
          ...athlete,
          [key]: Number(_threshold),
        })
        .then((response) => {
          setLoading(false);

          const nextAthlete: Partial<IAthlete> = {
            id: response.id,
            [key]: response[key],
          };

          if (key === 'ftp') {
            nextAthlete.sftp = response[key];
          }

          useAthletesStore.getState().updateAthlete(nextAthlete);
          useAthleteStore.getState().updateAthlete(nextAthlete);

          if (currentProfile.custom) {
            return Promise.resolve();
          }

          return athleteProfileRepository
            .getAthleteProfiles({
              athleteId: athlete.id,
            })
            .then((data: IAthleteProfile[]) => {
              const parsed = parseAthleteProfiles(data);
              defaultFormAthleteProfilesRef.current = parsed;
              setFormAthleteProfiles(parsed);
            });
        });
    },
    [
      athlete,
      athleteProfileRepository,
      athleteRepository,
      composeKey,
      currentProfile,
      defaultFormAthleteProfilesRef,
      setFormAthleteProfiles,
      thresholdValue,
    ]
  );

  const resetThreshold = useCallback(
    (value: string) => {
      if (loading) {
        return;
      }

      setThresholdValue(value);
      onThresholdBlur(value);
    },
    [loading, onThresholdBlur]
  );

  const isHR =
    composeKey === TrainingZoneKeys.CyclingHeartRateDefault ||
    composeKey === TrainingZoneKeys.SwimmingHeartRateDefault ||
    composeKey === TrainingZoneKeys.RunningHeartRateDefault;

  return (
    <div className="training-zones-form-modal__filters">
      <div className="training-zones-form-modal__filters-inner">
        <div className="training-zones-form-modal__filters-left">
          <Typography
            text={t('label.threshold')}
            className="training-zones-form-modal__filters-label"
          />
          <InputNumber
            value={thresholdValue ?? ''}
            onChange={onThresholdChange}
            onBlur={() => onThresholdBlur()}
            className="training-zones-form-modal__filters-input"
            placeholder={text[composeKey].placeholder}
            loading={loading}
            decimalPlaces={isHR ? 0 : 3}
            endAdornment={
              <div className="training-zones-form-modal__filters-input-adornment analog-typography--subtitle">
                {text[composeKey].units}
              </div>
            }
          />
          <ThresholdMatching composeKey={composeKey} onReset={resetThreshold} />
        </div>
        <div className="training-zones-form-modal__filters-right">
          {showSegment && (
            <Segment
              buttons={buttons}
              activeButton={buttons.findIndex((b) => {
                return b.id === composeKey;
              })}
              onChange={onChangeMethod}
            />
          )}
        </div>
      </div>

      <Divider />
    </div>
  );
};
