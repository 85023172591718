import './index.css';
import { useSmartChartsContext } from '../../context';
import {
  AvatarStack,
  IAvatarStackAvatar,
  IconButton,
  Tooltip,
} from '@cycling-web/analog-ui';
import { IAthlete } from '../../../../types/athletes';
import { Pencil } from 'lucide-react';
import { useUserRole } from '../../../../hooks/useUserRole';
import { Athlete } from '../../../../components/columns/Athlete';
import { useTranslation } from 'react-i18next';

export const SelectedAthletes = () => {
  const { t } = useTranslation();
  const { selectedAthletes, openAthletesModal } = useSmartChartsContext();
  const { isAthlete } = useUserRole();

  if (selectedAthletes.length === 0) {
    return null;
  }

  const avatarsProps: IAvatarStackAvatar[] = selectedAthletes.map(
    (a: IAthlete) => {
      return {
        text: a.fullName,
        src: a.picture,
      };
    }
  );

  return (
    <div className="sc2__selected-athletes">
      {selectedAthletes.length === 1 ? (
        <Athlete athlete={selectedAthletes[0]} avatarProps={{ size: 'm' }} />
      ) : (
        <AvatarStack size="m" avatars={avatarsProps} />
      )}
      {!isAthlete && (
        <div className="sc2__selected-athletes-actions">
          <Tooltip
            anchor={
              <IconButton
                variant="quietLayer3"
                content={<Pencil />}
                onClick={openAthletesModal}
              />
            }
            content={t('tooltip.edit_athletes')}
          />
        </div>
      )}
    </div>
  );
};
