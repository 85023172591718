import './index.css';
import { useTranslation } from 'react-i18next';
import { PerformanceReportTabs } from './components/PerformanceReportTabs';
import { Outlet } from 'react-router';
import { Page } from '@cycling-web/common';
import { useEffect } from 'react';
import { usePerformanceReportStore } from './store/slice';
import { useTitle } from '../../hooks/useTitle';
import { PageFiltersContextProvider } from '../../context/PageFilters';
import { useFetchWeeklyPerformanceReports } from './hooks/useFetchWeeklyPerformanceReports';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { useMobileMenu } from '../../components/MobileMenu';

export const PerformanceReportView = () => {
  const { t } = useTranslation();
  useTitle(t('label.performance_report'));
  useFetchWeeklyPerformanceReports();

  const onRenderMenu = useMobileMenu();
  const reset = usePerformanceReportStore((s) => s.reset);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Page
      pageHeaderProps={{ title: t('label.performance_report'), onRenderMenu }}
    >
      <PerformanceReportTabs />
      <Outlet />
    </Page>
  );
};

export const PerformanceReport = () => {
  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().performanceReport}
    >
      <PerformanceReportView />
    </PageFiltersContextProvider>
  );
};
