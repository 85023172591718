import './index.css';
import { IAnaAdminContent } from '../../../types';
import { ChevronDown } from 'lucide-react';
import { Typography, useBoolean } from '@cycling-web/analog-ui';
import { clsx } from 'clsx';

type IProps = {
  contents: IAnaAdminContent[];
};

export const AdminMessage = ({ contents }: IProps) => {
  const {
    value: showMessage,
    setTrue: openMessage,
    setFalse: dismissMessage,
    toggle: toggleMessage,
  } = useBoolean(false);

  if (contents.length === 0) {
    return null;
  }

  return (
    <div className="conversation-message__admin-content">
      <button
        className="conversation-message__admin-content-toggle analog-typography--subtitle"
        onClick={toggleMessage}
      >
        System message
        <ChevronDown
          size={14}
          className={clsx(
            'conversation-message__admin-content-icon',
            showMessage && 'open'
          )}
        />
      </button>
      {showMessage && (
        <div className="conversation-message__admin-content-text">
          {contents.map((content, i) => {
            return (
              <pre className="analog-typography--subtitle">{content.text}</pre>
            );
          })}
        </div>
      )}
    </div>
  );
};
