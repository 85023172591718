import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { IPerformanceReport } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { usePerformanceReportStore } from '../store/slice';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IPerformanceReportFilters } from '../types';

export const useFetchWeeklyPerformanceReports = () => {
  const { t } = useTranslation();
  const { filters } = usePageFilters<IPerformanceReportFilters>();

  const setReports = usePerformanceReportStore((s) => s.setReports);
  const setReportsLoaded = usePerformanceReportStore((s) => s.setReportsLoaded);

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!filters.week) {
      return;
    }

    const [week, year] = filters.week.split('_');
    const p = {
      week,
      year,
    };

    performanceRepository
      .getPerformanceReport(p)
      .then((reports: IPerformanceReport[]) => {
        setReports(reports);
      })
      .catch((error: AxiosError) => {
        setReportsLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_weekly_performance_report_title'),
              message: t('error.get_weekly_performance_report_message'),
            },
            { toastId: 'get_weekly_performance_report' }
          );
        }
      });
  }, [filters.week, performanceRepository, setReports, setReportsLoaded, t]);
};
