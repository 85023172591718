import './index.css';
import {
  IPerformanceReport,
  IReportFeedbackForm,
} from '../../../../types/performance';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog, EmptyState, Toast } from '@cycling-web/analog-ui';
import { CoachRepository } from '../../../../api/coach/repository';
import { CoachService } from '../../../../api/coach/service';
import { ReportFeedbackForm } from './Form';
import { usePerformanceReportStore } from '../../../PerformanceReport/store/slice';
import { getMonthFromWeek } from '../../../../utils/date-time';
import { useUsersStore } from '../../../../store/users/slice';
import { FeedbackAthleteCard } from '../FeedbackAthleteCard';
import { CheckCircle2 } from 'lucide-react';
import { usePageFilters } from '../../../../context/PageFilters';
import { IPerformanceReportFilters } from '../../../PerformanceReport/types';

type IProps = {
  report: IPerformanceReport;
  reports: IPerformanceReport[];
  singleReport: boolean;
  onDismiss: () => void;
};

export const FeedbackFormModal = ({
  report,
  reports,
  onDismiss,
  singleReport,
}: IProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const userProfile = useUsersStore((s) => s.userProfile);
  const coachId = userProfile?.coachId as number;
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [currentReport, setCurrentReport] =
    useState<IPerformanceReport>(report);
  const index = useMemo(() => {
    return reports.findIndex(
      (r: IPerformanceReport) => r.athleteId === currentReport.athleteId
    );
  }, [currentReport, reports]);

  const { filters } = usePageFilters<IPerformanceReportFilters>();

  const { week, month, year } = useMemo(() => {
    const [week, year] = filters.week.split('_');
    const month = getMonthFromWeek(+week, +year);
    return { week: +week, month, year: +year };
  }, [filters.week]);

  const coachRepository = useMemo(() => {
    return new CoachRepository(new CoachService());
  }, []);

  const schema = useMemo(() => {
    return z
      .object({
        performanceRating: z
          .string()
          .min(1, { message: t('validation.required') })
          .transform((v) => Number(v))
          .refine((v) => v > 0 && v <= 10, {
            message: t('validation.out_of_range', { min: 1, max: 10 }),
          }),
        comments: z.string().min(1, { message: t('validation.required') }),
        nextRace: z.string().min(1, { message: t('validation.required') }),
      })
      .passthrough();
  }, [t]);

  const getFormValues = useCallback(
    (report: IPerformanceReport) => {
      return {
        coachId,
        athleteId: report.athleteId,
        performanceRating: report.coachRating.toString(),
        nextRace: report.nextRace,
        comments: report.comments,
        createdAt: new Date().toISOString(),
        updatedAt: '',
        year,
        week,
      };
    },
    [coachId, week, year]
  );

  const form = useForm<IReportFeedbackForm>({
    defaultValues: getFormValues(currentReport),
    resolver: zodResolver(schema),
  });

  const { handleSubmit, reset } = form;

  const handleSave = useCallback(
    (formData: IReportFeedbackForm) => {
      setLoading(true);

      coachRepository
        .saveFeedback(formData)
        .then(() => {
          usePerformanceReportStore.getState().updateReport({
            athleteId: formData.athleteId,
            coachRating: +formData.performanceRating,
            comments: formData.comments,
            nextRace: formData.nextRace,
          });

          if (singleReport) {
            onDismiss();
            return;
          }

          const nextReport = reports[index + 1];
          if (nextReport) {
            setCurrentReport(nextReport);
            reset(getFormValues(nextReport));
          } else {
            setShowCompleted(true);
          }
        })
        .catch(() => {
          Toast.error(
            {
              title: t('error.save_report_feedback_title'),
              message: t('error.save_report_feedback_message'),
            },
            { toastId: 'save_report_feedback_title' }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      coachRepository,
      getFormValues,
      index,
      onDismiss,
      reports,
      reset,
      setCurrentReport,
      t,
      singleReport,
    ]
  );

  const onSubmit = useCallback(() => {
    handleSubmit(
      (formData: IReportFeedbackForm) => {
        handleSave(formData);
      },
      (errors) => {
        console.log(errors);
      }
    )();
  }, [handleSave, handleSubmit]);

  const subtitleJSX = !singleReport && !showCompleted && (
    <div className="report-feedback-form-modal__header analog-typography--headline bold">
      <span className="report-feedback-form-modal__header-current">
        {index + 1}
      </span>{' '}
      {t('prepositions.of')} {reports.length}
    </div>
  );

  const handlePreviousReport = useCallback(() => {
    const nextReport = reports[index - 1];
    if (nextReport) {
      setCurrentReport(nextReport);
      reset(getFormValues(nextReport));
    }
  }, [getFormValues, index, reports, reset, setCurrentReport]);

  const onRenderFooter = useCallback(() => {
    if (showCompleted) {
      return null;
    }
    return (
      <footer className="report-feedback-form-modal__footer">
        {!singleReport && index !== 0 && (
          <Button
            variant="secondary"
            content={t('action.previous')}
            onClick={handlePreviousReport}
          />
        )}
        <div className="report-feedback-form-modal__footer-right">
          <Button
            variant="primary"
            content={t('action.save')}
            loading={loading}
            onClick={onSubmit}
          />
        </div>
      </footer>
    );
  }, [
    showCompleted,
    singleReport,
    index,
    t,
    handlePreviousReport,
    loading,
    onSubmit,
  ]);

  return (
    <FormProvider {...form}>
      <Dialog
        title={t('label.weekly_report_w_m', { week: `W${week}`, month })}
        subtitle={subtitleJSX}
        onDismiss={onDismiss}
        outsidePress={false}
        submitButtonProps={{
          content: t('action.save'),
          onClick: onSubmit,
          loading: loading,
        }}
        onRenderFooter={onRenderFooter}
      >
        <div className="report-feedback-form-modal">
          {showCompleted ? (
            <EmptyState
              icon={
                <CheckCircle2
                  size={72}
                  className="report-feedback-form-modal-icon"
                />
              }
              title={t('label.weekly_report_completed')}
              buttonProps={{
                content: t('action.done'),
                onClick: onDismiss,
              }}
            />
          ) : (
            <div className="report-feedback-form-modal__main">
              <FeedbackAthleteCard report={currentReport} />
              <ReportFeedbackForm />
            </div>
          )}
        </div>
      </Dialog>
    </FormProvider>
  );
};
