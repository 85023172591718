import { IMentionOption } from '../components/Conversation/components/ConversationTextEditor/mention/types';
import {
  IConversationEntity,
  ITaggedEntity,
  ITaggedEntityMeta,
  ITaggedEntityMetaType,
  ITaggedEntityUserEntryPoint,
} from '../types/smart-charts';
import { useGroupsStore } from '../store/groups/slice';
import { useAthletesStore } from '../store/athletes/slice';
import {
  IAnaContextEntity,
  IAnaContextEntityMetaData,
  IAnaContextType,
} from '../components/Conversation';

type IPrepareTaggedEntitiesProps = {
  mentions: IMentionOption[];
  isNew: boolean;
};

export const prepareTaggedEntities = ({
  mentions = [],
  isNew,
}: IPrepareTaggedEntitiesProps): ITaggedEntity[] => {
  return mentions.map((o) => {
    const user_entry_point = isNew
      ? ITaggedEntityUserEntryPoint.New
      : ITaggedEntityUserEntryPoint.Existing;

    const entity_metadata: ITaggedEntityMeta =
      o.entity === 'athlete'
        ? {
            type: ITaggedEntityMetaType.Athlete,
            athlete_id: o.id.toString(),
            athlete_full_name: o.text,
          }
        : {
            type: ITaggedEntityMetaType.Group,
            group_id: o.id.toString(),
            group_name: o.text,
          };

    let linked_tagged_entities: ITaggedEntity[] | null = null;

    if (o.entity === 'group') {
      const groups = useGroupsStore.getState().groups;
      const group = groups.find((g) => o.id.toString() === g.id.toString());
      const athletesMap = useAthletesStore.getState().athletesMap;

      if (group) {
        group.athleteIds.forEach((id: number) => {
          const athlete = athletesMap[id];

          if (athlete) {
            if (!linked_tagged_entities) {
              linked_tagged_entities = [];
            }

            linked_tagged_entities.push({
              entity_type: IConversationEntity.Athlete,
              entity_id: athlete.id.toString(),
              entity_metadata: {
                type: ITaggedEntityMetaType.Athlete,
                athlete_id: athlete.id.toString(),
                athlete_full_name: athlete.fullName,
              },
              user_entry_point,
              user_action: 'add',
              linked_tagged_entities: null,
            });
          }
        });
      }
    }

    return {
      entity_type:
        o.entity === 'athlete'
          ? IConversationEntity.Athlete
          : IConversationEntity.Group,
      entity_id: o.id.toString(),
      user_entry_point,
      user_action: 'add',
      entity_metadata,
      linked_tagged_entities,
    };
  });
};

// -----------------------------------------------------------------------------
// Websockets

type IPrepareAnaContextEntitiesProps = {
  mentions: IMentionOption[];
};

export const prepareAnaContextEntities = ({
  mentions = [],
}: IPrepareAnaContextEntitiesProps): IAnaContextEntity[] => {
  const athletesMap = useAthletesStore.getState().athletesMap;
  const groups = useGroupsStore.getState().groups;

  return mentions.map((o) => {
    let entity: IAnaContextEntity;

    if (o.entity === 'athlete') {
      const athlete = athletesMap[o.id];
      entity = {
        entity_metadata: {
          context_type: IAnaContextType.ATHLETE,
          athlete_id: Number(o.id),
          athlete_full_name: o.text || athlete?.fullName || '',
        },
        user_action: 'add',
      };
    } else {
      const group = groups.find((g) => g.id.toString() === o.id.toString());

      const linked_entities: IAnaContextEntityMetaData[] =
        group?.athleteIds
          .map((id) => {
            const athlete = athletesMap[id];
            return athlete
              ? {
                  context_type: IAnaContextType.ATHLETE,
                  athlete_id: Number(athlete.id),
                  athlete_full_name: athlete.fullName,
                }
              : null;
          })
          .filter((e): e is IAnaContextEntityMetaData => e !== null) || [];

      entity = {
        entity_metadata: {
          context_type: IAnaContextType.GROUP,
          group_id: Number(o.id),
          group_name: o.text || group?.name || '',
          linked_entities:
            linked_entities.length > 0 ? linked_entities : undefined,
        },
        user_action: 'add',
      };
    }

    return entity;
  });
};
