import { AthleteProfileService } from './service';
import { AxiosRequestConfig } from 'axios';
import { BaseRepository, IRepositoryOptions } from '@cycling-web/common';
import { IAthleteProfile } from '../../types/athlete-profiles';
import {
  IGetAthleteProfilesRequest,
  ISaveAthleteProfilesRequest,
  ISaveAthleteProfilesResponse,
} from './types';

export interface IAthleteProfileRepository {
  getAthleteProfiles: (
    p: IGetAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ) => Promise<IAthleteProfile[]>;

  saveAthleteProfiles: (
    p: ISaveAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ) => Promise<ISaveAthleteProfilesResponse>;
}

export class AthleteProfileRepository
  extends BaseRepository
  implements IAthleteProfileRepository
{
  constructor(
    private readonly service: AthleteProfileService,
    options?: IRepositoryOptions
  ) {
    super(options);
  }

  async getAthleteProfiles(
    p: IGetAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ): Promise<IAthleteProfile[]> {
    const response = await this.handleRequest(
      this.service.getAthleteProfiles,
      p,
      c
    );
    return response.data;
  }

  async saveAthleteProfiles(
    p: ISaveAthleteProfilesRequest,
    c?: AxiosRequestConfig
  ): Promise<ISaveAthleteProfilesResponse> {
    const response = await this.handleRequest(
      this.service.saveAthleteProfiles,
      p,
      c
    );
    return response.data;
  }
}
