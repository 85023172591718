import { createContext, use } from 'react';
import { ITrainingPlanContext } from '../types';

export const TrainingPlanContext = createContext<ITrainingPlanContext>(
  {} as ITrainingPlanContext
);

export const useTrainingPlanContext = () => {
  return use(TrainingPlanContext);
};
