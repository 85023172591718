import './index.css';
import { EmptyState } from '@cycling-web/analog-ui';
import InfoCircleFilled from '../../../assets/icons/info-circle-filled';
import { useTranslation } from 'react-i18next';

export const WaitlistCompleted = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      icon={<InfoCircleFilled className="waitlist-completed__banner-icon" />}
      className="waitlist-completed__banner"
      title={t('banner.waitlist_completed_title')}
      text={t('banner.waitlist_completed_text')}
    />
  );
};
