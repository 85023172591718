import {
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
} from 'lexical';

export type SerializedHintNode = Spread<
  {
    type: 'hint';
    version: 1;
  },
  SerializedTextNode
>;

export class HintNode extends TextNode {
  static getType(): string {
    return 'hint';
  }

  static clone(node: HintNode): HintNode {
    return new HintNode(node.__text, node.__key);
  }

  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    dom.classList.add('conversation__hint');
    dom.setAttribute('aria-hidden', 'true');
    dom.style.userSelect = 'none';
    dom.style.pointerEvents = 'none';
    dom.contentEditable = 'false';
    dom.textContent = this.__text;
    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  isToken(): true {
    return true;
  }

  static importJSON(serializedNode: SerializedHintNode): HintNode {
    const node = new HintNode(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedHintNode {
    return {
      ...super.exportJSON(),
      type: 'hint',
      version: 1,
    };
  }
}

export function $createHintNode(text: string): HintNode {
  return new HintNode(text);
}

export function $isHintNode(
  node: LexicalNode | null | undefined
): node is HintNode {
  return node instanceof HintNode;
}
