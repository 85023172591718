import './index.css';
import { TrainingPlanHeader } from '../Header';
import { useTranslation } from 'react-i18next';
import {
  Datepicker,
  FormElement,
  ISegmentButton,
  Segment,
  Typography,
} from '@cycling-web/analog-ui';
import { FormColumn, FormRow } from '@cycling-web/common';
import { useCallback, useMemo, useState } from 'react';
import { useTrainingPlanContext } from '../../context';
import { Competitions } from '../Competitions';
import { Fitness } from '../Fitness';

export const EventAndGoals = () => {
  const { t } = useTranslation();
  const { trainingPlan, onTrainingPlanChange } = useTrainingPlanContext();

  // ---------------------------------------------------------------------------

  const [activeSegment, setActiveSegment] = useState<number>(0);
  const segmentButtons = useMemo((): ISegmentButton[] => {
    return [
      {
        id: 'competition',
        text: t('label.competition'),
      },
      {
        id: 'fitness',
        text: t('label.fitness'),
      },
    ];
  }, [t]);

  const onDateChange = useCallback(
    (date: Date) => {
      onTrainingPlanChange({
        date,
      });
    },
    [onTrainingPlanChange]
  );

  return (
    <div className="training-plan__events-and-goals">
      <TrainingPlanHeader title={t('label.training_plan_step1_title')} />
      <FormRow>
        <FormColumn>
          <FormElement label={t('label.start_date')}>
            <Datepicker
              min={new Date()}
              value={trainingPlan.date}
              onChange={onDateChange}
            />
          </FormElement>
        </FormColumn>
        <FormColumn />
      </FormRow>

      <div className="training-plan__events-and-goals-main">
        <Typography
          variant="subtitle"
          color="text-primary"
          text={t('label.choose_training_focus')}
        />

        <div>
          <Segment
            buttons={segmentButtons}
            activeButton={activeSegment}
            onChange={setActiveSegment}
          />
        </div>

        {activeSegment === 0 ? <Competitions /> : <Fitness />}
      </div>
    </div>
  );
};
