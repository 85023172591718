import { IFeatureFlagsSlice, IFeatureFlagsState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IFeatureFlags } from '../../types/feature-flags';

export const initialState: IFeatureFlagsState = {
  featureFlags: [],
  featureFlagsLoaded: false,
};

export const useFeatureFlagsStore = create<IFeatureFlagsSlice>()(
  immer((set) => ({
    ...initialState,
    setFeatureFlags: (featureFlags: IFeatureFlags) => {
      set((state) => {
        state.featureFlags = featureFlags;
        state.featureFlagsLoaded = true;
      });
    },
    setFeatureFlagsLoaded: (p: boolean) => {
      set((state) => {
        state.featureFlagsLoaded = p;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
