import { IFitnessGoal, TrainingPlanFitnessGoal } from '../types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useFitnessGoals = (): IFitnessGoal[] => {
  const { t } = useTranslation();

  return useMemo((): IFitnessGoal[] => {
    return [
      {
        id: TrainingPlanFitnessGoal.CyclingEconomy,
        text: t('label.cycling_economy'),
        description: 'Description',
      },
      {
        id: TrainingPlanFitnessGoal.IncreaseFTP,
        text: t('label.increase_ftp'),
        description: 'Description',
      },
      {
        id: TrainingPlanFitnessGoal.ImproveClimbingPerformance,
        text: t('label.improve_climbing_performance'),
        description: 'Description',
      },
      {
        id: TrainingPlanFitnessGoal.ImproveVO2Max,
        text: t('label.improve_vo2max'),
        description: 'Description',
      },
      {
        id: TrainingPlanFitnessGoal.SprintPower,
        text: t('label.sprint_power'),
        description: 'Description',
      },
      {
        id: TrainingPlanFitnessGoal.LactateTolerance,
        text: t('label.lactate_tolerance'),
        description: 'Description',
      },
      {
        id: TrainingPlanFitnessGoal.ExcelInGroupRides,
        text: t('label.excel_in_group_rides'),
        description: 'Description',
      },
    ];
  }, [t]);
};
