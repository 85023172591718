import './index.css';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@cycling-web/analog-ui';
import { X } from 'lucide-react';
import { useAnaContext } from '../../context';

export const ToolHeader = () => {
  const { t } = useTranslation();
  const { activeTool, dismissActiveTool } = useAnaContext();

  return (
    <header className="ana-tool__header">
      <Typography
        weight="bold"
        className="ana-tool__header-title"
        text={activeTool?.title || t('label.tool')}
      />
      <div className="ana-tool__header-right">
        <Tooltip
          anchor={
            <IconButton
              size="s"
              variant="quietLayer3"
              content={<X />}
              onClick={dismissActiveTool}
            />
          }
          content={t('tooltip.close_what', {
            what: ` ${t('label.tool').toLowerCase()}`,
          })}
          placement="bottom-end"
        />
      </div>
    </header>
  );
};
