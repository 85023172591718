import { Page } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { AthleteMedicalReport } from '../AthleteMedicalReport';
import { LS } from '../../constants';
import { onTrackBackButtonClick } from '../../ms/utils';
import { useMobileMenu } from '../../components/MobileMenu';

export const MedicalReport = () => {
  const { t } = useTranslation();

  const onRenderMenu = useMobileMenu();
  const backUrl = localStorage.getItem(LS.PreviousPage) || undefined;

  return (
    <Page
      pageHeaderProps={{
        title: t('label.medical_report'),
        backUrl,
        onBackUrlClick: onTrackBackButtonClick,
        onRenderMenu,
      }}
    >
      <AthleteMedicalReport />
    </Page>
  );
};
