import { useCallback, useMemo } from 'react';
import { WorkoutsRepository } from '../../../api/workouts/repository';
import { WorkoutsService } from '../../../api/workouts/service';
import { format } from 'date-fns';

export const useFetchDayWorkouts = () => {
  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchDayWorkouts = useCallback(
    (date: Date, athleteId: string) => {
      const startDate = format(date, 'yyyy-MM-dd');
      const endDate = format(date, 'yyyy-MM-dd');

      return workoutsRepository.getCalendar({
        athleteId: +athleteId,
        startDate,
        endDate,
      });
    },
    [workoutsRepository]
  );

  return {
    fetchDayWorkouts,
  };
};
