import { useBoolean } from '@cycling-web/analog-ui';
import { use, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IAthlete } from '../../../../types/athletes';
import { ISmartChartsContext } from '../../types';
import { useTitle } from '../../../../hooks/useTitle';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useFetchHistory } from '../../hooks/useFetchHistory';
import { useFetchConversation } from '../../hooks/useFetchConversation';
import { ROUTES } from '../../../../router/routes';
import { useSmartChartsStore } from '../../store/slice';
import { useAthletesStore } from '../../../../store/athletes/slice';
import {
  ISmartChartConfig,
  ISmartChartConfigBase,
  ISmartChartTurn,
  ITaggedEntity,
  SmartChartActor,
} from '../../../../types/smart-charts';
import { useUsersStore } from '../../../../store/users/slice';
import { SmartChartsRepository } from '../../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../../api/smart-charts/service';
import { format } from 'date-fns';
import { useUpdateConfig } from '../../hooks/useUpdateConfig';
import { useUserRole } from '../../../../hooks/useUserRole';
import {
  ConversationContext,
  ConversationMessageSource,
  ConversationState,
  IConversationMessageBase,
  IConversationSendOptions,
} from '../../../../components/Conversation';
import { ConversationRepository } from '../../../../api/conversation/repository';
import { ConversationService } from '../../../../api/conversation/service';
import { AxiosError } from 'axios';
import { LS, URL_PARAMS } from '../../../../constants';
import { useGroupsStore } from '../../../../store/groups/slice';
import { prepareTaggedEntities } from '../../../../utils/conversation';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import {
  TrackingAction,
  TrackingEvent,
  TrackingModal,
} from '../../../../ms/tracking-entities';
import { useMediaMatch } from 'rooks';

export const useViewModel = () => {
  const { chartId } = useParams();
  /** When new message is sent and there is no conversation yet, this flag prevents
   * fetching config and compute data */
  const isNewConversation = useRef<boolean>(false);
  const conversationBuffer = useRef<ISmartChartTurn[]>([]);

  const { setConversationState, handleStopPlayMessage } =
    use(ConversationContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  useFetchHistory();
  useFetchConversation({
    isNewConversation,
    conversationBuffer,
  });
  const { updateConfig } = useUpdateConfig();
  const { isAthlete } = useUserRole();
  const isMobile = useMediaMatch('(max-width: 480px)');
  const baseUrl: string = useMemo((): string => {
    const pathname = window.location.pathname.split('/');
    if (pathname.at(-1) !== ROUTES.SMART_CHARTS) {
      pathname.pop();
    }
    return pathname.join('/');
  }, []);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const conversationRepository = useMemo(() => {
    return new ConversationRepository(new ConversationService());
  }, []);

  const { t } = useTranslation();
  useTitle(t('label.smart_charts'));
  const navigate = useNavigate();
  const stableNavigateRef = useRef(navigate);

  const userProfile = useUsersStore((s) => s.userProfile);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const setProcessingBufferMessage = useSmartChartsStore(
    (s) => s.setProcessingBufferMessage
  );
  const config = useSmartChartsStore((s) => s.config);
  const datasource_group_specific =
    config?.ai_graph_in_scope?.data_sources?.datasource_group_specific;

  const {
    value: showHistory,
    setTrue: openHistory,
    setFalse: dismissHistory,
  } = useBoolean(!isMobile);

  const {
    value: showAthletesModal,
    setTrue: openAthletesModal,
    setFalse: dismissAthletesModal,
  } = useBoolean(false);

  const handleOpenAthletesModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
      modal: TrackingModal.ATHLETES,
    });

    openAthletesModal();
  }, [openAthletesModal]);

  const handleDismissAthletesModal = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.ATHLETES,
    });

    dismissAthletesModal();
  }, [dismissAthletesModal]);

  const selectedAthletes = useSmartChartsStore((s) => s.selectedAthletes);
  const setSelectedAthletes = useSmartChartsStore((s) => s.setSelectedAthletes);
  const athletes = useAthletesStore((s) => s.athletes);
  const groups = useGroupsStore((s) => s.groups);
  const groupsLoaded = useGroupsStore((s) => s.groupsLoaded);

  useEffect(() => {
    if (isAthlete) {
      setSelectedAthletes(athletes);
    }
  }, [athletes, isAthlete, setSelectedAthletes]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let updated = false;

    if (athletesLoaded) {
      const athleteId = urlParams.get(URL_PARAMS.AthleteId);
      if (athleteId && athletesMap[athleteId]) {
        const a = athletesMap[athleteId];
        setSelectedAthletes([a]);
        urlParams.delete(URL_PARAMS.AthleteId);
        updated = true;
      }
    }

    if (groupsLoaded) {
      const groupId = urlParams.get(URL_PARAMS.GroupId);
      if (groupId) {
        const group = groups.find((g) => g.id === +groupId);
        if (group) {
          const athleteIds = group.athleteIds || [];
          const selectedAthletes = athleteIds
            .map((id) => athletesMap[id])
            .filter(Boolean);
          setSelectedAthletes(selectedAthletes);
        }
        urlParams.delete(URL_PARAMS.GroupId);
        updated = true;
      }
    }

    if (updated) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [athletesLoaded, athletesMap, groups, groupsLoaded, setSelectedAthletes]);

  useEffect(() => {
    if (!athletesLoaded || !datasource_group_specific) {
      return;
    }

    const selectedAthletes: IAthlete[] = [];
    const values = Object.values(datasource_group_specific);

    for (let i = 0; i < values.length; i++) {
      const athleteMeta = values[i][0].metadata;
      const athlete = athletesMap[athleteMeta.athlete_id];
      if (athlete) {
        selectedAthletes.push(athlete);
      }
    }
    setSelectedAthletes(selectedAthletes);
  }, [
    athletesLoaded,
    athletesMap,
    datasource_group_specific,
    setSelectedAthletes,
  ]);

  const onSelectAthletes = useCallback(
    (athletes: IAthlete[]) => {
      trackUserInteractionEvent(TrackingEvent.CLICK, {
        action: TrackingAction.APPLY_ATHLETES,
      });

      function areArraysEqual(arr1: IAthlete[], arr2: IAthlete[]): boolean {
        if (arr1.length !== arr2.length) {
          return false;
        }

        const sortedArr1 = [...arr1].sort((a, b) => a.id - b.id);
        const sortedArr2 = [...arr2].sort((a, b) => a.id - b.id);

        return sortedArr1.every(
          (item, index) => item.id === sortedArr2[index].id
        );
      }

      setSelectedAthletes(athletes);

      if (!areArraysEqual(selectedAthletes, athletes)) {
        updateConfig(athletes)
          .then(() => {
            useSmartChartsStore.getState().reloadChart();
          })
          .catch((e) => {
            console.log(e);
          });
      }

      dismissAthletesModal();
    },
    [selectedAthletes, setSelectedAthletes, dismissAthletesModal, updateConfig]
  );

  const startNewSession = useCallback(() => {
    setExpanded(false);
    useSmartChartsStore.getState().startConversation();
    stableNavigateRef.current(`${baseUrl}`);
  }, [baseUrl]);

  const handleChunkReceive = useCallback(
    (chunk: string) => {
      const lines = chunk.split('\n');

      lines.forEach((line) => {
        if (line.startsWith('data: ')) {
          const jsonData = line.substring(6);

          try {
            const parsedChunk = JSON.parse(jsonData) as ISmartChartTurn;
            setProcessingBufferMessage(parsedChunk);

            if (parsedChunk.message) {
              conversationBuffer.current.push(parsedChunk);
            }
          } catch (err) {
            console.error('Failed to parse JSON:', err);
          }
        }
      });
    },
    [setProcessingBufferMessage]
  );

  const applyMessagesFromBuffer = useCallback(() => {
    if (conversationBuffer.current.length > 0) {
      useSmartChartsStore.getState().appendMessages(conversationBuffer.current);
      conversationBuffer.current = [];
    }
  }, []);

  const sendMessage = useCallback(
    (
      value: string,
      options?: IConversationSendOptions
    ): Promise<IConversationMessageBase | undefined> => {
      if (value.trim() === '' || !userProfile) {
        return Promise.resolve(undefined);
      }

      trackUserInteractionEvent(TrackingEvent.CLICK, {
        action: TrackingAction.SEND_SC_TEXT_MESSAGE,
      });

      const userMessage: ISmartChartTurn = {
        id: `${Date.now()}`,
        actor: SmartChartActor.User,
        actor_user_id: userProfile.id,
        created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX"),
        is_in_progress: false,
        message: value,
        next_suggestions: [],
        generated_diff_message: null,
        turn_subprocesses: [],
        ai_graph_snapshot_data: null,
      };
      conversationBuffer.current.push(userMessage);
      setConversationState(ConversationState.PROCESSING);

      const taggedEntities: ITaggedEntity[] = prepareTaggedEntities({
        mentions: options?.mentions || [],
        isNew: !chartId,
      });

      const fetchConversation = chartId
        ? Promise.resolve(chartId)
        : smartChartsRepository
            .createConversation(selectedAthletes)
            .then((conversation: ISmartChartConfigBase) => {
              useSmartChartsStore.getState().appendHistory(conversation);
              isNewConversation.current = true;
              stableNavigateRef.current(`${baseUrl}/${conversation.id}`);
              return conversation.id;
            });

      let _chartId: string = chartId || '';
      return fetchConversation
        .then((id: string) => {
          _chartId = id;
          if (!isNewConversation.current) {
            applyMessagesFromBuffer();
          }
          return smartChartsRepository.sendMessage({
            graphId: id,
            query: value,
            tagged_entities: taggedEntities,
            onChunkReceive: handleChunkReceive,
          });
        })
        .then(() => {
          const messages = [...conversationBuffer.current];
          applyMessagesFromBuffer();
          return smartChartsRepository
            .getComputeData({
              graphId: _chartId,
            })
            .then((config: ISmartChartConfig) => {
              useSmartChartsStore.getState().updateHistory({
                id: config.id,
                ai_graph_meta_data: config.ai_graph_meta_data,
              });
              useSmartChartsStore.getState().setConfig(config);
              return {
                id: messages[0].id,
                source: ConversationMessageSource.SmartCharts,
              };
            })
            .catch((error: AxiosError) => {
              const code = error?.response?.status;
              if (code === 400) {
                useSmartChartsStore.getState().updateConfig({
                  ai_graph_in_scope: {
                    ai_generated_graphs: [],
                  },
                });
              }
              return undefined;
            });
        })
        .finally(() => {
          setProcessingBufferMessage(undefined);
          setConversationState(ConversationState.IDLE);
        });
    },
    [
      applyMessagesFromBuffer,
      baseUrl,
      chartId,
      handleChunkReceive,
      selectedAthletes,
      setConversationState,
      setProcessingBufferMessage,
      smartChartsRepository,
      userProfile,
    ]
  );

  const sendAudioMessage = useCallback(
    (
      base64: string,
      options?: IConversationSendOptions
    ): Promise<IConversationMessageBase | undefined> => {
      return conversationRepository
        .generateTranscribeTicket()
        .then(({ ticket_id }) => {
          return conversationRepository.transcribeAudioMessage({
            ticket: ticket_id,
            audio_data: base64,
          });
        })
        .then(({ text }) => {
          return sendMessage(text, options);
        })
        .catch(() => {
          setConversationState(ConversationState.IDLE);
          return undefined;
        });
    },
    [conversationRepository, sendMessage, setConversationState]
  );

  const [backUrl, setBackUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    setBackUrl(localStorage.getItem(LS.PreviousPage) || undefined);
  }, []);

  useEffect(() => {
    return () => {
      useSmartChartsStore.getState().reset();

      localStorage.removeItem(LS.PreviousPage);
    };
  }, []);

  useEffect(() => {
    return () => {
      handleStopPlayMessage();
    };
  }, [handleStopPlayMessage, chartId]);

  const context: ISmartChartsContext = useMemo((): ISmartChartsContext => {
    return {
      showAthletesModal,
      openAthletesModal: handleOpenAthletesModal,
      dismissAthletesModal: handleDismissAthletesModal,
      onSelectAthletes,
      selectedAthletes,
      startNewSession,
      showHistory,
      openHistory,
      dismissHistory,
      sendMessage,
      sendAudioMessage,
      baseUrl,
      expanded,
      setExpanded,
      backUrl,
    };
  }, [
    onSelectAthletes,
    handleOpenAthletesModal,
    handleDismissAthletesModal,
    showAthletesModal,
    selectedAthletes,
    startNewSession,
    showHistory,
    openHistory,
    dismissHistory,
    sendMessage,
    sendAudioMessage,
    baseUrl,
    expanded,
    setExpanded,
    backUrl,
  ]);

  return {
    context,
  };
};
