import { format, parse } from 'date-fns';
import { DateRange } from '../types/enums';
import { useFiltersStore } from '../store/filters/slice';

const apiDateFormat = 'yyyy-MM-dd';
const localDateFormat = 'dd/MM/yyyy';

export function pdFormatTime(seconds: number): string {
  if (seconds >= 3600) {
    return Math.round(seconds / 3600) + 'h';
  } else if (seconds >= 60) {
    return Math.round(seconds / 60) + 'm';
  } else {
    return Math.round(seconds) + 's';
  }
}

export const formatTime: (seconds: number) => string = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (seconds < 60) {
    return seconds + 's';
  } else if (hours > 0) {
    if (remainingMinutes === 0 && remainingSeconds === 0) {
      return hours + 'h';
    } else if (remainingSeconds === 0) {
      return hours + 'h ' + remainingMinutes + 'm';
    } else {
      return hours + 'h ' + remainingMinutes + 'm ' + remainingSeconds + 's';
    }
  } else if (remainingSeconds === 0) {
    return minutes + 'm';
  } else {
    return minutes + 'm ' + remainingSeconds + 's';
  }
};

export const getStartAndEndDateOfWeek = (
  previousWeek = false
): { start: Date; end: Date } => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  let diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

  if (previousWeek) {
    diff -= 7;
  }

  const startOfWeek = new Date(today.setDate(diff));
  startOfWeek.setHours(0, 0, 0, 0);

  let endOfWeek: Date;

  if (!previousWeek) {
    endOfWeek = new Date();
  } else {
    endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
  }

  endOfWeek.setHours(23, 59, 59, 999);

  return {
    start: startOfWeek,
    end: endOfWeek,
  };
};

export const calculateDateRange = (
  period: string | undefined
): { startDate: Date; endDate: Date } => {
  let startDate: Date;
  let endDate = new Date();

  if (period === undefined) {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  const ONE_DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

  switch (period) {
    case DateRange.Week:
      {
        const { start, end } = getStartAndEndDateOfWeek(true);
        startDate = start;
        endDate = end;
      }
      break;
    case DateRange.Month:
      startDate = new Date(endDate.getTime() - 30 * ONE_DAY_MILLISECONDS);
      break;
    case DateRange.TwoMonth:
      startDate = new Date(endDate.getTime() - 60 * ONE_DAY_MILLISECONDS);
      break;
    case DateRange.ThreeMonth:
      startDate = new Date(endDate.getTime() - 90 * ONE_DAY_MILLISECONDS);
      break;
    case DateRange.Year:
      startDate = new Date(endDate.getTime() - 365 * ONE_DAY_MILLISECONDS);
      break;
    default:
      if (period.startsWith('season_')) {
        const year = parseInt(period.replace('season_', ''), 10);
        if (isNaN(year)) {
          startDate = new Date();
          endDate = new Date();
        }
        startDate = new Date(year, 0, 1);
        endDate = new Date(year, 11, 31, 23, 59, 59, 999);
      } else {
        const customPeriods = useFiltersStore.getState().customPeriods;
        const customPeriod = customPeriods.find(
          (p) => p.id.toString() === period.toString()
        );

        if (customPeriod) {
          startDate = new Date(customPeriod.startDate);
          endDate = new Date(customPeriod.endDate);
        } else {
          startDate = new Date();
          endDate = new Date();
        }
      }
  }

  return {
    startDate,
    endDate,
  };
};

export const formatDate: (date: Date) => string = (date) => {
  return format(date, localDateFormat);
};

export const parseDate: (input: string) => Date = (input) =>
  parse(input, apiDateFormat, new Date());

export const severityToOpacity = (severity: string): number => {
  switch (severity) {
    case 'MILD':
      return 0.45;
    case 'MODERATE':
      return 0.65;
    case 'SEVERE':
      return 0.85;
    default:
      return 1;
  }
};
