import Map, { Layer, Marker, Source } from 'react-map-gl/mapbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import { LayerProps } from 'react-map-gl/dist/mapbox-legacy';
import { IRouteMapViewProps } from './types';
import { EmptyState } from '@cycling-web/analog-ui';
import { MapPinOff } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ChartColor } from '../Chart';

export const RouteMapView = ({
  viewState,
  setViewState,
  routeGeoJSON,
  markers,
  laps = [],
  activePosition,
}: IRouteMapViewProps) => {
  const { t } = useTranslation();

  const routeLayer: LayerProps = {
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ChartColor.Purple,
      'line-width': 4,
      'line-opacity': 0.8,
    },
  };

  const lapLayer: LayerProps = {
    id: 'laps',
    type: 'line',
    source: 'laps',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ChartColor.LightBlue,
      'line-width': 3,
      'line-opacity': 0.6,
    },
  };

  const activePointLayer: LayerProps = {
    id: 'active-point',
    type: 'circle',
    source: 'active-point',
    paint: {
      'circle-radius': 8,
      'circle-color': ChartColor.Orange,
      'circle-opacity': 1,
      'circle-stroke-color': '#fff',
      'circle-stroke-width': 2,
    },
  };

  if (!import.meta.env.VITE_MAPBOX_KEY) {
    return (
      <EmptyState
        size="l"
        icon={<MapPinOff size={32} />}
        text={t('banner.empty_route_map_text')}
      />
    );
  }

  return (
    <div className="route-map__map">
      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_KEY}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
      >
        <Source id="route" type="geojson" data={routeGeoJSON}>
          <Layer {...routeLayer} />
        </Source>

        {laps.length > 0 && (
          <Source
            id="laps"
            type="geojson"
            data={{ type: 'FeatureCollection', features: laps }}
          >
            <Layer {...lapLayer} />
          </Source>
        )}

        {markers.map((marker, index) => (
          <Marker
            key={index}
            longitude={marker.longitude}
            latitude={marker.latitude}
            color={marker.color}
          />
        ))}

        {activePosition && (
          <Source id="active-point" type="geojson" data={activePosition}>
            <Layer {...activePointLayer} />
          </Source>
        )}
      </Map>
    </div>
  );
};
