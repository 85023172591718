import './index.css';
import {
  FormElement,
  ISelectOption,
  Select,
  TruncatedText,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { ChartColor } from '../../Chart';

type IProps = {
  name: string;
  className?: string;
};

export const ColorControl = ({ name, className }: IProps) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const [_value] = useWatch({ name: [name] });

  const colorJSX = useCallback((color: string) => {
    return (
      <div
        className="color-control__color"
        style={{
          background: color,
        }}
      />
    );
  }, []);

  const options: ISelectOption[] = useMemo(() => {
    const colors: string[] = [
      'Grey',
      'LightBlue',
      'Blue',
      'LightPurple',
      'Purple',
      'Orange',
      'Red',
      'LightRed',
      'Rose',
      'LightRose',
      'Magenta',
      'LightMagenta',
      'LightCyan',
      'Cyan',
      'LightGreen',
      'Green',
      'LightYellow',
      'Yellow',
    ];

    const options: ISelectOption[] = colors.map((color: string) => {
      return {
        id: color,
        text: color,
        icon: colorJSX(ChartColor[color]),
      };
    });

    return options;
  }, [colorJSX]);

  const value = useMemo(() => {
    return options.find((o: ISelectOption) => o.id === _value);
  }, [options, _value]);

  const onChange = useCallback(
    (option: ISelectOption) => {
      setValue(name, option.id);
    },
    [setValue, name]
  );

  const onRenderDisplayValue = useCallback(
    (displayValue?: string) => {
      return (
        displayValue && (
          <>
            {colorJSX(ChartColor[displayValue])}
            <TruncatedText
              className="color-control__value"
              text={displayValue}
            />
          </>
        )
      );
    },
    [colorJSX]
  );

  return (
    <FormElement className={className} label={t('label.color')}>
      <Select
        options={options}
        value={value}
        onChange={onChange}
        placeholder={t('placeholder.color')}
        onRenderDisplayValue={onRenderDisplayValue}
        dropdownProps={{
          maxHeight: '290px',
          minWidth: '220px',
        }}
      />
    </FormElement>
  );
};
