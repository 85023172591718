import GarminLogo from '../../../assets/illustrations/garmin-logo.svg?react';
import { Device } from '@cycling-web/common';
import { OnboardingIntegrationItem } from '../IntegrationItem';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton, Toast, useBoolean } from '@cycling-web/analog-ui';
import { ConsentDialog } from '../ConsentDialog';
import { ConnectedDialog } from '../ConnectedDialog';
import { PlatformRepository } from '../../../api/platform/repository';
import { PlatformService } from '../../../api/platform/service';
import { LS } from '../../../constants';
import { IPlatform } from '../../../types/platform';
import { usePlatformsStore } from '../../../store/platforms/slice';

type IProps = {
  platform: IPlatform;
  platformLoaded: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
  showDetails?: boolean;
};

export const Garmin = ({
  platform,
  platformLoaded,
  onConnect,
  onDisconnect,
  showDetails,
}: IProps) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const oauth_token = params.get('oauth_token');
  const oauth_verifier = params.get('oauth_verifier');
  const platformParam = params.get('device');

  const fetchingRef = useRef<boolean>(false);
  const isConsentGiven = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    value: showConsentDialog,
    setTrue: openConsentDialog,
    setFalse: dismissConsentDialog,
  } = useBoolean(false);

  const {
    value: showConnectedDialog,
    setTrue: openConnectedDialog,
    setFalse: dismissConnectedDialog,
  } = useBoolean(false);

  const platformRepository = useMemo(() => {
    return new PlatformRepository(new PlatformService());
  }, []);

  const connectGarmin = useCallback((): Promise<void> => {
    if (!oauth_token || !oauth_verifier || fetchingRef.current) {
      return Promise.reject();
    }

    fetchingRef.current = true;
    setLoading(true);

    return platformRepository
      .addGarminAccount({
        oauth_token,
        oauth_verifier,
      })
      .then(() => {
        onConnect();
        openConnectedDialog();
      })
      .catch((e) => {
        setError(true);
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        fetchingRef.current = false;
      });
  }, [
    oauth_token,
    oauth_verifier,
    platformRepository,
    onConnect,
    openConnectedDialog,
  ]);

  useEffect(() => {
    if (platformParam === Device.Garmin) {
      connectGarmin();
    }
  }, [connectGarmin, platformParam]);

  const onConnectPlatform = useCallback(() => {
    if (oauth_token && oauth_verifier) {
      localStorage.removeItem(LS.PreviousPage);
      connectGarmin();
    } else {
      platformRepository.getGarminUrl().then((garminUrl: string) => {
        localStorage.setItem(
          LS.PreviousPage,
          `${window.location.href}?device=${Device.Garmin}`
        );
        window.open(garminUrl);
      });
    }
  }, [connectGarmin, oauth_token, oauth_verifier, platformRepository]);

  const handleConnectPlatform = useCallback(() => {
    if (isConsentGiven.current || (oauth_token && oauth_verifier)) {
      onConnectPlatform();
    } else {
      openConsentDialog();
    }
  }, [onConnectPlatform, openConsentDialog, oauth_token, oauth_verifier]);

  const handleGiveConsent = useCallback((): Promise<void> => {
    dismissConsentDialog();
    onConnectPlatform();
    return Promise.resolve();
  }, [dismissConsentDialog, onConnectPlatform]);

  const handleDisconnect = useCallback(() => {
    usePlatformsStore.getState().disconnect(platform);
    return platformRepository
      .deleteGarminAccount()
      .then(() => {
        if (onDisconnect) {
          onDisconnect();
        }
      })
      .catch(() => {
        Toast.error({
          title: t('error.disconnect_app_title'),
        });
        usePlatformsStore.getState().setConnected(platform);
      });
  }, [platform, platformRepository, onDisconnect, t]);

  return (
    <>
      {(oauth_token && oauth_verifier) || platformLoaded ? (
        <OnboardingIntegrationItem
          icon={<GarminLogo />}
          type="platform"
          handleIntegration={handleConnectPlatform}
          loading={loading}
          error={error}
          platform={platform}
          handleDisconnect={handleDisconnect}
          showDetails={showDetails}
        />
      ) : (
        <Skeleton width="100%" height="111.5px" />
      )}
      {showConsentDialog && (
        <ConsentDialog
          onDismiss={dismissConsentDialog}
          onSubmit={handleGiveConsent}
        />
      )}
      {showConnectedDialog && (
        <ConnectedDialog
          integrationName={t('label.garmin')}
          onDismiss={dismissConnectedDialog}
        />
      )}
    </>
  );
};
