import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFeatureFlagsStore } from '../store/feature-flags/slice';
import { FeatureFlagsRepository } from '../api/feature-flags/repository';
import { FeatureFlagsService } from '../api/feature-flags/service';
import { IFeatureFlags } from '../types/feature-flags';

export const useFetchFeatureFlags = () => {
  const fetching = useRef<boolean>(false);
  const setFeatureFlags = useFeatureFlagsStore((s) => s.setFeatureFlags);
  const setFeatureFlagsLoaded = useFeatureFlagsStore(
    (s) => s.setFeatureFlagsLoaded
  );

  const featuresFlagsRepository = useMemo(() => {
    return new FeatureFlagsRepository(new FeatureFlagsService(), {
      abort: true,
    });
  }, []);

  const fetchFeatureFlags = useCallback(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    featuresFlagsRepository
      .getFeatureFlags()
      .then((flags: IFeatureFlags) => {
        setFeatureFlags(flags);
      })
      .catch((e) => {
        console.log(e);
        setFeatureFlagsLoaded(true);
      })
      .finally(() => {
        fetching.current = false;
      });
  }, [featuresFlagsRepository, setFeatureFlags, setFeatureFlagsLoaded]);

  useEffect(() => {
    fetchFeatureFlags();
  }, [fetchFeatureFlags]);

  return {
    fetchFeatureFlags,
  };
};
