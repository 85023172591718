import { Dispatch, RefObject, SetStateAction } from 'react';
import { LexicalEditor } from 'lexical';
import { IMentionOption } from '../components/ConversationTextEditor/mention/types';
import { ITaggedEntity } from '../../../types/smart-charts';

export type IConversationInputVariant = 'layer1' | 'layer2' | 'layer3';

export type IConversationInputProps = {
  variant?: IConversationInputVariant;
  onSendText: (
    text: string,
    options?: IConversationSendOptions
  ) => Promise<IConversationMessageBase | undefined>;
  onSendAudio?: (
    base64: string,
    options?: IConversationSendOptions
  ) => Promise<IConversationMessageBase | undefined>;
  placeholder?: string;
  editorRef?: RefObject<LexicalEditor | null>;
  /** Controlled change handler */
  onChange?: (value: string) => void;
  autofocus?: boolean;

  /** Web Sockets */
  onSocketMessage?: (message: IConversationMessageBase) => void;
};

export type IConversationSendOptions = {
  mentions?: IMentionOption[];
};

export enum ConversationState {
  IDLE = 'idle',
  LISTENING = 'listening',
  PROCESSING = 'processing',
  STREAMING = 'streaming',
}

export type IConversationContext = {
  conversationState: ConversationState;
  setConversationState: Dispatch<SetStateAction<ConversationState>>;
  audioRef: RefObject<HTMLAudioElement | null>;
  audioAbortControllerRef: RefObject<AbortController | null>;
  playingMessage: IConversationMessageBase | undefined;
  setPlayingMessage: Dispatch<
    SetStateAction<IConversationMessageBase | undefined>
  >;

  activeAudioState: AudioState;
  handlePlayMessage: (message: IConversationMessageBase) => void;
  handleStopPlayMessage: () => void;

  /** Web Sockets */
  onSocketMessage?: (message: IAnaResponsePayload) => void;
  emit: (message: string, entities?: IAnaContextEntity[]) => void;
  emitContext: (entities: IAnaContextEntity[]) => void;
  socketConnected: boolean;
};

// Audio

export type IConversationActiveAudio = {
  id: string;
  state: AudioState;
};

export enum AudioState {
  IDLE = 'idle',
  LOADING = 'loading',
  PLAYING = 'playing',
}

// Voice

export type IVoiceParams = {
  averageVolume: number;
};

export type OnSpeak = (args: IVoiceParams) => void;

export type IVoiceRecordingContext = {
  toggleRecording: () => void;
  cancelRecording: () => void;
  voiceParams: IVoiceParams;
  recordingTime: number;
};

// Message

export type IConversationMessageBase = {
  id: string;
  source: ConversationMessageSource;
};

export enum ConversationMessageSource {
  AnaCoach = 'ana_coach',
  SmartCharts = 'smart_charts',
}

export type IConversationMessage = IConversationMessageBase & {
  author: IConversationMessageAuthor;
  text: string;

  // TODO: New field
  content?: IAnaMessageContent[];
};

export enum IConversationMessageAuthor {
  User = 'user',
  Agent = 'agent',
  Assistant = 'assistant',
}

/** Web Sockets */

export type IUseWebSocketsProps = {
  setConversationState: Dispatch<SetStateAction<ConversationState>>;
  onSocketMessage?: (message: IAnaResponsePayload) => void;
};

export enum IAnaAuthor {
  User = 'user',
  Agent = 'agent',
  Assistant = 'assistant',
}

export enum IAnaSocketMessageType {
  ASK = 'ui_client.ask_ana',
  CONNECTION_ACCEPTED = 'server.user_connection_accepted',
  ACK = 'server.ack',
  QUERY_ACK = 'server.ask_ana_user_query_ack',
  ERROR = 'server.error',
  PROVIDE_CONTEXT = 'ui_client.provide_context_to_ana',
  RESPONSE = 'server.ask_ana_response_message',
  STREAMING_RESPONSE = 'server.ask_ana_streaming_response',
  RESPONSE_START = 'server.ask_ana_start_response',
  RESPONSE_STOP = 'server.ask_ana_stop_response',
}

export enum IAnaToolName {
  GRAPH_DISPLAY = 'graph_display',
  DEEP_APP_NAVIGATION = 'deep_app_navigation',
  TEXT_CANVAS_DISPLAY = 'text_canvas_display',
}

export enum IAnaUserType {
  AUTHENTICATED = 'authenticated',
  ANONYMOUS = 'anonymous',
}

export enum IAnaContentType {
  ADMIN = 'admin_text',
  TEXT = 'text',
  TOOL_USE = 'tool_use',
  TOOL_RESULT = 'tool_result',
  DELIMITER = 'delimeter',
  THOUGHT = 'thought',
}

export enum IAnaToolResultStatus {
  CAN_OPEN = 'available_for_user_to_open',
}

export type IAnaUser = {
  user_type: IAnaUserType;
  user_id: number;
  tenant_name: string;
  auth_token: string;
};

export type IAnaRequestMessage = {
  message_type: IAnaSocketMessageType;
  query: string;
};

export type IAnaContextMessage = {
  message_type: IAnaSocketMessageType.PROVIDE_CONTEXT;
  entities?: IAnaContextEntity[];
};

export type IAnaResponseMessage = {
  message_type: IAnaSocketMessageType.RESPONSE;
  query: string;
};

export type IAnaResponseStopMessage = {
  id: string;
  actor: IAnaAuthor;
  message_type: IAnaSocketMessageType.RESPONSE_STOP;
};

export type IAnaResponseStartMessage = {
  id: string;
  actor: IAnaAuthor;
  message_type: IAnaSocketMessageType.RESPONSE_START;
};

export type IAnaStreamMessage = {
  id: string;
  actor: IAnaAuthor;
  message_type: IAnaSocketMessageType.STREAMING_RESPONSE;
  content: IAnaMessageContent;
};

export type IAnaAckMessage = {
  message_type: IAnaSocketMessageType.ACK;
  ref_event_id: string;
};

export type IAnaErrorMessage = {
  message_type: IAnaSocketMessageType.ERROR;
};

export type IAnaSocketResponse =
  | IAnaStreamMessage
  | IAnaAckMessage
  | IAnaResponseStartMessage
  | IAnaResponseStopMessage
  | IAnaResponseMessage
  | IAnaErrorMessage;

export type IAnaThoughtContent = {
  content_id: string;
  content_type: IAnaContentType.THOUGHT;
  content_class_tag: string;
  text: string;
  title: string;
  // Frontend field
  loading?: boolean;
};

export type IAnaAdminContent = {
  content_id: string;
  content_type: IAnaContentType.ADMIN;
  content_class_tag: string;
  text: string;
  title: string;
  // Frontend field
  loading?: boolean;
};

export type IAnaDelimiterContent = {
  content_id: string;
  content_type: IAnaContentType.DELIMITER;
  // Frontend field
  loading?: boolean;
};

export type IAnaTextContent = {
  content_id: string;
  content_type: IAnaContentType.TEXT;
  text: string;
  // Frontend field
  loading?: boolean;
};

export type IAnaToolUseContent = {
  content_id: string;
  content_type: IAnaContentType.TOOL_USE;
  call_id: string;
  graph_id: string;
  title: string;
  text: string;
  tool_name: IAnaToolName;
  // Frontend field
  loading?: boolean;
};

export type IAnaToolResultContent = {
  content_id: string;
  content_type: IAnaContentType.TOOL_RESULT;
  output: { message: string; graph_id: string };
  status: IAnaToolResultStatus;
  tool_name: IAnaToolName;
  // Frontend field
  loading?: boolean;
};

export type IAnaMessageContent =
  | IAnaDelimiterContent
  | IAnaTextContent
  | IAnaToolUseContent
  | IAnaToolResultContent
  | IAnaThoughtContent
  | IAnaAdminContent;

export type IAnaRequestPayload = {
  user: IAnaUser;
  message: IAnaRequestMessage;
  tagged_entities?: ITaggedEntity[];
};

export type IAnaContextPayload = {
  user: IAnaUser;
  message: IAnaContextMessage;
};

export type IAnaResponsePayload = {
  event_id: string;
  timestamp: string;
  user: IAnaUser;
  message: IAnaSocketResponse;
};

export type IAnaContextEntity = {
  entity_metadata: IAnaContextEntityMetaData;
  user_action: string;
};

export type IAnaContextEntityMetaData = {
  context_type: IAnaContextType;
} & Record<string, any>;

export enum IAnaContextType {
  ATHLETE = 'ContextAthleteEntityMetadata',
  GROUP = 'ContextGroupEntityMetadata',
  GRAPH = 'ContextGraphEntityMetadata',
  NAV = 'UINavigationEntityMetadata',
}

/** Conversation History */

export type IAnaHistory = {
  id: string;
  user: IAnaUser;
  title: string;
  status: AnaHistoryType;
  context: IAnaConversationContext;
  runs: any[];
  artifacts: IAnaArtifact[];
  turns: IAnaTurn[];
  created_at: string;
  updated_at: string;
};

export enum AnaHistoryType {
  ACTIVE = 'active',
}

export type IAnaConversationContext = {
  id: string;
  previous_context_id: string | null;
  entities: any[];
};

export type IAnaArtifact = {
  id: string;
  artifact_metadata: IAnaArtifactMetaData;
  created_at: string;
  updated_at: string;
};

export type IAnaArtifactMetaData = {
  artifact_type: string;
  graph_id: string;
};

export type IAnaTurn = {
  id: string;
  previous_turn_id: string | null;
  actor: IConversationMessageAuthor;
  content: IAnaMessageContent[];
  is_in_progress: boolean;
  created_at: string;
};
