import './index.css';
import { use, useCallback } from 'react';
import { WorkoutsCalendarContext } from '../../context';
import { addMonths } from 'date-fns';
import { Button, IconButton, Tooltip } from '@cycling-web/analog-ui';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { CalendarDatepicker } from './CalendarDatepicker';

export const CalendarHeader = () => {
  const { t } = useTranslation();
  const { setDate, openTrainingPlanModal, date } = use(WorkoutsCalendarContext);

  const handlePrevMonth = useCallback(() => {
    setDate(addMonths(date, -1));
  }, [date, setDate]);

  const handleNextMonth = useCallback(() => {
    setDate(addMonths(date, 1));
  }, [date, setDate]);

  const handleToday = useCallback(() => {
    setDate(new Date());
  }, [setDate]);

  return (
    <div className="workouts-calendar__header">
      <CalendarDatepicker />

      <div className="workouts-calendar__header-controls">
        <Button
          className="workouts-calendar__header-controls-today"
          variant="secondary"
          size="s"
          content={t('label.today')}
          onClick={handleToday}
        />

        <Tooltip
          anchor={
            <IconButton
              variant="quietLayer3"
              size="s"
              content={<ChevronLeft />}
              onClick={handlePrevMonth}
            />
          }
          content={t('tooltip.prev', { what: t('label.month').toLowerCase() })}
        />
        <Tooltip
          anchor={
            <IconButton
              variant="quietLayer3"
              size="s"
              content={<ChevronRight />}
              onClick={handleNextMonth}
            />
          }
          content={t('tooltip.next', { what: t('label.month').toLowerCase() })}
        />
      </div>

      <div className="workouts-calendar__header-actions">
        <Button
          size="s"
          variant="secondary"
          startIcon={<Plus />}
          content={t('label.training_plan')}
          onClick={openTrainingPlanModal}
        />
      </div>
    </div>
  );
};
