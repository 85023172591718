import { useCallback, useEffect, useMemo } from 'react';
import { FiltersRepository } from '../api/filters/repository';
import { FiltersService } from '../api/filters/service';
import { useFiltersStore } from '../store/filters/slice';

export const useFetchCustomPeriods = () => {
  const filtersRepository = useMemo(() => {
    return new FiltersRepository(new FiltersService(), { abort: true });
  }, []);

  const fetchCustomPeriods = useCallback(() => {
    filtersRepository
      .getCustomPeriodFilters()
      .then((response) => {
        useFiltersStore.getState().setCustomPeriods(response);
      })
      .catch((error) => {
        //
      });
  }, [filtersRepository]);

  useEffect(() => {
    fetchCustomPeriods();
  }, [fetchCustomPeriods]);

  return {
    fetchCustomPeriods,
  };
};
