import './index.css';
import { ChartSpline } from 'lucide-react';
import { EmptyState, Spinner } from '@cycling-web/analog-ui';
import { t } from 'i18next';
import { PinnedChart } from './components/PinnedChart';
import { useFetchPinnedCharts } from './hooks/useFetchPinnedCharts';
import { usePinnedTabStore } from '../../store/pinned-tabs/slice';
import { ISmartChartConfig } from '../../types/smart-charts';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ROUTES } from '../../router/routes';
import { Url } from '../../utils/url';
import { URL_PARAMS } from '../../constants';
import { useFetchWorkoutSeasons } from '../../hooks/useFetchWorkoutSeasons';
import { useGroupsStore } from '../../store/groups/slice';
import {
  PageFiltersContextProvider,
  usePageFilters,
} from '../../context/PageFilters';
import { getDefaultPageFilters } from '../../constants/page-filters';
import { IPinnedTabsFilters } from './types';

export const PinnedTabView = () => {
  const { pinnedTabId, groupId, athleteId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useFetchPinnedCharts();

  const { initPageFilters } = usePageFilters<IPinnedTabsFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().pinnedTabs);
  }, [initPageFilters]);

  const group = useGroupsStore((s) => s.group);
  const athleteIds = useMemo(() => {
    if (athleteId) {
      return athleteId;
    }

    if (groupId && group) {
      return group.athleteIds.join(',');
    }

    return undefined;
  }, [athleteId, group, groupId]);

  useFetchWorkoutSeasons({ athleteIds });

  const charts = usePinnedTabStore((s) => s.charts);
  const chartsLoaded = usePinnedTabStore((s) => s.chartsLoaded);

  useEffect(() => {
    return () => {
      usePinnedTabStore.getState().setCharts([]);
      usePinnedTabStore.getState().setChartsLoaded(false);
    };
  }, [pinnedTabId, groupId, athleteId]);

  const navigateToSmartCharts = useCallback(() => {
    const tmp = pathname
      .split('/')
      .filter((_, i) => i < 3)
      .join('/');
    Url.saveUrlToLS();

    if (athleteId) {
      navigate(
        `${tmp}/${ROUTES.SMART_CHARTS}?${URL_PARAMS.ClearLs}=false&${URL_PARAMS.AthleteId}=${athleteId}`
      );
    } else if (groupId) {
      navigate(
        `${tmp}/${ROUTES.SMART_CHARTS}?${URL_PARAMS.ClearLs}=false&${URL_PARAMS.GroupId}=${groupId}`
      );
    }
  }, [navigate, pathname, athleteId, groupId]);

  if (!chartsLoaded) {
    return (
      <EmptyState
        icon={<Spinner size="xl" />}
        title={t('banner.loading_chart_data')}
      />
    );
  }

  if (charts.length === 0) {
    return (
      <EmptyState
        icon={<ChartSpline size={40} />}
        title={t('banner.empty_pinned_charts_title')}
        text={t('banner.empty_pinned_charts_text')}
        buttonProps={{
          content: t('action.create_chart'),
          onClick: navigateToSmartCharts,
        }}
      />
    );
  }

  const chartsJSX = charts.map((chart: ISmartChartConfig) => {
    return <PinnedChart key={chart.id} chart={chart} />;
  });

  return <div className="pinned-charts">{chartsJSX}</div>;
};

export const PinnedTab = () => {
  return (
    <PageFiltersContextProvider
      defaultFilters={getDefaultPageFilters().pinnedTabs}
    >
      <PinnedTabView />
    </PageFiltersContextProvider>
  );
};
