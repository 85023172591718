import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IBodyComposition } from '../../../../types/wellness';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';
import {
  Chart,
  ChartColor,
  chartTooltipFormatter,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import { format, parse } from 'date-fns';
import { IHandleChangeFilter, useBreakpoints } from '@cycling-web/common';
import { EMPTY } from '../../../../constants';

type IProps = {
  data: IBodyComposition | null;
  loading: boolean;
  filters: {
    period: string;
  };
  handleChangeFilter: IHandleChangeFilter;
};

export const BodyCompositionChart = ({
  data,
  loading,
  filters,
  handleChangeFilter,
}: IProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const source = useMemo(() => {
    const source: IChartSource = [
      [
        ChartAxisName.Date,
        ChartAxisName.Weight,
        ChartAxisName.Fat_Percent,
        ChartAxisName.Skin_Folds_Sum,
      ],
    ];

    if (!data) {
      return source;
    }

    const dataPoints = data.points || [];

    for (let i = 0; i < dataPoints.length; i++) {
      const p = dataPoints[i];
      const parsedDate = parse(p.startDate, 'yyyy-MM-dd', new Date());
      const formattedDate = format(parsedDate, 'dd/MM/yyyy');
      source.push([
        formattedDate,
        p.data.bodyMass || null,
        p.data.bodyFatPercent || null,
        p.data.skinFold || null,
      ]);
    }

    return source;
  }, [data]);

  const onRenderTooltipValue = useCallback(
    (value: number, series: string) => {
      if (series === ChartAxisName.Weight) {
        return value ? `${value}${t('units.kg')}` : EMPTY;
      }
      if (series === ChartAxisName.Fat_Percent) {
        return value ? `${value}%` : EMPTY;
      }
      return value ? `${value}${t('units.mm')}` : EMPTY;
    },
    [t]
  );

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: [
        {
          ...(isMobile
            ? {
                left: 40,
                right: 0,
              }
            : {}),
          height: '35%',
        },
        {
          ...(isMobile
            ? {
                left: 40,
                right: 0,
              }
            : {}),
          top: '50%',
          height: '35%',
        },
      ],
      axisPointer: {
        link: [
          {
            xAxisIndex: 'all',
          },
        ],
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
          axisLine: {
            show: false,
          },
          gridIndex: 0,
        },
        {
          type: 'category',
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
          axisLine: {
            show: false,
          },
          gridIndex: 1,
        },
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          gridIndex: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
        {
          type: 'value',
          position: 'right',
          min: 0,
          gridIndex: 0,
        },
        {
          type: 'value',
          min: 0,
          gridIndex: 1,
          splitLine: {
            show: true,
            lineStyle: {
              color: ChartColor.GridLine,
              type: 'dashed',
              width: 1,
            },
          },
        },
      ],
      series: [
        {
          name: ChartAxisName.Weight,
          type: 'line',
          yAxisIndex: 0,
          itemStyle: {
            color: ChartColor.LightBlue,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
          connectNulls: true,
        },
        {
          name: ChartAxisName.Fat_Percent,
          type: 'line',
          yAxisIndex: 1,
          xAxisIndex: 0,
          itemStyle: {
            color: ChartColor.LightPurple,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
          connectNulls: true,
        },
        {
          name: ChartAxisName.Skin_Folds_Sum,
          type: 'line',
          yAxisIndex: 2,
          xAxisIndex: 1,
          itemStyle: {
            color: ChartColor.LightYellow,
          },
          lineStyle: {
            width: 1,
          },
          symbolSize: 2,
          smooth: true,
          connectNulls: true,
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [onRenderTooltipValue, source, isMobile]);

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'layer3' }}
      />
    ),
  };

  return (
    <ChartWrapper minHeight="600px">
      <Chart
        headerProps={{
          title: t('label.body_composition'),
          filtersBarProps,
        }}
        option={option}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.AthleteBodyComposition,
          }),
        }}
      />
    </ChartWrapper>
  );
};
