import { MarkdownPart } from './MarkdownPart';
import { FractionPart } from './FractionPart';
import { LatexPart } from './LatexPart';

type IProps = {
  index: number;
  part: { type: string; content: string };
};

export const MessagePart = ({ index, part }: IProps) => {
  return (
    <div key={`wrapper-${index}`} className="conversation-message__part">
      {part.type === 'markdown' && (
        <MarkdownPart key={index} content={part.content} />
      )}
      {part.type === 'fraction' && (
        <FractionPart key={index} content={part.content} />
      )}
      {part.type === 'latex' && (
        <LatexPart key={index} content={part.content} />
      )}
    </div>
  );
};
