import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { ITrainingLoad } from '../../../types/performance';
import { Toast } from '@cycling-web/analog-ui';
import { calculateDateRange } from '../../../utils/utils';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import { useTrainingLoadStore } from '../store/slice';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { ITrainingLoadFilters } from '../types';

export const useFetchAthleteTrainingLoad = () => {
  const { athleteId } = useParams();
  const { t } = useTranslation();
  const { filters } = usePageFilters<ITrainingLoadFilters>();

  const setTrainingLoad = useTrainingLoadStore((s) => s.setTrainingLoad);
  const setTrainingLoadLoaded = useTrainingLoadStore(
    (s) => s.setTrainingLoadLoaded
  );

  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || filters?.period === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(filters.period);

    performanceRepository
      .getTrainingLoad({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteId: athleteId,
      })
      .then((data: ITrainingLoad) => {
        setTrainingLoad(data);
      })
      .catch((error: AxiosError) => {
        setTrainingLoadLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_training_load_title'),
              message: t('error.get_training_load_message'),
            },
            { toastId: 'get_training_load' }
          );
        }
      });
  }, [
    athleteId,
    filters.dateGroupBy,
    filters.period,
    performanceRepository,
    setTrainingLoad,
    setTrainingLoadLoaded,
    t,
  ]);
};
