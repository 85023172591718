import './index.css';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ICalendarEvent } from '../../../../types/workouts';
import { WorkoutsRepository } from '../../../../api/workouts/repository';
import { WorkoutsService } from '../../../../api/workouts/service';
import { addMonths, format } from 'date-fns';
import { useTrainingPlanContext } from '../../context';
import { Event } from './Event';
import { Skeleton } from '@cycling-web/analog-ui';

export const Competitions = () => {
  const { t } = useTranslation();
  const { athleteId, trainingPlan } = useTrainingPlanContext();

  // ---------------------------------------------------------------------------
  // Events

  const [eventsLoaded, setEventsLoaded] = useState<boolean>(false);
  const [events, setEvents] = useState<ICalendarEvent[]>([]);

  const workoutsRepository = useMemo(() => {
    return new WorkoutsRepository(new WorkoutsService(), { abort: true });
  }, []);

  const fetchEvents = useCallback(
    (date: Date, athleteId: number | string) => {
      const MONTHS = 6;
      const startDate = format(date, 'yyyy-MM-dd');
      const endDate = format(addMonths(date, MONTHS), 'yyyy-MM-dd');

      return workoutsRepository
        .getEvents({
          athleteId: +athleteId,
          startDate,
          endDate,
        })
        .finally(() => {
          setEventsLoaded(true);
        });
    },
    [workoutsRepository]
  );

  useEffect(() => {
    fetchEvents(trainingPlan.date, athleteId)
      .then((response) => {
        setEvents(response);
      })
      .catch((e) => {
        //
      });
  }, [fetchEvents, trainingPlan.date, athleteId]);

  // ---------------------------------------------------------------------------

  const eventsJSX = events.map((event: ICalendarEvent) => {
    return <Event key={event.id} event={event} />;
  });

  return (
    <div className="training-plan__competitions">
      {eventsLoaded ? (
        eventsJSX
      ) : (
        <>
          <Skeleton width="100%" height="40px" />
          <Skeleton width="100%" height="40px" />
          <Skeleton width="100%" height="40px" />
        </>
      )}
    </div>
  );
};
