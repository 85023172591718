import { PageFilters } from '@cycling-web/common';
import { WeeksFilter } from '../../../../components/filters/WeeksFilter';
import { MultipleAthleteFilter } from '../../../../components/filters/AthleteFilter';
import { useCallback, useEffect } from 'react';
import { IPerformanceReportFilters } from '../../../PerformanceReport/types';
import { usePageFilters } from '../../../../context/PageFilters';
import { getDefaultPageFilters } from '../../../../constants/page-filters';

export const PerformanceChartsFilters = () => {
  const { filters, handleChangeFilter, removeFilter, initPageFilters } =
    usePageFilters<IPerformanceReportFilters>();

  useEffect(() => {
    initPageFilters(getDefaultPageFilters().performanceReport);
  }, [initPageFilters]);

  const handleClearSearch = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  const handleClearAthletes = useCallback(() => {
    removeFilter('athletes');
  }, [removeFilter]);

  const filtersJSX = (
    <>
      <MultipleAthleteFilter
        value={filters.athletes?.split(',').map(Number) || []}
        onChange={handleChangeFilter('athletes')}
        style={{ minWidth: '220px' }}
        className="performance-charts__athletes-filters"
        onClear={handleClearAthletes}
      />
      <WeeksFilter value={filters.week} onChange={handleChangeFilter('week')} />
    </>
  );

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleClearSearch,
      }}
      filterProps={{ filters: filtersJSX }}
    />
  );
};
