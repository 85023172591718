import './index.css';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { CyclingSummary } from './CyclingSummary';
import { useWorkoutCalendarStore } from '../../store/slice';
import { EMPTY } from '../../../../constants';
import { TsbUtils } from '../../../../utils/tsb';

type IProps = {
  week: number;
  year: number;
};

export const WeekSummary = ({ week, year }: IProps) => {
  const { t } = useTranslation();
  const key = `${week}_${year}`;
  const reports = useWorkoutCalendarStore((s) => s.weekSummary);
  const report = reports[key];

  let tsbColor = '#f3f3f6';

  if (report) {
    tsbColor = TsbUtils.getContent(TsbUtils.getStatus(report?.tsb)).color;
  }

  const tsbStyle = { color: tsbColor };

  return (
    <div className="workout-calendar-week-summary">
      <div className="week-summary__header">
        <div className="week-summary__header-stat">
          <Typography
            as="p"
            variant="subtitle"
            weight="bold"
            text={report?.ctl ? Math.round(report.ctl) : EMPTY}
          />
          <Typography
            as="p"
            variant="caption"
            weight="regular"
            text={t('label.ctl')}
          />
        </div>
        <div className="week-summary__header-stat">
          <Typography
            as="p"
            variant="subtitle"
            weight="bold"
            text={report?.atl ? Math.round(report.atl) : EMPTY}
          />
          <Typography
            as="p"
            variant="caption"
            weight="regular"
            text={t('label.atl')}
          />
        </div>
        <div className="week-summary__header-stat">
          <Typography
            as="p"
            variant="subtitle"
            weight="bold"
            style={tsbStyle}
            text={report?.tsb ? Math.round(report.tsb) : EMPTY}
          />
          <Typography
            as="p"
            variant="caption"
            weight="regular"
            text={t('label.tsb')}
          />
        </div>
      </div>

      <CyclingSummary report={report} />
    </div>
  );
};
