export const parseMessage = (message: string) => {
  const parts: Array<{ type: string; content: string }> = [];
  const regex =
    // @ts-ignore
    /(\$\$.*?\$\$|\$.*?\$|\\\[.*?\\\]|\\\(.*?\\\)|\\begin\{.*?}.*?\\end\{.*?}|\\frac\{.*?}\{.*?})/gs;

  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(message)) !== null) {
    if (match.index > lastIndex) {
      parts.push({
        type: 'markdown',
        content: message.slice(lastIndex, match.index),
      });
    }

    const latex = match[0];
    if (/\\frac\{.*?}\{.*?}/.test(latex)) {
      parts.push({ type: 'fraction', content: latex });
    } else {
      parts.push({ type: 'latex', content: latex });
    }
    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({
      type: 'markdown',
      content: message.slice(lastIndex),
    });
  }

  return parts;
};
