import './index.css';
import { Page } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../hooks/useTitle';
import { SettingsTabs } from './components/SettingsTabs';
import { Outlet } from 'react-router';
import { useMobileMenu } from '../../components/MobileMenu';

export const Settings = () => {
  const { t } = useTranslation();
  useTitle(t('label.settings'));

  const onRenderMenu = useMobileMenu();

  return (
    <Page pageHeaderProps={{ title: t('label.settings'), onRenderMenu }}>
      <SettingsTabs />
      <Outlet />
    </Page>
  );
};
