import { useTranslation } from 'react-i18next';
import { use, useCallback, useMemo } from 'react';
import { ZonesModalContext } from '../../context';
import { Discipline } from '../../../../../../types/enums';
import { ITabsItem, Tabs } from '@cycling-web/analog-ui';
import { TrainingZoneParameter } from '../../../../../../types/athlete-profiles';
import { TrainingZoneKeys } from '../../../../utils/getDefaults';
import { useFormContext } from 'react-hook-form';

export const ZonesModalTabs = () => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const { composeKey, setComposeKey, openDiscardDialog, resetToDefault } =
    use(ZonesModalContext);
  const [discipline] = composeKey.split('_');

  const tabs: Record<Discipline, ITabsItem[]> = useMemo(() => {
    return {
      [Discipline.Cycling]: [
        {
          path: TrainingZoneKeys.CyclingPowerMFTP,
          text: t('label.power'),
          isActive: composeKey.includes(
            `${Discipline.Cycling}_${TrainingZoneParameter.Power}`
          ),
        },
        {
          path: TrainingZoneKeys.CyclingHeartRateDefault,
          text: t('label.heart_rate'),
          isActive: composeKey.includes(
            `${Discipline.Cycling}_${TrainingZoneParameter.HeartRate}`
          ),
        },
      ],
      [Discipline.Running]: [
        {
          path: TrainingZoneKeys.RunningPaceDefault,
          text: t('label.pace'),
          isActive: composeKey.includes(
            `${Discipline.Running}_${TrainingZoneParameter.Pace}`
          ),
        },
        {
          path: TrainingZoneKeys.RunningHeartRateDefault,
          text: t('label.heart_rate'),
          isActive: composeKey.includes(
            `${Discipline.Running}_${TrainingZoneParameter.HeartRate}`
          ),
        },
      ],
      [Discipline.Swimming]: [
        {
          path: TrainingZoneKeys.SwimmingCSSDefault,
          text: t('label.pace'),
          isActive: composeKey.includes(
            `${Discipline.Swimming}_${TrainingZoneParameter.CSS}`
          ),
        },
        {
          path: TrainingZoneKeys.SwimmingHeartRateDefault,
          text: t('label.heart_rate'),
          isActive: composeKey.includes(
            `${Discipline.Swimming}_${TrainingZoneParameter.HeartRate}`
          ),
        },
      ],
    };
  }, [composeKey, t]);

  const handleChange = useCallback(
    (index: number) => {
      if (formState.isDirty) {
        openDiscardDialog({
          submitAction: () => {
            const zoneParameter = tabs[discipline][index].path;
            if (zoneParameter) {
              setComposeKey(zoneParameter as TrainingZoneParameter);
            }
            return resetToDefault();
          },
        });
      } else {
        const zoneParameter = tabs[discipline][index].path;
        if (zoneParameter) {
          setComposeKey(zoneParameter as TrainingZoneParameter);
        }
      }
    },
    [
      discipline,
      formState.isDirty,
      openDiscardDialog,
      resetToDefault,
      setComposeKey,
      tabs,
    ]
  );

  return (
    <div className="training-zones-form-modal__tabs">
      <Tabs items={tabs[discipline]} onChange={handleChange} />
    </div>
  );
};
