import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ISmartChartsIntro } from '../../../types/smart-charts';
import { useSmartChartsStore } from '../store/slice';
import { SmartChartsRepository } from '../../../api/smart-charts/repository';
import { SmartChartsService } from '../../../api/smart-charts/service';

export const useFetchHistory = () => {
  const fetching = useRef<boolean>(false);
  const setIntro = useSmartChartsStore((s) => s.setIntro);
  const setIntroLoaded = useSmartChartsStore((s) => s.setIntroLoaded);

  const smartChartsRepository = useMemo(() => {
    return new SmartChartsRepository(new SmartChartsService());
  }, []);

  const fetchIntro = useCallback(() => {
    if (fetching.current) {
      return;
    }

    fetching.current = true;
    smartChartsRepository
      .getIntroRequest({})
      .then((intro: ISmartChartsIntro) => {
        setIntro(intro);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIntroLoaded(true);
        fetching.current = false;
      });
  }, [setIntro, setIntroLoaded, smartChartsRepository]);

  useEffect(() => {
    fetchIntro();
  }, [fetchIntro]);

  return {
    fetchIntro,
  };
};
