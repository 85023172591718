import './index.css';
import { useAnaContext } from '../../context';
import {
  ConversationContext,
  IConversationMessage,
  Message,
} from '../../../Conversation';
import { use, useEffect, useRef } from 'react';
import { Logger } from '../../../../utils/logger';

export const AnaContent = () => {
  const {
    messages,
    streamingMessages,
    fetchConversationHistory,
    isPageLoading,
    scrollContainerRef,
    scrollToBottom,
    paginationRef,
  } = useAnaContext();
  const { handleStopPlayMessage } = use(ConversationContext);

  useEffect(() => {
    Logger.default(messages);
  }, [messages]);

  const scrollParamsRef = useRef({
    prevScrollHeight: 0,
    prevScrollTop: 0,
  });

  useEffect(() => {
    fetchConversationHistory(paginationRef.current).then(() => {
      scrollToBottom();
    });
  }, [fetchConversationHistory, scrollToBottom, paginationRef]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (!scrollContainer || isPageLoading) return;

      const OFFSET = 50;

      if (scrollContainer.scrollTop <= OFFSET) {
        scrollParamsRef.current.prevScrollHeight = scrollContainer.scrollHeight;
        scrollParamsRef.current.prevScrollTop = scrollContainer.scrollTop;

        fetchConversationHistory(paginationRef.current).then(() => {
          requestAnimationFrame(() => {
            const scrollContainer = scrollContainerRef.current;
            if (!scrollContainer) return;

            const newScrollHeight = scrollContainer.scrollHeight;
            const heightDiff =
              newScrollHeight - scrollParamsRef.current.prevScrollHeight;

            scrollContainer.scrollTop =
              scrollParamsRef.current.prevScrollTop + heightDiff;
          });
        });
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [
    fetchConversationHistory,
    isPageLoading,
    paginationRef,
    scrollContainerRef,
  ]);

  const messagesJSX = messages.map(
    (message: IConversationMessage, i: number) => {
      return <Message key={message.id || `message-${i}`} message={message} />;
    }
  );

  const streamingMessagesJSX = Object.entries(streamingMessages).map(
    ([id, message]) => {
      return (
        <Message
          key={`streaming-${id}`}
          message={message}
          isLast={true}
          streaming={true}
        />
      );
    }
  );

  useEffect(() => {
    return () => {
      handleStopPlayMessage();
    };
  }, [handleStopPlayMessage]);

  return (
    <div className="ana__content" ref={scrollContainerRef}>
      {messagesJSX}
      {streamingMessagesJSX}
    </div>
  );
};
