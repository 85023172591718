import './index.css';
import { CSSProperties, use, useEffect, useRef, useState } from 'react';
import { VoiceRecordingContext } from '../../context/VoiceRecordingContext';

const BAR_WIDTH = 1;
const BAR_GAP = 1;
const INTERVAL = 50;

export const RecordingAnimation = () => {
  const { voiceParams } = use(VoiceRecordingContext);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const lastVolumeRef = useRef<number>(0);
  const [bars, setBars] = useState<number[]>([]);
  const timerRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!rootRef.current) {
        return;
      }

      const rect = rootRef.current.getBoundingClientRect();
      const barsCount = Math.floor(rect.width / (BAR_WIDTH + BAR_GAP)) - 1;
      setBars(new Array(barsCount).fill(0));

      timerRef.current = setInterval(() => {
        setBars((bars) => {
          const copy = [...bars];
          copy.shift();
          const normalizedVolume = (lastVolumeRef.current / 15) * 100;
          copy.push(normalizedVolume);
          return copy;
        });
      }, INTERVAL);
    });

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    lastVolumeRef.current = voiceParams.averageVolume;
  }, [voiceParams]);

  const barsJSX = bars.map((volume, i) => {
    const style: CSSProperties =
      volume > 0
        ? {
            height: `${volume}%`,
            backgroundColor:
              volume > 2
                ? 'var(--analog-text-primary)'
                : 'var(--analog-border-middle)',
          }
        : {};
    return (
      <div key={i} className="voice-recording__animation-bar" style={style} />
    );
  });

  return (
    <div className="voice-recording__animation" ref={rootRef}>
      <div className="voice-recording__animation-bars">{barsJSX}</div>
    </div>
  );
};
