import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { UsersRepository } from '../../api/users/repository';
import { UsersService } from '../../api/users/service';
import { useUsersStore } from '../../store/users/slice';
import { IUserProfile } from '../../types/users';
import { EmptyState } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../router/routes';
import { LS } from '../../constants';
import { User } from 'lucide-react';
import {
  OnboardingStep,
  PageSpinner,
  useLayoutContext,
  UserRole,
} from '@cycling-web/common';
import { useAthletesStore } from '../../store/athletes/slice';
import { useGroupsStore } from '../../store/groups/slice';
import { useTeamsStore } from '../../store/teams/slice';
import { parseTeamName } from '../../utils/teamName';
import { IUserTeam } from '../../types/teams';
import { useAnalogAuthContext } from '@cycling-web/auth';

export const Reception = () => {
  const { t } = useTranslation();
  const fetchingRef = useRef<boolean>(false);
  const authenticated = useAnalogAuthContext();
  const userProfile = useUsersStore((s) => s.userProfile);
  const userProfileLoaded = useUsersStore((s) => s.userProfileLoaded);
  const setUserProfile = useUsersStore((s) => s.setUserProfile);
  const teams = useTeamsStore((s) => s.teams);
  const { teamId } = useParams();
  const navigate = useNavigate();
  const stableNavigateRef = useRef(navigate);
  const { pathname, search } = useLocation();
  const { handleSignOut } = useLayoutContext();

  useEffect(() => {
    if (teamId) {
      localStorage.setItem(LS.Team, teamId);
    } else {
      localStorage.removeItem(LS.Team);
    }
  }, [teamId]);

  useEffect(() => {
    if (teamId) {
      const teamName = parseTeamName(teamId);
      const team = teams.find((t: IUserTeam) => t.tenantName === teamName);
      if (team) {
        useTeamsStore.getState().setSelectedTeam(team);
      }
    }
  }, [teamId, teams]);

  useEffect(() => {
    return () => {
      useTeamsStore.getState().setSelectedTeam(null);
    };
  }, [teamId]);

  const usersRepository = useMemo(() => {
    return new UsersRepository(new UsersService());
  }, []);

  const handleRedirect = useCallback(
    (profile: IUserProfile | null) => {
      if (!profile) {
        return;
      }

      if (pathname.includes(ROUTES.ONBOARDING)) {
        return;
      }

      if (profile.onboardingStatus !== OnboardingStep.Completed) {
        stableNavigateRef.current(`/${teamId}/${ROUTES.ONBOARDING}`);
        return;
      }

      if (profile.roles.includes(UserRole.Athlete)) {
        if (pathname.includes(ROUTES.ATHLETE)) {
          stableNavigateRef.current(`${pathname}${search}`);
        } else {
          stableNavigateRef.current(
            `/${teamId}/${ROUTES.ATHLETE}/${profile.athleteId}`
          );
        }
      } else {
        if (pathname.includes(ROUTES.COACH)) {
          stableNavigateRef.current(`${pathname}${search}`);
        } else {
          stableNavigateRef.current(`/${teamId}/${ROUTES.COACH}`);
        }
      }
    },
    [pathname, search, teamId]
  );

  useEffect(() => {
    if (!authenticated || userProfile || fetchingRef.current) {
      return;
    }

    fetchingRef.current = true;

    usersRepository
      .getUserProfile()
      .then((profile: IUserProfile) => {
        handleRedirect(profile);
        setUserProfile(profile);
      })
      .catch(() => {
        setUserProfile(null);
        stableNavigateRef.current(`/${ROUTES.TEAMS}`);
      })
      .finally(() => {
        fetchingRef.current = false;
      });
  }, [
    authenticated,
    usersRepository,
    teamId,
    t,
    setUserProfile,
    userProfileLoaded,
    pathname,
    handleRedirect,
    userProfile,
  ]);

  useEffect(() => {
    return () => {
      useUsersStore.getState().setUserProfile(null);
      useUsersStore.getState().setUserProfileLoaded(false);
      useAthletesStore.getState().setAthletes([]);
      useAthletesStore.getState().setAthletesLoaded(false);
      useGroupsStore.getState().setGroups([]);
      useGroupsStore.getState().setGroupsLoaded(false);
    };
  }, []);

  if (!userProfileLoaded) {
    return <PageSpinner />;
  }

  if (!userProfile) {
    return (
      <EmptyState
        title={t('banner.no_profile_title')}
        text={t('banner.no_profile_text')}
        icon={<User size={32} />}
        buttonProps={{
          onClick: handleSignOut,
          content: t('action.sign_out'),
        }}
      />
    );
  }

  return <Outlet />;
};
