import { IAthleteProfileSlice, IAthleteProfileState } from './types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  ITrainingLoadFeedback,
  ITrainingZonePerformance,
} from '../../../types/performance';
import { IAthleteProfile } from '../../../types/athlete-profiles';
import { parseAthleteProfiles } from '../utils/profiles';

export const initialState: IAthleteProfileState = {
  trainingLoadFeedback: undefined,
  trainingLoadFeedbackLoaded: false,

  trainingProfiles: {},
  athleteProfiles: [],
  athleteProfilesLoaded: false,

  zonePerformance: [],
  zonePerformanceLoaded: false,
};

export const useAthleteProfileStore = create<IAthleteProfileSlice>()(
  immer((set) => ({
    ...initialState,
    setTrainingLoadFeedback: (
      trainingLoadFeedback: ITrainingLoadFeedback | undefined
    ) => {
      set((state) => {
        state.trainingLoadFeedback = trainingLoadFeedback;
        state.trainingLoadFeedbackLoaded = true;
      });
    },
    setTrainingLoadFeedbackLoaded: (trainingLoadFeedbackLoaded: boolean) => {
      set((state) => {
        state.trainingLoadFeedbackLoaded = trainingLoadFeedbackLoaded;
      });
    },
    setAthleteProfiles: (athleteProfiles: IAthleteProfile[]) => {
      set((state) => {
        state.trainingProfiles = parseAthleteProfiles(athleteProfiles);
        state.athleteProfiles = athleteProfiles;
        state.athleteProfilesLoaded = true;
      });
    },
    setAthleteProfilesLoaded: (loaded: boolean) => {
      set((state) => {
        state.athleteProfilesLoaded = loaded;
      });
    },
    setZonePerformance: (performance: ITrainingZonePerformance[]) => {
      set((state) => {
        state.zonePerformance = performance;
        state.zonePerformanceLoaded = true;
      });
    },
    setZonePerformanceLoaded: (b: boolean) => {
      set((state) => {
        state.zonePerformanceLoaded = b;
      });
    },
    reset: () => {
      set(initialState);
    },
  }))
);
