import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useMemo } from 'react';
import { Users } from 'lucide-react';
import { IMedicalReportRecord } from '../../types/medical';
import {
  IEmptyStateProps,
  ITableColumn,
  ITableItem,
  TruncatedText,
} from '@cycling-web/analog-ui';
import { SeverityTag } from '../columns/SeverityTag';
import { format } from 'date-fns';
import { Details } from './Details';
import { useNavigate, useParams } from 'react-router';
import { MedicalReportsTableProps } from './types';
import { useDictionaryValue } from '../../hooks/useDictionaryValue';
import { Athlete } from '../columns/Athlete';
import { useBaseUrl } from '../../hooks/useBaseUrl';
import { ROUTES } from '../../router/routes';

export const useViewModel = (props: MedicalReportsTableProps) => {
  const { t } = useTranslation();
  const baseUrl = useBaseUrl();

  const { items, loaded, showAthleteColumn = false, athletesMap = {} } = props;
  const navigate = useNavigate();
  const getDictionaryValue = useDictionaryValue();

  const columns: ITableColumn[] = useMemo(() => {
    const _columns: ITableColumn[] = [];

    if (showAthleteColumn) {
      _columns.push(
        {
          key: 'athlete',
          name: t('label.athlete'),
          minWidth: 200,
          grow: 1,
        },
        {
          key: 'issue',
          name: t('label.issue'),
          minWidth: 100,
          grow: 100,
        }
      );
    } else {
      _columns.push({
        key: 'issue',
        name: t('label.issue'),
        minWidth: 100,
        grow: 1,
      });
    }

    return [
      ..._columns,
      {
        key: 'severity',
        name: t('label.severity'),
        minWidth: 110,
        maxWidth: 110,
      },
      {
        key: 'onsetDate',
        name: t('label.onset_date'),
        minWidth: 130,
        maxWidth: 130,
      },
      {
        key: 'recovered_by',
        name: t('label.recovered_by'),
        minWidth: 130,
        maxWidth: 130,
      },
      {
        key: 'reported_by',
        name: t('label.reported_by'),
        minWidth: 200,
        maxWidth: 200,
      },
      {
        key: 'details',
        name: t('label.details'),
        minWidth: 110,
        maxWidth: 110,
      },
    ];
  }, [showAthleteColumn, t]);

  const onRenderCell = (column: ITableColumn, item: ITableItem) => {
    const castedItem = item as IMedicalReportRecord;

    const url = `${baseUrl}/${ROUTES.COACH}/${ROUTES.ATHLETES}/${castedItem.athleteId}/${ROUTES.HEALTH}`;

    const issues = castedItem.issue
      ? castedItem.issue
          .split(',')
          .map((issue: string) => {
            return getDictionaryValue({
              recordType: castedItem.recordType,
              key: issue,
            });
          })
          .join(', ')
      : '';

    const renderMap: Record<string, ReactNode> = {
      athlete: (
        <Athlete athlete={athletesMap[castedItem.athleteId]} url={url} />
      ),
      issue: <TruncatedText text={issues} />,
      severity: <SeverityTag severity={castedItem.severity} />,
      onsetDate: format(new Date(castedItem.onsetDate * 1000), 'dd/MM/yyyy'),
      recovered_by: format(
        new Date(castedItem.estimatedRecovery * 1000),
        'dd/MM/yyyy'
      ),
      reported_by: castedItem.createdBy,
      details: <Details item={castedItem} />,
    };

    return renderMap[column.key];
  };

  const emptyStateProps: IEmptyStateProps | undefined =
    loaded && items.length === 0
      ? {
          title:
            props.emptyStateProps?.title || t('banner.empty_reports_title'),
          text:
            props.emptyStateProps?.text || t('banner.empty_reports_message'),
          icon: props.emptyStateProps?.icon || <Users size={32} />,
          ...props.emptyStateProps,
        }
      : undefined;

  const onRowClick = useCallback(
    (item: ITableItem) => {
      const castedItem = item as IMedicalReportRecord;
      navigate(`${castedItem.id}`);
    },
    [navigate]
  );

  return {
    columns,
    items,
    loading: !loaded,
    emptyStateProps,
    onRenderCell,
    onRowClick,
  };
};
