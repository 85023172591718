import './index.css';
import {
  Button,
  Calendar,
  IconButton,
  LabelValue,
  Popover,
  Toast,
} from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { format, formatISO, parseISO } from 'date-fns';
import { MedicalRepository } from '../../../../api/medical/repository';
import { MedicalService } from '../../../../api/medical/service';
import { useAthleteMedicalReportStore } from '../../store/slice';
import { AxiosError } from 'axios';
import { ApiErrorCode } from '../../../../constants';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useUserRole } from '../../../../hooks/useUserRole';
import { trackUserInteractionEvent } from '../../../../ms/log-insights';
import { TrackingEvent, TrackingForm } from '../../../../ms/tracking-entities';
import { useMediaMatch } from 'rooks';
import { Check } from 'lucide-react';

export const RecoveryPopover = () => {
  const { t } = useTranslation();
  const { isAthlete } = useUserRole();
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const athleteReport = useAthleteMedicalReportStore((s) => s.report);
  const [date, setDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [recoveredOn, setRecoveredOn] = useState<number | undefined>(
    athleteReport?.recoveredOn
  );
  const isMobile = useMediaMatch('(max-width: 480px)');

  const onChange = (date: Date) => {
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
    const utcDate = formatISO(parseISO(formattedDate));
    const nextDate = new Date(utcDate);
    setDate(nextDate);
  };

  const medicalRepository = useMemo(() => {
    return new MedicalRepository(new MedicalService());
  }, []);

  const onSave = useCallback(() => {
    if (!athleteReport) {
      return;
    }

    trackUserInteractionEvent(TrackingEvent.SUBMIT_FORM, {
      form: TrackingForm.MARK_RECOVERED,
    });

    setLoading(true);

    const recoveredOn = date.getTime();
    medicalRepository
      .markRecovered({
        id: athleteReport.id,
        recoveredOn,
      })
      .then(() => {
        setRecoveredOn(recoveredOn);
        if (
          athletesMap[athleteReport.athleteId].medicalReport?.id ===
          athleteReport.id
        ) {
          useAthletesStore.getState().updateAthlete({
            id: athleteReport.athleteId,
            medicalReport: undefined,
          });
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status !== ApiErrorCode.Unauthorized) {
          Toast.error(
            {
              title: t('error.mark_recovered_title'),
              message: t('error.mark_recovered_message'),
            },
            { toastId: 'mark_recovered' }
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [athletesMap, athleteReport, date, medicalRepository, t]);

  const content = (
    <div className="recovery-popover__content">
      <Calendar value={date} onChange={onChange} />
      <footer className="recovery-popover__footer">
        <LabelValue
          label={`${t('label.selected_date')}:`}
          value={format(date, 'dd/MM/yyyy')}
        />
        <Button content={t('action.save')} loading={loading} onClick={onSave} />
      </footer>
    </div>
  );

  return recoveredOn ? (
    <>
      {t('label.recovered_on')}: {format(new Date(recoveredOn), 'dd/MM/yyyy')}
    </>
  ) : (
    !isAthlete && (
      <Popover
        anchor={
          isMobile ? (
            <IconButton content={<Check />} />
          ) : (
            <Button content={t('action.mark_as_recovered')} />
          )
        }
        content={content}
      />
    )
  );
};
