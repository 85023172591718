import { useTranslation } from 'react-i18next';

type IProps = {
  text?: string;
};

export const MessageLoading = ({ text }: IProps) => {
  const { t } = useTranslation();

  const _text = text || t('label.thinking');

  return (
    <div className="conversation-message analog-typography--body">
      <div className="conversation-message__content">
        <div className="conversation-message__content-processing shimmer">
          {_text}
        </div>
      </div>
    </div>
  );
};
