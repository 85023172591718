import { useEffect, useMemo } from 'react';
import { PerformanceRepository } from '../../../api/performance/repository';
import { PerformanceService } from '../../../api/performance/service';
import { useAthletePerformanceStore } from '../store/slice';
import { format } from 'date-fns';
import { IPowerDerivative } from '../../../types/performance';
import { calculateDateRange } from '../../../utils/utils';
import { Toast } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { AbortError, ApiErrorCode } from '../../../constants';
import { usePageFilters } from '../../../context/PageFilters';
import { IAthleteDetailsFilters } from '../../AthleteDetails/types';

export const useFetchPowerDerivatives = () => {
  const { t } = useTranslation();
  const { athleteId } = useParams();
  const { filters: sharedFilters } = usePageFilters<IAthleteDetailsFilters>();
  const filters = useAthletePerformanceStore((s) => s.filters);
  const setPowerDerivatives = useAthletePerformanceStore(
    (s) => s.setPowerDerivatives
  );
  const setPowerDerivativesLoaded = useAthletePerformanceStore(
    (s) => s.setPowerDerivativesLoaded
  );
  const performanceRepository = useMemo(() => {
    return new PerformanceRepository(new PerformanceService(), { abort: true });
  }, []);

  useEffect(() => {
    if (!athleteId || sharedFilters?.period === undefined) {
      return;
    }

    const { startDate, endDate } = calculateDateRange(sharedFilters.period);

    performanceRepository
      .getPowerDerivatives({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        athleteIds: [+athleteId],
        unit: filters.mmp.dateGroupBy,
      })
      .then((response: IPowerDerivative) => {
        setPowerDerivatives(response);
      })
      .catch((error: AxiosError) => {
        setPowerDerivativesLoaded(true);
        if (
          error?.response?.status !== ApiErrorCode.Unauthorized &&
          error.name !== AbortError
        ) {
          Toast.error(
            {
              title: t('error.get_power_derivatives_title'),
              message: t('error.get_power_derivatives_message'),
            },
            { toastId: 'get_power_derivatives' }
          );
        }
      });
  }, [
    athleteId,
    sharedFilters?.period,
    filters.mmp.dateGroupBy,
    performanceRepository,
    setPowerDerivatives,
    setPowerDerivativesLoaded,
    t,
  ]);
};
