import {
  createContext,
  use,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  IAnaContext,
  IAnaContextProps,
  IConversationPagination,
} from '../types';
import { useBoolean } from '@cycling-web/analog-ui';
import { ROUTES } from '../../../router/routes';
import {
  IAnaAuthor,
  IAnaContentType,
  IAnaResponsePayload,
  IAnaSocketMessageType,
  IAnaSocketResponse,
  IAnaStreamMessage,
  IAnaToolUseContent,
} from '../../Conversation';
import { useAnaAssistantStore } from '../store/slice';
import { Logger } from '../../../utils/logger';
import { useFeatureFlagsStore } from '../../../store/feature-flags/slice';
import { FeatureFlag } from '../../../types/feature-flags';
import { useFetchPaginatedHistory } from '../hooks/useFetchPaginatedHistory';
import { LS, PAGINATION_SIZE } from '../../../constants';
import { trackUserInteractionEvent } from '../../../ms/log-insights';
import { TrackingEvent, TrackingModal } from '../../../ms/tracking-entities';

export const AnaContext = createContext<IAnaContext>({} as IAnaContext);

export const useAnaContext = () => use(AnaContext);

export const AnaContextProvider = ({ children }: IAnaContextProps) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      const el = scrollContainerRef.current;
      if (el) {
        el.scrollTo(0, el.scrollHeight);
      }
    });
  }, [scrollContainerRef]);

  // ---------------------------------------------------------------------------
  // Pagination controls

  const paginationRef = useRef<IConversationPagination>({
    turn_skip: 0,
    turn_take: PAGINATION_SIZE,
  });

  const incrementSkip = useCallback(() => {
    paginationRef.current.turn_skip++;
  }, []);

  // ---------------------------------------------------------------------------
  // Appearance controls
  const featureFlags = useFeatureFlagsStore((s) => s.featureFlags);
  const lsShowAna = localStorage.getItem(LS.ShowAna) === 'true';

  const {
    value: showAna,
    setTrue: openAna,
    setFalse: dismissAna,
    setValue: setOpenAna,
  } = useBoolean(
    lsShowAna && featureFlags.includes(FeatureFlag.Ana_Everywhere)
  );

  const toggleAna = useCallback(() => {
    setOpenAna((f: boolean) => {
      if (f) {
        trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
          modal: TrackingModal.ANA,
        });

        localStorage.removeItem(LS.ShowAna);
      } else {
        trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
          modal: TrackingModal.ANA,
        });

        localStorage.setItem(LS.ShowAna, 'true');
      }
      return !f;
    });
  }, [setOpenAna]);

  const handleOpenAna = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
      modal: TrackingModal.ANA,
    });

    localStorage.setItem(LS.ShowAna, 'true');
    openAna();
  }, [openAna]);

  const handleDismissAna = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.ANA,
    });

    localStorage.removeItem(LS.ShowAna);
    dismissAna();
  }, [dismissAna]);

  useEffect(() => {
    setOpenAna(lsShowAna && featureFlags.includes(FeatureFlag.Ana_Everywhere));
  }, [featureFlags, lsShowAna, setOpenAna]);

  const blacklistUrl = useMemo((): string[] => {
    return [
      ROUTES.TEAMS,
      ROUTES.LEGAL,
      ROUTES.SMART_CHARTS,
      ROUTES.SETTINGS,
      ROUTES.ADMIN_PANEL,
    ];
  }, []);

  // ---------------------------------------------------------------------------
  // Tool control

  const activeTool = useAnaAssistantStore((s) => s.activeTool);

  const openActiveTool = useCallback((tool: IAnaToolUseContent) => {
    trackUserInteractionEvent(TrackingEvent.MODAL_OPENED, {
      modal: TrackingModal.ANA_TOOL,
    });

    useAnaAssistantStore.getState().setActiveTool(tool);
  }, []);

  const dismissActiveTool = useCallback(() => {
    trackUserInteractionEvent(TrackingEvent.MODAL_CLOSED, {
      modal: TrackingModal.ANA_TOOL,
    });

    useAnaAssistantStore.getState().setActiveTool(null);
  }, []);

  // ---------------------------------------------------------------------------
  // Messages

  const {
    messages,
    streamingMessages,
    updateStream,
    completeStream,
    completeStreamContent,
    addMessage,
  } = useAnaAssistantStore();

  const onStreamingResponseMessage = useCallback(
    (message: IAnaSocketResponse) => {
      const streamMessage = message as IAnaStreamMessage;
      const contentType = streamMessage.content.content_type;

      Logger.default(streamMessage);

      if (contentType === IAnaContentType.TOOL_RESULT) {
        Logger.info(`[Tool Result] ###############`);
        return;
      }

      if (contentType === IAnaContentType.DELIMITER) {
        Logger.warning(`[Content Change] ###############`);
        completeStreamContent(
          streamMessage.id,
          streamMessage.content.content_id
        );
        return;
      }

      if (contentType === IAnaContentType.THOUGHT) {
        updateStream(streamMessage);
        return;
      }

      if (contentType === IAnaContentType.ADMIN) {
        updateStream(streamMessage);
        return;
      }

      if (contentType === IAnaContentType.TEXT) {
        if (streamMessage.actor === IAnaAuthor.User) {
          const messages = useAnaAssistantStore.getState().messages;
          const lastMessage = messages[messages.length - 1];
          if (lastMessage?.author === IAnaAuthor.User) {
            return;
          }
        }

        updateStream(streamMessage);

        if (streamMessage.actor === IAnaAuthor.User) {
          completeStream(streamMessage.id);
        }
        return;
      }

      if (contentType === IAnaContentType.TOOL_USE) {
        updateStream(streamMessage);
        return;
      }
    },
    [completeStreamContent, completeStream, updateStream]
  );

  const onSocketMessage = useCallback(
    (payload: IAnaResponsePayload) => {
      const type = payload.message.message_type;
      scrollToBottom();

      if (type === IAnaSocketMessageType.ERROR) {
        Logger.error(`[Server Error] ################`);
        return;
      }

      if (type === IAnaSocketMessageType.RESPONSE_START) {
        incrementSkip();
        Logger.success(`[Response Start] ################`);
        return;
      }

      if (type === IAnaSocketMessageType.RESPONSE) {
        incrementSkip();
        return;
      }

      if (type === IAnaSocketMessageType.RESPONSE_STOP) {
        Logger.error(`[Response Stop] ################`);
        completeStream(payload.message.id);
        return;
      }

      if (type === IAnaSocketMessageType.ACK) {
        Logger.info(`[Server Ack] ################`);
        return;
      }

      if (type === IAnaSocketMessageType.STREAMING_RESPONSE) {
        const message = payload.message;
        onStreamingResponseMessage(message);
      }
    },
    [completeStream, onStreamingResponseMessage, scrollToBottom, incrementSkip]
  );

  const { fetchConversationHistory, isPageLoading } =
    useFetchPaginatedHistory();

  const context = useMemo(
    (): IAnaContext => ({
      showAna,
      openAna: handleOpenAna,
      dismissAna: handleDismissAna,
      setOpenAna,
      toggleAna,
      blacklistUrl,
      messages,
      streamingMessages,
      onSocketMessage,
      addMessage,
      activeTool,
      openActiveTool,
      dismissActiveTool,
      fetchConversationHistory,
      isPageLoading,
      scrollContainerRef,
      scrollToBottom,
      paginationRef,
      incrementSkip,
    }),
    [
      showAna,
      handleOpenAna,
      handleDismissAna,
      setOpenAna,
      toggleAna,
      blacklistUrl,
      messages,
      streamingMessages,
      onSocketMessage,
      addMessage,
      activeTool,
      openActiveTool,
      dismissActiveTool,
      fetchConversationHistory,
      isPageLoading,
      scrollContainerRef,
      scrollToBottom,
      paginationRef,
      incrementSkip,
    ]
  );

  return <AnaContext value={context}>{children}</AnaContext>;
};
