import { SelectSingleControl } from '@cycling-web/common';
import { FormElement, ISelectOption } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { getClientTimeZone, getTimeZones } from '../../../utils/date-time';

export const TimezoneControl = () => {
  const { t } = useTranslation();
  const { formState, setValue } = useFormContext<{
    timezone?: string | null;
  }>();
  const timezoneValue = useWatch({ name: 'timezone' });

  const timezoneOptions: ISelectOption[] = useMemo((): ISelectOption[] => {
    const timezones = getTimeZones();
    return timezones.map((zone) => {
      return {
        id: zone.zone,
        text: zone.displayName,
      };
    });
  }, []);

  useEffect(() => {
    if (!timezoneValue) {
      const detectedTimezone = getClientTimeZone();
      const detectedOption = timezoneOptions.find(
        (o) => o.id === detectedTimezone?.zone
      );

      if (detectedOption) {
        setValue('timezone', detectedOption.id);
      }
    }
  }, [timezoneValue, timezoneOptions, setValue]);

  return (
    <FormElement
      label={t('label.timezone')}
      message={formState?.errors?.timezone?.message}
    >
      <SelectSingleControl
        options={timezoneOptions}
        name="timezone"
        placeholder={t('placeholder.timezone')}
        invalid={!!formState.errors.timezone}
        search
        dropdownProps={{
          maxHeight: '290px',
        }}
      />
    </FormElement>
  );
};
