import './index.css';
import { Widget } from '../Widget';
import { WidgetHeader } from '../WidgetHeader';
import { useTranslation } from 'react-i18next';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IFiltersBarProps } from '@cycling-web/common';
import { usePageFilters } from '../../../../context/PageFilters';
import { IAthleteProfileFilters } from '../../types';
import { IChartSource } from '../../../../components/Chart';
import { TimeInZonesChart } from '../../../../components/charts/TimeInZonesChart';
import { TrainingZoneKeys } from '../../utils/getDefaults';

type IProps = {
  source: IChartSource;
  composeKey: string;
  loading?: boolean;
  color?: string;
};

export const TimeInZones = ({ source, color, composeKey, loading }: IProps) => {
  const { t } = useTranslation();

  const subtitle: Record<string, string> = {
    [TrainingZoneKeys.CyclingPowerMFTP]: `${t('label.power')} (${t(
      'label.ftp'
    )})`,
    [TrainingZoneKeys.CyclingPowerMCP]: `${t('label.power')} (${t(
      'label.mcp'
    )})`,
    [TrainingZoneKeys.CyclingHeartRateDefault]: `${t('label.lthr')} (${t(
      'label.cycling'
    )})`,
    [TrainingZoneKeys.SwimmingCSSDefault]: `${t('label.pace')} (${t(
      'label.css'
    )})`,
    [TrainingZoneKeys.SwimmingHeartRateDefault]: `${t('label.lthr')} (${t(
      'label.swimming'
    )})`,
    [TrainingZoneKeys.RunningPaceDefault]: `${t('label.pace')} (${t(
      'label.tp'
    )})`,
    [TrainingZoneKeys.RunningHeartRateDefault]: `${t('label.lthr')} (${t(
      'label.running'
    )})`,
  };

  const { filters, handleChangeFilter } =
    usePageFilters<IAthleteProfileFilters>();

  const filtersBarProps: IFiltersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{
          variant: 'layer4',
          dropdownProps: { minWidth: '180px', placement: 'bottom-end' },
        }}
      />
    ),
    buttonProps: {
      variant: 'layer4',
    },
  };

  return (
    <Widget className="athlete-profile__time-in-zone-widget">
      <WidgetHeader
        title={t('label.time_in_zones')}
        subtitle={subtitle[composeKey]}
        filtersBarProps={filtersBarProps}
      />
      <TimeInZonesChart source={source || []} color={color} loading={loading} />
    </Widget>
  );
};
