import './index.css';
import { MedicalReportsTableView } from '../../../../components/MedicalReportsTableView';
import { useAthletesStore } from '../../../../store/athletes/slice';
import { useMemo } from 'react';
import { useMedicalReportsStore } from '../../store/slice';
import { filterReportsDelegate } from '../../utils/filterReportsDelegate';
import { useTranslation } from 'react-i18next';
import { usePageFilters } from '../../../../context/PageFilters';
import { IMedicalReportsFilters } from '../../types';

export const MedicalReportsTable = () => {
  const { t } = useTranslation();
  const { filters } = usePageFilters<IMedicalReportsFilters>();
  const reports = useMedicalReportsStore((s) => s.reports);
  const reportsLoaded = useMedicalReportsStore((s) => s.reportsLoaded);
  const athletesMap = useAthletesStore((s) => s.athletesMap);
  const athletesLoaded = useAthletesStore((s) => s.athletesLoaded);

  const filteredReports = useMemo(() => {
    return reports.filter(filterReportsDelegate(filters, athletesMap));
  }, [athletesMap, filters, reports]);

  return (
    <MedicalReportsTableView
      items={filteredReports}
      loaded={reportsLoaded && athletesLoaded}
      showAthleteColumn={true}
      athletesMap={athletesMap}
      emptyStateProps={{
        title: undefined,
        text: t('banner.empty_dashboard_health_message'),
        className: 'medical-reports__empty-state',
      }}
    />
  );
};
