import './index.css';
import { OnboardingFooter } from '../Footer';
import { Typography } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useFetchPlatforms } from '../../../../hooks/useFetchPlatforms';
import { usePlatformsStore } from '../../../../store/platforms/slice';
import { IPlatform, IPlatformEnum } from '../../../../types/platform';
import { UltraHuman } from '../../../../components/data-sharing/UltraHuman';
import { Whoop } from '../../../../components/data-sharing/Whoop';
import { Wahoo } from '../../../../components/data-sharing/Wahoo';
import { Oura } from '../../../../components/data-sharing/Oura';
import { Garmin } from '../../../../components/data-sharing/Garmin';
import { Environment, getEnvironment } from '../../../../utils/getEnvironment';

export const OnboardingDevices = () => {
  const { t } = useTranslation();
  const { fetchPlatforms } = useFetchPlatforms();

  const platforms = usePlatformsStore((s) => s.platforms);
  const platformsLoaded = usePlatformsStore((s) => s.platformsLoaded);

  const stepCompleted = useMemo(() => {
    const devices: IPlatformEnum[] = [
      IPlatformEnum.UltraHuman,
      IPlatformEnum.Wahoo,
      IPlatformEnum.Whoop,
      IPlatformEnum.Oura,
      IPlatformEnum.Garmin,
    ];
    return (
      platforms.filter((p: IPlatform) => {
        return devices.includes(p.platformName as IPlatformEnum);
      }).length > 0
    );
  }, [platforms]);

  const connectedPlatforms: Record<IPlatformEnum, IPlatform> = useMemo(() => {
    return platforms.reduce((acc: Record<string, IPlatform>, p: IPlatform) => {
      acc[p.platformName as IPlatformEnum] = p;
      return acc;
    }, {});
  }, [platforms]);

  const onConnect = useCallback(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  return (
    <>
      <header className="onboarding-devices__header">
        <Typography
          variant="h4"
          weight="bold"
          className="onboarding-devices__header-title"
          text={t('label.data_sharing')}
        />
      </header>
      <div className="onboarding-devices__list">
        <Typography
          variant="headline"
          weight="regular"
          className="onboarding-devices__list-title"
          text={t('label.integrated_devices')}
        />

        <div className="onboarding-devices__list-items">
          <UltraHuman
            platform={connectedPlatforms[IPlatformEnum.UltraHuman]}
            platformLoaded={platformsLoaded}
            onConnect={onConnect}
          />
          <Whoop
            platform={connectedPlatforms[IPlatformEnum.Whoop]}
            platformLoaded={platformsLoaded}
            onConnect={onConnect}
          />
          <Wahoo
            platform={connectedPlatforms[IPlatformEnum.Wahoo]}
            platformLoaded={platformsLoaded}
            onConnect={onConnect}
          />
          <Oura
            platform={connectedPlatforms[IPlatformEnum.Oura]}
            platformLoaded={platformsLoaded}
            onConnect={onConnect}
          />
          {getEnvironment() !== Environment.Production && (
            <Garmin
              platform={connectedPlatforms[IPlatformEnum.Garmin]}
              platformLoaded={platformsLoaded}
              onConnect={onConnect}
            />
          )}
        </div>
      </div>
      <OnboardingFooter stepCompleted={stepCompleted} />
    </>
  );
};
