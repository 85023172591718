import { Button, IconButton } from '@cycling-web/analog-ui';
import { Plus } from 'lucide-react';
import { DEFAULT_GROUP, GroupsContext } from '../../context';
import { PageFilters } from '@cycling-web/common';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { IGroupsFilters } from '../../../../types/groups';
import { usePageFilters } from '../../../../context/PageFilters';
import { useMediaMatch } from 'rooks';

export const GroupFilters = () => {
  const { t } = useTranslation();
  const { openGroupFormModal } = useContext(GroupsContext);
  const { filters, handleChangeFilter, removeFilter } =
    usePageFilters<IGroupsFilters>();
  const isMobile = useMediaMatch('(max-width: 480px)');

  const handleSearchClear = useCallback(() => {
    removeFilter('search');
  }, [removeFilter]);

  return (
    <PageFilters
      searchInputProps={{
        value: filters?.search || '',
        onChange: handleChangeFilter('search'),
        onClear: handleSearchClear,
      }}
      actionElements={
        isMobile ? (
          <IconButton
            content={<Plus />}
            onClick={openGroupFormModal(DEFAULT_GROUP)}
          />
        ) : (
          <Button
            startIcon={<Plus />}
            content={t('banner.empty_groups_action')}
            onClick={openGroupFormModal(DEFAULT_GROUP)}
          />
        )
      }
    />
  );
};
