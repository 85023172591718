import './index.css';
import { IChartContext, IChartFixedCursor, IChartProps } from '../../types';
import { ChartCore } from '../ChartCore';
import { ChartHeader } from '../ChartHeader';
import { ChartLegend } from '../ChartLegend';
import { useMemo, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { EmptyState, Spinner } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { ChartSpline } from 'lucide-react';
import { RadarIndicators } from '../RadarIndicators';
import { ChartContext } from '../../context';
import { clsx } from 'clsx';

export const Chart = ({
  option,
  events,
  headerProps,
  legendProps,
  radarProps,
  loading,
  group,
  hasData,
  onPlotClick,
  fixedCursorProps,
  forceReinit,
  emptyStateProps,
}: IChartProps) => {
  const { t } = useTranslation();
  const [ready, setReady] = useState<boolean>(false);
  const [fixedCursors, setFixedCursors] = useState<IChartFixedCursor[]>([]);

  const chartRef = useRef<echarts.EChartsType | null>(null);
  const showHeader = headerProps?.show === undefined ? true : headerProps.show;
  const showLegend = legendProps?.show === undefined ? true : legendProps.show;

  const isRadar = !!option.radar;

  // @ts-ignore
  const source: any[] = option?.dataset?.source || [];
  const _hasData =
    hasData ||
    source.filter((row) => {
      if (!row) {
        return false;
      }

      if (typeof row[0] === 'string' && row[0].includes('_offset')) {
        return false;
      }

      return true;
    })?.length > 1;

  const context: IChartContext = useMemo((): IChartContext => {
    return {
      option,
      chartRef,
      ready,
      setReady,
      events,
      group,
      onPlotClick,
      fixedCursorProps,
      fixedCursors,
      setFixedCursors,
      forceReinit,
    };
  }, [
    option,
    ready,
    events,
    group,
    onPlotClick,
    fixedCursorProps,
    fixedCursors,
    forceReinit,
  ]);

  const chartClass = clsx('chart-core__wrapper');

  const emptyStateClass = clsx(
    'chart-core__empty-state',
    (loading || _hasData) && 'chart-core__empty-state--hidden'
  );

  const loadingStateClass = clsx(
    'chart-core__loading-state',
    !loading && 'chart-core__loading-state--hidden'
  );

  return (
    <ChartContext.Provider value={context}>
      <div className="chart-container">
        {showHeader && <ChartHeader {...headerProps} loading={loading} />}
        <div className="chart-core__container">
          <div className={chartClass}>
            <ChartCore />
            {isRadar && <RadarIndicators {...radarProps} />}
          </div>

          <EmptyState
            className={loadingStateClass}
            icon={<Spinner size="xl" />}
            text={t('banner.loading_chart_data')}
          />

          <EmptyState
            className={emptyStateClass}
            icon={emptyStateProps?.icon || <ChartSpline size={40} />}
            text={emptyStateProps?.text || t('banner.empty_chart_message')}
          />
        </div>
        {showLegend ? (
          legendProps?.onRenderLegend ? (
            legendProps.onRenderLegend()
          ) : (
            <ChartLegend
              chartRef={chartRef}
              {...legendProps}
              loading={loading}
            />
          )
        ) : null}
      </div>
    </ChartContext.Provider>
  );
};
