import { ICalendarEvent } from '../../../../types/workouts';
import CyclingIcon from '../../../../assets/icons/cycling.svg?react';
import { TruncatedText } from '@cycling-web/analog-ui';
import { useTranslation } from 'react-i18next';
import { EMPTY } from '../../../../constants';
import { format } from 'date-fns';
import { UTCMidnightToSameDate } from '../../../../utils/date-time';

type IProps = {
  event: ICalendarEvent;
};

export const Event = ({ event }: IProps) => {
  const { t } = useTranslation();

  const eventDate = event?.eventDateTimeUtc
    ? format(UTCMidnightToSameDate(event.eventDateTimeUtc), 'dd/MM/yyyy')
    : '';

  return (
    <div className="training-plan__competitions-event analog-typography--subtitle">
      <CyclingIcon className="training-plan__event-icon" />
      <TruncatedText
        className=" training-plan__event-name"
        text={event.title}
      />
      <div className="training-plan__competitions-date">{eventDate}</div>
      <div className="training-plan__competitions-priority">
        {t('label.priority')} {event.priority || EMPTY}
      </div>
    </div>
  );
};
