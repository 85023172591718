import { createContext, ReactNode, useContext } from 'react';
import { IUserProfileBase } from '../types/common';
import { IMenuBaseOption, IMenuOption } from '@cycling-web/analog-ui';

export type ILayoutContext = {
  leftAdornment?: ReactNode;
  user: IUserProfileBase | null;
  userLoaded: boolean;
  userMenuOptions?: IMenuOption[];
  handleUserMenuChange?: (option: IMenuBaseOption) => void;
  handleSignOut: () => void;
};

export const LayoutContext = createContext<ILayoutContext>(
  {} as ILayoutContext
);

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};
