import React, { useCallback, useMemo } from 'react';

import './index.css';
import { useTranslation } from 'react-i18next';
import { ChartWrapper } from '../../../../components/ChartWrapper';
import { LastNDaysFilter } from '../../../../components/filters/LastNDaysFilter';
import { IHandleChangeFilter, useBreakpoints } from '@cycling-web/common';
import {
  Chart,
  ChartColor,
  ChartLegendItem,
  chartTooltipFormatter,
  getDateAxisInterval,
  IChartSource,
  IEChartOption,
} from '../../../../components/Chart';
import { getGradient } from '../../../../components/Chart/utils/getGradient';
import { TodayDetails } from './TodayDetails';
import {
  ITrainingLoad,
  ITrainingLoadPoint,
} from '../../../../types/performance';
import { formatDate, parseDate } from '../../../../utils/utils';
import { handleChartMouseOver } from '../../../../components/Chart/utils/chartTracking';
import {
  ChartAxisName,
  ChartTrackingNames,
} from '../../../../constants/charts';

type IProps = {
  data: ITrainingLoad | null;
  filters: {
    period: string;
  };
  handleChangeFilter: IHandleChangeFilter;
  loading?: boolean;
};

export const PerformanceManagementChart = ({
  data,
  filters,
  handleChangeFilter,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const source = useMemo(() => {
    const source: IChartSource = [
      [
        ChartAxisName.Date,
        ChartAxisName.CTL,
        ChartAxisName.ATL,
        ChartAxisName.TSB,
      ],
    ];
    const points = data?.points || [];
    points.forEach((item: ITrainingLoadPoint) => {
      const date = formatDate(parseDate(item.startDate));
      source.push([date]);

      source[source.length - 1].push(
        item.data.ctl,
        item.data.atl,
        item.data.tsb
      );
    });

    return source;
  }, [data]);

  const onRenderTooltipValue = useCallback((value: number, _: string) => {
    return Math.round(value);
  }, []);

  const performanceToday = useMemo(() => {
    const lastValues = source[source.length - 1];
    return {
      ctl: Math.round(typeof lastValues[1] === 'number' ? lastValues[1] : 0),
      atl: Math.round(typeof lastValues[2] === 'number' ? lastValues[2] : 0),
      tsb: Math.round(typeof lastValues[3] === 'number' ? lastValues[3] : 0),
    };
  }, [source]);

  const minYAxis = useMemo(() => {
    const tsbValues: number[] = [];

    for (let i = 1; i < source.length; i++) {
      tsbValues.push(source[i][3] as number);
    }

    if (tsbValues.length > 0) {
      return Math.floor(Math.min(...tsbValues) / 50) * 50;
    }
    return 0;
  }, [source]);

  const option: IEChartOption = useMemo((): IEChartOption => {
    return {
      dataset: {
        source,
      },
      grid: isMobile
        ? {
            left: 40,
            right: 0,
          }
        : {},
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: 30,
          interval: getDateAxisInterval({
            period: filters.period,
          }),
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: [
        {
          type: 'value',
          min: minYAxis,
        },
      ],
      series: [
        {
          name: ChartAxisName.CTL,
          type: 'line',
          yAxisIndex: 0,
          z: 2,
          itemStyle: {
            color: ChartColor.Blue,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.ATL,
          type: 'line',
          yAxisIndex: 0,
          z: 2,
          itemStyle: {
            color: ChartColor.LightPurple,
          },
          symbolSize: 2,
          smooth: true,
        },
        {
          name: ChartAxisName.TSB,
          type: 'line',
          yAxisIndex: 0,
          z: 1,
          itemStyle: {
            color: ChartColor.Yellow,
          },
          areaStyle: {
            ...getGradient({ from: ChartColor.LightYellow30 }),
            origin: 'start',
          },
          symbolSize: 2,
          smooth: true,
          encode: {
            x: ChartAxisName.Date,
            y: ChartAxisName.TSB,
          },
        },
      ],
      tooltip: {
        formatter: chartTooltipFormatter({
          onRenderValue: onRenderTooltipValue,
        }),
      },
    };
  }, [filters, minYAxis, onRenderTooltipValue, source, isMobile]);

  const onRenderLegendItem = (item) => {
    return <ChartLegendItem item={item} />;
  };

  const filtersBarProps = {
    filters: (
      <LastNDaysFilter
        value={filters.period}
        onChange={handleChangeFilter('period')}
        selectProps={{ variant: 'layer3' }}
      />
    ),
  };

  return (
    <ChartWrapper>
      <Chart
        headerProps={{
          title: t('label.performance_management_chart'),
          subtitle: <TodayDetails {...performanceToday} />,
          filtersBarProps,
        }}
        option={option}
        legendProps={{
          onRenderItem: onRenderLegendItem,
        }}
        loading={loading}
        events={{
          onMouseOver: handleChartMouseOver({
            name: ChartTrackingNames.PerformanceManagement,
          }),
        }}
      />
    </ChartWrapper>
  );
};
