import './index.css';
import { IAthleteTrainingZone } from '../../../../../../types/athlete-profiles';
import { Typography } from '@cycling-web/analog-ui';
import { ChartColor } from '../../../../../../components/Chart';
import { useCallback } from 'react';
import {
  MAX_ZONE_VALUE,
  MAX_ZONE_VALUE_PLACEHOLDER,
} from '../../../../utils/getDefaults';

type IProps = {
  zone: IAthleteTrainingZone;
  units: string;
};

export const TrainingZone = ({ zone, units }: IProps) => {
  const color = ChartColor[zone.color] || ChartColor.Blue;

  const formatZone = useCallback((value: number) => {
    return value === MAX_ZONE_VALUE ? MAX_ZONE_VALUE_PLACEHOLDER : value;
  }, []);

  return (
    <div className="training-zones">
      <Typography
        variant="subtitle"
        className="training-zones__title"
        weight="bold"
        text={zone.name}
        style={{ color }}
      />
      <Typography
        variant="subtitle"
        className="training-zones__value"
        text={`${formatZone(zone.from)} - ${formatZone(zone.to)}`}
      />
      <Typography
        variant="subtitle"
        className="training-zones__units"
        text={units}
      />
    </div>
  );
};
