import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';

export type SerializedAutocompleteNode = {
  type: 'autocomplete';
  version: 1;
  id: string;
  text: string;
};

export class AutocompleteNode extends DecoratorNode<React.ReactNode> {
  __id: string;
  __text: string;

  static getType(): string {
    return 'autocomplete';
  }

  static clone(node: AutocompleteNode): AutocompleteNode {
    return new AutocompleteNode(node.__id, node.__text, node.__key);
  }

  static importJSON(
    serializedNode: SerializedAutocompleteNode
  ): AutocompleteNode {
    return new AutocompleteNode(serializedNode.id, serializedNode.text);
  }

  constructor(id: string, text: string, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__text = text;
  }

  exportJSON(): SerializedAutocompleteNode {
    return {
      type: 'autocomplete',
      version: 1,
      id: this.__id,
      text: this.__text,
    };
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span');
    dom.classList.add('conversation__autocomplete');
    dom.setAttribute('data-lexical-text', 'true');
    dom.setAttribute('contenteditable', 'false');
    dom.setAttribute('aria-hidden', 'true');
    dom.textContent = this.__text;
    return dom;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): React.ReactNode {
    return null;
  }

  isInert(): boolean {
    return true;
  }

  isIsolated(): boolean {
    return true;
  }
}

export function $createAutocompleteNode(
  id: string,
  text: string
): AutocompleteNode {
  return new AutocompleteNode(id, text);
}

export function $isAutocompleteNode(
  node: LexicalNode | null | undefined
): node is AutocompleteNode {
  return node instanceof AutocompleteNode;
}
