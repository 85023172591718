import './index.css';
import { Page } from '@cycling-web/common';
import { useViewModel } from './useViewModel';
import { EventPageTitle } from './components/PageTitle';
import { Card } from '@cycling-web/analog-ui';
import { AthleteStats } from '../../components/AthleteStats';
import { RouteMap } from '../../components/RouteMap';
import { ClimbingChartWrapper } from './components/ClimbingChartWrapper';
import { EventOverview } from './components/EventOverview';
import { onTrackBackButtonClick } from '../../ms/utils';
import { useMobileMenu } from '../../components/MobileMenu';

export const EventDetails = () => {
  const {
    event,
    eventLoaded,
    backUrl,
    athlete,
    athleteLoaded,
    gpx,
    gpxLoaded,
    positionOnMap,
  } = useViewModel();
  const onRenderMenu = useMobileMenu();

  return (
    <Page
      pageHeaderProps={{
        title: <EventPageTitle />,
        backUrl,
        onBackUrlClick: onTrackBackButtonClick,
        onRenderMenu,
      }}
    >
      <div className="event-overview">
        <header className="event-header">
          <AthleteStats athlete={athlete} athleteLoaded={athleteLoaded} />
        </header>
        <Card variant="layer2" className="event-overview__cards">
          <EventOverview />
          {event?.gpxFileUrl && gpxLoaded && (
            <>
              <Card className="event-route-map-wrapper" variant="layer3">
                <RouteMap data={gpx} activePosition={positionOnMap} />
              </Card>
              <ClimbingChartWrapper />
            </>
          )}
        </Card>
      </div>
    </Page>
  );
};
